import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Card, Col, Layout, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { CyclesTable } from '../cycle/cyclesTable';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedCycles: React.FC<Props> = ({ location }) => {
  const [triggerReload, setTriggerReload] = useState(false);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout>
      <Content>
        <Row gutter={[0, 20]} justify="center">
          <Col span={24}>
            <Card>
              <Collapse
                bordered={false}
                defaultActiveKey={1}
                ghost
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Collapse.Panel header="Overview" key="1">
                  <CyclesTable
                    reload={triggerReload}
                    onReloaded={() => setTriggerReload(false)}
                    queryParams={queryParams}
                  />
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const Cycles = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedCycles));

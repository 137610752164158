import React from 'react';

import {
  Result,
  Row,
  DatePicker,
  Form,
  Select,
  Col,
  message,
  notification,
  Pagination,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { SensorCalibrationLogsTable } from './sensorCalibrationLogsTable';
import { ListCalibrationLogsQuery } from '../../model/querys';
import { getCalibrationLogs } from '../../duck/modules/calibrationLogs';

const { Option } = Select;
const { RangePicker } = DatePicker;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    sensorId: string;
  };

const UnconnectedSensorCalibrationLogs: React.FC<Props> = ({
  sensorId,
  loading,
  error,
  model,
  getCalibrationLogsConnect,
}) => {
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  React.useEffect(() => {
    const query: ListCalibrationLogsQuery = {
      sensorIds: [sensorId],
      limit,
      offset,
    };
    getCalibrationLogsConnect(query);
  }, [sensorId, offset, limit]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load calibration logs"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);
  };

  return (
    <SensorCalibrationLogsTable
      pagination={
        model &&
        model.total != null && (
          <Row justify="center" style={{ marginBottom: 10 }}>
            <Pagination
              total={model.total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )
      }
      loading={loading}
      logs={model?.calibrationLogs || []}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  model: state.calibrationLogs.list,
  loading: state.calibrationLogs.list.loading,
  error: state.calibrationLogs.list.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCalibrationLogsConnect: getCalibrationLogs,
    },
    dispatch,
  );
};

export const SensorCalibrationLogs = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorCalibrationLogs);

import {
  CREATE_SENSOR_CAP,
  CREATE_SENSOR_CAP_FAILED,
  CREATE_SENSOR_CAP_SUCCESS,
  FETCH_SENSOR_CAP,
  FETCH_SENSOR_CAPS,
  FETCH_SENSOR_CAPS_FAILED,
  FETCH_SENSOR_CAPS_SUCCESS,
  FETCH_SENSOR_CAP_FAILED,
  FETCH_SENSOR_CAP_SUCCESS,
  UPDATE_SENSOR_CAP,
  UPDATE_SENSOR_CAP_SUCCESS,
  UPDATE_SENSOR_CAP_FAILED,
  DELETE_SENSOR_CAP,
  DELETE_SENSOR_CAP_SUCCESS,
  DELETE_SENSOR_CAP_FAILED,
  IMPORT_SENSOR_CAPS,
  IMPORT_SENSOR_CAPS_SUCCESS,
  IMPORT_SENSOR_CAPS_FAILED,
} from '../../constants';
import { SensorCapModel } from '../../model/domain';
import { SimpleSensorCapModel } from '../../model/simple';
import {
  ListSensorCapsQuery,
  ListSensorCapsQueryModel,
} from '../../model/querys';

// SENSOR_CAPS
export interface CreateSensorCapAction {
  type: CREATE_SENSOR_CAP;
}

export interface CreateSensorCapSuccessAction {
  type: CREATE_SENSOR_CAP_SUCCESS;
  payload: SensorCapModel;
}

export interface CreateSensorCapFailedAction {
  type: CREATE_SENSOR_CAP_FAILED;
  payload: Error;
}

export interface ImportSensorCapsAction {
  type: IMPORT_SENSOR_CAPS;
}

export interface ImportSensorCapsSuccessAction {
  type: IMPORT_SENSOR_CAPS_SUCCESS;
  payload: ListSensorCapsQueryModel;
}

export interface ImportSensorCapsFailedAction {
  type: IMPORT_SENSOR_CAPS_FAILED;
  payload: Error;
}

export interface FetchSensorCapAction {
  type: FETCH_SENSOR_CAP;
}

export interface FetchSensorCapSuccessAction {
  type: FETCH_SENSOR_CAP_SUCCESS;
  payload: SensorCapModel;
}

export interface FetchSensorCapFailedAction {
  type: FETCH_SENSOR_CAP_FAILED;
  payload: Error;
}

export interface FetchSensorCapsAction {
  type: FETCH_SENSOR_CAPS;
  payload: ListSensorCapsQuery;
}

export interface FetchSensorCapsSuccessAction {
  type: FETCH_SENSOR_CAPS_SUCCESS;
  payload: {
    query: ListSensorCapsQueryModel;
    sensorCaps: SimpleSensorCapModel[];
  };
}

export interface FetchSensorCapsFailedAction {
  type: FETCH_SENSOR_CAPS_FAILED;
  payload: Error;
}

export interface UpdateSensorCapAction {
  type: UPDATE_SENSOR_CAP;
}

export interface UpdateSensorCapSuccessAction {
  type: UPDATE_SENSOR_CAP_SUCCESS;
  payload: SensorCapModel;
}

export interface UpdateSensorCapFailedAction {
  type: UPDATE_SENSOR_CAP_FAILED;
  payload: Error;
}

export interface DeleteSensorCapAction {
  type: DELETE_SENSOR_CAP;
  payload: {
    sensorCapId: string;
  };
}

export interface DeleteSensorCapSuccessAction {
  type: DELETE_SENSOR_CAP_SUCCESS;
  payload: SensorCapModel;
}

export interface DeleteSensorCapFailedAction {
  type: DELETE_SENSOR_CAP_FAILED;
  payload: Error;
}

export type SensorCapDeleteAction =
  | DeleteSensorCapAction
  | DeleteSensorCapSuccessAction
  | DeleteSensorCapFailedAction;
export type SensorCapUpdateAction =
  | UpdateSensorCapAction
  | UpdateSensorCapSuccessAction
  | UpdateSensorCapFailedAction;
export type SensorCapCreateAction =
  | CreateSensorCapAction
  | CreateSensorCapSuccessAction
  | CreateSensorCapFailedAction;
export type SensorCapFetchAction =
  | FetchSensorCapAction
  | FetchSensorCapSuccessAction
  | FetchSensorCapFailedAction;
export type SensorCapsFetchAction =
  | FetchSensorCapsAction
  | FetchSensorCapsSuccessAction
  | FetchSensorCapsFailedAction;
export type SensorCapsImportAction =
  | ImportSensorCapsAction
  | ImportSensorCapsSuccessAction
  | ImportSensorCapsFailedAction;
export type SensorCapsAction =
  | SensorCapCreateAction
  | SensorCapFetchAction
  | SensorCapsFetchAction
  | SensorCapUpdateAction
  | SensorCapDeleteAction
  | SensorCapsImportAction;

// Action creators
export function sensorCapCreate(): CreateSensorCapAction {
  return {
    type: CREATE_SENSOR_CAP,
  };
}

export function sensorCapCreated(
  sensorCap: SensorCapModel,
): CreateSensorCapSuccessAction {
  return {
    type: CREATE_SENSOR_CAP_SUCCESS,
    payload: sensorCap,
  };
}

export function sensorCapCreateFailed(
  error: Error,
): CreateSensorCapFailedAction {
  return {
    type: CREATE_SENSOR_CAP_FAILED,
    payload: error,
  };
}

export function sensorCapsImport(): ImportSensorCapsAction {
  return {
    type: IMPORT_SENSOR_CAPS,
  };
}

export function sensorCapsImported(
  response: ListSensorCapsQueryModel,
): ImportSensorCapsSuccessAction {
  return {
    type: IMPORT_SENSOR_CAPS_SUCCESS,
    payload: response,
  };
}

export function sensorCapsImportFailed(
  error: Error,
): ImportSensorCapsFailedAction {
  return {
    type: IMPORT_SENSOR_CAPS_FAILED,
    payload: error,
  };
}

export function sensorCapFetch(): FetchSensorCapAction {
  return {
    type: FETCH_SENSOR_CAP,
  };
}

export function sensorCapFetched(
  sensorCap: SensorCapModel,
): FetchSensorCapSuccessAction {
  return {
    type: FETCH_SENSOR_CAP_SUCCESS,
    payload: sensorCap,
  };
}

export function sensorCapFetchFailed(error: Error): FetchSensorCapFailedAction {
  return {
    type: FETCH_SENSOR_CAP_FAILED,
    payload: error,
  };
}

export function sensorCapsFetch(
  query: ListSensorCapsQuery,
): FetchSensorCapsAction {
  return {
    type: FETCH_SENSOR_CAPS,
    payload: query,
  };
}

export function sensorCapsFetched(
  query: ListSensorCapsQueryModel,
  sensorCaps: SimpleSensorCapModel[],
): FetchSensorCapsSuccessAction {
  return {
    type: FETCH_SENSOR_CAPS_SUCCESS,
    payload: { query, sensorCaps },
  };
}

export function sensorCapsFetchFailed(
  error: Error,
): FetchSensorCapsFailedAction {
  return {
    type: FETCH_SENSOR_CAPS_FAILED,
    payload: error,
  };
}

export function sensorCapUpdate(): UpdateSensorCapAction {
  return {
    type: UPDATE_SENSOR_CAP,
  };
}

export function sensorCapUpdated(
  sensorCap: SensorCapModel,
): UpdateSensorCapSuccessAction {
  return {
    type: UPDATE_SENSOR_CAP_SUCCESS,
    payload: sensorCap,
  };
}

export function sensorCapUpdateFailed(
  error: Error,
): UpdateSensorCapFailedAction {
  return {
    type: UPDATE_SENSOR_CAP_FAILED,
    payload: error,
  };
}

export function sensorCapDelete(sensorCapId: string): DeleteSensorCapAction {
  return {
    type: DELETE_SENSOR_CAP,
    payload: { sensorCapId },
  };
}

export function sensorCapDeleted(
  sensorCap: SensorCapModel,
): DeleteSensorCapSuccessAction {
  return {
    type: DELETE_SENSOR_CAP_SUCCESS,
    payload: sensorCap,
  };
}

export function sensorCapDeleteFailed(
  error: Error,
): DeleteSensorCapFailedAction {
  return {
    type: DELETE_SENSOR_CAP_FAILED,
    payload: error,
  };
}

import React from 'react';
import {
  Col,
  Pagination,
  Result,
  Row,
  Typography,
  Form,
  Checkbox,
  Select,
  Tag,
  Table,
  Empty,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getPonds } from '../../duck/modules/ponds';
import { PondsSensorDataTable } from '../pond/pondsSensorDataTable';
import { MqttConnectionStatus, SensorType } from '../../model/enums';
import { functionalColor } from '../../colors';
import { pondApi } from '../../api/pondApi';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Column from 'antd/lib/table/Column';
import { Link } from 'react-router-dom';
import { SimplePondModel } from '../../model/simple';
import {
  getDayOfCultivation,
  getEstimatedShrimpSizeInPcsPerKg,
} from '../../util';
import Example from './example';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      includeDisabled?: string;
      controllerStatuses?: MqttConnectionStatus[];
    };
  };

const UnconnectedShrimpSizeTable: React.FC<Props> = ({ queryParams }) => {
  const [loading, setLoading] = React.useState(true);
  const [ponds, setPonds] = React.useState([]);

  // TODO: FIXME
  // React.useEffect(() => {
  //   let isSubscribed = true;

  //   const fetchPonds = async () => {
  //     const response = await pondApi.listPonds({
  //       limit: 1000,
  //       offset: 0,
  //       includeDisabled: false,
  //     });
  //     if (isSubscribed) {
  //       const filteredPonds = response.ponds.filter(
  //         (pond) => pond.cycle != null,
  //       );
  //       setPonds(filteredPonds);
  //       setLoading(false);
  //     }
  //   };

  //   fetchPonds().catch(console.error);

  //   return () => (isSubscribed = false);
  // }, [queryParams]);



  const renderTable = () => {
    return (
      <Table
        dataSource={ponds}
        bordered
        loading={loading}
        sticky={true}
        pagination={{ defaultPageSize: 50 }}
      >
        <Column
          title="Farm"
          render={(pond: SimplePondModel) => {
            return (
              <Link to={`/farm/${pond.id}`}>
                <Typography.Link>{pond.farm.name}</Typography.Link>
              </Link>
            );
          }}
        />
        <Column
          title="Pond"
          render={(pond: SimplePondModel) => {
            return (
              <Link to={`/pond/${pond.id}`}>
                <Typography.Link>{pond.name}</Typography.Link>
              </Link>
            );
          }}
        />

        <Column
          title="DOC"
          sorter={(p1: SimplePondModel, p2: SimplePondModel) => {
            let docP1 = 0;
            let docP2 = 0;

            if (p1.cycle && p1.cycle.startTimestamp) {
              docP1 = getDayOfCultivation(p1.cycle.startTimestamp);
            }

            if (p2.cycle && p2.cycle.startTimestamp) {
              docP2 = getDayOfCultivation(p2.cycle.startTimestamp);
            }

            return docP1 - docP2;
          }}
          render={(pond: SimplePondModel) => {
            if (pond.cycle && pond.cycle.startTimestamp) {
              return (
                <Typography.Text>
                  {getDayOfCultivation(pond.cycle.startTimestamp)}
                </Typography.Text>
              );
            }
          }}
        />

        <ColumnGroup title="Size (pcs/kg)">
          <Column
            title="Last sample"
            sorter={(p1: SimplePondModel, p2: SimplePondModel) => {
              let sizeP1 = 0;
              let sizeP2 = 0;

              if (
                p1.cycle &&
                p1.cycle.latestSampleAggregation &&
                p1.cycle.latestSampleAggregation.numberOfShrimpsPerKg
              ) {
                sizeP1 = p1.cycle.latestSampleAggregation.numberOfShrimpsPerKg;
              }

              if (
                p2.cycle &&
                p2.cycle.latestSampleAggregation &&
                p2.cycle.latestSampleAggregation.numberOfShrimpsPerKg
              ) {
                sizeP2 = p2.cycle.latestSampleAggregation.numberOfShrimpsPerKg;
              }

              return sizeP1 - sizeP2;
            }}
            render={(pond: SimplePondModel) => {
              if (
                pond.cycle &&
                pond.cycle.latestSampleAggregation &&
                pond.cycle.latestSampleAggregation.numberOfShrimpsPerKg
              ) {
                return (
                  <Tooltip title={pond.cycle.latestSampleAggregation.date}>
                    <Typography.Text>
                      {`${pond.cycle.latestSampleAggregation.numberOfShrimpsPerKg}`}
                    </Typography.Text>
                  </Tooltip>
                );
              }
              return <Typography.Text>{'-'}</Typography.Text>;
            }}
          />

          <Column
            title="Expected"
            sorter={(p1: SimplePondModel, p2: SimplePondModel) => {
              let sizeP1 = 0;
              let sizeP2 = 0;

              if (p1.cycle && p1.cycle.startTimestamp) {
                const doc = getDayOfCultivation(p1.cycle.startTimestamp);
                sizeP1 = getEstimatedShrimpSizeInPcsPerKg(doc);
              }

              if (p2.cycle && p2.cycle.startTimestamp) {
                const doc = getDayOfCultivation(p2.cycle.startTimestamp);
                sizeP2 = getEstimatedShrimpSizeInPcsPerKg(doc);
              }

              return sizeP1 - sizeP2;
            }}
            render={(pond: SimplePondModel) => {
              if (pond.cycle && pond.cycle.startTimestamp) {
                const doc = getDayOfCultivation(pond.cycle.startTimestamp);
                return (
                  <Typography.Text>
                    {`${getEstimatedShrimpSizeInPcsPerKg(doc).toFixed(0)}`}
                  </Typography.Text>
                );
              }
            }}
          />
        </ColumnGroup>

        <ColumnGroup title="Sensor data">
          <Column
            title="DO (ppm)"
            render={(pond: SimplePondModel) => {
              const doSensor = pond.sensors.find(
                (s) => s.type == SensorType.YDS,
              );
              if (
                doSensor &&
                doSensor.lastMeasurement &&
                doSensor.lastMeasurement.doPPM > 0
              ) {
                return (
                  <Tooltip title={doSensor.lastMeasurementTimestamp}>
                    <Typography.Text>
                      {`${doSensor.lastMeasurement.doPPM}`}
                    </Typography.Text>
                  </Tooltip>
                );
              }
              return <Typography.Text>{'-'}</Typography.Text>;
            }}
          />
          <Column
            title="PH"
            render={(pond: SimplePondModel) => {
              const phSensor = pond.sensors.find(
                (s) => s.type == SensorType.NDP || s.type == SensorType.SAP,
              );
              if (
                phSensor &&
                phSensor.lastMeasurement &&
                phSensor.lastMeasurement.pH > 0
              ) {
                return (
                  <Tooltip title={phSensor.lastMeasurementTimestamp}>
                    <Typography.Text>
                      {`${phSensor.lastMeasurement.pH}`}
                    </Typography.Text>
                  </Tooltip>
                );
              }
              return <Typography.Text>{'-'}</Typography.Text>;
            }}
          />
          <Column
            title="EC (A)"
            render={(pond: SimplePondModel) => {
              const energySensor = pond.sensors.find(
                (s) => s.type == SensorType.SES,
              );
              if (energySensor && energySensor.lastMeasurement) {
                return (
                  <Tooltip title={energySensor.lastMeasurementTimestamp}>
                    <Typography.Text>
                      {`${energySensor.lastMeasurement.ecInA}`}
                    </Typography.Text>
                  </Tooltip>
                );
              }
              return <Typography.Text>{'-'}</Typography.Text>;
            }}
          />
        </ColumnGroup>
      </Table>
    );
  };

  return (
    <>
      <Col>{renderTable()}</Col>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  ponds: state.ponds.list.ponds,
  loading: state.ponds.list.loading,
  error: state.ponds.list.error,
  total: state.ponds.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getPondsConnect: getPonds,
    },
    dispatch,
  );
};

export const ShrimpSizeTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedShrimpSizeTable);

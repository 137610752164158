import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { calibrationLogApi } from '../../api/calibrationLogApi';
import {
  FETCH_CALIBRATION_LOG,
  FETCH_CALIBRATION_LOG_FAILED,
  FETCH_CALIBRATION_LOG_SUCCESS,
  FETCH_CALIBRATION_LOGS,
  FETCH_CALIBRATION_LOGS_FAILED,
  FETCH_CALIBRATION_LOGS_SUCCESS,
} from '../../constants';
import {
  ListCalibrationLogsQuery,
  ListCalibrationLogsQueryModel,
} from '../../model/querys';
import { CalibrationLogsState } from '../../model/state';
import {
  calibrationLogFetch,
  calibrationLogFetched,
  calibrationLogFetchFailed,
  CalibrationLogsAction,
  calibrationLogsFetch,
  calibrationLogsFetched,
  calibrationLogsFetchFailed,
} from '../actions';

export function getCalibrationLog(id: string) {
  return async (dispatch: Dispatch<CalibrationLogsAction, {}, any>) => {
    try {
      dispatch(calibrationLogFetch());
      const calibrationLog = await calibrationLogApi.fetchCalibrationLog(id);
      dispatch(calibrationLogFetched(calibrationLog));
    } catch (e) {
      dispatch(calibrationLogFetchFailed(e));
    }
  };
}

export function getCalibrationLogs(query: ListCalibrationLogsQuery) {
  return async (dispatch: Dispatch<CalibrationLogsAction, {}, any>) => {
    try {
      dispatch(calibrationLogsFetch(query));
      const queryModel: ListCalibrationLogsQueryModel = await calibrationLogApi.listCalibrationLogs(
        query,
      );
      const calibrationLogs = queryModel.logs;
      dispatch(calibrationLogsFetched(queryModel, calibrationLogs));
    } catch (e) {
      dispatch(calibrationLogsFetchFailed(e));
    }
  };
}

export default function calibrationLogsReducer(
  state: CalibrationLogsState = {
    list: {
      loading: false,
      error: null,
      calibrationLogs: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      calibrationLog: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: CalibrationLogsAction,
): CalibrationLogsState {
  switch (action.type) {
    case FETCH_CALIBRATION_LOG:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          calibrationLog: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_CALIBRATION_LOG_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          calibrationLog: action.payload,
        },
      };
    case FETCH_CALIBRATION_LOG_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          calibrationLog: null,
        },
      };
    case FETCH_CALIBRATION_LOGS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          calibrationLogs: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_CALIBRATION_LOGS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          calibrationLogs: action.payload.calibrationLogs,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_CALIBRATION_LOGS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          calibrationLogs: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { organisationApi } from '../../api/organisationApi';

import { lcmApi } from '../../api/lcmApi';
import {
  CREATE_LCM,
  CREATE_LCM_FAILED,
  CREATE_LCM_SUCCESS,
  FETCH_LCM,
  FETCH_LCM_FAILED,
  FETCH_LCM_SUCCESS,
  FETCH_LCMS,
  FETCH_LCMS_FAILED,
  FETCH_LCMS_SUCCESS,
  UPDATE_LCM,
  UPDATE_LCM_FAILED,
  UPDATE_LCM_SUCCESS,
  REMOVE_LCM_FROM_ORGANISATION,
  REMOVE_LCM_FROM_ORGANISATION_SUCCESS,
  REMOVE_LCM_FROM_ORGANISATION_FAILED,
  INT_MAX,
  FETCH_ORGANISATION_LCMS,
  FETCH_ORGANISATION_LCMS_SUCCESS,
  FETCH_ORGANISATION_LCMS_FAILED,
} from '../../constants';
import { CreateLcmCmd, UpdateLcmCmd } from '../../model/cmds';
import { ListLcmsQuery, ListLcmsQueryModel } from '../../model/querys';
import { LcmsState } from '../../model/state';
import {
  organisationLcmRemove,
  organisationLcmRemoved,
  organisationLcmsFetch,
  organisationLcmsFetched,
  organisationLcmsFetchFailed,
  lcmCreate,
  lcmCreated,
  lcmCreateFailed,
  lcmFetch,
  lcmFetched,
  lcmFetchFailed,
  LcmsAction,
  lcmsFetch,
  lcmsFetched,
  lcmsFetchFailed,
  lcmUpdate,
  lcmUpdated,
  lcmUpdateFailed,
  gatewayFetch,
  gatewayFetched,
  gatewayLcmAdded,
  gatewayLcmAddFailed,
} from '../actions';
import { gatewayApi } from '../../api/gatewayApi';
import { GatewayStateField } from '../../model/enums';

export function createLcm(
  cmd: CreateLcmCmd,
  organisationId?: string,
  gatewayId?: string,
) {
  return async (dispatch: Dispatch<LcmsAction, {}, any>) => {
    try {
      dispatch(lcmCreate());
      let lcm = await lcmApi.createLcm(cmd);

      if (organisationId) {
        const organisation = await organisationApi.addLcmToOrganisation(
          organisationId,
          { lcmId: lcm.id },
        );
      }

      if (gatewayId) {
        await gatewayApi.addLcmToGateway(gatewayId, {
          lcmId: lcm.id,
        });

        await gatewayApi.synchronizeGateway(gatewayId, {
          stateFields: [GatewayStateField.LCM],
        });

        let gateway = await gatewayApi.fetchGateway(gatewayId);

        if (
          gateway.lcms.actual.find((l) => l.id === lcm.id) ||
          gateway.lcms.requested?.find((l) => l.id === lcm.id)
        ) {
          dispatch(gatewayLcmAdded(gateway));
        } else {
          dispatch(gatewayLcmAddFailed(new Error('Not added to Gateway')));
        }
      }

      lcm = await lcmApi.fetchLcm(lcm.id);

      dispatch(lcmCreated(lcm));
    } catch (e) {
      dispatch(lcmCreateFailed(e));
    }
  };
}

export function getLcm(id: string) {
  return async (dispatch: Dispatch<LcmsAction, {}, any>) => {
    try {
      dispatch(lcmFetch());
      const lcm = await lcmApi.fetchLcm(id);
      dispatch(lcmFetched(lcm));
    } catch (e) {
      dispatch(lcmFetchFailed(e));
    }
  };
}

export function getLcms(query: ListLcmsQuery) {
  return async (dispatch: Dispatch<LcmsAction, {}, any>) => {
    try {
      dispatch(lcmsFetch(query));
      const queryModel: ListLcmsQueryModel = await lcmApi.listLcms(query);
      const lcms = queryModel.lcms;

      dispatch(lcmsFetched(queryModel, lcms));
    } catch (e) {
      dispatch(lcmsFetchFailed(e));
    }
  };
}

export function getOrganisationLcms(organisationId: string) {
  return async (dispatch: Dispatch<LcmsAction, {}, any>) => {
    try {
      const query = {
        limit: INT_MAX,
        offset: 0,
        organisationIds: [organisationId],
      };

      dispatch(organisationLcmsFetch(query));
      const queryModel = await lcmApi.listLcms(query);
      const lcms = queryModel.lcms;

      dispatch(organisationLcmsFetched(queryModel, lcms));
    } catch (e) {
      dispatch(organisationLcmsFetchFailed(e));
    }
  };
}

export function updateLcm(id: string, cmd: UpdateLcmCmd) {
  return async (dispatch: Dispatch<LcmsAction, {}, any>) => {
    try {
      dispatch(lcmUpdate());
      const response = await lcmApi.updateLcm(id, cmd);
      dispatch(lcmUpdated(response));
      dispatch(getLcm(id));
    } catch (e) {
      dispatch(lcmUpdateFailed(e));
    }
  };
}

export function removeLcmFromOrganisation(
  organisationId: string,
  lcmId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<LcmsAction, {}, any>) => {
    try {
      dispatch(organisationLcmRemove(organisationId, lcmId));
      const sensor = await organisationApi.removeLcmFromOrganisation(
        organisationId,
        lcmId,
      );

      const organisation = await organisationApi.fetchOrganisation(
        organisationId,
      );

      dispatch(organisationLcmRemoved(organisation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(lcmUpdateFailed(e));
    }
  };
}

export default function lcmsReducer(
  state: LcmsState = {
    create: {
      loading: false,
      error: null,
      lcm: null,
    },
    list: {
      loading: false,
      error: null,
      lcms: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      lcm: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    removeFromOrganisation: {
      loading: false,
      error: null,
      success: null,
    },
    organisationLcms: {
      loading: false,
      error: null,
      lcms: [],
      total: null,
      offset: null,
      limit: null,
    },
  },
  action: LcmsAction,
): LcmsState {
  switch (action.type) {
    case CREATE_LCM:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          lcm: null,
        },
      };
    case CREATE_LCM_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          lcm: action.payload,
        },
      };
    case CREATE_LCM_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          lcm: null,
        },
      };
    case FETCH_LCM:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          lcm: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_LCM_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          lcm: action.payload,
        },
      };
    case FETCH_LCM_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          lcm: null,
        },
      };
    case UPDATE_LCM:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_LCM_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_LCM_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_LCMS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          lcms: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_LCMS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          lcms: action.payload.lcms,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_LCMS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          lcms: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case REMOVE_LCM_FROM_ORGANISATION: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: true,
          success: null,
          error: null,
        },
      };
    }

    case REMOVE_LCM_FROM_ORGANISATION_SUCCESS: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: false,
          success: true,
          error: null,
        },
      };
    }

    case REMOVE_LCM_FROM_ORGANISATION_FAILED: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: true,
          success: false,
          error: action.payload,
        },
      };
    }

    case FETCH_ORGANISATION_LCMS:
      return {
        ...state,
        organisationLcms: {
          ...state.organisationLcms,
          loading: true,
          error: null,
          lcms: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ORGANISATION_LCMS_SUCCESS:
      return {
        ...state,
        organisationLcms: {
          ...state.organisationLcms,
          loading: false,
          error: null,
          lcms: action.payload.lcms,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_ORGANISATION_LCMS_FAILED:
      return {
        ...state,
        organisationLcms: {
          ...state.organisationLcms,
          loading: true,
          error: null,
          lcms: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    default:
      return state;
  }
}

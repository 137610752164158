import {
  CREATE_TICKET,
  CREATE_TICKET_FAILED,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_SUCCESSS,
  DELETE_TICKET,
  DELETE_TICKET_FAILED,
  DELETE_TICKET_SUCCESS,
  FETCH_TICKET,
  FETCH_TICKETS,
  FETCH_TICKETS_FAILED,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKET_FAILED,
  FETCH_TICKET_SUCCESS,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILED,
  UPDATE_TICKET_SUCCESS,
} from '../../constants';
import { TicketModel } from '../../model/domain';
import { ListTicketsQueryModel, ListTicketsQuery } from '../../model/querys';
import { SimpleTicketModel } from '../../model/simple';

export interface CreateTicketAction {
  type: CREATE_TICKET;
}

export interface CreateTicketSuccessAction {
  type: CREATE_TICKET_SUCCESSS;
  payload: TicketModel;
}

export interface CreateTicketFailedAction {
  type: CREATE_TICKET_FAILED;
  payload: Error;
}

export interface FetchTicketAction {
  type: FETCH_TICKET;
}

export interface FetchTicketSuccessAction {
  type: FETCH_TICKET_SUCCESS;
  payload: TicketModel;
}

export interface FetchTicketFailedAction {
  type: FETCH_TICKET_FAILED;
  payload: Error;
}

export interface FetchTicketsAction {
  type: FETCH_TICKETS;
  payload: ListTicketsQuery;
}

export interface FetchTicketsSuccessAction {
  type: FETCH_TICKETS_SUCCESS;
  payload: {
    query: ListTicketsQueryModel;
    tickets: SimpleTicketModel[];
  };
}

export interface FetchTicketsFailedAction {
  type: FETCH_TICKETS_FAILED;
  payload: Error;
}

export interface UpdateTicketAction {
  type: UPDATE_TICKET;
}

export interface UpdateTicketSuccessAction {
  type: UPDATE_TICKET_SUCCESS;
  payload: TicketModel;
}

export interface UpdateTicketFailedAction {
  type: UPDATE_TICKET_FAILED;
  payload: Error;
}

export interface DeleteTicketAction {
  type: DELETE_TICKET;
}

export interface DeleteTicketSuccessAction {
  type: DELETE_TICKET_SUCCESS;
  payload: TicketModel;
}

export interface DeleteTicketFailedAction {
  type: DELETE_TICKET_FAILED;
  payload: Error;
}

export type TicketCreateAction =
  | CreateTicketAction
  | CreateTicketSuccessAction
  | CreateTicketFailedAction;
export type TicketUpdateAction =
  | UpdateTicketAction
  | UpdateTicketSuccessAction
  | UpdateTicketFailedAction;
export type TicketFetchAction =
  | FetchTicketAction
  | FetchTicketSuccessAction
  | FetchTicketFailedAction;
export type TicketsFetchAction =
  | FetchTicketsAction
  | FetchTicketsSuccessAction
  | FetchTicketsFailedAction;
export type TicketDeleteAction =
  | DeleteTicketAction
  | DeleteTicketSuccessAction
  | DeleteTicketFailedAction;
export type TicketsAction =
  | TicketCreateAction
  | TicketUpdateAction
  | TicketDeleteAction
  | TicketFetchAction
  | TicketsFetchAction;

export function ticketCreate(): CreateTicketAction {
  return {
    type: CREATE_TICKET,
  };
}

export function ticketCreated(ticket: TicketModel): CreateTicketSuccessAction {
  return {
    type: CREATE_TICKET_SUCCESS,
    payload: ticket,
  };
}

export function ticketCreateFailed(error: Error): CreateTicketFailedAction {
  return {
    type: CREATE_TICKET_FAILED,
    payload: error,
  };
}

export function ticketFetch(): FetchTicketAction {
  return {
    type: FETCH_TICKET,
  };
}

export function ticketFetched(ticket: TicketModel): FetchTicketSuccessAction {
  return {
    type: FETCH_TICKET_SUCCESS,
    payload: ticket,
  };
}

export function ticketFetchFailed(error: Error): FetchTicketFailedAction {
  return {
    type: FETCH_TICKET_FAILED,
    payload: error,
  };
}

export function ticketsFetch(query: ListTicketsQuery): FetchTicketsAction {
  return {
    type: FETCH_TICKETS,
    payload: query,
  };
}

export function ticketsFetched(
  query: ListTicketsQueryModel,
  tickets: SimpleTicketModel[],
): FetchTicketsSuccessAction {
  return {
    type: FETCH_TICKETS_SUCCESS,
    payload: { query, tickets },
  };
}

export function ticketsFetchFailed(error: Error): FetchTicketFailedAction {
  return {
    type: FETCH_TICKET_FAILED,
    payload: error,
  };
}

export function ticketUpdate(): UpdateTicketAction {
  return {
    type: UPDATE_TICKET,
  };
}

export function ticketUpdated(ticket: TicketModel): UpdateTicketSuccessAction {
  return {
    type: UPDATE_TICKET_SUCCESS,
    payload: ticket,
  };
}

export function ticketUpdateFailed(error: Error): UpdateTicketFailedAction {
  return {
    type: UPDATE_TICKET_FAILED,
    payload: error,
  };
}

export function ticketDelete(): DeleteTicketAction {
  return {
    type: DELETE_TICKET,
  };
}

export function ticketDeleted(ticket: TicketModel): DeleteTicketSuccessAction {
  return {
    type: DELETE_TICKET_SUCCESS,
    payload: ticket,
  };
}

export function ticketDeleteFailed(error: Error): DeleteTicketFailedAction {
  return {
    type: DELETE_TICKET_FAILED,
    payload: error,
  };
}

import React from 'react';
import { Typography, Tag, Tooltip, Badge, Input } from 'antd';
import { Link } from 'react-router-dom';
import { SimpleAnnouncementModel } from '../../model/simple';
import { functionalColor } from '../../colors';
import { AnnouncementType } from '../../model/enums';
import moment from 'moment';
import { AnnouncementMessage } from '../../model/domain';

export const renderStatusBadge = (announcement: SimpleAnnouncementModel) => {
  if (announcement.deleted) {
    return <Badge color={functionalColor.error} text="Deleted" />;
  }
  if (!announcement.valid) {
    return <Badge color={functionalColor.error} text="Invalid" />;
  }
  if (moment(announcement.notShowAfter).isBefore(moment())) {
    return <Badge color={functionalColor.error} text="Outdated" />;
  }
  if (moment(announcement.notShowBefore).isAfter(moment())) {
    return <Badge color={functionalColor.warning} text="Upcoming" />;
  }

  return <Badge color={functionalColor.success} text="Valid" />;
};

export const renderTypeTag = (announcement: SimpleAnnouncementModel) => {
  switch (announcement.type) {
    case AnnouncementType.INFO:
      return <Tag color={functionalColor.link}>{'INFO'}</Tag>;
    case AnnouncementType.MAINTENANCE:
      return <Tag color={functionalColor.warning}>{'MAINTENANCE'}</Tag>;
    default:
      return <Tag color={functionalColor.primaryText}>{announcement.type}</Tag>;
  }
};

export const renderAnnouncmentDetailLink = (
  announcement: SimpleAnnouncementModel,
) => (
  <Link key={announcement.id} to={`/announcements/${announcement.id}`}>
    {announcement.id}
  </Link>
);

export const renderStartingTooltip = (
  announcement: SimpleAnnouncementModel,
) => (
  <Tooltip title={`${moment(announcement.notShowBefore).fromNow()}`}>
    <Typography>{`${moment(announcement.notShowBefore).format(
      'DD/MM/YYYY - HH:mm',
    )}`}</Typography>
  </Tooltip>
);

export const renderEndingTooltip = (announcement: SimpleAnnouncementModel) => (
  <Tooltip title={`${moment(announcement.notShowAfter).fromNow()}`}>
    <Typography>{`${moment(announcement.notShowAfter).format(
      'DD/MM/YYYY - HH:mm',
    )}`}</Typography>
  </Tooltip>
);

export const renderLastChageTooltip = (
  announcement: SimpleAnnouncementModel,
) => (
  <Tooltip
    title={`${moment(announcement.timestamp).format('DD/MM/YYYY - HH:mm')}`}
  >
    <Typography>{`${moment(announcement.timestamp).fromNow()}`}</Typography>
  </Tooltip>
);

export const renderAnnouncementMessage = (
  announcement: AnnouncementMessage,
) => (
  <div>
    <input style={{ width: '100%' }} disabled value={announcement.title} />
    <br />
    <br />
    <textarea
      rows={10}
      style={{ width: '100%' }}
      disabled
      value={announcement.body}
    />
  </div>
);

import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import _ from 'lodash';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';

import { UpdateControllerCmd, UpdateSocketOffsetsCmd } from '../../model/cmds';
import {
  ControllerModel,
  SocketOffsets,
  StateFieldModel,
} from '../../model/domain';
import { usePrevious } from '../_util/hook';
import { functionalColor } from '../../colors';

const { Option } = Select;

type Props = {
  socketOffsets?: StateFieldModel<SocketOffsets>;
  onUpdate: (cmd: UpdateControllerCmd) => void;
  loading?: boolean;
  updating: boolean;
};

export const ControllerSocketOffsetsForm: React.FC<Props> = ({
  socketOffsets,
  loading,
  updating,
  onUpdate,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (socketOffsets) {
      let analog1, analog2, analog3, analog4;
      if (socketOffsets.inSync) {
        analog1 = socketOffsets.actual.analog1;
        analog2 = socketOffsets.actual.analog2;
        analog3 = socketOffsets.actual.analog3;
        analog4 = socketOffsets.actual.analog4;
      } else {
        analog1 = socketOffsets.requested?.analog1;
        analog2 = socketOffsets.requested?.analog2;
        analog3 = socketOffsets.requested?.analog3;
        analog4 = socketOffsets.requested?.analog4;
      }

      form.setFieldsValue({
        analog1,
        analog2,
        analog3,
        analog4,
      });
    }
  }, [socketOffsets]);

  const onSubmit = (values: Store) => {
    const { analog1, analog2, analog3, analog4 } = values;
    const offsetCmd: UpdateSocketOffsetsCmd = {};

    if (analog1 !== socketOffsets?.actual?.analog1) {
      offsetCmd.analog1 = analog1;
    }

    if (analog2 !== socketOffsets?.actual?.analog2) {
      offsetCmd.analog2 = analog2;
    }

    if (analog3 !== socketOffsets?.actual?.analog3) {
      offsetCmd.analog3 = analog3;
    }

    if (analog4 !== socketOffsets?.actual?.analog4) {
      offsetCmd.analog4 = analog4;
    }
    const cmd: UpdateControllerCmd = {};

    if (Object.keys(offsetCmd).length > 0) {
      cmd.socketOffsets = offsetCmd;
    } else {
      // nothing changed
      cmd.socketOffsets = {
        analog1: socketOffsets?.actual.analog1,
        analog2: socketOffsets?.actual.analog2,
        analog3: socketOffsets?.actual.analog3,
        analog4: socketOffsets?.actual.analog4,
      };
    }

    console.log(cmd);

    onUpdate(cmd);
  };

  const renderCardContent = () => {
    if (socketOffsets && !_.isEmpty(socketOffsets)) {
      return (
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="Analog 1" name="analog1">
            <Input
              placeholder="Offset for DeviceSocket:ANALOG_1"
              type="number"
            />
          </Form.Item>

          <Form.Item label="Analog 2" name="analog2">
            <Input
              placeholder="Offset for DeviceSocket:ANALOG_2"
              type="number"
            />
          </Form.Item>

          <Form.Item label="Analog 3" name="analog3">
            <Input
              placeholder="Offset for DeviceSocket:ANALOG_3"
              type="number"
            />
          </Form.Item>

          <Form.Item label="Analog 4" name="analog4">
            <Input
              placeholder="Offset for DeviceSocket:ANALOG_4"
              type="number"
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={updating}>
                Update Offsets
              </Button>
            </Space>
          </Form.Item>
        </Form>
      );
    }
    return (
      <div>
        <Form form={form} /> <Empty description={<span>No Data</span>} />
      </div>
    );
  };

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography>{'Socket Offsets'}</Typography>
          {socketOffsets &&
            (socketOffsets.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing...`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            ))}
        </Row>
      }
      loading={loading}
      style={{ height: '100%' }}
    >
      {renderCardContent()}
    </Card>
  );
};

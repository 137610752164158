import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateAeratorCmd, UpdateAeratorCmd } from '../model/cmds';
import { AeratorModel } from '../model/domain';
import { ListAeratorsQuery, ListAeratorsQueryModel } from '../model/querys';

export class AeratorApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createAerator = this.createAerator.bind(this);
    this.getAerator = this.getAerator.bind(this);
    this.listAerators = this.listAerators.bind(this);
    this.updateAerator = this.updateAerator.bind(this);
  }

  public createAerator(cmd: CreateAeratorCmd): Promise<AeratorModel> {
    return this.post<
      AeratorModel,
      CreateAeratorCmd,
      AxiosResponse<AeratorModel>
    >(`/v1/aerators`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getAerator(aeratorId: string): Promise<AeratorModel> {
    return this.get<AeratorModel, AxiosResponse<AeratorModel>>(
      `/v1/aerators/${aeratorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listAerators(
    cmd?: ListAeratorsQuery,
  ): Promise<ListAeratorsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListAeratorsQueryModel,
      AxiosResponse<ListAeratorsQueryModel>
    >('/v1/aerators', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateAerator(
    aeratorId: string,
    cmd: UpdateAeratorCmd,
  ): Promise<AeratorModel> {
    return this.patch<
      AeratorModel,
      UpdateAeratorCmd,
      AxiosResponse<AeratorModel>
    >(`/v1/aerators/${aeratorId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const aeratorApi = new AeratorApi();

import {
  Button,
  Card,
  Descriptions,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import _ from 'lodash';
import React from 'react';
import { SimpleGatewayModel } from '../../model/simple';

type Props = {
  originalGatewayId: string;
  onUpdate: (replacingGatewayId: string, targetGatewayId: string) => void;
  updating?: boolean;
  gatewayOptions: SimpleGatewayModel[]
};

export const SwapCard: React.FC<Props> = ({
  originalGatewayId,
  onUpdate,
  updating,
  gatewayOptions,
}) => {
  const [form] = Form.useForm();

  const onSubmit = (values: Store) => {
    const { targetGatewayId } = values;

    onUpdate(originalGatewayId, targetGatewayId);
  };

  const renderCardContent = () => {
    if (originalGatewayId && !_.isEmpty(originalGatewayId)) {
      return (
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            label="Target Gateway"
            name="targetGatewayId"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select Gateway" allowClear
              options={
                gatewayOptions.map((option) => {
                  return {
                    label: `${option.id} - ${option.name
                      }`,
                    value: option.id,
                  };
                }) || []
              }
            >

            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={updating}>
                Swap gateways
              </Button>
            </Space>
          </Form.Item>
        </Form>
      );
    }
    return <Empty description={<span>No Data </span>} />;
  };

  const renderCardTitle = () => {
    return (
      <Row justify="space-between" align="middle">
        <Typography>{'Swap Gateway'}</Typography>
      </Row>
    );
  };

  return (
    <Card
      title={renderCardTitle()}
      loading={updating}
      style={{ height: '100%' }}
    >
      {renderCardContent()}
    </Card>
  );
};

import React from 'react';
import { Form, Input, Button, Space, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { FeedTableModel } from '../../model/domain';
import { UpdateFeedTableCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';

type Props = {
  feedTable: FeedTableModel;
  onUpdate: (cmd: UpdateFeedTableCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const FeedTableDataForm: React.FC<Props> = ({
  feedTable,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({ ...feedTable });
  }, [feedTable]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Feed table updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating feed table',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { name } = values;
    const cmd: UpdateFeedTableCmd = {};
    if (name !== feedTable.name) {
      cmd.name = name;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && feedTable.name) {
      cmd.name = feedTable.name;
    }

    if (Object.keys(cmd).length > 0) {
      onUpdate(cmd);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        name: feedTable?.name,
      }}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Name of the feed table" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Feed Table
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

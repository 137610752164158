import React from 'react';
import {
  Table,
  Tag,
  Result,
  Typography,
  Tooltip,
  Pagination,
  Col,
  Row,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getNotifications } from '../../duck/modules/notifications';
import { functionalColor } from '../../colors';
import Column from 'antd/lib/table/Column';
import { NotificationStatus } from '../../model/enums';
import moment from 'moment';
import { NotificationModel } from '../../model/domain';
import { ListNotificationsQuery } from '../../model/querys';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    reload: boolean;
    onReloaded: () => void;
    pondId?: string;
    accountId?: string;
    gatewayId?: string;
    pageSize?: number;
    hideType?: boolean;
    hideChannel?: boolean;
    hideOrganisation?: boolean;
    hideGateway?: boolean;
    hideAccount?: boolean;
    hideFarm?: boolean;
    hidePond?: boolean;
    hideStatus?: boolean;
    hideUpdated?: boolean;
    queryParams?: {
      page?: number;
      pageSize?: number;
    };
  };

const UnconnectedNotificationsTable: React.FC<Props> = ({
  notifications,
  loading,
  error,
  total,
  reload,
  onReloaded,
  getNotificationsConnect,
  pondId,
  accountId,
  gatewayId,
  pageSize,
  hideType,
  hideChannel,
  hideOrganisation,
  hideGateway,
  hideAccount,
  hideFarm,
  hidePond,
  hideStatus,
  hideUpdated,
  queryParams,
}) => {
  const [limit, setLimit] = React.useState(
    queryParams?.pageSize || pageSize || 25,
  );
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  React.useEffect(() => {
    const cmd: ListNotificationsQuery = { limit, offset };
    if (pondId) {
      cmd.pondIds = [pondId];
    }
    if (accountId) {
      cmd.accountIds = [accountId];
    }
    if (gatewayId) {
      cmd.gatewayIds = [gatewayId];
    }
    getNotificationsConnect(cmd);
    onReloaded();
  }, [offset, limit, reload, pondId, accountId, gatewayId, pageSize]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load notifications"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return (
    <Col>
      <Table
        pagination={false}
        loading={loading}
        rowKey={(item) => item.id}
        dataSource={notifications}
        scroll={{ x: true }}
        bordered
      >
        {!hideType && (
          <Column
            align="center"
            title="Type"
            render={(notification: NotificationModel) => {
              return <Typography>{notification.type}</Typography>;
            }}
          />
        )}

        {!hideChannel && (
          <Column
            align="center"
            title="Channel"
            render={(notification: NotificationModel) => {
              return <Typography>{notification.channel}</Typography>;
            }}
          />
        )}

        {!hideOrganisation && (
          <Column
            align="center"
            title="Organisation"
            render={(notification: NotificationModel) => {
              if (notification.organisation) {
                return (
                  <Link to={`/organisations/${notification.organisation.id}`}>
                    {notification.organisation.name ||
                      notification.organisation.id}
                  </Link>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        )}

        {!hideAccount && (
          <Column
            align="center"
            title="Account"
            render={(notification: NotificationModel) => {
              if (notification.account) {
                return (
                  <Link to={`/accounts/${notification.account.id}`}>
                    {notification.account.name || notification.account.id}
                  </Link>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        )}

        {!hideGateway && (
          <Column
            align="center"
            title="Gateway"
            render={(notification: NotificationModel) => {
              if (notification.gateway) {
                return (
                  <Link to={`/gateways/${notification.gateway.id}`}>
                    {notification.gateway.name || notification.gateway.id}
                  </Link>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        )}

        {!hideFarm && (
          <Column
            align="center"
            title="Farm"
            render={(notification: NotificationModel) => {
              if (notification.farm) {
                return (
                  <Link to={`/farms/${notification.farm.id}`}>
                    {notification.farm.name || notification.farm.id}
                  </Link>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        )}

        {!hidePond && (
          <Column
            align="center"
            title="Pond"
            render={(notification: NotificationModel) => {
              if (notification.pond) {
                return (
                  <Link to={`/farms/${notification.pond.id}`}>
                    {notification.pond.name || notification.pond.id}
                  </Link>
                );
              }
              return <Typography>{'-'}</Typography>;
            }}
          />
        )}

        {!hideStatus && (
          <Column
            align="center"
            title="Status"
            render={(notification: NotificationModel) => {
              let color = functionalColor.link;
              if (
                notification.status === NotificationStatus.SENT ||
                notification.status === NotificationStatus.ACKNOWLEDGED
              ) {
                color = functionalColor.success;
              } else if (notification.status === NotificationStatus.FAILED) {
                color = functionalColor.error;
              } else if (
                notification.status === NotificationStatus.PARTIAL_SENT
              ) {
                //color = functionalColor.warning
                return <Tag color={color}>{'PARTIAL'}</Tag>;
              }
              return <Tag color={color}>{notification.status}</Tag>;
            }}
          />
        )}

        {!hideUpdated && (
          <Column
            align="center"
            title="Updated"
            render={(notification: NotificationModel) => {
              return (
                <Tooltip title={notification.updateTimestamp} placement="left">
                  <span>
                    <Typography>
                      {moment(notification.updateTimestamp).fromNow()}
                    </Typography>
                  </span>
                </Tooltip>
              );
            }}
          />
        )}
      </Table>
      {total != null && (
        <Row justify="end" style={{ marginTop: 10 }}>
          <Pagination
            total={total}
            defaultCurrent={page || 1}
            pageSize={currentPageSize}
            pageSizeOptions={['5', '10', '25', '50', '100', '200']}
            responsive={true}
            showSizeChanger={true}
            showTotal={(total) => `Total: ${total} items`}
            onChange={(page, pageSize) => {
              if (page && pageSize) {
                handleChange(pageSize, page);
              }
            }}
            onShowSizeChange={(_, pageSize) => {
              handleChange(pageSize, page);
            }}
          />
        </Row>
      )}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  notifications: state.notifications.list.notifications,
  loading: state.notifications.list.loading,
  error: state.notifications.list.error,
  total: state.notifications.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getNotificationsConnect: getNotifications,
    },
    dispatch,
  );
};

export const NotificationsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedNotificationsTable);

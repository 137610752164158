import React, { useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Space,
  Row,
  Col,
  notification,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { AccountModel } from '../../model/domain';
import { UpdateAccountCmd } from '../../model/cmds';
import { generatePassword } from '../_util/password';
import { noWhitespacesRule } from '../_util/validation';
import { sanitizeFormValue } from '../_util/form';
import { usePrevious } from '../_util/hook';
import { TimeZone } from '../../model/enums';
import {
  nameRule,
  emailRule,
  usernameRule,
  passwordRule,
  phoneNumberRule,
} from '../_util/validation';

const { Option } = Select;

type Props = {
  account: AccountModel;
  onSave: (cmd: UpdateAccountCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const AccountForm: React.FC<Props> = ({
  account,
  updating,
  updateSuccess,
  updateError,
  onSave,
}) => {
  const [form] = Form.useForm();
  const [newPassword, setNewPassword] = React.useState('');

  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({ ...account });
  }, [account]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Account updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating account',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const {
      email,
      username,
      gender,
      phoneNumber,
      name,
      crmContactId,
      newPassword,
      language,
      timeZone,
    } = values;
    const cmd: UpdateAccountCmd = {};

    if (newPassword) {
      cmd.password = newPassword;
    }

    if (email !== account.email) {
      cmd.email = email;
    }

    if (username !== account.username) {
      cmd.username = username;
    }

    if (gender !== account.gender) {
      cmd.gender = gender;
    }

    if (phoneNumber !== account.phoneNumber) {
      cmd.phoneNumber = sanitizeFormValue(phoneNumber);
    }

    if (name !== account.name) {
      cmd.name = sanitizeFormValue(name);
    }

    if (crmContactId !== account.crmContactId) {
      cmd.crmContactId = sanitizeFormValue(crmContactId);
    }

    if (language !== account.language) {
      cmd.language = language;
    }

    if (timeZone !== account.timeZone) {
      cmd.timeZone = timeZone;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0) {
      cmd.name = account.name;
      cmd.email = account.email;
      cmd.username = account.username;
      cmd.phoneNumber = account.phoneNumber;
      cmd.language = account.language;
      cmd.timeZone = account.timeZone;
      cmd.gender = account.gender;
      cmd.crmContactId = account.crmContactId;
    }

    onSave(cmd);
  };

  const onNewPasswordChanged = (newPassword: string) => {
    setNewPassword(newPassword);
    form.setFieldsValue({ newPassword });
  };

  return (
    <Form
      form={form}
      initialValues={{
        email: account.email,
        username: account.username,
        newPassword,
        gender: account.gender,
        language: account.language,
        phoneNumber: account.phoneNumber,
        name: account.name,
        line: account.line,
        crmContactId: account.crmContactId,
        timeZone: account.timeZone,
      }}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item label="Name" name="name" rules={[nameRule]}>
        <Input placeholder="Who uses this account?" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[emailRule, { required: true }]}
      >
        <Input placeholder="john.doe@gmx.de" />
      </Form.Item>

      <Form.Item
        label="Username"
        name="username"
        rules={[usernameRule, noWhitespacesRule, { required: true }]}
      >
        <Input placeholder="e.g.: johndoe" />
      </Form.Item>

      <Form.Item label="New Password" name="newPassword" rules={[passwordRule]}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={16}>
            <Input
              placeholder={'Change password?'}
              onChange={(e) => onNewPasswordChanged(e.target.value)}
              value={newPassword ? newPassword : undefined}
            />
          </Col>
          <Col xs={24} sm={8}>
            <Button
              style={{ width: '100%' }}
              onClick={() => onNewPasswordChanged(generatePassword(8))}
            >
              Generate
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item label="Phone no." name="phoneNumber">
        <Input placeholder="+491234567890" />
      </Form.Item>

      <Form.Item name="language" label="Language">
        <Select placeholder="Select prefered language" allowClear>
          <Option value="ENGLISH">English</Option>
          <Option value="THAI">Thai</Option>
        </Select>
      </Form.Item>

      <Form.Item name="timeZone" label="Time zone">
        <Select placeholder="Select time zone" allowClear>
          <Option value={TimeZone.THAILAND}>{'Asia/Bangkok'}</Option>
          <Option value={TimeZone.GERMANY}>{'Europe/Berlin'}</Option>
          <Option value={TimeZone.UTC}>{'UTC'}</Option>
          <Option value={TimeZone.INDONESIA_WESTERN}>
            {'Asia/Jakarta'}
          </Option>
          <Option value={TimeZone.INDONESIA_CENTRAL}>
            {'Asia/Makassar'}
          </Option>
          <Option value={TimeZone.INDONESIA_EASTERN}>
            {'Asia/Jayapura'}
          </Option>
          <Option value={TimeZone.JAPAN}>{'Asia/Tokyo)'}</Option>
          <Option value={TimeZone.MALAYSIA}>{'Asia/Kuala_Lumpur'}</Option>
          <Option value={TimeZone.SINGAPORE}>{'Asia/Singapore'}</Option>
          <Option value={TimeZone.VIETNAM}>{'Asia/Ho_Chi_Minh'}</Option>
        </Select>
      </Form.Item>

      <Form.Item name="gender" label="Gender">
        <Select placeholder="Select gender" allowClear>
          <Option value="MALE">Male</Option>
          <Option value="FEMALE">Female</Option>
        </Select>
      </Form.Item>

      <Form.Item label="CRM ContactId" name="crmContactId">
        <Input placeholder={'Add ContactId from the CRM-System'} />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Account
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import {
  CREATE_LCM,
  CREATE_LCM_FAILED,
  CREATE_LCM_SUCCESS,
  FETCH_LCM,
  FETCH_LCMS,
  FETCH_LCMS_FAILED,
  FETCH_LCMS_SUCCESS,
  FETCH_LCM_FAILED,
  FETCH_LCM_SUCCESS,
  UPDATE_LCM,
  UPDATE_LCM_SUCCESS,
  UPDATE_LCM_FAILED,
  ADD_LCM_TO_ORGANISATION,
  ADD_LCM_TO_ORGANISATION_SUCCESS,
  ADD_LCM_TO_ORGANISATION_FAILED,
  REMOVE_LCM_FROM_ORGANISATION,
  REMOVE_LCM_FROM_ORGANISATION_SUCCESS,
  REMOVE_LCM_FROM_ORGANISATION_FAILED,
  FETCH_ORGANISATION_LCMS,
  FETCH_ORGANISATION_LCMS_SUCCESS,
  FETCH_ORGANISATION_LCMS_FAILED,
} from '../../constants';
import { OrganisationModel, LcmModel } from '../../model/domain';
import { SimpleLcmModel } from '../../model/simple';
import { ListLcmsQuery, ListLcmsQueryModel } from '../../model/querys';

// LCMS
export interface CreateLcmAction {
  type: CREATE_LCM;
}

export interface CreateLcmSuccessAction {
  type: CREATE_LCM_SUCCESS;
  payload: LcmModel;
}

export interface CreateLcmFailedAction {
  type: CREATE_LCM_FAILED;
  payload: Error;
}

export interface FetchLcmAction {
  type: FETCH_LCM;
}

export interface FetchLcmSuccessAction {
  type: FETCH_LCM_SUCCESS;
  payload: LcmModel;
}

export interface FetchLcmFailedAction {
  type: FETCH_LCM_FAILED;
  payload: Error;
}

export interface FetchLcmsAction {
  type: FETCH_LCMS;
  payload: ListLcmsQuery;
}

export interface FetchLcmsSuccessAction {
  type: FETCH_LCMS_SUCCESS;
  payload: {
    query: ListLcmsQueryModel;
    lcms: SimpleLcmModel[];
  };
}

export interface FetchLcmsFailedAction {
  type: FETCH_LCMS_FAILED;
  payload: Error;
}

export interface FetchOrganisationLcmsAction {
  type: FETCH_ORGANISATION_LCMS;
  payload: ListLcmsQuery;
}

export interface FetchOrganisationLcmsSuccessAction {
  type: FETCH_ORGANISATION_LCMS_SUCCESS;
  payload: {
    query: ListLcmsQueryModel;
    lcms: SimpleLcmModel[];
  };
}

export interface FetchOrganisationLcmsFailedAction {
  type: FETCH_ORGANISATION_LCMS_FAILED;
  payload: Error;
}

export interface UpdateLcmAction {
  type: UPDATE_LCM;
}

export interface UpdateLcmSuccessAction {
  type: UPDATE_LCM_SUCCESS;
  payload: LcmModel;
}

export interface UpdateLcmFailedAction {
  type: UPDATE_LCM_FAILED;
  payload: Error;
}

export interface AddLcmToOrganisationAction {
  type: ADD_LCM_TO_ORGANISATION;
  organisationId: string;
  lcmId: string;
}

export interface AddLcmToOrganisationSuccessAction {
  type: ADD_LCM_TO_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface AddLcmToOrganisationFailedAction {
  type: ADD_LCM_TO_ORGANISATION_FAILED;
  payload: Error;
}

export interface RemoveLcmFromOrganisationAction {
  type: REMOVE_LCM_FROM_ORGANISATION;
  organisationId: string;
  lcmId: string;
}

export interface RemoveLcmFromOrganisationSuccessAction {
  type: REMOVE_LCM_FROM_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface RemoveLcmFromOrganisationFailedAction {
  type: REMOVE_LCM_FROM_ORGANISATION_FAILED;
  payload: Error;
}

export type OrganisationAddLcmAction =
  | AddLcmToOrganisationAction
  | AddLcmToOrganisationSuccessAction
  | AddLcmToOrganisationFailedAction;
export type OrganisationRemoveLcmAction =
  | RemoveLcmFromOrganisationAction
  | RemoveLcmFromOrganisationSuccessAction
  | RemoveLcmFromOrganisationFailedAction;
export type LcmUpdateAction =
  | UpdateLcmAction
  | UpdateLcmSuccessAction
  | UpdateLcmFailedAction;
export type LcmCreateAction =
  | CreateLcmAction
  | CreateLcmSuccessAction
  | CreateLcmFailedAction;
export type LcmFetchAction =
  | FetchLcmAction
  | FetchLcmSuccessAction
  | FetchLcmFailedAction;
export type LcmsFetchAction =
  | FetchLcmsAction
  | FetchLcmsSuccessAction
  | FetchLcmsFailedAction;
export type OrganisationLcmsFetchAction =
  | FetchOrganisationLcmsAction
  | FetchOrganisationLcmsSuccessAction
  | FetchOrganisationLcmsFailedAction;
export type LcmsAction =
  | LcmCreateAction
  | LcmFetchAction
  | LcmsFetchAction
  | LcmUpdateAction
  | OrganisationAddLcmAction
  | OrganisationRemoveLcmAction
  | OrganisationLcmsFetchAction;

// Action creators
export function lcmCreate(): CreateLcmAction {
  return {
    type: CREATE_LCM,
  };
}

export function lcmCreated(lcm: LcmModel): CreateLcmSuccessAction {
  return {
    type: CREATE_LCM_SUCCESS,
    payload: lcm,
  };
}

export function lcmCreateFailed(error: Error): CreateLcmFailedAction {
  return {
    type: CREATE_LCM_FAILED,
    payload: error,
  };
}

export function lcmFetch(): FetchLcmAction {
  return {
    type: FETCH_LCM,
  };
}

export function lcmFetched(lcm: LcmModel): FetchLcmSuccessAction {
  return {
    type: FETCH_LCM_SUCCESS,
    payload: lcm,
  };
}

export function lcmFetchFailed(error: Error): FetchLcmFailedAction {
  return {
    type: FETCH_LCM_FAILED,
    payload: error,
  };
}

export function lcmsFetch(query: ListLcmsQuery): FetchLcmsAction {
  return {
    type: FETCH_LCMS,
    payload: query,
  };
}

export function lcmsFetched(
  query: ListLcmsQueryModel,
  lcms: SimpleLcmModel[],
): FetchLcmsSuccessAction {
  return {
    type: FETCH_LCMS_SUCCESS,
    payload: { query, lcms },
  };
}

export function lcmsFetchFailed(error: Error): FetchLcmsFailedAction {
  return {
    type: FETCH_LCMS_FAILED,
    payload: error,
  };
}

export function lcmUpdate(): UpdateLcmAction {
  return {
    type: UPDATE_LCM,
  };
}

export function lcmUpdated(lcm: LcmModel): UpdateLcmSuccessAction {
  return {
    type: UPDATE_LCM_SUCCESS,
    payload: lcm,
  };
}

export function lcmUpdateFailed(error: Error): UpdateLcmFailedAction {
  return {
    type: UPDATE_LCM_FAILED,
    payload: error,
  };
}

export function organisationLcmAdd(
  organisationId: string,
  lcmId: string,
): AddLcmToOrganisationAction {
  return {
    type: ADD_LCM_TO_ORGANISATION,
    organisationId,
    lcmId,
  };
}

export function organisationLcmAdded(
  organisation: OrganisationModel,
): AddLcmToOrganisationSuccessAction {
  return {
    type: ADD_LCM_TO_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationLcmAddFailed(
  error: Error,
): AddLcmToOrganisationFailedAction {
  return {
    type: ADD_LCM_TO_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationLcmRemove(
  organisationId: string,
  lcmId: string,
): RemoveLcmFromOrganisationAction {
  return {
    type: REMOVE_LCM_FROM_ORGANISATION,
    organisationId,
    lcmId,
  };
}

export function organisationLcmRemoved(
  organisation: OrganisationModel,
): RemoveLcmFromOrganisationSuccessAction {
  return {
    type: REMOVE_LCM_FROM_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationLcmRemoveFailed(
  error: Error,
): RemoveLcmFromOrganisationFailedAction {
  return {
    type: REMOVE_LCM_FROM_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationLcmsFetch(
  query: ListLcmsQuery,
): FetchOrganisationLcmsAction {
  return {
    type: FETCH_ORGANISATION_LCMS,
    payload: query,
  };
}

export function organisationLcmsFetched(
  query: ListLcmsQueryModel,
  lcms: SimpleLcmModel[],
): FetchOrganisationLcmsSuccessAction {
  return {
    type: FETCH_ORGANISATION_LCMS_SUCCESS,
    payload: { query, lcms },
  };
}

export function organisationLcmsFetchFailed(
  error: Error,
): FetchOrganisationLcmsFailedAction {
  return {
    type: FETCH_ORGANISATION_LCMS_FAILED,
    payload: error,
  };
}

import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal, Tabs } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { SmartSensorsResponsiveTable } from '../smartSensor/smartSensorsResponsiveTable';
import { CreateSmartSensorForm } from '../smartSensor/createSmartSensorForm';
import { SmartRelaysResponsiveTable } from '../smartRelay/smartRelaysResponsiveTable';
import { CreateSmartRelayForm } from '../smartRelay/createSmartRelayForm';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedSmartDevices: React.FC<Props> = ({ location }) => {
  const [isSensorModalVisible, setIsSensorModalVisible] = useState(false);
  const [triggerSensorReload, setSensorTriggerReload] = useState(false);

  const [isRelayModalVisible, setIsRelayModalVisible] = useState(false);
  const [triggerRelayReload, setRelayTriggerReload] = useState(false);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const handleTabChanged = (activeKey: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const pathName = window.location.pathname;
    const searchParams = new URLSearchParams();
    searchParams.set('tab', activeKey);
    const newUrl = `${protocol}//${host}${pathName}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setSensorTriggerReload(true);
    setRelayTriggerReload(true);
  };
  const defaultTab = queryParams['tab']?.toString() || 'general';

  return (
    <Layout>
      <Content>
        <Tabs defaultActiveKey={defaultTab} onChange={handleTabChanged}>
          <TabPane tab="Smart Sensors" key="sensors">
            <Modal
              title="Create smart sensor"
              visible={isSensorModalVisible}
              onCancel={() => {
                setIsSensorModalVisible(false);
              }}
              footer={null}
            >
              <CreateSmartSensorForm
                onCreated={() => {
                  setSensorTriggerReload(true);
                  setIsSensorModalVisible(false);
                }}
              />
            </Modal>

            <SmartSensorsResponsiveTable
              reload={triggerSensorReload}
              onReloaded={() => setSensorTriggerReload(false)}
              queryParams={queryParams}
            />

            {!isSensorModalVisible && (
              <Fab
                style={{ bottom: 0, right: 0 }}
                mainButtonStyles={{
                  backgroundColor: getNavbarColor(),
                  color: getNavbarTextColor(),
                }}
                icon={<PlusOutlined />}
                alwaysShowTitle={false}
                onClick={() => {
                  setIsSensorModalVisible(true);
                }}
                text="Create smart sensor"
              />
            )}
          </TabPane>
          {/* <TabPane tab="Smart Relays" key="relays">
            <Modal
              title="Create smart relay"
              visible={isRelayModalVisible}
              onCancel={() => {
                setIsRelayModalVisible(false);
              }}
              footer={null}
            >
              <CreateSmartRelayForm
                onCreated={() => {
                  setRelayTriggerReload(true);
                  setIsRelayModalVisible(false);
                }}
              />
            </Modal>

            <SmartRelaysResponsiveTable
              reload={triggerRelayReload}
              onReloaded={() => setRelayTriggerReload(false)}
              queryParams={queryParams}
            />

            {!isRelayModalVisible && (
              <Fab
                style={{ bottom: 0, right: 0 }}
                mainButtonStyles={{
                  backgroundColor: getNavbarColor(),
                  color: getNavbarTextColor(),
                }}
                icon={<PlusOutlined />}
                alwaysShowTitle={false}
                onClick={() => {
                  setIsRelayModalVisible(true);
                }}
                text="Create smart relay"
              />
            )}
          </TabPane> */}
        </Tabs>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const SmartDevices = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedSmartDevices));

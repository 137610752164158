import * as qs from 'qs';
import { PathLike } from 'fs';
import store from '../../store';

export const getConfig = () => {
  const token = store.getState().auth.token;
  const config = {
    returnRejectedPromiseOnError: true,
    timeout: 30000,
    baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:8080',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    paramsSerializer: (params: PathLike) =>
      qs.stringify(params, { indices: false }),
    params: {},
  };
  return config;
};

export const getMultipartConfig = () => {
  const token = store.getState().auth.token;
  const config = {
    returnRejectedPromiseOnError: true,
    timeout: 30000,
    baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:8080',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
  };
  return config;
};

import React from 'react';
import { Form, Input, Button, Alert, Space, Switch, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { OrganisationModel } from '../../model/domain';
import { UpdateOrganisationCmd } from '../../model/cmds';
import { nameRule } from '../_util/validation';
import { Link } from 'react-router-dom';
import { usePrevious } from '../_util/hook';

type Props = {
  organisation: OrganisationModel | null;
  onUpdate: (cmd: UpdateOrganisationCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const OrganisationDataForm: React.FC<Props> = ({
  organisation,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({ ...organisation });
  }, [organisation]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Organisation updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating organisation',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { name } = values;
    const cmd: UpdateOrganisationCmd = {};

    if (name !== organisation?.name) {
      cmd.name = name;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && organisation) {
      cmd.name = organisation.name;
    }

    onUpdate(cmd);
  };

  return (
    <Form
      form={form}
      initialValues={{
        name: organisation?.name,
      }}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[nameRule, { required: true }]}
      >
        <Input placeholder="Name of the organisation" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Organisation
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

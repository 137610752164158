import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Select,
  Tooltip,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { PondModel } from '../../model/domain';
import { UpdatePondCmd } from '../../model/cmds';
import { nameRule } from '../_util/validation';
import { usePrevious } from '../_util/hook';
import { PondShape, PondSlope } from '../../model/enums';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { functionalColor } from '../../colors';

const { Option } = Select;

type Props = {
  pond: PondModel;
  onUpdate: (cmd: UpdatePondCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const PondDataForm: React.FC<Props> = ({
  pond,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...pond,
      name: pond.name.requested || pond.name.actual,
      electricalSetup: getElectricalSetup(
        pond.electricalVoltage,
        pond.electricalPhases,
      ),
      salinity: pond.salinity.requested || pond.salinity.actual,
    });
  }, [pond]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Pond updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating pond',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const {
      name,
      widthInMeter,
      electricalSetup,
      lengthInMeter,
      depthInMeter,
      shape,
      slope,
      salinity,
    } = values;
    const cmd: UpdatePondCmd = {};

    let desiredVoltage;
    let desiredPhases;

    if (electricalSetup === '120V-1P') {
      desiredVoltage = 120;
      desiredPhases = 1;
    } else if (electricalSetup === '230V-1P') {
      desiredVoltage = 230;
      desiredPhases = 1;
    } else if (electricalSetup === '400V-3P') {
      desiredVoltage = 400;
      desiredPhases = 3;
    }

    if (name !== pond.name.actual) {
      cmd.name = name;
    }

    if (salinity !== pond.salinity.actual) {
      cmd.salinity = salinity;
    }

    if (widthInMeter !== pond.widthInMeter) {
      cmd.widthInMeter = widthInMeter;
    }

    if (lengthInMeter !== pond.lengthInMeter) {
      cmd.lengthInMeter = lengthInMeter;
    }

    if (depthInMeter !== pond.depthInMeter) {
      cmd.depthInMeter = depthInMeter;
    }

    if (shape !== pond.shape) {
      cmd.shape = shape;
    }

    if (slope !== pond.slope) {
      cmd.slope = slope;
    }

    if (desiredVoltage !== pond.electricalVoltage) {
      cmd.electricalVoltage = desiredVoltage;
    }

    if (desiredPhases !== pond.electricalPhases) {
      cmd.electricalPhases = desiredPhases;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && pond) {
      cmd.name = pond.name && (pond.name.requested || pond.name.actual);
      cmd.electricalPhases = pond.electricalPhases;
      cmd.electricalVoltage = pond.electricalVoltage;
      cmd.widthInMeter = pond.widthInMeter;
      cmd.lengthInMeter = pond.lengthInMeter;
      cmd.depthInMeter = pond.depthInMeter;
      cmd.shape = pond.shape;
      cmd.slope = pond.slope;
      cmd.salinity =
        pond.salinity && (pond.salinity.requested || pond.salinity.actual);
    }

    onUpdate(cmd);
  };

  const getElectricalSetup = (
    electricalVoltage: number,
    electricalPhases: number,
  ) => {
    if (electricalVoltage === 120 && electricalPhases === 1) {
      return '120V-1P';
    } else if (electricalVoltage === 230 && electricalPhases === 1) {
      return '230V-1P';
    } else if (electricalVoltage === 400 && electricalPhases === 3) {
      return '400V-3P';
    }
    return '';
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Name"
        name="name"
        rules={[nameRule, { required: true }]}
      >
        <Input
          placeholder="Name of the pond"
          suffix={
            pond.name.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing... "${pond.name.requested}"`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        />
      </Form.Item>

      <Form.Item label="Electrical setup" name="electricalSetup">
        <Select placeholder="Select voltage" allowClear>
          <Option value={'120V-1P'}>{'120V - 1 phase'}</Option>
          <Option value={'230V-1P'}>{'230V - 1 phase'}</Option>
          <Option value={'400V-3P'}>{'400V - 3 phases'}</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Width in Meter" name="widthInMeter">
        <Input type="number" placeholder="42.5" />
      </Form.Item>

      <Form.Item label="Length in Meter" name="lengthInMeter">
        <Input type="number" placeholder="123" />
      </Form.Item>

      <Form.Item label="Depth in Meter" name="depthInMeter">
        <Input type="number" placeholder="1.5" />
      </Form.Item>

      <Form.Item label="Pond shape" name="shape">
        <Select placeholder="Select shape of the pond" allowClear>
          <Option value={PondShape.ELLIPSE}>{'Ellipse'}</Option>
          <Option value={PondShape.RECTANGLE}>{'Rectangle'}</Option>
          <Option value={PondShape.RIGHT_TRIANGLE}>{'Right triangle'}</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Pond slope" name="slope">
        <Select placeholder="Select slope of the pond" allowClear>
          <Option value={PondSlope.GENTLE}>{'Gentle'}</Option>
          <Option value={PondSlope.SLANTED}>{'Slanted'}</Option>
          <Option value={PondSlope.STRAIGHT}>{'Straight'}</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Pond salinity (ppt)" name="salinity">
        <Input
          placeholder="20"
          suffix={
            pond.salinity.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing..."`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Pond
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { getAccounts } from '../../duck/modules/accounts';
import { createOrganisation } from '../../duck/modules/organisations';
import { AccountModel, OrganisationModel } from '../../model/domain';
import { nameRule } from '../_util/validation';
import { CreateAccountForm } from '../account/createAccountForm';
import { INT_MAX } from '../../constants';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (organisation: OrganisationModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateOrganisationForm: React.FC<Props> = ({
  createOrganisationConnect,
  getAccountsConnect,
  loading,
  error,
  organisationCreated,
  accounts,
  onCreated,
  onError,
}) => {
  React.useEffect(() => {
    getAccountsConnect({
      limit: INT_MAX,
      offset: 0,
      accountsWithoutOrganisations: true,
    });
  }, []);

  React.useEffect(() => {
    if (organisationCreated) {
      notification.success({
        message: 'Organisation created',
      });
    }
    if (organisationCreated && onCreated) {
      onCreated(organisationCreated);
    }
  }, [organisationCreated]);

  React.useEffect(() => {
    if (error) {
      notification.error({
        message: 'Organisation creation error',
        description: error?.message,
      });
    }
    if (error && onError) {
      onError(error);
    }
  }, [error]);

  const [form] = Form.useForm();
  const [accountId, setAccountId] = React.useState('');
  const [visible, setVisible] = React.useState(false);

  const onFinish = (values: Store) => {
    const { name, accountId } = values;
    createOrganisationConnect({ name, accountId });
  };

  const onReset = () => {
    onAccountIdChanged('');
    form.resetFields();
  };

  const onAccountIdChanged = (accountId: string) => {
    setAccountId(accountId);
    form.setFieldsValue({ accountId });
  };

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[nameRule, { required: true }]}
        >
          <Input placeholder="HydroNeo GmbH" />
        </Form.Item>

        <Form.Item
          label="Creator"
          name="accountId"
          rules={[{ required: true }]}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={13}>
              <Select
                placeholder="Select creator account"
                allowClear
                onChange={onAccountIdChanged}
                value={accountId ? accountId : undefined}
              >
                {accounts.map((account) => (
                  <Option key={account.id} value={account.id}>
                    {account.email}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={11}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Create Account
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              Create Organisation
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        title={'Create an account'}
      >
        <CreateAccountForm
          onCreated={(account: AccountModel) => {
            onAccountIdChanged(account.id);
            setVisible(false);
          }}
          onError={(_error: Error) => {
            setVisible(false);
          }}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.organisations.create.loading,
  error: state.organisations.create.error,
  organisationCreated: state.organisations.create.organisation,
  accounts: state.accounts.list.accounts,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createOrganisationConnect: createOrganisation,
      getAccountsConnect: getAccounts,
    },
    dispatch,
  );
};

export const CreateOrganisationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateOrganisationForm);

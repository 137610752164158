import React from 'react';
import { Descriptions, Typography } from 'antd';
import { LcmModel } from '../../model/domain';
import { Link } from 'react-router-dom';

type Props = {
  title?: React.ReactNode;
  lcm: LcmModel;
  withId?: boolean;
  withLink?: boolean;
};

export const LcmDefaultValuesDescription: React.FC<Props> = ({
  lcm,
  title,
  withId,
  withLink,
}) => {
  return (
    <Descriptions column={{ xs: 1, sm: 1 }} bordered title={title}>
      {withLink && (
        <Descriptions.Item label="LCM">
          <Link to={`/lcms/${lcm.id}`}> {lcm.name?.actual || lcm.id}</Link>
        </Descriptions.Item>
      )}
      {withId && <Descriptions.Item label="ID">{lcm.id}</Descriptions.Item>}
      <Descriptions.Item label="Server Adress">
        {lcm.defaultServerAddress || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Frequency">
        {lcm.defaultFrequency || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Power">
        {lcm.defaultPower || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Crypt Key">
        {lcm.defaultCryptKey || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Bandwidth">
        {lcm.defaultBandwidth || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Coding Factor">
        {lcm.defaultCodingFactor || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Spreading Factor">
        {lcm.defaultSpreadingFactor || '-'}
      </Descriptions.Item>
    </Descriptions>
  );
};

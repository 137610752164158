import {
  Card,
  Col,
  Layout,
  Result,
  Row,
  Typography,
  Descriptions,
  Tooltip,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { RootState } from '../../duck';
import { UpdatePumpCmd } from '../../model/cmds';
import { getPump, updatePump } from '../../duck/modules/pumps';
import { PumpEntityForm } from '../pump/pumpEntityForm';
import { RelayConnectionType } from '../../model/enums';
import { getRelayboardIdOptions } from '../../util';
import { getGateway } from '../../duck/modules/gateways';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ pumpId: string }>;

const UnconnectedAertorDetail: React.FC<Props> = ({
  pump,
  match,
  loading,
  error,
  getPumpConnect,
  getGatewayConnect,
  updatePumpConnect,
  updating,
  updateError,
  updateSuccess,
  gatewayState,
}) => {
  const { pumpId } = match.params;

  React.useEffect(() => {
    getPumpConnect(pumpId);
  }, []);

  React.useEffect(() => {
    if (pump && pump.gateway && pump.gateway.id) {
      getGatewayConnect(pump.gateway.id);
    }
  }, [pump]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load pump data"
        subTitle={error.message}
      />
    );
  }

  const renderFormCard = () => {
    return (
      <Card title={'General Data'} loading={loading}>
        {pump && gatewayState.gateway && !gatewayState.loading && (
          <PumpEntityForm
            pump={pump}
            updating={updating}
            updateSuccess={updateSuccess}
            updateError={updateError}
            onUpdate={(cmd: UpdatePumpCmd) => {
              updatePumpConnect(pumpId, cmd);
            }}
            relayboardIdOptions={getRelayboardIdOptions(gatewayState.gateway)}
          />
        )}
      </Card>
    );
  };

  const renderGeneralDataCard = () => {
    return (
      <Card title={'Operation Data'} loading={loading}>
        {pump && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Connection Type">
              <Typography>{pump.relay.actual.connectionType}</Typography>
            </Descriptions.Item>
            <Descriptions.Item label="Relay State">
              <Typography>{pump.relay.actual.state}</Typography>
            </Descriptions.Item>
            {pump.relay.actual.connectionType ===
              RelayConnectionType.SMART_RELAY && (
              <Descriptions.Item label="Smart Relay (DeviceId)">
                <Typography>{pump.relay.actual.smartRelayId}</Typography>
              </Descriptions.Item>
            )}
            {pump.relay.actual.connectionType ===
              RelayConnectionType.SMART_RELAY && (
              <Descriptions.Item label="Smart Relay (Relay)">
                <Typography>{pump.relay.actual.smartRelayboardId}</Typography>
              </Descriptions.Item>
            )}
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderHierarchyInformationCard = () => {
    return (
      <Card title={'Hierarchy'} loading={loading}>
        {pump && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Gateway">
              {pump.gateway ? (
                <Link to={`/gateways/${pump.gateway.id}`}>
                  {pump.gateway.name || pump.gateway.id}
                </Link>
              ) : (
                <Typography>{'No Gateway'}</Typography>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Pond">
              {pump.pond ? (
                <Link to={`/ponds/${pump.pond.actual.id}`}>
                  {pump.pond.actual.name || pump.pond.actual.id}
                </Link>
              ) : (
                <Typography>{'No Pond'}</Typography>
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]} justify="start">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderFormCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[20, 20]} justify="start">
              <Col span={24}>{renderHierarchyInformationCard()}</Col>
              <Col span={24}>{renderGeneralDataCard()}</Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  pump: state.pumps.detail.pump,
  loading: state.pumps.detail.loading,
  error: state.pumps.detail.error,
  updating: state.pumps.detail.updating,
  updateError: state.pumps.detail.updateError,
  updateSuccess: state.pumps.detail.updateSuccess,
  gatewayState: state.gateways.detail,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getPumpConnect: getPump,
      updatePumpConnect: updatePump,
      getGatewayConnect: getGateway,
    },
    dispatch,
  );
};

export const PumpDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAertorDetail);

import { ThunkDispatch as Dispatch } from 'redux-thunk';
import {
  AUTHENTICATE as AUTHENTICATED,
  UNAUTHENTICATE as UNAUTHENTICATED,
  FETCH_ME_SUCCESS,
} from '../../constants';
import { authApi } from '../../api/authApi';
import { accountApi } from '../../api/accountApi';
import {
  AccountModel,
  AuthCredentials,
  DecodedToken,
} from '../../model/domain';
import { LoginCmd } from '../../model/cmds';
import { AuthState } from '../../model/state';
import { Role } from '../../model/enums';
import {
  AuthenticationAction,
  authenticated,
  unauthenticated,
} from '../actions';

export function login(username: string, password: string) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    try {
      const cmd: LoginCmd = { username, password };
      const response: AuthCredentials = await authApi.login(cmd);

      const decodedTokenString = atob(response.token.split('.')[1]);
      const decodedToken = JSON.parse(decodedTokenString) as DecodedToken;

      if (decodedToken.role.find((role) => role === Role.ROLE_HYDRONEO_GOD)) {
        await window.localStorage.setItem('token', response.token);
        dispatch(authenticated(response));
      } else {
        throw new Error("You don't have the role ROLE_HYDRONEO_GOD!");
      }
    } catch (e) {
      e.message = 'Invalid credentials!';
      dispatch(unauthenticated(e));
    }
  };
}

export function logout() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    await window.localStorage.removeItem('token');
    dispatch(unauthenticated(null));
  };
}

export function checkAuthentication() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const savedToken = await window.localStorage.getItem('token');
    if (savedToken) {
      const token: AuthCredentials = { token: savedToken };

      dispatch(authenticated(token));
    } else {
      dispatch(unauthenticated(null));
    }
  };
}

export function fetchMe() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    try {
      const account: AccountModel = await accountApi.fetchMe();
      // use action creator instead
      dispatch({
        type: FETCH_ME_SUCCESS,
        payload: account,
      });
    } catch (e) {
      // DISPATCH FETCH_ME_FAILED
    }
  };
}

export default function authReducer(
  state: AuthState = {
    error: null,
    loading: false,
    token: null,
    isAuthenticated: null,
    me: null,
  },
  action: AuthenticationAction,
): AuthState {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case UNAUTHENTICATED:
      return {
        me: null,
        token: null,
        isAuthenticated: false,
        error: action.payload,
        loading: false,
      };
    case FETCH_ME_SUCCESS:
      return { ...state, me: action.payload };
  }
  return state;
}

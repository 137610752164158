import {
  Button,
  Col,
  Descriptions,
  List,
  Modal,
  notification,
  Popconfirm,
  Result,
  Row,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck';
import { Link } from 'react-router-dom';
import {
  addAeratorToGateway,
  removeAeratorFromGateway,
  synchronizeGateway,
} from '../../duck/modules/gateways';
import {
  addAeratorToPond,
  refreshPond,
  removeAeratorFromPond,
} from '../../duck/modules/ponds';
import { AeratorModel, PondModel } from '../../model/domain';
import {
  GatewayStateField,
  MqttConnectionStatus,
  RelayConnectionType,
} from '../../model/enums';
import { getRelayboardIdOptions, getNameToAeratorType } from '../../util';
import { usePrevious } from '../_util/hook';
import { CreateAeratorForm } from '../aerator/createAeratorForm';
import DescriptionsItem from 'antd/lib/descriptions/Item';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pond: PondModel;
  };

export const UnconnectedPondAeratorsList: React.FC<Props> = ({
  pond,
  refreshPondConnect,
  synchronizeGatewayConnect,

  // gateway
  removeAeratorFromGatewayConnect,
  removeAeratorFromPondConnect,
  refreshState,

  gatewayAddAeratorState,
  gatewayRemoveAeratorState,
  pondAddAeratorState,
  pondRemoveAeratorState,
}) => {
  const [showAddAeratorModal, setShowAddAeratorModal] = React.useState(false);

  const prev = usePrevious({
    gatewayAddAeratorState,
    gatewayRemoveAeratorState,
    pondAddAeratorState,
    pondRemoveAeratorState,
  });

  React.useEffect(() => {
    if (
      prev?.gatewayAddAeratorState.loading &&
      !gatewayAddAeratorState.loading &&
      !gatewayAddAeratorState.error
    ) {
      notification.success({
        message: 'Aerator added to Gateway',
      });
    }
  }, [gatewayAddAeratorState]);

  React.useEffect(() => {
    if (
      prev?.gatewayAddAeratorState.loading &&
      !gatewayAddAeratorState.loading &&
      gatewayAddAeratorState.error
    ) {
      notification.error({
        message: 'Error while adding aerator to Gateway',
        description: gatewayAddAeratorState.error.message,
      });
    }
  }, [gatewayAddAeratorState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemoveAeratorState.loading &&
      !gatewayRemoveAeratorState.loading &&
      !gatewayRemoveAeratorState.error
    ) {
      notification.success({
        message: 'Aerator removed from Gateway',
      });
    }
  }, [gatewayRemoveAeratorState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemoveAeratorState.loading &&
      !gatewayRemoveAeratorState.loading &&
      gatewayRemoveAeratorState.error
    ) {
      notification.error({
        message: 'Error while removing aerator from Gateway',
        description: gatewayRemoveAeratorState.error.message,
      });
    }
  }, [gatewayRemoveAeratorState]);

  React.useEffect(() => {
    if (
      prev?.pondAddAeratorState.loading &&
      !pondAddAeratorState.loading &&
      !pondAddAeratorState.error
    ) {
      setShowAddAeratorModal(false);
      notification.success({
        message: 'Aerator added to Pond',
      });
    }
  }, [pondAddAeratorState]);

  React.useEffect(() => {
    if (
      prev?.pondAddAeratorState.loading &&
      !pondAddAeratorState.loading &&
      pondAddAeratorState.error
    ) {
      notification.error({
        message: 'Error while adding aerator to Pond',
        description: pondAddAeratorState.error.message,
      });
    }
  }, [pondAddAeratorState]);

  React.useEffect(() => {
    if (
      prev?.pondRemoveAeratorState.loading &&
      !pondRemoveAeratorState.loading &&
      !pondRemoveAeratorState.error
    ) {
      notification.success({
        message: 'Aerator removed from Pond',
      });
    }
  }, [pondRemoveAeratorState]);

  React.useEffect(() => {
    if (
      prev?.pondRemoveAeratorState.loading &&
      !pondRemoveAeratorState.loading &&
      pondRemoveAeratorState.error
    ) {
      notification.error({
        message: 'Error while removing aerator from Pond',
        description: pondRemoveAeratorState.error.message,
      });
    }
  }, [pondRemoveAeratorState]);

  const renderAeratorsTabContent = () => {
    if (pond.gateway) {
      const gatewayId = pond.gateway.id;
      const inSync = pond.gateway.aerators.inSync;
      const isOnline =
        pond.gateway.mqttConnectionStatus === MqttConnectionStatus.CONNECTED;

      return (
        <List
          bordered
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 3,
          }}
          loading={!inSync}
          dataSource={
            pond.aeration.aerators.sort((a1, a2) =>
              a1.id.localeCompare(a2.id),
            ) || []
          }
          locale={{
            emptyText: <Typography>No aerators</Typography>,
          }}
          header={
            <Row justify={'space-between'}>
              <Col>
                <Typography.Text strong>Aerators</Typography.Text>
              </Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  disabled={!isOnline}
                  onClick={() => setShowAddAeratorModal(true)}
                >
                  {'Add aerator'}
                </Button>
              </Col>
            </Row>
          }
          footer={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.AERATOR],
                  });
                  refreshPondConnect(pond.id);
                }}
              >
                Force Sync
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  refreshPondConnect(pond.id);
                }}
              >
                Refresh
              </Button>
            </Space>
          }
          renderItem={(aerator) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <Descriptions bordered column={1}>
                    {aerator.name && (
                      <Descriptions.Item
                        label={
                          <Space>
                            <Typography.Text>{'Name'}</Typography.Text>
                          </Space>
                        }
                      >
                        <Typography.Text strong>
                          {aerator.name || ''}
                        </Typography.Text>
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label={'ID'}>
                      <Link to={`/aerators/${aerator.id}`}>{aerator.id}</Link>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={
                        <Space>
                          <Typography.Text>{'Group'}</Typography.Text>
                        </Space>
                      }
                    >
                      <Typography.Text>{aerator.group}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Space>
                          <Typography.Text>{'Relay'}</Typography.Text>
                        </Space>
                      }
                    >
                      <Descriptions layout={'vertical'} bordered={true}>
                        <Descriptions.Item label={'Connection'}>
                          <Typography.Text>
                            {aerator.relay.connectionType}
                          </Typography.Text>
                        </Descriptions.Item>
                        {aerator.relay.connectionType ===
                          RelayConnectionType.RELAY_BOARD && (
                          <Descriptions.Item label={'Relayboard ID'}>
                            <Typography.Text>
                              {aerator.relay.relayboardId}
                            </Typography.Text>
                          </Descriptions.Item>
                        )}
                        {aerator.relay.connectionType ===
                          RelayConnectionType.SMART_RELAY && (
                          <Descriptions.Item label={'Smart Relay'}>
                            <Typography.Text>
                              {aerator.relay.smartRelayId}
                            </Typography.Text>
                          </Descriptions.Item>
                        )}
                        {aerator.relay.connectionType ===
                          RelayConnectionType.SMART_RELAY && (
                          <Descriptions.Item label={'Smart Relayboard ID'}>
                            <Typography.Text>
                              {aerator.relay.smartRelayboardId}
                            </Typography.Text>
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label={'State'}>
                          <Typography.Text>
                            {aerator.relay.state}
                          </Typography.Text>
                        </Descriptions.Item>
                      </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label={'Type'}>
                      {aerator.type}
                    </Descriptions.Item>

                    <DescriptionsItem label="Actions">
                      <Space>
                        {isOnline ? (
                          <Popconfirm
                            title={
                              'Are you sure to remove this aerator from the pond?'
                            }
                            onConfirm={() => {
                              removeAeratorFromPondConnect(pond.id, aerator.id);
                              removeAeratorFromGatewayConnect(
                                gatewayId,
                                aerator.id,
                                pond.id,
                              );
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              loading={
                                aerator.id ===
                                  pondRemoveAeratorState.aeratorId ||
                                aerator.id ==
                                  gatewayRemoveAeratorState.aeratorId
                              }
                              danger
                              size="small"
                              disabled={!isOnline}
                            >
                              Remove
                            </Button>
                          </Popconfirm>
                        ) : (
                          <Button danger size="small" disabled>
                            Remove
                          </Button>
                        )}
                      </Space>
                    </DescriptionsItem>
                  </Descriptions>
                }
              />
            </List.Item>
          )}
        />
      );
    } else {
      return (
        <Typography.Title level={4}>
          You need an gateway in place to change aerators!
        </Typography.Title>
      );
    }
  };

  const renderAddAeratorModal = () => {
    return (
      <Modal
        visible={showAddAeratorModal}
        onOk={() => setShowAddAeratorModal(false)}
        onCancel={() => setShowAddAeratorModal(false)}
        footer={null}
        title={'Add aerator'}
      >
        <CreateAeratorForm
          relayboardOptions={
            pond.gateway && getRelayboardIdOptions(pond.gateway)
          }
          organisationId={pond.organisation.id}
          pondId={pond.id}
          gatewayId={pond.gateway?.id}
          onCreated={(_aerator: AeratorModel) => {
            setShowAddAeratorModal(false);
          }}
        />
      </Modal>
    );
  };

  if (pond.gateway) {
    return (
      <div>
        {renderAeratorsTabContent()}
        {renderAddAeratorModal()}
      </div>
    );
  }

  return (
    <div>
      <Result status="info" title="Pond has no Gateway!" />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  refreshState: state.ponds.detail.refresh,
  gatewayAddAeratorState: state.gateways.addAerator,
  gatewayRemoveAeratorState: state.gateways.removeAerator,
  pondAddAeratorState: state.ponds.addAerator,
  pondRemoveAeratorState: state.ponds.removeAerator,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      refreshPondConnect: refreshPond,

      addAeratorToPondConnect: addAeratorToPond,
      addAeratorToGatewayConnect: addAeratorToGateway,

      removeAeratorFromPondConnect: removeAeratorFromPond,
      removeAeratorFromGatewayConnect: removeAeratorFromGateway,

      synchronizeGatewayConnect: synchronizeGateway,
    },
    dispatch,
  );
};

export const PondAeratorsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondAeratorsList);

import React from 'react';
import { Form, Input, Button, Space, notification, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { FarmModel, LocationModel } from '../../model/domain';
import { UpdateFarmCmd } from '../../model/cmds';
import { nameRule } from '../_util/validation';
import { usePrevious } from '../_util/hook';

type Props = {
  farm: FarmModel | null;
  locations: LocationModel[];
  locationsLoading: boolean;
  onUpdate: (cmd: UpdateFarmCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
  onZipCodeChange: (zipCode: string) => void;
};

const { Option } = Select;

export const FarmDataForm: React.FC<Props> = ({
  farm,
  locations,
  locationsLoading,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
  onZipCodeChange,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({ ...farm });
  }, [farm]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Farm updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating farm',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { name, crmCompanyId, locationId, numberOfPonds } = values;
    const cmd: UpdateFarmCmd = {};

    if (name !== farm?.name) {
      cmd.name = name;
    }

    if (crmCompanyId !== farm?.crmCompanyId) {
      cmd.crmCompanyId = crmCompanyId;
    }

    if (locationId !== farm?.locationId) {
      cmd.locationId = locationId;
    }

    if (numberOfPonds !== farm?.numberOfPonds) {
      cmd.numberOfPonds = numberOfPonds;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && farm) {
      cmd.name = farm.name;
      cmd.crmCompanyId = farm.crmCompanyId;
      cmd.locationId = farm.locationId;
      cmd.numberOfPonds = farm.numberOfPonds;
    }

    onUpdate(cmd);
  };

  return (
    <Form
      form={form}
      initialValues={{
        name: farm?.name,
        crmCompanyId: farm?.crmCompanyId,
      }}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[nameRule, { required: true }]}
      >
        <Input placeholder="Name of the farm" />
      </Form.Item>

      <Form.Item label="CRM Company Id" name="crmCompanyId">
        <Input placeholder="12345678" />
      </Form.Item>

      <Form.Item label="Number of Ponds" name="numberOfPonds" extra=" 0 = 'N/A'">
        <Input placeholder="5" />
      </Form.Item>


      <Form.Item label="Search by ZIP code" name="zipCode">
        <Input
          onChange={() => {
            const zipCode: string = form.getFieldValue('zipCode');
            onZipCodeChange(zipCode);
          }}
          placeholder="Enter zip code to search for location"
        />
      </Form.Item>

      <Form.Item label="Location" name="locationId">
        <Select
          allowClear
          loading={locationsLoading}
          placeholder="Select location"
        >
          {farm?.location && (
            <Option value={farm.location.id}>
              {`${farm.location.zipCode} - ${farm.location.province}, ${farm.location.district}`}
            </Option>
          )}

          {form.getFieldValue('zipCode') &&
            locations
              .filter((l) => l.id !== farm?.locationId)
              .sort((l1, l2) => l1.zipCode.localeCompare(l2.zipCode))
              .map((location) => (
                <Option key={location.id} value={location.id}>
                  {`${location.zipCode} - ${location.province}, ${location.district}`}
                </Option>
              ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Farm
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

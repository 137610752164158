import React from 'react';
import {
  Table,
  Tag,
  Button,
  Result,
  Typography,
  Row,
  Pagination,
  Col,
  Select,
  Form,
  Checkbox,
  Descriptions,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { countFarms, getFarms } from '../../duck/modules/farms';
import { FarmModel } from '../../model/domain';
import { functionalColor } from '../../colors';
import Column from 'antd/lib/table/Column';
import { boolFromStringOtherwiseNull } from '../../util';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    reload: boolean;
    onReloaded: () => void;
    queryParams?: {
      page?: number;
      pageSize?: number;
      includeDisabled?: string;
      isActive?: string | string[];

    };
  };

const UnconnectedFarmsTable: React.FC<Props> = ({
  farms,
  loading,
  error,
  total,
  reload,
  onReloaded,
  getFarmsConnect,
  queryParams,
  countsState,
  countFarmsConnect
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);
  const [includeDisabled, setIncludeDisabled] = React.useState(
    queryParams?.includeDisabled !== 'true' ? false : true,
  );
  const [isActive, setIsActive] = React.useState<boolean[]>(
    typeof queryParams?.isActive === "string" ? [boolFromStringOtherwiseNull(queryParams?.isActive)] :
      typeof queryParams?.isActive === "object" && queryParams?.isActive instanceof Array ? queryParams?.isActive.map(boolFromStringOtherwiseNull) : []
  );


  React.useEffect(() => {
    if (typeof queryParams?.isActive === "string") {
      setIsActive([boolFromStringOtherwiseNull(queryParams?.isActive)]);
    } else if (typeof queryParams?.isActive === "object" && queryParams?.isActive instanceof Array) {
      setIsActive(queryParams?.isActive.map(boolFromStringOtherwiseNull));
    }
  }, [queryParams?.isActive]);



  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  React.useEffect(() => {
    getFarmsConnect({ limit, offset, includeDisabled, isActive });
    countFarmsConnect();
    onReloaded();
  }, [offset, limit, reload, includeDisabled, isActive]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load farms"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderFilter = () => {
    return (
      <div>
        <Form
          layout="inline"
          style={{ width: '100%' }}
          initialValues={{
            includeDisabled,
            isActive,
          }}
        >
          <Form.Item name="includeDisabled" valuePropName="includeDisabled">
            <Checkbox
              onChange={(e) => {
                const checked = new Boolean(e.target.checked);
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('includeDisabled', checked.toString());
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setIncludeDisabled(checked.valueOf());
              }}
              checked={includeDisabled}
            >
              {'Include disabled'}
            </Checkbox>
          </Form.Item>


          <Form.Item name="isActive">
            <Select
              mode="multiple"
              placeholder="Filter by BI"
              value={isActive}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('isActive');
                value.forEach((d) =>
                  searchParams.append('isActive', d.toString()),
                );
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setIsActive(data);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            >
              <Select.Option value={true}>
                <Tag color={functionalColor.success}>{'Active'}</Tag>
              </Select.Option>
              <Select.Option value={false}>
                <Tag color={functionalColor.error}>{'Inactive'}</Tag>
              </Select.Option>
            </Select>
          </Form.Item>


        </Form>
        <br />
      </div>
    );
  };

  const renderResultInfo = () => {

    return (
      <div>
        <Descriptions column={1} layout='horizontal' >

          <Descriptions.Item label="Query results">
            {total}
          </Descriptions.Item>
          <Descriptions.Item label="Active farms">
            {countsState?.active}
          </Descriptions.Item>

          <Descriptions.Item label="Inactive farms">
            {countsState?.inactive}
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            {countsState?.all}
          </Descriptions.Item>

        </Descriptions>
        <br />
      </div>
    );

  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load farms"
        subTitle={error.message}
      />
    );
  }

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={farms}
        scroll={{ x: true }}
      >



        <Column
          align="center"
          title="Enabled"
          render={(farm: FarmModel) => {
            if (farm.enabled) {
              return <Typography style={{ color: functionalColor.success }} >{"✓"}</Typography>
            }
            return <Typography style={{ color: functionalColor.error }}  >{"✘"}</Typography>
          }}
        />



        <Column
          align="center"
          title="BI"
          render={(farm: FarmModel) => {
            if (farm.isActive === true) {
              return <Tag color={functionalColor.success}>Active</Tag>;
            } else if (farm.isActive === false) {
              return <Tag color={functionalColor.error}>Inactive</Tag>;
            }
            return <Tag color={functionalColor.warning}>NULL</Tag>;
          }}
        />


        <Column
          align="center"
          title="ID"
          render={(farm: FarmModel) => (
            <Link
              key={farm.id}
              to={`/farms/${farm.id}`}
            >
              {farm.id}
            </Link>
          )}
        />

        <Column
          align="center"
          title="Name"
          render={(farm: FarmModel) => (
            <Typography>{farm.name}</Typography>
          )}
        />
        <Column
          align="center"
          title="Number of ponds"
          render={(farm: FarmModel) => (
            <Typography>{farm.numberOfPonds === 0 ? "N/A" : farm.numberOfPonds}</Typography>
          )}
        />

        <Column
          align="center"
          title="PUM"
          render={(farm: FarmModel) => (
            <Typography>{farm.ponds.length}</Typography>
          )}
        />

        <Column
          align="center"
          title="Organisation"
          render={(farm: FarmModel) => (
            <Link key={farm.id} to={`/organisations/${farm.organisation.id}`}>
              {farm.organisation.name}
            </Link>
          )}
        />

        <Column
          align="center"
          title="Location"
          render={(farm: FarmModel) => {
            if (farm.location) {
              return (
                <Typography>{`${farm.location.zipCode} - ${farm.location.province}, ${farm.location.district}`}</Typography>
              );
            }
            return <Typography>{`-`}</Typography>;
          }}
        />

        <Column
          align="center"
          title="CRM"
          render={(farm: FarmModel) => {
            if (farm.crmCompanyId) {
              return (
                <a
                  target="_blank"
                  href={`https://app.hubspot.com/contacts/5586633/company/${farm.crmCompanyId}`}
                >
                  {farm.crmCompanyId}
                </a>
              );
            }
            return <Typography>{`-`}</Typography>;
          }}
        />
      </Table>
    );
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  return (
    <Col>
      {renderFilter()}
      {renderResultInfo()}
      {renderTable()}
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  farms: state.farms.list.farms,
  loading: state.farms.list.loading,
  error: state.farms.list.error,
  total: state.farms.list.total,
  countsState: state.farms.counts,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFarmsConnect: getFarms,
      countFarmsConnect: countFarms,
    },
    dispatch,
  );
};

export const FarmsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedFarmsTable);

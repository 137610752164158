import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { sensingApi } from '../../api/sensingApi';
import {
  CREATE_SENSOR,
  CREATE_SENSOR_FAILED,
  CREATE_SENSOR_SUCCESS,
  FETCH_ORGANISATION_SENSORS,
  FETCH_ORGANISATION_SENSORS_FAILED,
  FETCH_ORGANISATION_SENSORS_SUCCESS,
  FETCH_SENSOR,
  FETCH_SENSOR_FAILED,
  FETCH_SENSOR_SUCCESS,
  FETCH_SENSORS,
  FETCH_SENSORS_FAILED,
  FETCH_SENSORS_SUCCESS,
  INT_MAX,
  UPDATE_SENSOR,
  UPDATE_SENSOR_FAILED,
  UPDATE_SENSOR_SUCCESS,
  REMOVE_ACCOUNT_FROM_ORGANISATION,
  REMOVE_SENSOR_FROM_ORGANISATION,
  REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS,
  REMOVE_SENSOR_FROM_ORGANISATION_FAILED,
} from '../../constants';
import {
  CreateSensorCmd,
  UpdateSensorCmd,
  AddSensorToOrganisationCmd,
  CalibrateSensorCmd,
} from '../../model/cmds';
import { SensorModel, SmartSensorModel } from '../../model/domain';
import {
  ListSensorsQuery,
  ListSensorsQueryModel,
  ListSensorDataAggregationQuery,
  ListSensorDataAggregationQueryModel,
} from '../../model/querys';
import { SensingState } from '../../model/state';
import {
  sensorOrganisationRemoveFailed,
  sensorOrganisationRemove,
  sensorOrganisationRemoved,
  sensorOrganisationAdd,
  sensorOrganisationAdded,
  sensorOrganisationAddFailed,
  sensorDataAggregationsFetch,
  sensorDataAggregationsFetched,
  sensorDataAggregationsFetchFailed,
  SmartSensorsAction,
} from '../actions';
import {
  FETCH_SENSOR_DATA_AGGREGATIONS,
  FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS,
  FETCH_SENSOR_DATA_AGGREGATIONS_FAILED,
} from '../../constants';
import {
  organisationSensorsFetch,
  organisationSensorsFetched,
  organisationSensorsFetchFailed,
  sensorCreate,
  sensorCreated,
  sensorCreateFailed,
  sensorFetch,
  sensorFetched,
  sensorFetchFailed,
  SensorsAction,
  sensorsFetch,
  sensorsFetched,
  sensorsFetchFailed,
  sensorUpdate,
  sensorUpdated,
  sensorUpdateFailed,
} from '../actions';
import { addSensorToGateway } from './gateways';

export function createSensor(cmd: CreateSensorCmd, gatewayIdToAttach?: string) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorCreate());
      const sensor = await sensingApi.createSensor(cmd);
      sensor.url = 'created';

      if (gatewayIdToAttach) {
        dispatch(
          addSensorToGateway(gatewayIdToAttach, { sensorId: sensor.id }),
        );
      }

      dispatch(sensorCreated(sensor));
      // dispatch(getSensors({limit: INT_MAX, offset: 0}));
      if (cmd.organisationId) {
        dispatch(
          getOrganisationSensors(cmd.organisationId, {
            limit: INT_MAX,
            offset: 0,
          }),
        );
      }
    } catch (e) {
      dispatch(sensorCreateFailed(e));
    }
  };
}

export function getSensor(id: string) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorFetch());
      const response: SensorModel = await sensingApi.getSensor(id);
      dispatch(sensorFetched(response));
    } catch (e) {
      dispatch(sensorFetchFailed(e));
    }
  };
}

export function getSensors(query: ListSensorsQuery) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorsFetch(query));
      const queryModel: ListSensorsQueryModel = await sensingApi.listSensors(
        query,
      );
      // fetch details for each received sensor
      const sensors = await Promise.all(
        queryModel.sensors.map((sensor) => sensingApi.getSensor(sensor.id)),
      );
      dispatch(sensorsFetched(queryModel, sensors));
    } catch (e) {
      dispatch(sensorsFetchFailed(e));
    }
  };
}

export function getOrganisationSensors(
  organisationId: string,
  query?: ListSensorsQuery,
) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(
        organisationSensorsFetch(query || { limit: INT_MAX, offset: 0 }),
      );
      const queryModel: ListSensorsQueryModel = await sensingApi.listOrganisationSensors(
        organisationId,
        query,
      );
      // fetch details for each received sensor
      const sensors = await Promise.all(
        queryModel.sensors.map((sensor) => sensingApi.getSensor(sensor.id)),
      );
      dispatch(organisationSensorsFetched(queryModel, sensors));
    } catch (e) {
      dispatch(organisationSensorsFetchFailed(e));
    }
  };
}

export function updateSensor(id: string, cmd: UpdateSensorCmd) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorUpdate());
      const response: SensorModel = await sensingApi.updateSensor(id, cmd);
      dispatch(sensorUpdated(response));
    } catch (e) {
      dispatch(sensorUpdateFailed(e));
    }
  };
}

export function addSensorToOrganisation(
  sensorId: string,
  cmd: AddSensorToOrganisationCmd,
) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorOrganisationAdd());
      const response: SensorModel = await sensingApi.addMemberToSensor(
        sensorId,
        cmd,
      );
      await dispatch(getSensor(sensorId));
      await dispatch(sensorOrganisationAdded(response));
    } catch (e) {
      dispatch(sensorOrganisationAddFailed(e));
    }
  };
}

export function removeSensorFromOrganisation(
  organisationId: string,
  sensorId: string,
) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorOrganisationRemove(sensorId, organisationId));
      await sensingApi.removeSensorFromOrganisation(organisationId, sensorId);
      await dispatch(getOrganisationSensors(organisationId));
      dispatch(sensorOrganisationRemoved());
    } catch (e) {
      dispatch(sensorOrganisationRemoveFailed(e));
    }
  };
}

export function getSensorDataAggregations(
  query: ListSensorDataAggregationQuery,
) {
  return async (dispatch: Dispatch<SensorsAction, {}, any>) => {
    try {
      dispatch(sensorDataAggregationsFetch(query));
      const response: ListSensorDataAggregationQueryModel = await sensingApi.getSensorDataAggregations(
        query,
      );
      dispatch(sensorDataAggregationsFetched(response));
    } catch (e) {
      dispatch(sensorDataAggregationsFetchFailed(e));
    }
  };
}

export default function sensingReducer(
  state: SensingState = {
    create: {
      loading: false,
      error: null,
      sensor: null,
    },
    list: {
      loading: false,
      error: null,
      sensors: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      sensor: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    organisationSensors: {
      loading: false,
      error: null,
      sensors: [],
      total: null,
      offset: null,
      limit: null,
    },
    aggregations: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    removeSensor: {
      loading: false,
      error: null,
      success: null,
      organisationId: null,
      sensorId: null,
    },
  },
  action: SensorsAction,
): SensingState {
  switch (action.type) {
    case CREATE_SENSOR:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, sensor: null },
      };
    case CREATE_SENSOR_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          sensor: action.payload,
        },
      };
    case CREATE_SENSOR_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          sensor: null,
        },
      };
    case FETCH_SENSOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          sensor: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_SENSOR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          sensor: action.payload,
        },
      };
    case FETCH_SENSOR_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          sensor: null,
        },
      };
    case UPDATE_SENSOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_SENSOR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
          sensor: action.payload,
        },
      };
    case UPDATE_SENSOR_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case FETCH_SENSORS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          sensors: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_SENSORS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          sensors: action.payload.sensors,
          limit: action.payload.queryModel.limit,
          offset: action.payload.queryModel.offset,
          total: action.payload.queryModel.total,
        },
      };

    case FETCH_SENSORS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          sensors: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case FETCH_ORGANISATION_SENSORS:
      return {
        ...state,
        organisationSensors: {
          ...state.organisationSensors,
          loading: true,
          error: null,
          sensors: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ORGANISATION_SENSORS_SUCCESS:
      return {
        ...state,
        organisationSensors: {
          ...state.organisationSensors,
          loading: false,
          error: null,
          sensors: action.payload.sensors,
          limit: action.payload.queryModel.limit,
          offset: action.payload.queryModel.offset,
          total: action.payload.queryModel.total,
        },
      };

    case FETCH_ORGANISATION_SENSORS_FAILED:
      return {
        ...state,
        organisationSensors: {
          ...state.organisationSensors,
          loading: true,
          error: null,
          sensors: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case FETCH_SENSOR_DATA_AGGREGATIONS:
      return {
        ...state,
        aggregations: {
          ...state.aggregations,
          loading: true,
          error: null,
          query: action.payload,
        },
      };

    case FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS:
      return {
        ...state,
        aggregations: {
          ...state.aggregations,
          loading: false,
          error: null,
          model: action.payload,
        },
      };

    case FETCH_SENSOR_DATA_AGGREGATIONS_FAILED:
      return {
        ...state,
        aggregations: {
          ...state.aggregations,
          loading: false,
          error: action.payload,
          model: null,
        },
      };

    case REMOVE_SENSOR_FROM_ORGANISATION:
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: true,
          error: null,
          success: null,
          organisationId: action.payload.organisationId,
          sensorId: action.payload.sensorId,
        },
      };
    case REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS:
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: false,
          error: null,
          success: true,
          organisationId: null,
          sensorId: null,
        },
      };
    case REMOVE_SENSOR_FROM_ORGANISATION_FAILED:
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: false,
          error: action.payload,
          success: false,
          organisationId: null,
          sensorId: null,
        },
      };


    default:
      return state;
  }
}

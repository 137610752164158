import {
  Col,
  Empty,
  Pagination,
  Row,
  Statistic,
  Table,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { SensorAggregationDataModel } from '../../model/domain';
import moment from 'moment';

const { Column, ColumnGroup } = Table;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    aggregations: SensorAggregationDataModel[];
    loading?: boolean;
    pagination?: any;
  };

const UnconnectedSensorAggregationDataTable: React.FC<Props> = ({
  aggregations,
  loading,
  pagination,
}) => {
  const hasMedian = !!aggregations.find((a) => a.median);
  const hasMin = !!aggregations.find((a) => a.min);
  const hasMax = !!aggregations.find((a) => a.max);
  const hasMean = !!aggregations.find((a) => a.mean);

  const renderMedian = () => {
    const hasDoPPM = !!aggregations.find((a) => a.median?.doPPM !== undefined);
    const hasDoPerc = !!aggregations.find(
      (a) => a.median?.doPerc !== undefined,
    );
    const hasDoRawPerc = !!aggregations.find(
      (a) => a.median?.doRawPerc !== undefined,
    );
    const hasTempInC = !!aggregations.find(
      (a) => a.median?.tempInC !== undefined,
    );
    const hasPhRawInMv = !!aggregations.find(
      (a) => a.median?.pHRawInMv !== undefined,
    );
    const hasPH = !!aggregations.find((a) => a.median?.pH !== undefined);
    const hasRawCondInMs = !!aggregations.find(
      (a) => a.median?.rawCondInMs !== undefined,
    );
    const hasCalcCondInMs = !!aggregations.find(
      (a) => a.median?.condInMs !== undefined,
    );
    const hasSalPPT = !!aggregations.find(
      (a) => a.median?.salPPT !== undefined,
    );
    const hasEcInA = !!aggregations.find((a) => a.median?.ecInA !== undefined);
    const hasEpInKW = !!aggregations.find(
      (a) => a.median?.epInKW !== undefined,
    );
    const hasLux = !!aggregations.find(
      (a) => a.median?.lux !== undefined,
    );
    const hasHumidityInPerc = !!aggregations.find(
      (a) => a.median?.humidityInPerc !== undefined,
    );
    const hasLuxTempInC = !!aggregations.find(
      (a) => a.median?.luxTempInC !== undefined,
    );

    return (
      <ColumnGroup align="center" title="MEDIAN">
        {(hasDoRawPerc as any) && (
          <Column
            align="center"
            title="Raw DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.doRawPerc !== undefined && (
                  <Typography>
                    {`${data.median?.doRawPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPerc as any) && (
          <Column
            align="center"
            title="DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.doPerc !== undefined && (
                  <Typography>
                    {`${data.median?.doPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPPM as any) && (
          <Column
            align="center"
            title="DO (ppm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.doPPM !== undefined && (
                  <Typography>{`${data.median?.doPPM?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.tempInC !== undefined && (
                  <Typography>
                    {`${data.median?.tempInC?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasPhRawInMv as any) && (
          <Column
            align="center"
            title="Raw PH (mV)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.pHRawInMv !== undefined && (
                  <Typography>
                    {`${data.median?.pHRawInMv?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasPH as any) && (
          <Column
            align="center"
            title="Calc PH"
            render={(data: SensorAggregationDataModel) =>
              data.median?.pH !== undefined && (
                <Typography>{`${data.median?.pH?.toFixed(1)}`}</Typography>
              )
            }
          />
        )}

        {(hasRawCondInMs as any) && (
          <Column
            align="center"
            title="Raw Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.rawCondInMs !== undefined && (
                  <Typography>
                    {`${data.median?.rawCondInMs?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasCalcCondInMs as any) && (
          <Column
            align="center"
            title="Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.condInMs !== undefined && (
                  <Typography>
                    {`${data.median?.condInMs?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasSalPPT as any) && (
          <Column
            align="center"
            title="Salinity (ppt)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.salPPT !== undefined && (
                  <Typography>
                    {`${data.median?.salPPT?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasEcInA as any) && (
          <Column
            align="center"
            title="EC (A)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.ecInA !== undefined && (
                  <Typography>{`${data.median?.ecInA?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEpInKW as any) && (
          <Column
            align="center"
            title="EP (kW)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.epInKW !== undefined && (
                  <Typography>
                    {`${data.median?.epInKW?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasLux as any) && (
          <Column
            align="center"
            title="LUX"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.lux !== undefined && (
                  <Typography>
                    {`${data.median?.lux?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasHumidityInPerc as any) && (
          <Column
            align="center"
            title="Humidity (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.humidityInPerc !== undefined && (
                  <Typography>
                    {`${data.median?.humidityInPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasLuxTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.median?.luxTempInC !== undefined && (
                  <Typography>
                    {`${data.median?.luxTempInC?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

      </ColumnGroup>
    );
  };

  const renderMin = () => {
    const hasDoPPM = !!aggregations.find((a) => a.min?.doPPM !== undefined);
    const hasDoPerc = !!aggregations.find((a) => a.min?.doPerc !== undefined);
    const hasDoRawPerc = !!aggregations.find(
      (a) => a.min?.doRawPerc !== undefined,
    );
    const hasTempInC = !!aggregations.find((a) => a.min?.tempInC !== undefined);
    const hasPhRawInMv = !!aggregations.find(
      (a) => a.min?.pHRawInMv !== undefined,
    );
    const hasPH = !!aggregations.find((a) => a.min?.pH !== undefined);
    const hasRawCondInMs = !!aggregations.find(
      (a) => a.min?.rawCondInMs !== undefined,
    );
    const hasCalcCondInMs = !!aggregations.find(
      (a) => a.min?.condInMs !== undefined,
    );
    const hasSalPPT = !!aggregations.find((a) => a.min?.salPPT !== undefined);
    const hasEcInA = !!aggregations.find((a) => a.min?.ecInA !== undefined);
    const hasEpInKW = !!aggregations.find((a) => a.min?.epInKW !== undefined);
    const hasLux = !!aggregations.find(
      (a) => a.min?.lux !== undefined,
    );
    const hasHumidityInPerc = !!aggregations.find(
      (a) => a.min?.humidityInPerc !== undefined,
    );
    const hasLuxTempInC = !!aggregations.find(
      (a) => a.min?.luxTempInC !== undefined,
    );

    return (
      <ColumnGroup align="center" title="MIN">
        {(hasDoRawPerc as any) && (
          <Column
            align="center"
            title="Raw DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.doRawPerc !== undefined && (
                  <Typography>
                    {`${data.min?.doRawPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPerc as any) && (
          <Column
            align="center"
            title="DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.doPerc !== undefined && (
                  <Typography>{`${data.min?.doPerc?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPPM as any) && (
          <Column
            align="center"
            title="DO (ppm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.doPPM !== undefined && (
                  <Typography>{`${data.min?.doPPM?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.tempInC !== undefined && (
                  <Typography>{`${data.min?.tempInC?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasPhRawInMv as any) && (
          <Column
            align="center"
            title="Raw PH (mV)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.pHRawInMv !== undefined && (
                  <Typography>
                    {`${data.min?.pHRawInMv?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasPH as any) && (
          <Column
            align="center"
            title="Calc PH"
            render={(data: SensorAggregationDataModel) =>
              data.min?.pH !== undefined && (
                <Typography>{`${data.min?.pH?.toFixed(1)}`}</Typography>
              )
            }
          />
        )}

        {(hasRawCondInMs as any) && (
          <Column
            align="center"
            title="Raw Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.rawCondInMs !== undefined && (
                  <Typography>
                    {`${data.min?.rawCondInMs?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasCalcCondInMs as any) && (
          <Column
            align="center"
            title="Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.condInMs !== undefined && (
                  <Typography>{`${data.min?.condInMs?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasSalPPT as any) && (
          <Column
            align="center"
            title="Salinity (ppt)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.salPPT !== undefined && (
                  <Typography>{`${data.min?.salPPT?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEcInA as any) && (
          <Column
            align="center"
            title="EC (A)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.ecInA !== undefined && (
                  <Typography>{`${data.min?.ecInA?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEpInKW as any) && (
          <Column
            align="center"
            title="EP (kW)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.epInKW !== undefined && (
                  <Typography>{`${data.min?.epInKW?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}


        {(hasLux as any) && (
          <Column
            align="center"
            title="LUX"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.lux !== undefined && (
                  <Typography>
                    {`${data.min?.lux?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasHumidityInPerc as any) && (
          <Column
            align="center"
            title="Humidity (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.humidityInPerc !== undefined && (
                  <Typography>
                    {`${data.min?.humidityInPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasLuxTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.min?.luxTempInC !== undefined && (
                  <Typography>
                    {`${data.min?.luxTempInC?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

      </ColumnGroup>
    );
  };

  const renderMax = () => {
    const hasDoPPM = !!aggregations.find((a) => a.max?.doPPM !== undefined);
    const hasDoPerc = !!aggregations.find((a) => a.max?.doPerc !== undefined);
    const hasDoRawPerc = !!aggregations.find(
      (a) => a.max?.doRawPerc !== undefined,
    );
    const hasTempInC = !!aggregations.find((a) => a.max?.tempInC !== undefined);
    const hasPhRawInMv = !!aggregations.find(
      (a) => a.max?.pHRawInMv !== undefined,
    );
    const hasPH = !!aggregations.find((a) => a.max?.pH !== undefined);
    const hasRawCondInMs = !!aggregations.find(
      (a) => a.max?.rawCondInMs !== undefined,
    );
    const hasCalcCondInMs = !!aggregations.find(
      (a) => a.max?.condInMs !== undefined,
    );
    const hasSalPPT = !!aggregations.find((a) => a.max?.salPPT !== undefined);
    const hasEcInA = !!aggregations.find((a) => a.max?.ecInA !== undefined);
    const hasEpInKW = !!aggregations.find((a) => a.max?.epInKW !== undefined);
    const hasLux = !!aggregations.find(
      (a) => a.max?.lux !== undefined,
    );
    const hasHumidityInPerc = !!aggregations.find(
      (a) => a.max?.humidityInPerc !== undefined,
    );
    const hasLuxTempInC = !!aggregations.find(
      (a) => a.max?.luxTempInC !== undefined,
    );

    return (
      <ColumnGroup align="center" title="MAX">
        {(hasDoRawPerc as any) && (
          <Column
            align="center"
            title="Raw DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.doRawPerc !== undefined && (
                  <Typography>
                    {`${data.max?.doRawPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPerc as any) && (
          <Column
            align="center"
            title="DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.doPerc !== undefined && (
                  <Typography>{`${data.max?.doPerc?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPPM as any) && (
          <Column
            align="center"
            title="DO (ppm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.doPPM !== undefined && (
                  <Typography>{`${data.max?.doPPM?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.tempInC !== undefined && (
                  <Typography>{`${data.max?.tempInC?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasPhRawInMv as any) && (
          <Column
            align="center"
            title="Raw PH (mV)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.pHRawInMv !== undefined && (
                  <Typography>
                    {`${data.max?.pHRawInMv?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasPH as any) && (
          <Column
            align="center"
            title="Calc PH"
            render={(data: SensorAggregationDataModel) =>
              data.max?.pH !== undefined && (
                <Typography>{`${data.max?.pH?.toFixed(1)}`}</Typography>
              )
            }
          />
        )}

        {(hasRawCondInMs as any) && (
          <Column
            align="center"
            title="Raw Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.rawCondInMs !== undefined && (
                  <Typography>
                    {`${data.max?.rawCondInMs?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasCalcCondInMs as any) && (
          <Column
            align="center"
            title="Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.condInMs !== undefined && (
                  <Typography>{`${data.max?.condInMs?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasSalPPT as any) && (
          <Column
            align="center"
            title="Salinity (ppt)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.salPPT !== undefined && (
                  <Typography>{`${data.max?.salPPT?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEcInA as any) && (
          <Column
            align="center"
            title="EC (A)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.ecInA !== undefined && (
                  <Typography>{`${data.max?.ecInA?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEpInKW as any) && (
          <Column
            align="center"
            title="EP (kW)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.epInKW !== undefined && (
                  <Typography>{`${data.max?.epInKW?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasLux as any) && (
          <Column
            align="center"
            title="LUX"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.lux !== undefined && (
                  <Typography>
                    {`${data.max?.lux?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasHumidityInPerc as any) && (
          <Column
            align="center"
            title="Humidity (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.humidityInPerc !== undefined && (
                  <Typography>
                    {`${data.max?.humidityInPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasLuxTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.max?.luxTempInC !== undefined && (
                  <Typography>
                    {`${data.max?.luxTempInC?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

      </ColumnGroup>
    );
  };

  const renderMean = () => {
    const hasDoPPM = !!aggregations.find((a) => a.mean?.doPPM !== undefined);
    const hasDoPerc = !!aggregations.find((a) => a.mean?.doPerc !== undefined);
    const hasDoRawPerc = !!aggregations.find(
      (a) => a.mean?.doRawPerc !== undefined,
    );
    const hasTempInC = !!aggregations.find(
      (a) => a.mean?.tempInC !== undefined,
    );
    const hasPhRawInMv = !!aggregations.find(
      (a) => a.mean?.pHRawInMv !== undefined,
    );
    const hasPH = !!aggregations.find((a) => a.mean?.pH !== undefined);
    const hasRawCondInMs = !!aggregations.find(
      (a) => a.mean?.rawCondInMs !== undefined,
    );
    const hasCalcCondInMs = !!aggregations.find(
      (a) => a.mean?.condInMs !== undefined,
    );
    const hasSalPPT = !!aggregations.find((a) => a.mean?.salPPT !== undefined);
    const hasEcInA = !!aggregations.find((a) => a.mean?.ecInA !== undefined);
    const hasEpInKW = !!aggregations.find((a) => a.mean?.epInKW !== undefined);
    const hasLux = !!aggregations.find(
      (a) => a.mean?.lux !== undefined,
    );
    const hasHumidityInPerc = !!aggregations.find(
      (a) => a.mean?.humidityInPerc !== undefined,
    );
    const hasLuxTempInC = !!aggregations.find(
      (a) => a.mean?.luxTempInC !== undefined,
    );

    return (
      <ColumnGroup align="center" title="MEAN">
        {(hasDoRawPerc as any) && (
          <Column
            align="center"
            title="Raw DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.doRawPerc !== undefined && (
                  <Typography>
                    {`${data.mean?.doRawPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPerc as any) && (
          <Column
            align="center"
            title="DO (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.doPerc !== undefined && (
                  <Typography>{`${data.mean?.doPerc?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasDoPPM as any) && (
          <Column
            align="center"
            title="DO (ppm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.doPPM !== undefined && (
                  <Typography>{`${data.mean?.doPPM?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}
        {(hasTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.tempInC !== undefined && (
                  <Typography>{`${data.mean?.tempInC?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasPhRawInMv as any) && (
          <Column
            align="center"
            title="Raw PH (mV)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.pHRawInMv !== undefined && (
                  <Typography>
                    {`${data.mean?.pHRawInMv?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasPH as any) && (
          <Column
            align="center"
            title="Calc PH"
            render={(data: SensorAggregationDataModel) =>
              data.mean?.pH !== undefined && (
                <Typography>{`${data.mean?.pH?.toFixed(1)}`}</Typography>
              )
            }
          />
        )}

        {(hasRawCondInMs as any) && (
          <Column
            align="center"
            title="Raw Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.rawCondInMs !== undefined && (
                  <Typography>
                    {`${data.mean?.rawCondInMs?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasCalcCondInMs as any) && (
          <Column
            align="center"
            title="Conductivity (µS/cm)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.condInMs !== undefined && (
                  <Typography>
                    {`${data.mean?.condInMs?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasSalPPT as any) && (
          <Column
            align="center"
            title="Salinity (ppt)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.salPPT !== undefined && (
                  <Typography>{`${data.mean?.salPPT?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEcInA as any) && (
          <Column
            align="center"
            title="EC (A)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.ecInA !== undefined && (
                  <Typography>{`${data.mean?.ecInA?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasEpInKW as any) && (
          <Column
            align="center"
            title="EP (kW)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.epInKW !== undefined && (
                  <Typography>{`${data.mean?.epInKW?.toFixed(1)}`}</Typography>
                )
              );
            }}
          />
        )}

        {(hasLux as any) && (
          <Column
            align="center"
            title="LUX"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.lux !== undefined && (
                  <Typography>
                    {`${data.mean?.lux?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasHumidityInPerc as any) && (
          <Column
            align="center"
            title="Humidity (%)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.humidityInPerc !== undefined && (
                  <Typography>
                    {`${data.mean?.humidityInPerc?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}

        {(hasLuxTempInC as any) && (
          <Column
            align="center"
            title="Temperature (°C)"
            render={(data: SensorAggregationDataModel) => {
              return (
                data.mean?.luxTempInC !== undefined && (
                  <Typography>
                    {`${data.mean?.luxTempInC?.toFixed(1)}`}
                  </Typography>
                )
              );
            }}
          />
        )}
      </ColumnGroup>
    );
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Row align="middle" justify="center">
          {pagination}
        </Row>
      </Col>
      <Col span={24}>
        <Table
          rowKey="timestamp"
          loading={loading}
          dataSource={aggregations}
          pagination={false}
          scroll={{ x: true }}
          bordered
        >
          <Column
            align="center"
            title="Timestamp"
            render={(data: SensorAggregationDataModel) => {
              return (
                <Typography>{moment(data.timestamp).format('lll')}</Typography>
              );
            }}
          />

          {hasMin && renderMin()}
          {hasMedian && renderMedian()}
          {hasMean && renderMean()}
          {hasMax && renderMax()}
        </Table>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="center">
          {pagination}
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const SensorAggregationDataTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorAggregationDataTable);

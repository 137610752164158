import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Card, Col, Layout, Table, Collapse, Divider } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { FeedModel, FeedTableModel } from '../../model/domain';
import { getLocations } from '../../duck/modules/locations';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { FeedsTable } from '../feed/feedsTable';
import { CreateFeedForm } from '../feed/createFeedForm';
import { FeedTablesTable } from '../feedTable/feedTablesTable';
import { CreateFeedTableForm } from '../feedTable/createFeedTableForm';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedFeedTables: React.FC<Props> = ({ location }) => {
  const [triggerReloadFeedTables, setTriggerReloadFeedTables] = useState(false);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout>
      <Content>
        <Row gutter={[0, 20]} justify="center">
          <Col span={24}>
            <Card>
              <Collapse
                bordered={false}
                ghost
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Collapse.Panel header="Create a feed table" key="1">
                  <CreateFeedTableForm
                    onCreated={(feed: FeedTableModel) => {
                      console.warn('CREATED', JSON.stringify(feed));
                      setTriggerReloadFeedTables(true);
                    }}
                    onError={(_error: Error) => {
                      console.warn('ERROR', JSON.stringify(_error));
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>

          <Col span={24}>
            <Card>
              <Collapse
                bordered={false}
                defaultActiveKey={1}
                ghost
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Collapse.Panel header="Overview" key="1">
                  <FeedTablesTable
                    reload={triggerReloadFeedTables}
                    onReloaded={() => setTriggerReloadFeedTables(false)}
                    queryParams={queryParams}
                  />
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const FeedTables = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedFeedTables));

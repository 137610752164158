import {
  FETCH_SENSOR_OPTIONS,
  FETCH_SENSOR_OPTIONS_SUCCESS,
  FETCH_SENSOR_OPTIONS_FAILED,
  FETCH_SMART_SENSOR_OPTIONS,
  FETCH_SMART_SENSOR_OPTIONS_SUCCESS,
  FETCH_SMART_SENSOR_OPTIONS_FAILED,
  FETCH_MANUFACTURER_OPTIONS,
  FETCH_MANUFACTURER_OPTIONS_SUCCESS,
  FETCH_MANUFACTURER_OPTIONS_FAILED,
  FETCH_POND_OPTIONS,
  FETCH_POND_OPTIONS_SUCCESS,
  FETCH_POND_OPTIONS_FAILED,
  FETCH_ORGANISATION_OPTIONS,
  FETCH_ORGANISATION_OPTIONS_SUCCESS,
  FETCH_ORGANISATION_OPTIONS_FAILED,
  FETCH_SENSOR_CAP_OPTIONS,
  FETCH_SENSOR_CAP_OPTIONS_SUCCESS,
  FETCH_SENSOR_CAP_OPTIONS_FAILED,
  FETCH_GATEWAY_OPTIONS,
  FETCH_GATEWAY_OPTIONS_SUCCESS,
  FETCH_GATEWAY_OPTIONS_FAILED,
} from '../../constants';
import {
  ListGatewaysQuery,
  ListGatewaysQueryModel,
  ListManufacturersQuery,
  ListManufacturersQueryModel,
  ListOrganisationsQuery,
  ListOrganisationsQueryModel,
  ListPondsQuery,
  ListPondsQueryModel,
  ListSensorCapsQuery,
  ListSensorCapsQueryModel,
  ListSensorsQuery,
  ListSensorsQueryModel,
  ListSmartSensorsQuery,
  ListSmartSensorsQueryModel,
} from '../../model/querys';

// OPTIONS

export interface FetchSensorOptionsAction {
  type: FETCH_SENSOR_OPTIONS;
  payload: {
    query: ListSensorsQuery;
  };
}

export interface FetchSensorOptionsSuccessAction {
  type: FETCH_SENSOR_OPTIONS_SUCCESS;
  payload: {
    model: ListSensorsQueryModel;
  };
}

export interface FetchSensorOptionsFailedAction {
  type: FETCH_SENSOR_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export interface FetchManufacturerOptionsAction {
  type: FETCH_MANUFACTURER_OPTIONS;
  payload: {
    query: ListManufacturersQuery;
  };
}

export interface FetchManufacturerOptionsSuccessAction {
  type: FETCH_MANUFACTURER_OPTIONS_SUCCESS;
  payload: {
    model: ListManufacturersQueryModel;
  };
}

export interface FetchManufacturerOptionsFailedAction {
  type: FETCH_MANUFACTURER_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export interface FetchGatewayOptionsAction {
  type: FETCH_GATEWAY_OPTIONS;
  payload: {
    query: ListGatewaysQuery;
  };
}

export interface FetchGatewayOptionsSuccessAction {
  type: FETCH_GATEWAY_OPTIONS_SUCCESS;
  payload: {
    model: ListGatewaysQueryModel;
  };
}

export interface FetchGatewayOptionsFailedAction {
  type: FETCH_GATEWAY_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export interface FetchSmartSensorOptionsAction {
  type: FETCH_SMART_SENSOR_OPTIONS;
  payload: {
    query: ListSmartSensorsQuery;
  };
}

export interface FetchSmartSensorOptionsSuccessAction {
  type: FETCH_SMART_SENSOR_OPTIONS_SUCCESS;
  payload: {
    model: ListSmartSensorsQueryModel;
  };
}

export interface FetchSmartSensorOptionsFailedAction {
  type: FETCH_SMART_SENSOR_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export interface FetchOrganisationOptionsAction {
  type: FETCH_ORGANISATION_OPTIONS;
  payload: {
    query: ListOrganisationsQuery;
  };
}

export interface FetchOrganisationOptionsSuccessAction {
  type: FETCH_ORGANISATION_OPTIONS_SUCCESS;
  payload: {
    model: ListOrganisationsQueryModel;
  };
}

export interface FetchOrganisationOptionsFailedAction {
  type: FETCH_ORGANISATION_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export interface FetchPondOptionsAction {
  type: FETCH_POND_OPTIONS;
  payload: {
    query: ListPondsQuery;
  };
}

export interface FetchPondOptionsSuccessAction {
  type: FETCH_POND_OPTIONS_SUCCESS;
  payload: {
    model: ListPondsQueryModel;
  };
}

export interface FetchPondOptionsFailedAction {
  type: FETCH_POND_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export interface FetchSensorCapOptionsAction {
  type: FETCH_SENSOR_CAP_OPTIONS;
  payload: {
    query: ListSensorCapsQuery;
  };
}

export interface FetchSensorCapOptionsSuccessAction {
  type: FETCH_SENSOR_CAP_OPTIONS_SUCCESS;
  payload: {
    model: ListSensorCapsQueryModel;
  };
}

export interface FetchSensorCapOptionsFailedAction {
  type: FETCH_SENSOR_CAP_OPTIONS_FAILED;
  payload: {
    error: Error;
  };
}

export type SmartSensorOptionsFetchAction =
  | FetchSmartSensorOptionsAction
  | FetchSmartSensorOptionsSuccessAction
  | FetchSmartSensorOptionsFailedAction;

export type SensorOptionsFetchAction =
  | FetchSensorOptionsAction
  | FetchSensorOptionsSuccessAction
  | FetchSensorOptionsFailedAction;

export type ManufacturerOptionsFetchAction =
  | FetchManufacturerOptionsAction
  | FetchManufacturerOptionsSuccessAction
  | FetchManufacturerOptionsFailedAction;

export type OrganisationOptionsFetchAction =
  | FetchOrganisationOptionsAction
  | FetchOrganisationOptionsSuccessAction
  | FetchOrganisationOptionsFailedAction;

export type PondOptionsFetchAction =
  | FetchPondOptionsAction
  | FetchPondOptionsSuccessAction
  | FetchPondOptionsFailedAction;

export type SensorCapOptionsFetchAction =
  | FetchSensorCapOptionsAction
  | FetchSensorCapOptionsSuccessAction
  | FetchSensorCapOptionsFailedAction;

export type GatewayOptionsFetchAction =
  | FetchGatewayOptionsAction
  | FetchGatewayOptionsSuccessAction
  | FetchGatewayOptionsFailedAction;


export type OptionsAction =
  | SensorOptionsFetchAction
  | SmartSensorOptionsFetchAction
  | ManufacturerOptionsFetchAction
  | OrganisationOptionsFetchAction
  | PondOptionsFetchAction
  | SensorCapOptionsFetchAction
  | GatewayOptionsFetchAction;

// Action creators

export function sensorOptionsFetch(
  query: ListSensorsQuery,
): FetchSensorOptionsAction {
  return {
    type: FETCH_SENSOR_OPTIONS,
    payload: {
      query,
    },
  };
}

export function sensorOptionsFetched(
  model: ListSensorsQueryModel,
): FetchSensorOptionsSuccessAction {
  return {
    type: FETCH_SENSOR_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function sensorOptionsFetchFailed(
  error: Error,
): FetchSensorOptionsFailedAction {
  return {
    type: FETCH_SENSOR_OPTIONS_FAILED,
    payload: { error },
  };
}

export function pondOptionsFetch(
  query: ListPondsQuery,
): FetchPondOptionsAction {
  return {
    type: FETCH_POND_OPTIONS,
    payload: {
      query,
    },
  };
}

export function pondOptionsFetched(
  model: ListPondsQueryModel,
): FetchPondOptionsSuccessAction {
  return {
    type: FETCH_POND_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function pondOptionsFetchFailed(
  error: Error,
): FetchPondOptionsFailedAction {
  return {
    type: FETCH_POND_OPTIONS_FAILED,
    payload: { error },
  };
}

export function organisationOptionsFetch(
  query: ListOrganisationsQuery,
): FetchOrganisationOptionsAction {
  return {
    type: FETCH_ORGANISATION_OPTIONS,
    payload: {
      query,
    },
  };
}

export function organisationOptionsFetched(
  model: ListOrganisationsQueryModel,
): FetchOrganisationOptionsSuccessAction {
  return {
    type: FETCH_ORGANISATION_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function organisationOptionsFetchFailed(
  error: Error,
): FetchOrganisationOptionsFailedAction {
  return {
    type: FETCH_ORGANISATION_OPTIONS_FAILED,
    payload: { error },
  };
}

export function smartSensorOptionsFetch(
  query: ListSmartSensorsQuery,
): FetchSmartSensorOptionsAction {
  return {
    type: FETCH_SMART_SENSOR_OPTIONS,
    payload: { query },
  };
}

export function smartSensorOptionsFetched(
  model: ListSmartSensorsQueryModel,
): FetchSmartSensorOptionsSuccessAction {
  return {
    type: FETCH_SMART_SENSOR_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function smartSensorOptionsFetchFailed(
  error: Error,
): FetchSmartSensorOptionsFailedAction {
  return {
    type: FETCH_SMART_SENSOR_OPTIONS_FAILED,
    payload: { error },
  };
}

export function manufacturerOptionsFetch(
  query: ListManufacturersQuery,
): FetchManufacturerOptionsAction {
  return {
    type: FETCH_MANUFACTURER_OPTIONS,
    payload: {
      query,
    },
  };
}

export function manufacturerOptionsFetched(
  model: ListManufacturersQueryModel,
): FetchManufacturerOptionsSuccessAction {
  return {
    type: FETCH_MANUFACTURER_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function manufacturerOptionsFetchFailed(
  error: Error,
): FetchManufacturerOptionsFailedAction {
  return {
    type: FETCH_MANUFACTURER_OPTIONS_FAILED,
    payload: { error },
  };
}

export function gatewayOptionsFetch(
  query: ListGatewaysQuery,
): FetchGatewayOptionsAction {
  return {
    type: FETCH_GATEWAY_OPTIONS,
    payload: {
      query,
    },
  };
}

export function gatewayOptionsFetched(
  model: ListGatewaysQueryModel,
): FetchGatewayOptionsSuccessAction {
  return {
    type: FETCH_GATEWAY_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function gatewayOptionsFetchFailed(
  error: Error,
): FetchGatewayOptionsFailedAction {
  return {
    type: FETCH_GATEWAY_OPTIONS_FAILED,
    payload: { error },
  };
}

export function sensorCapOptionsFetch(
  query: ListSensorCapsQuery,
): FetchSensorCapOptionsAction {
  return {
    type: FETCH_SENSOR_CAP_OPTIONS,
    payload: {
      query,
    },
  };
}

export function sensorCapOptionsFetched(
  model: ListSensorCapsQueryModel,
): FetchSensorCapOptionsSuccessAction {
  return {
    type: FETCH_SENSOR_CAP_OPTIONS_SUCCESS,
    payload: { model },
  };
}

export function sensorCapOptionsFetchFailed(
  error: Error,
): FetchSensorCapOptionsFailedAction {
  return {
    type: FETCH_SENSOR_CAP_OPTIONS_FAILED,
    payload: { error },
  };
}

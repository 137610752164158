import { Card, Descriptions, Empty, Typography } from 'antd';
import React from 'react';
import _ from 'lodash';

import { SoftwareVersion } from '../../model/domain';

type Props = {
  softwareVersion?: SoftwareVersion;
  loading?: boolean;
};

export const SoftwareVersionCard: React.FC<Props> = ({
  softwareVersion,
  loading,
}) => {
  const renderCardContent = () => {
    if (softwareVersion && !_.isEmpty(softwareVersion)) {
      return (
        <Descriptions bordered column={1} layout="horizontal">
          <Descriptions.Item label="Controller">
            <Typography.Text>
              {softwareVersion.controller ? softwareVersion.controller : '-'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Hare">
            <Typography.Text>
              {softwareVersion.hare ? softwareVersion.hare : '-'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Hedgehog">
            <Typography.Text>
              {softwareVersion.hedgehog ? softwareVersion.hedgehog : '-'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Raven">
            <Typography.Text>
              {softwareVersion.raven ? softwareVersion.raven : '-'}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      );
    }
    return <Empty description={<span>No Data </span>} />;
  };

  return (
    <Card
      title="Software Versions"
      loading={loading}
      style={{ height: '100%' }}
    >
      {renderCardContent()}
    </Card>
  );
};

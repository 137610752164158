import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateControllerCmd, UpdateControllerCmd } from '../model/cmds';
import { ControllerModel } from '../model/domain';
import {
  ListControllersQuery,
  ListControllersQueryModel,
} from '../model/querys';

export class ControllerApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createController = this.createController.bind(this);
    this.fetchController = this.fetchController.bind(this);
    this.listControllers = this.listControllers.bind(this);
    this.updateController = this.updateController.bind(this);
  }

  public createController(cmd: CreateControllerCmd): Promise<ControllerModel> {
    return this.post<
      ControllerModel,
      CreateControllerCmd,
      AxiosResponse<ControllerModel>
    >('/v1/controllers', cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchController(controllerId: string): Promise<ControllerModel> {
    return this.get<ControllerModel, AxiosResponse<ControllerModel>>(
      `/v1/controllers/${controllerId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listControllers(
    cmd?: ListControllersQuery,
  ): Promise<ListControllersQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListControllersQueryModel,
      AxiosResponse<ListControllersQueryModel>
    >('/v1/controllers', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateController(
    controllerId: string,
    cmd: UpdateControllerCmd,
  ): Promise<ControllerModel> {
    return this.patch<
      ControllerModel,
      UpdateControllerCmd,
      AxiosResponse<ControllerModel>
    >(`/v1/controllers/${controllerId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const controllerApi = new ControllerApi();

import {
  AUTHENTICATE,
  UNAUTHENTICATE,
  FETCH_ME_SUCCESS,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILED,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILED,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILED,
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_FAILED,
  FETCH_ORGANISATION,
  FETCH_ORGANISATION_SUCCESS,
  FETCH_ORGANISATION_FAILED,
  FETCH_ORGANISATIONS,
  FETCH_ORGANISATIONS_SUCCESS,
  FETCH_ORGANISATIONS_FAILED,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILED,
  FETCH_ORGANISATION_MEMBERS,
  FETCH_ORGANISATION_MEMBERS_FAILED,
  FETCH_ORGANISATION_MEMBERS_SUCCESS,
  FETCH_ORGANISATION_MEMBER_OPTIONS,
  FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS,
  FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED,
  ADD_MEMBER_TO_ORGANISATION,
  ADD_MEMBER_TO_ORGANISATION_FAILED,
  ADD_MEMBER_TO_ORGANISATION_SUCCESS,
  REMOVE_ACCOUNT_FROM_ORGANISATION,
  REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS,
  REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED,
  CREATE_FARM,
  CREATE_FARM_SUCCESS,
  CREATE_FARM_FAILED,
  FETCH_FARM,
  FETCH_FARM_SUCCESS,
  FETCH_FARM_FAILED,
  FETCH_FARMS,
  FETCH_FARMS_SUCCESS,
  FETCH_FARMS_FAILED,
  UPDATE_FARM,
  UPDATE_FARM_SUCCESS,
  UPDATE_FARM_FAILED,
  CREATE_POND,
  CREATE_POND_SUCCESS,
  CREATE_POND_FAILED,
  FETCH_POND,
  FETCH_POND_SUCCESS,
  FETCH_POND_FAILED,
  FETCH_PONDS,
  FETCH_PONDS_SUCCESS,
  FETCH_PONDS_FAILED,
  UPDATE_POND,
  UPDATE_POND_SUCCESS,
  UPDATE_POND_FAILED,
  UPDATE_POND_THRESHOLDS,
  UPDATE_POND_THRESHOLDS_SUCCESS,
  UPDATE_POND_THRESHOLDS_FAILED,
  CREATE_GATEWAY,
  CREATE_GATEWAY_SUCCESS,
  CREATE_GATEWAY_FAILED,
  FETCH_GATEWAY,
  FETCH_GATEWAY_SUCCESS,
  FETCH_GATEWAY_FAILED,
  FETCH_GATEWAYS,
  FETCH_GATEWAYS_SUCCESS,
  FETCH_GATEWAYS_FAILED,
  UPDATE_GATEWAY,
  UPDATE_GATEWAY_SUCCESS,
  UPDATE_GATEWAY_FAILED,
  CREATE_SENSOR,
  CREATE_SENSOR_SUCCESS,
  CREATE_SENSOR_FAILED,
  FETCH_SENSOR,
  FETCH_SENSOR_SUCCESS,
  FETCH_SENSOR_FAILED,
  FETCH_SENSORS,
  FETCH_SENSORS_SUCCESS,
  FETCH_SENSORS_FAILED,
  UPDATE_SENSOR,
  UPDATE_SENSOR_SUCCESS,
  UPDATE_SENSOR_FAILED,
  FETCH_ORGANISATION_SENSORS,
  FETCH_ORGANISATION_SENSORS_SUCCESS,
  FETCH_ORGANISATION_SENSORS_FAILED,
  ADD_SENSOR_TO_ORGANISATION,
  ADD_SENSOR_TO_ORGANISATION_SUCCESS,
  ADD_SENSOR_TO_ORGANISATION_FAILED,
  REMOVE_SENSOR_FROM_ORGANISATION,
  REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS,
  REMOVE_SENSOR_FROM_ORGANISATION_FAILED,
  ADD_SENSOR_TO_POND,
  ADD_SENSOR_TO_POND_SUCCESS,
  ADD_SENSOR_TO_POND_FAILED,
  REMOVE_SENSOR_FROM_POND,
  REMOVE_SENSOR_FROM_POND_SUCCESS,
  REMOVE_SENSOR_FROM_POND_FAILED,
  REFRESH_POND,
  REFRESH_POND_SUCCESS,
  REFRESH_POND_FAILED,
  CREATE_AERATOR,
  CREATE_AERATOR_SUCCESS,
  CREATE_AERATOR_FAILED,
  FETCH_AERATOR,
  FETCH_AERATOR_SUCCESS,
  FETCH_AERATOR_FAILED,
  FETCH_AERATORS,
  FETCH_AERATORS_SUCCESS,
  FETCH_AERATORS_FAILED,
  UPDATE_AERATOR,
  UPDATE_AERATOR_SUCCESS,
  UPDATE_AERATOR_FAILED,
  FETCH_SENSOR_DATA_AGGREGATIONS,
  FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS,
  FETCH_SENSOR_DATA_AGGREGATIONS_FAILED,
  SYNCRONIZE_GATEWAY,
  SYNCRONIZE_GATEWAY_SUCCESS,
  SYNCRONIZE_GATEWAY_FAILED,
  ADD_AERATOR_TO_POND,
  ADD_AERATOR_TO_POND_SUCCESS,
  ADD_AERATOR_TO_POND_FAILED,
  REMOVE_AERATOR_FROM_POND,
  REMOVE_AERATOR_FROM_POND_SUCCESS,
  REMOVE_AERATOR_FROM_POND_FAILED,
  ADD_SENSOR_TO_GATEWAY,
  ADD_SENSOR_TO_GATEWAY_SUCCESS,
  ADD_SENSOR_TO_GATEWAY_FAILED,
  REMOVE_SENSOR_FROM_GATEWAY,
  REMOVE_SENSOR_FROM_GATEWAY_SUCCESS,
  REMOVE_SENSOR_FROM_GATEWAY_FAILED,
  ADD_AERATOR_TO_GATEWAY,
  ADD_AERATOR_TO_GATEWAY_SUCCESS,
  ADD_AERATOR_TO_GATEWAY_FAILED,
  REMOVE_AERATOR_FROM_GATEWAY,
  REMOVE_AERATOR_FROM_GATEWAY_SUCCESS,
  REMOVE_AERATOR_FROM_GATEWAY_FAILED,
  ADD_POND_TO_GATEWAY,
  ADD_POND_TO_GATEWAY_SUCCESS,
  ADD_POND_TO_GATEWAY_FAILED,
  REMOVE_POND_FROM_GATEWAY,
  REMOVE_POND_FROM_GATEWAY_SUCCESS,
  REMOVE_POND_FROM_GATEWAY_FAILED,
  CREATE_CONTROLLER,
  CREATE_CONTROLLER_SUCCESS,
  CREATE_CONTROLLER_FAILED,
  FETCH_CONTROLLER,
  FETCH_CONTROLLER_SUCCESS,
  FETCH_CONTROLLER_FAILED,
  FETCH_CONTROLLERS,
  FETCH_CONTROLLERS_SUCCESS,
  FETCH_CONTROLLERS_FAILED,
  UPDATE_CONTROLLER,
  UPDATE_CONTROLLER_SUCCESS,
  UPDATE_CONTROLLER_FAILED,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILED,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILED,
  FETCH_AERATION_PROFILE,
  FETCH_AERATION_PROFILE_SUCCESS,
  FETCH_AERATION_PROFILE_FAILED,
  UPDATE_AERATION_PROFILE,
  UPDATE_AERATION_PROFILE_SUCCESS,
  UPDATE_AERATION_PROFILE_FAILED,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILED,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  CREATE_CYCLE,
  CREATE_CYCLE_SUCCESS,
  CREATE_CYCLE_FAILED,
  FETCH_CYCLE,
  FETCH_CYCLE_SUCCESS,
  FETCH_CYCLE_FAILED,
  FETCH_CYCLES,
  FETCH_CYCLES_SUCCESS,
  FETCH_CYCLES_FAILED,
  UPDATE_CYCLE,
  UPDATE_CYCLE_SUCCESS,
  UPDATE_CYCLE_FAILED,
  CREATE_SAMPLE,
  CREATE_SAMPLE_SUCCESS,
  CREATE_SAMPLE_FAILED,
  FETCH_SAMPLE,
  FETCH_SAMPLE_SUCCESS,
  FETCH_SAMPLE_FAILED,
  FETCH_SAMPLES,
  FETCH_SAMPLES_SUCCESS,
  FETCH_SAMPLES_FAILED,
  UPDATE_SAMPLE,
  UPDATE_SAMPLE_SUCCESS,
  UPDATE_SAMPLE_FAILED,
  DELETE_SAMPLE,
  DELETE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_FAILED,
  FETCH_ORGANISATION_GATEWAYS,
  FETCH_ORGANISATION_GATEWAYS_FAILED,
  FETCH_ORGANISATION_GATEWAYS_SUCCESS,
  ADD_GATEWAY_TO_ORGANISATION,
  ADD_GATEWAY_TO_ORGANISATION_SUCCESS,
  ADD_GATEWAY_TO_ORGANISATION_FAILED,
  REMOVE_GATEWAY_FROM_ORGANISATION,
  REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS,
  REMOVE_GATEWAY_FROM_ORGANISATION_FAILED,
  CREATE_FEED,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_FAILED,
  FETCH_FEED,
  FETCH_FEED_SUCCESS,
  FETCH_FEED_FAILED,
  DELETE_FEED,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAILED,
  FETCH_FEEDS,
  FETCH_FEEDS_SUCCESS,
  FETCH_FEEDS_FAILED,
  UPDATE_FEED,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_FAILED,
  CREATE_FEED_TABLE,
  CREATE_FEED_TABLE_SUCCESS,
  CREATE_FEED_TABLE_FAILED,
  FETCH_FEED_TABLE,
  FETCH_FEED_TABLE_SUCCESS,
  FETCH_FEED_TABLE_FAILED,
  DELETE_FEED_TABLE,
  DELETE_FEED_TABLE_SUCCESS,
  DELETE_FEED_TABLE_FAILED,
  FETCH_FEED_TABLES,
  FETCH_FEED_TABLES_SUCCESS,
  FETCH_FEED_TABLES_FAILED,
  UPDATE_FEED_TABLE,
  UPDATE_FEED_TABLE_SUCCESS,
  UPDATE_FEED_TABLE_FAILED,
  UPDATE_FEED_TABLE_ROWS,
  UPDATE_FEED_TABLE_ROWS_SUCCESS,
  UPDATE_FEED_TABLE_ROWS_FAILED,
  FETCH_CALIBRATION_LOG,
  FETCH_CALIBRATION_LOG_SUCCESS,
  FETCH_CALIBRATION_LOG_FAILED,
  FETCH_CALIBRATION_LOGS,
  FETCH_CALIBRATION_LOGS_SUCCESS,
  FETCH_CALIBRATION_LOGS_FAILED,
  CREATE_SMART_SENSOR,
  CREATE_SMART_SENSOR_SUCCESS,
  CREATE_SMART_SENSOR_FAILED,
  FETCH_SMART_SENSOR,
  FETCH_SMART_SENSOR_SUCCESS,
  FETCH_SMART_SENSOR_FAILED,
  ADD_SMART_SENSOR_TO_GATEWAY,
  ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS,
  ADD_SMART_SENSOR_TO_GATEWAY_FAILED,
  REMOVE_SMART_SENSOR_FROM_GATEWAY,
  REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS,
  REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED,
  ADD_LCM_TO_GATEWAY,
  ADD_LCM_TO_GATEWAY_SUCCESS,
  ADD_LCM_TO_GATEWAY_FAILED,
  REMOVE_LCM_FROM_GATEWAY,
  REMOVE_LCM_FROM_GATEWAY_SUCCESS,
  REMOVE_LCM_FROM_GATEWAY_FAILED,
  ADD_PUMP_TO_GATEWAY,
  ADD_PUMP_TO_GATEWAY_SUCCESS,
  ADD_PUMP_TO_GATEWAY_FAILED,
  REMOVE_PUMP_FROM_GATEWAY,
  REMOVE_PUMP_FROM_GATEWAY_SUCCESS,
  REMOVE_PUMP_FROM_GATEWAY_FAILED,
  ADD_PUMP_TO_POND,
  ADD_PUMP_TO_POND_SUCCESS,
  ADD_PUMP_TO_POND_FAILED,
  REMOVE_PUMP_FROM_POND,
  REMOVE_PUMP_FROM_POND_SUCCESS,
  REMOVE_PUMP_FROM_POND_FAILED,
  UPDATE_BATCH_INFORMATION,
  UPDATE_BATCH_INFORMATION_SUCCESS,
  UPDATE_BATCH_INFORMATION_FAILED,
  SWAP_GATEWAYS,
  SWAP_GATEWAYS_FAILED,
  SWAP_GATEWAYS_SUCCESS,
  UPDATE_ACCOUNT_ROLE,
  UPDATE_ACCOUNT_ROLE_FAILED,
  UPDATE_ACCOUNT_ROLE_SUCCESS,
  COUNT_ACCOUNTS,
  COUNT_ACCOUNTS_SUCCESS,
  COUNT_ACCOUNTS_FAILED,
  COUNT_FARMS,
  COUNT_FARMS_SUCCESS,
  COUNT_FARMS_FAILED,
  COUNT_PONDS,
  COUNT_PONDS_SUCCESS,
  COUNT_PONDS_FAILED,
} from '../../constants';
import {
  AuthCredentials,
  AccountModel,
  OrganisationModel,
  FarmModel,
  PondModel,
  GatewayModel,
  SensorModel,
  AeratorModel,
  ControllerModel,
  LocationModel,
  AerationProfileModel,
  NotificationModel,
  CycleModel,
  SampleModel,
  FeedModel,
  FeedTableModel,
  SensorCalibrationLogModel,
} from '../../model/domain';
import {
  ListAccountsQuery,
  ListAccountsQueryModel,
  ListOrganisationsQuery,
  ListOrganisationsQueryModel,
  ListFarmsQuery,
  ListFarmsQueryModel,
  ListPondsQuery,
  ListPondsQueryModel,
  ListGatewaysQuery,
  ListGatewaysQueryModel,
  ListSensorsQuery,
  ListSensorsQueryModel,
  ListAeratorsQuery,
  ListAeratorsQueryModel,
  ListSensorDataAggregationQuery,
  ListSensorDataAggregationQueryModel,
  ListControllersQuery,
  ListControllersQueryModel,
  ListLocationsQuery,
  ListLocationsQueryModel,
  ListNotificationsQuery,
  ListNotificationsQueryModel,
  ListCyclesQuery,
  ListCyclesQueryModel,
  ListSamplesQuery,
  ListSamplesQueryModel,
  ListFeedsQuery,
  ListFeedsQueryModel,
  ListFeedTablesQuery,
  ListFeedTablesQueryModel,
  ListCalibrationLogsQuery,
  ListCalibrationLogsQueryModel,
  CountAccountsModel,
  CountFarmsModel,
  CountPondsModel,
} from '../../model/querys';
import {
  SimpleCycleModel,
  SimpleFeedTableModel,
  SimpleSensorCalibrationLogModel,
} from '../../model/simple';

// Actions
export interface AuthenticateAction {
  type: AUTHENTICATE;
  payload: AuthCredentials;
}

export interface UnauthenticateAction {
  type: UNAUTHENTICATE;
  payload: Error | null;
}

export interface FetchMeSucessAction {
  type: FETCH_ME_SUCCESS;
  payload: AccountModel;
}

// Action creators
export function authenticated(response: AuthCredentials): AuthenticateAction {
  return {
    type: AUTHENTICATE,
    payload: response,
  };
}

export function unauthenticated(error: Error | null): UnauthenticateAction {
  return {
    type: UNAUTHENTICATE,
    payload: error,
  };
}

export type AuthenticationAction =
  | AuthenticateAction
  | UnauthenticateAction
  | FetchMeSucessAction;

// Actions
export interface CreateAccountAction {
  type: CREATE_ACCOUNT;
}

export interface CreateAccountSuccessAction {
  type: CREATE_ACCOUNT_SUCCESS;
  payload: AccountModel;
}

export interface CreateAccountFailedAction {
  type: CREATE_ACCOUNT_FAILED;
  payload: Error;
}

export interface UpdateAccountAction {
  type: UPDATE_ACCOUNT;
}

export interface UpdateAccountSuccessAction {
  type: UPDATE_ACCOUNT_SUCCESS;
  payload: AccountModel;
}

export interface UpdateAccountFailedAction {
  type: UPDATE_ACCOUNT_FAILED;
  payload: Error;
}

export interface UpdateAccountRoleAction {
  type: UPDATE_ACCOUNT_ROLE;
}

export interface UpdateAccountRoleSuccessAction {
  type: UPDATE_ACCOUNT_ROLE_SUCCESS;
  payload: AccountModel;
}

export interface UpdateAccountRoleFailedAction {
  type: UPDATE_ACCOUNT_ROLE_FAILED;
  payload: Error;
}

export interface FetchAccountAction {
  type: FETCH_ACCOUNT;
}

export interface FetchAccountSuccessAction {
  type: FETCH_ACCOUNT_SUCCESS;
  payload: AccountModel;
}

export interface FetchAccountFailedAction {
  type: FETCH_ACCOUNT_FAILED;
  payload: Error;
}

export interface FetchAccountsAction {
  type: FETCH_ACCOUNTS;
  payload: ListAccountsQuery;
}

export interface FetchAccountsSuccessAction {
  type: FETCH_ACCOUNTS_SUCCESS;
  payload: {
    query: ListAccountsQueryModel;
    accounts: AccountModel[];
  };
}

export interface FetchAccountsFailedAction {
  type: FETCH_ACCOUNTS_FAILED;
  payload: Error;
}

export interface CountAccountsAction {
  type: COUNT_ACCOUNTS;
}

export interface CountAccountsSuccessAction {
  type: COUNT_ACCOUNTS_SUCCESS;
  payload: {
    counts: CountAccountsModel;
  };
}

export interface CountAccountsFailedAction {
  type: COUNT_ACCOUNTS_FAILED;
  payload: Error;
}

export type AccountUpdateAction =
  | UpdateAccountAction
  | UpdateAccountSuccessAction
  | UpdateAccountFailedAction;
export type AccountUpdateRoleAction =
  | UpdateAccountRoleAction
  | UpdateAccountRoleSuccessAction
  | UpdateAccountRoleFailedAction;
export type AccountCreateAction =
  | CreateAccountAction
  | CreateAccountSuccessAction
  | CreateAccountFailedAction;
export type AccountFetchAction =
  | FetchAccountAction
  | FetchAccountSuccessAction
  | FetchAccountFailedAction;
export type AccountsFetchAction =
  | FetchAccountsAction
  | FetchAccountsSuccessAction
  | FetchAccountsFailedAction;
export type AccountCountAction =
  | CountAccountsAction
  | CountAccountsSuccessAction
  | CountAccountsFailedAction;

export type AccountsAction =
  | AccountCreateAction
  | AccountsFetchAction
  | AccountFetchAction
  | AccountUpdateAction
  | AccountUpdateRoleAction
  | AccountCountAction;

export function accountCreate(): CreateAccountAction {
  return {
    type: CREATE_ACCOUNT,
  };
}

export function accountCreated(
  account: AccountModel,
): CreateAccountSuccessAction {
  return {
    type: CREATE_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function accountCreateFailed(error: Error): CreateAccountFailedAction {
  return {
    type: CREATE_ACCOUNT_FAILED,
    payload: error,
  };
}

export function accountUpdate(): UpdateAccountAction {
  return {
    type: UPDATE_ACCOUNT,
  };
}

export function accountUpdated(
  account: AccountModel,
): UpdateAccountSuccessAction {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function accountUpdateFailed(error: Error): UpdateAccountFailedAction {
  return {
    type: UPDATE_ACCOUNT_FAILED,
    payload: error,
  };
}

export function accountRoleUpdate(): UpdateAccountRoleAction {
  return {
    type: UPDATE_ACCOUNT_ROLE,
  };
}

export function accountRoleUpdated(
  account: AccountModel,
): UpdateAccountRoleSuccessAction {
  return {
    type: UPDATE_ACCOUNT_ROLE_SUCCESS,
    payload: account,
  };
}

export function accountRoleUpdateFailed(
  error: Error,
): UpdateAccountRoleFailedAction {
  return {
    type: UPDATE_ACCOUNT_ROLE_FAILED,
    payload: error,
  };
}

export function accountFetch(): FetchAccountAction {
  return {
    type: FETCH_ACCOUNT,
  };
}

export function accountFetched(
  account: AccountModel,
): FetchAccountSuccessAction {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function accountFetchFailed(error: Error): FetchAccountFailedAction {
  return {
    type: FETCH_ACCOUNT_FAILED,
    payload: error,
  };
}

export function accountsFetch(query: ListAccountsQuery): FetchAccountsAction {
  return {
    type: FETCH_ACCOUNTS,
    payload: query,
  };
}

export function accountsFetched(
  query: ListAccountsQueryModel,
  accounts: AccountModel[],
): FetchAccountsSuccessAction {
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    payload: { query, accounts },
  };
}

export function accountsFetchFailed(error: Error): FetchAccountsFailedAction {
  return {
    type: FETCH_ACCOUNTS_FAILED,
    payload: error,
  };
}

export function accountsCount(): CountAccountsAction {
  return {
    type: COUNT_ACCOUNTS,
  };
}

export function accountsCounted(
  counts: CountAccountsModel,
): CountAccountsSuccessAction {
  return {
    type: COUNT_ACCOUNTS_SUCCESS,
    payload: { counts },
  };
}

export function accountsCountFailed(error: Error): CountAccountsFailedAction {
  return {
    type: COUNT_ACCOUNTS_FAILED,
    payload: error,
  };
}

// FARMS
export interface CreateFarmAction {
  type: CREATE_FARM;
}

export interface CreateFarmSuccessAction {
  type: CREATE_FARM_SUCCESS;
  payload: FarmModel;
}

export interface CreateFarmFailedAction {
  type: CREATE_FARM_FAILED;
  payload: Error;
}

export interface FetchFarmAction {
  type: FETCH_FARM;
}

export interface FetchFarmSuccessAction {
  type: FETCH_FARM_SUCCESS;
  payload: FarmModel;
}

export interface FetchFarmFailedAction {
  type: FETCH_FARM_FAILED;
  payload: Error;
}

export interface FetchFarmsAction {
  type: FETCH_FARMS;
  payload: ListFarmsQuery;
}

export interface FetchFarmsSuccessAction {
  type: FETCH_FARMS_SUCCESS;
  payload: {
    query: ListFarmsQueryModel;
    farms: FarmModel[];
  };
}

export interface FetchFarmsFailedAction {
  type: FETCH_FARMS_FAILED;
  payload: Error;
}

export interface UpdateFarmAction {
  type: UPDATE_FARM;
}

export interface UpdateFarmSuccessAction {
  type: UPDATE_FARM_SUCCESS;
  payload: FarmModel;
}

export interface UpdateFarmFailedAction {
  type: UPDATE_FARM_FAILED;
  payload: Error;
}

export interface CountFarmsAction {
  type: COUNT_FARMS;
}

export interface CountFarmsSuccessAction {
  type: COUNT_FARMS_SUCCESS;
  payload: {
    counts: CountFarmsModel;
  };
}

export interface CountFarmsFailedAction {
  type: COUNT_FARMS_FAILED;
  payload: Error;
}


export type FarmUpdateAction =
  | UpdateFarmAction
  | UpdateFarmSuccessAction
  | UpdateFarmFailedAction;
export type FarmCreateAction =
  | CreateFarmAction
  | CreateFarmSuccessAction
  | CreateFarmFailedAction;
export type FarmFetchAction =
  | FetchFarmAction
  | FetchFarmSuccessAction
  | FetchFarmFailedAction;
export type FarmsFetchAction =
  | FetchFarmsAction
  | FetchFarmsSuccessAction
  | FetchFarmsFailedAction;
export type FarmsCountAction =
  | CountFarmsAction
  | CountFarmsSuccessAction
  | CountFarmsFailedAction;
export type FarmsAction =
  | FarmCreateAction
  | FarmFetchAction
  | FarmsFetchAction
  | FarmUpdateAction
  | FarmsCountAction;

// Action creators
export function farmCreate(): CreateFarmAction {
  return {
    type: CREATE_FARM,
  };
}

export function farmCreated(farm: FarmModel): CreateFarmSuccessAction {
  return {
    type: CREATE_FARM_SUCCESS,
    payload: farm,
  };
}

export function farmCreateFailed(error: Error): CreateFarmFailedAction {
  return {
    type: CREATE_FARM_FAILED,
    payload: error,
  };
}

export function farmFetch(): FetchFarmAction {
  return {
    type: FETCH_FARM,
  };
}

export function farmFetched(farm: FarmModel): FetchFarmSuccessAction {
  return {
    type: FETCH_FARM_SUCCESS,
    payload: farm,
  };
}

export function farmFetchFailed(error: Error): FetchFarmFailedAction {
  return {
    type: FETCH_FARM_FAILED,
    payload: error,
  };
}

export function farmsFetch(query: ListFarmsQuery): FetchFarmsAction {
  return {
    type: FETCH_FARMS,
    payload: query,
  };
}

export function farmsFetched(
  query: ListFarmsQueryModel,
  farms: FarmModel[],
): FetchFarmsSuccessAction {
  return {
    type: FETCH_FARMS_SUCCESS,
    payload: { query, farms },
  };
}

export function farmsFetchFailed(error: Error): FetchFarmsFailedAction {
  return {
    type: FETCH_FARMS_FAILED,
    payload: error,
  };
}

export function farmUpdate(): UpdateFarmAction {
  return {
    type: UPDATE_FARM,
  };
}

export function farmUpdated(farm: FarmModel): UpdateFarmSuccessAction {
  return {
    type: UPDATE_FARM_SUCCESS,
    payload: farm,
  };
}

export function farmUpdateFailed(error: Error): UpdateFarmFailedAction {
  return {
    type: UPDATE_FARM_FAILED,
    payload: error,
  };
}

export function farmsCount(): CountFarmsAction {
  return {
    type: COUNT_FARMS,
  };
}

export function farmsCounted(
  counts: CountFarmsModel,
): CountFarmsSuccessAction {
  return {
    type: COUNT_FARMS_SUCCESS,
    payload: { counts },
  };
}

export function farmsCountFailed(error: Error): CountFarmsFailedAction {
  return {
    type: COUNT_FARMS_FAILED,
    payload: error,
  };
}

// PONDS
export interface CreatePondAction {
  type: CREATE_POND;
}

export interface CreatePondSuccessAction {
  type: CREATE_POND_SUCCESS;
  payload: PondModel;
}

export interface CreatePondFailedAction {
  type: CREATE_POND_FAILED;
  payload: Error;
}

export interface FetchPondAction {
  type: FETCH_POND;
}

export interface FetchPondSuccessAction {
  type: FETCH_POND_SUCCESS;
  payload: PondModel;
}

export interface FetchPondFailedAction {
  type: FETCH_POND_FAILED;
  payload: Error;
}

export interface RefreshPondAction {
  type: REFRESH_POND;
}

export interface RefreshPondSuccessAction {
  type: REFRESH_POND_SUCCESS;
  payload: PondModel;
}

export interface RefreshPondFailedAction {
  type: REFRESH_POND_FAILED;
  payload: Error;
}

export interface FetchPondsAction {
  type: FETCH_PONDS;
  payload: ListPondsQuery;
}

export interface FetchPondsSuccessAction {
  type: FETCH_PONDS_SUCCESS;
  payload: ListPondsQueryModel;
}

export interface FetchPondsFailedAction {
  type: FETCH_PONDS_FAILED;
  payload: Error;
}

export interface CountPondsAction {
  type: COUNT_PONDS;
}

export interface CountPondsSuccessAction {
  type: COUNT_PONDS_SUCCESS;
  payload: CountPondsModel;
}

export interface CountPondsFailedAction {
  type: COUNT_PONDS_FAILED;
  payload: Error;
}

export interface UpdatePondAction {
  type: UPDATE_POND;
}

export interface UpdatePondSuccessAction {
  type: UPDATE_POND_SUCCESS;
  payload: PondModel;
}

export interface UpdatePondFailedAction {
  type: UPDATE_POND_FAILED;
  payload: Error;
}

export interface UpdatePondThresholdsAction {
  type: UPDATE_POND_THRESHOLDS;
}

export interface UpdatePondThresholdsSuccessAction {
  type: UPDATE_POND_THRESHOLDS_SUCCESS;
  payload: PondModel;
}

export interface UpdatePondThresholdsFailedAction {
  type: UPDATE_POND_THRESHOLDS_FAILED;
  payload: Error;
}

export interface AddSensorToPondAction {
  type: ADD_SENSOR_TO_POND;
  payload: {
    pondId: string;
    sensorId: string;
  };
}

export interface AddSensorToPondSuccessAction {
  type: ADD_SENSOR_TO_POND_SUCCESS;
  payload: PondModel;
}

export interface AddSensorToPondFailedAction {
  type: ADD_SENSOR_TO_POND_FAILED;
  payload: Error;
}

export interface RemoveSensorFromPondAction {
  type: REMOVE_SENSOR_FROM_POND;
  payload: {
    pondId: string;
    sensorId: string;
  };
}

export interface RemoveSensorFromPondSuccessAction {
  type: REMOVE_SENSOR_FROM_POND_SUCCESS;
  payload: PondModel;
}

export interface RemoveSensorFromPondFailedAction {
  type: REMOVE_SENSOR_FROM_POND_FAILED;
  payload: Error;
}

export interface AddAeratorToPondAction {
  type: ADD_AERATOR_TO_POND;
  payload: {
    pondId: string;
    aeratorId: string;
  };
}

export interface AddAeratorToPondSuccessAction {
  type: ADD_AERATOR_TO_POND_SUCCESS;
  payload: PondModel;
}

export interface AddAeratorToPondFailedAction {
  type: ADD_AERATOR_TO_POND_FAILED;
  payload: Error;
}

export interface RemoveAeratorFromPondAction {
  type: REMOVE_AERATOR_FROM_POND;
  payload: {
    pondId: string;
    aeratorId: string;
  };
}

export interface RemoveAeratorFromPondSuccessAction {
  type: REMOVE_AERATOR_FROM_POND_SUCCESS;
  payload: PondModel;
}

export interface RemoveAeratorFromPondFailedAction {
  type: REMOVE_AERATOR_FROM_POND_FAILED;
  payload: Error;
}

export interface AddPumpToPondAction {
  type: ADD_PUMP_TO_POND;
  payload: {
    pondId: string;
    pumpId: string;
  };
}

export interface AddPumpToPondSuccessAction {
  type: ADD_PUMP_TO_POND_SUCCESS;
  payload: PondModel;
}

export interface AddPumpToPondFailedAction {
  type: ADD_PUMP_TO_POND_FAILED;
  payload: Error;
}

export interface RemovePumpFromPondAction {
  type: REMOVE_PUMP_FROM_POND;
  payload: {
    pondId: string;
    pumpId: string;
  };
}

export interface RemovePumpFromPondSuccessAction {
  type: REMOVE_PUMP_FROM_POND_SUCCESS;
  payload: PondModel;
}

export interface RemovePumpFromPondFailedAction {
  type: REMOVE_PUMP_FROM_POND_FAILED;
  payload: Error;
}

export type PondRemovePumpAction =
  | RemovePumpFromPondAction
  | RemovePumpFromPondSuccessAction
  | RemovePumpFromPondFailedAction;
export type PondAddPumpAction =
  | AddPumpToPondAction
  | AddPumpToPondSuccessAction
  | AddPumpToPondFailedAction;
export type PondRemoveAeratorAction =
  | RemoveAeratorFromPondAction
  | RemoveAeratorFromPondSuccessAction
  | RemoveAeratorFromPondFailedAction;
export type PondAddAeratorAction =
  | AddAeratorToPondAction
  | AddAeratorToPondSuccessAction
  | AddAeratorToPondFailedAction;
export type PondRefreshAction =
  | RefreshPondAction
  | RefreshPondSuccessAction
  | RefreshPondFailedAction;
export type PondRemoveSensorAction =
  | RemoveSensorFromPondAction
  | RemoveSensorFromPondSuccessAction
  | RemoveSensorFromPondFailedAction;
export type PondAddSensorAction =
  | AddSensorToPondAction
  | AddSensorToPondSuccessAction
  | AddSensorToPondFailedAction;
export type PondThresholdsUpdateAction =
  | UpdatePondThresholdsAction
  | UpdatePondThresholdsSuccessAction
  | UpdatePondThresholdsFailedAction;
export type PondUpdateAction =
  | UpdatePondAction
  | UpdatePondSuccessAction
  | UpdatePondFailedAction;
export type PondCreateAction =
  | CreatePondAction
  | CreatePondSuccessAction
  | CreatePondFailedAction;
export type PondFetchAction =
  | FetchPondAction
  | FetchPondSuccessAction
  | FetchPondFailedAction;
export type PondsFetchAction =
  | FetchPondsAction
  | FetchPondsSuccessAction
  | FetchPondsFailedAction;
export type PondsCountAction =
  | CountPondsAction
  | CountPondsSuccessAction
  | CountPondsFailedAction;
export type PondsAction =
  | PondCreateAction
  | PondFetchAction
  | PondsFetchAction
  | PondUpdateAction
  | PondThresholdsUpdateAction
  | PondAddSensorAction
  | PondRemoveSensorAction
  | PondRefreshAction
  | PondAddAeratorAction
  | PondRemoveAeratorAction
  | PondAddPumpAction
  | PondRemovePumpAction
  | PondsCountAction;

// Action creators
export function pondCreate(): CreatePondAction {
  return {
    type: CREATE_POND,
  };
}

export function pondCreated(pond: PondModel): CreatePondSuccessAction {
  return {
    type: CREATE_POND_SUCCESS,
    payload: pond,
  };
}

export function pondCreateFailed(error: Error): CreatePondFailedAction {
  return {
    type: CREATE_POND_FAILED,
    payload: error,
  };
}

export function pondFetch(): FetchPondAction {
  return {
    type: FETCH_POND,
  };
}

export function pondFetched(pond: PondModel): FetchPondSuccessAction {
  return {
    type: FETCH_POND_SUCCESS,
    payload: pond,
  };
}

export function pondFetchFailed(error: Error): FetchPondFailedAction {
  return {
    type: FETCH_POND_FAILED,
    payload: error,
  };
}

export function pondRefresh(): RefreshPondAction {
  return {
    type: REFRESH_POND,
  };
}

export function pondRefreshed(pond: PondModel): RefreshPondSuccessAction {
  return {
    type: REFRESH_POND_SUCCESS,
    payload: pond,
  };
}

export function pondRefreshFailed(error: Error): RefreshPondFailedAction {
  return {
    type: REFRESH_POND_FAILED,
    payload: error,
  };
}

export function pondsFetch(query: ListPondsQuery): FetchPondsAction {
  return {
    type: FETCH_PONDS,
    payload: query,
  };
}

export function pondsFetched(
  query: ListPondsQueryModel,
): FetchPondsSuccessAction {
  return {
    type: FETCH_PONDS_SUCCESS,
    payload: query,
  };
}

export function pondsFetchFailed(error: Error): FetchPondsFailedAction {
  return {
    type: FETCH_PONDS_FAILED,
    payload: error,
  };
}

export function pondsCount(): CountPondsAction {
  return {
    type: COUNT_PONDS,
  };
}

export function pondsCounted(
  counts: CountPondsModel,
): CountPondsSuccessAction {
  return {
    type: COUNT_PONDS_SUCCESS,
    payload: counts,
  };
}

export function pondsCountFailed(error: Error): CountPondsFailedAction {
  return {
    type: COUNT_PONDS_FAILED,
    payload: error,
  };
}

export function pondUpdate(): UpdatePondAction {
  return {
    type: UPDATE_POND,
  };
}

export function pondUpdated(pond: PondModel): UpdatePondSuccessAction {
  return {
    type: UPDATE_POND_SUCCESS,
    payload: pond,
  };
}

export function pondUpdateFailed(error: Error): UpdatePondFailedAction {
  return {
    type: UPDATE_POND_FAILED,
    payload: error,
  };
}

export function pondThresholdsUpdate(): UpdatePondThresholdsAction {
  return {
    type: UPDATE_POND_THRESHOLDS,
  };
}

export function pondThresholdsUpdated(
  pond: PondModel,
): UpdatePondThresholdsSuccessAction {
  return {
    type: UPDATE_POND_THRESHOLDS_SUCCESS,
    payload: pond,
  };
}

export function pondThresholdsUpdateFailed(
  error: Error,
): UpdatePondThresholdsFailedAction {
  return {
    type: UPDATE_POND_THRESHOLDS_FAILED,
    payload: error,
  };
}

export function pondSensorAdd(
  pondId: string,
  sensorId: string,
): AddSensorToPondAction {
  return {
    type: ADD_SENSOR_TO_POND,
    payload: {
      pondId,
      sensorId,
    },
  };
}

export function pondSensorAdded(pond: PondModel): AddSensorToPondSuccessAction {
  return {
    type: ADD_SENSOR_TO_POND_SUCCESS,
    payload: pond,
  };
}

export function pondSensorAddFailed(error: Error): AddSensorToPondFailedAction {
  return {
    type: ADD_SENSOR_TO_POND_FAILED,
    payload: error,
  };
}

export function pondSensorRemove(
  pondId: string,
  sensorId: string,
): RemoveSensorFromPondAction {
  return {
    type: REMOVE_SENSOR_FROM_POND,
    payload: {
      pondId,
      sensorId,
    },
  };
}

export function pondSensorRemoved(
  pond: PondModel,
): RemoveSensorFromPondSuccessAction {
  return {
    type: REMOVE_SENSOR_FROM_POND_SUCCESS,
    payload: pond,
  };
}

export function pondSensorRemoveFailed(
  error: Error,
): RemoveSensorFromPondFailedAction {
  return {
    type: REMOVE_SENSOR_FROM_POND_FAILED,
    payload: error,
  };
}

export function pondAeratorAdd(
  pondId: string,
  aeratorId: string,
): AddAeratorToPondAction {
  return {
    type: ADD_AERATOR_TO_POND,
    payload: {
      pondId,
      aeratorId,
    },
  };
}

export function pondAeratorAdded(
  pond: PondModel,
): AddAeratorToPondSuccessAction {
  return {
    type: ADD_AERATOR_TO_POND_SUCCESS,
    payload: pond,
  };
}

export function pondAeratorAddFailed(
  error: Error,
): AddAeratorToPondFailedAction {
  return {
    type: ADD_AERATOR_TO_POND_FAILED,
    payload: error,
  };
}

export function pondAeratorRemove(
  pondId: string,
  aeratorId: string,
): RemoveAeratorFromPondAction {
  return {
    type: REMOVE_AERATOR_FROM_POND,
    payload: {
      pondId,
      aeratorId,
    },
  };
}

export function pondAeratorRemoved(
  pond: PondModel,
): RemoveAeratorFromPondSuccessAction {
  return {
    type: REMOVE_AERATOR_FROM_POND_SUCCESS,
    payload: pond,
  };
}

export function pondAeratorRemoveFailed(
  error: Error,
): RemoveAeratorFromPondFailedAction {
  return {
    type: REMOVE_AERATOR_FROM_POND_FAILED,
    payload: error,
  };
}

export function pondPumpAdd(
  pondId: string,
  pumpId: string,
): AddPumpToPondAction {
  return {
    type: ADD_PUMP_TO_POND,
    payload: {
      pondId,
      pumpId,
    },
  };
}

export function pondPumpAdded(pond: PondModel): AddPumpToPondSuccessAction {
  return {
    type: ADD_PUMP_TO_POND_SUCCESS,
    payload: pond,
  };
}

export function pondPumpAddFailed(error: Error): AddPumpToPondFailedAction {
  return {
    type: ADD_PUMP_TO_POND_FAILED,
    payload: error,
  };
}

export function pondPumpRemove(
  pondId: string,
  pumpId: string,
): RemovePumpFromPondAction {
  return {
    type: REMOVE_PUMP_FROM_POND,
    payload: {
      pondId,
      pumpId,
    },
  };
}

export function pondPumpRemoved(
  pond: PondModel,
): RemovePumpFromPondSuccessAction {
  return {
    type: REMOVE_PUMP_FROM_POND_SUCCESS,
    payload: pond,
  };
}

export function pondPumpRemoveFailed(
  error: Error,
): RemovePumpFromPondFailedAction {
  return {
    type: REMOVE_PUMP_FROM_POND_FAILED,
    payload: error,
  };
}

// GATEWAYS
export interface CreateGatewayAction {
  type: CREATE_GATEWAY;
}

export interface CreateGatewaySuccessAction {
  type: CREATE_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface CreateGatewayFailedAction {
  type: CREATE_GATEWAY_FAILED;
  payload: Error;
}

export interface FetchGatewayAction {
  type: FETCH_GATEWAY;
}

export interface FetchGatewaySuccessAction {
  type: FETCH_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface FetchGatewayFailedAction {
  type: FETCH_GATEWAY_FAILED;
  payload: Error;
}

export interface FetchGatewaysAction {
  type: FETCH_GATEWAYS;
  payload: ListGatewaysQuery;
}

export interface FetchGatewaysSuccessAction {
  type: FETCH_GATEWAYS_SUCCESS;
  payload: {
    query: ListGatewaysQueryModel;
    gateways: GatewayModel[];
  };
}

export interface FetchGatewaysFailedAction {
  type: FETCH_GATEWAYS_FAILED;
  payload: Error;
}

export interface FetchOrganisationGatewaysAction {
  type: FETCH_ORGANISATION_GATEWAYS;
  payload: ListGatewaysQuery;
}

export interface FetchOrganisationGatewaysSuccessAction {
  type: FETCH_ORGANISATION_GATEWAYS_SUCCESS;
  payload: {
    query: ListGatewaysQueryModel;
    gateways: GatewayModel[];
  };
}

export interface FetchOrganisationGatewaysFailedAction {
  type: FETCH_ORGANISATION_GATEWAYS_FAILED;
  payload: Error;
}

export interface UpdateGatewayAction {
  type: UPDATE_GATEWAY;
}

export interface UpdateGatewaySuccessAction {
  type: UPDATE_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface UpdateGatewayFailedAction {
  type: UPDATE_GATEWAY_FAILED;
  payload: Error;
}

export interface SyncronizeGatewayAction {
  type: SYNCRONIZE_GATEWAY;
}

export interface SyncronizeGatewaySuccessAction {
  type: SYNCRONIZE_GATEWAY_SUCCESS;
}

export interface SyncronizeGatewayFailedAction {
  type: SYNCRONIZE_GATEWAY_FAILED;
  payload: Error;
}

export interface AddSensorToGatewayAction {
  type: ADD_SENSOR_TO_GATEWAY;
  payload: {
    gatewayId: string;
    sensorId: string;
  };
}

export interface AddSensorToGatewaySuccessAction {
  type: ADD_SENSOR_TO_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface AddSensorToGatewayFailedAction {
  type: ADD_SENSOR_TO_GATEWAY_FAILED;
  payload: Error;
}

export interface RemoveSensorFromGatewayAction {
  type: REMOVE_SENSOR_FROM_GATEWAY;
  payload: {
    gatewayId: string;
    sensorId: string;
  };
}

export interface RemoveSensorFromGatewaySuccessAction {
  type: REMOVE_SENSOR_FROM_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface RemoveSensorFromGatewayFailedAction {
  type: REMOVE_SENSOR_FROM_GATEWAY_FAILED;
  payload: Error;
}

export interface AddAeratorToGatewayAction {
  type: ADD_AERATOR_TO_GATEWAY;
  payload: {
    gatewayId: string;
    aeratorId: string;
  };
}

export interface AddAeratorToGatewaySuccessAction {
  type: ADD_AERATOR_TO_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface AddAeratorToGatewayFailedAction {
  type: ADD_AERATOR_TO_GATEWAY_FAILED;
  payload: Error;
}

export interface RemoveAeratorFromGatewayAction {
  type: REMOVE_AERATOR_FROM_GATEWAY;
  payload: {
    gatewayId: string;
    aeratorId: string;
  };
}

export interface RemoveAeratorFromGatewaySuccessAction {
  type: REMOVE_AERATOR_FROM_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface RemoveAeratorFromGatewayFailedAction {
  type: REMOVE_AERATOR_FROM_GATEWAY_FAILED;
  payload: Error;
}

export interface AddPumpToGatewayAction {
  type: ADD_PUMP_TO_GATEWAY;
  payload: {
    gatewayId: string;
    pumpId: string;
  };
}

export interface AddPumpToGatewaySuccessAction {
  type: ADD_PUMP_TO_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface AddPumpToGatewayFailedAction {
  type: ADD_PUMP_TO_GATEWAY_FAILED;
  payload: Error;
}

export interface RemovePumpFromGatewayAction {
  type: REMOVE_PUMP_FROM_GATEWAY;
  payload: {
    gatewayId: string;
    pumpId: string;
  };
}

export interface RemovePumpFromGatewaySuccessAction {
  type: REMOVE_PUMP_FROM_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface RemovePumpFromGatewayFailedAction {
  type: REMOVE_PUMP_FROM_GATEWAY_FAILED;
  payload: Error;
}

export interface AddPondToGatewayAction {
  type: ADD_POND_TO_GATEWAY;
  payload: {
    gatewayId: string;
    pondId: string;
  };
}

export interface AddPondToGatewaySuccessAction {
  type: ADD_POND_TO_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface AddPondToGatewayFailedAction {
  type: ADD_POND_TO_GATEWAY_FAILED;
  payload: Error;
}

export interface RemovePondFromGatewayAction {
  type: REMOVE_POND_FROM_GATEWAY;
  payload: {
    gatewayId: string;
    pondId: string;
  };
}

export interface RemovePondFromGatewaySuccessAction {
  type: REMOVE_POND_FROM_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface RemovePondFromGatewayFailedAction {
  type: REMOVE_POND_FROM_GATEWAY_FAILED;
  payload: Error;
}

export interface AddSmartSensorToGatewayAction {
  type: ADD_SMART_SENSOR_TO_GATEWAY;
  payload: {
    gatewayId: string;
    smartSensorId: string;
  };
}

export interface AddSmartSensorToGatewaySuccessAction {
  type: ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface AddSmartSensorToGatewayFailedAction {
  type: ADD_SMART_SENSOR_TO_GATEWAY_FAILED;
  payload: Error;
}

export interface RemoveSmartSensorFromGatewayAction {
  type: REMOVE_SMART_SENSOR_FROM_GATEWAY;
  payload: {
    gatewayId: string;
    smartSensorId: string;
  };
}

export interface RemoveSmartSensorFromGatewaySuccessAction {
  type: REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface RemoveSmartSensorFromGatewayFailedAction {
  type: REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED;
  payload: Error;
}

export interface AddLcmToGatewayAction {
  type: ADD_LCM_TO_GATEWAY;
  payload: {
    gatewayId: string;
    lcmId: string;
  };
}

export interface AddLcmToGatewaySuccessAction {
  type: ADD_LCM_TO_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface AddLcmToGatewayFailedAction {
  type: ADD_LCM_TO_GATEWAY_FAILED;
  payload: Error;
}

export interface RemoveLcmFromGatewayAction {
  type: REMOVE_LCM_FROM_GATEWAY;
  payload: {
    gatewayId: string;
    lcmId: string;
  };
}

export interface RemoveLcmFromGatewaySuccessAction {
  type: REMOVE_LCM_FROM_GATEWAY_SUCCESS;
  payload: GatewayModel;
}

export interface RemoveLcmFromGatewayFailedAction {
  type: REMOVE_LCM_FROM_GATEWAY_FAILED;
  payload: Error;
}

export interface UpdateBatchInformationAction {
  type: UPDATE_BATCH_INFORMATION;
}

export interface UpdateBatchInformationSuccessAction {
  type: UPDATE_BATCH_INFORMATION_SUCCESS;
  payload: GatewayModel;
}

export interface UpdateBatchInformationFailedAction {
  type: UPDATE_BATCH_INFORMATION_FAILED;
  paylaod: Error;
}

export interface SwapGateways {
  type: SWAP_GATEWAYS;
}

export interface SwapGatewaysSuccessAction {
  type: SWAP_GATEWAYS_SUCCESS;
  payload: GatewayModel;
}

export interface SwapGatewaysFailedAction {
  type: SWAP_GATEWAYS_FAILED;
  payload: Error;
}

export type GatewayRemovePumpAction =
  | RemovePumpFromGatewayAction
  | RemovePumpFromGatewaySuccessAction
  | RemovePumpFromGatewayFailedAction;
export type GatewayAddPumpAction =
  | AddPumpToGatewayAction
  | AddPumpToGatewaySuccessAction
  | AddPumpToGatewayFailedAction;
export type GatewayRemovePondAction =
  | RemovePondFromGatewayAction
  | RemovePondFromGatewaySuccessAction
  | RemovePondFromGatewayFailedAction;
export type GatewayAddPondAction =
  | AddPondToGatewayAction
  | AddPondToGatewaySuccessAction
  | AddPondToGatewayFailedAction;
export type GatewayRemoveSensorAction =
  | RemoveSensorFromGatewayAction
  | RemoveSensorFromGatewaySuccessAction
  | RemoveSensorFromGatewayFailedAction;
export type GatewayAddSensorAction =
  | AddSensorToGatewayAction
  | AddSensorToGatewaySuccessAction
  | AddSensorToGatewayFailedAction;
export type GatewayRemoveAeratorAction =
  | RemoveAeratorFromGatewayAction
  | RemoveAeratorFromGatewaySuccessAction
  | RemoveAeratorFromGatewayFailedAction;
export type GatewayAddAeratorAction =
  | AddAeratorToGatewayAction
  | AddAeratorToGatewaySuccessAction
  | AddAeratorToGatewayFailedAction;
export type GatewaySyncronizeAction =
  | SyncronizeGatewayAction
  | SyncronizeGatewaySuccessAction
  | SyncronizeGatewayFailedAction;
export type GatewayUpdateAction =
  | UpdateGatewayAction
  | UpdateGatewaySuccessAction
  | UpdateGatewayFailedAction;
export type GatewayCreateAction =
  | CreateGatewayAction
  | CreateGatewaySuccessAction
  | CreateGatewayFailedAction;
export type GatewayFetchAction =
  | FetchGatewayAction
  | FetchGatewaySuccessAction
  | FetchGatewayFailedAction;
export type GatewaysFetchAction =
  | FetchGatewaysAction
  | FetchGatewaysSuccessAction
  | FetchGatewaysFailedAction;
export type OrganisationGatewaysFetchAction =
  | FetchOrganisationGatewaysAction
  | FetchOrganisationGatewaysSuccessAction
  | FetchOrganisationGatewaysFailedAction;
export type GatewayRemoveSmartSensorAction =
  | RemoveSmartSensorFromGatewayAction
  | RemoveSmartSensorFromGatewaySuccessAction
  | RemoveSmartSensorFromGatewayFailedAction;
export type GatewayAddSmartSensorAction =
  | AddSmartSensorToGatewayAction
  | AddSmartSensorToGatewaySuccessAction
  | AddSmartSensorToGatewayFailedAction;
export type GatewayRemoveLcmAction =
  | RemoveLcmFromGatewayAction
  | RemoveLcmFromGatewaySuccessAction
  | RemoveLcmFromGatewayFailedAction;
export type GatewayAddLcmAction =
  | AddLcmToGatewayAction
  | AddLcmToGatewaySuccessAction
  | AddLcmToGatewayFailedAction;
export type BatchInformationAction =
  | UpdateBatchInformationAction
  | UpdateBatchInformationSuccessAction
  | UpdateBatchInformationFailedAction;

export type SwapGatewaysAction =
  | SwapGateways
  | SwapGatewaysSuccessAction
  | SwapGatewaysFailedAction;

export type GatewaysAction =
  | GatewayCreateAction
  | GatewayFetchAction
  | GatewaysFetchAction
  | GatewayUpdateAction
  | GatewaySyncronizeAction
  | GatewayAddSensorAction
  | GatewayRemoveSensorAction
  | GatewayAddAeratorAction
  | GatewayRemoveAeratorAction
  | GatewayAddPondAction
  | GatewayRemovePondAction
  | OrganisationGatewaysFetchAction
  | GatewayRemoveSmartSensorAction
  | GatewayAddSmartSensorAction
  | GatewayRemoveLcmAction
  | GatewayAddLcmAction
  | GatewayAddPumpAction
  | GatewayRemovePumpAction
  | BatchInformationAction
  | SwapGatewaysAction;

// Action creators
export function gatewayCreate(): CreateGatewayAction {
  return {
    type: CREATE_GATEWAY,
  };
}

export function gatewayCreated(
  gateway: GatewayModel,
): CreateGatewaySuccessAction {
  return {
    type: CREATE_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayCreateFailed(error: Error): CreateGatewayFailedAction {
  return {
    type: CREATE_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayFetch(): FetchGatewayAction {
  return {
    type: FETCH_GATEWAY,
  };
}

export function gatewayFetched(
  gateway: GatewayModel,
): FetchGatewaySuccessAction {
  return {
    type: FETCH_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayFetchFailed(error: Error): FetchGatewayFailedAction {
  return {
    type: FETCH_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewaysFetch(query: ListGatewaysQuery): FetchGatewaysAction {
  return {
    type: FETCH_GATEWAYS,
    payload: query,
  };
}

export function gatewaysFetched(
  query: ListGatewaysQueryModel,
  gateways: GatewayModel[],
): FetchGatewaysSuccessAction {
  return {
    type: FETCH_GATEWAYS_SUCCESS,
    payload: { query, gateways },
  };
}

export function gatewaysFetchFailed(error: Error): FetchGatewaysFailedAction {
  return {
    type: FETCH_GATEWAYS_FAILED,
    payload: error,
  };
}

export function organisationGatewaysFetch(
  query: ListGatewaysQuery,
): FetchOrganisationGatewaysAction {
  return {
    type: FETCH_ORGANISATION_GATEWAYS,
    payload: query,
  };
}

export function organisationGatewaysFetched(
  query: ListGatewaysQueryModel,
  gateways: GatewayModel[],
): FetchOrganisationGatewaysSuccessAction {
  return {
    type: FETCH_ORGANISATION_GATEWAYS_SUCCESS,
    payload: { query, gateways },
  };
}

export function organisationGatewaysFetchFailed(
  error: Error,
): FetchOrganisationGatewaysFailedAction {
  return {
    type: FETCH_ORGANISATION_GATEWAYS_FAILED,
    payload: error,
  };
}

export function gatewayUpdate(): UpdateGatewayAction {
  return {
    type: UPDATE_GATEWAY,
  };
}

export function gatewayUpdated(
  gateway: GatewayModel,
): UpdateGatewaySuccessAction {
  return {
    type: UPDATE_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayUpdateFailed(error: Error): UpdateGatewayFailedAction {
  return {
    type: UPDATE_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewaySyncronize(): SyncronizeGatewayAction {
  return {
    type: SYNCRONIZE_GATEWAY,
  };
}

export function gatewaySyncronized(): SyncronizeGatewaySuccessAction {
  return {
    type: SYNCRONIZE_GATEWAY_SUCCESS,
  };
}

export function gatewaySyncronizeFailed(
  error: Error,
): SyncronizeGatewayFailedAction {
  return {
    type: SYNCRONIZE_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewaySensorAdd(
  gatewayId: string,
  sensorId: string,
): AddSensorToGatewayAction {
  return {
    type: ADD_SENSOR_TO_GATEWAY,
    payload: {
      gatewayId,
      sensorId,
    },
  };
}

export function gatewaySensorAdded(
  gateway: GatewayModel,
): AddSensorToGatewaySuccessAction {
  return {
    type: ADD_SENSOR_TO_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewaySensorAddFailed(
  error: Error,
): AddSensorToGatewayFailedAction {
  return {
    type: ADD_SENSOR_TO_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewaySensorRemove(
  gatewayId: string,
  sensorId: string,
): RemoveSensorFromGatewayAction {
  return {
    type: REMOVE_SENSOR_FROM_GATEWAY,
    payload: {
      gatewayId,
      sensorId,
    },
  };
}

export function gatewaySensorRemoved(
  gateway: GatewayModel,
): RemoveSensorFromGatewaySuccessAction {
  return {
    type: REMOVE_SENSOR_FROM_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewaySensorRemoveFailed(
  error: Error,
): RemoveSensorFromGatewayFailedAction {
  return {
    type: REMOVE_SENSOR_FROM_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayAeratorAdd(
  gatewayId: string,
  aeratorId: string,
): AddAeratorToGatewayAction {
  return {
    type: ADD_AERATOR_TO_GATEWAY,
    payload: {
      gatewayId,
      aeratorId,
    },
  };
}

export function gatewayAeratorAdded(
  gateway: GatewayModel,
): AddAeratorToGatewaySuccessAction {
  return {
    type: ADD_AERATOR_TO_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayAeratorAddFailed(
  error: Error,
): AddAeratorToGatewayFailedAction {
  return {
    type: ADD_AERATOR_TO_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayAeratorRemove(
  gatewayId: string,
  aeratorId: string,
): RemoveAeratorFromGatewayAction {
  return {
    type: REMOVE_AERATOR_FROM_GATEWAY,
    payload: {
      gatewayId,
      aeratorId,
    },
  };
}

export function gatewayAeratorRemoved(
  gateway: GatewayModel,
): RemoveAeratorFromGatewaySuccessAction {
  return {
    type: REMOVE_AERATOR_FROM_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayAeratorRemoveFailed(
  error: Error,
): RemoveAeratorFromGatewayFailedAction {
  return {
    type: REMOVE_AERATOR_FROM_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayPumpAdd(
  gatewayId: string,
  pumpId: string,
): AddPumpToGatewayAction {
  return {
    type: ADD_PUMP_TO_GATEWAY,
    payload: {
      gatewayId,
      pumpId,
    },
  };
}

export function gatewayPumpAdded(
  gateway: GatewayModel,
): AddPumpToGatewaySuccessAction {
  return {
    type: ADD_PUMP_TO_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayPumpAddFailed(
  error: Error,
): AddPumpToGatewayFailedAction {
  return {
    type: ADD_PUMP_TO_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayPumpRemove(
  gatewayId: string,
  pumpId: string,
): RemovePumpFromGatewayAction {
  return {
    type: REMOVE_PUMP_FROM_GATEWAY,
    payload: {
      gatewayId,
      pumpId,
    },
  };
}

export function gatewayPumpRemoved(
  gateway: GatewayModel,
): RemovePumpFromGatewaySuccessAction {
  return {
    type: REMOVE_PUMP_FROM_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayPumpRemoveFailed(
  error: Error,
): RemovePumpFromGatewayFailedAction {
  return {
    type: REMOVE_PUMP_FROM_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayPondAdd(
  gatewayId: string,
  pondId: string,
): AddPondToGatewayAction {
  return {
    type: ADD_POND_TO_GATEWAY,
    payload: {
      gatewayId,
      pondId,
    },
  };
}

export function gatewayPondAdded(
  gateway: GatewayModel,
): AddPondToGatewaySuccessAction {
  return {
    type: ADD_POND_TO_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayPondAddFailed(
  error: Error,
): AddPondToGatewayFailedAction {
  return {
    type: ADD_POND_TO_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayPondRemove(
  gatewayId: string,
  pondId: string,
): RemovePondFromGatewayAction {
  return {
    type: REMOVE_POND_FROM_GATEWAY,
    payload: {
      gatewayId,
      pondId,
    },
  };
}

export function gatewayPondRemoved(
  gateway: GatewayModel,
): RemovePondFromGatewaySuccessAction {
  return {
    type: REMOVE_POND_FROM_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayPondRemoveFailed(
  error: Error,
): RemovePondFromGatewayFailedAction {
  return {
    type: REMOVE_POND_FROM_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewaySmartSensorAdd(
  gatewayId: string,
  smartSensorId: string,
): AddSmartSensorToGatewayAction {
  return {
    type: ADD_SMART_SENSOR_TO_GATEWAY,
    payload: {
      gatewayId,
      smartSensorId,
    },
  };
}

export function gatewaySmartSensorAdded(
  gateway: GatewayModel,
): AddSmartSensorToGatewaySuccessAction {
  return {
    type: ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewaySmartSensorAddFailed(
  error: Error,
): AddSmartSensorToGatewayFailedAction {
  return {
    type: ADD_SMART_SENSOR_TO_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewaySmartSensorRemove(
  gatewayId: string,
  smartSensorId: string,
): RemoveSmartSensorFromGatewayAction {
  return {
    type: REMOVE_SMART_SENSOR_FROM_GATEWAY,
    payload: {
      gatewayId,
      smartSensorId,
    },
  };
}

export function gatewaySmartSensorRemoved(
  gateway: GatewayModel,
): RemoveSmartSensorFromGatewaySuccessAction {
  return {
    type: REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewaySmartSensorRemoveFailed(
  error: Error,
): RemoveSmartSensorFromGatewayFailedAction {
  return {
    type: REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayLcmAdd(
  gatewayId: string,
  lcmId: string,
): AddLcmToGatewayAction {
  return {
    type: ADD_LCM_TO_GATEWAY,
    payload: {
      gatewayId,
      lcmId,
    },
  };
}

export function gatewayLcmAdded(
  gateway: GatewayModel,
): AddLcmToGatewaySuccessAction {
  return {
    type: ADD_LCM_TO_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayLcmAddFailed(error: Error): AddLcmToGatewayFailedAction {
  return {
    type: ADD_LCM_TO_GATEWAY_FAILED,
    payload: error,
  };
}

export function gatewayLcmRemove(
  gatewayId: string,
  lcmId: string,
): RemoveLcmFromGatewayAction {
  return {
    type: REMOVE_LCM_FROM_GATEWAY,
    payload: {
      gatewayId,
      lcmId,
    },
  };
}

export function gatewayLcmRemoved(
  gateway: GatewayModel,
): RemoveLcmFromGatewaySuccessAction {
  return {
    type: REMOVE_LCM_FROM_GATEWAY_SUCCESS,
    payload: gateway,
  };
}

export function gatewayLcmRemoveFailed(
  error: Error,
): RemoveLcmFromGatewayFailedAction {
  return {
    type: REMOVE_LCM_FROM_GATEWAY_FAILED,
    payload: error,
  };
}

export function batchInformationUpdate(): BatchInformationAction {
  return {
    type: UPDATE_BATCH_INFORMATION,
  };
}

export function batchInformationUpdated(
  gateway: GatewayModel,
): UpdateBatchInformationSuccessAction {
  return {
    type: UPDATE_BATCH_INFORMATION_SUCCESS,
    payload: gateway,
  };
}

export function batchInformationUpdateFailed(
  error: Error,
): UpdateBatchInformationFailedAction {
  return {
    type: UPDATE_BATCH_INFORMATION_FAILED,
    paylaod: error,
  };
}

export function swapGateways(): SwapGateways {
  return {
    type: SWAP_GATEWAYS,
  };
}

export function swapGatewaysSuccess(
  payload: GatewayModel,
): SwapGatewaysSuccessAction {
  return {
    type: SWAP_GATEWAYS_SUCCESS,
    payload: payload,
  };
}

export function swapGatewaysFailed(error: Error): SwapGatewaysFailedAction {
  return {
    type: SWAP_GATEWAYS_FAILED,
    payload: error,
  };
}

// SENSORS
export interface CreateSensorAction {
  type: CREATE_SENSOR;
}

export interface CreateSensorSuccessAction {
  type: CREATE_SENSOR_SUCCESS;
  payload: SensorModel;
}

export interface CreateSensorFailedAction {
  type: CREATE_SENSOR_FAILED;
  payload: Error;
}

export interface FetchSensorAction {
  type: FETCH_SENSOR;
}

export interface FetchSensorSuccessAction {
  type: FETCH_SENSOR_SUCCESS;
  payload: SensorModel;
}

export interface FetchSensorFailedAction {
  type: FETCH_SENSOR_FAILED;
  payload: Error;
}

export interface FetchSensorDataAggregationsAction {
  type: FETCH_SENSOR_DATA_AGGREGATIONS;
  payload: ListSensorDataAggregationQuery;
}

export interface FetchSensorDataAggregationsSuccessAction {
  type: FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS;
  payload: ListSensorDataAggregationQueryModel;
}

export interface FetchSensorDataAggregationsFailedAction {
  type: FETCH_SENSOR_DATA_AGGREGATIONS_FAILED;
  payload: Error;
}

export interface FetchSensorsAction {
  type: FETCH_SENSORS;
  payload: ListSensorsQuery;
}

export interface FetchSensorsSuccessAction {
  type: FETCH_SENSORS_SUCCESS;
  payload: {
    queryModel: ListSensorsQueryModel;
    sensors: SensorModel[];
  };
}

export interface FetchSensorsFailedAction {
  type: FETCH_SENSORS_FAILED;
  payload: Error;
}

export interface FetchOrganisationSensorsFailedAction {
  type: FETCH_ORGANISATION_SENSORS_FAILED;
  payload: Error;
}

export interface FetchOrganisationSensorsAction {
  type: FETCH_ORGANISATION_SENSORS;
  payload: ListSensorsQuery;
}

export interface FetchOrganisationSensorsSuccessAction {
  type: FETCH_ORGANISATION_SENSORS_SUCCESS;
  payload: {
    queryModel: ListSensorsQueryModel;
    sensors: SensorModel[];
  };
}

export interface UpdateSensorAction {
  type: UPDATE_SENSOR;
}

export interface UpdateSensorSuccessAction {
  type: UPDATE_SENSOR_SUCCESS;
  payload: SensorModel;
}

export interface UpdateSensorFailedAction {
  type: UPDATE_SENSOR_FAILED;
  payload: Error;
}

export interface AddSensorToOrganisationAction {
  type: ADD_SENSOR_TO_ORGANISATION;
}

export interface AddSensorToOrganisationSuccessAction {
  type: ADD_SENSOR_TO_ORGANISATION_SUCCESS;
  payload: SensorModel;
}

export interface AddSensorToOrganisationFailedAction {
  type: ADD_SENSOR_TO_ORGANISATION_FAILED;
  payload: Error;
}
export interface RemoveSensorFromOrganisationAction {
  type: REMOVE_SENSOR_FROM_ORGANISATION;
  payload: { organisationId: string; sensorId: string };
}

export interface RemoveSensorFromOrganisationSuccessAction {
  type: REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS;
}

export interface RemoveSensorFromOrganisationFailedAction {
  type: REMOVE_SENSOR_FROM_ORGANISATION_FAILED;
  payload: Error;
}

export type SensorDataAggregationsAction =
  | FetchSensorDataAggregationsAction
  | FetchSensorDataAggregationsSuccessAction
  | FetchSensorDataAggregationsFailedAction;

export type SensorOrganisationRemoveAction =
  | RemoveSensorFromOrganisationAction
  | RemoveSensorFromOrganisationSuccessAction
  | RemoveSensorFromOrganisationFailedAction;
export type SensorOrganisationAddAction =
  | AddSensorToOrganisationAction
  | AddSensorToOrganisationSuccessAction
  | AddSensorToOrganisationFailedAction;

export type SensorUpdateAction =
  | UpdateSensorAction
  | UpdateSensorSuccessAction
  | UpdateSensorFailedAction;
export type SensorCreateAction =
  | CreateSensorAction
  | CreateSensorSuccessAction
  | CreateSensorFailedAction;
export type SensorFetchAction =
  | FetchSensorAction
  | FetchSensorSuccessAction
  | FetchSensorFailedAction;
export type SensorsFetchAction =
  | FetchSensorsAction
  | FetchSensorsSuccessAction
  | FetchSensorsFailedAction;
export type OrganisationSensorsFetchAction =
  | FetchOrganisationSensorsAction
  | FetchOrganisationSensorsSuccessAction
  | FetchOrganisationSensorsFailedAction;

export type SensorsAction =
  | SensorCreateAction
  | SensorFetchAction
  | SensorsFetchAction
  | SensorUpdateAction
  | SensorOrganisationAddAction
  | SensorOrganisationRemoveAction
  | OrganisationSensorsFetchAction
  | SensorDataAggregationsAction;

// Action creators
export function sensorCreate(): CreateSensorAction {
  return {
    type: CREATE_SENSOR,
  };
}

export function sensorCreated(sensor: SensorModel): CreateSensorSuccessAction {
  return {
    type: CREATE_SENSOR_SUCCESS,
    payload: sensor,
  };
}

export function sensorCreateFailed(error: Error): CreateSensorFailedAction {
  return {
    type: CREATE_SENSOR_FAILED,
    payload: error,
  };
}

export function sensorFetch(): FetchSensorAction {
  return {
    type: FETCH_SENSOR,
  };
}

export function sensorFetched(sensor: SensorModel): FetchSensorSuccessAction {
  return {
    type: FETCH_SENSOR_SUCCESS,
    payload: sensor,
  };
}

export function sensorFetchFailed(error: Error): FetchSensorFailedAction {
  return {
    type: FETCH_SENSOR_FAILED,
    payload: error,
  };
}

export function sensorDataAggregationsFetch(
  query: ListSensorDataAggregationQuery,
): FetchSensorDataAggregationsAction {
  return {
    type: FETCH_SENSOR_DATA_AGGREGATIONS,
    payload: query,
  };
}

export function sensorDataAggregationsFetched(
  model: ListSensorDataAggregationQueryModel,
): FetchSensorDataAggregationsSuccessAction {
  return {
    type: FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS,
    payload: model,
  };
}

export function sensorDataAggregationsFetchFailed(
  error: Error,
): FetchSensorDataAggregationsFailedAction {
  return {
    type: FETCH_SENSOR_DATA_AGGREGATIONS_FAILED,
    payload: error,
  };
}

export function organisationSensorsFetch(
  query: ListSensorsQuery,
): FetchOrganisationSensorsAction {
  return {
    type: FETCH_ORGANISATION_SENSORS,
    payload: query,
  };
}

export function organisationSensorsFetched(
  queryModel: ListSensorsQueryModel,
  sensors: SensorModel[],
): FetchOrganisationSensorsSuccessAction {
  return {
    type: FETCH_ORGANISATION_SENSORS_SUCCESS,
    payload: {
      queryModel,
      sensors,
    },
  };
}

export function organisationSensorsFetchFailed(
  error: Error,
): FetchOrganisationSensorsFailedAction {
  return {
    type: FETCH_ORGANISATION_SENSORS_FAILED,
    payload: error,
  };
}

export function sensorsFetch(query: ListSensorsQuery): FetchSensorsAction {
  return {
    type: FETCH_SENSORS,
    payload: query,
  };
}

export function sensorsFetched(
  queryModel: ListSensorsQueryModel,
  sensors: SensorModel[],
): FetchSensorsSuccessAction {
  return {
    type: FETCH_SENSORS_SUCCESS,
    payload: {
      queryModel,
      sensors,
    },
  };
}

export function sensorsFetchFailed(error: Error): FetchSensorsFailedAction {
  return {
    type: FETCH_SENSORS_FAILED,
    payload: error,
  };
}

export function sensorUpdate(): UpdateSensorAction {
  return {
    type: UPDATE_SENSOR,
  };
}

export function sensorUpdated(sensor: SensorModel): UpdateSensorSuccessAction {
  return {
    type: UPDATE_SENSOR_SUCCESS,
    payload: sensor,
  };
}

export function sensorUpdateFailed(error: Error): UpdateSensorFailedAction {
  return {
    type: UPDATE_SENSOR_FAILED,
    payload: error,
  };
}

export function sensorOrganisationAdd(): AddSensorToOrganisationAction {
  return {
    type: ADD_SENSOR_TO_ORGANISATION,
  };
}

export function sensorOrganisationAdded(
  sensor: SensorModel,
): AddSensorToOrganisationSuccessAction {
  return {
    type: ADD_SENSOR_TO_ORGANISATION_SUCCESS,
    payload: sensor,
  };
}

export function sensorOrganisationAddFailed(
  error: Error,
): AddSensorToOrganisationFailedAction {
  return {
    type: ADD_SENSOR_TO_ORGANISATION_FAILED,
    payload: error,
  };
}

export function sensorOrganisationRemove(
  sensorId: string,
  organisationId: string,
): RemoveSensorFromOrganisationAction {
  return {
    type: REMOVE_SENSOR_FROM_ORGANISATION,
    payload: { sensorId, organisationId },
  };
}

export function sensorOrganisationRemoved(): RemoveSensorFromOrganisationSuccessAction {
  return {
    type: REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS,
  };
}

export function sensorOrganisationRemoveFailed(
  error: Error,
): RemoveSensorFromOrganisationFailedAction {
  return {
    type: REMOVE_SENSOR_FROM_ORGANISATION_FAILED,
    payload: error,
  };
}

// AERATORS
export interface CreateAeratorAction {
  type: CREATE_AERATOR;
}

export interface CreateAeratorSuccessAction {
  type: CREATE_AERATOR_SUCCESS;
  payload: AeratorModel;
}

export interface CreateAeratorFailedAction {
  type: CREATE_AERATOR_FAILED;
  payload: Error;
}

export interface FetchAeratorAction {
  type: FETCH_AERATOR;
}

export interface FetchAeratorSuccessAction {
  type: FETCH_AERATOR_SUCCESS;
  payload: AeratorModel;
}

export interface FetchAeratorFailedAction {
  type: FETCH_AERATOR_FAILED;
  payload: Error;
}

export interface FetchAeratorsAction {
  type: FETCH_AERATORS;
  payload: ListAeratorsQuery;
}

export interface FetchAeratorsSuccessAction {
  type: FETCH_AERATORS_SUCCESS;
  payload: {
    query: ListAeratorsQueryModel;
    aerators: AeratorModel[];
  };
}

export interface FetchAeratorsFailedAction {
  type: FETCH_AERATORS_FAILED;
  payload: Error;
}

export interface UpdateAeratorAction {
  type: UPDATE_AERATOR;
}

export interface UpdateAeratorSuccessAction {
  type: UPDATE_AERATOR_SUCCESS;
  payload: AeratorModel;
}

export interface UpdateAeratorFailedAction {
  type: UPDATE_AERATOR_FAILED;
  payload: Error;
}

export type AeratorUpdateAction =
  | UpdateAeratorAction
  | UpdateAeratorSuccessAction
  | UpdateAeratorFailedAction;
export type AeratorCreateAction =
  | CreateAeratorAction
  | CreateAeratorSuccessAction
  | CreateAeratorFailedAction;
export type AeratorFetchAction =
  | FetchAeratorAction
  | FetchAeratorSuccessAction
  | FetchAeratorFailedAction;
export type AeratorsFetchAction =
  | FetchAeratorsAction
  | FetchAeratorsSuccessAction
  | FetchAeratorsFailedAction;
export type AeratorsAction =
  | AeratorCreateAction
  | AeratorFetchAction
  | AeratorsFetchAction
  | AeratorUpdateAction;

// Action creators
export function aeratorCreate(): CreateAeratorAction {
  return {
    type: CREATE_AERATOR,
  };
}

export function aeratorCreated(
  aerator: AeratorModel,
): CreateAeratorSuccessAction {
  return {
    type: CREATE_AERATOR_SUCCESS,
    payload: aerator,
  };
}

export function aeratorCreateFailed(error: Error): CreateAeratorFailedAction {
  return {
    type: CREATE_AERATOR_FAILED,
    payload: error,
  };
}

export function aeratorFetch(): FetchAeratorAction {
  return {
    type: FETCH_AERATOR,
  };
}

export function aeratorFetched(
  aerator: AeratorModel,
): FetchAeratorSuccessAction {
  return {
    type: FETCH_AERATOR_SUCCESS,
    payload: aerator,
  };
}

export function aeratorFetchFailed(error: Error): FetchAeratorFailedAction {
  return {
    type: FETCH_AERATOR_FAILED,
    payload: error,
  };
}

export function aeratorsFetch(query: ListAeratorsQuery): FetchAeratorsAction {
  return {
    type: FETCH_AERATORS,
    payload: query,
  };
}

export function aeratorsFetched(
  query: ListAeratorsQueryModel,
  aerators: AeratorModel[],
): FetchAeratorsSuccessAction {
  return {
    type: FETCH_AERATORS_SUCCESS,
    payload: { query, aerators },
  };
}

export function aeratorsFetchFailed(error: Error): FetchAeratorsFailedAction {
  return {
    type: FETCH_AERATORS_FAILED,
    payload: error,
  };
}

export function aeratorUpdate(): UpdateAeratorAction {
  return {
    type: UPDATE_AERATOR,
  };
}

export function aeratorUpdated(
  aerator: AeratorModel,
): UpdateAeratorSuccessAction {
  return {
    type: UPDATE_AERATOR_SUCCESS,
    payload: aerator,
  };
}

export function aeratorUpdateFailed(error: Error): UpdateAeratorFailedAction {
  return {
    type: UPDATE_AERATOR_FAILED,
    payload: error,
  };
}

// CONTROLLERS
export interface CreateControllerAction {
  type: CREATE_CONTROLLER;
}

export interface CreateControllerSuccessAction {
  type: CREATE_CONTROLLER_SUCCESS;
  payload: ControllerModel;
}

export interface CreateControllerFailedAction {
  type: CREATE_CONTROLLER_FAILED;
  payload: Error;
}

export interface FetchControllerAction {
  type: FETCH_CONTROLLER;
}

export interface FetchControllerSuccessAction {
  type: FETCH_CONTROLLER_SUCCESS;
  payload: ControllerModel;
}

export interface FetchControllerFailedAction {
  type: FETCH_CONTROLLER_FAILED;
  payload: Error;
}

export interface FetchControllersAction {
  type: FETCH_CONTROLLERS;
  payload: ListControllersQuery;
}

export interface FetchControllersSuccessAction {
  type: FETCH_CONTROLLERS_SUCCESS;
  payload: {
    query: ListControllersQueryModel;
    controllers: ControllerModel[];
  };
}

export interface FetchControllersFailedAction {
  type: FETCH_CONTROLLERS_FAILED;
  payload: Error;
}

export interface UpdateControllerAction {
  type: UPDATE_CONTROLLER;
}

export interface UpdateControllerSuccessAction {
  type: UPDATE_CONTROLLER_SUCCESS;
  payload: ControllerModel;
}

export interface UpdateControllerFailedAction {
  type: UPDATE_CONTROLLER_FAILED;
  payload: Error;
}

export type ControllerUpdateAction =
  | UpdateControllerAction
  | UpdateControllerSuccessAction
  | UpdateControllerFailedAction;
export type ControllerCreateAction =
  | CreateControllerAction
  | CreateControllerSuccessAction
  | CreateControllerFailedAction;
export type ControllerFetchAction =
  | FetchControllerAction
  | FetchControllerSuccessAction
  | FetchControllerFailedAction;
export type ControllersFetchAction =
  | FetchControllersAction
  | FetchControllersSuccessAction
  | FetchControllersFailedAction;
export type ControllersAction =
  | ControllerCreateAction
  | ControllerFetchAction
  | ControllersFetchAction
  | ControllerUpdateAction;

// Action creators
export function controllerCreate(): CreateControllerAction {
  return {
    type: CREATE_CONTROLLER,
  };
}

export function controllerCreated(
  controller: ControllerModel,
): CreateControllerSuccessAction {
  return {
    type: CREATE_CONTROLLER_SUCCESS,
    payload: controller,
  };
}

export function controllerCreateFailed(
  error: Error,
): CreateControllerFailedAction {
  return {
    type: CREATE_CONTROLLER_FAILED,
    payload: error,
  };
}

export function controllerFetch(): FetchControllerAction {
  return {
    type: FETCH_CONTROLLER,
  };
}

export function controllerFetched(
  controller: ControllerModel,
): FetchControllerSuccessAction {
  return {
    type: FETCH_CONTROLLER_SUCCESS,
    payload: controller,
  };
}

export function controllerFetchFailed(
  error: Error,
): FetchControllerFailedAction {
  return {
    type: FETCH_CONTROLLER_FAILED,
    payload: error,
  };
}

export function controllersFetch(
  query: ListControllersQuery,
): FetchControllersAction {
  return {
    type: FETCH_CONTROLLERS,
    payload: query,
  };
}

export function controllersFetched(
  query: ListControllersQueryModel,
  controllers: ControllerModel[],
): FetchControllersSuccessAction {
  return {
    type: FETCH_CONTROLLERS_SUCCESS,
    payload: { query, controllers },
  };
}

export function controllersFetchFailed(
  error: Error,
): FetchControllersFailedAction {
  return {
    type: FETCH_CONTROLLERS_FAILED,
    payload: error,
  };
}

export function controllerUpdate(): UpdateControllerAction {
  return {
    type: UPDATE_CONTROLLER,
  };
}

export function controllerUpdated(
  controller: ControllerModel,
): UpdateControllerSuccessAction {
  return {
    type: UPDATE_CONTROLLER_SUCCESS,
    payload: controller,
  };
}

export function controllerUpdateFailed(
  error: Error,
): UpdateControllerFailedAction {
  return {
    type: UPDATE_CONTROLLER_FAILED,
    payload: error,
  };
}

// LOCATIONS

export interface FetchLocationAction {
  type: FETCH_LOCATION;
}

export interface FetchLocationSuccessAction {
  type: FETCH_LOCATION_SUCCESS;
  payload: LocationModel;
}

export interface FetchLocationFailedAction {
  type: FETCH_LOCATION_FAILED;
  payload: Error;
}

export interface FetchLocationsAction {
  type: FETCH_LOCATIONS;
  payload: ListLocationsQuery;
}

export interface FetchLocationsSuccessAction {
  type: FETCH_LOCATIONS_SUCCESS;
  payload: {
    query: ListLocationsQueryModel;
    locations: LocationModel[];
  };
}

export interface FetchLocationsFailedAction {
  type: FETCH_LOCATIONS_FAILED;
  payload: Error;
}

export type LocationFetchAction =
  | FetchLocationAction
  | FetchLocationSuccessAction
  | FetchLocationFailedAction;
export type LocationsFetchAction =
  | FetchLocationsAction
  | FetchLocationsSuccessAction
  | FetchLocationsFailedAction;
export type LocationsAction = LocationFetchAction | LocationsFetchAction;

// Action creators

export function locationFetch(): FetchLocationAction {
  return {
    type: FETCH_LOCATION,
  };
}

export function locationFetched(
  location: LocationModel,
): FetchLocationSuccessAction {
  return {
    type: FETCH_LOCATION_SUCCESS,
    payload: location,
  };
}

export function locationFetchFailed(error: Error): FetchLocationFailedAction {
  return {
    type: FETCH_LOCATION_FAILED,
    payload: error,
  };
}

export function locationsFetch(
  query: ListLocationsQuery,
): FetchLocationsAction {
  return {
    type: FETCH_LOCATIONS,
    payload: query,
  };
}

export function locationsFetched(
  query: ListLocationsQueryModel,
  locations: LocationModel[],
): FetchLocationsSuccessAction {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    payload: { query, locations },
  };
}

export function locationsFetchFailed(error: Error): FetchLocationsFailedAction {
  return {
    type: FETCH_LOCATIONS_FAILED,
    payload: error,
  };
}

// AERATION_PROFILES

export interface FetchAerationProfileAction {
  type: FETCH_AERATION_PROFILE;
}

export interface FetchAerationProfileSuccessAction {
  type: FETCH_AERATION_PROFILE_SUCCESS;
  payload: AerationProfileModel;
}

export interface FetchAerationProfileFailedAction {
  type: FETCH_AERATION_PROFILE_FAILED;
  payload: Error;
}

export interface UpdateAerationProfileAction {
  type: UPDATE_AERATION_PROFILE;
}

export interface UpdateAerationProfileSuccessAction {
  type: UPDATE_AERATION_PROFILE_SUCCESS;
  payload: AerationProfileModel;
}

export interface UpdateAerationProfileFailedAction {
  type: UPDATE_AERATION_PROFILE_FAILED;
  payload: Error;
}

export type AerationProfileUpdateAction =
  | UpdateAerationProfileAction
  | UpdateAerationProfileSuccessAction
  | UpdateAerationProfileFailedAction;
export type AerationProfileFetchAction =
  | FetchAerationProfileAction
  | FetchAerationProfileSuccessAction
  | FetchAerationProfileFailedAction;
export type AerationProfilesAction =
  | AerationProfileFetchAction
  | AerationProfileUpdateAction;

// Action creators

export function aerationProfileFetch(): FetchAerationProfileAction {
  return {
    type: FETCH_AERATION_PROFILE,
  };
}

export function aerationProfileFetched(
  aerationProfile: AerationProfileModel,
): FetchAerationProfileSuccessAction {
  return {
    type: FETCH_AERATION_PROFILE_SUCCESS,
    payload: aerationProfile,
  };
}

export function aerationProfileFetchFailed(
  error: Error,
): FetchAerationProfileFailedAction {
  return {
    type: FETCH_AERATION_PROFILE_FAILED,
    payload: error,
  };
}

export function aerationProfileUpdate(): UpdateAerationProfileAction {
  return {
    type: UPDATE_AERATION_PROFILE,
  };
}

export function aerationProfileUpdated(
  aerationProfile: AerationProfileModel,
): UpdateAerationProfileSuccessAction {
  return {
    type: UPDATE_AERATION_PROFILE_SUCCESS,
    payload: aerationProfile,
  };
}

export function aerationProfileUpdateFailed(
  error: Error,
): UpdateAerationProfileFailedAction {
  return {
    type: UPDATE_AERATION_PROFILE_FAILED,
    payload: error,
  };
}

// NOTIFICATIONS

export interface CreateNotificationAction {
  type: CREATE_NOTIFICATION;
}

export interface CreateNotificationSuccessAction {
  type: CREATE_NOTIFICATION_SUCCESS;
  payload: NotificationModel;
}

export interface CreateNotificationFailedAction {
  type: CREATE_NOTIFICATION_FAILED;
  payload: Error;
}

export interface FetchNotificationsAction {
  type: FETCH_NOTIFICATIONS;
  payload: ListNotificationsQuery;
}

export interface FetchNotificationsSuccessAction {
  type: FETCH_NOTIFICATIONS_SUCCESS;
  payload: ListNotificationsQueryModel;
}

export interface FetchNotificationsFailedAction {
  type: FETCH_NOTIFICATIONS_FAILED;
  payload: Error;
}

export type NotificationCreateAction =
  | CreateNotificationAction
  | CreateNotificationSuccessAction
  | CreateNotificationFailedAction;
export type NotificationsFetchAction =
  | FetchNotificationsAction
  | FetchNotificationsSuccessAction
  | FetchNotificationsFailedAction;
export type NotificationsAction =
  | NotificationCreateAction
  | NotificationsFetchAction;

// Action creators
export function notificationCreate(): CreateNotificationAction {
  return {
    type: CREATE_NOTIFICATION,
  };
}

export function notificationCreated(
  notification: NotificationModel,
): CreateNotificationSuccessAction {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
}

export function notificationCreateFailed(
  error: Error,
): CreateNotificationFailedAction {
  return {
    type: CREATE_NOTIFICATION_FAILED,
    payload: error,
  };
}

export function notificationsFetch(
  query: ListNotificationsQuery,
): FetchNotificationsAction {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: query,
  };
}

export function notificationsFetched(
  queryModel: ListNotificationsQueryModel,
): FetchNotificationsSuccessAction {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: queryModel,
  };
}

export function notificationsFetchFailed(
  error: Error,
): FetchNotificationsFailedAction {
  return {
    type: FETCH_NOTIFICATIONS_FAILED,
    payload: error,
  };
}

// CYCLES
export interface CreateCycleAction {
  type: CREATE_CYCLE;
}

export interface CreateCycleSuccessAction {
  type: CREATE_CYCLE_SUCCESS;
  payload: CycleModel;
}

export interface CreateCycleFailedAction {
  type: CREATE_CYCLE_FAILED;
  payload: Error;
}

export interface FetchCycleAction {
  type: FETCH_CYCLE;
}

export interface FetchCycleSuccessAction {
  type: FETCH_CYCLE_SUCCESS;
  payload: CycleModel;
}

export interface FetchCycleFailedAction {
  type: FETCH_CYCLE_FAILED;
  payload: Error;
}

export interface FetchCyclesAction {
  type: FETCH_CYCLES;
  payload: ListCyclesQuery;
}

export interface FetchCyclesSuccessAction {
  type: FETCH_CYCLES_SUCCESS;
  payload: {
    query: ListCyclesQueryModel;
    cycles: SimpleCycleModel[];
  };
}

export interface FetchCyclesFailedAction {
  type: FETCH_CYCLES_FAILED;
  payload: Error;
}

export interface UpdateCycleAction {
  type: UPDATE_CYCLE;
}

export interface UpdateCycleSuccessAction {
  type: UPDATE_CYCLE_SUCCESS;
  payload: CycleModel;
}

export interface UpdateCycleFailedAction {
  type: UPDATE_CYCLE_FAILED;
  payload: Error;
}

export type CycleUpdateAction =
  | UpdateCycleAction
  | UpdateCycleSuccessAction
  | UpdateCycleFailedAction;
export type CycleCreateAction =
  | CreateCycleAction
  | CreateCycleSuccessAction
  | CreateCycleFailedAction;
export type CycleFetchAction =
  | FetchCycleAction
  | FetchCycleSuccessAction
  | FetchCycleFailedAction;
export type CyclesFetchAction =
  | FetchCyclesAction
  | FetchCyclesSuccessAction
  | FetchCyclesFailedAction;
export type CyclesAction =
  | CycleCreateAction
  | CycleFetchAction
  | CyclesFetchAction
  | CycleUpdateAction;

// Action creators
export function cycleCreate(): CreateCycleAction {
  return {
    type: CREATE_CYCLE,
  };
}

export function cycleCreated(cycle: CycleModel): CreateCycleSuccessAction {
  return {
    type: CREATE_CYCLE_SUCCESS,
    payload: cycle,
  };
}

export function cycleCreateFailed(error: Error): CreateCycleFailedAction {
  return {
    type: CREATE_CYCLE_FAILED,
    payload: error,
  };
}

export function cycleFetch(): FetchCycleAction {
  return {
    type: FETCH_CYCLE,
  };
}

export function cycleFetched(cycle: CycleModel): FetchCycleSuccessAction {
  return {
    type: FETCH_CYCLE_SUCCESS,
    payload: cycle,
  };
}

export function cycleFetchFailed(error: Error): FetchCycleFailedAction {
  return {
    type: FETCH_CYCLE_FAILED,
    payload: error,
  };
}

export function cyclesFetch(query: ListCyclesQuery): FetchCyclesAction {
  return {
    type: FETCH_CYCLES,
    payload: query,
  };
}

export function cyclesFetched(
  query: ListCyclesQueryModel,
  cycles: SimpleCycleModel[],
): FetchCyclesSuccessAction {
  return {
    type: FETCH_CYCLES_SUCCESS,
    payload: { query, cycles },
  };
}

export function cyclesFetchFailed(error: Error): FetchCyclesFailedAction {
  return {
    type: FETCH_CYCLES_FAILED,
    payload: error,
  };
}

export function cycleUpdate(): UpdateCycleAction {
  return {
    type: UPDATE_CYCLE,
  };
}

export function cycleUpdated(cycle: CycleModel): UpdateCycleSuccessAction {
  return {
    type: UPDATE_CYCLE_SUCCESS,
    payload: cycle,
  };
}

export function cycleUpdateFailed(error: Error): UpdateCycleFailedAction {
  return {
    type: UPDATE_CYCLE_FAILED,
    payload: error,
  };
}

// SAMPLES
export interface CreateSampleAction {
  type: CREATE_SAMPLE;
}

export interface CreateSampleSuccessAction {
  type: CREATE_SAMPLE_SUCCESS;
  payload: SampleModel;
}

export interface CreateSampleFailedAction {
  type: CREATE_SAMPLE_FAILED;
  payload: Error;
}

export interface FetchSampleAction {
  type: FETCH_SAMPLE;
}

export interface FetchSampleSuccessAction {
  type: FETCH_SAMPLE_SUCCESS;
  payload: SampleModel;
}

export interface FetchSampleFailedAction {
  type: FETCH_SAMPLE_FAILED;
  payload: Error;
}

export interface DeleteSampleAction {
  type: DELETE_SAMPLE;
  payload: string;
}

export interface DeleteSampleSuccessAction {
  type: DELETE_SAMPLE_SUCCESS;
  payload: string;
}

export interface DeleteSampleFailedAction {
  type: DELETE_SAMPLE_FAILED;
  payload: Error;
}

export interface FetchSamplesAction {
  type: FETCH_SAMPLES;
  payload: ListSamplesQuery;
}

export interface FetchSamplesSuccessAction {
  type: FETCH_SAMPLES_SUCCESS;
  payload: {
    query: ListSamplesQueryModel;
    samples: SampleModel[];
  };
}

export interface FetchSamplesFailedAction {
  type: FETCH_SAMPLES_FAILED;
  payload: Error;
}

export interface UpdateSampleAction {
  type: UPDATE_SAMPLE;
}

export interface UpdateSampleSuccessAction {
  type: UPDATE_SAMPLE_SUCCESS;
  payload: SampleModel;
}

export interface UpdateSampleFailedAction {
  type: UPDATE_SAMPLE_FAILED;
  payload: Error;
}

export type SampleUpdateAction =
  | UpdateSampleAction
  | UpdateSampleSuccessAction
  | UpdateSampleFailedAction;
export type SampleCreateAction =
  | CreateSampleAction
  | CreateSampleSuccessAction
  | CreateSampleFailedAction;
export type SampleFetchAction =
  | FetchSampleAction
  | FetchSampleSuccessAction
  | FetchSampleFailedAction;
export type SamplesFetchAction =
  | FetchSamplesAction
  | FetchSamplesSuccessAction
  | FetchSamplesFailedAction;
export type SampleDeleteAction =
  | DeleteSampleAction
  | DeleteSampleSuccessAction
  | DeleteSampleFailedAction;
export type SamplesAction =
  | SampleCreateAction
  | SampleFetchAction
  | SamplesFetchAction
  | SampleUpdateAction
  | SampleDeleteAction;

// Action creators
export function sampleCreate(): CreateSampleAction {
  return {
    type: CREATE_SAMPLE,
  };
}

export function sampleCreated(sample: SampleModel): CreateSampleSuccessAction {
  return {
    type: CREATE_SAMPLE_SUCCESS,
    payload: sample,
  };
}

export function sampleCreateFailed(error: Error): CreateSampleFailedAction {
  return {
    type: CREATE_SAMPLE_FAILED,
    payload: error,
  };
}

export function sampleFetch(): FetchSampleAction {
  return {
    type: FETCH_SAMPLE,
  };
}

export function sampleFetched(sample: SampleModel): FetchSampleSuccessAction {
  return {
    type: FETCH_SAMPLE_SUCCESS,
    payload: sample,
  };
}

export function sampleFetchFailed(error: Error): FetchSampleFailedAction {
  return {
    type: FETCH_SAMPLE_FAILED,
    payload: error,
  };
}

export function sampleDelete(sampleId: string): DeleteSampleAction {
  return {
    type: DELETE_SAMPLE,
    payload: sampleId,
  };
}

export function sampleDeleted(sampleId: string): DeleteSampleSuccessAction {
  return {
    type: DELETE_SAMPLE_SUCCESS,
    payload: sampleId,
  };
}

export function sampleDeleteFailed(error: Error): DeleteSampleFailedAction {
  return {
    type: DELETE_SAMPLE_FAILED,
    payload: error,
  };
}

export function samplesFetch(query: ListSamplesQuery): FetchSamplesAction {
  return {
    type: FETCH_SAMPLES,
    payload: query,
  };
}

export function samplesFetched(
  query: ListSamplesQueryModel,
  samples: SampleModel[],
): FetchSamplesSuccessAction {
  return {
    type: FETCH_SAMPLES_SUCCESS,
    payload: { query, samples },
  };
}

export function samplesFetchFailed(error: Error): FetchSamplesFailedAction {
  return {
    type: FETCH_SAMPLES_FAILED,
    payload: error,
  };
}

export function sampleUpdate(): UpdateSampleAction {
  return {
    type: UPDATE_SAMPLE,
  };
}

export function sampleUpdated(sample: SampleModel): UpdateSampleSuccessAction {
  return {
    type: UPDATE_SAMPLE_SUCCESS,
    payload: sample,
  };
}

export function sampleUpdateFailed(error: Error): UpdateSampleFailedAction {
  return {
    type: UPDATE_SAMPLE_FAILED,
    payload: error,
  };
}

// FEEDS
export interface CreateFeedAction {
  type: CREATE_FEED;
}

export interface CreateFeedSuccessAction {
  type: CREATE_FEED_SUCCESS;
  payload: FeedModel;
}

export interface CreateFeedFailedAction {
  type: CREATE_FEED_FAILED;
  payload: Error;
}

export interface FetchFeedAction {
  type: FETCH_FEED;
}

export interface FetchFeedSuccessAction {
  type: FETCH_FEED_SUCCESS;
  payload: FeedModel;
}

export interface FetchFeedFailedAction {
  type: FETCH_FEED_FAILED;
  payload: Error;
}

export interface DeleteFeedAction {
  type: DELETE_FEED;
  payload: string;
}

export interface DeleteFeedSuccessAction {
  type: DELETE_FEED_SUCCESS;
  payload: string;
}

export interface DeleteFeedFailedAction {
  type: DELETE_FEED_FAILED;
  payload: Error;
}

export interface FetchFeedsAction {
  type: FETCH_FEEDS;
  payload: ListFeedsQuery;
}

export interface FetchFeedsSuccessAction {
  type: FETCH_FEEDS_SUCCESS;
  payload: {
    query: ListFeedsQueryModel;
    feeds: FeedModel[];
  };
}

export interface FetchFeedsFailedAction {
  type: FETCH_FEEDS_FAILED;
  payload: Error;
}

export interface UpdateFeedAction {
  type: UPDATE_FEED;
}

export interface UpdateFeedSuccessAction {
  type: UPDATE_FEED_SUCCESS;
  payload: FeedModel;
}

export interface UpdateFeedFailedAction {
  type: UPDATE_FEED_FAILED;
  payload: Error;
}

export type FeedUpdateAction =
  | UpdateFeedAction
  | UpdateFeedSuccessAction
  | UpdateFeedFailedAction;
export type FeedCreateAction =
  | CreateFeedAction
  | CreateFeedSuccessAction
  | CreateFeedFailedAction;
export type FeedFetchAction =
  | FetchFeedAction
  | FetchFeedSuccessAction
  | FetchFeedFailedAction;
export type FeedsFetchAction =
  | FetchFeedsAction
  | FetchFeedsSuccessAction
  | FetchFeedsFailedAction;
export type FeedDeleteAction =
  | DeleteFeedAction
  | DeleteFeedSuccessAction
  | DeleteFeedFailedAction;
export type FeedsAction =
  | FeedCreateAction
  | FeedFetchAction
  | FeedsFetchAction
  | FeedUpdateAction
  | FeedDeleteAction;

// Action creators
export function feedCreate(): CreateFeedAction {
  return {
    type: CREATE_FEED,
  };
}

export function feedCreated(feed: FeedModel): CreateFeedSuccessAction {
  return {
    type: CREATE_FEED_SUCCESS,
    payload: feed,
  };
}

export function feedCreateFailed(error: Error): CreateFeedFailedAction {
  return {
    type: CREATE_FEED_FAILED,
    payload: error,
  };
}

export function feedFetch(): FetchFeedAction {
  return {
    type: FETCH_FEED,
  };
}

export function feedFetched(feed: FeedModel): FetchFeedSuccessAction {
  return {
    type: FETCH_FEED_SUCCESS,
    payload: feed,
  };
}

export function feedFetchFailed(error: Error): FetchFeedFailedAction {
  return {
    type: FETCH_FEED_FAILED,
    payload: error,
  };
}

export function feedDelete(feedId: string): DeleteFeedAction {
  return {
    type: DELETE_FEED,
    payload: feedId,
  };
}

export function feedDeleted(feedId: string): DeleteFeedSuccessAction {
  return {
    type: DELETE_FEED_SUCCESS,
    payload: feedId,
  };
}

export function feedDeleteFailed(error: Error): DeleteFeedFailedAction {
  return {
    type: DELETE_FEED_FAILED,
    payload: error,
  };
}

export function feedsFetch(query: ListFeedsQuery): FetchFeedsAction {
  return {
    type: FETCH_FEEDS,
    payload: query,
  };
}

export function feedsFetched(
  query: ListFeedsQueryModel,
  feeds: FeedModel[],
): FetchFeedsSuccessAction {
  return {
    type: FETCH_FEEDS_SUCCESS,
    payload: { query, feeds },
  };
}

export function feedsFetchFailed(error: Error): FetchFeedsFailedAction {
  return {
    type: FETCH_FEEDS_FAILED,
    payload: error,
  };
}

export function feedUpdate(): UpdateFeedAction {
  return {
    type: UPDATE_FEED,
  };
}

export function feedUpdated(feed: FeedModel): UpdateFeedSuccessAction {
  return {
    type: UPDATE_FEED_SUCCESS,
    payload: feed,
  };
}

export function feedUpdateFailed(error: Error): UpdateFeedFailedAction {
  return {
    type: UPDATE_FEED_FAILED,
    payload: error,
  };
}

// FEED_TABLES
export interface CreateFeedTableAction {
  type: CREATE_FEED_TABLE;
}

export interface CreateFeedTableSuccessAction {
  type: CREATE_FEED_TABLE_SUCCESS;
  payload: FeedTableModel;
}

export interface CreateFeedTableFailedAction {
  type: CREATE_FEED_TABLE_FAILED;
  payload: Error;
}

export interface FetchFeedTableAction {
  type: FETCH_FEED_TABLE;
}

export interface FetchFeedTableSuccessAction {
  type: FETCH_FEED_TABLE_SUCCESS;
  payload: FeedTableModel;
}

export interface FetchFeedTableFailedAction {
  type: FETCH_FEED_TABLE_FAILED;
  payload: Error;
}

export interface DeleteFeedTableAction {
  type: DELETE_FEED_TABLE;
  payload: string;
}

export interface DeleteFeedTableSuccessAction {
  type: DELETE_FEED_TABLE_SUCCESS;
  payload: string;
}

export interface DeleteFeedTableFailedAction {
  type: DELETE_FEED_TABLE_FAILED;
  payload: Error;
}

export interface FetchFeedTablesAction {
  type: FETCH_FEED_TABLES;
  payload: ListFeedTablesQuery;
}

export interface FetchFeedTablesSuccessAction {
  type: FETCH_FEED_TABLES_SUCCESS;
  payload: {
    query: ListFeedTablesQueryModel;
    feedTables: SimpleFeedTableModel[];
  };
}

export interface FetchFeedTablesFailedAction {
  type: FETCH_FEED_TABLES_FAILED;
  payload: Error;
}

export interface UpdateFeedTableAction {
  type: UPDATE_FEED_TABLE;
}

export interface UpdateFeedTableSuccessAction {
  type: UPDATE_FEED_TABLE_SUCCESS;
  payload: FeedTableModel;
}

export interface UpdateFeedTableFailedAction {
  type: UPDATE_FEED_TABLE_FAILED;
  payload: Error;
}

export interface UpdateFeedTableRowsAction {
  type: UPDATE_FEED_TABLE_ROWS;
}

export interface UpdateFeedTableRowsSuccessAction {
  type: UPDATE_FEED_TABLE_ROWS_SUCCESS;
  payload: FeedTableModel;
}

export interface UpdateFeedTableRowsFailedAction {
  type: UPDATE_FEED_TABLE_ROWS_FAILED;
  payload: Error;
}

export type FeedTableRowsUpdateAction =
  | UpdateFeedTableRowsAction
  | UpdateFeedTableRowsSuccessAction
  | UpdateFeedTableRowsFailedAction;
export type FeedTableUpdateAction =
  | UpdateFeedTableAction
  | UpdateFeedTableSuccessAction
  | UpdateFeedTableFailedAction;
export type FeedTableCreateAction =
  | CreateFeedTableAction
  | CreateFeedTableSuccessAction
  | CreateFeedTableFailedAction;
export type FeedTableFetchAction =
  | FetchFeedTableAction
  | FetchFeedTableSuccessAction
  | FetchFeedTableFailedAction;
export type FeedTablesFetchAction =
  | FetchFeedTablesAction
  | FetchFeedTablesSuccessAction
  | FetchFeedTablesFailedAction;
export type FeedTableDeleteAction =
  | DeleteFeedTableAction
  | DeleteFeedTableSuccessAction
  | DeleteFeedTableFailedAction;
export type FeedTablesAction =
  | FeedTableCreateAction
  | FeedTableFetchAction
  | FeedTablesFetchAction
  | FeedTableUpdateAction
  | FeedTableDeleteAction
  | FeedTableRowsUpdateAction;

// Action creators
export function feedTableCreate(): CreateFeedTableAction {
  return {
    type: CREATE_FEED_TABLE,
  };
}

export function feedTableCreated(
  feedTable: FeedTableModel,
): CreateFeedTableSuccessAction {
  return {
    type: CREATE_FEED_TABLE_SUCCESS,
    payload: feedTable,
  };
}

export function feedTableCreateFailed(
  error: Error,
): CreateFeedTableFailedAction {
  return {
    type: CREATE_FEED_TABLE_FAILED,
    payload: error,
  };
}

export function feedTableFetch(): FetchFeedTableAction {
  return {
    type: FETCH_FEED_TABLE,
  };
}

export function feedTableFetched(
  feedTable: FeedTableModel,
): FetchFeedTableSuccessAction {
  return {
    type: FETCH_FEED_TABLE_SUCCESS,
    payload: feedTable,
  };
}

export function feedTableFetchFailed(error: Error): FetchFeedTableFailedAction {
  return {
    type: FETCH_FEED_TABLE_FAILED,
    payload: error,
  };
}

export function feedTableDelete(feedTableId: string): DeleteFeedTableAction {
  return {
    type: DELETE_FEED_TABLE,
    payload: feedTableId,
  };
}

export function feedTableDeleted(
  feedTableId: string,
): DeleteFeedTableSuccessAction {
  return {
    type: DELETE_FEED_TABLE_SUCCESS,
    payload: feedTableId,
  };
}

export function feedTableDeleteFailed(
  error: Error,
): DeleteFeedTableFailedAction {
  return {
    type: DELETE_FEED_TABLE_FAILED,
    payload: error,
  };
}

export function feedTablesFetch(
  query: ListFeedTablesQuery,
): FetchFeedTablesAction {
  return {
    type: FETCH_FEED_TABLES,
    payload: query,
  };
}

export function feedTablesFetched(
  query: ListFeedTablesQueryModel,
  feedTables: SimpleFeedTableModel[],
): FetchFeedTablesSuccessAction {
  return {
    type: FETCH_FEED_TABLES_SUCCESS,
    payload: { query, feedTables },
  };
}

export function feedTablesFetchFailed(
  error: Error,
): FetchFeedTablesFailedAction {
  return {
    type: FETCH_FEED_TABLES_FAILED,
    payload: error,
  };
}

export function feedTableUpdate(): UpdateFeedTableAction {
  return {
    type: UPDATE_FEED_TABLE,
  };
}

export function feedTableUpdated(
  feedTable: FeedTableModel,
): UpdateFeedTableSuccessAction {
  return {
    type: UPDATE_FEED_TABLE_SUCCESS,
    payload: feedTable,
  };
}

export function feedTableUpdateFailed(
  error: Error,
): UpdateFeedTableFailedAction {
  return {
    type: UPDATE_FEED_TABLE_FAILED,
    payload: error,
  };
}

export function feedTableRowsUpdate(): UpdateFeedTableRowsAction {
  return {
    type: UPDATE_FEED_TABLE_ROWS,
  };
}

export function feedTableRowsUpdated(
  feedTable: FeedTableModel,
): UpdateFeedTableRowsSuccessAction {
  return {
    type: UPDATE_FEED_TABLE_ROWS_SUCCESS,
    payload: feedTable,
  };
}

export function feedTableRowsUpdateFailed(
  error: Error,
): UpdateFeedTableRowsFailedAction {
  return {
    type: UPDATE_FEED_TABLE_ROWS_FAILED,
    payload: error,
  };
}

// CALIBRATION_LOGS
export interface FetchCalibrationLogAction {
  type: FETCH_CALIBRATION_LOG;
}

export interface FetchCalibrationLogSuccessAction {
  type: FETCH_CALIBRATION_LOG_SUCCESS;
  payload: SensorCalibrationLogModel;
}

export interface FetchCalibrationLogFailedAction {
  type: FETCH_CALIBRATION_LOG_FAILED;
  payload: Error;
}

export interface FetchCalibrationLogsAction {
  type: FETCH_CALIBRATION_LOGS;
  payload: ListCalibrationLogsQuery;
}

export interface FetchCalibrationLogsSuccessAction {
  type: FETCH_CALIBRATION_LOGS_SUCCESS;
  payload: {
    query: ListCalibrationLogsQueryModel;
    calibrationLogs: SimpleSensorCalibrationLogModel[];
  };
}

export interface FetchCalibrationLogsFailedAction {
  type: FETCH_CALIBRATION_LOGS_FAILED;
  payload: Error;
}

export type CalibrationLogFetchAction =
  | FetchCalibrationLogAction
  | FetchCalibrationLogSuccessAction
  | FetchCalibrationLogFailedAction;
export type CalibrationLogsFetchAction =
  | FetchCalibrationLogsAction
  | FetchCalibrationLogsSuccessAction
  | FetchCalibrationLogsFailedAction;
export type CalibrationLogsAction =
  | CalibrationLogFetchAction
  | CalibrationLogsFetchAction;

// Action creators
export function calibrationLogFetch(): FetchCalibrationLogAction {
  return {
    type: FETCH_CALIBRATION_LOG,
  };
}

export function calibrationLogFetched(
  calibrationLog: SensorCalibrationLogModel,
): FetchCalibrationLogSuccessAction {
  return {
    type: FETCH_CALIBRATION_LOG_SUCCESS,
    payload: calibrationLog,
  };
}

export function calibrationLogFetchFailed(
  error: Error,
): FetchCalibrationLogFailedAction {
  return {
    type: FETCH_CALIBRATION_LOG_FAILED,
    payload: error,
  };
}

export function calibrationLogsFetch(
  query: ListCalibrationLogsQuery,
): FetchCalibrationLogsAction {
  return {
    type: FETCH_CALIBRATION_LOGS,
    payload: query,
  };
}

export function calibrationLogsFetched(
  query: ListCalibrationLogsQueryModel,
  calibrationLogs: SimpleSensorCalibrationLogModel[],
): FetchCalibrationLogsSuccessAction {
  return {
    type: FETCH_CALIBRATION_LOGS_SUCCESS,
    payload: { query, calibrationLogs },
  };
}

export function calibrationLogsFetchFailed(
  error: Error,
): FetchCalibrationLogsFailedAction {
  return {
    type: FETCH_CALIBRATION_LOGS_FAILED,
    payload: error,
  };
}

import {
  ADD_GATEWAY_TO_ORGANISATION,
  ADD_GATEWAY_TO_ORGANISATION_FAILED,
  ADD_GATEWAY_TO_ORGANISATION_SUCCESS,
  ADD_MEMBER_TO_ORGANISATION,
  ADD_MEMBER_TO_ORGANISATION_FAILED,
  ADD_MEMBER_TO_ORGANISATION_SUCCESS,
  COUNT_ORGANISATIONS,
  COUNT_ORGANISATIONS_FAILED,
  COUNT_ORGANISATIONS_SUCCESS,
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_SUCCESS,
  FETCH_ORGANISATION,
  FETCH_ORGANISATIONS,
  FETCH_ORGANISATIONS_FAILED,
  FETCH_ORGANISATIONS_SUCCESS,
  FETCH_ORGANISATION_FAILED,
  FETCH_ORGANISATION_MEMBERS,
  FETCH_ORGANISATION_MEMBERS_FAILED,
  FETCH_ORGANISATION_MEMBERS_SUCCESS,
  FETCH_ORGANISATION_MEMBER_OPTIONS,
  FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED,
  FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS,
  FETCH_ORGANISATION_SUCCESS,
  REMOVE_ACCOUNT_FROM_ORGANISATION,
  REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED,
  REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS,
  REMOVE_GATEWAY_FROM_ORGANISATION,
  REMOVE_GATEWAY_FROM_ORGANISATION_FAILED,
  REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_FAILED,
  UPDATE_ORGANISATION_SUCCESS,
} from '../../constants';
import { OrganisationModel } from '../../model/domain';
import {
  CountOrganisationsModel,
  ListAccountsQueryModel,
  ListOrganisationsQuery,
  ListOrganisationsQueryModel,
} from '../../model/querys';
import {
  OrganisationAddSmartSensorAction,
  OrganisationRemoveSmartSensorAction,
} from './smartSensor';

export interface CreateOrganisationAction {
  type: CREATE_ORGANISATION;
}

export interface CreateOrganisationSuccessAction {
  type: CREATE_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface CreateOrganisationFailedAction {
  type: CREATE_ORGANISATION_FAILED;
  payload: Error;
}

export interface FetchOrganisationAction {
  type: FETCH_ORGANISATION;
}

export interface FetchOrganisationSuccessAction {
  type: FETCH_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface FetchOrganisationFailedAction {
  type: FETCH_ORGANISATION_FAILED;
  payload: Error;
}

export interface FetchOrganisationsAction {
  type: FETCH_ORGANISATIONS;
  payload: ListOrganisationsQuery;
}

export interface FetchOrganisationsSuccessAction {
  type: FETCH_ORGANISATIONS_SUCCESS;
  payload: ListOrganisationsQueryModel;
}

export interface FetchOrganisationsFailedAction {
  type: FETCH_ORGANISATIONS_FAILED;
  payload: Error;
}

export interface CountOrganisationsAction {
  type: COUNT_ORGANISATIONS;
}

export interface CountOrganisationsSuccessAction {
  type: COUNT_ORGANISATIONS_SUCCESS;
  payload: CountOrganisationsModel;
}

export interface CountOrganisationsFailedAction {
  type: COUNT_ORGANISATIONS_FAILED;
  payload: Error;
}

export interface UpdateOrganisationAction {
  type: UPDATE_ORGANISATION;
}

export interface UpdateOrganisationSuccessAction {
  type: UPDATE_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface UpdateOrganisationFailedAction {
  type: UPDATE_ORGANISATION_FAILED;
  payload: Error;
}

export interface FetchOrganisationMembersAction {
  type: FETCH_ORGANISATION_MEMBERS;
  payload: string;
}

export interface FetchOrganisationMembersSuccessAction {
  type: FETCH_ORGANISATION_MEMBERS_SUCCESS;
  payload: ListAccountsQueryModel;
}

export interface FetchOrganisationMembersFailedAction {
  type: FETCH_ORGANISATION_MEMBERS_FAILED;
  payload: Error;
}

export interface FetchOrganisationMemberOptionsAction {
  type: FETCH_ORGANISATION_MEMBER_OPTIONS;
}

export interface FetchOrganisationMemberOptionsSuccessAction {
  type: FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS;
  payload: ListAccountsQueryModel;
}

export interface FetchOrganisationMemberOptionsFailedAction {
  type: FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED;
  payload: Error;
}

export interface AddMemberToOrganisationAction {
  type: ADD_MEMBER_TO_ORGANISATION;
}

export interface AddMemberToOrganisationSuccessAction {
  type: ADD_MEMBER_TO_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface AddMemberToOrganisationFailedAction {
  type: ADD_MEMBER_TO_ORGANISATION_FAILED;
  payload: Error;
}

export interface RemoveAccountFromOrganisationAction {
  type: REMOVE_ACCOUNT_FROM_ORGANISATION;
  payload: { accountId: string; organisationId: string };
}

export interface RemoveAccountFromOrganisationSuccessAction {
  type: REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS;
}

export interface RemoveAccountFromOrganisationFailedAction {
  type: REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED;
  payload: Error;
}

export interface AddGatewayToOrganisationAction {
  type: ADD_GATEWAY_TO_ORGANISATION;
  organisationId: string;
  gatewayId: string;
}

export interface AddGatewayToOrganisationSuccessAction {
  type: ADD_GATEWAY_TO_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface AddGatewayToOrganisationFailedAction {
  type: ADD_GATEWAY_TO_ORGANISATION_FAILED;
  payload: Error;
}

export interface RemoveGatewayFromOrganisationAction {
  type: REMOVE_GATEWAY_FROM_ORGANISATION;
  organisationId: string;
  gatewayId: string;
}

export interface RemoveGatewayFromOrganisationSuccessAction {
  type: REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface RemoveGatewayFromOrganisationFailedAction {
  type: REMOVE_GATEWAY_FROM_ORGANISATION_FAILED;
  payload: Error;
}

export type OrganisationAddGatewayAction =
  | AddGatewayToOrganisationAction
  | AddGatewayToOrganisationSuccessAction
  | AddGatewayToOrganisationFailedAction;
export type OrganisationRemoveGatewayAction =
  | RemoveGatewayFromOrganisationAction
  | RemoveGatewayFromOrganisationSuccessAction
  | RemoveGatewayFromOrganisationFailedAction;
export type OrganisationRemoveAccountAction =
  | RemoveAccountFromOrganisationAction
  | RemoveAccountFromOrganisationSuccessAction
  | RemoveAccountFromOrganisationFailedAction;
export type OrganisationAddMemberAction =
  | AddMemberToOrganisationAction
  | AddMemberToOrganisationSuccessAction
  | AddMemberToOrganisationFailedAction;
export type OrganisationFetchMemberOptionsAction =
  | FetchOrganisationMemberOptionsAction
  | FetchOrganisationMemberOptionsSuccessAction
  | FetchOrganisationMemberOptionsFailedAction;
export type OrganisationFetchMembersAction =
  | FetchOrganisationMembersAction
  | FetchOrganisationMembersSuccessAction
  | FetchOrganisationMembersFailedAction;
export type OrganisationUpdateAction =
  | UpdateOrganisationAction
  | UpdateOrganisationSuccessAction
  | UpdateOrganisationFailedAction;
export type OrganisationCreateAction =
  | CreateOrganisationAction
  | CreateOrganisationSuccessAction
  | CreateOrganisationFailedAction;
export type OrganisationFetchAction =
  | FetchOrganisationAction
  | FetchOrganisationSuccessAction
  | FetchOrganisationFailedAction;
export type OrganisationsFetchAction =
  | FetchOrganisationsAction
  | FetchOrganisationsSuccessAction
  | FetchOrganisationsFailedAction;
export type OrganisationsCountAction =
  | CountOrganisationsAction
  | CountOrganisationsSuccessAction
  | CountOrganisationsFailedAction;


export type OrganisationsAction =
  | OrganisationCreateAction
  | OrganisationFetchAction
  | OrganisationsFetchAction
  | OrganisationUpdateAction
  | OrganisationFetchMembersAction
  | OrganisationFetchMemberOptionsAction
  | OrganisationAddMemberAction
  | OrganisationRemoveAccountAction
  | OrganisationAddGatewayAction
  | OrganisationRemoveGatewayAction
  | OrganisationAddSmartSensorAction
  | OrganisationRemoveSmartSensorAction
  | OrganisationsCountAction;

// Action creators
export function organisationCreate(): CreateOrganisationAction {
  return {
    type: CREATE_ORGANISATION,
  };
}

export function organisationCreated(
  organisation: OrganisationModel,
): CreateOrganisationSuccessAction {
  return {
    type: CREATE_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationCreateFailed(
  error: Error,
): CreateOrganisationFailedAction {
  return {
    type: CREATE_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationFetch(): FetchOrganisationAction {
  return {
    type: FETCH_ORGANISATION,
  };
}

export function organisationFetched(
  organisation: OrganisationModel,
): FetchOrganisationSuccessAction {
  return {
    type: FETCH_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationFetchFailed(
  error: Error,
): FetchOrganisationFailedAction {
  return {
    type: FETCH_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationsFetch(
  query: ListOrganisationsQuery,
): FetchOrganisationsAction {
  return {
    type: FETCH_ORGANISATIONS,
    payload: query,
  };
}

export function organisationsFetched(
  queryModel: ListOrganisationsQueryModel,
): FetchOrganisationsSuccessAction {
  return {
    type: FETCH_ORGANISATIONS_SUCCESS,
    payload: queryModel,
  };
}

export function organisationsFetchFailed(
  error: Error,
): FetchOrganisationsFailedAction {
  return {
    type: FETCH_ORGANISATIONS_FAILED,
    payload: error,
  };
}

export function organisationsCount(): CountOrganisationsAction {
  return {
    type: COUNT_ORGANISATIONS,
  };
}

export function organisationsCounted(
  model: CountOrganisationsModel,
): CountOrganisationsSuccessAction {
  return {
    type: COUNT_ORGANISATIONS_SUCCESS,
    payload: model,
  };
}

export function organisationsCountFailed(
  error: Error,
): CountOrganisationsFailedAction {
  return {
    type: COUNT_ORGANISATIONS_FAILED,
    payload: error,
  };
}

export function organisationUpdate(): UpdateOrganisationAction {
  return {
    type: UPDATE_ORGANISATION,
  };
}

export function organisationUpdated(
  organisation: OrganisationModel,
): UpdateOrganisationSuccessAction {
  return {
    type: UPDATE_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationUpdateFailed(
  error: Error,
): UpdateOrganisationFailedAction {
  return {
    type: UPDATE_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationMembersFetch(
  organisationId: string,
): FetchOrganisationMembersAction {
  return {
    type: FETCH_ORGANISATION_MEMBERS,
    payload: organisationId,
  };
}

export function organisationMembersFetched(
  accounts: ListAccountsQueryModel,
): FetchOrganisationMembersSuccessAction {
  return {
    type: FETCH_ORGANISATION_MEMBERS_SUCCESS,
    payload: accounts,
  };
}

export function organisationMembersFetchFailed(
  error: Error,
): FetchOrganisationMembersFailedAction {
  return {
    type: FETCH_ORGANISATION_MEMBERS_FAILED,
    payload: error,
  };
}

export function organisationMemberOptionsFetch(): FetchOrganisationMemberOptionsAction {
  return {
    type: FETCH_ORGANISATION_MEMBER_OPTIONS,
  };
}

export function organisationMemberOptionsFetched(
  accounts: ListAccountsQueryModel,
): FetchOrganisationMemberOptionsSuccessAction {
  return {
    type: FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS,
    payload: accounts,
  };
}

export function organisationMemberOptionsFetchFailed(
  error: Error,
): FetchOrganisationMemberOptionsFailedAction {
  return {
    type: FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED,
    payload: error,
  };
}

export function organisationMemberAdd(): AddMemberToOrganisationAction {
  return {
    type: ADD_MEMBER_TO_ORGANISATION,
  };
}

export function organisationMemberAdded(
  organisation: OrganisationModel,
): AddMemberToOrganisationSuccessAction {
  return {
    type: ADD_MEMBER_TO_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationMemberAddFailed(
  error: Error,
): AddMemberToOrganisationFailedAction {
  return {
    type: ADD_MEMBER_TO_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationAccountRemove(
  organisationId: string,
  accountId: string,
): RemoveAccountFromOrganisationAction {
  return {
    type: REMOVE_ACCOUNT_FROM_ORGANISATION,
    payload: { organisationId, accountId },
  };
}

export function organisationAccountRemoved(): RemoveAccountFromOrganisationSuccessAction {
  return {
    type: REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS,
  };
}

export function organisationAccountRemoveFailed(
  error: Error,
): RemoveAccountFromOrganisationFailedAction {
  return {
    type: REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationGatewayAdd(
  organisationId: string,
  gatewayId: string,
): AddGatewayToOrganisationAction {
  return {
    type: ADD_GATEWAY_TO_ORGANISATION,
    organisationId,
    gatewayId,
  };
}

export function organisationGatewayAdded(
  organisation: OrganisationModel,
): AddGatewayToOrganisationSuccessAction {
  return {
    type: ADD_GATEWAY_TO_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationGatewayAddFailed(
  error: Error,
): AddGatewayToOrganisationFailedAction {
  return {
    type: ADD_GATEWAY_TO_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationGatewayRemove(
  organisationId: string,
  gatewayId: string,
): RemoveGatewayFromOrganisationAction {
  return {
    type: REMOVE_GATEWAY_FROM_ORGANISATION,
    organisationId,
    gatewayId,
  };
}

export function organisationGatewayRemoved(
  organisation: OrganisationModel,
): RemoveGatewayFromOrganisationSuccessAction {
  return {
    type: REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationGatewayRemoveFailed(
  error: Error,
): RemoveGatewayFromOrganisationFailedAction {
  return {
    type: REMOVE_GATEWAY_FROM_ORGANISATION_FAILED,
    payload: error,
  };
}

import { ThunkDispatch as Dispatch } from 'redux-thunk';
import {
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_FAILED,
  FETCH_ORGANISATION,
  FETCH_ORGANISATION_SUCCESS,
  FETCH_ORGANISATION_FAILED,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILED,
  FETCH_ORGANISATION_MEMBERS_SUCCESS,
  FETCH_ORGANISATION_MEMBERS,
  FETCH_ORGANISATION_MEMBERS_FAILED,
  FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED,
  FETCH_ORGANISATION_MEMBER_OPTIONS,
  FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS,
  ADD_MEMBER_TO_ORGANISATION,
  ADD_MEMBER_TO_ORGANISATION_SUCCESS,
  ADD_MEMBER_TO_ORGANISATION_FAILED,
  FETCH_ORGANISATIONS,
  FETCH_ORGANISATIONS_SUCCESS,
  FETCH_ORGANISATIONS_FAILED,
  INT_MAX,
  ADD_GATEWAY_TO_ORGANISATION,
  ADD_GATEWAY_TO_ORGANISATION_SUCCESS,
  ADD_GATEWAY_TO_ORGANISATION_FAILED,
  REMOVE_GATEWAY_FROM_ORGANISATION_FAILED,
  REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS,
  REMOVE_GATEWAY_FROM_ORGANISATION,
  REMOVE_SENSOR_FROM_ORGANISATION,
  REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS,
  REMOVE_SENSOR_FROM_ORGANISATION_FAILED,
  ADD_SMART_SENSOR_TO_ORGANISATION,
  ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS,
  ADD_SMART_SENSOR_TO_ORGANISATION_FAILED,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED,
  COUNT_ORGANISATIONS,
  COUNT_ORGANISATIONS_SUCCESS,
  COUNT_ORGANISATIONS_FAILED,
} from '../../constants';
import {
  OrganisationModel,
  AccountModel,
  SimpleAccountModel,
  TokenModel,
  SmartSensorModel,
} from '../../model/domain';
import {
  CreateOrganisationCmd,
  UpdateOrganisationCmd,
  AddAccountToOrganisationCmd,
} from '../../model/cmds';
import { organisationApi } from '../../api/organisationApi';
import { OrganisationsState } from '../../model/state';
import {
  ListOrganisationsQuery,
  ListOrganisationsQueryModel,
  ListAccountsQuery,
  ListAccountsQueryModel,
  CountOrganisationsModel,
} from '../../model/querys';
import {
  OrganisationsAction,
  organisationCreate,
  organisationCreated,
  organisationCreateFailed,
  organisationFetch,
  organisationFetched,
  organisationFetchFailed,
  organisationsFetch,
  organisationsFetched,
  organisationsFetchFailed,
  organisationUpdate,
  organisationUpdated,
  organisationUpdateFailed,
  organisationMembersFetch,
  organisationMembersFetched,
  organisationMembersFetchFailed,
  organisationMemberOptionsFetch,
  organisationMemberOptionsFetched,
  organisationMemberOptionsFetchFailed,
  AddMemberToOrganisationAction,
  organisationMemberAdd,
  organisationMemberAddFailed,
  organisationMemberAdded,
  organisationAccountRemove,
  organisationGatewayAdd,
  organisationGatewayAdded,
  organisationGatewayAddFailed,
  organisationGatewayRemove,
  organisationGatewayRemoved,
  organisationGatewayRemoveFailed,
  organisationSmartSensorAdd,
  organisationSmartSensorAdded,
  organisationSmartSensorAddFailed,
  organisationSmartSensorRemove,
  organisationSmartSensorRemoved,
  organisationsCount,
  organisationsCounted,
  organisationsCountFailed,
} from '../actions';
import { accountApi } from '../../api/accountApi';
import { getAccount } from './accounts';

export function createOrganisation(cmd: CreateOrganisationCmd) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationCreate());
      const organisation = await organisationApi.createOrganisation(cmd);
      dispatch(organisationCreated(organisation));
      dispatch(getOrganisations({ limit: INT_MAX, offset: 0 }));
    } catch (e) {
      dispatch(organisationCreateFailed(e));
    }
  };
}

export function getOrganisation(id: string) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationFetch());
      const organisationModel: OrganisationModel = await organisationApi.fetchOrganisation(
        id,
      );
      const tokenModel: TokenModel = await accountApi.fetchToken(
        organisationModel.creator.id,
      );
      organisationModel.creatorToken = tokenModel.token;
      dispatch(organisationFetched(organisationModel));
    } catch (e) {
      dispatch(organisationFetchFailed(e));
    }
  };
}

export function getOrganisations(query: ListOrganisationsQuery) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationsFetch(query));
      const response: ListOrganisationsQueryModel = await organisationApi.listOrganisations(
        query,
      );
      dispatch(organisationsFetched(response));
    } catch (e) {
      dispatch(organisationsFetchFailed(e));
    }
  };
}

export function countOrganisations() {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationsCount());
      const response: CountOrganisationsModel = await organisationApi.countOrganisations();
      dispatch(organisationsCounted(response));
    } catch (e) {
      dispatch(organisationsCountFailed(e));
    }
  };
}

export function updateOrganisation(id: string, cmd: UpdateOrganisationCmd) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationUpdate());
      const response: OrganisationModel = await organisationApi.updateOrganisation(
        id,
        cmd,
      );
      dispatch(organisationUpdated(response));
    } catch (e) {
      dispatch(organisationUpdateFailed(e));
    }
  };
}

export function getOrganisationMembers(organisationId: string) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationMembersFetch(organisationId));
      const query: ListAccountsQuery = {
        limit: INT_MAX,
        offset: 0,
        includeDisabled: true,
      };
      const response: ListAccountsQueryModel = await accountApi.fetchAccounts(
        query,
      );
      response.accounts = response.accounts.filter((a) => !a.organisation?.id);
      dispatch(organisationMembersFetched(response));
    } catch (e) {
      dispatch(organisationMembersFetchFailed(e));
    }
  };
}

export function getOrganisationMemberOptions() {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationMemberOptionsFetch());
      const query: ListAccountsQuery = {
        limit: INT_MAX,
        offset: 0,
        accountsWithoutOrganisations: true,
      };
      const response: ListAccountsQueryModel = await accountApi.fetchAccounts(
        query,
      );
      dispatch(organisationMemberOptionsFetched(response));
    } catch (e) {
      dispatch(organisationMemberOptionsFetchFailed(e));
    }
  };
}

export function addMemberToOrganisation(
  organisationId: string,
  cmd: AddAccountToOrganisationCmd,
) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationMemberAdd());
      const response: OrganisationModel = await organisationApi.addMemberToOrganisation(
        organisationId,
        cmd,
      );
      await dispatch(getOrganisationMemberOptions());
      await dispatch(getOrganisation(organisationId));
      await dispatch(organisationMemberAdded(response));
      await dispatch(getAccount(cmd.accountId));
    } catch (e) {
      dispatch(organisationMemberAddFailed(e));
    }
  };
}

export function removeAccountFromOrganisation(
  organisationId: string,
  accountId: string,
) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationAccountRemove(organisationId, accountId));
      await organisationApi.removeAccountFromOrganisation(
        organisationId,
        accountId,
      );
      await dispatch(getOrganisationMemberOptions());
      await dispatch(getOrganisation(organisationId));
      await dispatch(getAccount(accountId));
    } catch (e) {
      dispatch(organisationMemberAddFailed(e));
    }
  };
}

export function addGatewayToOrganisation(
  organisationId: string,
  gatewayId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationGatewayAdd(organisationId, gatewayId));
      const organisation: OrganisationModel = await organisationApi.addGatewayToOrganisation(
        organisationId,
        { gatewayId },
      );
      dispatch(organisationGatewayAdded(organisation));
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(organisationGatewayAddFailed(e));
    }
  };
}

export function removeGatewayFromOrganisation(
  organisationId: string,
  gatewayId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationGatewayRemove(organisationId, gatewayId));
      const organisation: OrganisationModel = await organisationApi.removeGatewayFromOrganisation(
        organisationId,
        gatewayId,
      );
      dispatch(organisationGatewayRemoved(organisation));
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(organisationGatewayRemoveFailed(e));
    }
  };
}

export function addSmartSensorToOrganisation(
  organisationId: string,
  smartSensorId: string,
) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationSmartSensorAdd(organisationId, smartSensorId));
      const organisation: OrganisationModel = await organisationApi.addSmartSensorToOrganisation(
        organisationId,
        { smartSensorId },
      );
      dispatch(organisationSmartSensorAdded(organisation));
    } catch (e) {
      dispatch(organisationSmartSensorAddFailed(e));
    }
  };
}

export function removeSmartSensorFromOrganisation(
  organisationId: string,
  smartSensorId: string,
) {
  return async (dispatch: Dispatch<OrganisationsAction, {}, any>) => {
    try {
      dispatch(organisationSmartSensorRemove(organisationId, smartSensorId));
      const sensor = await organisationApi.removeSmartSensorFromOrganisation(
        organisationId,
        smartSensorId,
      );
      const organisation = await organisationApi.fetchOrganisation(
        organisationId,
      );
      dispatch(organisationSmartSensorRemoved(organisation));
    } catch (e) {
      dispatch(organisationGatewayRemoveFailed(e));
    }
  };
}

export default function organisationsReducer(
  state: OrganisationsState = {
    create: {
      loading: false,
      error: null,
      organisation: null,
    },
    list: {
      loading: false,
      error: null,
      organisations: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      organisation: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    members: {
      accounts: [],
      loading: false,
      error: null,
      adding: false,
    },
    memberOptions: {
      accounts: [],
      loading: false,
      error: null,
    },
    addGateway: {
      loading: false,
      error: null,
      organisation: null,
      organisationId: null,
      gatewayId: null,
    },
    removeGateway: {
      loading: false,
      error: null,
      organisation: null,
      organisationId: null,
      gatewayId: null,
    },
    addSmartSensor: {
      loading: false,
      error: null,
      organisation: null,
      organisationId: null,
      smartSensorId: null,
    },
    removeSmartSensor: {
      loading: false,
      error: null,
      organisation: null,
      organisationId: null,
      smartSensorId: null,
    },
    counts: {
      loading: false,
      error: null,
      active: null,
      inactive: null,
      all: null,
    }
  },
  action: OrganisationsAction,
): OrganisationsState {
  switch (action.type) {
    case CREATE_ORGANISATION:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          organisation: null,
        },
      };
    case CREATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          organisation: action.payload,
        },
      };
    case CREATE_ORGANISATION_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          organisation: null,
        },
      };
    case FETCH_ORGANISATION:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          organisation: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_ORGANISATION_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          organisation: action.payload,
        },
      };
    case FETCH_ORGANISATION_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          organisation: null,
        },
      };
    case UPDATE_ORGANISATION:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
          organisation: action.payload,
        },
      };
    case UPDATE_ORGANISATION_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_ORGANISATION_MEMBERS:
      return {
        ...state,
        members: { ...state.members, loading: true, error: null },
      };
    case FETCH_ORGANISATION_MEMBERS_SUCCESS:
      return {
        ...state,
        members: {
          ...state.members,
          loading: false,
          error: null,
          accounts: action.payload.accounts,
        },
      };
    case FETCH_ORGANISATION_MEMBERS_FAILED:
      return {
        ...state,
        members: {
          ...state.members,
          loading: false,
          error: action.payload,
          accounts: [],
        },
      };
    case FETCH_ORGANISATION_MEMBER_OPTIONS:
      return {
        ...state,
        memberOptions: { ...state.memberOptions, loading: true, error: null },
      };
    case FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS:
      return {
        ...state,
        memberOptions: {
          ...state.memberOptions,
          loading: false,
          error: null,
          accounts: action.payload.accounts,
        },
      };
    case FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED:
      return {
        ...state,
        memberOptions: {
          ...state.memberOptions,
          loading: false,
          error: action.payload,
          accounts: [],
        },
      };

    case FETCH_ORGANISATIONS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          organisations: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          organisations: action.payload.organisations,
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: action.payload.total,
        },
      };

    case FETCH_ORGANISATIONS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          organisations: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    case ADD_MEMBER_TO_ORGANISATION:
      return { ...state, members: { ...state.members, adding: true } };
    case ADD_MEMBER_TO_ORGANISATION_SUCCESS:
      return { ...state, members: { ...state.members, adding: false } };
    case ADD_MEMBER_TO_ORGANISATION_FAILED:
      return { ...state, members: { ...state.members, adding: false } };

    case ADD_GATEWAY_TO_ORGANISATION:
      return {
        ...state,
        addGateway: {
          ...state.addGateway,
          loading: true,
          error: null,
          organisation: null,
          organisationId: action.organisationId,
          gatewayId: action.gatewayId,
        },
      };
    case ADD_GATEWAY_TO_ORGANISATION_SUCCESS:
      return {
        ...state,
        addGateway: {
          ...state.addGateway,
          loading: false,
          error: null,
          organisation: action.payload,
          organisationId: null,
          gatewayId: null,
        },
      };
    case ADD_GATEWAY_TO_ORGANISATION_FAILED:
      return {
        ...state,
        addGateway: {
          ...state.addGateway,
          loading: false,
          error: action.payload,
          organisation: null,
          organisationId: null,
          gatewayId: null,
        },
      };

    case REMOVE_GATEWAY_FROM_ORGANISATION:
      return {
        ...state,
        removeGateway: {
          ...state.removeGateway,
          loading: true,
          error: null,
          organisation: null,
          organisationId: action.organisationId,
          gatewayId: action.gatewayId,
        },
      };
    case REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS:
      return {
        ...state,
        removeGateway: {
          ...state.removeGateway,
          loading: false,
          error: null,
          organisation: action.payload,
          organisationId: null,
          gatewayId: null,
        },
      };
    case REMOVE_GATEWAY_FROM_ORGANISATION_FAILED:
      return {
        ...state,
        removeGateway: {
          ...state.removeGateway,
          loading: false,
          error: action.payload,
          organisation: null,
          organisationId: null,
          gatewayId: null,
        },
      };

    case ADD_SMART_SENSOR_TO_ORGANISATION:
      return {
        ...state,
        addSmartSensor: {
          ...state.addSmartSensor,
          loading: true,
          error: null,
          organisation: null,
          organisationId: action.organisationId,
          smartSensorId: action.smartSensorId,
        },
      };
    case ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS:
      return {
        ...state,
        addSmartSensor: {
          ...state.addSmartSensor,
          loading: false,
          error: null,
          organisation: action.payload,
          organisationId: null,
          smartSensorId: null,
        },
      };
    case ADD_SMART_SENSOR_TO_ORGANISATION_FAILED:
      return {
        ...state,
        addSmartSensor: {
          ...state.addSmartSensor,
          loading: false,
          error: action.payload,
          organisation: null,
          organisationId: null,
          smartSensorId: null,
        },
      };

    case REMOVE_SMART_SENSOR_FROM_ORGANISATION:
      return {
        ...state,
        removeSmartSensor: {
          ...state.removeSmartSensor,
          loading: true,
          error: null,
          organisation: null,
          organisationId: action.organisationId,
          smartSensorId: action.smartSensorId,
        },
      };
    case REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS:
      return {
        ...state,
        removeSmartSensor: {
          ...state.removeSmartSensor,
          loading: false,
          error: null,
          organisation: action.payload,
          organisationId: null,
          smartSensorId: null,
        },
      };
    case REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED:
      return {
        ...state,
        removeSmartSensor: {
          ...state.removeSmartSensor,
          loading: false,
          error: action.payload,
          organisation: null,
          organisationId: null,
          smartSensorId: null,
        },
      };
    case COUNT_ORGANISATIONS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: true,
          error: null,
          active: null,
          inactive: null,
          all: null,
        }
      };
    case COUNT_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: null,
          active: action.payload.active,
          inactive: action.payload.inactive,
          all: action.payload.all,
        }
      };
    case COUNT_ORGANISATIONS_FAILED:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: action.payload,
          active: null,
          inactive: null,
          all: null,
        }
      };

    default:
      return state;
  }
}

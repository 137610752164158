import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { organisationApi } from '../../api/organisationApi';

import { productApi } from '../../api/productApi';
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_FEED_TABLE_FAILED,
  DELETE_PRODUCT_SUCCESS,
  INT_MAX,
  DELETE_PRODUCT_FAILED,
} from '../../constants';
import { CreateProductCmd, UpdateProductCmd } from '../../model/cmds';
import { ListProductsQuery, ListProductsQueryModel } from '../../model/querys';
import { ProductsState } from '../../model/state';
import {
  productCreate,
  productCreated,
  productCreateFailed,
  productFetch,
  productFetched,
  productFetchFailed,
  ProductsAction,
  productsFetch,
  productsFetched,
  productsFetchFailed,
  productUpdate,
  productUpdated,
  productUpdateFailed,
  productDelete,
  productDeleted,
  productDeleteFailed,
} from '../actions';

export function createProduct(cmd: CreateProductCmd) {
  return async (dispatch: Dispatch<ProductsAction, {}, any>) => {
    try {
      dispatch(productCreate());
      const product = await productApi.createProduct(cmd);
      dispatch(productCreated(product));
    } catch (e) {
      dispatch(productCreateFailed(e as any));
    }
  };
}

export function getProduct(id: string) {
  return async (dispatch: Dispatch<ProductsAction, {}, any>) => {
    try {
      dispatch(productFetch());
      const product = await productApi.getProduct(id);
      dispatch(productFetched(product));
    } catch (e) {
      dispatch(productFetchFailed(e as any));
    }
  };
}

export function getProducts(query: ListProductsQuery) {
  return async (dispatch: Dispatch<ProductsAction, {}, any>) => {
    try {
      dispatch(productsFetch(query));
      const queryModel: ListProductsQueryModel = await productApi.listProducts(
        query,
      );
      const products = queryModel.products;
      dispatch(productsFetched(queryModel, products));
    } catch (e) {
      dispatch(productsFetchFailed(e as any));
    }
  };
}

export function updateProduct(id: string, cmd: UpdateProductCmd) {
  return async (dispatch: Dispatch<ProductsAction, {}, any>) => {
    try {
      dispatch(productUpdate());
      const response = await productApi.updateProduct(id, cmd);
      dispatch(productUpdated(response));
      dispatch(getProduct(id));
    } catch (e) {
      dispatch(productUpdateFailed(e as any));
    }
  };
}

export function deleteProduct(productId: string, onSuccess?: Function) {
  return async (dispatch: Dispatch<ProductsAction, {}, any>) => {
    try {
      dispatch(productDelete(productId));
      await productApi.deleteProduct(productId);
      const product = await productApi.getProduct(productId);
      dispatch(productDeleted(product));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(productDeleteFailed(e as any));
    }
  };
}

export default function productsReducer(
  state: ProductsState = {
    create: {
      loading: false,
      error: null,
      product: null,
    },
    list: {
      loading: false,
      error: null,
      products: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      product: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      success: null,
      error: null,
    },
  },
  action: ProductsAction,
): ProductsState {
  switch (action.type) {
    case CREATE_PRODUCT:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          product: null,
        },
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          product: action.payload,
        },
      };
    case CREATE_PRODUCT_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          product: null,
        },
      };
    case FETCH_PRODUCT:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          product: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          product: action.payload,
        },
      };
    case FETCH_PRODUCT_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          product: null,
        },
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          products: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          products: action.payload.products,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_PRODUCTS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          products: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null,
          success: null,
        },
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          product: action.payload,
        },
        delete: {
          ...state.delete,
          loading: false,
          error: null,
          success: true,
        },
      };

    case DELETE_PRODUCT_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    default:
      return state;
  }
}

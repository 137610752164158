import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { activationApi } from '../../api/activationApi';
import {
    ACTIVATE_ACCOUNT,
    ACTIVATE_ACCOUNT_FAILED,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_FARM,
    ACTIVATE_FARM_FAILED,
    ACTIVATE_FARM_SUCCESS,
    ACTIVATE_ORGANISATION,
    ACTIVATE_ORGANISATION_FAILED,
    ACTIVATE_ORGANISATION_SUCCESS,
    ACTIVATE_POND,
    ACTIVATE_POND_FAILED,
    ACTIVATE_POND_SUCCESS,
    ACTIVATE_SCORING_ACCOUNT,
    ACTIVATE_SCORING_ACCOUNT_FAILED,
    ACTIVATE_SCORING_ACCOUNT_SUCCESS,
    ACTIVATE_VIP_ACCOUNT,
    ACTIVATE_VIP_ACCOUNT_FAILED,
    ACTIVATE_VIP_ACCOUNT_SUCCESS,
    DEACTIVATE_ACCOUNT,
    DEACTIVATE_ACCOUNT_FAILED,
    DEACTIVATE_ACCOUNT_SUCCESS,
    DEACTIVATE_FARM,
    DEACTIVATE_FARM_FAILED,
    DEACTIVATE_FARM_SUCCESS,
    DEACTIVATE_ORGANISATION,
    DEACTIVATE_ORGANISATION_FAILED,
    DEACTIVATE_ORGANISATION_SUCCESS,
    DEACTIVATE_POND,
    DEACTIVATE_POND_FAILED,
    DEACTIVATE_POND_SUCCESS,
    DEACTIVATE_SCORING_ACCOUNT,
    DEACTIVATE_SCORING_ACCOUNT_FAILED,
    DEACTIVATE_SCORING_ACCOUNT_SUCCESS,
    DEACTIVATE_VIP_ACCOUNT,
    DEACTIVATE_VIP_ACCOUNT_FAILED,
    DEACTIVATE_VIP_ACCOUNT_SUCCESS,
} from '../../constants';
import { AccountModel, FarmModel, OrganisationModel, PondModel } from '../../model/domain';
import { ActivationState } from '../../model/state';
import { accountActivate, accountActivated, accountActivateFailed, accountDeactivate, accountDeactivateFailed, accountDectivated, accountFetched, ActivationAction, DeactivationAction, farmActivate, farmActivated, farmActivateFailed, farmDeactivate, farmDeactivateFailed, farmDectivated, farmFetched, organisationActivate, organisationActivated, organisationActivateFailed, organisationDeactivate, organisationDeactivateFailed, organisationDectivated, organisationFetched, pondActivate, pondActivated, pondActivateFailed, pondDeactivate, pondDeactivateFailed, pondDectivated, pondFetched, scoringAccountActivate, scoringAccountActivated, scoringAccountActivateFailed, scoringAccountDeactivate, scoringAccountDeactivateFailed, scoringAccountDectivated, vipAccountActivate, vipAccountActivated, vipAccountActivateFailed, vipAccountDeactivate, vipAccountDeactivateFailed, vipAccountDectivated } from '../actions';


export function activateOrganisation(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(organisationActivate(id));
            const organisation: OrganisationModel = await activationApi.activateOrganisation(id);
            dispatch(organisationActivated(organisation));
            dispatch(organisationFetched(organisation))
        } catch (e) {
            dispatch(organisationActivateFailed(e as Error));
        }
    };
}

export function deactivateOrganisation(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(organisationDeactivate(id));
            const organisation: OrganisationModel = await activationApi.deactivateOrganisation(id);
            dispatch(organisationDectivated(organisation));
            dispatch(organisationFetched(organisation))
        } catch (e) {
            dispatch(organisationDeactivateFailed(e as Error));
        }
    };
}


export function activateFarm(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(farmActivate(id));
            const farm: FarmModel = await activationApi.activateFarm(id);
            dispatch(farmActivated(farm));
            dispatch(farmFetched(farm))
        } catch (e) {
            dispatch(farmActivateFailed(e as Error));
        }
    };
}

export function deactivateFarm(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(farmDeactivate(id));
            const farm: FarmModel = await activationApi.deactivateFarm(id);
            dispatch(farmDectivated(farm));
            dispatch(farmFetched(farm))
        } catch (e) {
            dispatch(farmDeactivateFailed(e as Error));
        }
    };
}

export function activatePond(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(pondActivate(id));
            const pond: PondModel = await activationApi.activatePond(id);
            dispatch(pondActivated(pond));
            dispatch(pondFetched(pond))
        } catch (e) {
            dispatch(pondActivateFailed(e as Error));
        }
    };
}

export function deactivatePond(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(pondDeactivate(id));
            const pond: PondModel = await activationApi.deactivatePond(id);
            dispatch(pondDectivated(pond));
            dispatch(pondFetched(pond))
        } catch (e) {
            dispatch(pondDeactivateFailed(e as Error));
        }
    };
}

export function activateAccount(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(accountActivate(id));
            const account: AccountModel = await activationApi.activateAccount(id);
            dispatch(accountActivated(account));
            dispatch(accountFetched(account))
        } catch (e) {
            dispatch(accountActivateFailed(e as Error));
        }
    };
}

export function deactivateAccount(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(accountDeactivate(id));
            const account: AccountModel = await activationApi.deactivateAccount(id);
            dispatch(accountDectivated(account));
            dispatch(accountFetched(account))
        } catch (e) {
            dispatch(accountDeactivateFailed(e as Error));
        }
    };
}


export function activateVipAccount(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(vipAccountActivate(id));
            const account: AccountModel = await activationApi.activateVipAccount(id);
            dispatch(vipAccountActivated(account));
            dispatch(accountFetched(account))
        } catch (e) {
            dispatch(vipAccountActivateFailed(e as Error));
        }
    };
}

export function deactivateVipAccount(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(vipAccountDeactivate(id));
            const account: AccountModel = await activationApi.deactivateVipAccount(id);
            dispatch(vipAccountDectivated(account));
            dispatch(accountFetched(account))
        } catch (e) {
            dispatch(vipAccountDeactivateFailed(e as Error));
        }
    };
}


export function activateScoringAccount(id: string) {
    return async (dispatch: Dispatch<ActivationAction, {}, any>) => {
        try {
            dispatch(scoringAccountActivate(id));
            const account: AccountModel = await activationApi.activateScoringAccount(id);
            dispatch(scoringAccountActivated(account));
            dispatch(accountFetched(account))
        } catch (e) {
            dispatch(scoringAccountActivateFailed(e as Error));
        }
    };
}

export function deactivateScoringAccount(id: string) {
    return async (dispatch: Dispatch<DeactivationAction, {}, any>) => {
        try {
            dispatch(scoringAccountDeactivate(id));
            const account: AccountModel = await activationApi.deactivateScoringAccount(id);
            dispatch(scoringAccountDectivated(account));
            dispatch(accountFetched(account))
        } catch (e) {
            dispatch(scoringAccountDeactivateFailed(e as Error));
        }
    };
}



export default function activationReducer(
    state: ActivationState = {
        organisation: {
            loading: false,
            error: null,
            organisation: null,
        },
        farm: {
            loading: false,
            error: null,
            farm: null,
        },
        pond: {
            loading: false,
            error: null,
            pond: null,
        },
        account: {
            loading: false,
            error: null,
            account: null,
        },
    },
    action: ActivationAction | DeactivationAction,
): ActivationState {
    switch (action.type) {
        case ACTIVATE_ORGANISATION:
        case DEACTIVATE_ORGANISATION:
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    loading: true,
                    error: null,
                    organisation: null,
                },
            };
        case ACTIVATE_ORGANISATION_SUCCESS:
        case DEACTIVATE_ORGANISATION_SUCCESS:

            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    loading: false,
                    error: null,
                    organisation: action.payload,
                },
            };
        case ACTIVATE_ORGANISATION_FAILED:
        case DEACTIVATE_ORGANISATION_FAILED:
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    loading: false,
                    error: action.payload,
                    organisation: null,
                },
            };
        case ACTIVATE_FARM:
        case DEACTIVATE_FARM:
            return {
                ...state,
                farm: {
                    ...state.farm,
                    loading: true,
                    error: null,
                    farm: null,
                },
            };
        case ACTIVATE_FARM_SUCCESS:
        case DEACTIVATE_FARM_SUCCESS:

            return {
                ...state,
                farm: {
                    ...state.farm,
                    loading: false,
                    error: null,
                    farm: action.payload,
                },
            };
        case ACTIVATE_FARM_FAILED:
        case DEACTIVATE_FARM_FAILED:
            return {
                ...state,
                farm: {
                    ...state.farm,
                    loading: false,
                    error: action.payload,
                    farm: null,
                },
            };
        case ACTIVATE_POND:
        case DEACTIVATE_POND:
            return {
                ...state,
                pond: {
                    ...state.pond,
                    loading: true,
                    error: null,
                    pond: null,
                },
            };
        case ACTIVATE_POND_SUCCESS:
        case DEACTIVATE_POND_SUCCESS:

            return {
                ...state,
                pond: {
                    ...state.pond,
                    loading: false,
                    error: null,
                    pond: action.payload,
                },
            };
        case ACTIVATE_POND_FAILED:
        case DEACTIVATE_POND_FAILED:
            return {
                ...state,
                pond: {
                    ...state.pond,
                    loading: false,
                    error: action.payload,
                    pond: null,
                },
            };
        case ACTIVATE_ACCOUNT:
        case DEACTIVATE_ACCOUNT:
        case ACTIVATE_VIP_ACCOUNT:
        case DEACTIVATE_VIP_ACCOUNT:
        case ACTIVATE_SCORING_ACCOUNT:
        case DEACTIVATE_SCORING_ACCOUNT:
            return {
                ...state,
                account: {
                    ...state.account,
                    loading: true,
                    error: null,
                    account: null,
                },
            };
        case ACTIVATE_ACCOUNT_SUCCESS:
        case DEACTIVATE_ACCOUNT_SUCCESS:
        case ACTIVATE_VIP_ACCOUNT_SUCCESS:
        case DEACTIVATE_VIP_ACCOUNT_SUCCESS:
        case ACTIVATE_SCORING_ACCOUNT_SUCCESS:
        case DEACTIVATE_SCORING_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    loading: false,
                    error: null,
                    account: action.payload,
                },
            };
        case ACTIVATE_ACCOUNT_FAILED:
        case DEACTIVATE_ACCOUNT_FAILED:
        case ACTIVATE_VIP_ACCOUNT_FAILED:
        case DEACTIVATE_VIP_ACCOUNT_FAILED:
        case ACTIVATE_SCORING_ACCOUNT_FAILED:
        case DEACTIVATE_SCORING_ACCOUNT_FAILED:
            return {
                ...state,
                account: {
                    ...state.account,
                    loading: false,
                    error: action.payload,
                    account: null,
                },
            };


        default:
            return state;
    }
}

import { passwordRegex } from './validation';
export const generatePassword = (length: number) => {
  const pattern = passwordRegex;
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';

  while (!password.match(pattern)) {
    password = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
  }

  return password;
};

import {
  CREATE_MANUFACTURER,
  CREATE_MANUFACTURER_FAILED,
  CREATE_MANUFACTURER_SUCCESS,
  FETCH_MANUFACTURER,
  FETCH_MANUFACTURERS,
  FETCH_MANUFACTURERS_FAILED,
  FETCH_MANUFACTURERS_SUCCESS,
  FETCH_MANUFACTURER_FAILED,
  FETCH_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_FAILED,
  DELETE_MANUFACTURER,
  DELETE_MANUFACTURER_SUCCESS,
  DELETE_MANUFACTURER_FAILED,
} from '../../constants';
import { ManufacturerModel } from '../../model/domain';
import { SimpleManufacturerModel } from '../../model/simple';
import {
  ListManufacturersQuery,
  ListManufacturersQueryModel,
} from '../../model/querys';

// MANUFACTURERS
export interface CreateManufacturerAction {
  type: CREATE_MANUFACTURER;
}

export interface CreateManufacturerSuccessAction {
  type: CREATE_MANUFACTURER_SUCCESS;
  payload: ManufacturerModel;
}

export interface CreateManufacturerFailedAction {
  type: CREATE_MANUFACTURER_FAILED;
  payload: Error;
}

export interface FetchManufacturerAction {
  type: FETCH_MANUFACTURER;
}

export interface FetchManufacturerSuccessAction {
  type: FETCH_MANUFACTURER_SUCCESS;
  payload: ManufacturerModel;
}

export interface FetchManufacturerFailedAction {
  type: FETCH_MANUFACTURER_FAILED;
  payload: Error;
}

export interface FetchManufacturersAction {
  type: FETCH_MANUFACTURERS;
  payload: ListManufacturersQuery;
}

export interface FetchManufacturersSuccessAction {
  type: FETCH_MANUFACTURERS_SUCCESS;
  payload: {
    query: ListManufacturersQueryModel;
    manufacturers: SimpleManufacturerModel[];
  };
}

export interface FetchManufacturersFailedAction {
  type: FETCH_MANUFACTURERS_FAILED;
  payload: Error;
}

export interface UpdateManufacturerAction {
  type: UPDATE_MANUFACTURER;
}

export interface UpdateManufacturerSuccessAction {
  type: UPDATE_MANUFACTURER_SUCCESS;
  payload: ManufacturerModel;
}

export interface UpdateManufacturerFailedAction {
  type: UPDATE_MANUFACTURER_FAILED;
  payload: Error;
}

export interface DeleteManufacturerAction {
  type: DELETE_MANUFACTURER;
  payload: {
    manufacturerId: string;
  };
}

export interface DeleteManufacturerSuccessAction {
  type: DELETE_MANUFACTURER_SUCCESS;
  payload: ManufacturerModel;
}

export interface DeleteManufacturerFailedAction {
  type: DELETE_MANUFACTURER_FAILED;
  payload: Error;
}

export type ManufacturerDeleteAction =
  | DeleteManufacturerAction
  | DeleteManufacturerSuccessAction
  | DeleteManufacturerFailedAction;
export type ManufacturerUpdateAction =
  | UpdateManufacturerAction
  | UpdateManufacturerSuccessAction
  | UpdateManufacturerFailedAction;
export type ManufacturerCreateAction =
  | CreateManufacturerAction
  | CreateManufacturerSuccessAction
  | CreateManufacturerFailedAction;
export type ManufacturerFetchAction =
  | FetchManufacturerAction
  | FetchManufacturerSuccessAction
  | FetchManufacturerFailedAction;
export type ManufacturersFetchAction =
  | FetchManufacturersAction
  | FetchManufacturersSuccessAction
  | FetchManufacturersFailedAction;
export type ManufacturersAction =
  | ManufacturerCreateAction
  | ManufacturerFetchAction
  | ManufacturersFetchAction
  | ManufacturerUpdateAction
  | ManufacturerDeleteAction;

// Action creators
export function manufacturerCreate(): CreateManufacturerAction {
  return {
    type: CREATE_MANUFACTURER,
  };
}

export function manufacturerCreated(
  manufacturer: ManufacturerModel,
): CreateManufacturerSuccessAction {
  return {
    type: CREATE_MANUFACTURER_SUCCESS,
    payload: manufacturer,
  };
}

export function manufacturerCreateFailed(
  error: Error,
): CreateManufacturerFailedAction {
  return {
    type: CREATE_MANUFACTURER_FAILED,
    payload: error,
  };
}

export function manufacturerFetch(): FetchManufacturerAction {
  return {
    type: FETCH_MANUFACTURER,
  };
}

export function manufacturerFetched(
  manufacturer: ManufacturerModel,
): FetchManufacturerSuccessAction {
  return {
    type: FETCH_MANUFACTURER_SUCCESS,
    payload: manufacturer,
  };
}

export function manufacturerFetchFailed(
  error: Error,
): FetchManufacturerFailedAction {
  return {
    type: FETCH_MANUFACTURER_FAILED,
    payload: error,
  };
}

export function manufacturersFetch(
  query: ListManufacturersQuery,
): FetchManufacturersAction {
  return {
    type: FETCH_MANUFACTURERS,
    payload: query,
  };
}

export function manufacturersFetched(
  query: ListManufacturersQueryModel,
  manufacturers: SimpleManufacturerModel[],
): FetchManufacturersSuccessAction {
  return {
    type: FETCH_MANUFACTURERS_SUCCESS,
    payload: { query, manufacturers },
  };
}

export function manufacturersFetchFailed(
  error: Error,
): FetchManufacturersFailedAction {
  return {
    type: FETCH_MANUFACTURERS_FAILED,
    payload: error,
  };
}

export function manufacturerUpdate(): UpdateManufacturerAction {
  return {
    type: UPDATE_MANUFACTURER,
  };
}

export function manufacturerUpdated(
  manufacturer: ManufacturerModel,
): UpdateManufacturerSuccessAction {
  return {
    type: UPDATE_MANUFACTURER_SUCCESS,
    payload: manufacturer,
  };
}

export function manufacturerUpdateFailed(
  error: Error,
): UpdateManufacturerFailedAction {
  return {
    type: UPDATE_MANUFACTURER_FAILED,
    payload: error,
  };
}

export function manufacturerDelete(
  manufacturerId: string,
): DeleteManufacturerAction {
  return {
    type: DELETE_MANUFACTURER,
    payload: { manufacturerId },
  };
}

export function manufacturerDeleted(
  manufacturer: ManufacturerModel,
): DeleteManufacturerSuccessAction {
  return {
    type: DELETE_MANUFACTURER_SUCCESS,
    payload: manufacturer,
  };
}

export function manufacturerDeleteFailed(
  error: Error,
): DeleteManufacturerFailedAction {
  return {
    type: DELETE_MANUFACTURER_FAILED,
    payload: error,
  };
}

import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Tooltip,
  Select,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { ProductModel } from '../../model/domain';
import { UpdateProductCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';
import { ProductCategory } from '../../model/enums';
import { SimpleManufacturerModel } from '../../model/simple';
import _ from 'lodash';

type Props = {
  product: ProductModel;
  onUpdate: (cmd: UpdateProductCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
  manufacturerOptions: SimpleManufacturerModel[];
};

export const ProductDataForm: React.FC<Props> = ({
  product,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
  manufacturerOptions,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...product,
      name: product.name,
      category: product.category,
      manufacturerId: product.manufacturer?.id || '',
    });
  }, [product]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Product updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Product',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { manufacturerId, name, category } = values;
    const cmd: UpdateProductCmd = {};
    if (manufacturerId != '' && manufacturerId !== product.manufacturer?.id) {
      cmd.manufacturerId = manufacturerId;
    }
    if (name != '' && name !== product.name) {
      cmd.name = name;
    }
    if (category !== product.category) {
      cmd.category = category;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && product) {
      cmd.manufacturerId = product.manufacturer?.id;
      cmd.name = product.name;
      cmd.category = product.category;
    }

    if (Object.keys(cmd).length > 0) {
      onUpdate(cmd);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Name of the Product" />
      </Form.Item>

      <Form.Item label="Category" name="category" rules={[{ required: true }]}>
        <Select placeholder="Select product category">
          <Select.Option value={ProductCategory.FEED}>{'FEED'}</Select.Option>
          <Select.Option value={ProductCategory.FEED_SUPPLEMENT}>
            {'FEED_SUPPLEMENT'}
          </Select.Option>
          <Select.Option value={ProductCategory.CHEMICAL}>
            {'CHEMICAL'}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="manufacturerId"
        label="Manufacturer"
        rules={[{ required: false, message: 'Selected manufacturer' }]}
      >
        <Select
          placeholder="Select Manufacturer"
          options={
            _.sortedUniq(manufacturerOptions).map((option) => {
              return {
                label: `${option.name}`,
                value: option.id,
              };
            }) || []
          }
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Product
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal, Tabs, Typography } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Action, Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import {
  PlusOutlined,
  UnorderedListOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { SensorCapsResponsiveTable } from '../sensorCaps/sensorCapsResponsiveTable';
import { CreateSensorCapForm } from '../sensorCaps/createSensorCapForm';
import { ImportSensorCapsForm } from '../sensorCaps/importSensorCapsForm';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedSensorCaps: React.FC<Props> = ({ location }) => {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [triggerSensorCapsReload, setSensorCapsTriggerReload] = useState(false);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout>
      <Content>
        <Modal
          title="Create Sensor Cap"
          visible={isCreateModalVisible}
          onCancel={() => {
            setIsCreateModalVisible(false);
          }}
          footer={null}
        >
          <CreateSensorCapForm
            onCreated={() => {
              setSensorCapsTriggerReload(true);
              setIsCreateModalVisible(false);
            }}
          />
        </Modal>

        <Modal
          title="Import sensor cap values from XLSX"
          visible={isImportModalVisible}
          onCancel={() => {
            setIsImportModalVisible(false);
          }}
          footer={null}
        >
          <ImportSensorCapsForm
            onImported={() => {
              setSensorCapsTriggerReload(true);
              setIsImportModalVisible(false);
            }}
          />
        </Modal>

        <SensorCapsResponsiveTable
          reload={triggerSensorCapsReload}
          onReloaded={() => setSensorCapsTriggerReload(false)}
          queryParams={queryParams}
        />

        {!isCreateModalVisible && !isImportModalVisible && (
          <Fab
            style={{ bottom: 0, right: 0 }}
            mainButtonStyles={{
              backgroundColor: getNavbarColor(),
              color: getNavbarTextColor(),
            }}
            icon={<PlusOutlined />}
            alwaysShowTitle={false}
          >
            <Action text="Create" onClick={() => setIsCreateModalVisible(true)}>
              <UnorderedListOutlined />
            </Action>
            <Action text="Import" onClick={() => setIsImportModalVisible(true)}>
              <FileExcelOutlined />
            </Action>
          </Fab>
        )}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const SensorCaps = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedSensorCaps));

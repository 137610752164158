import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import Pages from './components/_pages';
import { Layout, Typography } from 'antd';
import { checkAuthentication } from './duck/modules/auth';

import { RootState } from './duck/index';
import { NavigationMenu } from './components/_navigation/navigationMenu';
import { bindActionCreators, Dispatch } from 'redux';
import NavBar from './components/_navigation/navBar/navBar';
import SideBar from './components/_navigation/sideBar/sideBar';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const App = ({ checkAuthenticationConnect, isAuthenticated }: Props) => {
  React.useEffect(() => {
    checkAuthenticationConnect();
  }, []);

  const [toggleDrawer, setToggleDrawer] = React.useState(true);

  const Menu = (
    <NavigationMenu onClick={(evt) => setToggleDrawer(!toggleDrawer)} />
  );

  const App =
    isAuthenticated !== null ? (
      <div>
        <NavBar toggleDrawer={toggleDrawer} menu={Menu} />
        <Layout style={{ minHeight: '100vh' }}>
          <SideBar menu={Menu} />
          <Layout.Content
            style={{
              paddingBottom: 20,
            }}
          >
            <Route component={Pages} />
          </Layout.Content>
        </Layout>
        <Layout.Footer
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <Typography>
            Made with ❤ by <a href="https://hydroneo.net/">HydroNeo</a>
          </Typography>
        </Layout.Footer>
      </div>
    ) : null;

  return <div className="App">{App}</div>;
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      checkAuthenticationConnect: checkAuthentication,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import {
  CreateFeedTableCmd,
  UpdateFeedTableCmd,
  UpdateFeedTableRowCmd,
} from '../model/cmds';
import { FeedTableModel } from '../model/domain';
import { ListFeedTablesQuery, ListFeedTablesQueryModel } from '../model/querys';

export class FeedTableApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createFeedTable = this.createFeedTable.bind(this);
    this.fetchFeedTable = this.fetchFeedTable.bind(this);
    this.listFeedTables = this.listFeedTables.bind(this);
    this.updateFeedTable = this.updateFeedTable.bind(this);
  }

  public createFeedTable(cmd: CreateFeedTableCmd): Promise<FeedTableModel> {
    return this.post<
      FeedTableModel,
      CreateFeedTableCmd,
      AxiosResponse<FeedTableModel>
    >(`/v1/feedTables`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchFeedTable(feedTableId: string): Promise<FeedTableModel> {
    return this.get<FeedTableModel, AxiosResponse<FeedTableModel>>(
      `/v1/feedTables/${feedTableId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listFeedTables(
    cmd?: ListFeedTablesQuery,
  ): Promise<ListFeedTablesQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListFeedTablesQueryModel,
      AxiosResponse<ListFeedTablesQueryModel>
    >('/v1/feedTables', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateFeedTable(
    feedTableId: string,
    cmd: UpdateFeedTableCmd,
  ): Promise<FeedTableModel> {
    return this.patch<
      FeedTableModel,
      UpdateFeedTableCmd,
      AxiosResponse<FeedTableModel>
    >(`/v1/feedTables/${feedTableId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteFeedTable(feedTableId: string): Promise<void> {
    return this.delete<FeedTableModel, AxiosResponse<void>>(
      `/v1/feedTables/${feedTableId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateFeedTableRow(
    feedTableId: string,
    doc: number,
    cmd: UpdateFeedTableRowCmd,
  ): Promise<FeedTableModel> {
    return this.patch<
      FeedTableModel,
      UpdateFeedTableRowCmd,
      AxiosResponse<FeedTableModel>
    >(`/v1/feedTables/${feedTableId}/docs/${doc.toFixed(0)}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const feedTableApi = new FeedTableApi();

import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Popconfirm,
  Button,
  notification,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { UpdateSensorCapCmd } from '../../model/cmds';
import {
  deleteSensorCap,
  getSensorCap,
  updateSensorCap,
} from '../../duck/modules/sensorCaps';
import { SensorCapDataForm } from '../sensorCaps/sensorCapDataForm';
import { usePrevious } from '../_util/hook';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ sensorCapId: string }>;

const UnconnectedSensorCapDetail: React.FC<Props> = ({
  getSensorCapConnect,
  updateSensorCapConnect,
  sensorCap,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  deleteSensorCapConnect,
  deleteState,
}) => {
  const { sensorCapId } = match.params;

  React.useEffect(() => {
    getSensorCapConnect(sensorCapId);
  }, []);

  const prev = usePrevious({
    deleteSuccess: deleteState.success,
    deleteError: deleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteState.success) {
      notification.success({
        message: 'Sensor cap deleted',
      });
    }
  }, [deleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteState.error) {
      notification.error({
        message: 'Sensor cap deletion error',
        description: deleteState.error?.message,
      });
    }
  }, [deleteState.error]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load sensor cap"
        subTitle={error.message}
      />
    );
  }

  const renderGeneralDataCardContent = () => {
    if (sensorCap) {
      return (
        <SensorCapDataForm
          sensorCap={sensorCap}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onUpdate={(cmd: UpdateSensorCapCmd) => {
            updateSensorCapConnect(sensorCapId, cmd);
          }}
        />
      );
    }
  };

  const rendeDeleteButton = () => {
    if (sensorCap) {
      if (sensorCap.isDeleted === true) {
        return (
          <Button
            type="ghost"
            danger
            onClick={() =>
              updateSensorCapConnect(sensorCapId, { isDeleted: false })
            }
          >
            Restore
          </Button>
        );
      }
      return (
        <Popconfirm
          title="Do you really want to delete this sensor cap?"
          onConfirm={() => deleteSensorCapConnect(sensorCapId)}
        >
          <Button type="ghost" danger loading={deleteState.loading}>
            Delete
          </Button>
        </Popconfirm>
      );
    }
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{rendeDeleteButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="General Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  sensorCap: state.sensorCaps.detail.sensorCap,
  loading: state.sensorCaps.detail.loading,
  error: state.sensorCaps.detail.error,
  updating: state.sensorCaps.detail.updating,
  updateError: state.sensorCaps.detail.updateError,
  updateSuccess: state.sensorCaps.detail.updateSuccess,
  deleteState: state.sensorCaps.delete,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSensorCapConnect: getSensorCap,
      updateSensorCapConnect: updateSensorCap,
      deleteSensorCapConnect: deleteSensorCap,
    },
    dispatch,
  );
};

export const SensorCapDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorCapDetail);

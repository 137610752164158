import 'chart.js';

import {
  Col,
  Empty,
  Row,
  Statistic,
  Table,
  Typography,
  Pagination,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import {
  SensorAggregationDataModel,
  SensorSpecificDataModel,
} from '../../model/domain';
import moment from 'moment';
import { AggregationOperation, SensorType } from '../../model/enums';
import { chartjsTimeAxisOptions } from '../../util';

// because there is no typescript support for this framework
const ReactChartkick = require('react-chartkick');
const { LineChart } = ReactChartkick;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    aggregations: SensorAggregationDataModel[];
    loading?: boolean;
    pagination?: any;
    sensorType: SensorType;
  };

const UnconnectedSensorAggregationsGraph: React.FC<Props> = ({
  aggregations,
  loading,
  pagination,
  sensorType,
}) => {
  if (!loading && aggregations.length === 0) {
    return <Empty description="No data" />;
  }

  const hasMin = !!aggregations.find((a) => a.min);
  const hasMean = !!aggregations.find((a) => a.mean);
  const hasMedian = !!aggregations.find((a) => a.median);
  const hasMax = !!aggregations.find((a) => a.max);

  const getValues = (operation: AggregationOperation, fieldName: string) => {
    return aggregations.reduce((a, x) => {
      const timestamp = x.timestamp;
      let value;
      if (operation === AggregationOperation.MIN) {
        const values = x.min;
        value = values && values[fieldName as keyof SensorSpecificDataModel];
      } else if (operation === AggregationOperation.MEDIAN) {
        const values = x.median;
        value = values && values[fieldName as keyof SensorSpecificDataModel];
      } else if (operation === AggregationOperation.MEAN) {
        const values = x.mean;
        value = values && values[fieldName as keyof SensorSpecificDataModel];
      } else if (operation === AggregationOperation.MAX) {
        const values = x.max;
        value = values && values[fieldName as keyof SensorSpecificDataModel];
      }
      return { ...a, [timestamp]: value };
    }, {});
  };

  const getChartData = (fieldName: string) => {
    const data = [];
    if (hasMin) {
      data.push({
        name: 'Min',
        data: getValues(AggregationOperation.MIN, fieldName),
      });
    }
    if (hasMedian) {
      data.push({
        name: 'Median',
        data: getValues(AggregationOperation.MEDIAN, fieldName),
      });
    }
    if (hasMean) {
      data.push({
        name: 'Mean',
        data: getValues(AggregationOperation.MEAN, fieldName),
      });
    }
    if (hasMax) {
      data.push({
        name: 'Max',
        data: getValues(AggregationOperation.MAX, fieldName),
      });
    }
    return data;
  };

  const getChartColors = (fieldName: string) => {
    const data = [];
    if (hasMin) {
      data.push('green');
    }
    if (hasMedian) {
      data.push('blue');
    }
    if (hasMean) {
      data.push('orange');
    }
    if (hasMax) {
      data.push('red');
    }
    return data;
  };

  const renderTemperatureChart = () => {
    const fieldName = 'tempInC';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' °C'}
        messages={{ empty: 'No temperature data' }}
        zeros={true}
        ytitle="Temperature"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderLuxTemperatureChart = () => {
    const fieldName = 'luxTempInC';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' °C'}
        messages={{ empty: 'No temperature data' }}
        zeros={true}
        ytitle="Temperature"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderLuxChart = () => {
    const fieldName = 'lux';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        messages={{ empty: 'No lux data' }}
        zeros={true}
        ytitle="LUX"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderHumidityChart = () => {
    const fieldName = 'humidityInPerc';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        messages={{ empty: 'No humiditiy data' }}
        zeros={true}
        ytitle="Humidity"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderDoPPMChart = () => {
    const fieldName = 'doPPM';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' ppm'}
        messages={{ empty: 'No DO data' }}
        zeros={true}
        ytitle="DO (ppm)"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderDoPercChart = () => {
    const fieldName = 'doPerc';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' %'}
        messages={{ empty: 'No DO data' }}
        zeros={true}
        ytitle="DO (%)"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderDoRawPercChart = () => {
    const fieldName = 'doRawPerc';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={'%'}
        messages={{ empty: 'No DO data' }}
        zeros={true}
        ytitle="DO (raw %)"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderPhRawChart = () => {
    const fieldName = 'pHRawInMv';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' mV'}
        messages={{ empty: 'No PH data' }}
        zeros={true}
        ytitle="PH (raw)"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderEcInAChart = () => {
    const fieldName = 'ecInA';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' A'}
        messages={{ empty: 'No EC data' }}
        zeros={true}
        ytitle="EC"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderEpInKwChart = () => {
    const fieldName = 'epInKW';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' kW'}
        messages={{ empty: 'No EP data' }}
        zeros={true}
        ytitle="EP"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderPhCalcChart = () => {
    const fieldName = 'pH';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        messages={{ empty: 'No PH data' }}
        zeros={true}
        ytitle="PH"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderRawConductivityChart = () => {
    const fieldName = 'rawCondInMs';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' µS/cm'}
        messages={{ empty: 'No conductivity data' }}
        zeros={true}
        ytitle="Conductivity (raw)"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderConductivityChart = () => {
    const fieldName = 'condInMs';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' µS/cm'}
        messages={{ empty: 'No conductivity data' }}
        zeros={true}
        ytitle="Conductivity"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderSalinityChart = () => {
    const fieldName = 'salPPT';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' ppt'}
        messages={{ empty: 'No salinity data' }}
        zeros={true}
        ytitle="Salinity"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderDistanceChart = () => {
    const fieldName = 'distanceInMm';
    return (
      <LineChart
        data={getChartData(fieldName)}
        min={null}
        suffix={' mm'}
        messages={{ empty: 'No distance data' }}
        zeros={true}
        ytitle="Distance"
        library={chartjsTimeAxisOptions}
        colors={getChartColors(fieldName)}
      />
    );
  };

  const renderPagination = () => {
    return pagination;
  };

  switch (sensorType) {
    case SensorType.IDS:
    case SensorType.YDS:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderTemperatureChart()}</Col>
          <Col span={24}>{renderDoPPMChart()}</Col>
          <Col span={24}>{renderDoPercChart()}</Col>
          <Col span={24}>{renderDoRawPercChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );
    case SensorType.SAP:
    case SensorType.NDP:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderPhRawChart()}</Col>
          <Col span={24}>{renderPhCalcChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );
    case SensorType.SES:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderEcInAChart()}</Col>
          <Col span={24}>{renderEpInKwChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );
    case SensorType.OCS:
    case SensorType.OMS:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderTemperatureChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );
    case SensorType.YSS:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderTemperatureChart()}</Col>
          <Col span={24}>{renderRawConductivityChart()}</Col>
          <Col span={24}>{renderConductivityChart()}</Col>
          <Col span={24}>{renderSalinityChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );
    case SensorType.STOF:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderDistanceChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );
    case SensorType.LUX:
      return (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
          <Col span={24}>{renderLuxChart()}</Col>
          <Col span={24}>{renderHumidityChart()}</Col>
          <Col span={24}>{renderLuxTemperatureChart()}</Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              {renderPagination()}
            </Row>
          </Col>
        </Row>
      );

    default:
      console.log(sensorType);
      console.log('---------------------');
      return (
        <Typography.Text strong mark>
          {'Not implement for this sensor type!!!'}
        </Typography.Text>
      );
  }
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const SensorAggregationsGraph = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorAggregationsGraph);

import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { createAccount } from '../../duck/modules/accounts';
import { CreateAccountCmd } from '../../model/cmds';
import { AccountModel } from '../../model/domain';
import { Gender, Language, TimeZone } from '../../model/enums';
import { sanitizeFormValue } from '../_util/form';
import { generatePassword } from '../_util/password';
import {
  emailRule,
  nameRule,
  noWhitespacesRule,
  passwordRule,
  phoneNumberRule,
  usernameRule,
} from '../_util/validation';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (account: AccountModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateAccountForm: React.FC<Props> = ({
  createAccountConnected,
  loading,
  error,
  accountCreated,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();
  const [password, setPassword] = React.useState('');

  const tryCreateAccount = (values: Store) => {
    const {
      email,
      username,
      password,
      name,
      phoneNumber,
      language,
      gender,
      line,
      crmContactId,
      timeZone,
    } = values;

    const cmd: CreateAccountCmd = {
      email,
      username,
      password,
      name: sanitizeFormValue(name),
      phoneNumber: sanitizeFormValue(phoneNumber),
      language: language === 'THAI' ? Language.THAI : Language.ENGLISH,
      gender: gender === 'MALE' ? Gender.MALE : Gender.FEMALE,
      line: sanitizeFormValue(line),
      crmContactId: sanitizeFormValue(crmContactId),
      timeZone: timeZone
    };
    createAccountConnected(cmd);
  };

  const onReset = () => {
    onPasswordChanged('');
    form.resetFields();
  };

  React.useEffect(() => {
    if (accountCreated) {
      notification.success({
        message: 'Account created',
      });
      onReset();
    }
    if (accountCreated && onCreated) {
      onCreated(accountCreated);
    }
  }, [accountCreated]);

  React.useEffect(() => {
    if (error) {
      notification.error({
        message: 'Account creation error',
        description: error?.message,
      });
    }
    if (error && onError) {
      onError(error);
    }
  }, [error]);

  const onPasswordChanged = (password: string) => {
    setPassword(password);
    form.setFieldsValue({ password });
  };

  return (
    <Form form={form} layout="vertical" onFinish={tryCreateAccount}>
      <Form.Item label="Name" name="name" rules={[nameRule]}>
        <Input placeholder="John Doe" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[emailRule, { required: true }]}
      >
        <Input placeholder="john.doe@gmx.de" />
      </Form.Item>

      <Form.Item
        label="Username"
        name="username"
        rules={[usernameRule, noWhitespacesRule, { required: true }]}
      >
        <Input placeholder="e.g.: johndoe" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[passwordRule, { required: true }]}
      >
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={16}>
            <Input
              placeholder="Enter or generate password"
              onChange={(e) => onPasswordChanged(e.target.value)}
              value={password ? password : undefined}
            />
          </Col>
          <Col xs={24} sm={8}>
            <Button
              style={{ width: '100%' }}
              onClick={() => onPasswordChanged(generatePassword(8))}
            >
              Generate
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item label="Phone no." name="phoneNumber">
        <Input placeholder="+491234567890" />
      </Form.Item>

      <Form.Item name="language" label="Language">
        <Select placeholder="Select prefered language">
          <Option value="ENGLISH">English</Option>
          <Option value="THAI">Thai</Option>
        </Select>
      </Form.Item>

      <Form.Item name="timeZone" label="Time zone" rules={[{ required: true }]}>
        <Select placeholder="Select time zone" allowClear >
          <Option value={TimeZone.THAILAND}>{'Asia/Bangkok'}</Option>
          <Option value={TimeZone.GERMANY}>{'Europe/Berlin'}</Option>
          <Option value={TimeZone.UTC}>{'UTC'}</Option>
          <Option value={TimeZone.INDONESIA_WESTERN}>
            {'Asia/Jakarta'}
          </Option>
          <Option value={TimeZone.INDONESIA_CENTRAL}>
            {'Asia/Makassar'}
          </Option>
          <Option value={TimeZone.INDONESIA_EASTERN}>
            {'Asia/Jayapura'}
          </Option>
          <Option value={TimeZone.JAPAN}>{'Asia/Tokyo)'}</Option>
          <Option value={TimeZone.MALAYSIA}>{'Asia/Kuala_Lumpur'}</Option>
          <Option value={TimeZone.SINGAPORE}>{'Asia/Singapore'}</Option>
          <Option value={TimeZone.VIETNAM}>{'Asia/Ho_Chi_Minh'}</Option>
        </Select>
      </Form.Item>

      <Form.Item name="gender" label="Gender">
        <Select placeholder="Select gender">
          <Option value="MALE">Male</Option>
          <Option value="FEMALE">Female</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Line" name="line">
        <Input placeholder={'Add Line account'} />
      </Form.Item>

      <Form.Item label="CRM ContactId" name="crmContactId">
        <Input placeholder={'Add ContactId from the CRM-System'} />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Account
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.accounts.create.loading,
  error: state.accounts.create.error,
  accountCreated: state.accounts.create.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    { createAccountConnected: createAccount },
    dispatch,
  );
};

export const CreateAccountForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateAccountForm);

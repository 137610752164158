import * as React from 'react';
import { connect } from 'react-redux';

import { logout, fetchMe } from '../../duck/modules/auth';
import { Button, Card, Descriptions, Typography, Layout, Row, Col } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';

const { Paragraph } = Typography;
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UnconnectedProfile = ({
  logOutConnect,
  fetchMeConnect,
  me,
  token,
  auth,
}: Props) => {
  React.useEffect(() => {
    fetchMeConnect();
  }, []);

  const renderCard = () => {
    return (
      <Card title="Your Data">
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Username">
            <Typography>{auth.me?.username}</Typography>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <Typography>{auth.me?.email}</Typography>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]} justify="start">
          <Col span={24}>
            <Button type="primary" onClick={logOutConnect}>
              {'Logout'}
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>{renderCard()}</Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: state.auth.token,
  me: state.auth.me,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      logOutConnect: logout,
      fetchMeConnect: fetchMe,
    },
    dispatch,
  );
};

export const Profile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedProfile);

import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { LcmModel } from '../../model/domain';
import { Bandwidth, CodingFactor, SpreadingFactor } from '../../model/enums';
import { CreateLcmCmd } from '../../model/cmds';
import { createLcm } from '../../duck/modules/lcms';
import { generatePassword } from '../_util/password';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (lcm: LcmModel) => void;
    onError?: (error: Error) => void;
    organisationId?: string;
    gatewayId?: string;
  };

const UnconnectedCreateLcmForm: React.FC<Props> = ({
  createLcmConnect,
  lcmCreateState,
  onCreated,
  onError,
  organisationId,
  gatewayId,
}) => {
  React.useEffect(() => {
    if (lcmCreateState.lcm) {
      notification.success({
        message: 'LCM created',
      });
      onReset();
      if (onCreated != null) {
        onCreated(lcmCreateState.lcm);
      }
    }
  }, [lcmCreateState.lcm]);

  React.useEffect(() => {
    if (lcmCreateState.error) {
      notification.error({
        message: 'LCM creation error',
        description: lcmCreateState.error?.message,
      });
      if (onError != null) {
        onError(lcmCreateState.error);
      }
    }
  }, [lcmCreateState.error]);

  const [form] = Form.useForm();
  const [defaultKey, setDefaultKey] = React.useState('');

  const onDefaultKeyChanged = (defaultKey: string) => {
    setDefaultKey(defaultKey);
    form.setFieldsValue({ defaultKey });
  };

  const onReset = () => {
    onDefaultKeyChanged('');
    form.resetFields();
  };

  const onSubmit = (values: Store) => {
    const {
      defaultServerAddress,
      defaultFrequency,
      defaultPower,
      defaultKey,
      defaultCodingFactor,
      defaultBandwidth,
      defaultSpreadingFactor,
      name,
    } = values;

    const cmd: CreateLcmCmd = {
      defaultServerAddress,
      defaultFrequency,
      defaultPower,
      defaultKey,
      defaultCodingFactor,
      defaultBandwidth,
      defaultSpreadingFactor,
      name,
    };

    createLcmConnect(cmd, organisationId, gatewayId);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      initialValues={{
        defaultServerAddress: 1,
        defaultPower: 14,
        defaultCodingFactor: CodingFactor.RATE_4_8,
        defaultBandwidth: Bandwidth.BW_250KHZ,
        defaultSpreadingFactor: SpreadingFactor.FACTOR_8,
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: false }, { type: 'string' }]}
      >
        <Input placeholder="Enter name of LCM" type="text" />
      </Form.Item>

      {/* <Form.Item
        label="Server Address"
        name="defaultServerAddress"
        rules={[{ required: true }, { type: 'integer' }]}
      >
        <InputNumber
          placeholder="Enter default server adress"
          style={{ width: '100%' }}
        />
      </Form.Item> */}

      <Form.Item
        label="Frequency"
        name="defaultFrequency"
        rules={[{ required: true }, { type: 'number' }]}
      >
        <InputNumber
          placeholder="Enter default frequency"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        label="Key"
        name="defaultKey"
        rules={[
          {
            required: true,
            pattern: new RegExp(/^[A-Za-z0-9]{16}$/),
            message:
              'Exactly 16 characters long and must not contain any special characters!',
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={16}>
            <Input
              placeholder="Enter default key"
              type="text"
              onChange={(e) => onDefaultKeyChanged(e.target.value)}
              value={defaultKey ? defaultKey : undefined}
            />
          </Col>
          <Col span={8}>
            <Button
              style={{ width: '100%' }}
              onClick={() => onDefaultKeyChanged(generatePassword(16))}
            >
              {'Generate'}
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label="Power"
        name="defaultPower"
        rules={[{ required: true }, { type: 'integer' }]}
      >
        <InputNumber
          placeholder="Enter default power"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        label="Coding Factor"
        name="defaultCodingFactor"
        rules={[{ required: true }]}
      >
        <Select placeholder="Select default coding factor" allowClear>
          <Option value={CodingFactor.RATE_4_5}>{'RATE_4_5'}</Option>
          <Option value={CodingFactor.RATE_4_6}>{'RATE_4_6'}</Option>
          <Option value={CodingFactor.RATE_4_7}>{'RATE_4_7'}</Option>
          <Option value={CodingFactor.RATE_4_8}>{'RATE_4_8'}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Bandwidth"
        name="defaultBandwidth"
        rules={[{ required: true }]}
      >
        <Select placeholder="Select default bandwidth" allowClear>
          <Option value={Bandwidth.BW_125KHZ}>{'125 kHz'}</Option>
          <Option value={Bandwidth.BW_250KHZ}>{'250 kHz'}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Spreading Factor"
        name="defaultSpreadingFactor"
        rules={[{ required: true }]}
      >
        <Select placeholder="Select default spreading factor" allowClear>
          <Option value={SpreadingFactor.FACTOR_7}>{'FACTOR_7'}</Option>
          <Option value={SpreadingFactor.FACTOR_8}>{'FACTOR_8'}</Option>
          <Option value={SpreadingFactor.FACTOR_9}>{'FACTOR_9'}</Option>
          <Option value={SpreadingFactor.FACTOR_10}>{'FACTOR_10'}</Option>
          <Option value={SpreadingFactor.FACTOR_11}>{'FACTOR_11'}</Option>
          <Option value={SpreadingFactor.FACTOR_12}>{'FACTOR_12'}</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={lcmCreateState.loading}
          >
            Create LCM
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  lcmCreateState: state.lcms.create,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createLcmConnect: createLcm,
    },
    dispatch,
  );
};

export const CreateLcmForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateLcmForm);

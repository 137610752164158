import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { feedTableApi } from '../../api/feedTableApi';
import {
  CREATE_FEED_TABLE,
  CREATE_FEED_TABLE_FAILED,
  CREATE_FEED_TABLE_SUCCESS,
  FETCH_FEED_TABLE,
  FETCH_FEED_TABLE_FAILED,
  FETCH_FEED_TABLE_SUCCESS,
  FETCH_FEED_TABLES,
  FETCH_FEED_TABLES_FAILED,
  FETCH_FEED_TABLES_SUCCESS,
  UPDATE_FEED_TABLE,
  UPDATE_FEED_TABLE_FAILED,
  UPDATE_FEED_TABLE_SUCCESS,
  UPDATE_FEED_TABLE_ROWS,
  UPDATE_FEED_TABLE_ROWS_SUCCESS,
  UPDATE_FEED_TABLE_ROWS_FAILED,
} from '../../constants';
import {
  CreateFeedTableCmd,
  UpdateFeedTableCmd,
  UpdateFeedTableRowCmd,
} from '../../model/cmds';
import {
  ListFeedTablesQuery,
  ListFeedTablesQueryModel,
} from '../../model/querys';
import { FeedTablesState } from '../../model/state';
import {
  feedTableDelete,
  feedTableDeleted,
  feedTableDeleteFailed,
  feedTableRowsUpdate,
  feedTableRowsUpdateFailed,
} from '../actions';
import {
  DELETE_FEED_TABLE,
  DELETE_FEED_TABLE_SUCCESS,
  DELETE_FEED_TABLE_FAILED,
} from '../../constants';
import {
  feedTableCreate,
  feedTableCreated,
  feedTableCreateFailed,
  feedTableFetch,
  feedTableFetched,
  feedTableFetchFailed,
  FeedTablesAction,
  feedTablesFetch,
  feedTablesFetched,
  feedTablesFetchFailed,
  feedTableUpdate,
  feedTableUpdated,
  feedTableUpdateFailed,
} from '../actions';
import { FeedTableModel } from '../../model/domain';

export function createFeedTable(cmd: CreateFeedTableCmd) {
  return async (dispatch: Dispatch<FeedTablesAction, {}, any>) => {
    try {
      dispatch(feedTableCreate());
      const feedTable = await feedTableApi.createFeedTable(cmd);
      feedTable.feedTableRows = new Map(
        Object.entries(feedTable.feedTableRows),
      );
      dispatch(feedTableCreated(feedTable));
    } catch (e) {
      dispatch(feedTableCreateFailed(e));
    }
  };
}

export function getFeedTable(id: string) {
  return async (dispatch: Dispatch<FeedTablesAction, {}, any>) => {
    try {
      dispatch(feedTableFetch());
      let feedTable = await feedTableApi.fetchFeedTable(id);
      feedTable.feedTableRows = new Map(
        Object.entries(feedTable.feedTableRows),
      );
      dispatch(feedTableFetched(feedTable));
    } catch (e) {
      dispatch(feedTableFetchFailed(e));
    }
  };
}

export function getFeedTables(query: ListFeedTablesQuery) {
  return async (dispatch: Dispatch<FeedTablesAction, {}, any>) => {
    try {
      dispatch(feedTablesFetch(query));
      const queryModel: ListFeedTablesQueryModel = await feedTableApi.listFeedTables(
        query,
      );
      const feedTables = queryModel.feedTables;

      dispatch(feedTablesFetched(queryModel, feedTables));
    } catch (e) {
      dispatch(feedTablesFetchFailed(e));
    }
  };
}

export function deleteFeedTable(id: string) {
  return async (dispatch: Dispatch<FeedTablesAction, {}, any>) => {
    try {
      console.log('DELETING FEEDTABLE');
      dispatch(feedTableDelete(id));
      await feedTableApi.deleteFeedTable(id);
      dispatch(feedTableDeleted(id));
      console.log('FEEDTABLE DELETED');
    } catch (e) {
      dispatch(feedTableDeleteFailed(e));
      console.log('FEEDTABLE DELETING ERROR');
    }
  };
}

export function updateFeedTable(id: string, cmd: UpdateFeedTableCmd) {
  return async (dispatch: Dispatch<FeedTablesAction, {}, any>) => {
    try {
      dispatch(feedTableUpdate());
      const response = await feedTableApi.updateFeedTable(id, cmd);
      dispatch(feedTableUpdated(response));
      dispatch(getFeedTable(id));
    } catch (e) {
      dispatch(feedTableUpdateFailed(e));
    }
  };
}

export function updateFeedTableRows(
  id: string,
  cmds: Map<number, UpdateFeedTableRowCmd>,
) {
  return async (dispatch: Dispatch<FeedTablesAction, {}, any>) => {
    try {
      dispatch(feedTableRowsUpdate());

      const responses: Promise<FeedTableModel>[] = [];

      cmds.forEach((cmd, doc) => {
        const response = feedTableApi.updateFeedTableRow(id, doc, cmd);
        responses.push(response);
      });

      await Promise.all(responses);

      let feedTable = await feedTableApi.fetchFeedTable(id);
      feedTable.feedTableRows = new Map(
        Object.entries(feedTable.feedTableRows),
      );

      dispatch(feedTableUpdated(feedTable));
    } catch (e) {
      dispatch(feedTableRowsUpdateFailed(e));
    }
  };
}

export default function feedTablesReducer(
  state: FeedTablesState = {
    create: {
      loading: false,
      error: null,
      feedTable: null,
    },
    list: {
      loading: false,
      error: null,
      feedTables: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      feedTable: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      error: null,
      success: null,
    },
  },
  action: FeedTablesAction,
): FeedTablesState {
  switch (action.type) {
    case CREATE_FEED_TABLE:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          feedTable: null,
        },
      };
    case CREATE_FEED_TABLE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          feedTable: action.payload,
        },
      };
    case CREATE_FEED_TABLE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          feedTable: null,
        },
      };
    case FETCH_FEED_TABLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          feedTable: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_FEED_TABLE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          feedTable: action.payload,
        },
      };
    case FETCH_FEED_TABLE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          feedTable: null,
        },
      };
    case UPDATE_FEED_TABLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_FEED_TABLE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_FEED_TABLE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case DELETE_FEED_TABLE:
      return {
        ...state,
        delete: { ...state.delete, loading: true, error: null, success: null },
      };
    case DELETE_FEED_TABLE_SUCCESS:
      return {
        ...state,
        delete: { ...state.delete, loading: false, error: null, success: true },
      };
    case DELETE_FEED_TABLE_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: null,
        },
      };
    case FETCH_FEED_TABLES:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          feedTables: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_FEED_TABLES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          feedTables: action.payload.feedTables,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_FEED_TABLES_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          feedTables: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    case UPDATE_FEED_TABLE_ROWS:
      return {
        ...state,
        detail: { ...state.detail, updateSuccess: null, updateError: null },
      };
    case UPDATE_FEED_TABLE_ROWS_SUCCESS:
      return {
        ...state,
        detail: { ...state.detail, updateSuccess: true, updateError: null },
      };
    case UPDATE_FEED_TABLE_ROWS_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    default:
      return state;
  }
}

import React from 'react';
import { Form, Input, Button, Space, notification, Tooltip } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { SmartSensorModel } from '../../model/domain';
import { UpdateSmartSensorCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';
import { functionalColor } from '../../colors';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';

type Props = {
  smartSensor: SmartSensorModel;
  onUpdate: (cmd: UpdateSmartSensorCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const SmartSensorDataForm: React.FC<Props> = ({
  smartSensor,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...smartSensor,
      name: smartSensor.name?.requested || smartSensor.name?.actual || '',
    });
  }, [smartSensor]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Smart Sensor updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Smart Sensor',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { name } = values;
    const cmd: UpdateSmartSensorCmd = {};
    if (name !== smartSensor.name?.actual) {
      cmd.name = name;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && smartSensor) {
      cmd.name =
        smartSensor.name &&
        (smartSensor.name.requested || smartSensor.name.actual);
    }

    if (Object.keys(cmd).length > 0) {
      onUpdate(cmd);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input
          placeholder="Name of the Smart Sensor"
          suffix={
            smartSensor.name != undefined &&
            (smartSensor.name.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={'Syncing...'}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            ))
          }
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Smart Sensor
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import {
  Divider,
  List,
  Result,
  Space,
  Typography,
  Button,
  Spin,
  ConfigProvider,
  Empty,
  Tooltip,
  Card,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { PlusOutlined } from '@ant-design/icons';

import { RootState } from '../../duck';
import { getFarms } from '../../duck/modules/farms';
import { FarmModel, SimpleFarmModel } from '../../model/domain';
import { OrganisationFarmsListItem } from './organisationFarmsListItem';
import Modal from 'antd/lib/modal/Modal';
import { CreateFarmForm } from '../farm/createFarmForm';
import { ListLocationsQuery } from '../../model/querys';
import { INT_MAX } from '../../constants';
import { getLocations } from '../../duck/modules/locations';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId: string;
  };

const UnconnectedOrganisationFarmsList: React.FC<Props> = ({
  farms,
  loading,
  error,
  organisationId,
  getFarmsConnect,
  getLocationsConnnect,
  locationsState,
}) => {
  const [limit, setLimit] = React.useState(25);
  const [offset, setOffset] = React.useState(0);

  const [showAddFarmModal, setShowAddFarmModal] = React.useState(false);

  React.useEffect(() => {
    getFarmsConnect({
      limit,
      offset,
      includeDisabled: true,
      organisationIds: [organisationId],
    });
  }, [offset, limit]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load farms"
        subTitle={error.message}
      />
    );
  }

  const renderFarm = (farm: FarmModel) => {
    return (
      <OrganisationFarmsListItem farm={farm} organisationId={organisationId} />
    );
  };

  const renderListHeader = () => {
    return (
      <Card>
        <Divider>
          <Space>
            <Typography>Farms</Typography>
            <Tooltip title="Add farm to organisation">
              <Button
                type="ghost"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => setShowAddFarmModal(true)}
              />
            </Tooltip>
          </Space>
        </Divider>
      </Card>
    );
  };

  return (
    <>
      <List
        header={renderListHeader()}
        locale={{
          emptyText: (
            <Empty description="There are no farms accociated with this organisation">
              <Button onClick={() => setShowAddFarmModal(true)} type="primary">
                Create one?
              </Button>
            </Empty>
          ),
        }}
        loading={loading}
        dataSource={farms}
        renderItem={renderFarm}
        grid={{ gutter: 40, column: 1 }}
      />
      <Modal
        visible={showAddFarmModal}
        onOk={() => setShowAddFarmModal(false)}
        onCancel={() => setShowAddFarmModal(false)}
        footer={null}
        title={'Add farm to organisation'}
      >
        <CreateFarmForm
          organisationId={organisationId}
          onCreated={(_farm: FarmModel) => {
            getFarmsConnect({
              limit,
              offset,
              includeDisabled: true,
              organisationIds: [organisationId],
            });
            setShowAddFarmModal(false);
          }}
          onError={(_error: Error) => {}}
          locations={locationsState.list.locations}
          locationsLoading={locationsState.list.loading}
          onZipCodeChange={(zipCode: string) => {
            const query: ListLocationsQuery = {
              limit: INT_MAX,
              offset: 0,
              zipCodes: [zipCode],
            };
            getLocationsConnnect(query);
          }}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  farms: state.farms.list.farms,
  loading: state.farms.list.loading,
  error: state.farms.list.error,
  total: state.farms.list.total,
  locationsState: state.locations,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFarmsConnect: getFarms,
      getLocationsConnnect: getLocations,
    },
    dispatch,
  );
};

export const OrganisationFarmsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationFarmsList);

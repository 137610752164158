import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { organisationApi } from '../../api/organisationApi';

import { smartRelayApi } from '../../api/smartRelayApi';
import {
  CREATE_SMART_RELAY,
  CREATE_SMART_RELAY_FAILED,
  CREATE_SMART_RELAY_SUCCESS,
  FETCH_SMART_RELAY,
  FETCH_SMART_RELAY_FAILED,
  FETCH_SMART_RELAY_SUCCESS,
  FETCH_SMART_RELAYS,
  FETCH_SMART_RELAYS_FAILED,
  FETCH_SMART_RELAYS_SUCCESS,
  UPDATE_SMART_RELAY,
  UPDATE_SMART_RELAY_FAILED,
  UPDATE_SMART_RELAY_SUCCESS,
  REMOVE_SMART_RELAY_FROM_ORGANISATION,
  REMOVE_SMART_RELAY_FROM_ORGANISATION_SUCCESS,
  REMOVE_SMART_RELAY_FROM_ORGANISATION_FAILED,
  INT_MAX,
  FETCH_ORGANISATION_SMART_RELAYS,
  FETCH_ORGANISATION_SMART_RELAYS_SUCCESS,
  FETCH_ORGANISATION_SMART_RELAYS_FAILED,
} from '../../constants';
import { CreateSmartRelayCmd, UpdateSmartRelayCmd } from '../../model/cmds';
import {
  ListSmartRelaysQuery,
  ListSmartRelaysQueryModel,
} from '../../model/querys';
import { SmartRelaysState } from '../../model/state';
import {
  organisationSmartRelayRemove,
  organisationSmartRelayRemoved,
  organisationSmartRelaysFetch,
  organisationSmartRelaysFetched,
  organisationSmartRelaysFetchFailed,
  smartRelayCreate,
  smartRelayCreated,
  smartRelayCreateFailed,
  smartRelayFetch,
  smartRelayFetched,
  smartRelayFetchFailed,
  SmartRelaysAction,
  smartRelaysFetch,
  smartRelaysFetched,
  smartRelaysFetchFailed,
  smartRelayUpdate,
  smartRelayUpdated,
  smartRelayUpdateFailed,
} from '../actions/smartRelay';

export function createSmartRelay(
  cmd: CreateSmartRelayCmd,
  organisationId?: string,
) {
  return async (dispatch: Dispatch<SmartRelaysAction, {}, any>) => {
    try {
      dispatch(smartRelayCreate());
      const smartRelay = await smartRelayApi.createSmartRelay(cmd);

      if (organisationId) {
        // add relay to organisation
        const organisation = await organisationApi.addSmartRelayToOrganisation(
          organisationId,
          { smartRelayId: smartRelay.id },
        );

        dispatch(smartRelayCreated(smartRelay));
      } else {
        dispatch(smartRelayCreated(smartRelay));
      }
    } catch (e) {
      dispatch(smartRelayCreateFailed(e));
    }
  };
}

export function getSmartRelay(id: string) {
  return async (dispatch: Dispatch<SmartRelaysAction, {}, any>) => {
    try {
      dispatch(smartRelayFetch());
      const smartRelay = await smartRelayApi.fetchSmartRelay(id);
      dispatch(smartRelayFetched(smartRelay));
    } catch (e) {
      dispatch(smartRelayFetchFailed(e));
    }
  };
}

export function getSmartRelays(query: ListSmartRelaysQuery) {
  return async (dispatch: Dispatch<SmartRelaysAction, {}, any>) => {
    try {
      dispatch(smartRelaysFetch(query));
      const queryModel: ListSmartRelaysQueryModel = await smartRelayApi.listSmartRelays(
        query,
      );
      const smartRelays = queryModel.smartRelays;

      dispatch(smartRelaysFetched(queryModel, smartRelays));
    } catch (e) {
      dispatch(smartRelaysFetchFailed(e));
    }
  };
}

export function getOrganisationSmartRelays(organisationId: string) {
  return async (dispatch: Dispatch<SmartRelaysAction, {}, any>) => {
    try {
      const query = {
        limit: INT_MAX,
        offset: 0,
        organisationIds: [organisationId],
      };

      dispatch(organisationSmartRelaysFetch(query));
      const queryModel = await smartRelayApi.listSmartRelays(query);
      const smartRelays = queryModel.smartRelays;

      dispatch(organisationSmartRelaysFetched(queryModel, smartRelays));
    } catch (e) {
      dispatch(organisationSmartRelaysFetchFailed(e));
    }
  };
}

export function updateSmartRelay(id: string, cmd: UpdateSmartRelayCmd) {
  return async (dispatch: Dispatch<SmartRelaysAction, {}, any>) => {
    try {
      dispatch(smartRelayUpdate());
      const response = await smartRelayApi.updateSmartRelay(id, cmd);
      dispatch(smartRelayUpdated(response));
      dispatch(getSmartRelay(id));
    } catch (e) {
      dispatch(smartRelayUpdateFailed(e));
    }
  };
}

export function removeSmartRelayFromOrganisation(
  organisationId: string,
  smartRelayId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<SmartRelaysAction, {}, any>) => {
    try {
      dispatch(organisationSmartRelayRemove(organisationId, smartRelayId));
      const relay = await organisationApi.removeSmartRelayFromOrganisation(
        organisationId,
        smartRelayId,
      );

      const organisation = await organisationApi.fetchOrganisation(
        organisationId,
      );

      dispatch(organisationSmartRelayRemoved(organisation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(smartRelayUpdateFailed(e));
    }
  };
}

export default function smartRelaysReducer(
  state: SmartRelaysState = {
    create: {
      loading: false,
      error: null,
      smartRelay: null,
    },
    list: {
      loading: false,
      error: null,
      smartRelays: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      smartRelay: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    removeFromOrganisation: {
      loading: false,
      error: null,
      success: null,
    },
    organisationSmartRelays: {
      loading: false,
      error: null,
      smartRelays: [],
      total: null,
      offset: null,
      limit: null,
    },
  },
  action: SmartRelaysAction,
): SmartRelaysState {
  switch (action.type) {
    case CREATE_SMART_RELAY:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          smartRelay: null,
        },
      };
    case CREATE_SMART_RELAY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          smartRelay: action.payload,
        },
      };
    case CREATE_SMART_RELAY_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          smartRelay: null,
        },
      };
    case FETCH_SMART_RELAY:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          smartRelay: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_SMART_RELAY_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          smartRelay: action.payload,
        },
      };
    case FETCH_SMART_RELAY_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          smartRelay: null,
        },
      };
    case UPDATE_SMART_RELAY:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_SMART_RELAY_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_SMART_RELAY_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_SMART_RELAYS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          smartRelays: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_SMART_RELAYS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          smartRelays: action.payload.smartRelays,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_SMART_RELAYS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          smartRelays: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case REMOVE_SMART_RELAY_FROM_ORGANISATION: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: true,
          success: null,
          error: null,
        },
      };
    }

    case REMOVE_SMART_RELAY_FROM_ORGANISATION_SUCCESS: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: false,
          success: true,
          error: null,
        },
      };
    }

    case REMOVE_SMART_RELAY_FROM_ORGANISATION_FAILED: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: true,
          success: false,
          error: action.payload,
        },
      };
    }

    case FETCH_ORGANISATION_SMART_RELAYS:
      return {
        ...state,
        organisationSmartRelays: {
          ...state.organisationSmartRelays,
          loading: true,
          error: null,
          smartRelays: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ORGANISATION_SMART_RELAYS_SUCCESS:
      return {
        ...state,
        organisationSmartRelays: {
          ...state.organisationSmartRelays,
          loading: false,
          error: null,
          smartRelays: action.payload.smartRelays,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_ORGANISATION_SMART_RELAYS_FAILED:
      return {
        ...state,
        organisationSmartRelays: {
          ...state.organisationSmartRelays,
          loading: true,
          error: null,
          smartRelays: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    default:
      return state;
  }
}

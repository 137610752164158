import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  CreateMessageCmd,
  CreatePopUpMessageCmd,
  UpdateMessageCmd,
} from '../model/cmds';
import { MessageModel } from '../model/domain';
import { ListMessagesQueryModel, ListMessagesQuery } from '../model/querys';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';

export class MessageApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createMessage = this.createMessage.bind(this);
    this.getPopUpMessage = this.getPopUpMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
  }

  public createMessage(cmd: CreateMessageCmd): Promise<MessageModel> {
    return this.post<
      MessageModel,
      CreateMessageCmd,
      AxiosResponse<MessageModel>
    >(`/v1/messages`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getPopUpMessage(messageId: string): Promise<MessageModel> {
    return this.get<MessageModel, AxiosResponse<MessageModel>>(
      `/v1/messages/${messageId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listPopUpMessages(
    cmd?: ListMessagesQuery,
  ): Promise<ListMessagesQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }

    return this.get<
      ListMessagesQueryModel,
      AxiosResponse<ListMessagesQueryModel>
    >(`/v1/messages`, config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteMessage(messageId: string): Promise<MessageModel> {
    return this.delete<MessageModel, AxiosResponse<MessageModel>>(
      `/v1/messages/${messageId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateMessage(
    messageId: string,
    cmd: UpdateMessageCmd,
  ): Promise<MessageModel> {
    console.log(cmd);
    return this.patch<
      MessageModel,
      UpdateMessageCmd,
      AxiosResponse<MessageModel>
    >(`/v1/messages/${messageId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const messageApi = new MessageApi();

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { SensorCalibrationLogModel } from '../model/domain';
import {
  ListCalibrationLogsQuery,
  ListCalibrationLogsQueryModel,
} from '../model/querys';

export class CalibrationLogApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.fetchCalibrationLog = this.fetchCalibrationLog.bind(this);
    this.listCalibrationLogs = this.listCalibrationLogs.bind(this);
  }

  public fetchCalibrationLog(
    calibrationLogId: string,
  ): Promise<SensorCalibrationLogModel> {
    return this.get<
      SensorCalibrationLogModel,
      AxiosResponse<SensorCalibrationLogModel>
    >(`/v1/calibrationLogs/${calibrationLogId}`, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listCalibrationLogs(
    cmd?: ListCalibrationLogsQuery,
  ): Promise<ListCalibrationLogsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListCalibrationLogsQueryModel,
      AxiosResponse<ListCalibrationLogsQueryModel>
    >('/v1/calibrationLogs', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const calibrationLogApi = new CalibrationLogApi();

import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from './duck';
import thunk from 'redux-thunk';
import './index.css';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

export default store;

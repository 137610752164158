import {
  Button,
  Col,
  Descriptions,
  List,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';

import { Link } from 'react-router-dom';
import {
  getOrganisationSmartSensors,
  getSmartSensors,
  removeSmartSensorFromOrganisation,
} from '../../duck/modules/smartSensors';
import { INT_MAX } from '../../constants';
import { SimpleSmartSensorModel } from '../../model/simple';
import { AddSmartSensorToOrganisationForm } from './addSmartSensorToOrganisationForm';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId: string;
  };

const UnconnectedOrganisationSmartSensorsList: React.FC<Props> = ({
  organisationId,
  smartSensorsListState,
  getOrganisationSmartSensorsConnect,
  removeSmartSensorFromOrganisationConnect,
  smartSensorsRemoveFromOrganisationState,
}) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    getOrganisationSmartSensorsConnect(organisationId);
  }, []);

  React.useEffect(() => {
    if (smartSensorsRemoveFromOrganisationState.success === true) {
      notification.success({
        message: 'Smart Sensor removed',
      });
    }
  }, [smartSensorsRemoveFromOrganisationState.success]);

  React.useEffect(() => {
    if (smartSensorsRemoveFromOrganisationState.error) {
      notification.error({
        message: 'Error while removing the Smart Sensor',
        description: smartSensorsRemoveFromOrganisationState.error?.message,
      });
    }
  }, [smartSensorsRemoveFromOrganisationState.error]);

  const renderSmartSensor = (smartSensor: SimpleSmartSensorModel) => {
    return (
      <List.Item>
        <List.Item.Meta
          description={
            <Descriptions bordered column={1}>
              <Descriptions.Item label={'Name'}>
                <Typography>
                  {smartSensor.name != undefined ? smartSensor.name : '-'}
                </Typography>
              </Descriptions.Item>

              <Descriptions.Item label={'ID'}>
                <Link to={`/smartSensors/${smartSensor.id}`}>
                  {smartSensor.id}
                </Link>
              </Descriptions.Item>

              <Descriptions.Item
                label={<Typography.Text>{'Actions'}</Typography.Text>}
              >
                <Space>
                  <Popconfirm
                    title={
                      'Are you sure to remove this smart sensor from the organisation?'
                    }
                    onConfirm={() => {
                      removeSmartSensorFromOrganisationConnect(
                        organisationId,
                        smartSensor.id,
                        () => {
                          getOrganisationSmartSensorsConnect(organisationId);
                        },
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="default">
                      Remove
                    </Button>
                  </Popconfirm>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          }
        />
      </List.Item>
    );
  };

  const renderListHeader = () => {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Text strong>{'Smart Sensors'}</Typography.Text>
        </Col>
        <Col>
          <Button onClick={() => setVisible(true)} type="link">
            {'Add Smart Sensor'}
          </Button>
        </Col>
        <Modal
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          title={'Add smart sensor to organisation'}
        >
          <AddSmartSensorToOrganisationForm
            organisationId={organisationId}
            onAdded={(_: any) => {
              getOrganisationSmartSensorsConnect(organisationId);
              setVisible(false);
            }}
            onError={(_error: Error) => {
              // setVisible(false);
            }}
          />
        </Modal>
      </Row>
    );
  };

  return (
    <List
      loading={smartSensorsListState.loading}
      bordered
      header={renderListHeader()}
      itemLayout="horizontal"
      dataSource={smartSensorsListState.smartSensors}
      grid={{
        gutter: 8,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      renderItem={(smartSensor) => renderSmartSensor(smartSensor)}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  smartSensorsListState: state.smartSensors.organisationSmartSensors,
  smartSensorsRemoveFromOrganisationState:
    state.smartSensors.removeFromOrganisation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationSmartSensorsConnect: getOrganisationSmartSensors,
      removeSmartSensorFromOrganisationConnect: removeSmartSensorFromOrganisation,
    },
    dispatch,
  );
};

export const OrganisationSmartSensorsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationSmartSensorsList);

import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { CreateAccountForm } from '../account/createAccountForm';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { AccountsTable } from '../account/accountsTable';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedAccounts: React.FC<Props> = ({ location }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout>
      <Content>
        <Modal
          title="Create an account"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <CreateAccountForm onCreated={handleOk} />
        </Modal>

        <AccountsTable
          queryParams={queryParams}
          reload={triggerReload}
          onReloaded={() => setTriggerReload(false)}
        />

        {!isModalVisible && (
          <Fab
            style={{ bottom: 0, right: 0 }}
            mainButtonStyles={{
              backgroundColor: getNavbarColor(),
              color: getNavbarTextColor(),
            }}
            icon={<PlusOutlined />}
            alwaysShowTitle={false}
            onClick={showModal}
            text="Add account"
          />
        )}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const Accounts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedAccounts));

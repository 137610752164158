import React from 'react';
import { Descriptions, Typography } from 'antd';
import { SensorSpecificDataModel } from '../../model/domain';

type Props = {
  lastMeasurement: SensorSpecificDataModel;
};

export const LastMeasurementDescription: React.FC<Props> = ({
  lastMeasurement,
}) => {
  if (lastMeasurement && Object(lastMeasurement).key > 0) {
    return (
      <Descriptions bordered column={1}>
        {lastMeasurement.doRawPerc !== undefined && (
          <Descriptions.Item label="Raw DO (%)">
            <Typography>{`${lastMeasurement.doRawPerc} %`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.doPerc !== undefined && (
          <Descriptions.Item label="DO (%)">
            <Typography>{`${lastMeasurement.doPerc} %`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.doPPM !== undefined && (
          <Descriptions.Item label="DO (ppm)">
            <Typography>{`${lastMeasurement.doPPM} ppm`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.tempInC !== undefined && (
          <Descriptions.Item label="Temperature">
            <Typography>{`${lastMeasurement.tempInC} °C`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.pHRawInMv !== undefined && (
          <Descriptions.Item label="Raw PH">
            <Typography>{`${lastMeasurement.pHRawInMv} mV`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.pH !== undefined && (
          <Descriptions.Item label="PH">
            <Typography>{`${lastMeasurement.pH}`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.ecInA !== undefined && (
          <Descriptions.Item label="EC">
            <Typography>{`${lastMeasurement.ecInA} A`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.epInKW !== undefined && (
          <Descriptions.Item label="EP">
            <Typography>{`${lastMeasurement.epInKW} kW`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.rawCondInMs !== undefined && (
          <Descriptions.Item label="Raw Conductivity">
            <Typography>{`${lastMeasurement.rawCondInMs} µS/cm`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.condInMs !== undefined && (
          <Descriptions.Item label="Conductivity">
            <Typography>{`${lastMeasurement.condInMs} µS/cm`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.salPPT !== undefined && (
          <Descriptions.Item label="Salinity">
            <Typography>{`${lastMeasurement.salPPT} ppt`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.lux !== undefined && (
          <Descriptions.Item label="LUX">
            <Typography>{`${lastMeasurement.lux}`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.humidityInPerc !== undefined && (
          <Descriptions.Item label="Humidity">
            <Typography>{`${lastMeasurement.humidityInPerc} %`}</Typography>
          </Descriptions.Item>
        )}
        {lastMeasurement.luxTempInC !== undefined && (
          <Descriptions.Item label="Temperature">
            <Typography>{`${lastMeasurement.luxTempInC} °C`}</Typography>
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  }
  return <Typography>{`No data`}</Typography>;
};

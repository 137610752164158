import React, { useState } from 'react';
import { Drawer, Button, Row, Col, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './navBar.css';
import {
  getNavbarTextColor,
  getNavbarColor,
  getEnvironment,
} from '../../../util';
import { CloseOutlined } from '@ant-design/icons';

const NavBar = ({ menu, toggleDrawer }: any) => {
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [toggleDrawer]);

  return (
    <nav className="navbar" style={{ backgroundColor: getNavbarColor() }}>
      <Row className="menu" align="middle">
        <Col>
          <Button
            type="default"
            size="middle"
            icon={<MenuOutlined />}
            onClick={() => setVisible(true)}
            style={{
              background: getNavbarTextColor(),
              color: getNavbarColor(),
            }}
          />
        </Col>
        <Col>
          <div
            className="logo"
            style={{ backgroundColor: getNavbarTextColor() }}
          >
            <Typography
              style={{
                fontSize: 17,
                color: getNavbarColor(),
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              {getEnvironment()}
            </Typography>
          </div>
        </Col>
      </Row>

      <Drawer
        title={
          <Typography style={{ color: getNavbarTextColor() }}>
            {'Menu'}
          </Typography>
        }
        closeIcon={<CloseOutlined style={{ color: getNavbarTextColor() }} />}
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
        headerStyle={{
          backgroundColor: getNavbarColor(),
          borderRadius: 0,
          borderBottom: 'solid 1px',
          borderColor: getNavbarTextColor(),
        }}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: getNavbarColor(),
        }}
      >
        {menu}
      </Drawer>
    </nav>
  );
};

export default NavBar;

import {
  Card,
  Col,
  Layout,
  Result,
  Row,
  Typography,
  Descriptions,
  Tooltip,
  Steps,
  StepsProps,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../duck';
import { getNameToCalibrationState, getSensorNameToType } from '../../util';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import { CalibrationFailure, CalibrationState } from '../../model/enums';
import { getCalibrationLog } from '../../duck/modules/calibrationLogs';
import { CalibrationStateBadge } from '../sensor/calibrationStateBadge';
import { LastMeasurementDescription } from '../sensor/lastMeasurementDescription';
import { SensorCalibrationLogModel } from '../../model/domain';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ calibrationLogId: string }>;

const UnconnectedCalibrationLogDetail: React.FC<Props> = ({
  calibrationLog,
  match,
  loading,
  error,
  getCalibrationLogConnect,
}) => {
  const { calibrationLogId } = match.params;

  React.useEffect(() => {
    getCalibrationLogConnect(calibrationLogId);
  }, []);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load calibration log"
        subTitle={error.message}
      />
    );
  }

  const renderCalibrationDataCard = () => {
    return (
      <Card title="Calibration Data" loading={loading}>
        {calibrationLog && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Sensor">
              <Link to={`/sensors/${calibrationLog.sensor.id}`}>
                {`${getSensorNameToType(calibrationLog.sensor.type)}`}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Timestamp">
              <Tooltip
                placement="left"
                title={moment(calibrationLog.updatedTimestamp).fromNow()}
              >
                <Typography>
                  {moment(calibrationLog.updatedTimestamp).format('LLLL')}
                </Typography>
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="Result">
              <Descriptions bordered column={1}>
                <Descriptions.Item label="State">
                  <CalibrationStateBadge state={calibrationLog.result.state} />
                </Descriptions.Item>
                <Descriptions.Item label="Step">
                  <Typography>{calibrationLog.result.step}</Typography>
                </Descriptions.Item>
                {calibrationLog.result.failure !=
                  CalibrationFailure.NOT_SET && (
                  <Descriptions.Item label="Reason">
                    <Typography>{calibrationLog.result.failure}</Typography>
                  </Descriptions.Item>
                )}
                {calibrationLog.result.newParam1 != undefined && (
                  <Descriptions.Item label="New Param 1">
                    <Typography>{calibrationLog.result.newParam1}</Typography>
                  </Descriptions.Item>
                )}
                {calibrationLog.result.newParam2 != undefined && (
                  <Descriptions.Item label="New Param 2">
                    <Typography>{calibrationLog.result.newParam2}</Typography>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderLatestMeasurementCard = () => {
    return (
      <Card title="Most Recent Data" loading={loading}>
        {calibrationLog && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Last Measurement">
              <LastMeasurementDescription
                lastMeasurement={calibrationLog.sensor.lastMeasurement}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Timestamp">
              <Tooltip
                placement="left"
                title={moment(
                  calibrationLog.sensor.lastMeasurementTimestamp,
                ).fromNow()}
              >
                <Typography>
                  {moment(
                    calibrationLog.sensor.lastMeasurementTimestamp,
                  ).format('LLLL')}
                </Typography>
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  function getStatusToStep(
    calibrationLog: SensorCalibrationLogModel,
  ): StepsProps['status'] {
    switch (calibrationLog.result.state) {
      case CalibrationState.NO_ANSWER_IN_TIME:
      case CalibrationState.FAILURE:
        return 'error';
      case CalibrationState.SUCCESS:
        return 'finish';
      case CalibrationState.PENDING:
        return 'process';
      default:
        return 'wait';
    }
  }

  const renderCalibrationEntriesCard = () => {
    let stepNo = 1;
    return (
      <Card title="Calibration Process" loading={loading}>
        {calibrationLog && (
          <Steps
            direction="vertical"
            progressDot
            current={calibrationLog.log.length - 1}
            status={getStatusToStep(calibrationLog)}
          >
            {calibrationLog.log.map((logEntry) => {
              return (
                <Steps.Step
                  key={stepNo}
                  title={getNameToCalibrationState(logEntry.state)}
                  subTitle={logEntry.step}
                  description={
                    <Descriptions layout="horizontal" column={{ xs: 1, sm: 4 }}>
                      <Descriptions.Item label="Last Measurement">
                        <Typography>
                          {logEntry.lastMeasurement != undefined
                            ? logEntry.lastMeasurement
                            : '-'}
                        </Typography>
                      </Descriptions.Item>
                      {logEntry.failure != CalibrationFailure.NOT_SET &&
                        logEntry.failure != undefined && (
                          <Descriptions.Item label="Reason">
                            <Typography>{logEntry.failure}</Typography>
                          </Descriptions.Item>
                        )}
                      {logEntry.newParam1 != undefined && (
                        <Descriptions.Item label="Param 1">
                          <Typography>{logEntry.newParam1}</Typography>
                        </Descriptions.Item>
                      )}
                      {logEntry.newParam2 != undefined && (
                        <Descriptions.Item label="Param 2">
                          <Typography>{logEntry.newParam2}</Typography>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  }
                />
              );
            })}
          </Steps>
        )}
      </Card>
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]} justify="start">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderCalibrationDataCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderLatestMeasurementCard()}
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {renderCalibrationEntriesCard()}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  calibrationLog: state.calibrationLogs.detail.calibrationLog,
  loading: state.calibrationLogs.detail.loading,
  error: state.calibrationLogs.detail.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCalibrationLogConnect: getCalibrationLog,
    },
    dispatch,
  );
};

export const CalibrationLogDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCalibrationLogDetail);

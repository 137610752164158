import { ThunkDispatch as Dispatch } from 'redux-thunk';
import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILED,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_FAILED,
  FETCH_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILED,
  INT_MAX,
  UPDATE_ACCOUNT_ROLE,
  UPDATE_ACCOUNT_ROLE_SUCCESS,
  UPDATE_ACCOUNT_ROLE_FAILED,
  COUNT_ACCOUNTS,
  COUNT_ACCOUNTS_SUCCESS,
  COUNT_ACCOUNTS_FAILED,
} from '../../constants';
import { accountApi } from '../../api/accountApi';
import {
  AccountModel,
  AuthCredentials,
  DecodedToken,
  TokenModel,
} from '../../model/domain';
import {
  CreateAccountCmd,
  UpdateAccountCmd,
  UpdateAccountRoleCmd,
} from '../../model/cmds';
import { ListAccountsQueryModel, ListAccountsQuery, CountAccountsModel } from '../../model/querys';
import { AccountsState } from '../../model/state';
import {
  accountsFetch,
  accountsFetched,
  accountsFetchFailed,
  accountCreate,
  accountCreated,
  accountCreateFailed,
  AccountsAction,
  accountFetch,
  accountFetched,
  accountFetchFailed,
  accountUpdate,
  accountUpdated,
  accountUpdateFailed,
  accountRoleUpdate,
  accountRoleUpdated,
  accountRoleUpdateFailed,
  accountsCount,
  accountsCounted,
  accountsCountFailed,
} from '../actions';
import * as _ from 'lodash';
import { getOrganisationMemberOptions } from './organisations';
import { organisationApi } from '../../api/organisationApi';
import { Role } from '../../model/enums';

export function getAccount(id: string) {
  return async (dispatch: Dispatch<AccountsAction, {}, any>) => {
    try {
      dispatch(accountFetch());
      const account: AccountModel = await accountApi.fetchAccount(id);
      const tokenModel: TokenModel = await accountApi.fetchToken(id);

      if (account.organisation?.id) {
        const organisation = await organisationApi.fetchOrganisation(
          account.organisation.id,
        );
        account.organisation = organisation;
      }

      account.token = tokenModel.token;

      dispatch(accountFetched(account));
    } catch (e) {
      dispatch(accountFetchFailed(e));
    }
  };
}

export function updateAccount(id: string, cmd: UpdateAccountCmd) {
  return async (dispatch: Dispatch<AccountsAction, {}, any>) => {
    try {
      dispatch(accountUpdate());
      const response: AccountModel = await accountApi.updateAccount(id, cmd);
      dispatch(accountUpdated(response));
    } catch (e) {
      dispatch(accountUpdateFailed(e));
    }
  };
}

export function updateAccountRole(id: string, cmd: UpdateAccountRoleCmd) {
  return async (dispatch: Dispatch<AccountsAction, {}, any>) => {
    try {
      dispatch(accountRoleUpdate());
      const response: AccountModel = await accountApi.updateAccountRole(
        id,
        cmd,
      );
      dispatch(accountRoleUpdated(response));
    } catch (e) {
      dispatch(accountRoleUpdateFailed(e));
    }
  };
}

export function getAccounts(query: ListAccountsQuery) {
  return async (dispatch: Dispatch<AccountsAction, {}, any>) => {
    try {
      dispatch(accountsFetch(query));
      const queryModel: ListAccountsQueryModel = await accountApi.fetchAccounts(
        query,
      );

      const accounts = await Promise.all(
        queryModel.accounts.map((account) =>
          accountApi.fetchAccount(account.id),
        ),
      );

      dispatch(accountsFetched(queryModel, accounts));
    } catch (e) {
      dispatch(accountsFetchFailed(e));
    }
  };
}

export function countAccounts() {
  return async (dispatch: Dispatch<AccountsAction, {}, any>) => {
    try {
      dispatch(accountsCount());
      const response: CountAccountsModel = await accountApi.countAccounts();
      dispatch(accountsCounted(response));
    } catch (e) {
      dispatch(accountsCountFailed(e));
    }
  };
}

export function createAccount(cmd: CreateAccountCmd) {
  return async (dispatch: Dispatch<AccountsAction, {}, any>) => {
    try {
      dispatch(accountCreate());
      const response: AuthCredentials = await accountApi.createAccount(cmd);
      const decodedTokenString = atob(response.token.split('.')[1]);
      const decodedToken = JSON.parse(decodedTokenString) as DecodedToken;
      const account = await accountApi.fetchAccount(decodedToken.sub);
      dispatch(getAccounts({ limit: INT_MAX, offset: 0 }));
      dispatch(getOrganisationMemberOptions());

      dispatch(accountCreated(account));
    } catch (e) {
      dispatch(accountCreateFailed(e));
    }
  };
}

export default function accountsReducer(
  state: AccountsState = {
    create: {
      loading: false,
      error: null,
      account: null,
    },
    list: {
      loading: false,
      error: null,
      accounts: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      account: null,
    },
    update: {
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    updateRole: {
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    counts: {
      loading: false,
      error: null,
      active: null,
      inactive: null,
      all: null,
    }
  },
  action: AccountsAction,
): AccountsState {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, account: null },
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          account: action.payload,
        },
      };
    case CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          account: null,
        },
      };
    case FETCH_ACCOUNT:
      return {
        ...state,
        detail: { ...state.detail, loading: true, error: null, account: null },
      };
    case FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          account: action.payload,
        },
      };
    case FETCH_ACCOUNT_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          account: null,
        },
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        update: {
          ...state.update,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
        detail: { ...state.detail, account: action.payload },
      };
    case UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        update: {
          ...state.update,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case UPDATE_ACCOUNT_ROLE:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_ACCOUNT_ROLE_SUCCESS:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
        detail: { ...state.detail, account: action.payload },
      };
    case UPDATE_ACCOUNT_ROLE_FAILED:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_ACCOUNTS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          accounts: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          accounts: action.payload.accounts,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };
    case FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          accounts: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    case COUNT_ACCOUNTS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: true,
          error: null,
          active: null,
          inactive: null,
          all: null,
        }
      };
    case COUNT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: null,
          active: action.payload.counts.active,
          inactive: action.payload.counts.inactive,
          all: action.payload.counts.all,
        }
      };
    case COUNT_ACCOUNTS_FAILED:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: action.payload,
          active: null,
          inactive: null,
          all: null,
        }
      };
  }
  return state;
}

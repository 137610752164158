import React from 'react';
import {
  Table,
  Tag,
  Button,
  Result,
  Typography,
  Row,
  Pagination,
  Col,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getFarms } from '../../duck/modules/farms';
import { FarmModel, FeedTableModel } from '../../model/domain';
import { functionalColor } from '../../colors';
import Column from 'antd/lib/table/Column';
import { getFeedTables } from '../../duck/modules/feedTables';
import { SimpleFeedTableModel } from '../../model/simple';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    reload: boolean;
    onReloaded: () => void;
    queryParams?: {
      page?: number;
      pageSize?: number;
    };
  };

const UnconnectedFeedTablesTable: React.FC<Props> = ({
  feedTables,
  loading,
  error,
  total,
  reload,
  onReloaded,
  getFeedTablesConnect,
  queryParams,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  React.useEffect(() => {
    getFeedTablesConnect({ limit, offset, includeDeleted: false });
    onReloaded();
  }, [offset, limit, reload]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load feedTables"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return (
    <Col>
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={feedTables}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Name"
          render={(feedTable: SimpleFeedTableModel) => (
            <Link key={feedTable.id} to={`/feedTables/${feedTable.id}`}>
              {feedTable.name}
            </Link>
          )}
        />
      </Table>
      {total != null && (
        <Row justify="end" style={{ marginTop: 10 }}>
          <Pagination
            total={total}
            defaultCurrent={page || 1}
            pageSize={currentPageSize}
            pageSizeOptions={['5', '10', '25', '50', '100', '200']}
            responsive={true}
            showSizeChanger={true}
            showTotal={(total) => `Total: ${total} items`}
            onChange={(page, pageSize) => {
              if (page && pageSize) {
                handleChange(pageSize, page);
              }
            }}
            onShowSizeChange={(_, pageSize) => {
              handleChange(pageSize, page);
            }}
          />
        </Row>
      )}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  feedTables: state.feedTables.list.feedTables,
  loading: state.feedTables.list.loading,
  error: state.feedTables.list.error,
  total: state.feedTables.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFeedTablesConnect: getFeedTables,
    },
    dispatch,
  );
};

export const FeedTablesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedFeedTablesTable);

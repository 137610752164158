import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Tooltip,
  Select,
  Switch,
  Typography,
  DatePicker,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { AnnouncementModel } from '../../model/domain';
import { UpdateAnnouncementCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';
import { AnnouncementType } from '../../model/enums';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

type Props = {
  announcement: AnnouncementModel;
  onUpdate: (cmd: UpdateAnnouncementCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const AnnouncementDataForm: React.FC<Props> = ({
  announcement,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  const [validSwitchValue, setValidSwitchValue] = React.useState(
    announcement.valid,
  );

  const convertUTC = (date: Date) => {
    return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  };

  React.useEffect(() => {
    form.setFieldsValue({
      //...announcement,
      validityPeriod: [
        moment(announcement.notShowBefore),
        moment(announcement.notShowAfter),
      ],
      valid: announcement.valid,
      type: announcement.type,
      englishTitle: announcement.content.english.title,
      thaiTitle: announcement.content.thai.title,
      englishBody: announcement.content.english.body,
      thaiBody: announcement.content.thai.body,
    });

    setValidSwitchValue(announcement.valid);
  }, [announcement]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Announcement updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Announcement',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const {
      type,
      valid,
      englishTitle,
      thaiTitle,
      englishBody,
      thaiBody,
      validityPeriod,
    } = values;

    const notShowBefore: moment.Moment = validityPeriod[0];
    const notShowAfter: moment.Moment = validityPeriod[1];

    const cmd: UpdateAnnouncementCmd = {};
    cmd.valid = valid;
    cmd.type = type;

    cmd.notShowBefore = convertUTC(notShowBefore.toDate());
    cmd.notShowAfter = convertUTC(notShowAfter.toDate());

    cmd.content = {
      english: {
        title: englishTitle,
        body: englishBody,
      },
      thai: {
        title: thaiTitle,
        body: thaiBody,
      },
    };

    onUpdate(cmd);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item label="Type" name="type" rules={[{ required: true }]}>
        <Select placeholder="Select announcement type">
          <Select.Option value={AnnouncementType.INFO}>{'INFO'}</Select.Option>
          <Select.Option value={AnnouncementType.MAINTENANCE}>
            {'MAINTENANCE'}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        style={{ marginBottom: 0 }}
        label={<Typography.Text italic>{'Validity'}</Typography.Text>}
      >
        <Form.Item
          label="Schedule"
          name="validityPeriod"
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="DD / MM / YYYY HH:mm"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Displayed according to schedule?"
          name="valid"
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
          rules={[{ required: true }]}
        >
          <Switch
            onChange={(checked) => setValidSwitchValue(checked)}
            checked={validSwitchValue}
            checkedChildren=" Yes "
            unCheckedChildren=" Hidden Anyway "
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={<Typography.Text italic>{'Title'}</Typography.Text>}
        style={{ marginBottom: 0 }}
      >
        <Form.Item
          label="English"
          name="englishTitle"
          rules={[{ required: true }, { type: 'string' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input placeholder="English" />
        </Form.Item>
        <Form.Item
          label="Thai"
          name="thaiTitle"
          rules={[{ required: true }, { type: 'string' }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <Input placeholder="Thai" />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={<Typography.Text italic>{'Body'}</Typography.Text>}
        style={{ marginBottom: 0 }}
      >
        <Form.Item
          label="English"
          name="englishBody"
          rules={[{ required: true }, { type: 'string' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <TextArea
            placeholder="English"
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(body) =>
              form.setFieldsValue({ englishBody: body.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="Thai"
          name="thaiBody"
          rules={[{ required: true }, { type: 'string' }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <TextArea
            placeholder="Thai"
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(body) =>
              form.setFieldsValue({ thaiBody: body.target.value })
            }
          />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Announcement
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

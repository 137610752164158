import React from 'react';

import {
  Result,
  Row,
  DatePicker,
  Form,
  Select,
  Col,
  message,
  notification,
  Pagination,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { SensorAggregationDataTable } from './sensorAggregationDataTable';
import { getSensorDataAggregations } from '../../duck/modules/sensing';
import { ListSensorDataAggregationQuery } from '../../model/querys';
import { AggregationOperation, TimeUnit, SensorType } from '../../model/enums';
import { SensorAggregationsGraph } from './sensorAggregationsGraph';

const { Option } = Select;
const { RangePicker } = DatePicker;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    sensorId: string;
    sensorType: SensorType;
    queryParams?: {
      page?: number;
      pageSize?: number;
    };
  };

const UnconnectedSensorAggregations: React.FC<Props> = ({
  sensorId,
  loading,
  error,
  model,
  queryParams,
  sensorType,
  getSensorDataAggregationsConnect,
}) => {
  enum Visualisation {
    TABLE = 'TABLE',
    GRAPH = 'GRAPH',
  }
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  const [timeUnit, setTimeUnit] = React.useState(TimeUnit.HOUR);
  const [aggregations, setAggregations] = React.useState([
    //AggregationOperation.MIN,
    AggregationOperation.MEDIAN,
    //AggregationOperation.MEAN,
    //AggregationOperation.MAX,
  ]);
  const [visualisation, setVisualisation] = React.useState(Visualisation.GRAPH);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  React.useEffect(() => {
    const query: ListSensorDataAggregationQuery = {
      sensorId,
      limit,
      offset,
      timeUnit,
      aggregations,
      // hoursOfDay: [0, 1], // 0 .. 23
      // daysOfYear: [0, 1], // 0 ... 365
      // minutesOfHour: [0, 59], // 0..59
    };
    getSensorDataAggregationsConnect(query);
  }, [sensorId, offset, limit, timeUnit, aggregations]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load sensor data"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderFilterForm = () => {
    return (
      <Form
        layout="inline"
        style={{ width: '100%' }}
        initialValues={{ aggregations, timeUnit, visualisation }}
      >
        <Form.Item name="visualisation" label="Visualisation">
          <Select
            placeholder="Please visualisation component"
            onChange={(data: Visualisation) => setVisualisation(data)}
          >
            <Option value={Visualisation.TABLE}>Table</Option>
            <Option value={Visualisation.GRAPH}>Graph</Option>
          </Select>
        </Form.Item>

        <Form.Item name="timeUnit" label="Time unit">
          <Select
            placeholder="Please select time unit"
            onChange={(data: TimeUnit) => setTimeUnit(data)}
          >
            <Option value={TimeUnit.MINUTE}>Minute</Option>
            <Option value={TimeUnit.HOUR}>Hour</Option>
            <Option value={TimeUnit.DAY}>Day</Option>
          </Select>
        </Form.Item>
        <Form.Item name="aggregations" label="Aggregations">
          <Select
            mode="multiple"
            placeholder="Please select aggregations"
            value={aggregations}
            onChange={(data: AggregationOperation[]) => setAggregations(data)}
            allowClear={false}
            style={{ minWidth: 200 }}
          >
            <Option value={AggregationOperation.MIN}>MIN</Option>
            <Option value={AggregationOperation.MAX}>MAX</Option>
            <Option value={AggregationOperation.MEAN}>MEAN</Option>
            <Option value={AggregationOperation.MEDIAN}>MEDIAN</Option>
          </Select>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>{renderFilterForm()}</Col>
      <Col span={24}>
        {visualisation === Visualisation.TABLE && (
          <Col>
            <SensorAggregationDataTable
              pagination={
                model &&
                model.total != null && (
                  <Row justify="center" style={{ marginBottom: 10 }}>
                    <Pagination
                      total={model.total}
                      defaultCurrent={page || 1}
                      pageSize={currentPageSize}
                      pageSizeOptions={['5', '10', '25', '50', '100', '200']}
                      responsive={true}
                      showSizeChanger={true}
                      showTotal={(total) => `Total: ${total} items`}
                      onChange={(page, pageSize) => {
                        if (page && pageSize) {
                          handleChange(pageSize, page);
                        }
                      }}
                      onShowSizeChange={(_, pageSize) => {
                        handleChange(pageSize, page);
                      }}
                    />
                  </Row>
                )
              }
              loading={loading}
              aggregations={model?.data || []}
            />
          </Col>
        )}

        {visualisation === Visualisation.GRAPH && (
          <SensorAggregationsGraph
            sensorType={sensorType}
            pagination={
              model &&
              model.total != null && (
                <Row justify="center" style={{ marginBottom: 10 }}>
                  <Pagination
                    total={model.total}
                    defaultCurrent={page || 1}
                    pageSize={currentPageSize}
                    pageSizeOptions={['5', '10', '25', '50', '100', '200']}
                    responsive={true}
                    showSizeChanger={true}
                    showTotal={(total) => `Total: ${total} items`}
                    onChange={(page, pageSize) => {
                      if (page && pageSize) {
                        handleChange(pageSize, page);
                      }
                    }}
                    onShowSizeChange={(_, pageSize) => {
                      handleChange(pageSize, page);
                    }}
                  />
                </Row>
              )
            }
            loading={loading}
            aggregations={model?.data || []}
          />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  model: state.sensing.aggregations.model,
  query: state.sensing.aggregations.query,
  loading: state.sensing.aggregations.loading,
  error: state.sensing.aggregations.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSensorDataAggregationsConnect: getSensorDataAggregations,
    },
    dispatch,
  );
};

export const SensorAggregations = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorAggregations);

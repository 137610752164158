import { Button, Card, Descriptions, Empty, Row, Typography } from 'antd';
import React from 'react';
import _ from 'lodash';

import { Position } from '../../model/domain';

type Props = {
  position?: Position;
  loading?: boolean;
};

export const PositionCard: React.FC<Props> = ({ position, loading }) => {
  const renderCardContent = () => {
    if (position && !_.isEmpty(position)) {
      return (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Latitude">
            <Typography.Text>{position.latitude}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Longitude">
            <Typography.Text>{position.longitude}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Altitude">
            <Typography.Text>{position.altitude}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      );
    }
    return <Empty description={<span>No Data </span>} />;
  };

  const renderCardTitle = () => {
    return (
      <Row justify="space-between" align="middle">
        <Typography>{'Position'}</Typography>

        {!loading && position && !_.isEmpty(position) && (
          <a
            target="_blank"
            href={`https://maps.google.com/maps?q=${position.latitude},${position.longitude}`}
          >
            <Button type="primary" onClick={() => {}}>
              {'Show Map'}
            </Button>
          </a>
        )}
      </Row>
    );
  };

  return (
    <Card
      title={renderCardTitle()}
      loading={loading}
      style={{ height: '100%' }}
    >
      {renderCardContent()}
    </Card>
  );
};

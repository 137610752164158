import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal, Tabs } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { AnnouncementsResponsiveTable } from '../announcement/announcementsResponsiveTable';
import { CreateAnnouncementForm } from '../announcement/createAnnouncementForm';
import { LatestAnnouncementPartial } from '../announcement/latestAnnouncementPartial';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedAnnouncements: React.FC<Props> = ({ location }) => {
  const [isAnnouncementModalVisible, setIsAnnouncementModalVisible] = useState(
    false,
  );
  const [triggerAnnouncementReload, setAnnouncementTriggerReload] = useState(
    false,
  );

  const [isManufacturerModalVisible, setIsManufacturerModalVisible] = useState(
    false,
  );
  const [triggerManufacturerReload, setManufacturerTriggerReload] = useState(
    false,
  );

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const handleTabChanged = (activeKey: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const pathName = window.location.pathname;
    const searchParams = new URLSearchParams();
    searchParams.set('tab', activeKey);
    const newUrl = `${protocol}//${host}${pathName}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setAnnouncementTriggerReload(true);
    setManufacturerTriggerReload(true);
  };
  const defaultTab = queryParams['tab']?.toString() || 'latest';

  return (
    <Layout>
      <Content>
        <Tabs defaultActiveKey={defaultTab} onChange={handleTabChanged}>
          <TabPane tab="Latest" key="latest">
            <LatestAnnouncementPartial
              reload={triggerAnnouncementReload}
              onReloaded={() => setAnnouncementTriggerReload(false)}
              onCreateClick={() => {
                setIsAnnouncementModalVisible(true);
              }}
            />
          </TabPane>
          <TabPane tab="List" key="list">
            <AnnouncementsResponsiveTable
              reload={triggerAnnouncementReload}
              onReloaded={() => setAnnouncementTriggerReload(false)}
              queryParams={queryParams}
            />

            {!isAnnouncementModalVisible && (
              <Fab
                style={{ bottom: 0, right: 0 }}
                mainButtonStyles={{
                  backgroundColor: getNavbarColor(),
                  color: getNavbarTextColor(),
                }}
                icon={<PlusOutlined />}
                alwaysShowTitle={false}
                onClick={() => {
                  setIsAnnouncementModalVisible(true);
                }}
                text="Create announcement"
              />
            )}
          </TabPane>
        </Tabs>
        <Modal
          title="Create announcement"
          visible={isAnnouncementModalVisible}
          onCancel={() => {
            setIsAnnouncementModalVisible(false);
          }}
          footer={null}
        >
          <CreateAnnouncementForm
            onCreated={() => {
              setAnnouncementTriggerReload(true);
              setIsAnnouncementModalVisible(false);
            }}
          />
        </Modal>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const Announcements = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedAnnouncements));

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateCycleCmd, UpdateCycleCmd } from '../model/cmds';
import { CycleModel } from '../model/domain';
import { ListCyclesQuery, ListCyclesQueryModel } from '../model/querys';

export class CycleApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createCycle = this.createCycle.bind(this);
    this.fetchCycle = this.fetchCycle.bind(this);
    this.listCycles = this.listCycles.bind(this);
    this.updateCycle = this.updateCycle.bind(this);
  }

  public createCycle(pondId: string, cmd: CreateCycleCmd): Promise<CycleModel> {
    return this.post<CycleModel, CreateCycleCmd, AxiosResponse<CycleModel>>(
      `/v1/ponds/${pondId}/cycles`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchCycle(cycleId: string): Promise<CycleModel> {
    return this.get<CycleModel, AxiosResponse<CycleModel>>(
      `/v1/cycles/${cycleId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listCycles(cmd?: ListCyclesQuery): Promise<ListCyclesQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<ListCyclesQueryModel, AxiosResponse<ListCyclesQueryModel>>(
      '/v1/cycles',
      config,
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateCycle(
    cycleId: string,
    cmd: UpdateCycleCmd,
  ): Promise<CycleModel> {
    return this.patch<CycleModel, UpdateCycleCmd, AxiosResponse<CycleModel>>(
      `/v1/cycles/${cycleId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const cycleApi = new CycleApi();

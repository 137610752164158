import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { ShrimpSizeTable } from '../shrimpSize/shrimpSizeTable';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedShrimpSize: React.FC<Props> = ({ location }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout>
      <Content>
        <ShrimpSizeTable queryParams={queryParams} />
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const ShrimpSize = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedShrimpSize));

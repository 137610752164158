import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { FarmsTable } from '../farm/farmsTable';
import { CreateFarmForm } from '../farm/createFarmForm';
import { FarmModel } from '../../model/domain';
import { ListLocationsQuery } from '../../model/querys';
import { INT_MAX } from '../../constants';
import { getLocations } from '../../duck/modules/locations';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedFarms: React.FC<Props> = ({
  location,
  locationsState,
  getLocationsConnnect,
}) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout>
      <Content>
        <Modal
          title="Create a farm"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <CreateFarmForm
            onCreated={(farm: FarmModel) => {
              console.warn('CREATED', JSON.stringify(farm));
              setTriggerReload(true);
              handleOk();
            }}
            onError={(_error: Error) => {
              console.warn('ERROR', JSON.stringify(_error));
            }}
            onZipCodeChange={(zipCode: string) => {
              const query: ListLocationsQuery = {
                limit: INT_MAX,
                offset: 0,
                zipCodes: [zipCode],
              };
              getLocationsConnnect(query);
            }}
            locations={locationsState.list.locations}
            locationsLoading={locationsState.list.loading}
          />
        </Modal>

        <FarmsTable
          reload={triggerReload}
          onReloaded={() => setTriggerReload(false)}
          queryParams={queryParams}
        />

        {!isModalVisible && (
          <Fab
            style={{ bottom: 0, right: 0 }}
            mainButtonStyles={{
              backgroundColor: getNavbarColor(),
              color: getNavbarTextColor(),
            }}
            icon={<PlusOutlined />}
            alwaysShowTitle={false}
            onClick={showModal}
            text="Add farm"
          />
        )}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  locationsState: state.locations,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getLocationsConnnect: getLocations,
    },
    dispatch,
  );
};

export const Farms = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedFarms));

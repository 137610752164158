import {
  Button,
  Col,
  Descriptions,
  Empty,
  Form,
  List,
  Modal,
  notification,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { functionalColor } from '../../colors';
import { RootState } from '../../duck';
import {
  addSensorToGateway,
  addSensorToGatewayAndPond,
  removeSensorFromGateway,
  synchronizeGateway,
} from '../../duck/modules/gateways';
import {
  addSensorToPond,
  refreshPond,
  removeSensorFromPond,
} from '../../duck/modules/ponds';
import { getOrganisationSensors } from '../../duck/modules/sensing';
import { PondModel } from '../../model/domain';
import { GatewayStateField, MqttConnectionStatus } from '../../model/enums';
import { getSensorNameToType } from '../../util';
import { usePrevious } from '../_util/hook';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import DescriptionsItem from 'antd/lib/descriptions/Item';

const { TabPane } = Tabs;
const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pond: PondModel;
  };

export const UnconnectedPondSensorsList: React.FC<Props> = ({
  pond,
  getOrganisationSensorsConnect,
  refreshPondConnect,
  synchronizeGatewayConnect,
  addSensorToGatewayAndPondConnect,
  removeSensorFromGatewayConnect,
  removeSensorFromPondConnect,
  organisationSensors,
  gatewayAddSensorState,
  gatewayRemoveSensorState,
  pondAddSensorState,
  pondRemoveSensorState,
}) => {
  const [showAddSensorModal, setShowAddSensorModal] = React.useState(false);
  const [addSensorForm] = Form.useForm();

  const prev = usePrevious({
    gatewayAddSensorState,
    gatewayRemoveSensorState,
    pondAddSensorState,
    pondRemoveSensorState,
  });

  React.useEffect(() => {
    getOrganisationSensorsConnect(pond.organisation.id);
  }, []);

  React.useEffect(() => {
    if (
      prev?.gatewayAddSensorState.loading &&
      !gatewayAddSensorState.loading &&
      !gatewayAddSensorState.error
    ) {
      notification.success({
        message: 'Sensor added to Gateway',
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [gatewayAddSensorState]);

  React.useEffect(() => {
    if (
      prev?.gatewayAddSensorState.loading &&
      !gatewayAddSensorState.loading &&
      gatewayAddSensorState.error
    ) {
      notification.error({
        message: 'Error while adding sensor to Gateway',
        description: gatewayAddSensorState.error.message,
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [gatewayAddSensorState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemoveSensorState.loading &&
      !gatewayRemoveSensorState.loading &&
      !gatewayRemoveSensorState.error
    ) {
      notification.success({
        message: 'Sensor removed from Gateway',
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [gatewayRemoveSensorState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemoveSensorState.loading &&
      !gatewayRemoveSensorState.loading &&
      gatewayRemoveSensorState.error
    ) {
      notification.error({
        message: 'Error while removing sensor from Gateway',
        description: gatewayRemoveSensorState.error.message,
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [gatewayRemoveSensorState]);

  React.useEffect(() => {
    if (
      prev?.pondAddSensorState.loading &&
      !pondAddSensorState.loading &&
      !pondAddSensorState.error
    ) {
      setShowAddSensorModal(false);
      addSensorForm.resetFields();
      notification.success({
        message: 'Sensor added to Pond',
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [pondAddSensorState]);

  React.useEffect(() => {
    if (
      prev?.pondAddSensorState.loading &&
      !pondAddSensorState.loading &&
      pondAddSensorState.error
    ) {
      notification.error({
        message: 'Error while adding sensor to Pond',
        description: pondAddSensorState.error.message,
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [pondAddSensorState]);

  React.useEffect(() => {
    if (
      prev?.pondRemoveSensorState.loading &&
      !pondRemoveSensorState.loading &&
      !pondRemoveSensorState.error
    ) {
      notification.success({
        message: 'Sensor removed from Pond',
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [pondRemoveSensorState]);

  React.useEffect(() => {
    if (
      prev?.pondRemoveSensorState.loading &&
      !pondRemoveSensorState.loading &&
      pondRemoveSensorState.error
    ) {
      notification.error({
        message: 'Error while removing sensor from Pond',
        description: pondRemoveSensorState.error.message,
      });
      getOrganisationSensorsConnect(pond.organisation.id);
    }
  }, [pondRemoveSensorState]);

  const renderSensorsListContent = () => {
    if (pond.gateway) {
      const gatewayId = pond.gateway.id;
      const inSync = pond.gateway.sensors.inSync;
      const isOnline =
        pond.gateway.mqttConnectionStatus === MqttConnectionStatus.CONNECTED;

      return (
        <List
          bordered
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          loading={!inSync}
          dataSource={
            pond.sensors.sort((s1, s2) => s1.id.localeCompare(s2.id)) || []
          }
          locale={{
            emptyText: <Typography>No sensors</Typography>,
          }}
          header={
            <Row justify={'space-between'}>
              <Col>
                <Typography.Text strong>Sensors</Typography.Text>
              </Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  disabled={!isOnline}
                  onClick={() => setShowAddSensorModal(true)}
                >
                  {'Add sensor'}
                </Button>
              </Col>
            </Row>
          }
          footer={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.SENSOR],
                  });
                  refreshPondConnect(pond.id);
                }}
              >
                Force Sync
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  refreshPondConnect(pond.id);
                }}
              >
                Refresh
              </Button>
            </Space>
          }
          renderItem={(sensor) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <Descriptions bordered column={1}>
                    {sensor.name && (
                      <Descriptions.Item
                        label={
                          <Space>
                            <Typography.Text>{'Name'}</Typography.Text>
                            {sensor.name && sensor.name.inSync && (
                              <Tooltip title={'Synchronized'}>
                                <InfoCircleOutlined
                                  style={{ color: 'rgba(0,0,0,.45)' }}
                                />
                              </Tooltip>
                            )}
                            {sensor.name && !sensor.name.inSync && (
                              <Tooltip title={`Syncing...`}>
                                <WarningOutlined
                                  style={{
                                    color: functionalColor.warning,
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Space>
                        }
                      >
                        <Typography.Text strong>
                          {sensor.name ? sensor.name.actual : ''}
                        </Typography.Text>
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label={'ID'}>
                      <Link to={`/sensors/${sensor.id}`}>{sensor.id}</Link>
                    </Descriptions.Item>

                    <Descriptions.Item label={'Type'}>
                      {getSensorNameToType(sensor.type)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Space>
                          <Typography.Text>{'Socket'}</Typography.Text>
                          {sensor.deviceSocket.inSync ? (
                            <Tooltip title={'Synchronized'}>
                              <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={`Syncing...`}>
                              <WarningOutlined
                                style={{
                                  color: functionalColor.warning,
                                }}
                              />
                            </Tooltip>
                          )}
                        </Space>
                      }
                    >
                      <Typography.Text>
                        {sensor.deviceSocket.inSync
                          ? sensor.deviceSocket.actual
                          : sensor.deviceSocket.requested}
                      </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Space>
                          <Typography.Text>{'Mode'}</Typography.Text>
                          {sensor.deviceSocket.inSync ? (
                            <Tooltip title={'Synchronized'}>
                              <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={`Syncing...`}>
                              <WarningOutlined
                                style={{
                                  color: functionalColor.warning,
                                }}
                              />
                            </Tooltip>
                          )}
                        </Space>
                      }
                    >
                      <Typography.Text>
                        {sensor.mode.inSync
                          ? sensor.mode.actual
                          : sensor.mode.requested}
                      </Typography.Text>
                    </Descriptions.Item>
                    <DescriptionsItem label="Actions">
                      <Space>
                        {isOnline ? (
                          <Popconfirm
                            title={
                              'Are you sure to remove this sensor from the pond?'
                            }
                            onConfirm={() => {
                              removeSensorFromPondConnect(pond.id, sensor.id);
                              removeSensorFromGatewayConnect(
                                gatewayId,
                                sensor.id,
                                pond.id,
                              );
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              loading={
                                sensor.id === pondRemoveSensorState.sensorId ||
                                sensor.id == gatewayRemoveSensorState.sensorId
                              }
                              danger
                              size="small"
                              disabled={!isOnline}
                            >
                              Remove
                            </Button>
                          </Popconfirm>
                        ) : (
                          <Button danger size="small" disabled>
                            Remove
                          </Button>
                        )}
                      </Space>
                    </DescriptionsItem>
                  </Descriptions>
                }
              />
            </List.Item>
          )}
        />
      );
    } else {
      return (
        <Typography.Title level={4}>
          You need an gateway in place to change sensors!
        </Typography.Title>
      );
    }
  };

  const renderAddSensorModal = () => {
    return (
      <Modal
        visible={showAddSensorModal}
        onOk={() => setShowAddSensorModal(false)}
        onCancel={() => setShowAddSensorModal(false)}
        footer={null}
        title={'Add sensor'}
      >
        <Form
          form={addSensorForm}
          layout="horizontal"
          onFinish={(values: Store) => {
            const { sensorId } = values;
            if (pond.gateway) {
              addSensorToGatewayAndPondConnect(
                sensorId,
                pond.gateway.id,
                pond.id,
              );
            }
          }}
        >
          <Form.Item label="Sensor" name="sensorId">
            <Select
              placeholder="Select sensor from organisations sensors"
              autoFocus
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'No unused sensors found.'}
                >
                  <Link to={`/organisations/${pond.organisation.id}`}>
                    <Button type="link">Show organisation</Button>
                  </Link>
                </Empty>
              }
            >
              {/* TODO: Show sensors from gateway */}
              {organisationSensors
                .filter((s) => !s.gateway || s.gateway.id === pond.gateway?.id)
                .map((sensor) => (
                  <Option key={sensor.id} value={sensor.id}>
                    {sensor.name && sensor.name.actual
                      ? `${sensor.name.actual} (${getSensorNameToType(
                          sensor.type,
                        )})`
                      : `${sensor.id} (${getSensorNameToType(sensor.type)})`}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  gatewayAddSensorState.loading || pondAddSensorState.loading
                }
              >
                Add sensor
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  if (pond.gateway) {
    return (
      <div>
        {renderSensorsListContent()}
        {renderAddSensorModal()}
      </div>
    );
  }

  return (
    <div>
      <Result status="info" title="Pond has no Gateway!" />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  organisationSensorsLoading: state.sensing.organisationSensors.loading,
  organisationSensors: state.sensing.organisationSensors.sensors,
  refreshState: state.ponds.detail.refresh,
  gatewayAddSensorState: state.gateways.addSensor,
  gatewayRemoveSensorState: state.gateways.removeSensor,
  pondAddSensorState: state.ponds.addSensor,
  pondRemoveSensorState: state.ponds.removeSensor,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationSensorsConnect: getOrganisationSensors,
      refreshPondConnect: refreshPond,

      addSensorToPondConnect: addSensorToPond,
      addSensorToGatewayConnect: addSensorToGateway,

      removeSensorFromPondConnect: removeSensorFromPond,
      removeSensorFromGatewayConnect: removeSensorFromGateway,

      synchronizeGatewayConnect: synchronizeGateway,
      addSensorToGatewayAndPondConnect: addSensorToGatewayAndPond,
    },
    dispatch,
  );
};

export const PondSensorsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondSensorsList);

import {
  Badge,
  Col,
  Empty,
  Pagination,
  Row,
  Space,
  Statistic,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import moment from 'moment';
import { SimpleSensorCalibrationLogModel } from '../../model/simple';
import { Link } from 'react-router-dom';
import { CalibrationFailure, CalibrationState } from '../../model/enums';
import { functionalColor } from '../../colors';
import { CalibrationStateBadge } from './calibrationStateBadge';

const { Column, ColumnGroup } = Table;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    logs: SimpleSensorCalibrationLogModel[];
    loading?: boolean;
    pagination?: any;
  };

const UnconnectedSensorCalibrationLogsTable: React.FC<Props> = ({
  logs,
  loading,
  pagination,
}) => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={logs}
          pagination={false}
          scroll={{ x: true }}
          bordered
        >
          <Column
            align="center"
            title="Calibration"
            render={(data: SimpleSensorCalibrationLogModel) => {
              return (
                <Tooltip
                  placement="left"
                  title={moment(data.timestamp).fromNow()}
                >
                  <Link to={`/calibrationLogs/${data.id}`}>
                    {moment(data.timestamp).format('LLLL')}
                  </Link>
                </Tooltip>
              );
            }}
          />

          <ColumnGroup align="center" title="Result">
            <Column
              align="center"
              title="State"
              render={(data: SimpleSensorCalibrationLogModel) => {
                return <CalibrationStateBadge state={data.result.state} />;
              }}
            />
            <Column
              align="center"
              title="Step"
              render={(data: SimpleSensorCalibrationLogModel) => {
                return <Typography>{data.result.step}</Typography>;
              }}
            />
            <Column
              align="center"
              title="Reason"
              render={(data: SimpleSensorCalibrationLogModel) => {
                if (data.result.failure === CalibrationFailure.NOT_SET) {
                  return <Typography>{'-'}</Typography>;
                }
                return <Typography>{data.result.failure}</Typography>;
              }}
            />
            <Column
              align="center"
              title="New Param 1"
              render={(data: SimpleSensorCalibrationLogModel) => {
                if (
                  data.result.newParam1 === null ||
                  data.result.newParam2 === undefined
                ) {
                  return <Typography>{'-'}</Typography>;
                }
                return <Typography>{data.result.newParam1}</Typography>;
              }}
            />
            <Column
              align="center"
              title="New Parm 2"
              render={(data: SimpleSensorCalibrationLogModel) => {
                if (
                  data.result.newParam2 === null ||
                  data.result.newParam2 === undefined
                ) {
                  return <Typography>{'-'}</Typography>;
                }
                return <Typography>{data.result.newParam2}</Typography>;
              }}
            />
          </ColumnGroup>
        </Table>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="center">
          {pagination}
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const SensorCalibrationLogsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorCalibrationLogsTable);

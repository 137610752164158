import { useEffect, useRef, useState } from 'react';

export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useForceUpdate = () => useState()[1];

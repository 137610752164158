import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import {
  LogoutOutlined,
  UserOutlined,
  BankOutlined,
  ApartmentOutlined,
  BorderOutlined,
  GatewayOutlined,
  NotificationOutlined,
  Loading3QuartersOutlined,
  FilterOutlined,
  WifiOutlined,
  BookOutlined,
  MessageOutlined,
  CommentOutlined,
  SlidersOutlined,
  WarningOutlined,
  RiseOutlined,
} from '@ant-design/icons';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onClick?: (event: any) => void;
  };

const UnconnectedNavigationMenu: React.FC<Props> = ({
  isAuthenticated,
  onClick,
}) => {
  const items = [
    {
      key: 'accounts',
      icon: <UserOutlined />,
      title: 'Accounts',
      navTo: '/accounts',
    },
    {
      key: 'organisations',
      icon: <BankOutlined />,
      title: 'Organisations',
      navTo: '/organisations',
    },
    {
      key: 'farms',
      icon: <ApartmentOutlined />,
      title: 'Farms',
      navTo: '/farms',
    },
    {
      key: 'ponds',
      icon: <BorderOutlined />,
      title: 'Ponds',
      navTo: '/ponds',
    },

    {
      key: 'shrimp-size',
      icon: <RiseOutlined />,
      title: 'Shrimp Size',
      navTo: '/shrimp-size',
    },
    {
      key: 'gateways',
      icon: <GatewayOutlined />,
      title: 'Gateways',
      navTo: '/gateways',
    },
    {
      key: 'notifications',
      icon: <NotificationOutlined />,
      title: 'Notifications',
      navTo: '/notifications',
    },
    {
      key: 'cycles',
      icon: <Loading3QuartersOutlined />,
      title: 'Cycles',
      navTo: '/cycles',
    },
    {
      key: 'feedTables',
      icon: <FilterOutlined />,
      title: 'Feed Tables',
      navTo: '/feedTables',
    },
    {
      key: 'catalog',
      icon: <BookOutlined />,
      title: 'Product Catalog',
      navTo: '/catalog',
    },
    {
      key: 'smartDevices',
      icon: <WifiOutlined />,
      title: 'Smart Devices',
      navTo: '/smartDevices',
    },
    {
      key: 'announcement',
      icon: <MessageOutlined />,
      title: 'Announcements',
      navTo: '/announcements',
    },
    {
      key: 'popup',
      icon: <CommentOutlined />,
      title: 'Pop Ups',
      navTo: '/popUps',
    },
    {
      key: 'sensorCapsValues',
      icon: <SlidersOutlined />,
      title: 'Sensor Caps',
      navTo: '/sensorCaps',
    },
    {
      key: 'ticket',
      icon: <WarningOutlined />,
      title: 'Tickets',
      navTo: '/tickets',
    },
    {
      key: 'profile',
      icon: <LogoutOutlined />,
      title: 'Profile',
      navTo: '/profile',
    },
  ];

  const [selectedKey, setSelectedKey] = React.useState(items[0].key);
  const changeSelectedKey = (event: any) => {
    const key = event.key;
    if (selectedKey == event.key) {
      window.location.reload();
    }
    setSelectedKey(key);
    if (onClick) {
      onClick(event);
    }
  };

  if (!isAuthenticated) {
    return <div />;
  }

  const navbarColor = getNavbarColor();
  const textColor = getNavbarTextColor();

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[]}
      style={{ backgroundColor: navbarColor }}
      openKeys={items.flatMap((item) => item.key)}
      selectable={false}
      expandIcon={<div />}
      triggerSubMenuAction={'click'}
    >
      {items.map((item) => {
        return (
          <Menu.Item
            onClick={changeSelectedKey}
            key={item.key}
            icon={item.icon}
            style={{ color: textColor }}
          >
            <NavLink
              style={{ color: textColor, textDecoration: 'none' }}
              to={item.navTo}
            >
              {item.title}
            </NavLink>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const NavigationMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedNavigationMenu);

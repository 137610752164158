import React from 'react';
import { Layout, Typography } from 'antd';
import './sideBar.css';
import {
  getNavbarColor,
  getNavbarTextColor,
  getEnvironment,
} from '../../../util';
const SideBar = ({ menu }: any) => {
  return (
    <Layout.Sider
      theme="dark"
      breakpoint="lg"
      collapsedWidth="0"
      trigger={null}
      style={{ backgroundColor: getNavbarColor(), textAlign: 'center' }}
    >
      <div className="logo" style={{ backgroundColor: getNavbarTextColor() }}>
        <Typography style={{ fontSize: 17, color: getNavbarColor() }}>
          {getEnvironment()}
        </Typography>
      </div>
      {menu}
    </Layout.Sider>
  );
};

export default SideBar;

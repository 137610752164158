import React from 'react';
import {
  Col,
  Pagination,
  Result,
  Row,
  Typography,
  Form,
  Checkbox,
  Select,
  Tag,
  Descriptions,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { countPonds, getPonds } from '../../duck/modules/ponds';
import { PondsSensorDataTable } from './pondsSensorDataTable';
import { MqttConnectionStatus } from '../../model/enums';
import { functionalColor } from '../../colors';
import { boolFromStringOtherwiseNull } from '../../util';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      includeDisabled?: string;
      controllerStatuses?: MqttConnectionStatus[];
      isActive?: string | string[];
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedPondsTable: React.FC<Props> = ({
  ponds,
  loading,
  error,
  total,
  getPondsConnect,
  queryParams,
  reload,
  onReloaded,
  countsState,
  countPondsConnect,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);
  const [includeDisabled, setIncludeDisabled] = React.useState(
    queryParams?.includeDisabled !== 'true' ? false : true,
  );
  const [controllerStatuses, setControllerStatuses] = React.useState(
    queryParams?.controllerStatuses || [],
  );

  const [isActive, setIsActive] = React.useState<boolean[]>(
    typeof queryParams?.isActive === "string" ? [boolFromStringOtherwiseNull(queryParams?.isActive)] :
      typeof queryParams?.isActive === "object" && queryParams?.isActive instanceof Array ? queryParams?.isActive.map(boolFromStringOtherwiseNull) : []
  );

  React.useEffect(() => {
    if (typeof queryParams?.isActive === "string") {
      setIsActive([boolFromStringOtherwiseNull(queryParams?.isActive)]);
    } else if (typeof queryParams?.isActive === "object" && queryParams?.isActive instanceof Array) {
      setIsActive(queryParams?.isActive.map(boolFromStringOtherwiseNull));
    }
  }, [queryParams?.isActive]);


  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  React.useEffect(() => {
    getPondsConnect({
      limit,
      offset,
      includeDisabled,
      controllerStatuses,
      isActive
    });
    countPondsConnect();
    onReloaded();
  }, [offset, limit, reload, controllerStatuses, isActive, includeDisabled]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load ponds"
        subTitle={error.message}
      />
    );
  }

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderFilter = () => {
    return (
      <div>
        <Form
          layout="inline"
          style={{ width: '100%' }}
          initialValues={{
            includeDisabled,
            isActive
          }}
        >
          <Form.Item name="includeDisabled" valuePropName="includeDisabled">
            <Checkbox
              onChange={(e) => {
                const checked = new Boolean(e.target.checked);
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('includeDisabled', checked.toString());
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setIncludeDisabled(checked.valueOf());
              }}
              checked={includeDisabled}
            >
              {'Include disabled'}
            </Checkbox>
          </Form.Item>

          <Form.Item name="isActive">
            <Select
              mode="multiple"
              placeholder="Filter by BI"
              value={isActive}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('isActive');
                value.forEach((d) =>
                  searchParams.append('isActive', d.toString()),
                );
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setIsActive(data);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            >
              <Select.Option value={true}>
                <Tag color={functionalColor.success}>{'Active'}</Tag>
              </Select.Option>
              <Select.Option value={false}>
                <Tag color={functionalColor.error}>{'Inactive'}</Tag>
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="controllerStatuses">
            <Select
              mode="tags"
              placeholder="Filter by MQTT status"
              value={controllerStatuses}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('controllerStatuses');
                value.forEach((d) =>
                  searchParams.append('controllerStatuses', d),
                );
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setControllerStatuses(data);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            >
              <Select.Option value={MqttConnectionStatus.CONNECTED}>
                <Tag color={functionalColor.success}>{'Online'}</Tag>
              </Select.Option>
              <Select.Option value={MqttConnectionStatus.NOT_CONNECTED}>
                <Tag color={functionalColor.error}>{'Offline'}</Tag>
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <br />
      </div>
    );
  };

  const renderResultInfo = () => {
    return (
      <div>
        <Descriptions column={1} layout='horizontal' >

          <Descriptions.Item label="Query results">
            {total}
          </Descriptions.Item>
          <Descriptions.Item label="Active ponds">
            {countsState?.active}
          </Descriptions.Item>

          <Descriptions.Item label="Inactive ponds">
            {countsState?.inactive}
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            {countsState?.all}
          </Descriptions.Item>

        </Descriptions>
        <br />
      </div>
    );


  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load ponds"
        subTitle={error.message}
      />
    );
  }

  const renderTable = () => {
    return (
      <PondsSensorDataTable
        pagination={false}
        loading={loading}
        showStatus
        showOrganisation
        showFarm
        showName
        showCycleDoC
        showAeration
        ponds={ponds}
      />
    );
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  return (
    <Col>
      {renderFilter()}
      {renderResultInfo()}
      {renderTable()}
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  ponds: state.ponds.list.ponds,
  loading: state.ponds.list.loading,
  error: state.ponds.list.error,
  total: state.ponds.list.total,
  countsState: state.ponds.counts,

});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getPondsConnect: getPonds,
      countPondsConnect: countPonds,
    },
    dispatch,
  );
};

export const PondsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondsTable);

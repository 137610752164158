import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig, getMultipartConfig } from './util/apiConfig';
import { CreateSensorCapCmd, UpdateSensorCapCmd } from '../model/cmds';
import { SensorCapModel } from '../model/domain';
import { ListSensorCapsQuery, ListSensorCapsQueryModel } from '../model/querys';
import { UploadFile } from 'antd/lib/upload/interface';
import { SimpleSensorCapModel } from '../model/simple';

export class SensorCapApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createSensorCap = this.createSensorCap.bind(this);
    this.getSensorCap = this.getSensorCap.bind(this);
    this.listSensorCaps = this.listSensorCaps.bind(this);
    this.updateSensorCap = this.updateSensorCap.bind(this);
    this.deleteSensorCap = this.deleteSensorCap.bind(this);
  }

  public createSensorCap(cmd: CreateSensorCapCmd): Promise<SensorCapModel> {
    return this.post<
      SensorCapModel,
      CreateSensorCapCmd,
      AxiosResponse<SensorCapModel>
    >(`/v1/sensorCapsValues`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public importSensorCaps(
    formData: FormData,
  ): Promise<ListSensorCapsQueryModel> {
    return this.post<any, any, AxiosResponse<ListSensorCapsQueryModel>>(
      `/v1/sensorCapsValues/upload`,
      formData,
      getMultipartConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getSensorCap(sensorCapId: string): Promise<SensorCapModel> {
    return this.get<SensorCapModel, AxiosResponse<SensorCapModel>>(
      `/v1/sensorCapsValues/${sensorCapId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listSensorCaps(
    cmd?: ListSensorCapsQuery,
  ): Promise<ListSensorCapsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListSensorCapsQueryModel,
      AxiosResponse<ListSensorCapsQueryModel>
    >('/v1/sensorCapsValues', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateSensorCap(
    sensorCapId: string,
    cmd: UpdateSensorCapCmd,
  ): Promise<SensorCapModel> {
    return this.patch<
      SensorCapModel,
      UpdateSensorCapCmd,
      AxiosResponse<SensorCapModel>
    >(`/v1/sensorCapsValues/${sensorCapId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteSensorCap(sensorCapId: string): Promise<SensorCapModel> {
    return this.delete<SensorCapModel, AxiosResponse<SensorCapModel>>(
      `/v1/sensorCapsValues/${sensorCapId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const sensorCapApi = new SensorCapApi();

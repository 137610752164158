import { Button, Form, notification, Select, Space, DatePicker } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { createController } from '../../duck/modules/controllers';
import { ControllerModel } from '../../model/domain';
import {
  ControllerProductVersion,
  ControllerRelayboardVersion,
  ControllerUsage,
} from '../../model/enums';
import moment from 'moment';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (controller: ControllerModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateGatewayForm: React.FC<Props> = ({
  controllerCreateState,
  createControllerConnect,
  onCreated,
  onError,
}) => {
  React.useEffect(() => {
    if (controllerCreateState.controller) {
      notification.success({
        message: 'Controller created',
      });
    }
    if (controllerCreateState.controller && onCreated) {
      onCreated(controllerCreateState.controller);
    }
  }, [controllerCreateState.controller]);

  React.useEffect(() => {
    if (controllerCreateState.error) {
      notification.error({
        message: 'Controller creation error',
        description: controllerCreateState.error?.message,
      });
    }
    if (controllerCreateState.error && onError) {
      onError(controllerCreateState.error);
    }
  }, [controllerCreateState.error]);

  const [form] = Form.useForm();
  const [gatewayType, setGatewayType] = React.useState('CONTROLLER');

  const onCreateGateway = (values: Store) => {
    if (gatewayType === 'CONTROLLER') {
      const { productVersion, relayboardVersion, usage, dateOfInstallation, endOfWarranty } = values;

      let dateOfInstallationMoment = null;
      if (dateOfInstallation) {
        dateOfInstallationMoment = moment(dateOfInstallation).format('YYYY-MM-DD');
      }

      let endOfWarrantyMoment = null;
      if (endOfWarranty) {
        endOfWarrantyMoment = moment(endOfWarranty).format('YYYY-MM-DD');
      }

      createControllerConnect({
        productVersion: productVersion,
        relayboardVersion: relayboardVersion,
        usage: usage,
        dateOfInstallation: dateOfInstallationMoment,
        endOfWarranty: endOfWarrantyMoment,
      });
    }
  };

  const onReset = () => {
    onGatewayTypeChanged('');
    form.resetFields();
  };

  const onGatewayTypeChanged = (gatewayType: string) => {
    setGatewayType(gatewayType);
    form.setFieldsValue({ accountId: gatewayType });
  };

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onCreateGateway}>
        <Form.Item label="Gateway Type" rules={[{ required: true }]}>
          <Select
            placeholder="Select Type"
            onChange={onGatewayTypeChanged}
            value={gatewayType}
          >
            <Option value={'CONTROLLER'}>{'Controller'}</Option>
          </Select>
        </Form.Item>

        {gatewayType === 'CONTROLLER' && (
          <Form.Item
            label="Product Version"
            name="productVersion"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select Product Version">
              <Option value={ControllerProductVersion.V0}>{'V0'}</Option>
              <Option value={ControllerProductVersion.V1}>{'V1'}</Option>
              <Option value={ControllerProductVersion.V2}>{'V2'}</Option>
              <Option value={ControllerProductVersion.V3}>{'V3'}</Option>
            </Select>
          </Form.Item>
        )}

        {gatewayType === 'CONTROLLER' && (
          <Form.Item
            label="Relayboard Version"
            name="relayboardVersion"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select Relayboard Version">
              <Option value={ControllerRelayboardVersion.V1}>{'V1'}</Option>
              <Option value={ControllerRelayboardVersion.V3}>{'V3'}</Option>
              <Option value={ControllerRelayboardVersion.V3_LATCHING}>
                {'V3_LATCHING'}
              </Option>
            </Select>
          </Form.Item>
        )}


        {gatewayType === 'CONTROLLER' && (
          <Form.Item
            label="Usage"
            name="usage"
            rules={[{ required: false }]}
          >
            <Select placeholder="Select Usage">
              <Option value={ControllerUsage.SMART_SHRIMP_FARMING}>{'SMART_SHRIMP_FARMING'}</Option>
              <Option value={ControllerUsage.SMART_FISH_FARMING}>{'SMART_FISH_FARMING'}</Option>
              <Option value={ControllerUsage.INDUSTRIAL}>{'INDUSTRIAL'}</Option>
              <Option value={ControllerUsage.RESEARCH_AND_DEVELOPMENT}>{'RESEARCH_AND_DEVELOPMENT'}</Option>
            </Select>
          </Form.Item>
        )}


        {gatewayType === 'CONTROLLER' && (
          <Form.Item
            label="Date of Installation"
            name="dateOfInstallation"
            rules={[{ required: false }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD / MM / YYYY'}
              placeholder="Date of Installation (optional)"
            />
          </Form.Item>
        )}

        {gatewayType === 'CONTROLLER' && (
          <Form.Item
            label="End of Warranty"
            name="endOfWarranty"
            rules={[{ required: false }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD / MM / YYYY'}
              placeholder="End of Warranty (optional)"
            />
          </Form.Item>
        )}




        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={controllerCreateState.loading}
            >
              Create Gateway
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  controllerCreateState: state.controllers.create,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createControllerConnect: createController,
    },
    dispatch,
  );
};

export const CreateGatewayForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateGatewayForm);

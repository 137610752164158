import {
  CREATE_MESSAGE,
  CREATE_MESSAGE_FAILED,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_SUCCESSS,
  DELETE_MESSAGE,
  DELETE_MESSAGE_FAILED,
  DELETE_MESSAGE_SUCCESS,
  FETCH_MESSAGE,
  FETCH_MESSAGES,
  FETCH_MESSAGES_FAILED,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGE_FAILED,
  FETCH_MESSAGE_SUCCESS,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_FAILED,
  UPDATE_MESSAGE_SUCCESS,
} from '../../constants';
import { MessageModel } from '../../model/domain';
import { ListMessagesQueryModel, ListMessagesQuery } from '../../model/querys';
import { SimpleMessageModel } from '../../model/simple';

export interface CreateMessageAction {
  type: CREATE_MESSAGE;
}

export interface CreateMessageSuccessAction {
  type: CREATE_MESSAGE_SUCCESSS;
  payload: MessageModel;
}

export interface CreateMessageFailedAction {
  type: CREATE_MESSAGE_FAILED;
  payload: Error;
}

export interface FetchMessageAction {
  type: FETCH_MESSAGE;
}

export interface FetchMessageSuccessAction {
  type: FETCH_MESSAGE_SUCCESS;
  payload: MessageModel;
}

export interface FetchMessageFailedAction {
  type: FETCH_MESSAGE_FAILED;
  payload: Error;
}

export interface FetchMessagesAction {
  type: FETCH_MESSAGES;
  payload: ListMessagesQuery;
}

export interface FetchMessagesSuccessAction {
  type: FETCH_MESSAGES_SUCCESS;
  payload: {
    query: ListMessagesQueryModel;
    messages: SimpleMessageModel[];
  };
}

export interface FetchMessagesFailedAction {
  type: FETCH_MESSAGES_FAILED;
  payload: Error;
}

export interface UpdateMessageAction {
  type: UPDATE_MESSAGE;
}

export interface UpdateMessageSuccessAction {
  type: UPDATE_MESSAGE_SUCCESS;
  payload: MessageModel;
}

export interface UpdateMessageFailedAction {
  type: UPDATE_MESSAGE_FAILED;
  payload: Error;
}

export interface DeleteMessageAction {
  type: DELETE_MESSAGE;
}

export interface DeleteMessageSuccessAction {
  type: DELETE_MESSAGE_SUCCESS;
  payload: MessageModel;
}

export interface DeleteMessageFailedAction {
  type: DELETE_MESSAGE_FAILED;
  payload: Error;
}

export type MessageCreateAction =
  | CreateMessageAction
  | CreateMessageSuccessAction
  | CreateMessageFailedAction;
export type MessageUpdateAction =
  | UpdateMessageAction
  | UpdateMessageSuccessAction
  | UpdateMessageFailedAction;
export type MessageFetchAction =
  | FetchMessageAction
  | FetchMessageSuccessAction
  | FetchMessageFailedAction;
export type MessagesFetchAction =
  | FetchMessagesAction
  | FetchMessagesSuccessAction
  | FetchMessagesFailedAction;
export type MessageDeleteAction =
  | DeleteMessageAction
  | DeleteMessageSuccessAction
  | DeleteMessageFailedAction;
export type MessagesAction =
  | MessageCreateAction
  | MessageUpdateAction
  | MessageDeleteAction
  | MessageFetchAction
  | MessagesFetchAction;

export function messageCreate(): CreateMessageAction {
  return {
    type: CREATE_MESSAGE,
  };
}

export function messageCreated(
  message: MessageModel,
): CreateMessageSuccessAction {
  return {
    type: CREATE_MESSAGE_SUCCESS,
    payload: message,
  };
}

export function messageCreateFailed(error: Error): CreateMessageFailedAction {
  return {
    type: CREATE_MESSAGE_FAILED,
    payload: error,
  };
}

export function messageFetch(): FetchMessageAction {
  return {
    type: FETCH_MESSAGE,
  };
}

export function messageFetched(
  message: MessageModel,
): FetchMessageSuccessAction {
  return {
    type: FETCH_MESSAGE_SUCCESS,
    payload: message,
  };
}

export function messageFetchFailed(error: Error): FetchMessageFailedAction {
  return {
    type: FETCH_MESSAGE_FAILED,
    payload: error,
  };
}

export function messagesFetch(query: ListMessagesQuery): FetchMessagesAction {
  return {
    type: FETCH_MESSAGES,
    payload: query,
  };
}

export function messagesFetched(
  query: ListMessagesQueryModel,
  messages: SimpleMessageModel[],
): FetchMessagesSuccessAction {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: { query, messages },
  };
}

export function messagesFetchFailed(error: Error): FetchMessageFailedAction {
  return {
    type: FETCH_MESSAGE_FAILED,
    payload: error,
  };
}

export function messageUpdate(): UpdateMessageAction {
  return {
    type: UPDATE_MESSAGE,
  };
}

export function messageUpdated(
  message: MessageModel,
): UpdateMessageSuccessAction {
  return {
    type: UPDATE_MESSAGE_SUCCESS,
    payload: message,
  };
}

export function messageUpdateFailed(error: Error): UpdateMessageFailedAction {
  return {
    type: UPDATE_MESSAGE_FAILED,
    payload: error,
  };
}

export function messageDelete(): DeleteMessageAction {
  return {
    type: DELETE_MESSAGE,
  };
}

export function messageDeleted(
  message: MessageModel,
): DeleteMessageSuccessAction {
  return {
    type: DELETE_MESSAGE_SUCCESS,
    payload: message,
  };
}

export function messageDeleteFailed(error: Error): DeleteMessageFailedAction {
  return {
    type: DELETE_MESSAGE_FAILED,
    payload: error,
  };
}

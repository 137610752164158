import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { feedApi } from '../../api/feedApi';
import {
  CREATE_FEED,
  CREATE_FEED_FAILED,
  CREATE_FEED_SUCCESS,
  FETCH_FEED,
  FETCH_FEED_FAILED,
  FETCH_FEED_SUCCESS,
  FETCH_FEEDS,
  FETCH_FEEDS_FAILED,
  FETCH_FEEDS_SUCCESS,
  UPDATE_FEED,
  UPDATE_FEED_FAILED,
  UPDATE_FEED_SUCCESS,
} from '../../constants';
import { CreateFeedCmd, UpdateFeedCmd } from '../../model/cmds';
import { ListFeedsQuery, ListFeedsQueryModel } from '../../model/querys';
import { FeedsState } from '../../model/state';
import { feedDelete, feedDeleted, feedDeleteFailed } from '../actions';
import {
  DELETE_FEED,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAILED,
} from '../../constants';
import {
  feedCreate,
  feedCreated,
  feedCreateFailed,
  feedFetch,
  feedFetched,
  feedFetchFailed,
  FeedsAction,
  feedsFetch,
  feedsFetched,
  feedsFetchFailed,
  feedUpdate,
  feedUpdated,
  feedUpdateFailed,
} from '../actions';

export function createFeed(cmd: CreateFeedCmd) {
  return async (dispatch: Dispatch<FeedsAction, {}, any>) => {
    try {
      dispatch(feedCreate());
      const feed = await feedApi.createFeed(cmd);
      dispatch(feedCreated(feed));
    } catch (e) {
      dispatch(feedCreateFailed(e));
    }
  };
}

export function getFeed(id: string) {
  return async (dispatch: Dispatch<FeedsAction, {}, any>) => {
    try {
      dispatch(feedFetch());
      const feed = await feedApi.fetchFeed(id);
      dispatch(feedFetched(feed));
    } catch (e) {
      dispatch(feedFetchFailed(e));
    }
  };
}

export function getFeeds(query: ListFeedsQuery) {
  return async (dispatch: Dispatch<FeedsAction, {}, any>) => {
    try {
      dispatch(feedsFetch(query));
      const queryModel: ListFeedsQueryModel = await feedApi.listFeeds(query);
      const feeds = queryModel.feeds;

      dispatch(feedsFetched(queryModel, feeds));
    } catch (e) {
      dispatch(feedsFetchFailed(e));
    }
  };
}

export function deleteFeed(id: string) {
  return async (dispatch: Dispatch<FeedsAction, {}, any>) => {
    try {
      dispatch(feedDelete(id));
      await feedApi.deleteFeed(id);
      dispatch(feedDeleted(id));
    } catch (e) {
      dispatch(feedDeleteFailed(e));
    }
  };
}

export function updateFeed(id: string, cmd: UpdateFeedCmd) {
  return async (dispatch: Dispatch<FeedsAction, {}, any>) => {
    try {
      dispatch(feedUpdate());
      const response = await feedApi.updateFeed(id, cmd);
      dispatch(feedUpdated(response));
      dispatch(getFeed(id));
    } catch (e) {
      dispatch(feedUpdateFailed(e));
    }
  };
}

export default function feedsReducer(
  state: FeedsState = {
    create: {
      loading: false,
      error: null,
      feed: null,
    },
    list: {
      loading: false,
      error: null,
      feeds: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      feed: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      error: null,
      success: null,
    },
  },
  action: FeedsAction,
): FeedsState {
  switch (action.type) {
    case CREATE_FEED:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, feed: null },
      };
    case CREATE_FEED_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          feed: action.payload,
        },
      };
    case CREATE_FEED_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          feed: null,
        },
      };
    case FETCH_FEED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          feed: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_FEED_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          feed: action.payload,
        },
      };
    case FETCH_FEED_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          feed: null,
        },
      };
    case UPDATE_FEED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_FEED_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_FEED_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case DELETE_FEED:
      return {
        ...state,
        delete: { ...state.delete, loading: true, error: null, success: null },
      };
    case DELETE_FEED_SUCCESS:
      return {
        ...state,
        delete: { ...state.delete, loading: false, error: null, success: true },
      };
    case DELETE_FEED_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: null,
        },
      };
    case FETCH_FEEDS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          feeds: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_FEEDS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          feeds: action.payload.feeds,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_FEEDS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          feeds: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

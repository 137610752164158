import {
  CREATE_PUMP,
  CREATE_PUMP_FAILED,
  CREATE_PUMP_SUCCESS,
  FETCH_PUMP,
  FETCH_PUMPS,
  FETCH_PUMPS_FAILED,
  FETCH_PUMPS_SUCCESS,
  FETCH_PUMP_FAILED,
  FETCH_PUMP_SUCCESS,
  UPDATE_PUMP,
  UPDATE_PUMP_FAILED,
  UPDATE_PUMP_SUCCESS,
} from '../../constants';
import { PumpModel } from '../../model/domain';
import { ListPumpsQuery, ListPumpsQueryModel } from '../../model/querys';

// PUMPS
export interface CreatePumpAction {
  type: CREATE_PUMP;
}

export interface CreatePumpSuccessAction {
  type: CREATE_PUMP_SUCCESS;
  payload: PumpModel;
}

export interface CreatePumpFailedAction {
  type: CREATE_PUMP_FAILED;
  payload: Error;
}

export interface FetchPumpAction {
  type: FETCH_PUMP;
}

export interface FetchPumpSuccessAction {
  type: FETCH_PUMP_SUCCESS;
  payload: PumpModel;
}

export interface FetchPumpFailedAction {
  type: FETCH_PUMP_FAILED;
  payload: Error;
}

export interface FetchPumpsAction {
  type: FETCH_PUMPS;
  payload: ListPumpsQuery;
}

export interface FetchPumpsSuccessAction {
  type: FETCH_PUMPS_SUCCESS;
  payload: {
    query: ListPumpsQueryModel;
    pumps: PumpModel[];
  };
}

export interface FetchPumpsFailedAction {
  type: FETCH_PUMPS_FAILED;
  payload: Error;
}

export interface UpdatePumpAction {
  type: UPDATE_PUMP;
}

export interface UpdatePumpSuccessAction {
  type: UPDATE_PUMP_SUCCESS;
  payload: PumpModel;
}

export interface UpdatePumpFailedAction {
  type: UPDATE_PUMP_FAILED;
  payload: Error;
}

export type PumpUpdateAction =
  | UpdatePumpAction
  | UpdatePumpSuccessAction
  | UpdatePumpFailedAction;
export type PumpCreateAction =
  | CreatePumpAction
  | CreatePumpSuccessAction
  | CreatePumpFailedAction;
export type PumpFetchAction =
  | FetchPumpAction
  | FetchPumpSuccessAction
  | FetchPumpFailedAction;
export type PumpsFetchAction =
  | FetchPumpsAction
  | FetchPumpsSuccessAction
  | FetchPumpsFailedAction;
export type PumpsAction =
  | PumpCreateAction
  | PumpFetchAction
  | PumpsFetchAction
  | PumpUpdateAction;

// Action creators
export function pumpCreate(): CreatePumpAction {
  return {
    type: CREATE_PUMP,
  };
}

export function pumpCreated(pump: PumpModel): CreatePumpSuccessAction {
  return {
    type: CREATE_PUMP_SUCCESS,
    payload: pump,
  };
}

export function pumpCreateFailed(error: Error): CreatePumpFailedAction {
  return {
    type: CREATE_PUMP_FAILED,
    payload: error,
  };
}

export function pumpFetch(): FetchPumpAction {
  return {
    type: FETCH_PUMP,
  };
}

export function pumpFetched(pump: PumpModel): FetchPumpSuccessAction {
  return {
    type: FETCH_PUMP_SUCCESS,
    payload: pump,
  };
}

export function pumpFetchFailed(error: Error): FetchPumpFailedAction {
  return {
    type: FETCH_PUMP_FAILED,
    payload: error,
  };
}

export function pumpsFetch(query: ListPumpsQuery): FetchPumpsAction {
  return {
    type: FETCH_PUMPS,
    payload: query,
  };
}

export function pumpsFetched(
  query: ListPumpsQueryModel,
  pumps: PumpModel[],
): FetchPumpsSuccessAction {
  return {
    type: FETCH_PUMPS_SUCCESS,
    payload: { query, pumps },
  };
}

export function pumpsFetchFailed(error: Error): FetchPumpsFailedAction {
  return {
    type: FETCH_PUMPS_FAILED,
    payload: error,
  };
}

export function pumpUpdate(): UpdatePumpAction {
  return {
    type: UPDATE_PUMP,
  };
}

export function pumpUpdated(pump: PumpModel): UpdatePumpSuccessAction {
  return {
    type: UPDATE_PUMP_SUCCESS,
    payload: pump,
  };
}

export function pumpUpdateFailed(error: Error): UpdatePumpFailedAction {
  return {
    type: UPDATE_PUMP_FAILED,
    payload: error,
  };
}

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';

import { AccountModel, FarmModel, OrganisationModel, PondModel } from '../model/domain';

export class ActivationApi extends Api {
    public constructor(config?: AxiosRequestConfig) {
        super(config);
        this.activateOrganisation = this.activateOrganisation.bind(this);
        this.deactivateOrganisation = this.deactivateOrganisation.bind(this);
        this.activateFarm = this.activateFarm.bind(this);
        this.deactivateFarm = this.deactivateFarm.bind(this);
        this.activatePond = this.activatePond.bind(this);
        this.deactivatePond = this.deactivatePond.bind(this);
        this.activateAccount = this.activateAccount.bind(this);
        this.deactivateAccount = this.deactivateAccount.bind(this);
        this.activateVipAccount = this.activateVipAccount.bind(this);
        this.deactivateVipAccount = this.deactivateVipAccount.bind(this);

    }


    public activateOrganisation(organisationId): Promise<OrganisationModel> {
        return this.post<OrganisationModel, null, AxiosResponse<OrganisationModel>>(
            `/v1/activate/organisations/${organisationId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public deactivateOrganisation(organisationId): Promise<OrganisationModel> {
        return this.post<OrganisationModel, null, AxiosResponse<OrganisationModel>>(
            `/v1/deactivate/organisations/${organisationId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public activateFarm(farmId): Promise<FarmModel> {
        return this.post<FarmModel, null, AxiosResponse<FarmModel>>(
            `/v1/activate/farms/${farmId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public deactivateFarm(farmId): Promise<FarmModel> {
        return this.post<FarmModel, null, AxiosResponse<FarmModel>>(
            `/v1/deactivate/farms/${farmId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }


    public activatePond(pondId): Promise<PondModel> {
        return this.post<PondModel, null, AxiosResponse<PondModel>>(
            `/v1/activate/ponds/${pondId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public deactivatePond(pondId): Promise<PondModel> {
        return this.post<PondModel, null, AxiosResponse<PondModel>>(
            `/v1/deactivate/ponds/${pondId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public activateAccount(accountId): Promise<AccountModel> {
        return this.post<AccountModel, null, AxiosResponse<AccountModel>>(
            `/v1/activate/accounts/${accountId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public deactivateAccount(accountId): Promise<AccountModel> {
        return this.post<AccountModel, null, AxiosResponse<AccountModel>>(
            `/v1/deactivate/accounts/${accountId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }


    public activateVipAccount(accountId): Promise<AccountModel> {
        return this.post<AccountModel, null, AxiosResponse<AccountModel>>(
            `/v1/activateVip/accounts/${accountId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public deactivateVipAccount(accountId): Promise<AccountModel> {
        return this.post<AccountModel, null, AxiosResponse<AccountModel>>(
            `/v1/deactivateVip/accounts/${accountId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public activateScoringAccount(accountId): Promise<AccountModel> {
        return this.post<AccountModel, null, AxiosResponse<AccountModel>>(
            `/v1/activateScoring/accounts/${accountId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

    public deactivateScoringAccount(accountId): Promise<AccountModel> {
        return this.post<AccountModel, null, AxiosResponse<AccountModel>>(
            `/v1/deactivateScoring/accounts/${accountId}`,
            null,
            getConfig(),
        )
            .then(this.success)
            .catch((error: AxiosError<any>) => {
                if (error.response && error.response.data) {
                    error.message = `${error.response.data.key}: ${error.response.data.reason}`;
                }
                throw error;
            });
    }

}

export const activationApi = new ActivationApi();



import React from 'react';
import { Badge } from 'antd';
import { CalibrationState } from '../../model/enums';
import { getNameToCalibrationState } from '../../util';

type Props = {
  state: CalibrationState;
};

export const CalibrationStateBadge: React.FC<Props> = ({ state }) => {
  switch (state) {
    case CalibrationState.SUCCESS:
      return <Badge status="success" text={getNameToCalibrationState(state)} />;
    case CalibrationState.FAILURE:
      return <Badge status="error" text={getNameToCalibrationState(state)} />;
    case CalibrationState.PENDING:
      return (
        <Badge status="processing" text={getNameToCalibrationState(state)} />
      );
    case CalibrationState.NO_ANSWER_IN_TIME:
      return <Badge status="error" text={getNameToCalibrationState(state)} />;
    case CalibrationState.REMOTE_STARTED:
      return (
        <Badge status="processing" text={getNameToCalibrationState(state)} />
      );
    case CalibrationState.NOT_SET:
      return <Badge status="warning" text={getNameToCalibrationState(state)} />;
    default:
      return <Badge status="default" text={getNameToCalibrationState(state)} />;
  }
};

import React from 'react';
import { Row, Card, Col, Layout, Divider, Space } from 'antd';
import { LoginForm } from '../auth/loginForm';

const { Content } = Layout;

export const Login: React.FC<{}> = () => {
  return (
    <Content>
      <Row
        justify="center"
        align="middle"
        gutter={[16, 16]}
        style={{ height: '100%' }}
      >
        <Col xs={20} sm={16} md={12} lg={8} xl={8} xxl={8}>
          <Card title="Login">
            <LoginForm />
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  notification,
  Typography,
  Descriptions,
  Tag,
  Tooltip,
  Button,
  Empty,
  List,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { UpdateSmartSensorCmd } from '../../model/cmds';
import {
  getSmartSensor,
  updateSmartSensor,
} from '../../duck/modules/smartSensors';

import { usePrevious } from '../_util/hook';
import { SmartSensorDataForm } from '../smartSensor/smartSensorDataForm';
import { MqttConnectionStatus } from '../../model/enums';
import { functionalColor } from '../../colors';
import moment from 'moment';
import { SmartSensorConnectedSensorsList } from '../smartSensor/smartSensorConnectedSensorsList';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ smartSensorId: string }>;

const UnconnectedSmartSensorDetail: React.FC<Props> = ({
  getSmartSensorConnect,
  updateSmartSensorConnect,
  smartSensor,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
}) => {
  const { smartSensorId } = match.params;

  React.useEffect(() => {
    getSmartSensorConnect(smartSensorId);
  }, []);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Smart Sensor"
        subTitle={error.message}
      />
    );
  }

  const renderGeneralDataCardContent = () => {
    if (smartSensor) {
      return (
        <SmartSensorDataForm
          smartSensor={smartSensor}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onUpdate={(cmd: UpdateSmartSensorCmd) => {
            updateSmartSensorConnect(smartSensorId, cmd);
          }}
        />
      );
    }
  };

  const renderHierachyCardContent = () => {
    if (smartSensor) {
      return (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Organisation">
            {smartSensor.organisation ? (
              <Link to={`/organisations/${smartSensor.organisation.id}`}>
                {smartSensor.organisation.name}
              </Link>
            ) : (
              <Empty
                description={'No Organisation'}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Gateway">
            {smartSensor.gateway ? (
              <Row justify="space-between">
                <Link to={`/gateways/${smartSensor.gateway.id}`}>
                  {smartSensor.gateway.name || smartSensor.gateway.id}
                </Link>
                {smartSensor.gateway.mqttConnectionStatus ===
                MqttConnectionStatus.CONNECTED ? (
                  <Tag color={functionalColor.success}>{'Online'}</Tag>
                ) : (
                  <Tooltip
                    title={moment(
                      smartSensor.gateway.mqttConnectionTimestamp,
                    ).fromNow()}
                  >
                    <span>
                      <Tag color={functionalColor.error}>{'Offline'}</Tag>
                    </span>
                  </Tooltip>
                )}
              </Row>
            ) : (
              <Empty
                description={'No Gateway'}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </Descriptions.Item>
        </Descriptions>
      );
    }
  };

  const renderOperationDataCardContent = () => {
    if (smartSensor) {
      return (
        <Descriptions column={{ xs: 1, sm: 2 }} bordered>
          <Descriptions.Item label="Server Adress">
            {smartSensor.serverAddress}
          </Descriptions.Item>
          <Descriptions.Item label="Client Adress">
            {smartSensor.clientAddress}
          </Descriptions.Item>
          <Descriptions.Item label="Frequency">
            {smartSensor.frequency}
          </Descriptions.Item>
          <Descriptions.Item label="Power">
            {smartSensor.power}
          </Descriptions.Item>
          <Descriptions.Item label="Crypt Key">
            {smartSensor.cryptKey}
          </Descriptions.Item>
          <Descriptions.Item label="Bandwidth">
            {smartSensor.bandwidth}
          </Descriptions.Item>
          <Descriptions.Item label="Coding Factor">
            {smartSensor.codingFactor}
          </Descriptions.Item>
          <Descriptions.Item label="Spreading Factor">
            {smartSensor.spreadingFactor}
          </Descriptions.Item>
        </Descriptions>
      );
    }
  };

  const renderConnectedSensorsCardContent = () => {
    return <SmartSensorConnectedSensorsList smartSensorId={smartSensorId} />;
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="General Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Hierarchy" loading={loading}>
              {renderHierachyCardContent()}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Operation Data" loading={loading}>
              {renderOperationDataCardContent()}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Connected Sensors" loading={loading}>
              {renderConnectedSensorsCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  smartSensor: state.smartSensors.detail.smartSensor,
  loading: state.smartSensors.detail.loading,
  error: state.smartSensors.detail.error,
  updating: state.smartSensors.detail.updating,
  updateError: state.smartSensors.detail.updateError,
  updateSuccess: state.smartSensors.detail.updateSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSmartSensorConnect: getSmartSensor,
      updateSmartSensorConnect: updateSmartSensor,
    },
    dispatch,
  );
};

export const SmartSensorDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSmartSensorDetail);

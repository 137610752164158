import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Typography,
  Table,
  Popconfirm,
  InputNumber,
  Input,
  Form,
  Button,
  notification,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { UpdateFeedTableCmd } from '../../model/cmds';
import {
  deleteFeedTable,
  getFeedTable,
  updateFeedTable,
} from '../../duck/modules/feedTables';
import { FeedTableDataForm } from '../feedTable/feedTableDataForm';
import { FeedTableTable } from '../feedTable/feedTableTable';
import { usePrevious } from '../_util/hook';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ feedTableId: string }>;

const UnconnectedFeedTableDetail: React.FC<Props> = ({
  getFeedTableConnect,
  updateFeedTableConnect,
  feedTable,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  deleteState,
  deleteFeedTableConnect,
}) => {
  const { feedTableId } = match.params;

  React.useEffect(() => {
    getFeedTableConnect(feedTableId);
  }, []);

  const prev = usePrevious({
    deleteSuccess: deleteState.success,
    deleteError: deleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteState.success) {
      notification.success({
        message: 'Feed table deleted',
      });
    }
  }, [deleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteState.error) {
      notification.error({
        message: 'Feed table deletion error',
        description: deleteState.error?.message,
      });
    }
  }, [deleteState.error]);

  const renderTableCardContent = () => {
    if (feedTable) {
      return <FeedTableTable feedTable={feedTable} />;
    }
  };

  const renderGeneralDataCardContent = () => {
    if (feedTable) {
      return (
        <FeedTableDataForm
          feedTable={feedTable}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onUpdate={(cmd: UpdateFeedTableCmd) => {
            updateFeedTableConnect(feedTableId, cmd);
          }}
        />
      );
    }
  };

  const rendeDeleteButton = () => {
    if (feedTable) {
      return (
        <Popconfirm
          title="Do you really want to delete the table?"
          onConfirm={() => deleteFeedTableConnect(feedTableId)}
        >
          <Button type="ghost" danger loading={deleteState.loading}>
            Delete
          </Button>
        </Popconfirm>
      );
    }
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load feed table"
        subTitle={error.message}
      />
    );
  }

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{rendeDeleteButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="General Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Card title="Table" loading={loading}>
              {renderTableCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  feedTable: state.feedTables.detail.feedTable,
  loading: state.feedTables.detail.loading,
  error: state.feedTables.detail.error,
  updating: state.feedTables.detail.updating,
  updateError: state.feedTables.detail.updateError,
  updateSuccess: state.feedTables.detail.updateSuccess,
  deleteState: state.feedTables.delete,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFeedTableConnect: getFeedTable,
      updateFeedTableConnect: updateFeedTable,
      deleteFeedTableConnect: deleteFeedTable,
    },
    dispatch,
  );
};

export const FeedTableDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedFeedTableDetail);

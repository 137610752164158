import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Select,
  Tooltip,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { PondModel, AerationProfileModel } from '../../model/domain';
import { UpdatePondCmd, UpdateAerationProfileCmd } from '../../model/cmds';
import { nameRule } from '../_util/validation';
import { usePrevious } from '../_util/hook';
import { PondShape, PondSlope, AeratorAutomation } from '../../model/enums';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { functionalColor } from '../../colors';

const { Option } = Select;

type Props = {
  aerationProfile: AerationProfileModel;
  onUpdate: (cmd: UpdateAerationProfileCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const AerationProfileForm: React.FC<Props> = ({
  aerationProfile,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...aerationProfile,
      automation:
        aerationProfile.automation.requested ||
        aerationProfile.automation.actual,
      switchWaitTimeInMs:
        aerationProfile.switchWaitTimeInMs.requested ||
        aerationProfile.switchWaitTimeInMs.actual,
      sirenWaitTimeInMs:
        aerationProfile.sirenWaitTimeInMs.requested ||
        aerationProfile.sirenWaitTimeInMs.actual,
    });
  }, [aerationProfile]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Aeration profile updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating aeration profile',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const {
      // automation,
      switchWaitTimeInMs,
      sirenWaitTimeInMs,
    } = values;
    const cmd: UpdateAerationProfileCmd = {};

    // if(automation !==  aerationProfile.automation.actual) {
    //   cmd.automation = automation;
    // }

    if (switchWaitTimeInMs !== aerationProfile.switchWaitTimeInMs.actual) {
      cmd.switchWaitTimeInMs = switchWaitTimeInMs;
    }

    if (sirenWaitTimeInMs !== aerationProfile.sirenWaitTimeInMs.actual) {
      cmd.sirenWaitTimeInMs = sirenWaitTimeInMs;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && aerationProfile) {
      cmd.switchWaitTimeInMs =
        aerationProfile.switchWaitTimeInMs.requested ||
        aerationProfile.switchWaitTimeInMs.actual;
      cmd.sirenWaitTimeInMs =
        aerationProfile.sirenWaitTimeInMs.requested ||
        aerationProfile.sirenWaitTimeInMs.actual;
    }

    onUpdate(cmd);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      {/* <Form.Item label="Automation" name="automation">
        <Select placeholder="Select automation level" allowClear suffixIcon={
            aerationProfile.automation.inSync ? (
              <Tooltip title={"Synchronized"}>
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing..."`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }>
          <Option value={AeratorAutomation.NOT_SET}>{"Not set"}</Option>
          <Option value={AeratorAutomation.ON_SITE}>{"On Site"}</Option>
          <Option value={AeratorAutomation.MANUAL}>{"Manual"}</Option>
          <Option value={AeratorAutomation.PARTIAL_AUTOMATION}>{"Partial Automation"}</Option>
          <Option value={AeratorAutomation.FULL_AUTOMATION}>{"Full Automation"}</Option>
        </Select>
      </Form.Item> */}

      <Form.Item
        label="Duration of the siren alarm (ms)"
        name="sirenWaitTimeInMs"
      >
        <Input
          placeholder="5000"
          suffix={
            aerationProfile.sirenWaitTimeInMs.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing..."`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        />
      </Form.Item>

      <Form.Item label="Time between switching (ms)" name="switchWaitTimeInMs">
        <Input
          placeholder="1000"
          suffix={
            aerationProfile.switchWaitTimeInMs.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing..."`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  FETCH_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED,
} from '../../constants';
import { ProductModel } from '../../model/domain';
import { SimpleProductModel } from '../../model/simple';
import { ListProductsQuery, ListProductsQueryModel } from '../../model/querys';

// PRODUCTS
export interface CreateProductAction {
  type: CREATE_PRODUCT;
}

export interface CreateProductSuccessAction {
  type: CREATE_PRODUCT_SUCCESS;
  payload: ProductModel;
}

export interface CreateProductFailedAction {
  type: CREATE_PRODUCT_FAILED;
  payload: Error;
}

export interface FetchProductAction {
  type: FETCH_PRODUCT;
}

export interface FetchProductSuccessAction {
  type: FETCH_PRODUCT_SUCCESS;
  payload: ProductModel;
}

export interface FetchProductFailedAction {
  type: FETCH_PRODUCT_FAILED;
  payload: Error;
}

export interface FetchProductsAction {
  type: FETCH_PRODUCTS;
  payload: ListProductsQuery;
}

export interface FetchProductsSuccessAction {
  type: FETCH_PRODUCTS_SUCCESS;
  payload: {
    query: ListProductsQueryModel;
    products: SimpleProductModel[];
  };
}

export interface FetchProductsFailedAction {
  type: FETCH_PRODUCTS_FAILED;
  payload: Error;
}

export interface UpdateProductAction {
  type: UPDATE_PRODUCT;
}

export interface UpdateProductSuccessAction {
  type: UPDATE_PRODUCT_SUCCESS;
  payload: ProductModel;
}

export interface UpdateProductFailedAction {
  type: UPDATE_PRODUCT_FAILED;
  payload: Error;
}

export interface DeleteProductAction {
  type: DELETE_PRODUCT;
  payload: {
    productId: string;
  };
}

export interface DeleteProductSuccessAction {
  type: DELETE_PRODUCT_SUCCESS;
  payload: ProductModel;
}

export interface DeleteProductFailedAction {
  type: DELETE_PRODUCT_FAILED;
  payload: Error;
}

export type ProductDeleteAction =
  | DeleteProductAction
  | DeleteProductSuccessAction
  | DeleteProductFailedAction;
export type ProductUpdateAction =
  | UpdateProductAction
  | UpdateProductSuccessAction
  | UpdateProductFailedAction;
export type ProductCreateAction =
  | CreateProductAction
  | CreateProductSuccessAction
  | CreateProductFailedAction;
export type ProductFetchAction =
  | FetchProductAction
  | FetchProductSuccessAction
  | FetchProductFailedAction;
export type ProductsFetchAction =
  | FetchProductsAction
  | FetchProductsSuccessAction
  | FetchProductsFailedAction;
export type ProductsAction =
  | ProductCreateAction
  | ProductFetchAction
  | ProductsFetchAction
  | ProductUpdateAction
  | ProductDeleteAction;

// Action creators
export function productCreate(): CreateProductAction {
  return {
    type: CREATE_PRODUCT,
  };
}

export function productCreated(
  product: ProductModel,
): CreateProductSuccessAction {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    payload: product,
  };
}

export function productCreateFailed(error: Error): CreateProductFailedAction {
  return {
    type: CREATE_PRODUCT_FAILED,
    payload: error,
  };
}

export function productFetch(): FetchProductAction {
  return {
    type: FETCH_PRODUCT,
  };
}

export function productFetched(
  product: ProductModel,
): FetchProductSuccessAction {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: product,
  };
}

export function productFetchFailed(error: Error): FetchProductFailedAction {
  return {
    type: FETCH_PRODUCT_FAILED,
    payload: error,
  };
}

export function productsFetch(query: ListProductsQuery): FetchProductsAction {
  return {
    type: FETCH_PRODUCTS,
    payload: query,
  };
}

export function productsFetched(
  query: ListProductsQueryModel,
  products: SimpleProductModel[],
): FetchProductsSuccessAction {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { query, products },
  };
}

export function productsFetchFailed(error: Error): FetchProductsFailedAction {
  return {
    type: FETCH_PRODUCTS_FAILED,
    payload: error,
  };
}

export function productUpdate(): UpdateProductAction {
  return {
    type: UPDATE_PRODUCT,
  };
}

export function productUpdated(
  product: ProductModel,
): UpdateProductSuccessAction {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: product,
  };
}

export function productUpdateFailed(error: Error): UpdateProductFailedAction {
  return {
    type: UPDATE_PRODUCT_FAILED,
    payload: error,
  };
}

export function productDelete(productId: string): DeleteProductAction {
  return {
    type: DELETE_PRODUCT,
    payload: { productId },
  };
}

export function productDeleted(
  product: ProductModel,
): DeleteProductSuccessAction {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: product,
  };
}

export function productDeleteFailed(error: Error): DeleteProductFailedAction {
  return {
    type: DELETE_PRODUCT_FAILED,
    payload: error,
  };
}

import {
  CREATE_ANNOUCEMENT,
  CREATE_ANNOUCEMENT_FAILED,
  CREATE_ANNOUCEMENT_SUCCESS,
  FETCH_ANNOUCEMENT,
  FETCH_ANNOUCEMENTS,
  FETCH_ANNOUCEMENTS_FAILED,
  FETCH_ANNOUCEMENTS_SUCCESS,
  FETCH_ANNOUCEMENT_FAILED,
  FETCH_ANNOUCEMENT_SUCCESS,
  UPDATE_ANNOUCEMENT,
  UPDATE_ANNOUCEMENT_SUCCESS,
  UPDATE_ANNOUCEMENT_FAILED,
  DELETE_ANNOUCEMENT,
  DELETE_ANNOUCEMENT_SUCCESS,
  DELETE_ANNOUCEMENT_FAILED,
  FETCH_LATEST_ANNOUCEMENT,
  FETCH_LATEST_ANNOUCEMENT_SUCCESS,
  FETCH_LATEST_ANNOUCEMENT_FAILED,
} from '../../constants';
import { AnnouncementModel } from '../../model/domain';
import { SimpleAnnouncementModel } from '../../model/simple';
import {
  ListAnnouncementsQuery,
  ListAnnouncementsQueryModel,
} from '../../model/querys';

// ANNOUCEMENTS
export interface CreateAnnouncementAction {
  type: CREATE_ANNOUCEMENT;
}

export interface CreateAnnouncementSuccessAction {
  type: CREATE_ANNOUCEMENT_SUCCESS;
  payload: AnnouncementModel;
}

export interface CreateAnnouncementFailedAction {
  type: CREATE_ANNOUCEMENT_FAILED;
  payload: Error;
}

export interface FetchAnnouncementAction {
  type: FETCH_ANNOUCEMENT;
}

export interface FetchAnnouncementSuccessAction {
  type: FETCH_ANNOUCEMENT_SUCCESS;
  payload: AnnouncementModel;
}

export interface FetchAnnouncementFailedAction {
  type: FETCH_ANNOUCEMENT_FAILED;
  payload: Error;
}

export interface FetchAnnouncementsAction {
  type: FETCH_ANNOUCEMENTS;
  payload: ListAnnouncementsQuery;
}

export interface FetchAnnouncementsSuccessAction {
  type: FETCH_ANNOUCEMENTS_SUCCESS;
  payload: {
    query: ListAnnouncementsQueryModel;
    announcements: SimpleAnnouncementModel[];
  };
}

export interface FetchLatestAnnouncementAction {
  type: FETCH_LATEST_ANNOUCEMENT;
}

export interface FetchLatestAnnouncementSuccessAction {
  type: FETCH_LATEST_ANNOUCEMENT_SUCCESS;
  payload: AnnouncementModel;
}

export interface FetchLatestAnnouncementFailedAction {
  type: FETCH_LATEST_ANNOUCEMENT_FAILED;
  payload: Error;
}

export interface FetchAnnouncementsFailedAction {
  type: FETCH_ANNOUCEMENTS_FAILED;
  payload: Error;
}

export interface UpdateAnnouncementAction {
  type: UPDATE_ANNOUCEMENT;
}

export interface UpdateAnnouncementSuccessAction {
  type: UPDATE_ANNOUCEMENT_SUCCESS;
  payload: AnnouncementModel;
}

export interface UpdateAnnouncementFailedAction {
  type: UPDATE_ANNOUCEMENT_FAILED;
  payload: Error;
}

export interface DeleteAnnouncementAction {
  type: DELETE_ANNOUCEMENT;
  payload: {
    announcementId: string;
  };
}

export interface DeleteAnnouncementSuccessAction {
  type: DELETE_ANNOUCEMENT_SUCCESS;
  payload: AnnouncementModel;
}

export interface DeleteAnnouncementFailedAction {
  type: DELETE_ANNOUCEMENT_FAILED;
  payload: Error;
}

export type AnnouncementDeleteAction =
  | DeleteAnnouncementAction
  | DeleteAnnouncementSuccessAction
  | DeleteAnnouncementFailedAction;
export type AnnouncementUpdateAction =
  | UpdateAnnouncementAction
  | UpdateAnnouncementSuccessAction
  | UpdateAnnouncementFailedAction;
export type AnnouncementCreateAction =
  | CreateAnnouncementAction
  | CreateAnnouncementSuccessAction
  | CreateAnnouncementFailedAction;
export type AnnouncementFetchAction =
  | FetchAnnouncementAction
  | FetchAnnouncementSuccessAction
  | FetchAnnouncementFailedAction;
export type AnnouncementsFetchAction =
  | FetchAnnouncementsAction
  | FetchAnnouncementsSuccessAction
  | FetchAnnouncementsFailedAction;
export type LatestAnnouncementFetchAction =
  | FetchLatestAnnouncementAction
  | FetchLatestAnnouncementSuccessAction
  | FetchLatestAnnouncementFailedAction;

export type AnnouncementsAction =
  | AnnouncementCreateAction
  | AnnouncementFetchAction
  | AnnouncementsFetchAction
  | AnnouncementUpdateAction
  | AnnouncementDeleteAction
  | LatestAnnouncementFetchAction;

// Action creators
export function announcementCreate(): CreateAnnouncementAction {
  return {
    type: CREATE_ANNOUCEMENT,
  };
}

export function announcementCreated(
  announcement: AnnouncementModel,
): CreateAnnouncementSuccessAction {
  return {
    type: CREATE_ANNOUCEMENT_SUCCESS,
    payload: announcement,
  };
}

export function announcementCreateFailed(
  error: Error,
): CreateAnnouncementFailedAction {
  return {
    type: CREATE_ANNOUCEMENT_FAILED,
    payload: error,
  };
}

export function announcementFetch(): FetchAnnouncementAction {
  return {
    type: FETCH_ANNOUCEMENT,
  };
}

export function announcementFetched(
  announcement: AnnouncementModel,
): FetchAnnouncementSuccessAction {
  return {
    type: FETCH_ANNOUCEMENT_SUCCESS,
    payload: announcement,
  };
}

export function announcementFetchFailed(
  error: Error,
): FetchAnnouncementFailedAction {
  return {
    type: FETCH_ANNOUCEMENT_FAILED,
    payload: error,
  };
}

export function latestAnnouncementFetch(): FetchLatestAnnouncementAction {
  return {
    type: FETCH_LATEST_ANNOUCEMENT,
  };
}

export function latestAnnouncementFetched(
  latestAnnouncement: AnnouncementModel,
): FetchLatestAnnouncementSuccessAction {
  return {
    type: FETCH_LATEST_ANNOUCEMENT_SUCCESS,
    payload: latestAnnouncement,
  };
}

export function latestAnnouncementFetchFailed(
  error: Error,
): FetchLatestAnnouncementFailedAction {
  return {
    type: FETCH_LATEST_ANNOUCEMENT_FAILED,
    payload: error,
  };
}

export function announcementsFetch(
  query: ListAnnouncementsQuery,
): FetchAnnouncementsAction {
  return {
    type: FETCH_ANNOUCEMENTS,
    payload: query,
  };
}

export function announcementsFetched(
  query: ListAnnouncementsQueryModel,
  announcements: SimpleAnnouncementModel[],
): FetchAnnouncementsSuccessAction {
  return {
    type: FETCH_ANNOUCEMENTS_SUCCESS,
    payload: { query, announcements },
  };
}

export function announcementsFetchFailed(
  error: Error,
): FetchAnnouncementsFailedAction {
  return {
    type: FETCH_ANNOUCEMENTS_FAILED,
    payload: error,
  };
}

export function announcementUpdate(): UpdateAnnouncementAction {
  return {
    type: UPDATE_ANNOUCEMENT,
  };
}

export function announcementUpdated(
  announcement: AnnouncementModel,
): UpdateAnnouncementSuccessAction {
  return {
    type: UPDATE_ANNOUCEMENT_SUCCESS,
    payload: announcement,
  };
}

export function announcementUpdateFailed(
  error: Error,
): UpdateAnnouncementFailedAction {
  return {
    type: UPDATE_ANNOUCEMENT_FAILED,
    payload: error,
  };
}

export function announcementDelete(
  announcementId: string,
): DeleteAnnouncementAction {
  return {
    type: DELETE_ANNOUCEMENT,
    payload: { announcementId },
  };
}

export function announcementDeleted(
  announcement: AnnouncementModel,
): DeleteAnnouncementSuccessAction {
  return {
    type: DELETE_ANNOUCEMENT_SUCCESS,
    payload: announcement,
  };
}

export function announcementDeleteFailed(
  error: Error,
): DeleteAnnouncementFailedAction {
  return {
    type: DELETE_ANNOUCEMENT_FAILED,
    payload: error,
  };
}

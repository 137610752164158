import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Form, Input, Button, Select, Alert, Space, notification } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'antd/lib/form/interface';
import { createFarm } from '../../duck/modules/farms';

import { nameRule } from '../_util/validation';
import { getOrganisations } from '../../duck/modules/organisations';
import { usePrevious } from '../_util/hook';
import { FarmModel, LocationModel } from '../../model/domain';
import { INT_MAX } from '../../constants';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId?: string;
    locations: LocationModel[];
    onCreated?: (farm: FarmModel) => void;
    onError?: (_error: Error) => void;
    onZipCodeChange: (zipCode: string) => void;
    locationsLoading: boolean;
  };

const UnconnectedCreateFarmForm: React.FC<Props> = ({
  createFarmConnect,
  getOrganisationsConnect,
  loading,
  error,
  success,
  organisations,
  onCreated,
  onError,
  organisationId,
  onZipCodeChange,
  locations,
  locationsLoading,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ success, error });

  React.useEffect(() => {
    getOrganisationsConnect({ limit: INT_MAX, offset: 0 });
  }, []);

  React.useEffect(() => {
    if (prev?.success === null && success) {
      notification.success({
        message: 'Farm created',
      });
      form.resetFields();
      if (onCreated) {
        onCreated(success);
      }
    }
  }, [success]);

  React.useEffect(() => {
    if (prev?.error === null && error) {
      notification.error({
        message: 'Farm creation error',
        description: error?.message,
      });
      if (onError) {
        onError(error);
      }
    }
  }, [error]);

  const onFinish = (values: Store) => {
    const {
      name,
      organisationId: formOrganisationId,
      crmCompanyId,
      locationId,
    } = values;
    if (organisationId) {
      createFarmConnect({ name, organisationId, crmCompanyId, locationId });
    } else {
      createFarmConnect({
        name,
        organisationId: formOrganisationId,
        crmCompanyId,
        locationId,
      });
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Name"
        name="name"
        rules={[nameRule, { required: true }]}
      >
        <Input placeholder="Farm #1234" />
      </Form.Item>

      {!organisationId && (
        <Form.Item
          label="Organisation"
          name="organisationId"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select corresponding organisation" allowClear>
            {organisations.map((organisation) => (
              <Option key={organisation.id} value={organisation.id}>
                {organisation.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item label="CRM Company Id" name="crmCompanyId">
        <Input placeholder="12345678" />
      </Form.Item>

      <Form.Item label="Search by ZIP code" name="zipCode">
        <Input
          onChange={() => {
            const zipCode: string = form.getFieldValue('zipCode');
            onZipCodeChange(zipCode);
          }}
          placeholder="Enter zip code to search for location"
        />
      </Form.Item>

      <Form.Item label="Location" name="locationId">
        <Select
          allowClear
          loading={locationsLoading}
          placeholder="Select location"
        >
          {form.getFieldValue('zipCode') &&
            locations
              .sort((l1, l2) => l1.zipCode.localeCompare(l2.zipCode))
              .map((location) => (
                <Option key={location.id} value={location.id}>
                  {`${location.zipCode} - ${location.province}, ${location.district}`}
                </Option>
              ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Farm
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.farms.create.loading,
  error: state.farms.create.error,
  success: state.farms.create.farm,
  organisations: state.organisations.list.organisations,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createFarmConnect: createFarm,
      getOrganisationsConnect: getOrganisations,
    },
    dispatch,
  );
};

export const CreateFarmForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateFarmForm);

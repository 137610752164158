import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { pondApi } from '../../api/pondApi';
import {
  CREATE_POND,
  CREATE_POND_FAILED,
  CREATE_POND_SUCCESS,
  FETCH_POND,
  FETCH_POND_FAILED,
  FETCH_POND_SUCCESS,
  FETCH_PONDS,
  FETCH_PONDS_FAILED,
  FETCH_PONDS_SUCCESS,
  UPDATE_POND,
  UPDATE_POND_FAILED,
  UPDATE_POND_SUCCESS,
  ADD_PUMP_TO_POND,
  ADD_PUMP_TO_POND_SUCCESS,
  ADD_PUMP_TO_POND_FAILED,
  REMOVE_PUMP_FROM_POND,
  REMOVE_PUMP_FROM_POND_SUCCESS,
  REMOVE_PUMP_FROM_POND_FAILED,
  COUNT_PONDS,
  COUNT_PONDS_SUCCESS,
  COUNT_PONDS_FAILED,
} from '../../constants';
import {
  CreatePondCmd,
  UpdatePondCmd,
  AddSensorToPondCmd,
  AddAeratorToPondCmd,
} from '../../model/cmds';
import { CountPondsModel, ListPondsQuery, ListPondsQueryModel } from '../../model/querys';
import { PondsState } from '../../model/state';
import { getOrganisation, getOrganisations } from './organisations';
import {
  pondCreate,
  pondCreated,
  pondCreateFailed,
  pondFetch,
  pondFetched,
  pondFetchFailed,
  pondPumpAdd,
  pondPumpAdded,
  pondPumpAddFailed,
  pondPumpRemove,
  pondPumpRemoved,
  pondPumpRemoveFailed,
  PondsAction,
  pondsCount,
  pondsCounted,
  pondsCountFailed,
  pondSensorAddFailed,
  pondsFetch,
  pondsFetched,
  pondsFetchFailed,
  pondUpdate,
  pondUpdated,
  pondUpdateFailed,
} from '../actions';
import { getFarms } from './farms';
import {
  pondThresholdsUpdate,
  pondThresholdsUpdated,
  pondThresholdsUpdateFailed,
  pondSensorAdd,
  pondSensorAdded,
  pondSensorRemove,
  pondSensorRemoved,
  pondSensorRemoveFailed,
  pondRefresh,
  pondRefreshed,
  pondRefreshFailed,
  pondAeratorAdd,
  pondAeratorAdded,
  pondAeratorAddFailed,
  pondAeratorRemove,
  pondAeratorRemoved,
  pondAeratorRemoveFailed,
} from '../actions';
import {
  UPDATE_POND_THRESHOLDS,
  UPDATE_POND_THRESHOLDS_SUCCESS,
  UPDATE_POND_THRESHOLDS_FAILED,
  INT_MAX,
  ADD_SENSOR_TO_POND,
  ADD_SENSOR_TO_POND_SUCCESS,
  ADD_SENSOR_TO_POND_FAILED,
  REMOVE_SENSOR_FROM_POND_FAILED,
  REMOVE_SENSOR_FROM_POND,
  REMOVE_SENSOR_FROM_POND_SUCCESS,
  REFRESH_POND,
  REFRESH_POND_SUCCESS,
  REFRESH_POND_FAILED,
  ADD_AERATOR_TO_POND,
  ADD_AERATOR_TO_POND_SUCCESS,
  ADD_AERATOR_TO_POND_FAILED,
  REMOVE_AERATOR_FROM_POND,
  REMOVE_AERATOR_FROM_POND_SUCCESS,
  REMOVE_AERATOR_FROM_POND_FAILED,
} from '../../constants';
import { gatewayApi } from '../../api/gatewayApi';
import { aeratorApi } from '../../api/aeratorApi';
import { sensingApi } from '../../api/sensingApi';
import { pumpApi } from '../../api/pumpApi';

export function createPond(cmd: CreatePondCmd) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondCreate());
      const pond = await pondApi.createPond(cmd);
      dispatch(pondCreated(pond));
      dispatch(getPonds({ limit: INT_MAX, offset: 0 }));
      dispatch(
        getFarms({
          limit: INT_MAX,
          offset: 0,
          organisationIds: [cmd.organisationId],
        }),
      );
    } catch (e) {
      dispatch(pondCreateFailed(e));
    }
  };
}

export function getPond(id: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondFetch());
      const pond = await pondApi.fetchPond(id);
      dispatch(pondFetched(pond));
    } catch (e) {
      dispatch(pondFetchFailed(e));
    }
  };
}

export function refreshPond(id: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondRefresh());
      const pond = await pondApi.fetchPond(id);
      dispatch(pondRefreshed(pond));
    } catch (e) {
      dispatch(pondRefreshFailed(e));
    }
  };
}

export function getPonds(query: ListPondsQuery) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondsFetch(query));
      const queryModel: ListPondsQueryModel = await pondApi.listPonds(query);
      dispatch(pondsFetched(queryModel));
    } catch (e) {
      dispatch(pondsFetchFailed(e));
    }
  };
}

export function updatePond(id: string, cmd: UpdatePondCmd) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondUpdate());
      const response = await pondApi.updatePond(id, cmd);
      dispatch(pondUpdated(response));
      dispatch(refreshPond(id));
    } catch (e) {
      dispatch(pondUpdateFailed(e));
    }
  };
}

export function updatePondThresholds(id: string, cmd: UpdatePondCmd) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondThresholdsUpdate());
      const response = await pondApi.updatePond(id, cmd);
      dispatch(pondThresholdsUpdated(response));
      dispatch(refreshPond(id));
    } catch (e) {
      dispatch(pondThresholdsUpdateFailed(e));
    }
  };
}

export function addSensorToPond(pondId: string, sensorId: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondSensorAdd(pondId, sensorId));
      // add sensor to pond
      const response = await sensingApi.updateSensor(sensorId, {
        pondId: pondId,
      });
      // get updated pond
      const pond = await pondApi.fetchPond(pondId);
      dispatch(pondSensorAdded(pond));
    } catch (e) {
      dispatch(pondSensorAddFailed(e));
    }
  };
}

export function removeSensorFromPond(pondId: string, sensorId: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondSensorRemove(pondId, sensorId));
      // remove sensor to pond
      const response = await sensingApi.updateSensor(sensorId, {
        pondId: undefined,
      });
      console.warn(JSON.stringify(response));
      // get updated pond
      const pond = await pondApi.fetchPond(pondId);
      dispatch(pondSensorRemoved(pond));
    } catch (e) {
      dispatch(pondSensorRemoveFailed(e));
    }
  };
}

export function addAeratorToPond(pondId: string, aeratorId: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondAeratorAdd(pondId, aeratorId));
      // add aerator to pond
      const response = await aeratorApi.updateAerator(aeratorId, {
        pondId: pondId,
      });
      // get updated pond
      const pond = await pondApi.fetchPond(pondId);
      dispatch(pondAeratorAdded(pond));
    } catch (e) {
      dispatch(pondAeratorAddFailed(e));
    }
  };
}

export function removeAeratorFromPond(pondId: string, aeratorId: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondAeratorRemove(pondId, aeratorId));
      // remove aerator to pond
      const response = await aeratorApi.updateAerator(aeratorId, {
        pondId: undefined,
      });
      // get updated pond
      const pond = await pondApi.fetchPond(pondId);
      dispatch(pondAeratorRemoved(pond));
    } catch (e) {
      dispatch(pondAeratorRemoveFailed(e));
    }
  };
}

export function addPumpToPond(pondId: string, pumpId: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondPumpAdd(pondId, pumpId));
      // add pump to pond
      const response = await pumpApi.updatePump(pumpId, {
        pondId: pondId,
      });
      // get updated pond
      const pond = await pondApi.fetchPond(pondId);
      dispatch(pondPumpAdded(pond));
    } catch (e) {
      dispatch(pondPumpAddFailed(e));
    }
  };
}

export function removePumpFromPond(pondId: string, pumpId: string) {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondPumpRemove(pondId, pumpId));
      // remove pump to pond
      const response = await pumpApi.updatePump(pumpId, {
        pondId: undefined,
      });
      // get updated pond
      const pond = await pondApi.fetchPond(pondId);
      dispatch(pondPumpRemoved(pond));
    } catch (e) {
      dispatch(pondPumpRemoveFailed(e));
    }
  };
}

export function countPonds() {
  return async (dispatch: Dispatch<PondsAction, {}, any>) => {
    try {
      dispatch(pondsCount());
      const response: CountPondsModel = await pondApi.countPonds();
      dispatch(pondsCounted(response));
    } catch (e) {
      dispatch(pondsCountFailed(e));
    }
  };
}

export default function pondsReducer(
  state: PondsState = {
    create: {
      loading: false,
      error: null,
      pond: null,
    },
    list: {
      loading: false,
      error: null,
      ponds: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      thresholds: {
        updating: false,
        updateSuccess: null,
        updateError: null,
      },
      loading: false,
      error: null,
      pond: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
      refresh: {
        loading: false,
        error: null,
      },
    },
    addSensor: {
      loading: false,
      error: null,
      sensorId: null,
      pondId: null,
    },
    removeSensor: {
      loading: false,
      error: null,
      sensorId: null,
      pondId: null,
    },
    addAerator: {
      loading: false,
      error: null,
      aeratorId: null,
      pondId: null,
    },
    removeAerator: {
      loading: false,
      error: null,
      aeratorId: null,
      pondId: null,
    },
    addPump: {
      loading: false,
      error: null,
      pumpId: null,
      pondId: null,
    },
    removePump: {
      loading: false,
      error: null,
      pumpId: null,
      pondId: null,
    },
    counts: {
      loading: false,
      error: null,
      active: null,
      inactive: null,
      all: null,
    }
  },
  action: PondsAction,
): PondsState {
  switch (action.type) {
    case CREATE_POND:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, pond: null },
      };
    case CREATE_POND_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          pond: action.payload,
        },
      };
    case CREATE_POND_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          pond: null,
        },
      };
    case FETCH_POND:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          pond: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_POND_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          pond: action.payload,
        },
      };
    case FETCH_POND_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          pond: null,
        },
      };
    case REFRESH_POND:
      return {
        ...state,
        detail: { ...state.detail, refresh: { loading: true, error: null } },
      };
    case REFRESH_POND_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          pond: action.payload,
          refresh: { loading: false, error: null },
        },
      };
    case REFRESH_POND_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          refresh: { loading: false, error: action.payload },
        },
      };
    case UPDATE_POND:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_POND_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_POND_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case UPDATE_POND_THRESHOLDS:
      return {
        ...state,
        detail: {
          ...state.detail,
          thresholds: {
            updating: true,
            updateSuccess: null,
            updateError: null,
          },
        },
      };
    case UPDATE_POND_THRESHOLDS_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          thresholds: {
            updating: false,
            updateSuccess: true,
            updateError: null,
          },
        },
      };
    case UPDATE_POND_THRESHOLDS_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          thresholds: {
            updating: false,
            updateSuccess: false,
            updateError: action.payload,
          },
        },
      };
    case ADD_SENSOR_TO_POND: {
      return {
        ...state,
        addSensor: {
          ...state.addSensor,
          loading: true,
          error: null,
          sensorId: action.payload.sensorId,
          pondId: action.payload.pondId,
        },
      };
    }
    case ADD_SENSOR_TO_POND_SUCCESS: {
      return {
        ...state,
        addSensor: {
          ...state.addSensor,
          loading: false,
          error: null,
          sensorId: null,
          pondId: null,
        },
      };
    }
    case ADD_SENSOR_TO_POND_FAILED: {
      return {
        ...state,
        addSensor: {
          ...state.addSensor,
          loading: false,
          error: action.payload,
          sensorId: null,
          pondId: null,
        },
      };
    }
    case REMOVE_SENSOR_FROM_POND: {
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: true,
          error: null,
          sensorId: action.payload.sensorId,
          pondId: action.payload.pondId,
        },
      };
    }
    case REMOVE_SENSOR_FROM_POND_SUCCESS: {
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: false,
          error: null,
          sensorId: null,
          pondId: null,
        },
      };
    }
    case REMOVE_SENSOR_FROM_POND_FAILED: {
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: false,
          error: action.payload,
          sensorId: null,
          pondId: null,
        },
      };
    }
    case ADD_AERATOR_TO_POND: {
      return {
        ...state,
        addAerator: {
          ...state.addAerator,
          loading: true,
          error: null,
          aeratorId: action.payload.aeratorId,
          pondId: action.payload.pondId,
        },
      };
    }
    case ADD_AERATOR_TO_POND_SUCCESS: {
      return {
        ...state,
        addAerator: {
          ...state.addAerator,
          loading: false,
          error: null,
          aeratorId: null,
          pondId: null,
        },
      };
    }
    case ADD_AERATOR_TO_POND_FAILED: {
      return {
        ...state,
        addAerator: {
          ...state.addAerator,
          loading: false,
          error: action.payload,
          aeratorId: null,
          pondId: null,
        },
      };
    }
    case REMOVE_AERATOR_FROM_POND: {
      return {
        ...state,
        removeAerator: {
          ...state.removeAerator,
          loading: true,
          error: null,
          aeratorId: action.payload.aeratorId,
          pondId: action.payload.pondId,
        },
      };
    }
    case REMOVE_AERATOR_FROM_POND_SUCCESS: {
      return {
        ...state,
        removeAerator: {
          ...state.removeAerator,
          loading: false,
          error: null,
          aeratorId: null,
          pondId: null,
        },
      };
    }
    case REMOVE_AERATOR_FROM_POND_FAILED: {
      return {
        ...state,
        removeAerator: {
          ...state.removeAerator,
          loading: false,
          error: action.payload,
          aeratorId: null,
          pondId: null,
        },
      };
    }
    case ADD_PUMP_TO_POND: {
      return {
        ...state,
        addPump: {
          ...state.addPump,
          loading: true,
          error: null,
          pumpId: action.payload.pumpId,
          pondId: action.payload.pondId,
        },
      };
    }
    case ADD_PUMP_TO_POND_SUCCESS: {
      return {
        ...state,
        addPump: {
          ...state.addPump,
          loading: false,
          error: null,
          pumpId: null,
          pondId: null,
        },
      };
    }
    case ADD_PUMP_TO_POND_FAILED: {
      return {
        ...state,
        addPump: {
          ...state.addPump,
          loading: false,
          error: action.payload,
          pumpId: null,
          pondId: null,
        },
      };
    }
    case REMOVE_PUMP_FROM_POND: {
      return {
        ...state,
        removePump: {
          ...state.removePump,
          loading: true,
          error: null,
          pumpId: action.payload.pumpId,
          pondId: action.payload.pondId,
        },
      };
    }
    case REMOVE_PUMP_FROM_POND_SUCCESS: {
      return {
        ...state,
        removePump: {
          ...state.removePump,
          loading: false,
          error: null,
          pumpId: null,
          pondId: null,
        },
      };
    }
    case REMOVE_PUMP_FROM_POND_FAILED: {
      return {
        ...state,
        removePump: {
          ...state.removePump,
          loading: false,
          error: action.payload,
          pumpId: null,
          pondId: null,
        },
      };
    }

    case FETCH_PONDS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          ponds: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_PONDS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          ponds: action.payload.ponds,
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: action.payload.total,
        },
      };

    case FETCH_PONDS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          ponds: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    case COUNT_PONDS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: true,
          error: null,
          active: null,
          inactive: null,
          all: null,
        }
      };
    case COUNT_PONDS_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: null,
          active: action.payload.active,
          inactive: action.payload.inactive,
          all: action.payload.all,
        }
      };
    case COUNT_PONDS_FAILED:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: action.payload,
          active: null,
          inactive: null,
          all: null,
        }
      };

    default:
      return state;
  }
}

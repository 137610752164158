import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Form, Input, Button, Space, notification } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'antd/lib/form/interface';
import { login } from '../../duck/modules/auth';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UnconnectedLoginForm: React.FC<Props> = ({
  loginConnected,
  loading,
  error,
  success,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (success) {
      notification.success({
        message: 'Login successfull',
      });
    }
  }, [success]);

  React.useEffect(() => {
    if (error) {
      notification.error({
        message: 'Login failed',
        description: error?.message,
      });
    }
  }, [error]);

  const tryLogin = (values: Store) => {
    const { username, password } = values;
    loginConnected(username, password);
  };

  return (
    <Form form={form} onFinish={tryLogin}>
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  success: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ loginConnected: login }, dispatch);
};

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedLoginForm);

import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { ticketApi } from '../../api/ticketApi';
import {
  CREATE_TICKET,
  CREATE_TICKET_FAILED,
  CREATE_TICKET_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKET_FAILED,
  DELETE_TICKET_SUCCESS,
  FETCH_TICKET,
  FETCH_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKET_FAILED,
  FETCH_TICKET_SUCCESS,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILED,
  UPDATE_TICKET_SUCCESS,
} from '../../constants';
import {
  AddCommentToTicketCmd,
  CreateTicketCmd,
  UpdateTicketCmd,
} from '../../model/cmds';
import { ListTicketsQuery, ListTicketsQueryModel } from '../../model/querys';
import { TicketState } from '../../model/state';
import {
  ticketCreate,
  ticketCreated,
  ticketCreateFailed,
  ticketDelete,
  ticketDeleted,
  ticketDeleteFailed,
  ticketFetch,
  ticketFetched,
  ticketFetchFailed,
  TicketsAction,
  ticketsFetch,
  ticketsFetched,
  ticketUpdate,
  ticketUpdated,
  ticketUpdateFailed,
} from '../actions/tickets';

export function createTicket(cmd: CreateTicketCmd) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketCreate());
      const ticket = await ticketApi.createTicket(cmd);
      dispatch(ticketCreated(ticket));
    } catch (e) {
      dispatch(ticketCreateFailed(e as Error));
    }
  };
}

export function getTicket(id: string) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketFetch());
      const ticket = await ticketApi.getTicket(id);
      dispatch(ticketFetched(ticket));
    } catch (e) {
      dispatch(ticketFetchFailed(e as Error));
    }
  };
}

export function addCommentAndUpdateTicket(
  id: string,
  commentCmd: AddCommentToTicketCmd,
  ticketCmd?: UpdateTicketCmd,
) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketUpdate());
      var ticket = await ticketApi.addComment(id, commentCmd);
      if (ticketCmd) {
        ticket = await ticketApi.updateTicket(id, ticketCmd);
      }
      dispatch(ticketUpdated(ticket));
    } catch (e) {
      dispatch(ticketUpdateFailed(e as Error));
    }
  };
}

export function removeCommentFromTicket(tId: string, cId: string) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketUpdate());
      var ticket = await ticketApi.removeComment(tId, cId);
      dispatch(ticketUpdated(ticket));
    } catch (e) {
      dispatch(ticketUpdateFailed(e as Error));
    }
  };
}

export function getTickets(query: ListTicketsQuery) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketsFetch(query));
      const tickets: ListTicketsQueryModel = await ticketApi.listTickets(query);
      dispatch(ticketsFetched(tickets, tickets.tickets));
    } catch (e) {
      dispatch(ticketFetchFailed(e as Error));
    }
  };
}

export function updateTicket(id: string, cmd: UpdateTicketCmd) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketUpdate());
      const ticket = await ticketApi.updateTicket(id, cmd);
      dispatch(ticketUpdated(ticket));
    } catch (e) {
      dispatch(ticketUpdateFailed(e as Error));
    }
  };
}

export function deleteTicket(id: string) {
  return async (dispatch: Dispatch<TicketsAction, {}, any>) => {
    try {
      dispatch(ticketDelete());
      const ticket = await ticketApi.deleteTicket(id);
      dispatch(ticketDeleted(ticket));
    } catch (e) {
      dispatch(ticketDeleteFailed(e as Error));
    }
  };
}
export default function ticketReducer(
  state: TicketState = {
    create: {
      loading: false,
      error: null,
      ticket: null,
    },
    list: {
      loading: false,
      error: null,
      tickets: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      ticket: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
      deleting: false,
      deleteSuccess: null,
      deleteError: null,
    },
  },
  action: TicketsAction,
): TicketState {
  switch (action.type) {
    case CREATE_TICKET:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          ticket: null,
        },
      };
    case CREATE_TICKET_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          ticket: action.payload,
        },
      };
    case CREATE_TICKET_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          ticket: null,
        },
      };
    case FETCH_TICKET:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          ticket: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_TICKET_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          ticket: action.payload,
        },
      };
    case FETCH_TICKET_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          error: action.payload,
          ticket: null,
        },
      };
    case UPDATE_TICKET:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ticket: action.payload,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_TICKET_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case DELETE_TICKET:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: true,
          deleteSuccess: null,
          deleteError: null,
        },
      };
    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: false,
          deleteSuccess: true,
          deleteError: null,
        },
      };
    case DELETE_TICKET_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: false,
          deleteSuccess: null,
          deleteError: action.payload,
        },
      };
    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          deleting: false,
          deleteSuccess: false,
          deleteError: null,
        },
      };
    case FETCH_TICKETS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          tickets: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };
    case FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          tickets: action.payload.tickets,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };
    case FETCH_TICKET_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          tickets: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { RootState } from '../../duck';
import {
  getAccount,
  getAccounts,
  updateAccountRole,
} from '../../duck/modules/accounts';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Typography,
  Button,
  Space,
  Result,
  notification,
  Descriptions,
  Select,
  message,
  Form,
  Switch,
  Tag,
} from 'antd';
import { updateAccount } from '../../duck/modules/accounts';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { AccountForm } from '../account/accountForm';
import {
  UpdateAccountCmd,
  AddAccountToOrganisationCmd,
} from '../../model/cmds';
import { JoinOrganisationForm } from '../account/joinOrganisationForm';
import { INT_MAX } from '../../constants';
import { createNotification } from '../../duck/modules/notifications';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { functionalColor } from '../../colors';
import { OrganisationModel } from '../../model/domain';
import {
  removeAccountFromOrganisation,
  addMemberToOrganisation,
  getOrganisations,
} from '../../duck/modules/organisations';
import { Store } from 'antd/lib/form/interface';
import { NotificationsTable } from '../notification/notificationsTable';
import { getDeepLinkForAppImpersonate } from '../../util';
import { NotificationType, Role } from '../../model/enums';
import { activateAccount, activateScoringAccount, activateVipAccount, deactivateAccount, deactivateScoringAccount, deactivateVipAccount } from '../../duck/modules/activation';
import { render } from '@testing-library/react';

const { Option } = Select;
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ accountId: string }>;

const UnconnectedAccountDetail: React.FC<Props> = ({
  getAccountConnect,
  getAccountsConnect,
  updateAccountConnect,
  updateAccountRoleConnect,
  createNotificationConnect,
  removeAccountFromOrganisationConnect,
  addMemberToOrganisationConnect,
  getOrganisationsConnect,
  notificationState,
  organisationState,
  account,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  updatingRole,
  updateRoleError,
  updateRoleSuccess,
  activationState,
  activateAccountConnect,
  deactivateAccountConnect,
  activateVipAccountConnect,
  deactivateVipAccountConnect,
  activateScoringAccountConnect,
  deactivateScoringAccountConnect
}) => {
  const { accountId } = match.params;
  const [form] = Form.useForm();
  const [triggerReload, setTriggerReload] = useState(false);

  React.useEffect(() => {
    getAccountConnect(accountId);
    getOrganisationsConnect({ limit: INT_MAX, offset: 0 });
  }, []);

  const renderAccountStatusButton = () => {
    if (account) {
      if (account.enabled) {
        return (
          <Button
            type="ghost"
            danger
            onClick={() => updateAccountConnect(accountId, { enabled: false })}
          >
            {'Disable'}
          </Button>
        );
      } else {
        return (
          <Button
            type="ghost"
            danger
            onClick={() => updateAccountConnect(accountId, { enabled: true })}
          >
            {'Enable'}
          </Button>
        );
      }
    }
  };

  const renderAccountAdminRoleButton = () => {
    if (account) {
      if (!account.roles.includes(Role.ROLE_HYDRONEO_GOD)) {
        return (
          <Button
            type="primary"
            onClick={() => {
              updateAccountRoleConnect(accountId, {
                roles: [Role.ROLE_FARMER, Role.ROLE_HYDRONEO_GOD],
              });
            }}
          >
            {'Add Admin Role'}
          </Button>
        );
      } else if (account.roles.includes(Role.ROLE_HYDRONEO_GOD)) {
        return (
          <Button
            type="primary"
            danger
            onClick={() => {
              updateAccountRoleConnect(accountId, {
                roles: [Role.ROLE_FARMER],
              });
            }}
          >
            {'Remove Admin Role'}
          </Button>
        );
      }
    }
  };

  const renderAccountVendorRoleButton = () => {
    if (account) {
      if (!account.roles.includes(Role.ROLE_VENDOR)) {
        return (
          <Button
            type="primary"
            onClick={() => {
              updateAccountRoleConnect(accountId, {
                roles: [Role.ROLE_VENDOR],
              });
            }}
          >
            {'Make Vendor'}
          </Button>
        );
      } else if (account.roles.includes(Role.ROLE_VENDOR)) {
        return (
          <Button
            type="primary"
            danger
            onClick={() => {
              updateAccountRoleConnect(accountId, {
                roles: [Role.ROLE_FARMER],
              });
            }}
          >
            {'Convert Vendor to Farmer'}
          </Button>
        );
      }
    }
  };

  const renderVipButton = () => {
    if (account) {
      if (!account.isVIP) {
        return (
          <Button
            type="primary"
            onClick={() => {
              activateVipAccountConnect(accountId);
            }}
          >
            {'Make VIP'}
          </Button>
        );
      } else if (account.isVIP) {
        return (
          <Button
            type="primary"
            danger
            onClick={() => {
              deactivateVipAccountConnect(accountId);
            }}
          >
            {'Remove VIP'}
          </Button>
        );
      }
    }
  };


  const renderCreditScoringButton = () => {
    if (account) {
      if (!account.creditScoringActive) {
        return (
          <Button
            type="primary"
            onClick={() => {
              activateScoringAccountConnect(accountId);
            }}
          >
            {'Enable Scoring'}
          </Button>
        );
      } else if (account.creditScoringActive) {
        return (
          <Button
            type="primary"
            danger
            onClick={() => {
              deactivateScoringAccountConnect(accountId);
            }}
          >
            {'Disable Scoring'}
          </Button>
        );
      }
    }
  };


  const renderCreditScoringTag = () => {
    if (account) {
      if (!account.creditScoringActive) {
        return (
          <Tag color="red">{'DISABLED'}</Tag>
        );
      } else if (account.creditScoringActive) {
        return (
          <Tag color="green">{'ACTIVE'}</Tag>
        );
      }
    }
  };

  const renderCreditScoringCardContent = () => {
    if (account && !loading) {
      return (
        <Descriptions bordered colon column={1}>
          <Descriptions.Item label="Status">{
            <Row style={{ alignItems: 'center', justifyContent: "space-between" }}>
              {renderCreditScoringTag()}
              {renderCreditScoringButton()}
            </Row>
          }</Descriptions.Item>

          <Descriptions.Item label="Financial Score">
            {account.financialScore ?
              <Descriptions bordered colon column={1}>
                <Descriptions.Item label="Score">{account.financialScore.score}</Descriptions.Item>
                <Descriptions.Item label="Details">{account.financialScore.details ?? "N/A"}</Descriptions.Item>
              </Descriptions>
              : 'N/A'}
          </Descriptions.Item>

          <Descriptions.Item label="Social Score">
            {account.socialScore ?
              <Descriptions bordered colon column={1}>
                <Descriptions.Item label="Score">{account.socialScore.score}</Descriptions.Item>
                <Descriptions.Item label="Details">{account.socialScore.details ?? "N/A"}</Descriptions.Item>
              </Descriptions>
              : 'N/A'}
          </Descriptions.Item>

          <Descriptions.Item label="Lifestyle Score">
            {account.lifestyleScore ?
              <Descriptions bordered colon column={1}>
                <Descriptions.Item label="Score">{account.lifestyleScore.score}</Descriptions.Item>
                <Descriptions.Item label="Details">{account.lifestyleScore.details ?? "N/A"}</Descriptions.Item>
              </Descriptions>
              : 'N/A'}
          </Descriptions.Item>

          <Descriptions.Item label="Health Score">
            {account.healthScore ?
              <Descriptions bordered colon column={1}>
                <Descriptions.Item label="Score">{account.healthScore.score}</Descriptions.Item>
                <Descriptions.Item label="Details">{account.healthScore.details ?? "N/A"}</Descriptions.Item>
              </Descriptions>
              : 'N/A'}
          </Descriptions.Item>

        </Descriptions>

      );
    }
  };

  const renderHubspotButton = () => {
    if (account) {
      return (
        <Button
          type="primary"
          onClick={() => {
            if (account?.crmContactId) {
              window.location.replace(
                `https://app.hubspot.com/contacts/5586633/contact/${account.crmContactId}`,
              );
            } else {
              notification.error({
                message: 'No Contact connected',
              });
            }
          }}
        >
          Got to Hubspot
        </Button>
      );
    }
  };

  const renderImpersonateButton = () => {
    if (account) {
      return (
        <a target="_blank" href={getDeepLinkForAppImpersonate(account.token)}>
          <Button type="primary" danger>
            Impersonate
          </Button>
        </a>
      );
    }
  };

  const renderTriggerNotificationButton = () => {
    if (account) {
      return (
        <Button
          type="primary"
          icon
          loading={notificationState.loading}
          onClick={() => {
            createNotificationConnect({
              accountId,
              type: NotificationType.TEST,
              isCritical: false,
            });
            setTriggerReload(true);
          }}
        >
          Trigger Notification
        </Button>
      );
    }
  };

  const renderTriggerCriticalNotificationButton = () => {
    if (account) {
      return (
        <Button
          type="primary"
          icon
          loading={notificationState.loading}
          onClick={() => {
            createNotificationConnect({
              accountId,
              type: NotificationType.TEST,
              isCritical: true,
            });
            setTriggerReload(true);
          }}
        >
          Trigger Critical Notification
        </Button>
      );
    }
  };

  const renderAccountCardContent = () => {
    if (account) {
      return (
        <AccountForm
          account={account}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onSave={(cmd: UpdateAccountCmd) => {
            updateAccountConnect(accountId, cmd);
            getAccountsConnect({ limit: INT_MAX, offset: 0 });
          }}
        />
      );
    }
  };

  const onLeaveOrganisation = (organisation: OrganisationModel) => {
    const isOrganisationCreator = organisation.creator.id === accountId;
    if (isOrganisationCreator) {
      notification.error({
        message: "Can't leave the organisation",
        description: 'This account is the creator!',
      });
    } else {
      form.resetFields();
      removeAccountFromOrganisationConnect(organisation.id, accountId);
    }
  };

  const onJoinOrganisation = (values: Store) => {
    const { organisationId } = values;
    const cmd: AddAccountToOrganisationCmd = { accountId };
    addMemberToOrganisationConnect(organisationId, cmd);
  };

  const renderHierachyCardContent = () => {
    if (account) {
      return (
        <Descriptions bordered column={1}>
          <DescriptionsItem label="Referral Code">
            {account?.referralCode ? (
              <Typography.Text copyable={{ tooltips: true }}>
                {account?.referralCode}
              </Typography.Text>
            ) : (
              <Typography>{'-'}</Typography>
            )}
          </DescriptionsItem>

          <DescriptionsItem label="Organisation">
            {account.organisation ? (
              <Space>
                <Link to={`/organisations/${account.organisation.id}`}>
                  {account?.organisation.name}
                </Link>
                <Button
                  type="ghost"
                  danger
                  onClick={() =>
                    account.organisation &&
                    onLeaveOrganisation(account.organisation)
                  }
                >
                  Leave
                </Button>
              </Space>
            ) : (
              <Form form={form} layout="inline" onFinish={onJoinOrganisation}>
                <Form.Item name="organisationId" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select Organisation"
                    allowClear
                    loading={organisationState.loading}
                  >
                    {organisationState.organisations.map((org) => (
                      <Option key={org.id} value={org.id}>
                        {org.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Join
                  </Button>
                </Form.Item>
              </Form>
            )}
          </DescriptionsItem>
        </Descriptions>
      );
    }
  };

  const activationWidget = () => {
    return (
      <Switch
        checked={account?.isActive}
        checkedChildren={"Active"}
        unCheckedChildren={"Inactive"}
        onClick={() => account?.isActive ? deactivateAccountConnect(accountId) : activateAccountConnect(accountId)}
        loading={activationState.loading}
      />
    );
  }

  const enabledWidget = () => {
    return (
      <Switch
        checked={account?.enabled}
        checkedChildren={"Enabled"}
        unCheckedChildren={"Disabled"}
        onClick={() => account?.enabled ? updateAccountConnect(accountId, { enabled: false }) : updateAccountConnect(accountId, { enabled: true })}
        loading={updating}
      />
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load account"
        subTitle={error.message}
      />
    );
  }

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{enabledWidget()}</Col>
              <Col>{activationWidget()}</Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{account && renderTriggerNotificationButton()}</Col>
              <Col>{account && renderTriggerCriticalNotificationButton()}</Col>
              <Col>{account && renderHubspotButton()}</Col>
              <Col>{account && renderImpersonateButton()}</Col>
              <Col>{account && renderAccountAdminRoleButton()}</Col>
              <Col>{account && renderAccountVendorRoleButton()}</Col>
              <Col>{account && renderVipButton()}</Col>

            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Card title="Account" loading={loading}>
                  {account && renderAccountCardContent()}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Card title="Hierarchy" loading={loading}>
                  {account && renderHierachyCardContent()}
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="Credit Scoring" loading={loading}>
                  {account && renderCreditScoringCardContent()}
                </Card>
              </Col>
              <Col xs={24}>
                <Card title="Notifitcations" loading={loading}>
                  <NotificationsTable
                    accountId={accountId}
                    reload={triggerReload}
                    onReloaded={() => setTriggerReload(false)}
                    pageSize={5}
                    hideAccount
                    hideOrganisation
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  account: state.accounts.detail.account,
  loading: state.accounts.detail.loading,
  error: state.accounts.detail.error,
  updating: state.accounts.update.updating,
  updateError: state.accounts.update.updateError,
  updateSuccess: state.accounts.update.updateSuccess,
  updatingRole: state.accounts.updateRole.updating,
  updateRoleError: state.accounts.updateRole.updateError,
  updateRoleSuccess: state.accounts.updateRole.updateSuccess,
  notificationState: state.notifications.create,
  organisationState: state.organisations.list,
  activationState: state.activation.account,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAccountConnect: getAccount,
      getAccountsConnect: getAccounts,
      updateAccountConnect: updateAccount,
      updateAccountRoleConnect: updateAccountRole,
      createNotificationConnect: createNotification,
      removeAccountFromOrganisationConnect: removeAccountFromOrganisation,
      addMemberToOrganisationConnect: addMemberToOrganisation,
      getOrganisationsConnect: getOrganisations,
      activateAccountConnect: activateAccount,
      deactivateAccountConnect: deactivateAccount,
      activateVipAccountConnect: activateVipAccount,
      deactivateVipAccountConnect: deactivateVipAccount,
      activateScoringAccountConnect: activateScoringAccount,
      deactivateScoringAccountConnect: deactivateScoringAccount,
    },
    dispatch,
  );
};

export const AccountDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAccountDetail);

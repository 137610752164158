import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Form, Input, Button, Select, Space, notification } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'antd/lib/form/interface';
import { createPond } from '../../duck/modules/ponds';

import { nameRule } from '../_util/validation';
import { getOrganisations } from '../../duck/modules/organisations';
import { usePrevious } from '../_util/hook';
import { PondShape, PondSlope } from '../../model/enums';
import { CreatePondCmd } from '../../model/cmds';
import { PondModel } from '../../model/domain';
import { getFarms } from '../../duck/modules/farms';
import { useState } from 'react';
import { INT_MAX } from '../../constants';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId?: string;
    farmId?: string;
    onCreated?: (pondModel: PondModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreatePondForm: React.FC<Props> = ({
  createPondConnect,
  getOrganisationsConnect,
  getFarmsConnect,
  onCreated,
  onError,
  loading,
  error,
  success,
  organisations,
  farms,
  organisationId: oId,
  farmId: fId,
}) => {
  const [organisationId, setOrganisationId] = useState(oId);
  const [farmId, setFarmId] = useState(fId);
  const [form] = Form.useForm();
  const prev = usePrevious({ success, error });

  React.useEffect(() => {
    if (!oId) {
      getOrganisationsConnect({ limit: INT_MAX, offset: 0 });
    }
  }, [organisationId]);

  React.useEffect(() => {
    if (!fId && organisationId) {
      getFarmsConnect({
        limit: INT_MAX,
        offset: 0,
        organisationIds: [organisationId],
      });
    }
  }, [farmId, organisationId]);

  React.useEffect(() => {
    if (prev?.success === null && success) {
      notification.success({
        message: 'Pond created',
      });
    }
    if (success && onCreated) {
      onCreated(success);
    }
  }, [success]);

  React.useEffect(() => {
    if (prev?.error === null && error) {
      notification.error({
        message: 'Pond creation error',
        description: error?.message,
      });
      if (error && onError) {
        onError(error);
      }
    }
  }, [error]);

  const onFinish = (values: Store) => {
    if (organisationId && farmId) {
      const {
        name,
        electricalSetup,
        widthInMeter,
        lengthInMeter,
        depthInMeter,
        shape,
        slope,
      } = values;

      const createCmd: CreatePondCmd = {
        organisationId,
        farmId,
        name,
        electricalVoltage: undefined,
        electricalPhases: undefined,
        widthInMeter,
        lengthInMeter,
        depthInMeter,
        shape,
        slope,
      };

      if (electricalSetup === '120V-1P') {
        createCmd.electricalVoltage = 120;
        createCmd.electricalPhases = 1;
      } else if (electricalSetup === '230V-1P') {
        createCmd.electricalVoltage = 230;
        createCmd.electricalPhases = 1;
      } else if (electricalSetup === '400V-3P') {
        createCmd.electricalVoltage = 400;
        createCmd.electricalPhases = 3;
      }

      createPondConnect(createCmd);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {!oId && (
        <Form.Item
          label="Organisation"
          name="organisationId"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select corresponding organisation"
            allowClear
            onChange={(value: string) => {
              setOrganisationId(value);
              form.setFieldsValue({ farmId: undefined });
            }}
          >
            {organisations.map((organisation) => (
              <Option key={organisation.id} value={organisation.id}>
                {organisation.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {!fId && organisationId && (
        <Form.Item label="Farm" name="farmId" rules={[{ required: true }]}>
          <Select
            placeholder="Select corresponding farm"
            allowClear
            onChange={(value: string) => {
              setFarmId(value);
            }}
          >
            {farms.map((farm) => (
              <Option key={farm.id} value={farm.id}>
                {farm.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {organisationId && farmId && (
        <Form.Item
          label="Pond name"
          name="name"
          rules={[nameRule, { required: true }]}
        >
          <Input placeholder="Pond #42" />
        </Form.Item>
      )}

      {organisationId && farmId && (
        <Form.Item label="Electrical setup" name="electricalSetup">
          <Select placeholder="Select voltage" allowClear>
            <Option value={'120V-1P'}>{'120V - 1 phase'}</Option>
            <Option value={'230V-1P'}>{'230V - 1 phase'}</Option>
            <Option value={'400V-3P'}>{'400V - 3 phases'}</Option>
          </Select>
        </Form.Item>
      )}

      {organisationId && farmId && (
        <Form.Item label="Width in Meter" name="widthInMeter">
          <Input type="number" placeholder="42.5" />
        </Form.Item>
      )}
      {organisationId && farmId && (
        <Form.Item label="Length in Meter" name="lengthInMeter">
          <Input type="number" placeholder="123" />
        </Form.Item>
      )}
      {organisationId && farmId && (
        <Form.Item label="Depth in Meter" name="depthInMeter">
          <Input type="number" placeholder="1.5" />
        </Form.Item>
      )}

      {organisationId && farmId && (
        <Form.Item label="Pond shape" name="shape">
          <Select placeholder="Select shape of the pond" allowClear>
            <Option value={PondShape.ELLIPSE}>{'Ellipse'}</Option>
            <Option value={PondShape.RECTANGLE}>{'Rectangle'}</Option>
            <Option value={PondShape.RIGHT_TRIANGLE}>{'Right triangle'}</Option>
          </Select>
        </Form.Item>
      )}

      {organisationId && farmId && (
        <Form.Item label="Pond slope" name="slope">
          <Select placeholder="Select slope of the pond" allowClear>
            <Option value={PondSlope.GENTLE}>{'Gentle'}</Option>
            <Option value={PondSlope.SLANTED}>{'Slanted'}</Option>
            <Option value={PondSlope.STRAIGHT}>{'Straight'}</Option>
          </Select>
        </Form.Item>
      )}

      {organisationId && farmId && (
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              Create Pond
            </Button>
          </Space>
        </Form.Item>
      )}
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.ponds.create.loading,
  error: state.ponds.create.error,
  success: state.ponds.create.pond,
  organisations: state.organisations.list.organisations,
  farms: state.farms.list.farms,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createPondConnect: createPond,
      getOrganisationsConnect: getOrganisations,
      getFarmsConnect: getFarms,
    },
    dispatch,
  );
};

export const CreatePondForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreatePondForm);

import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { sensingApi } from '../../api/sensingApi';
import { ticketApi } from '../../api/ticketApi';
import {
  CHECK_CALIBRATION,
  CHECK_CALIBRATION_FAILED,
  CHECK_CALIBRATION_SUCCESS,
  CREATE_TICKET,
  CREATE_TICKET_FAILED,
  CREATE_TICKET_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKET_FAILED,
  DELETE_TICKET_SUCCESS,
  FETCH_TICKET,
  FETCH_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKET_FAILED,
  FETCH_TICKET_SUCCESS,
  TRIGGER_CALIBRATION,
  TRIGGER_CALIBRATION_FAILED,
  TRIGGER_CALIBRATION_SUCCESS,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILED,
  UPDATE_TICKET_SUCCESS,
} from '../../constants';
import {
  AddCommentToTicketCmd,
  CalibrateSensorCmd,
  CreateTicketCmd,
  UpdateTicketCmd,
} from '../../model/cmds';
import { SensorType } from '../../model/enums';
import { ListTicketsQuery, ListTicketsQueryModel } from '../../model/querys';
import { CalibrationState, TicketState } from '../../model/state';
import {
  CalibrationAction,
  checkCalibration,
  checkCalibrationFailed,
  checkCalibrationSuccess,
  triggerCalibration,
  triggerCalibrationFailed,
  triggerCalibrationSuccess,
} from '../actions/calibration';

export function checkForCalibration(id: string) {
  return async (dispatch: Dispatch<CalibrationAction, {}, any>) => {
    try {
      dispatch(checkCalibration(id));

      const sensor = await sensingApi.getSensor(id);

      if (sensor.type === SensorType.YDS || sensor.type === SensorType.STOF) {
        // sensor can be calibrate
        dispatch(checkCalibrationSuccess(true, sensor));
      } else {
        // sensor can not be calibrated
        dispatch(checkCalibrationSuccess(false, sensor));
      }
    } catch (e) {
      dispatch(checkCalibrationFailed(e));
    }
  };
}

export function calibrateSensor(id: string, cmd: CalibrateSensorCmd) {
  return async (dispatch: Dispatch<CalibrationAction, {}, any>) => {
    try {
      dispatch(triggerCalibration(id, cmd));
      const log = await sensingApi.calibrateSensor(id, cmd);
      dispatch(triggerCalibrationSuccess(log));
    } catch (e) {
      dispatch(triggerCalibrationFailed(e));
    }
  };
}

export default function calibrationReducer(
  state: CalibrationState = {
    calibrateable: {
      loading: false,
      sensor: null,
      result: null,
      error: null,
    },
    trigger: {
      cmd: null,
      error: null,
      loading: false,
      log: null,
      sensorId: null,
    },
  },
  action: CalibrationAction,
): CalibrationState {
  switch (action.type) {
    case CHECK_CALIBRATION:
      return {
        ...state,
        calibrateable: {
          ...state.calibrateable,
          loading: true,
          result: null,
          sensor: null,
          error: null,
        },
      };

    case CHECK_CALIBRATION_SUCCESS:
      return {
        ...state,
        calibrateable: {
          ...state.calibrateable,
          loading: false,
          result: action.payload.result,
          sensor: action.payload.sensor,
          error: null,
        },
      };

    case CHECK_CALIBRATION_FAILED:
      return {
        ...state,
        calibrateable: {
          ...state.calibrateable,
          loading: false,
          result: null,
          sensor: null,
          error: action.payload,
        },
      };
    case TRIGGER_CALIBRATION:
      return {
        ...state,
        trigger: {
          ...state.trigger,
          cmd: action.payload.cmd,
          sensorId: action.payload.sensorId,
          loading: true,
          error: null,
          log: null,
        },
      };
    case TRIGGER_CALIBRATION_SUCCESS:
      return {
        ...state,
        trigger: {
          ...state.trigger,
          loading: false,
          error: null,
          log: action.payload,
        },
      };
    case TRIGGER_CALIBRATION_FAILED:
      return {
        ...state,
        trigger: {
          ...state.trigger,
          loading: false,
          error: action.payload,
          log: null,
        },
      };
    default:
      return state;
  }
}

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateSampleCmd, UpdateSampleCmd } from '../model/cmds';
import { SampleModel } from '../model/domain';
import { ListSamplesQuery, ListSamplesQueryModel } from '../model/querys';

export class SampleApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createSample = this.createSample.bind(this);
    this.fetchSample = this.fetchSample.bind(this);
    this.listSamples = this.listSamples.bind(this);
    this.updateSample = this.updateSample.bind(this);
  }

  public createSample(
    pondId: string,
    cmd: CreateSampleCmd,
  ): Promise<SampleModel> {
    return this.post<SampleModel, CreateSampleCmd, AxiosResponse<SampleModel>>(
      `/v1/ponds/${pondId}/samples`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchSample(sampleId: string): Promise<SampleModel> {
    return this.get<SampleModel, AxiosResponse<SampleModel>>(
      `/v1/samples/${sampleId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listSamples(cmd?: ListSamplesQuery): Promise<ListSamplesQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListSamplesQueryModel,
      AxiosResponse<ListSamplesQueryModel>
    >('/v1/samples', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateSample(
    sampleId: string,
    cmd: UpdateSampleCmd,
  ): Promise<SampleModel> {
    return this.patch<SampleModel, UpdateSampleCmd, AxiosResponse<SampleModel>>(
      `/v1/samples/${sampleId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteSample(sampleId: string): Promise<void> {
    return this.delete<SampleModel, AxiosResponse<void>>(
      `/v1/samples/${sampleId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const sampleApi = new SampleApi();

export enum Language {
  ENGLISH = 'ENGLISH',
  THAI = 'THAI',
}

export enum Role {
  ROLE_HYDRONEO_GOD = 'ROLE_HYDRONEO_GOD',
  ROLE_FARMER = 'ROLE_FARMER',
  ROLE_VENDOR = 'ROLE_VENDOR'
}

export enum Gender {
  NOT_SET = 'NOT_SET',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum PondShape {
  RECTANGLE = 'RECTANGLE',
  ELLIPSE = 'ELLIPSE',
  RIGHT_TRIANGLE = 'RIGHT_TRIANGLE',
}

export enum PondSlope {
  STRAIGHT = 'STRAIGHT',
  SLANTED = 'SLANTED',
  GENTLE = 'GENTLE',
}

export enum SensorClass {
  NOT_SET = 0,
  ANALOG = 1,
  DIGITAL = 2,
}

export enum SensorType {
  NOT_SET = 'NOT_SET',
  YDS = 'YDS',
  IDS = 'IDS',
  SAP = 'SAP',
  SES = 'SES',
  YSS = 'YSS',
  OMS = 'OMS',
  OCS = 'OCS',
  NDP = 'NDP',
  OGPS = 'OGPS',
  STOF = 'STOF',
  LUX = 'LUX',
}

// DIGITAL_1 to DIGITAL_254 exept 11,12,13,14,21
export enum DeviceSocket {
  NOT_SET = 'NOT_SET',
  ANALOG_1 = 'ANALOG_1',
  ANALOG_2 = 'ANALOG_2',
  ANALOG_3 = 'ANALOG_3',
  ANALOG_4 = 'ANALOG_4',
  EC_1 = 'EC_1',
  DIGITAL_1 = 'DIGITAL_1',
  DIGITAL_2 = 'DIGITAL_2',
  DIGITAL_3 = 'DIGITAL_3',
  DIGITAL_4 = 'DIGITAL_4',
  DIGITAL_5 = 'DIGITAL_5',
  DIGITAL_6 = 'DIGITAL_6',
  DIGITAL_7 = 'DIGITAL_7',
  DIGITAL_8 = 'DIGITAL_8',
  DIGITAL_9 = 'DIGITAL_9',
  DIGITAL_10 = 'DIGITAL_10',
  DIGITAL_15 = 'DIGITAL_15',
  DIGITAL_16 = 'DIGITAL_16',
  DIGITAL_17 = 'DIGITAL_17',
  DIGITAL_18 = 'DIGITAL_18',
  DIGITAL_19 = 'DIGITAL_19',
  DIGITAL_20 = 'DIGITAL_20',
  DIGITAL_22 = 'DIGITAL_22',
  DIGITAL_23 = 'DIGITAL_23',
  DIGITAL_24 = 'DIGITAL_24',
  DIGITAL_25 = 'DIGITAL_25',
  DIGITAL_26 = 'DIGITAL_26',
  DIGITAL_27 = 'DIGITAL_27',
  DIGITAL_28 = 'DIGITAL_28',
  DIGITAL_29 = 'DIGITAL_29',
  DIGITAL_30 = 'DIGITAL_30',
  DIGITAL_31 = 'DIGITAL_31',
  DIGITAL_32 = 'DIGITAL_32',
  DIGITAL_33 = 'DIGITAL_33',
  DIGITAL_34 = 'DIGITAL_34',
  DIGITAL_35 = 'DIGITAL_35',
  DIGITAL_36 = 'DIGITAL_36',
  DIGITAL_37 = 'DIGITAL_37',
  DIGITAL_38 = 'DIGITAL_38',
  DIGITAL_39 = 'DIGITAL_39',
  DIGITAL_40 = 'DIGITAL_40',
  DIGITAL_41 = 'DIGITAL_41',
  DIGITAL_42 = 'DIGITAL_42',
  DIGITAL_43 = 'DIGITAL_43',
  DIGITAL_44 = 'DIGITAL_44',
  DIGITAL_45 = 'DIGITAL_45',
  DIGITAL_46 = 'DIGITAL_46',
  DIGITAL_47 = 'DIGITAL_47',
  DIGITAL_48 = 'DIGITAL_48',
  DIGITAL_49 = 'DIGITAL_49',
  DIGITAL_50 = 'DIGITAL_50',
  DIGITAL_51 = 'DIGITAL_51',
  DIGITAL_52 = 'DIGITAL_52',
  DIGITAL_53 = 'DIGITAL_53',
  DIGITAL_54 = 'DIGITAL_54',
  DIGITAL_55 = 'DIGITAL_55',
  DIGITAL_56 = 'DIGITAL_56',
  DIGITAL_57 = 'DIGITAL_57',
  DIGITAL_58 = 'DIGITAL_58',
  DIGITAL_59 = 'DIGITAL_59',
  DIGITAL_60 = 'DIGITAL_60',
  DIGITAL_61 = 'DIGITAL_61',
  DIGITAL_62 = 'DIGITAL_62',
  DIGITAL_63 = 'DIGITAL_63',
  DIGITAL_64 = 'DIGITAL_64',
  DIGITAL_65 = 'DIGITAL_65',
  DIGITAL_66 = 'DIGITAL_66',
  DIGITAL_67 = 'DIGITAL_67',
  DIGITAL_68 = 'DIGITAL_68',
  DIGITAL_69 = 'DIGITAL_69',
  DIGITAL_70 = 'DIGITAL_70',
  DIGITAL_71 = 'DIGITAL_71',
  DIGITAL_72 = 'DIGITAL_72',
  DIGITAL_73 = 'DIGITAL_73',
  DIGITAL_74 = 'DIGITAL_74',
  DIGITAL_75 = 'DIGITAL_75',
  DIGITAL_76 = 'DIGITAL_76',
  DIGITAL_77 = 'DIGITAL_77',
  DIGITAL_78 = 'DIGITAL_78',
  DIGITAL_79 = 'DIGITAL_79',
  DIGITAL_80 = 'DIGITAL_80',
  DIGITAL_81 = 'DIGITAL_81',
  DIGITAL_82 = 'DIGITAL_82',
  DIGITAL_83 = 'DIGITAL_83',
  DIGITAL_84 = 'DIGITAL_84',
  DIGITAL_85 = 'DIGITAL_85',
  DIGITAL_86 = 'DIGITAL_86',
  DIGITAL_87 = 'DIGITAL_87',
  DIGITAL_88 = 'DIGITAL_88',
  DIGITAL_89 = 'DIGITAL_89',
  DIGITAL_90 = 'DIGITAL_90',
  DIGITAL_91 = 'DIGITAL_91',
  DIGITAL_92 = 'DIGITAL_92',
  DIGITAL_93 = 'DIGITAL_93',
  DIGITAL_94 = 'DIGITAL_94',
  DIGITAL_95 = 'DIGITAL_95',
  DIGITAL_96 = 'DIGITAL_96',
  DIGITAL_97 = 'DIGITAL_97',
  DIGITAL_98 = 'DIGITAL_98',
  DIGITAL_99 = 'DIGITAL_99',
  DIGITAL_100 = 'DIGITAL_100',
  DIGITAL_101 = 'DIGITAL_101',
  DIGITAL_102 = 'DIGITAL_102',
  DIGITAL_103 = 'DIGITAL_103',
  DIGITAL_104 = 'DIGITAL_104',
  DIGITAL_105 = 'DIGITAL_105',
  DIGITAL_106 = 'DIGITAL_106',
  DIGITAL_107 = 'DIGITAL_107',
  DIGITAL_108 = 'DIGITAL_108',
  DIGITAL_109 = 'DIGITAL_109',
  DIGITAL_110 = 'DIGITAL_110',
  DIGITAL_111 = 'DIGITAL_111',
  DIGITAL_112 = 'DIGITAL_112',
  DIGITAL_113 = 'DIGITAL_113',
  DIGITAL_114 = 'DIGITAL_114',
  DIGITAL_115 = 'DIGITAL_115',
  DIGITAL_116 = 'DIGITAL_116',
  DIGITAL_117 = 'DIGITAL_117',
  DIGITAL_118 = 'DIGITAL_118',
  DIGITAL_119 = 'DIGITAL_119',
  DIGITAL_120 = 'DIGITAL_120',
  DIGITAL_121 = 'DIGITAL_121',
  DIGITAL_122 = 'DIGITAL_122',
  DIGITAL_123 = 'DIGITAL_123',
  DIGITAL_124 = 'DIGITAL_124',
  DIGITAL_125 = 'DIGITAL_125',
  DIGITAL_126 = 'DIGITAL_126',
  DIGITAL_127 = 'DIGITAL_127',
  DIGITAL_128 = 'DIGITAL_128',
  DIGITAL_129 = 'DIGITAL_129',
  DIGITAL_130 = 'DIGITAL_130',
  DIGITAL_131 = 'DIGITAL_131',
  DIGITAL_132 = 'DIGITAL_132',
  DIGITAL_133 = 'DIGITAL_133',
  DIGITAL_134 = 'DIGITAL_134',
  DIGITAL_135 = 'DIGITAL_135',
  DIGITAL_136 = 'DIGITAL_136',
  DIGITAL_137 = 'DIGITAL_137',
  DIGITAL_138 = 'DIGITAL_138',
  DIGITAL_139 = 'DIGITAL_139',
  DIGITAL_140 = 'DIGITAL_140',
  DIGITAL_141 = 'DIGITAL_141',
  DIGITAL_142 = 'DIGITAL_142',
  DIGITAL_143 = 'DIGITAL_143',
  DIGITAL_144 = 'DIGITAL_144',
  DIGITAL_145 = 'DIGITAL_145',
  DIGITAL_146 = 'DIGITAL_146',
  DIGITAL_147 = 'DIGITAL_147',
  DIGITAL_148 = 'DIGITAL_148',
  DIGITAL_149 = 'DIGITAL_149',
  DIGITAL_150 = 'DIGITAL_150',
  DIGITAL_151 = 'DIGITAL_151',
  DIGITAL_152 = 'DIGITAL_152',
  DIGITAL_153 = 'DIGITAL_153',
  DIGITAL_154 = 'DIGITAL_154',
  DIGITAL_155 = 'DIGITAL_155',
  DIGITAL_156 = 'DIGITAL_156',
  DIGITAL_157 = 'DIGITAL_157',
  DIGITAL_158 = 'DIGITAL_158',
  DIGITAL_159 = 'DIGITAL_159',
  DIGITAL_160 = 'DIGITAL_160',
  DIGITAL_161 = 'DIGITAL_161',
  DIGITAL_162 = 'DIGITAL_162',
  DIGITAL_163 = 'DIGITAL_163',
  DIGITAL_164 = 'DIGITAL_164',
  DIGITAL_165 = 'DIGITAL_165',
  DIGITAL_166 = 'DIGITAL_166',
  DIGITAL_167 = 'DIGITAL_167',
  DIGITAL_168 = 'DIGITAL_168',
  DIGITAL_169 = 'DIGITAL_169',
  DIGITAL_170 = 'DIGITAL_170',
  DIGITAL_171 = 'DIGITAL_171',
  DIGITAL_172 = 'DIGITAL_172',
  DIGITAL_173 = 'DIGITAL_173',
  DIGITAL_174 = 'DIGITAL_174',
  DIGITAL_175 = 'DIGITAL_175',
  DIGITAL_176 = 'DIGITAL_176',
  DIGITAL_177 = 'DIGITAL_177',
  DIGITAL_178 = 'DIGITAL_178',
  DIGITAL_179 = 'DIGITAL_179',
  DIGITAL_180 = 'DIGITAL_180',
  DIGITAL_181 = 'DIGITAL_181',
  DIGITAL_182 = 'DIGITAL_182',
  DIGITAL_183 = 'DIGITAL_183',
  DIGITAL_184 = 'DIGITAL_184',
  DIGITAL_185 = 'DIGITAL_185',
  DIGITAL_186 = 'DIGITAL_186',
  DIGITAL_187 = 'DIGITAL_187',
  DIGITAL_188 = 'DIGITAL_188',
  DIGITAL_189 = 'DIGITAL_189',
  DIGITAL_190 = 'DIGITAL_190',
  DIGITAL_191 = 'DIGITAL_191',
  DIGITAL_192 = 'DIGITAL_192',
  DIGITAL_193 = 'DIGITAL_193',
  DIGITAL_194 = 'DIGITAL_194',
  DIGITAL_195 = 'DIGITAL_195',
  DIGITAL_196 = 'DIGITAL_196',
  DIGITAL_197 = 'DIGITAL_197',
  DIGITAL_198 = 'DIGITAL_198',
  DIGITAL_199 = 'DIGITAL_199',
  DIGITAL_200 = 'DIGITAL_200',
  DIGITAL_201 = 'DIGITAL_201',
  DIGITAL_202 = 'DIGITAL_202',
  DIGITAL_203 = 'DIGITAL_203',
  DIGITAL_204 = 'DIGITAL_204',
  DIGITAL_205 = 'DIGITAL_205',
  DIGITAL_206 = 'DIGITAL_206',
  DIGITAL_207 = 'DIGITAL_207',
  DIGITAL_208 = 'DIGITAL_208',
  DIGITAL_209 = 'DIGITAL_209',
  DIGITAL_210 = 'DIGITAL_210',
  DIGITAL_211 = 'DIGITAL_211',
  DIGITAL_212 = 'DIGITAL_212',
  DIGITAL_213 = 'DIGITAL_213',
  DIGITAL_214 = 'DIGITAL_214',
  DIGITAL_215 = 'DIGITAL_215',
  DIGITAL_216 = 'DIGITAL_216',
  DIGITAL_217 = 'DIGITAL_217',
  DIGITAL_218 = 'DIGITAL_218',
  DIGITAL_219 = 'DIGITAL_219',
  DIGITAL_220 = 'DIGITAL_220',
  DIGITAL_221 = 'DIGITAL_221',
  DIGITAL_222 = 'DIGITAL_222',
  DIGITAL_223 = 'DIGITAL_223',
  DIGITAL_224 = 'DIGITAL_224',
  DIGITAL_225 = 'DIGITAL_225',
  DIGITAL_226 = 'DIGITAL_226',
  DIGITAL_227 = 'DIGITAL_227',
  DIGITAL_228 = 'DIGITAL_228',
  DIGITAL_229 = 'DIGITAL_229',
  DIGITAL_230 = 'DIGITAL_230',
  DIGITAL_231 = 'DIGITAL_231',
  DIGITAL_232 = 'DIGITAL_232',
  DIGITAL_233 = 'DIGITAL_233',
  DIGITAL_234 = 'DIGITAL_234',
  DIGITAL_235 = 'DIGITAL_235',
  DIGITAL_236 = 'DIGITAL_236',
  DIGITAL_237 = 'DIGITAL_237',
  DIGITAL_238 = 'DIGITAL_238',
  DIGITAL_239 = 'DIGITAL_239',
  DIGITAL_240 = 'DIGITAL_240',
  DIGITAL_241 = 'DIGITAL_241',
  DIGITAL_242 = 'DIGITAL_242',
  DIGITAL_243 = 'DIGITAL_243',
  DIGITAL_244 = 'DIGITAL_244',
  DIGITAL_245 = 'DIGITAL_245',
  DIGITAL_246 = 'DIGITAL_246',
  DIGITAL_247 = 'DIGITAL_247',
  DIGITAL_248 = 'DIGITAL_248',
  DIGITAL_249 = 'DIGITAL_249',
  DIGITAL_250 = 'DIGITAL_250',
  DIGITAL_251 = 'DIGITAL_251',
  DIGITAL_252 = 'DIGITAL_252',
  DIGITAL_253 = 'DIGITAL_253',
  DIGITAL_254 = 'DIGITAL_254',
}

export enum SensingMode {
  NOT_SET = 'NOT_SET',
  TIME_BASED = 'TIME_BASED',
}

export enum RelayMode {
  NOT_SET = 'NOT_SET',
  SIMPLE = 'SIMPLE',
  SCHEDULED = 'SCHEDULED',
}

export enum RelayConnectionType {
  NOT_SET = 'NOT_SET',
  RELAY_BOARD = 'RELAY_BOARD',
  SMART_RELAY = 'SMART_RELAY',
}

export enum RelayboardId {
  NOT_SET = 'NOT_SET',
  RELAY_1 = 'RELAY_1',
  RELAY_2 = 'RELAY_2',
  RELAY_3 = 'RELAY_3',
  RELAY_4 = 'RELAY_4',
  RELAY_5 = 'RELAY_5',
  RELAY_6 = 'RELAY_6',
  RELAY_7 = 'RELAY_7',
  RELAY_8 = 'RELAY_8',
  RELAY_9 = 'RELAY_9',
  RELAY_10 = 'RELAY_10',
  RELAY_11 = 'RELAY_11',
  RELAY_12 = 'RELAY_12',
  RELAY_13 = 'RELAY_13',
  RELAY_14 = 'RELAY_14',
  RELAY_15 = 'RELAY_15',
  RELAY_16 = 'RELAY_16',
}

export enum RelayState {
  NOT_SET = 'NOT_SET',
  OFF = 'OFF',
  ON = 'ON',
}

export enum SmartRelayboardId {
  NOT_SET = 'NOT_SET',
  RELAY_1 = 'RELAY_1',
  RELAY_2 = 'RELAY_2',
}

export enum AeratorGroup {
  NOT_SET = 'NOT_SET',
  GROUP_1 = 'GROUP_1',
  GROUP_2 = 'GROUP_2',
  GROUP_3 = 'GROUP_3',
  GROUP_4 = 'GROUP_4',
  GROUP_5 = 'GROUP_5',
  GROUP_6 = 'GROUP_6',
  GROUP_7 = 'GROUP_7',
  GROUP_8 = 'GROUP_8',
  GROUP_9 = 'GROUP_9',
  GROUP_10 = 'GROUP_10',
  GROUP_11 = 'GROUP_11',
  GROUP_12 = 'GROUP_12',
}

export enum MqttConnectionStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export enum TimeUnit {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
}

export enum AggregationOperation {
  MIN = 'MIN',
  MAX = 'MAX',
  MEAN = 'MEAN',
  MEDIAN = 'MEDIAN',
}

export enum GatewayStateField {
  AERATOR = 'AERATOR',
  GATEWAY = 'GATEWAY',
  LCM = 'LCM',
  POND = 'POND',
  SENSOR = 'SENSOR',
  SMART_RELAY = 'SMART_RELAY',
  SMART_SENSOR = 'SMART_SENSOR',
  PUMP = 'PUMP',
}

export enum GatewayType {
  NOT_SET = 'NOT_SET',
  CONTROLLER_V0 = 'CONTROLLER_V0',
  CONTROLLER_V1 = 'CONTROLLER_V1',
  CONTROLLER_V2 = 'CONTROLLER_V2',
  CONTROLLER_V3 = 'CONTROLLER_V3',
}

export enum ControllerProductVersion {
  V0 = 'V0',
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export enum ControllerRelayboardVersion {
  NOT_SET = 'NOT_SET',
  V1 = 'V1',
  V3 = 'V3',
  V3_LATCHING = 'V3_LATCHING',
}

export enum ControllerUsage {
  SMART_SHRIMP_FARMING = 'SMART_SHRIMP_FARMING',
  SMART_FISH_FARMING = 'SMART_FISH_FARMING',
  INDUSTRIAL = 'INDUSTRIAL',
  RESEARCH_AND_DEVELOPMENT = "RESEARCH_AND_DEVELOPMENT",
}

export enum TimeZone {
  UTC = 'UTC',
  GERMANY = 'GERMANY',
  THAILAND = 'THAILAND',
  INDONESIA_WESTERN = 'INDONESIA_WESTERN',
  INDONESIA_CENTRAL = 'INDONESIA_CENTRAL',
  INDONESIA_EASTERN = 'INDONESIA_EASTERN',
  JAPAN = 'JAPAN',
  MALAYSIA = 'MALAYSIA',
  SINGAPORE = 'SINGAPORE',
  VIETNAM = 'VIETNAM',
}

export enum Currency {
  BATH = 'BATH',
  EURO = 'EURO',
}

export enum AeratorAutomation {
  NOT_SET = 'NOT_SET',
  ON_SITE = 'ON_SITE',
  MANUAL = 'MANUAL',
  PARTIAL_AUTOMATION = 'PARTIAL_AUTOMATION',
  FULL_AUTOMATION = 'FULL_AUTOMATION',
}

export enum NotificationType {
  GATEWAY_OFFLINE = 'GATEWAY_OFFLINE',
  SENSOR_VALUE_DROPPED_BELOW_CRITICAL = 'SENSOR_VALUE_DROPPED_BELOW_CRITICAL',
  SENSOR_VALUE_DROPPED_BELOW_REMINDER = 'SENSOR_VALUE_DROPPED_BELOW_REMINDER',
  SENSOR_VALUE_RAISED_ABOVE_REMINDER = 'SENSOR_VALUE_RAISED_ABOVE_REMINDER',
  SENSOR_VALUE_RAISED_ABOVE_CRITICAL = 'SENSOR_VALUE_RAISED_ABOVE_CRITICAL',
  TEST = 'TEST',
}

export enum NotificationStatus {
  READY = 'READY',
  SENT = 'SENT',
  PARTIAL_SENT = 'PARTIAL_SENT',
  FAILED = 'FAILED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}

export enum NotificationChannel {
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  IN_APP_NOTIFICATION = 'IN_APP_NOTIFICATION',
  SLACK_SYS_OP = 'SLACK_SYS_OP',
  SLACK_BIZ_OP = 'SLACK_BIZ_OP',
}

export enum CycleState {
  NOT_SET = 'NOT_SET',
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
}

export enum SampleType {
  SHRIMP_LENGTH = 'SHRIMP_LENGTH',
  SHRIMP_WEIGHT = 'SHRIMP_WEIGHT',
  SHRIMP_BUCKET = 'SHRIMP_BUCKET',
}

export enum AeratorGroupRelayState {
  NOT_SET = 'NOT_SET',
  ON = 'ON',
  OFF = 'OFF',
  MIXED = 'MIXED',
}

export enum PowerSource {
  NOT_SET = 'NOT_SET',
  POWER_LINE = 'POWER_LINE',
  UPS = 'UPS',
}

export enum AeratorType {
  NOT_SET = 'NOT_SET',
  BUBBLES = 'BUBBLES',
  NANO_BUBBLES = 'NANO_BUBBLES',
  PADDLE_WHEEL = 'PADDLE_WHEEL',
  SUBMERGED_PROPELLER = 'SUBMERGED_PROPELLER',
  SUBMERGED_TURBINE = 'SUBMERGED_TURBINE',
}

export enum CalibrationState {
  NOT_SET = 'NOT_SET',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  NO_ANSWER_IN_TIME = 'NO_ANSWER_IN_TIME',
  REMOTE_STARTED = 'REMOTE_STARTED',
}

export enum CalibrationStep {
  NOT_SET = 'NOT_SET',
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
}

export enum CalibrationFailure {
  NOT_SET = 'NOT_SET',
  TIME_OUT = 'TIME_OUT',
  CALIBRATION_ALREADY_RUNNING = 'CALIBRATION_ALREADY_RUNNING',
  ANOTHER_CALIBRATION_IS_RUNNING = 'ANOTHER_CALIBRATION_IS_RUNNING',
  WRONG_CALIBRATION_SOLUTION = 'WRONG_CALIBRATION_SOLUTION',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  UNKNOWN = 'UNKNOWN',
  CALIBRATION_STEP_NOT_SUPPORTED = 'CALIBRATION_STEP_NOT_SUPPORTED',
}

export enum CodingFactor {
  NOT_SET = 'NOT_SET',
  RATE_4_5 = 'RATE_4_5',
  RATE_4_6 = 'RATE_4_6',
  RATE_4_7 = 'RATE_4_7',
  RATE_4_8 = 'RATE_4_8',
}

export enum Bandwidth {
  NOT_SET = 'NOT_SET',
  BW_125KHZ = 'BW_125KHZ',
  BW_250KHZ = 'BW_250KHZ',
}

export enum SpreadingFactor {
  NOT_SET = 'NOT_SET',
  FACTOR_7 = 'FACTOR_7',
  FACTOR_8 = 'FACTOR_8',
  FACTOR_9 = 'FACTOR_9',
  FACTOR_10 = 'FACTOR_10',
  FACTOR_11 = 'FACTOR_11',
  FACTOR_12 = 'FACTOR_12',
}

export enum PairingStatus {
  NOT_SET = 'NOT_SET',
  PAIRED = 'PAIRED',
  PAIRING = 'PAIRING',
}

export enum SensorConnectionType {
  NOT_SET = 'NOT_SET',
  MAIN_BOARD = 'MAIN_BOARD',
  SMART_SENSOR = 'SMART_SENSOR',
}

export enum SmartRelaySwitchButtonStatus {
  OFF = 'OFF',
  ON = 'ON',
  AUTO = 'AUTO',
}

export enum ScheduleType {
  NOT_SCHEDULE = 'NOT_SCHEDULE',
  ACTIVE = 'ACTIVE',
  REPETITION = 'REPETITION',
}

export enum PumpMode {
  NOT_SET = 'NOT_SET',
  MANUAL_ON = 'MANUAL_ON',
  MANUAL_OFF = 'MANUAL_OFF',
  SCHEDULES = 'SCHEDULES',
}

export enum ProductCategory {
  FEED = 'FEED',
  FEED_SUPPLEMENT = 'FEED_SUPPLEMENT',
  CHEMICAL = 'CHEMICAL',
}

export enum AnnouncementType {
  NOT_SET = 'NOT_SET',
  INFO = 'INFO',
  MAINTENANCE = 'MAINTENANCE',
}

export enum MessageType {
  GATEWAY_OFFLINE = 'GATEWAY_OFFLINE',
  GATEWAY_ON_UPS = 'GATEWAY_ON_UPS',

  SENSOR_VALUE_DROPPED_BELOW_CRITICAL = 'SENSOR_VALUE_DROPPED_BELOW_CRITICAL',
  SENSOR_VALUE_DROPPED_BELOW_REMINDER = 'SENSOR_VALUE_DROPPED_BELOW_REMINDER',
  SENSOR_VALUE_RAISED_ABOVE_REMINDER = 'SENSOR_VALUE_RAISED_ABOVE_REMINDER',
  SENSOR_VALUE_RAISED_ABOVE_CRITICAL = 'SENSOR_VALUE_RAISED_ABOVE_CRITICAL',

  OCS_VALUE_RAISED_ABOVE_REMINDER = 'OCS_VALUE_RAISED_ABOVE_REMINDER',
  OMS_VALUE_RAISED_ABOVE_REMINDER = 'OMS_VALUE_RAISED_ABOVE_REMINDER',

  POP_UP = 'POP_UP',

  TEST = 'TEST',
}

export enum PopUpType {
  GENERAL_POP_UP = 'GENERAL_POP_UP',
  USER_POP_UP = 'USER_POP_UP',
  ORGANISATION_POP_UP = 'ORGANISATION_POP_UP',
  NOT_SET = 'NOT_SET',
}

export enum PopUpRule {
  FARM = 'FARM',
  POND = 'POND',
  ACCOUNT = 'ACCOUNT',
  SHOW_ONCE = 'SHOW_ONCE',
  REPEAT_ONE_DAILY = 'REPEAT_ONE_DAILY',
  SENSOR = 'SENSOR',
  TNC = 'TNC',
}

export enum PopUpAction {
  FARM = 'FARM',
  POND = 'POND',
  ACCOUNT = 'ACCOUNT',
  SHOW_ONCE = 'SHOW_ONCE',
  SENSOR = 'SENSOR',
  TNC = 'TNC',
}

export enum TicketStatus {
  OPEN = 'OPEN',
  WIP = 'WIP',
  SOLVED = 'SOLVED',
}

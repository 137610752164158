import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Form, Input, Button, Space, notification } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from '../_util/hook';
import { FeedTableModel } from '../../model/domain';
import { createFeedTable } from '../../duck/modules/feedTables';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (farm: FeedTableModel) => void;
    onError?: (_error: Error) => void;
  };

const UnconnectedCreateFeedTableForm: React.FC<Props> = ({
  createFeedTableConnect,
  loading,
  error,
  success,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ success, error });

  React.useEffect(() => {
    if (prev?.success === null && success) {
      notification.success({
        message: 'Feed table created',
      });
      form.resetFields();
      if (onCreated) {
        onCreated(success);
      }
    }
  }, [success]);

  React.useEffect(() => {
    if (prev?.error === null && error) {
      notification.error({
        message: 'Feed table creation error',
        description: error?.message,
      });
      if (onError) {
        onError(error);
      }
    }
  }, [error]);

  const onFinish = (values: Store) => {
    const { name } = values;
    createFeedTableConnect({ name });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="DOF Feed Table" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Feed Table
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.feedTables.create.loading,
  error: state.feedTables.create.error,
  success: state.feedTables.create.feedTable,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createFeedTableConnect: createFeedTable,
    },
    dispatch,
  );
};

export const CreateFeedTableForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateFeedTableForm);

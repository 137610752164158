import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { ManufacturerModel } from '../../model/domain';
import { CreateManufacturerCmd } from '../../model/cmds';
import { createManufacturer } from '../../duck/modules/manufacturers';
import { getManufacturerOptions } from '../../duck/modules/options';
import { INT_MAX } from '../../constants';
import { ProductCategory } from '../../model/enums';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (manufacturer: ManufacturerModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateManufacturerForm: React.FC<Props> = ({
  createManufacturerConnect,
  manufacturerCreateState,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (manufacturerCreateState.manufacturer) {
      notification.success({
        message: 'Manufacturer created',
      });
      onReset();
      if (onCreated != null) {
        onCreated(manufacturerCreateState.manufacturer);
      }
    }
  }, [manufacturerCreateState.manufacturer]);

  React.useEffect(() => {
    if (manufacturerCreateState.error) {
      notification.error({
        message: 'Manufacturer creation error',
        description: manufacturerCreateState.error?.message,
      });
      if (onError != null) {
        onError(manufacturerCreateState.error);
      }
    }
  }, [manufacturerCreateState.error]);

  const onReset = () => {
    form.resetFields();
  };

  const onSubmit = (values: Store) => {
    const { name, categories } = values;

    const cmd: CreateManufacturerCmd = {
      name,
      categories,
    };

    createManufacturerConnect(cmd);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={{}}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true }, { type: 'string' }]}
      >
        <Input placeholder="Enter manufacturer name" type="text" />
      </Form.Item>

      <Form.Item
        label="Categories"
        name="categories"
        rules={[{ required: false }]}
      >
        <Select
          mode="multiple"
          placeholder="Select manufacturer categories"
          allowClear
        >
          <Select.Option value={ProductCategory.FEED}>{'FEED'}</Select.Option>
          <Select.Option value={ProductCategory.FEED_SUPPLEMENT}>
            {'FEED_SUPPLEMENT'}
          </Select.Option>
          <Select.Option value={ProductCategory.CHEMICAL}>
            {'CHEMICAL'}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={manufacturerCreateState.loading}
          >
            {'Create Manufacturer'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  manufacturerCreateState: state.manufacturers.create,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createManufacturerConnect: createManufacturer,
    },
    dispatch,
  );
};

export const CreateManufacturerForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateManufacturerForm);

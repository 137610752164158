import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { announcementApi } from '../../api/announcementApi';
import {
  CREATE_ANNOUCEMENT,
  CREATE_ANNOUCEMENT_FAILED,
  CREATE_ANNOUCEMENT_SUCCESS,
  FETCH_ANNOUCEMENT,
  FETCH_ANNOUCEMENT_FAILED,
  FETCH_ANNOUCEMENT_SUCCESS,
  FETCH_ANNOUCEMENTS,
  FETCH_ANNOUCEMENTS_FAILED,
  FETCH_ANNOUCEMENTS_SUCCESS,
  UPDATE_ANNOUCEMENT,
  UPDATE_ANNOUCEMENT_FAILED,
  UPDATE_ANNOUCEMENT_SUCCESS,
  DELETE_ANNOUCEMENT,
  DELETE_ANNOUCEMENT_SUCCESS,
  DELETE_ANNOUCEMENT_FAILED,
  FETCH_LATEST_ANNOUCEMENT,
  FETCH_LATEST_ANNOUCEMENT_SUCCESS,
  FETCH_LATEST_ANNOUCEMENT_FAILED,
} from '../../constants';
import { CreateAnnouncementCmd, UpdateAnnouncementCmd } from '../../model/cmds';
import {
  ListAnnouncementsQuery,
  ListAnnouncementsQueryModel,
} from '../../model/querys';
import { AnnouncementsState } from '../../model/state';
import {
  announcementCreate,
  announcementCreated,
  announcementCreateFailed,
  announcementFetch,
  announcementFetched,
  announcementFetchFailed,
  AnnouncementsAction,
  announcementsFetch,
  announcementsFetched,
  announcementsFetchFailed,
  announcementUpdate,
  announcementUpdated,
  announcementUpdateFailed,
  announcementDelete,
  announcementDeleted,
  announcementDeleteFailed,
  latestAnnouncementFetch,
  latestAnnouncementFetched,
  latestAnnouncementFetchFailed,
} from '../actions';

export function createAnnouncement(cmd: CreateAnnouncementCmd) {
  return async (dispatch: Dispatch<AnnouncementsAction, {}, any>) => {
    try {
      dispatch(announcementCreate());
      const announcement = await announcementApi.createAnnouncement(cmd);
      dispatch(announcementCreated(announcement));
    } catch (e) {
      dispatch(announcementCreateFailed(e));
    }
  };
}

export function getAnnouncement(id: string) {
  return async (dispatch: Dispatch<AnnouncementsAction, {}, any>) => {
    try {
      dispatch(announcementFetch());
      const announcement = await announcementApi.getAnnouncement(id);
      dispatch(announcementFetched(announcement));
    } catch (e) {
      dispatch(announcementFetchFailed(e));
    }
  };
}

export function getLatestAnnouncement() {
  return async (dispatch: Dispatch<AnnouncementsAction, {}, any>) => {
    try {
      dispatch(latestAnnouncementFetch());
      const announcement = await announcementApi.getLatestAnnouncement();
      dispatch(latestAnnouncementFetched(announcement));
    } catch (e) {
      dispatch(latestAnnouncementFetchFailed(e));
    }
  };
}

export function getAnnouncements(query: ListAnnouncementsQuery) {
  return async (dispatch: Dispatch<AnnouncementsAction, {}, any>) => {
    try {
      dispatch(announcementsFetch(query));
      const queryModel: ListAnnouncementsQueryModel = await announcementApi.listAnnouncements(
        query,
      );
      const announcements = queryModel.announcements;
      dispatch(announcementsFetched(queryModel, announcements));
    } catch (e) {
      dispatch(announcementsFetchFailed(e));
    }
  };
}

export function updateAnnouncement(id: string, cmd: UpdateAnnouncementCmd) {
  return async (dispatch: Dispatch<AnnouncementsAction, {}, any>) => {
    try {
      dispatch(announcementUpdate());
      const response = await announcementApi.updateAnnouncement(id, cmd);
      dispatch(announcementUpdated(response));
      dispatch(getAnnouncement(id));
    } catch (e) {
      dispatch(announcementUpdateFailed(e));
    }
  };
}

export function deleteAnnouncement(
  announcementId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<AnnouncementsAction, {}, any>) => {
    try {
      dispatch(announcementDelete(announcementId));
      await announcementApi.deleteAnnouncement(announcementId);
      const announcement = await announcementApi.getAnnouncement(
        announcementId,
      );
      dispatch(announcementDeleted(announcement));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(announcementDeleteFailed(e));
    }
  };
}

export default function announcementsReducer(
  state: AnnouncementsState = {
    create: {
      loading: false,
      error: null,
      announcement: null,
    },
    list: {
      loading: false,
      error: null,
      announcements: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      announcement: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      success: null,
      error: null,
    },
  },
  action: AnnouncementsAction,
): AnnouncementsState {
  switch (action.type) {
    case CREATE_ANNOUCEMENT:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          announcement: null,
        },
      };
    case CREATE_ANNOUCEMENT_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          announcement: action.payload,
        },
      };
    case CREATE_ANNOUCEMENT_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          announcement: null,
        },
      };
    case FETCH_ANNOUCEMENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          announcement: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_ANNOUCEMENT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          announcement: action.payload,
        },
      };
    case FETCH_ANNOUCEMENT_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          announcement: null,
        },
      };

    case FETCH_LATEST_ANNOUCEMENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          announcement: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_LATEST_ANNOUCEMENT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          announcement: action.payload,
        },
      };
    case FETCH_LATEST_ANNOUCEMENT_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          announcement: null,
        },
      };

    case UPDATE_ANNOUCEMENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_ANNOUCEMENT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_ANNOUCEMENT_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_ANNOUCEMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          announcements: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ANNOUCEMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          announcements: action.payload.announcements,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_ANNOUCEMENTS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          announcements: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case DELETE_ANNOUCEMENT:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null,
          success: null,
        },
      };

    case DELETE_ANNOUCEMENT_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          announcement: action.payload,
        },
        delete: {
          ...state.delete,
          loading: false,
          error: null,
          success: true,
        },
      };

    case DELETE_ANNOUCEMENT_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    default:
      return state;
  }
}

import {
  Col,
  Layout,
  Modal,
  Pagination,
  Row,
  Table,
  Tag,
  Typography,
} from 'antd';
import qs from 'qs';
import Column from 'rc-table/lib/sugar/Column';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Fab } from 'react-tiny-fab';
import { bindActionCreators, Dispatch } from 'redux';
import { functionalColor } from '../../colors';
import { RootState } from '../../duck';
import { getMessage, getMessages } from '../../duck/modules/messages';
import { MessageType, PopUpRule, PopUpType } from '../../model/enums';
import { SimpleMessageModel } from '../../model/simple';
import { getNavbarColor, getNavbarTextColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { CreatePopUpForm as CreatePopUpForm } from '../popUp/createPopUpForm';
import { MessageModel } from '../../model/domain';
import { Link } from 'react-router-dom';
import moment from 'moment';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps & {
    queryParams: {
      page?: number;
      pageSize?: number;
    };
  };

const UnconnectedPopUps: React.FC<Props> = ({
  messages,
  loading,
  error,
  total,
  getMessagesConnect,
  queryParams,
}) => {
  const [triggerReload, setTriggerReload] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);

  const [offset, setOffset] = React.useState(0);

  const [page, setPage] = React.useState(queryParams?.page || 1);

  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    getMessagesConnect({
      limit: limit,
      offset: offset,
      types: [MessageType.POP_UP],
    });
  }, [offset, limit]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const rederTable = () => {
    return (
      <Col>
        <Table
          bordered
          pagination={false}
          loading={loading}
          rowKey="id"
          dataSource={messages}
          scroll={{ x: true }}
        >
          <Column
            align="center"
            title="Type"
            render={(message: SimpleMessageModel) => {
              var data = new Map(Object.entries(message.data));
              var typeRaw = new Map(Object.entries(data.get('nodes'))).get(
                'type',
              );
              var type = String(typeRaw);
              return <Typography>{type}</Typography>;
            }}
          />
          <Column
            align="center"
            title="ID"
            render={(message: SimpleMessageModel) => {
              return (
                <Link key={message.id} to={`/popUps/${message.id}`}>
                  {message.id}
                </Link>
              );
            }}
          />
          <Column
            align="center"
            title="Title (English)"
            render={(message: SimpleMessageModel) => {
              var messageData = new Map(Object.entries(message.data));
              var popUpData = new Map(
                Object.entries(messageData.get('nodes')),
              ) as Map<String, any>;

              var dataInEnglishRaw = popUpData.get('dataInEnglish') as Object;
              var dataInEnglish = new Map(Object.entries(dataInEnglishRaw));
              var titleInEnglish = String(dataInEnglish.get('title'));
              return <Typography>{titleInEnglish}</Typography>;
            }}
          />
          <Column
            align="center"
            title="Title (Thai)"
            render={(message: SimpleMessageModel) => {
              var messageData = new Map(Object.entries(message.data));
              var popUpData = new Map(
                Object.entries(messageData.get('nodes')),
              ) as Map<String, any>;

              var dataInThaiRaw = popUpData.get('dataInThai') as Object;
              var dataInThai = new Map(Object.entries(dataInThaiRaw));
              var titleInThai = String(dataInThai.get('title'));
              return <Typography>{titleInThai}</Typography>;
            }}
          />
          <Column
            align="center"
            title="Created At"
            render={(message: SimpleMessageModel) => {
              return (
                <Typography>{`${moment(message.createTimestamp).format(
                  'DD/MM/YYYY - HH:mm',
                )}`}</Typography>
              );
            }}
          />
        </Table>
        {total != null && (
          <Row justify="end" style={{ marginTop: 10 }}>
            <Pagination
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </Col>
    );
  };

  return (
    <Layout>
      <Content>
        <Modal
          title="Create a pop up"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <CreatePopUpForm
            onCreated={(message: MessageModel) => {
              setIsModalVisible(false);
              getMessagesConnect({
                limit: 30,
                offset: 0,
                types: [MessageType.POP_UP],
              });
            }}
            onError={(_error: Error) => {
              setIsModalVisible(false);
              console.warn('ERROR', JSON.stringify(_error));
            }}
          />
        </Modal>
        {rederTable()}
        <Fab
          style={{ bottom: 0, right: 0 }}
          mainButtonStyles={{
            backgroundColor: getNavbarColor(),
            color: getNavbarTextColor(),
          }}
          icon={<PlusOutlined />}
          alwaysShowTitle={false}
          onClick={showModal}
          text="Add pop up"
        />
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  messages: state.message.list.messages,
  loading: state.message.list.loading,
  error: state.message.list.error,
  total: state.message.list.total,
  offset: state.message.list.offset,
  limit: state.message.list.limit,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getMessagesConnect: getMessages,
    },
    dispatch,
  );
};

export const PopUps = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedPopUps));

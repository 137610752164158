import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Layout,
  Modal,
  Form,
  notification,
  Row,
  Tabs,
  Typography,
  Result,
  Descriptions,
  Tooltip,
  Button,
  List,
  Table,
  Space,
  Empty,
  Spin,
  AutoComplete,
  Popconfirm,
  message,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import {
  getGateway,
  updateGateway,
  synchronizeGateway,
  addSmartSensorToGateway,
  removeSmartSensorFromGateway,
  addLcmToGateway,
  removeLcmFromGateway,
  addPumpToGateway,
  removePumpFromGateway,
  updateBatchInformation,
  swap,
} from '../../duck/modules/gateways';
import { Input } from 'antd';
import {
  GatewayAeratorModel,
  GatewayLcmModel,
  GatewayModel,
  GatewaySensorModel,
  GatewaySmartSensorModel,
  SensorModel,
} from '../../model/domain';
import { UpdateGatewayCmd } from '../../model/cmds';
import {
  GatewayStateField,
  SensorType,
  SmartRelayboardId,
  RelayboardId,
} from '../../model/enums';
import {
  addAeratorToGateway,
  removeAeratorFromGateway,
} from '../../duck/modules/gateways';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from '../_util/hook';
import {
  getColorForSensorValue,
  getSensorNameToType,
  SensorValueType,
} from '../../util';
import {
  createSensor,
  getOrganisationSensors,
} from '../../duck/modules/sensing';
import {
  addPondToGateway,
  removePondFromGateway,
} from '../../duck/modules/gateways';
import {
  addSensorToGateway,
  removeSensorFromGateway,
} from '../../duck/modules/gateways';
import { GatewayDataForm } from '../gateway/gatewayDataForm';
import {
  getController,
  updateController,
} from '../../duck/modules/controllers';
import { NetworkInformationCard } from '../controller/networkInformationCard';
import { ControllerDataForm } from '../controller/controllerDataForm';
import { PositionCard } from '../controller/positionCard';
import { SoftwareVersionCard } from '../controller/softwareVersionCard';
import { NotificationsTable } from '../notification/notificationsTable';
import _, { replace } from 'lodash';
import moment from 'moment';
import { ControllerSocketOffsetsForm } from '../controller/controllerSocketOffsetsForm';
import { CreateLcmForm } from '../lcm/createLcmForm';
import { getLcm } from '../../duck/modules/lcms';
import { LcmDefaultValuesDescription } from '../lcm/lcmDefaultValuesDescription';
import qs from 'qs';
import Column from 'antd/lib/table/Column';
import {
  getSensorOptions,
  getSmartSensorOptions,
  getPondOptions,
  getOrganisationOptions,
  getGatewayOptions,
} from '../../duck/modules/options';
import { INT_MAX } from '../../constants';
import {
  addGatewayToOrganisation,
  removeGatewayFromOrganisation,
} from '../../duck/modules/organisations';
import { SimpleOrganisationModel } from '../../model/simple';
import { RenderBatchInformationCard } from './batchInformationCard';
import { SwapCard } from '../controller/swapCard';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ gatewayId: string }>;

const UnconnectedGatewayDetail: React.FC<Props> = ({
  location,
  match,

  getGatewayConnect,
  synchronizeGatewayConnect,
  addAeratorToGatewayConnect,
  removeAeratorFromGatewayConnect,
  addSensorToGatewayConnect,
  removeSensorFromGatewayConnect,
  removePondFromGatewayConnect,
  getOrganisationSensorsConnect,
  updateGatewayConnect,
  addSmartSensorToGatewayConnect,
  removeSmartSensorFromGatewayConnect,
  addPumpToGatewayConnect,
  removePumpFromGatewayConnect,

  addLcmToGatewayConnect,
  removeLcmFromGatewayConnect,
  getLcmConnect,
  getGatewayOptionsConnect,

  gateway,
  gatewayLoading,
  gatewayError,
  gatewayUpdating,
  gatewayUpdateError,
  gatewayUpdateSuccess,
  gatewayAddPondState,
  gatewayRemovePondState,
  gatewayAddSensorState,
  gatewayRemoveSensorState,
  gatewayAddAeratorState,
  gatewayRemoveAeratorState,
  gatewayAddSmartSensorState,
  gatewayRemoveSmartSensorState,
  gatewayAddLcmState,
  gatewayRemoveLcmState,
  gatewayAddPumpState,
  gatewayRemovePumpState,
  gatewaySwapState,

  lcmDetailState,

  controller,
  controllerLoading,
  controllerError,
  controllerUpdating,
  controllerUpdateError,
  controllerUpdateSuccess,

  getControllerConnect,
  updateControllerConnect,

  sensorOptions,
  smartSensorOptions,
  pondOptions,
  organisationOptions,
  gatewayOptions,

  getSensorOptionsConnect,
  getSmartSensorOptionsConnect,
  getPondOptionsConnect,
  getOrganisationOptionsConnect,
  addGatewayToOrganisationConnect,
  removeGatewayFromOrganisationConnect,
  updateBatchInformationConnect,
  swapGatewaysConnect,
  gatewayAddOrganisationState: gatewayAddState,
  gatewayRemoveOrganisationState: gatewayRemoveState,

  addPondToGatewayConnect,

  createSensorConnect,
  createSensorState,
}) => {
  const { gatewayId } = match.params;
  const [triggerReload, setTriggerReload] = useState(false);
  const [addSensorForm] = Form.useForm();
  const [addAeratorForm] = Form.useForm();
  const [addSmartSensorForm] = Form.useForm();
  const [addLcmForm] = Form.useForm();
  const [addPumpForm] = Form.useForm();
  const [assignOrganisationForm] = Form.useForm();
  const [addPondForm] = Form.useForm();

  const [isLcmCreateModalVisible, setIsLcmCreateModalVisible] = useState(false);

  const prev = usePrevious({
    gatewayAddPondState,
    gatewayRemovePondState,
    gatewayAddSensorState,
    gatewayRemoveSensorState,
    gatewayAddAeratorState,
    gatewayRemoveAeratorState,
    controllerUpdateSuccess,
    controllerUpdateError,
    gatewayAddSmartSensorState,
    gatewayRemoveSmartSensorState,
    gatewayAddLcmState,
    gatewayRemoveLcmState,
    gatewayAddPumpState,
    gatewayRemovePumpState,
    createSensorState,
    gatewaySwapState,
  });

  function Effects() {
    React.useEffect(() => {
      getGatewayConnect(gatewayId);
      getControllerConnect(gatewayId);
    }, []);

    React.useEffect(() => {
      gateway &&
        gateway.organisation &&
        getOrganisationSensorsConnect(gateway.organisation.id);


      const lcmId =
        (gateway && gateway.lcms.requested?.find((l) => l.id))?.id ||
        (gateway && gateway.lcms.actual.find((l) => l.id))?.id ||
        null;

      if (lcmId && lcmId != lcmDetailState.lcm?.id) {
        getLcmConnect(lcmId);
      }

      gateway &&
        gateway.organisation &&
        getSensorOptionsConnect({
          organisationIds: [gateway.organisation.id],
          gatewayIds: 'null',
          limit: INT_MAX,
          offset: 0,
        });

      gateway &&
        gateway.organisation &&
        getPondOptionsConnect({
          organisationIds: [gateway.organisation.id],
          gatewayIds: 'null',
          limit: INT_MAX,
          offset: 0,
        });

      gateway &&
        !gateway.organisation &&
        getOrganisationOptionsConnect({
          limit: INT_MAX,
          offset: 0,
        });

      gateway &&
        gateway.organisation &&
        getSmartSensorOptionsConnect({
          organisationIds: [gateway.organisation.id],
          gatewayIds: 'null',
          limit: INT_MAX,
          offset: 0,
        });

      gateway &&
        gateway.organisation &&
        getGatewayOptionsConnect({
          organisationIds: [gateway.organisation.id],
          limit: INT_MAX,
          offset: 0,
        });

    }, [gateway]);
    React.useEffect(() => {
      if (
        prev?.gatewayRemovePondState.loading &&
        !gatewayRemovePondState.loading &&
        !gatewayRemovePondState.error
      ) {
        notification.success({
          message: 'Pond removed from Gateway',
        });
      }
    }, [gatewayRemovePondState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePondState.loading &&
        !gatewayRemovePondState.loading &&
        gatewayRemovePondState.error
      ) {
        notification.error({
          message: 'Error while removing Pond from Gateway',
          description: gatewayRemovePondState.error.message,
        });
      }
    }, [gatewayRemovePondState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddSensorState.loading &&
        !gatewayAddSensorState.loading &&
        !gatewayAddSensorState.error
      ) {
        notification.success({
          message: 'Sensor added to Gateway',
        });
        gateway &&
          gateway.organisation &&
          getOrganisationSensorsConnect(gateway.organisation.id);
      }
    }, [gatewayAddSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddSensorState.loading &&
        !gatewayAddSensorState.loading &&
        gatewayAddSensorState.error
      ) {
        notification.error({
          message: 'Error while adding sensor to Gateway',
          description: gatewayAddSensorState.error.message,
        });
        gateway &&
          gateway.organisation &&
          getOrganisationSensorsConnect(gateway.organisation.id);
      }
    }, [gatewayAddSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveSensorState.loading &&
        !gatewayRemoveSensorState.loading &&
        !gatewayRemoveSensorState.error
      ) {
        notification.success({
          message: 'Sensor removed from Gateway',
        });
        gateway &&
          gateway.organisation &&
          getOrganisationSensorsConnect(gateway.organisation.id);
      }
    }, [gatewayRemoveSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveSensorState.loading &&
        !gatewayRemoveSensorState.loading &&
        gatewayRemoveSensorState.error
      ) {
        notification.error({
          message: 'Error while removing sensor from Gateway',
          description: gatewayRemoveSensorState.error.message,
        });
        gateway &&
          gateway.organisation &&
          getOrganisationSensorsConnect(gateway.organisation.id);
      }
    }, [gatewayRemoveSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddAeratorState.loading &&
        !gatewayAddAeratorState.loading &&
        !gatewayAddAeratorState.error
      ) {
        notification.success({
          message: 'Aerator added to Gateway',
        });
      }
    }, [gatewayAddAeratorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddAeratorState.loading &&
        !gatewayAddAeratorState.loading &&
        gatewayAddAeratorState.error
      ) {
        notification.error({
          message: 'Error while adding aerator to Gateway',
          description: gatewayAddAeratorState.error.message,
        });
      }
    }, [gatewayAddAeratorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveAeratorState.loading &&
        !gatewayRemoveAeratorState.loading &&
        !gatewayRemoveAeratorState.error
      ) {
        notification.success({
          message: 'Aerator removed from Gateway',
        });
      }
    }, [gatewayRemoveAeratorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveAeratorState.loading &&
        !gatewayRemoveAeratorState.loading &&
        gatewayRemoveAeratorState.error
      ) {
        notification.error({
          message: 'Error while removing aerator from Gateway',
          description: gatewayRemoveAeratorState.error.message,
        });
      }
    }, [gatewayRemoveAeratorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePondState.loading &&
        !gatewayRemovePondState.loading &&
        !gatewayRemovePondState.error
      ) {
        notification.success({
          message: 'Pond removed from Gateway',
        });
      }
    }, [gatewayRemovePondState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePondState.loading &&
        !gatewayRemovePondState.loading &&
        gatewayRemovePondState.error
      ) {
        notification.error({
          message: 'Error while removing pond from Gateway',
          description: gatewayRemovePondState.error.message,
        });
      }
    }, [gatewayRemovePondState]);

    React.useEffect(() => {
      if (prev?.controllerUpdateSuccess === null && controllerUpdateSuccess) {
        notification.success({
          message: 'Controller updated',
        });
      }
    }, [controllerUpdateSuccess]);

    React.useEffect(() => {
      if (prev?.controllerUpdateError === null && controllerUpdateError) {
        notification.error({
          message: 'Error while updating Controller',
          description: controllerUpdateError?.message,
        });
      }
    }, [controllerUpdateError]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddSmartSensorState.loading &&
        !gatewayAddSmartSensorState.loading &&
        !gatewayAddSmartSensorState.error
      ) {
        notification.success({
          message: 'Smart Sensor added to Gateway',
        });
      }
    }, [gatewayAddSmartSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddSmartSensorState.loading &&
        !gatewayAddSmartSensorState.loading &&
        gatewayAddSmartSensorState.error
      ) {
        notification.error({
          message: 'Error while adding smart sensor to Gateway',
          description: gatewayAddSmartSensorState.error.message,
        });
      }
    }, [gatewayAddSmartSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveSmartSensorState.loading &&
        !gatewayRemoveSmartSensorState.loading &&
        !gatewayRemoveSmartSensorState.error
      ) {
        notification.success({
          message: 'Smart Sensor removed from Gateway',
        });
      }
    }, [gatewayRemoveSmartSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveSmartSensorState.loading &&
        !gatewayRemoveSmartSensorState.loading &&
        gatewayRemoveSmartSensorState.error
      ) {
        notification.error({
          message: 'Error while removing smart sensor from Gateway',
          description: gatewayRemoveSmartSensorState.error.message,
        });
      }
    }, [gatewayRemoveSmartSensorState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddLcmState.loading &&
        !gatewayAddLcmState.loading &&
        !gatewayAddLcmState.error
      ) {
        notification.success({
          message: 'LCM added to Gateway',
        });
      }
    }, [gatewayAddLcmState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddLcmState.loading &&
        !gatewayAddLcmState.loading &&
        gatewayAddLcmState.error
      ) {
        notification.error({
          message: 'Error while adding LCM to Gateway',
          description: gatewayAddLcmState.error.message,
        });
      }
    }, [gatewayAddLcmState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveLcmState.loading &&
        !gatewayRemoveLcmState.loading &&
        !gatewayRemoveLcmState.error
      ) {
        notification.success({
          message: 'LCM removed from Gateway',
        });
      }
    }, [gatewayRemoveLcmState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemoveLcmState.loading &&
        !gatewayRemoveLcmState.loading &&
        gatewayRemoveLcmState.error
      ) {
        notification.error({
          message: 'Error while removing LCM from Gateway',
          description: gatewayRemoveLcmState.error.message,
        });
      }
    }, [gatewayRemoveLcmState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddPumpState.loading &&
        !gatewayAddPumpState.loading &&
        !gatewayAddPumpState.error
      ) {
        notification.success({
          message: 'Pump added to Gateway',
        });
      }
    }, [gatewayAddPumpState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddPumpState.loading &&
        !gatewayAddPumpState.loading &&
        gatewayAddPumpState.error
      ) {
        notification.error({
          message: 'Error while adding pump to Gateway',
          description: gatewayAddPumpState.error.message,
        });
      }
    }, [gatewayAddPumpState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePumpState.loading &&
        !gatewayRemovePumpState.loading &&
        !gatewayRemovePumpState.error
      ) {
        notification.success({
          message: 'Pump removed from Gateway',
        });
      }
    }, [gatewayRemovePumpState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePumpState.loading &&
        !gatewayRemovePumpState.loading &&
        gatewayRemovePumpState.error
      ) {
        notification.error({
          message: 'Error while removing pump from Gateway',
          description: gatewayRemovePumpState.error.message,
        });
      }
    }, [gatewayRemovePumpState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddPondState.loading &&
        !gatewayAddPondState.loading &&
        !gatewayAddPondState.error
      ) {
        notification.success({
          message: 'Pond assigned to Gateway',
        });
        addPondForm.resetFields();
      }
    }, [gatewayAddPondState]);

    React.useEffect(() => {
      if (
        prev?.gatewayAddPondState.loading &&
        !gatewayAddPondState.loading &&
        gatewayAddPondState.error
      ) {
        notification.error({
          message: 'Error while assigning pond to Gateway',
          description: gatewayAddPondState.error.message,
        });
      }
    }, [gatewayAddPondState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePondState.loading &&
        !gatewayRemovePondState.loading &&
        !gatewayRemovePondState.error
      ) {
        notification.success({
          message: 'Pond removed from Gateway',
        });
      }
    }, [gatewayRemovePondState]);

    React.useEffect(() => {
      if (
        prev?.gatewayRemovePondState.loading &&
        !gatewayRemovePondState.loading &&
        gatewayRemovePondState.error
      ) {
        notification.error({
          message: 'Error while removing pond from Gateway',
          description: gatewayRemovePondState.error.message,
        });
      }
    }, [gatewayRemovePondState]);

    React.useEffect(() => {
      if (
        prev?.gatewaySwapState.loading &&
        !gatewaySwapState.loading &&
        !gatewaySwapState.error
      ) {
        notification.success({
          message: 'Gateway swapped successfully',
        });
      }
    }, [gatewaySwapState]);

    React.useEffect(() => {
      if (
        prev?.gatewaySwapState.loading &&
        !gatewaySwapState.loading &&
        gatewaySwapState.error
      ) {
        notification.error({
          message: 'Error while swapping Gateway',
          description: gatewaySwapState.error.message,
        });
      }
    }, [gatewaySwapState]);
  }
  Effects();

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleTabChanged = (activeKey: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const pathName = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', activeKey);
    const newUrl = `${protocol}//${host}${pathName}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setActiveKey(activeKey);
  };

  const defaultTab = queryParams['tab']?.toString() || 'general';

  const [activeKey, setActiveKey] = useState(defaultTab);

  const renderForceSyncButton = () => {
    return (
      <Button
        type="primary"
        onClick={() =>
          synchronizeGatewayConnect(gatewayId, {
            stateFields: [
              GatewayStateField.SMART_RELAY,
              GatewayStateField.GATEWAY,
              GatewayStateField.LCM,
              GatewayStateField.POND,
              GatewayStateField.AERATOR,
              GatewayStateField.SENSOR,
              GatewayStateField.SMART_SENSOR,
              GatewayStateField.PUMP,
            ],
          })
        }
      >
        {'Syncronize'}
      </Button>
    );
  };

  const renderConectionDataCard = () => {
    return (
      <Card
        title="Connection Data"
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        <Row gutter={[16, 16]}>
          {gateway && (
            <Col span={24}>
              <Descriptions bordered column={1}>
                <Descriptions.Item label={'Connection Status'}>
                  <Typography.Text>
                    {gateway.mqttConnectionStatus}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Connection Timestamp'}>
                  <Tooltip
                    placement="left"
                    title={moment(gateway.mqttConnectionTimestamp).fromNow()}
                  >
                    <Typography>
                      {moment(gateway.mqttConnectionTimestamp).format('llll')}
                    </Typography>
                  </Tooltip>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}
          {gateway && <Col span={24}>{renderForceSyncButton()}</Col>}
        </Row>
      </Card>
    );
  };

  const renderAssignOrganisationForm = () => {
    return (
      <Form
        form={assignOrganisationForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { organisationId } = data;
          console.warn(`Assing gateway to organisation ${organisationId}`);
          addGatewayToOrganisationConnect(organisationId, gatewayId, () => {
            getGatewayConnect(gatewayId);
            getControllerConnect(gatewayId);
          });
          assignOrganisationForm.resetFields();
        }}
      >
        <Form.Item
          style={{ flex: 0.7 }}
          name="organisationId"
          rules={[{ required: true, message: 'Please input organisationId!' }]}
        >
          <AutoComplete
            placeholder="Enter ID of organisation"
            options={
              organisationOptions.model?.organisations.map((option) => {
                return {
                  label: `${option.name} (${option.id})`,
                  value: option.id,
                };
              }) || []
            }
          />
        </Form.Item>

        <Form.Item style={{ flex: 0.3 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={gatewayAddState.loading}
          >
            {'Assign'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderOrganisationRow = (organisation: SimpleOrganisationModel) => {
    return (
      <Row justify="space-between" align="middle">
        <Link to={`/organisations/${organisation.id}`}>
          {organisation.name}
        </Link>

        <Popconfirm
          title="Do you really want to remove the gateway from the organisation?"
          onConfirm={() => {
            removeGatewayFromOrganisationConnect(
              organisation.id,
              gatewayId,
              () => {
                getGatewayConnect(gatewayId);
                getControllerConnect(gatewayId);
              },
            );
          }}
        >
          <Button danger loading={gatewayRemoveState.loading}>
            Remove
          </Button>
        </Popconfirm>
      </Row>
    );
  };

  const renderAddPondForm = () => {
    return (
      <Form
        form={addPondForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { pondId } = data;
          addPondToGatewayConnect(gatewayId, { pondId });
        }}
      >
        <Form.Item
          style={{ flex: 0.7 }}
          name="pondId"
          rules={[{ required: true, message: 'Please input pondId!' }]}
        >
          <AutoComplete
            placeholder="Enter ID of pond"
            options={
              pondOptions.model?.ponds.map((option) => {
                return {
                  label: `${option.farm.name || option.farm.id} - ${option.name
                    } (${option.id})`,
                  value: option.id,
                };
              }) || []
            }
          />
        </Form.Item>

        <Form.Item style={{ flex: 0.3 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={gatewayAddPondState.loading}
          >
            {'Add Pond'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderHierarchyCard = () => {
    return (
      <Card
        title={'Hierarchy'}
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        {gateway && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Organisation">
              {gateway.organisation
                ? renderOrganisationRow(gateway.organisation)
                : renderAssignOrganisationForm()}
            </Descriptions.Item>

            <Descriptions.Item label="Ponds">
              <List
                header={
                  gateway.organisation &&
                  pondOptions.model &&
                  pondOptions.model.total > 0 &&
                  renderAddPondForm()
                }
                dataSource={gateway.ponds.actual}
                renderItem={(pond) => (
                  <List.Item
                    extra={
                      <Button
                        loading={
                          gatewayRemovePondState.loading &&
                          gatewayRemovePondState.pondId === pond.id &&
                          gatewayRemovePondState.gatewayId === gatewayId
                        }
                        onClick={() => {
                          removePondFromGatewayConnect(gatewayId, pond.id);
                        }}
                      >
                        {'Remove'}
                      </Button>
                    }
                  >
                    <Link to={`/ponds/${pond.id}`}>{pond.name}</Link>
                  </List.Item>
                )}
              />
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderGeneralDataCard = () => {
    return (
      <Card
        title="Gateway Data"
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        {gateway && (
          <GatewayDataForm
            updating={gatewayUpdating}
            updateSuccess={gatewayUpdateSuccess}
            updateError={gatewayUpdateError}
            onUpdate={(cmd: UpdateGatewayCmd) =>
              updateGatewayConnect(gatewayId, cmd)
            }
            gateway={gateway}
          />
        )}
      </Card>
    );
  };

  const renderOnBoardCard = () => {
    const ocs =
      gateway && gateway.sensors.actual.find((s) => s.type === SensorType.OCS);
    const oms =
      gateway && gateway.sensors.actual.find((s) => s.type === SensorType.OMS);

    return (
      <Card
        title="On-Board Sensors"
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={'CPU'}>
                <Descriptions layout="vertical" bordered column={3}>
                  <Descriptions.Item label="ID">
                    {ocs?.id ? (
                      <Typography.Text copyable>{ocs?.id}</Typography.Text>
                    ) : (
                      <Typography>{'-'}</Typography>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Temperature">
                    {gateway?.ocsSensor?.lastMeasurement?.tempInC ? (
                      <Typography>
                        {`${gateway?.ocsSensor?.lastMeasurement?.tempInC} °C`}
                      </Typography>
                    ) : (
                      <Typography>{'-'}</Typography>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Timestamp">
                    {gateway?.ocsSensor?.lastMeasurementTimestamp ? (
                      <Tooltip
                        title={gateway?.ocsSensor?.lastMeasurementTimestamp}
                      >
                        <Typography>{`${moment(
                          gateway?.ocsSensor?.lastMeasurementTimestamp,
                        ).fromNow()}`}</Typography>
                      </Tooltip>
                    ) : (
                      <Typography>{'-'}</Typography>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Actions">
                    <Row gutter={10}>
                      {!ocs && (
                        <Col>
                          <Empty description="No OCS registered">
                            <Row justify="center" gutter={10}>
                              <Col>
                                <Button
                                  type="primary"
                                  loading={
                                    (createSensorState.loading &&
                                      createSensorState?.sensor?.type ==
                                      SensorType.OCS) ||
                                    (!!gateway?.sensors.requested?.find(
                                      (s) => s.type === SensorType.OCS,
                                    ) &&
                                      createSensorState?.sensor?.type ==
                                      SensorType.OCS)
                                  }
                                  onClick={() => {
                                    createSensorConnect(
                                      {
                                        name: `OCS sensor - created at ${moment()}`,
                                        type: SensorType.OCS,
                                        organisationId:
                                          gateway?.organisation.id,
                                      },
                                      gateway.id,
                                    );
                                  }}
                                >
                                  {'Create new'}
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    handleTabChanged('sensors');
                                    message.info(
                                      'Select desired sensor from dropdown and click "Add Sensor" to reassign',
                                    );
                                    getSensorOptionsConnect(
                                      {
                                        organisationIds: [
                                          gateway.organisation.id,
                                          'null',
                                        ],
                                        gatewayIds: 'null',
                                        limit: INT_MAX,
                                        offset: 0,
                                      },
                                      [SensorType.OCS],
                                    );
                                  }}
                                >
                                  {'Reassign existing'}
                                </Button>
                              </Col>
                            </Row>
                          </Empty>
                        </Col>
                      )}

                      {ocs && (
                        <Col>
                          <Link to={`/sensors/${ocs.id}`}>
                            <Button>{'Show History'}</Button>
                          </Link>
                        </Col>
                      )}

                      {ocs && (
                        <Col>
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            onConfirm={() => {
                              removeSensorFromGatewayConnect(
                                gateway.id,
                                ocs.id,
                              );
                            }}
                          >
                            <Button
                              danger
                              loading={
                                (gatewayRemoveSensorState.loading &&
                                  gatewayRemoveSensorState.sensorId ==
                                  ocs.id) ||
                                (!!gateway.sensors.requested?.find(
                                  (s) => s.type === SensorType.OCS,
                                ) &&
                                  gatewayRemoveSensorState.sensorId == ocs.id)
                              }
                            >
                              <Typography>{'Remove from Gateway'}</Typography>
                            </Button>
                          </Popconfirm>
                        </Col>
                      )}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>

              <Descriptions.Item label={'Mainboard'}>
                <Descriptions layout="vertical" bordered column={3}>
                  <Descriptions.Item label="ID">
                    {oms?.id ? (
                      <Typography.Text copyable>{oms?.id}</Typography.Text>
                    ) : (
                      <Typography>{'-'}</Typography>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Temperature">
                    {gateway?.omsSensor?.lastMeasurement?.tempInC ? (
                      <Typography>
                        {`${gateway?.omsSensor?.lastMeasurement?.tempInC} °C`}
                      </Typography>
                    ) : (
                      <Typography>{'-'}</Typography>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Timestamp">
                    {gateway?.omsSensor?.lastMeasurementTimestamp ? (
                      <Tooltip
                        title={gateway?.omsSensor?.lastMeasurementTimestamp}
                      >
                        <Typography>{`${moment(
                          gateway?.omsSensor?.lastMeasurementTimestamp,
                        ).fromNow()}`}</Typography>
                      </Tooltip>
                    ) : (
                      <Typography>{'-'}</Typography>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Actions">
                    <Row gutter={10}>
                      {!oms && (
                        <Col>
                          <Empty description="No OMS registered">
                            <Row justify="center" gutter={10}>
                              <Col>
                                <Button
                                  type="primary"
                                  loading={
                                    (createSensorState.loading &&
                                      createSensorState?.sensor?.type ==
                                      SensorType.OMS) ||
                                    (!!gateway?.sensors.requested?.find(
                                      (s) => s.type === SensorType.OMS,
                                    ) &&
                                      createSensorState?.sensor?.type ==
                                      SensorType.OMS)
                                  }
                                  onClick={() => {
                                    createSensorConnect(
                                      {
                                        name: `OMS sensor - created at ${moment()}`,
                                        type: SensorType.OMS,
                                        organisationId: gateway.organisation.id,
                                      },
                                      gateway.id,
                                    );
                                  }}
                                >
                                  {'Create new'}
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    handleTabChanged('sensors');
                                    message.info(
                                      'Select desired sensor from dropdown and click "Add Sensor" to reassign',
                                    );
                                    getSensorOptionsConnect(
                                      {
                                        organisationIds: [
                                          gateway.organisation.id,
                                          'null',
                                        ],
                                        gatewayIds: 'null',
                                        limit: INT_MAX,
                                        offset: 0,
                                      },
                                      [SensorType.OMS],
                                    );
                                  }}
                                >
                                  {'Reassign existing'}
                                </Button>
                              </Col>
                            </Row>
                          </Empty>
                        </Col>
                      )}

                      {oms && (
                        <Col>
                          <Link to={`/sensors/${oms.id}`}>
                            <Button>{'Show History'}</Button>
                          </Link>
                        </Col>
                      )}

                      {oms && (
                        <Col>
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            onConfirm={() => {
                              removeSensorFromGatewayConnect(
                                gateway.id,
                                oms.id,
                              );
                            }}
                          >
                            <Button
                              danger
                              loading={
                                (gatewayRemoveSensorState.loading &&
                                  gatewayRemoveSensorState.sensorId ==
                                  oms.id) ||
                                (!!gateway.sensors.requested?.find(
                                  (s) => s.type === SensorType.OMS,
                                ) &&
                                  gatewayRemoveSensorState.sensorId == oms.id)
                              }
                            >
                              <Typography>{'Remove from Gateway'}</Typography>
                            </Button>
                          </Popconfirm>
                        </Col>
                      )}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderNotificationsCard = () => {
    return (
      <Card title={'Notifications'} style={{ height: '100%' }}>
        <NotificationsTable
          reload={triggerReload}
          onReloaded={() => setTriggerReload(false)}
          pageSize={5}
          gatewayId={gatewayId}
          hideGateway
          hideOrganisation
        />
      </Card>
    );
  };

  const renderGeneralTab = () => {
    return (
      <TabPane tab="General" key="general">
        <Row gutter={[16, 16]} justify="start" align="stretch">
          <Col xs={24} lg={12}>
            {renderGeneralDataCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderHierarchyCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderOnBoardCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderConectionDataCard()}
          </Col>
          <Col xs={24} lg={24}>
            {renderNotificationsCard()}
          </Col>
        </Row>
      </TabPane>
    );
  };

  const renderAddSensorForm = () => {
    return (
      <Form
        form={addSensorForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { sensorId } = data;
          addSensorToGatewayConnect(gatewayId, { sensorId });
          addSensorForm.resetFields();
        }}
      >
        <Form.Item
          style={{ flex: 0.7 }}
          name="sensorId"
          label="Add Sensor"
          rules={[{ required: true, message: 'Please input sensorId!' }]}
        >
          <AutoComplete
            placeholder="Enter ID of sensor"
            options={
              sensorOptions.model?.sensors.map((option) => {
                return {
                  label: `${getSensorNameToType(option.type)}: ${option.id} ${option.name ? `(${option.name})` : ''
                    }`,
                  value: option.id,
                };
              }) || []
            }
          />
        </Form.Item>

        <Form.Item style={{ flex: 0.3 }}>
          <Button type="primary" htmlType="submit">
            {'Add Sensor'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderAddSmartSensorForm = () => {
    return (
      <Form
        form={addSmartSensorForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { smartSensorId } = data;
          addSmartSensorToGatewayConnect(gatewayId, { smartSensorId });
          addSmartSensorForm.resetFields();
        }}
      >
        <Form.Item
          style={{ flex: 0.7 }}
          name="smartSensorId"
          label="Add Smart Sensor"
          rules={[{ required: true, message: 'Please input smartSensorId!' }]}
        >
          <AutoComplete
            placeholder="Enter ID of smart sensor"
            options={
              smartSensorOptions.model?.smartSensors.map((option) => {
                return {
                  label: `${option.id} ${option.name ? `(${option.name})` : ''
                    }`,
                  value: option.id,
                };
              }) || []
            }
          />
        </Form.Item>

        <Form.Item style={{ flex: 0.3 }}>
          <Button type="primary" htmlType="submit">
            {'Add Smart Sensor'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderSensorsCard = () => {
    return (
      <Card title="Sensors" loading={gatewayLoading} style={{ height: '100%' }}>
        <Row gutter={[10, 10]}>
          {gateway && <Col span={24}> {renderAddSensorForm()} </Col>}
          {gateway && (
            <Col span={24}>
              <Table
                dataSource={
                  !gateway.sensors.inSync
                    ? _.sortBy(gateway.sensors.requested)
                    : _.sortBy(gateway.sensors.actual) || []
                }
                loading={!gateway.sensors.inSync}
                pagination={false}
                bordered
                rowKey={(item) => item.id}
              >
                <Column
                  align="center"
                  title="Type"
                  render={(sensor) => {
                    return (
                      <Link to={`/sensors/${sensor.id}`}>
                        {`${getSensorNameToType(sensor.type)}`}
                      </Link>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="Actions"
                  render={(sensor: GatewaySensorModel) => {
                    return (
                      <Space>
                        <Button
                          key={`${sensor.id}-remove`}
                          size="small"
                          type="primary"
                          onClick={() =>
                            removeSensorFromGatewayConnect(
                              gateway.id,
                              sensor.id,
                            )
                          }
                        >
                          {'Remove'}
                        </Button>
                      </Space>
                    );
                  }}
                />
              </Table>
            </Col>
          )}

          {gateway && (
            <Col span={24}>
              <Button
                type="primary"
                onClick={() =>
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.SENSOR],
                  })
                }
              >
                {'Syncronize'}
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    );
  };

  const renderSmartSensorsCard = () => {
    return (
      <Card
        title="Smart Sensors"
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        <Row gutter={[10, 10]}>
          {gateway && <Col span={24}> {renderAddSmartSensorForm()} </Col>}
          {gateway && (
            <Col span={24}>
              <Table
                dataSource={_.sortBy(gateway.smartSensors.actual) || []}
                loading={!gateway.smartSensors.inSync}
                pagination={false}
                bordered
                rowKey={(item) => item.id}
              >
                <Column
                  align="center"
                  title="Sensor"
                  render={(smartSensor: GatewaySmartSensorModel) => {
                    return (
                      <Link to={`/smartSensors/${smartSensor.id}`}>
                        {smartSensor.name || smartSensor.id}
                      </Link>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="Actions"
                  render={(smartSensor: GatewaySmartSensorModel) => {
                    return (
                      <Space>
                        <Button
                          key={`${smartSensor.id}-remove`}
                          size="small"
                          type="primary"
                          onClick={() =>
                            removeSmartSensorFromGatewayConnect(
                              gateway.id,
                              smartSensor.id,
                            )
                          }
                        >
                          {'Remove'}
                        </Button>
                      </Space>
                    );
                  }}
                />
              </Table>
            </Col>
          )}

          {gateway && (
            <Col span={24}>
              <Button
                type="primary"
                onClick={() =>
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.SMART_SENSOR],
                  })
                }
              >
                {'Syncronize'}
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    );
  };

  const renderOffsetCard = () => {
    return (
      <ControllerSocketOffsetsForm
        loading={controllerLoading}
        socketOffsets={controller?.socketOffsets}
        updating={controllerUpdating}
        onUpdate={(cmd) => updateControllerConnect(gatewayId, cmd)}
      />
    );
  };

  const renderSensorsTab = () => {
    return (
      <TabPane tab="Sensors" key="sensors">
        <Row gutter={[16, 16]} justify="start" align="stretch">
          <Col xs={24} lg={12}>
            {renderSensorsCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderSmartSensorsCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderOffsetCard()}
          </Col>
        </Row>
      </TabPane>
    );
  };

  const renderAddAeratorForm = () => {
    return (
      <Form
        form={addAeratorForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { aeratorId } = data;
          addAeratorToGatewayConnect(gatewayId, { aeratorId });
          addAeratorForm.resetFields();
        }}
      >
        <Form.Item
          name="aeratorId"
          label="Add Aerator"
          rules={[{ required: true, message: 'Please input your aeratorId!' }]}
        >
          <Input placeholder="aeratorId" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {'Add Aerator'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderAertorsCard = () => {
    return (
      <Card
        title="Aerators"
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        <Row gutter={[10, 10]}>
          {gateway && <Col span={24}> {renderAddAeratorForm()} </Col>}
          {gateway && (
            <Col span={24}>
              <Table
                dataSource={
                  _.sortBy(
                    gateway.aerators.actual.map((a) => {
                      let relayNumber = 0;
                      if (a.relayboardId !== RelayboardId.NOT_SET) {
                        relayNumber = parseInt(
                          a.relayboardId.replace('RELAY_', ''),
                        );
                      }
                      if (a.smartRelayboardId !== SmartRelayboardId.NOT_SET) {
                        relayNumber = parseInt(
                          a.smartRelayboardId.replace('RELAY_', ''),
                        );
                      }
                      return {
                        ...a,
                        relayNumber,
                      };
                    }),
                    ['relayNumber'],
                  ) || []
                }
                loading={!gateway.aerators.inSync}
                pagination={false}
                bordered
                rowKey={(item) => item.id}
              >
                <Column
                  align="center"
                  title="Connection"
                  render={(aerator) => {
                    if (
                      aerator.smartRelayboardId !== SmartRelayboardId.NOT_SET
                    ) {
                      return (
                        <Typography
                          key={`connection-${aerator.id}`}
                        >{`Smart Relay`}</Typography>
                      );
                    }
                    return (
                      <Typography
                        key={`connection-${aerator.id}`}
                      >{`Relayboard`}</Typography>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="Relay Number"
                  render={(aerator) => {
                    return (
                      <Typography key={`relay-${aerator.id}`}>
                        {aerator.relayNumber}
                      </Typography>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="State"
                  render={(aerator: GatewayAeratorModel) => {
                    return (
                      <Typography key={`state-${aerator.id}`}>
                        {aerator.state}
                      </Typography>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="Actions"
                  render={(aerator: GatewayAeratorModel) => {
                    return (
                      <Space key={`actions-${aerator.id}`}>
                        <Link to={`/aerators/${aerator.id}`}>
                          <Button size="small" type="primary">
                            {'Show Details'}
                          </Button>
                        </Link>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() =>
                            removeAeratorFromGatewayConnect(
                              gateway.id,
                              aerator.id,
                            )
                          }
                        >
                          {'Remove'}
                        </Button>
                      </Space>
                    );
                  }}
                />
              </Table>
            </Col>
          )}
          {gateway && (
            <Col key={`actions-${gateway.id}`} span={24}>
              <Button
                type="primary"
                onClick={() =>
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.AERATOR],
                  })
                }
              >
                {'Syncronize'}
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    );
  };

  const renderAddPumpForm = () => {
    return (
      <Form
        form={addPumpForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { pumpId } = data;
          addPumpToGatewayConnect(gatewayId, { pumpId });
          addPumpForm.resetFields();
        }}
      >
        <Form.Item
          name="pumpId"
          label="Add Pump"
          rules={[{ required: true, message: 'Please input your pumpId!' }]}
        >
          <Input placeholder="pumpId" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {'Add Pump'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderPumpsCard = () => {
    return (
      <Card title="Pumps" loading={gatewayLoading} style={{ height: '100%' }}>
        <Row gutter={[10, 10]}>
          {gateway && <Col span={24}> {renderAddPumpForm()} </Col>}
          {gateway && (
            <Col span={24}>
              <Table
                dataSource={
                  _.sortBy(
                    gateway.pumps.actual.map((a) => {
                      let relayNumber = 0;
                      if (a.relayboardId !== RelayboardId.NOT_SET) {
                        relayNumber = parseInt(
                          a.relayboardId.replace('RELAY_', ''),
                        );
                      }
                      if (a.smartRelayboardId !== SmartRelayboardId.NOT_SET) {
                        relayNumber = parseInt(
                          a.smartRelayboardId.replace('RELAY_', ''),
                        );
                      }
                      return {
                        ...a,
                        relayNumber,
                      };
                    }),
                    ['relayNumber'],
                  ) || []
                }
                loading={!gateway.pumps.inSync}
                pagination={false}
                bordered
                rowKey={(item) => item.id}
              >
                <Column
                  align="center"
                  title="Connection"
                  render={(pump) => {
                    if (pump.smartRelayboardId !== SmartRelayboardId.NOT_SET) {
                      return (
                        <Typography
                          key={`connection-${pump.id}`}
                        >{`Smart Relay`}</Typography>
                      );
                    }
                    return (
                      <Typography
                        key={`connection-${pump.id}`}
                      >{`Relayboard`}</Typography>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="Relay Number"
                  render={(pump) => {
                    return (
                      <Typography key={`relay-${pump.id}`}>
                        {pump.relayNumber}
                      </Typography>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="State"
                  render={(pump) => {
                    return (
                      <Typography key={`state-${pump.id}`}>
                        {pump.state}
                      </Typography>
                    );
                  }}
                />
                <Column
                  align="center"
                  title="Actions"
                  render={(pump) => {
                    return (
                      <Space key={`actions-${pump.id}`}>
                        <Link to={`/pumps/${pump.id}`}>
                          <Button size="small" type="primary">
                            {'Show Details'}
                          </Button>
                        </Link>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            removePumpFromGatewayConnect(gateway.id, pump.id);
                          }}
                        >
                          {'Remove'}
                        </Button>
                      </Space>
                    );
                  }}
                />
              </Table>
            </Col>
          )}
          {gateway && (
            <Col key={`actions-${gateway.id}`} span={24}>
              <Button
                type="primary"
                onClick={() =>
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.PUMP],
                  })
                }
              >
                {'Syncronize'}
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    );
  };

  const renderActuatorsTab = () => {
    return (
      <TabPane tab="Actuators" key="actuators">
        <Row gutter={[16, 16]} justify="start" align="stretch">
          <Col xs={24} lg={12}>
            {renderAertorsCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderPumpsCard()}
          </Col>
        </Row>
      </TabPane>
    );
  };

  const renderAddLcmForm = () => {
    return (
      <Form
        form={addLcmForm}
        layout="inline"
        onFinish={(data: Store) => {
          const { lcmId } = data;
          addLcmToGatewayConnect(gatewayId, { lcmId });
          addLcmForm.resetFields();
        }}
      >
        <Form.Item
          name="lcmId"
          label="Add LCM"
          rules={[{ required: true, message: 'Please input lcmId!' }]}
        >
          <Input placeholder="lcmId" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {'Add LCM'}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderLcmCard = () => {
    const lcmId =
      (gateway && gateway.lcms.requested?.find((l) => l.id))?.id ||
      (gateway && gateway.lcms.actual.find((l) => l.id))?.id ||
      null;

    return (
      <Card
        title={
          <Row justify="space-between" align="middle">
            <Modal
              title="Create LCM"
              visible={isLcmCreateModalVisible}
              onCancel={() => {
                setIsLcmCreateModalVisible(false);
              }}
              footer={null}
            >
              <CreateLcmForm
                onCreated={() => {
                  getGatewayConnect(gatewayId);
                  getControllerConnect(gatewayId);
                  setIsLcmCreateModalVisible(false);
                }}
                organisationId={gateway?.organisation?.id}
                gatewayId={gatewayId}
              />
            </Modal>
            <Typography>{'LCM'}</Typography>

            {gateway && (
              <Button
                type="primary"
                onClick={() =>
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.LCM],
                  })
                }
              >
                {'Syncronize'}
              </Button>
            )}
          </Row>
        }
        loading={gatewayLoading}
        style={{ height: '100%' }}
      >
        <Row gutter={[10, 10]}>
          {gateway && (
            <Col span={24}>
              <Spin spinning={!gateway.lcms.inSync}>
                {lcmId ? (
                  lcmDetailState.loading === false &&
                  lcmDetailState.lcm &&
                  lcmDetailState.lcm.id == lcmId && (
                    <LcmDefaultValuesDescription
                      withId
                      lcm={lcmDetailState.lcm}
                    />
                  )
                ) : (
                  <Empty description="There is no LCM connected">
                    <Button
                      onClick={() => setIsLcmCreateModalVisible(true)}
                      type="primary"
                    >
                      {'Create LCM'}
                    </Button>
                  </Empty>
                )}
              </Spin>
            </Col>
          )}

          {/* {gateway && (
            <Col span={24}>
              {lcmId && (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => removeLcmFromGatewayConnect(gateway.id, lcmId)}
                >
                  Remove
                </Button>
              )}
            </Col>
          )} */}

          {/* {gateway &&
            gateway.lcms.inSync === true &&
            gateway.lcms.actual.length === 0 && (
              <Col span={24}> {renderAddLcmForm()} </Col>
            )} */}
        </Row>
      </Card>
    );
  };

  const renderControllerCard = () => {
    return (
      <ControllerDataForm
        loading={controllerLoading}
        controller={controller || undefined}
        updating={controllerUpdating}
        onUpdate={(cmd) => updateControllerConnect(gatewayId, cmd)}
      />
    );
  };

  const renderNetworkInfoCard = () => {
    return (
      <NetworkInformationCard
        loading={gatewayLoading}
        networkInformation={gateway?.networkInformation}
      />
    );
  };

  const renderPositionCard = () => {
    return (
      <PositionCard loading={gatewayLoading} position={gateway?.position} />
    );
  };

  const renderSoftwareInfoCard = () => {
    return (
      <SoftwareVersionCard
        loading={controllerLoading}
        softwareVersion={controller?.softwareVersion}
      />
    );
  };

  const renderBatchInformationCard = () => {
    return (
      <RenderBatchInformationCard
        batchInformation={gateway?.batchInformation}
        onUpdate={(cmd) => updateBatchInformationConnect(gatewayId, cmd)}
        updating={gatewayUpdating}
      />
    );
  };

  const renderSwapCard = () => {


    return (
      <SwapCard
        originalGatewayId={gatewayId}
        gatewayOptions={gatewayOptions.model?.gateways.filter(g => g.id != gatewayId) || []}
        onUpdate={(replacementGatewayId, originalGatewayId) =>
          swapGatewaysConnect(replacementGatewayId, originalGatewayId)
        }
        updating={gatewaySwapState.loading}
      />
    );

  };

  const renderMiscTab = () => {
    return (
      <TabPane tab="Misc" key="misc">
        <Row gutter={[16, 16]} justify="start" align="stretch">
          <Col xs={24} lg={12}>
            {renderControllerCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderNetworkInfoCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderLcmCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderSoftwareInfoCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderPositionCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderBatchInformationCard()}
          </Col>
          <Col xs={24} lg={12}>
            {renderSwapCard()}
          </Col>
        </Row>
      </TabPane>
    );
  };

  if (gatewayError) {
    return (
      <Result
        status="error"
        title="Can not load Gateway"
        subTitle={gatewayError.message}
      />
    );
  }

  if (controllerError) {
    return (
      <Result
        status="error"
        title="Can not load Controller"
        subTitle={controllerError.message}
      />
    );
  }

  return (
    <Layout>
      <Content>
        <Tabs
          defaultActiveKey={defaultTab}
          onChange={handleTabChanged}
          activeKey={activeKey}
        >
          {renderGeneralTab()}
          {renderSensorsTab()}
          {renderActuatorsTab()}
          {renderMiscTab()}
        </Tabs>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  // gateway related
  gateway: state.gateways.detail.gateway,
  gatewayLoading: state.gateways.detail.loading,
  gatewayError: state.gateways.detail.error,
  gatewayUpdating: state.gateways.detail.updating,
  gatewayUpdateError: state.gateways.detail.updateError,
  gatewayUpdateSuccess: state.gateways.detail.updateSuccess,
  gatewayAddPondState: state.gateways.addPond,
  gatewayRemovePondState: state.gateways.removePond,
  gatewayAddSensorState: state.gateways.addSensor,
  gatewayRemoveSensorState: state.gateways.removeSensor,
  gatewayAddAeratorState: state.gateways.addAerator,
  gatewayRemoveAeratorState: state.gateways.removeAerator,
  gatewayAddPumpState: state.gateways.addPump,
  gatewayRemovePumpState: state.gateways.removePump,

  gatewayAddSmartSensorState: state.gateways.addSmartSensor,
  gatewayRemoveSmartSensorState: state.gateways.removeSmartSensor,

  gatewayAddLcmState: state.gateways.addLcm,
  gatewayRemoveLcmState: state.gateways.removeLcm,
  lcmDetailState: state.lcms.detail,

  gatewaySwapState: state.gateways.swapGateway,

  // controller related
  controller: state.controllers.detail.controller,
  controllerLoading: state.controllers.detail.loading,
  controllerError: state.controllers.detail.error,
  controllerUpdating: state.controllers.detail.updating,
  controllerUpdateError: state.controllers.detail.updateError,
  controllerUpdateSuccess: state.controllers.detail.updateSuccess,

  sensorOptions: state.options.sensorOptions,
  smartSensorOptions: state.options.smartSensorOptions,

  pondOptions: state.options.pondOptions,
  organisationOptions: state.options.organisationOptions,

  gatewayAddOrganisationState: state.organisations.addGateway,
  gatewayRemoveOrganisationState: state.organisations.removeGateway,

  createSensorState: state.sensing.create,

  gatewayOptions: state.options.gatewayOptions,

});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      // gateway related
      getGatewayConnect: getGateway,
      synchronizeGatewayConnect: synchronizeGateway,
      getOrganisationSensorsConnect: getOrganisationSensors,

      addAeratorToGatewayConnect: addAeratorToGateway,
      removeAeratorFromGatewayConnect: removeAeratorFromGateway,
      addSensorToGatewayConnect: addSensorToGateway,
      removeSensorFromGatewayConnect: removeSensorFromGateway,
      addPondToGatewayConnect: addPondToGateway,
      removePondFromGatewayConnect: removePondFromGateway,
      updateGatewayConnect: updateGateway,
      updateBatchInformationConnect: updateBatchInformation,
      swapGatewaysConnect: swap,
      addSmartSensorToGatewayConnect: addSmartSensorToGateway,
      removeSmartSensorFromGatewayConnect: removeSmartSensorFromGateway,

      addLcmToGatewayConnect: addLcmToGateway,
      removeLcmFromGatewayConnect: removeLcmFromGateway,
      getLcmConnect: getLcm,

      addPumpToGatewayConnect: addPumpToGateway,
      removePumpFromGatewayConnect: removePumpFromGateway,

      // controller related
      getControllerConnect: getController,
      updateControllerConnect: updateController,

      getSensorOptionsConnect: getSensorOptions,
      getSmartSensorOptionsConnect: getSmartSensorOptions,

      getPondOptionsConnect: getPondOptions,
      getOrganisationOptionsConnect: getOrganisationOptions,

      addGatewayToOrganisationConnect: addGatewayToOrganisation,
      removeGatewayFromOrganisationConnect: removeGatewayFromOrganisation,

      createSensorConnect: createSensor,


      getGatewayOptionsConnect: getGatewayOptions,
    },
    dispatch,
  );
};

export const GatewayDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedGatewayDetail));

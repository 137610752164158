import {
  CREATE_SMART_SENSOR,
  CREATE_SMART_SENSOR_FAILED,
  CREATE_SMART_SENSOR_SUCCESS,
  FETCH_SMART_SENSOR,
  FETCH_SMART_SENSORS,
  FETCH_SMART_SENSORS_FAILED,
  FETCH_SMART_SENSORS_SUCCESS,
  FETCH_SMART_SENSOR_FAILED,
  FETCH_SMART_SENSOR_SUCCESS,
  UPDATE_SMART_SENSOR,
  UPDATE_SMART_SENSOR_SUCCESS,
  UPDATE_SMART_SENSOR_FAILED,
  ADD_SMART_SENSOR_TO_ORGANISATION,
  ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS,
  ADD_SMART_SENSOR_TO_ORGANISATION_FAILED,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED,
  FETCH_ORGANISATION_SMART_SENSORS,
  FETCH_ORGANISATION_SMART_SENSORS_SUCCESS,
  FETCH_ORGANISATION_SMART_SENSORS_FAILED,
} from '../../constants';
import { OrganisationModel, SmartSensorModel } from '../../model/domain';
import { SimpleSmartSensorModel } from '../../model/simple';
import {
  ListSmartSensorsQuery,
  ListSmartSensorsQueryModel,
} from '../../model/querys';

// SMART_SENSORS
export interface CreateSmartSensorAction {
  type: CREATE_SMART_SENSOR;
}

export interface CreateSmartSensorSuccessAction {
  type: CREATE_SMART_SENSOR_SUCCESS;
  payload: SmartSensorModel;
}

export interface CreateSmartSensorFailedAction {
  type: CREATE_SMART_SENSOR_FAILED;
  payload: Error;
}

export interface FetchSmartSensorAction {
  type: FETCH_SMART_SENSOR;
}

export interface FetchSmartSensorSuccessAction {
  type: FETCH_SMART_SENSOR_SUCCESS;
  payload: SmartSensorModel;
}

export interface FetchSmartSensorFailedAction {
  type: FETCH_SMART_SENSOR_FAILED;
  payload: Error;
}

export interface FetchSmartSensorsAction {
  type: FETCH_SMART_SENSORS;
  payload: ListSmartSensorsQuery;
}

export interface FetchSmartSensorsSuccessAction {
  type: FETCH_SMART_SENSORS_SUCCESS;
  payload: {
    query: ListSmartSensorsQueryModel;
    smartSensors: SimpleSmartSensorModel[];
  };
}

export interface FetchSmartSensorsFailedAction {
  type: FETCH_SMART_SENSORS_FAILED;
  payload: Error;
}

export interface FetchOrganisationSmartSensorsAction {
  type: FETCH_ORGANISATION_SMART_SENSORS;
  payload: ListSmartSensorsQuery;
}

export interface FetchOrganisationSmartSensorsSuccessAction {
  type: FETCH_ORGANISATION_SMART_SENSORS_SUCCESS;
  payload: {
    query: ListSmartSensorsQueryModel;
    smartSensors: SimpleSmartSensorModel[];
  };
}

export interface FetchOrganisationSmartSensorsFailedAction {
  type: FETCH_ORGANISATION_SMART_SENSORS_FAILED;
  payload: Error;
}

export interface UpdateSmartSensorAction {
  type: UPDATE_SMART_SENSOR;
}

export interface UpdateSmartSensorSuccessAction {
  type: UPDATE_SMART_SENSOR_SUCCESS;
  payload: SmartSensorModel;
}

export interface UpdateSmartSensorFailedAction {
  type: UPDATE_SMART_SENSOR_FAILED;
  payload: Error;
}

export interface AddSmartSensorToOrganisationAction {
  type: ADD_SMART_SENSOR_TO_ORGANISATION;
  organisationId: string;
  smartSensorId: string;
}

export interface AddSmartSensorToOrganisationSuccessAction {
  type: ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface AddSmartSensorToOrganisationFailedAction {
  type: ADD_SMART_SENSOR_TO_ORGANISATION_FAILED;
  payload: Error;
}

export interface RemoveSmartSensorFromOrganisationAction {
  type: REMOVE_SMART_SENSOR_FROM_ORGANISATION;
  organisationId: string;
  smartSensorId: string;
}

export interface RemoveSmartSensorFromOrganisationSuccessAction {
  type: REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS;
  payload: OrganisationModel;
}

export interface RemoveSmartSensorFromOrganisationFailedAction {
  type: REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED;
  payload: Error;
}

export type OrganisationAddSmartSensorAction =
  | AddSmartSensorToOrganisationAction
  | AddSmartSensorToOrganisationSuccessAction
  | AddSmartSensorToOrganisationFailedAction;
export type OrganisationRemoveSmartSensorAction =
  | RemoveSmartSensorFromOrganisationAction
  | RemoveSmartSensorFromOrganisationSuccessAction
  | RemoveSmartSensorFromOrganisationFailedAction;
export type SmartSensorUpdateAction =
  | UpdateSmartSensorAction
  | UpdateSmartSensorSuccessAction
  | UpdateSmartSensorFailedAction;
export type SmartSensorCreateAction =
  | CreateSmartSensorAction
  | CreateSmartSensorSuccessAction
  | CreateSmartSensorFailedAction;
export type SmartSensorFetchAction =
  | FetchSmartSensorAction
  | FetchSmartSensorSuccessAction
  | FetchSmartSensorFailedAction;
export type SmartSensorsFetchAction =
  | FetchSmartSensorsAction
  | FetchSmartSensorsSuccessAction
  | FetchSmartSensorsFailedAction;
export type OrganisationSmartSensorsFetchAction =
  | FetchOrganisationSmartSensorsAction
  | FetchOrganisationSmartSensorsSuccessAction
  | FetchOrganisationSmartSensorsFailedAction;
export type SmartSensorsAction =
  | SmartSensorCreateAction
  | SmartSensorFetchAction
  | SmartSensorsFetchAction
  | SmartSensorUpdateAction
  | OrganisationAddSmartSensorAction
  | OrganisationRemoveSmartSensorAction
  | OrganisationSmartSensorsFetchAction;

// Action creators
export function smartSensorCreate(): CreateSmartSensorAction {
  return {
    type: CREATE_SMART_SENSOR,
  };
}

export function smartSensorCreated(
  smartSensor: SmartSensorModel,
): CreateSmartSensorSuccessAction {
  return {
    type: CREATE_SMART_SENSOR_SUCCESS,
    payload: smartSensor,
  };
}

export function smartSensorCreateFailed(
  error: Error,
): CreateSmartSensorFailedAction {
  return {
    type: CREATE_SMART_SENSOR_FAILED,
    payload: error,
  };
}

export function smartSensorFetch(): FetchSmartSensorAction {
  return {
    type: FETCH_SMART_SENSOR,
  };
}

export function smartSensorFetched(
  smartSensor: SmartSensorModel,
): FetchSmartSensorSuccessAction {
  return {
    type: FETCH_SMART_SENSOR_SUCCESS,
    payload: smartSensor,
  };
}

export function smartSensorFetchFailed(
  error: Error,
): FetchSmartSensorFailedAction {
  return {
    type: FETCH_SMART_SENSOR_FAILED,
    payload: error,
  };
}

export function smartSensorsFetch(
  query: ListSmartSensorsQuery,
): FetchSmartSensorsAction {
  return {
    type: FETCH_SMART_SENSORS,
    payload: query,
  };
}

export function smartSensorsFetched(
  query: ListSmartSensorsQueryModel,
  smartSensors: SimpleSmartSensorModel[],
): FetchSmartSensorsSuccessAction {
  return {
    type: FETCH_SMART_SENSORS_SUCCESS,
    payload: { query, smartSensors },
  };
}

export function smartSensorsFetchFailed(
  error: Error,
): FetchSmartSensorsFailedAction {
  return {
    type: FETCH_SMART_SENSORS_FAILED,
    payload: error,
  };
}

export function smartSensorUpdate(): UpdateSmartSensorAction {
  return {
    type: UPDATE_SMART_SENSOR,
  };
}

export function smartSensorUpdated(
  smartSensor: SmartSensorModel,
): UpdateSmartSensorSuccessAction {
  return {
    type: UPDATE_SMART_SENSOR_SUCCESS,
    payload: smartSensor,
  };
}

export function smartSensorUpdateFailed(
  error: Error,
): UpdateSmartSensorFailedAction {
  return {
    type: UPDATE_SMART_SENSOR_FAILED,
    payload: error,
  };
}

export function organisationSmartSensorAdd(
  organisationId: string,
  smartSensorId: string,
): AddSmartSensorToOrganisationAction {
  return {
    type: ADD_SMART_SENSOR_TO_ORGANISATION,
    organisationId,
    smartSensorId,
  };
}

export function organisationSmartSensorAdded(
  organisation: OrganisationModel,
): AddSmartSensorToOrganisationSuccessAction {
  return {
    type: ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationSmartSensorAddFailed(
  error: Error,
): AddSmartSensorToOrganisationFailedAction {
  return {
    type: ADD_SMART_SENSOR_TO_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationSmartSensorRemove(
  organisationId: string,
  smartSensorId: string,
): RemoveSmartSensorFromOrganisationAction {
  return {
    type: REMOVE_SMART_SENSOR_FROM_ORGANISATION,
    organisationId,
    smartSensorId,
  };
}

export function organisationSmartSensorRemoved(
  organisation: OrganisationModel,
): RemoveSmartSensorFromOrganisationSuccessAction {
  return {
    type: REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS,
    payload: organisation,
  };
}

export function organisationSmartSensorRemoveFailed(
  error: Error,
): RemoveSmartSensorFromOrganisationFailedAction {
  return {
    type: REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED,
    payload: error,
  };
}

export function organisationSmartSensorsFetch(
  query: ListSmartSensorsQuery,
): FetchOrganisationSmartSensorsAction {
  return {
    type: FETCH_ORGANISATION_SMART_SENSORS,
    payload: query,
  };
}

export function organisationSmartSensorsFetched(
  query: ListSmartSensorsQueryModel,
  smartSensors: SimpleSmartSensorModel[],
): FetchOrganisationSmartSensorsSuccessAction {
  return {
    type: FETCH_ORGANISATION_SMART_SENSORS_SUCCESS,
    payload: { query, smartSensors },
  };
}

export function organisationSmartSensorsFetchFailed(
  error: Error,
): FetchOrganisationSmartSensorsFailedAction {
  return {
    type: FETCH_ORGANISATION_SMART_SENSORS_FAILED,
    payload: error,
  };
}

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateLcmCmd, UpdateLcmCmd } from '../model/cmds';
import { LcmModel } from '../model/domain';
import { ListLcmsQuery, ListLcmsQueryModel } from '../model/querys';

export class LcmApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createLcm = this.createLcm.bind(this);
    this.fetchLcm = this.fetchLcm.bind(this);
    this.listLcms = this.listLcms.bind(this);
    this.updateLcm = this.updateLcm.bind(this);
  }

  public createLcm(cmd: CreateLcmCmd): Promise<LcmModel> {
    return this.post<LcmModel, CreateLcmCmd, AxiosResponse<LcmModel>>(
      `/v1/lcms`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchLcm(lcmId: string): Promise<LcmModel> {
    return this.get<LcmModel, AxiosResponse<LcmModel>>(
      `/v1/lcms/${lcmId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listLcms(cmd?: ListLcmsQuery): Promise<ListLcmsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<ListLcmsQueryModel, AxiosResponse<ListLcmsQueryModel>>(
      '/v1/lcms',
      config,
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateLcm(lcmId: string, cmd: UpdateLcmCmd): Promise<LcmModel> {
    return this.patch<LcmModel, UpdateLcmCmd, AxiosResponse<LcmModel>>(
      `/v1/lcms/${lcmId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const lcmApi = new LcmApi();

import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Card,
  Descriptions,
  Table,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getSmartSensors } from '../../duck/modules/smartSensors';
import Column from 'antd/lib/table/Column';
import { SimpleSmartSensorModel } from '../../model/simple';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedSmartSensorsResponsiveTable: React.FC<Props> = ({
  smartSensors,
  loading,
  error,
  total,
  getSmartSensorsConnect,
  queryParams,
  reload,
  onReloaded,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  React.useEffect(() => {
    getSmartSensorsConnect({
      limit,
      offset,
    });
    onReloaded();
  }, [offset, limit, reload]);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderList = () => {
    return (
      <List
        loading={loading}
        grid={{ gutter: 10, column: 1 }}
        dataSource={smartSensors}
        renderItem={(smartSensor) => (
          <List.Item>
            <Card>
              <Descriptions
                column={3}
                layout="horizontal"
                colon={true}
                size="small"
                bordered
              >
                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Smart Sensor'}
                    </Typography>
                  }
                >
                  <Link
                    key={smartSensor.id}
                    to={`/smartSensors/${smartSensor.id}`}
                  >
                    {smartSensor.name != undefined
                      ? smartSensor.name
                      : smartSensor.id}
                  </Link>{' '}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Organisation'}
                    </Typography>
                  }
                >
                  {smartSensor.organisation ? (
                    <Link
                      key={smartSensor.id}
                      to={`/organisations/${smartSensor.organisation.id}`}
                    >
                      {smartSensor.organisation.name ||
                        smartSensor.organisation.id}
                    </Link>
                  ) : (
                    <Typography>{'-'}</Typography>
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Gateway'}
                    </Typography>
                  }
                >
                  {smartSensor.gateway ? (
                    <Link
                      key={smartSensor.id}
                      to={`/gateways/${smartSensor.gateway.id}`}
                    >
                      {smartSensor.gateway.name || smartSensor.gateway.id}
                    </Link>
                  ) : (
                    <Typography>{'-'}</Typography>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </List.Item>
        )}
      />
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={smartSensors}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Smart Sensor"
          render={(smartSensor: SimpleSmartSensorModel) => (
            <Link key={smartSensor.id} to={`/smartSensors/${smartSensor.id}`}>
              {smartSensor.name != undefined
                ? smartSensor.name
                : smartSensor.id}
            </Link>
          )}
        />
        <Column
          align="center"
          title="Organisation"
          render={(smartSensor: SimpleSmartSensorModel) => {
            if (smartSensor.organisation) {
              return (
                <Link
                  key={smartSensor.id}
                  to={`/organisations/${smartSensor.organisation.id}`}
                >
                  {smartSensor.organisation.name || smartSensor.organisation.id}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
        <Column
          align="center"
          title="Gateway"
          render={(smartSensor: SimpleSmartSensorModel) => {
            if (smartSensor.gateway) {
              return (
                <Link
                  key={smartSensor.id}
                  to={`/gateways/${smartSensor.gateway.id}`}
                >
                  {smartSensor.gateway.name || smartSensor.gateway.id}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      </Table>
    );
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Smart Sensors"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      <div className="hide-on-mobile">{renderTable()}</div>

      <div className="hide-on-desktop">{renderList()}</div>

      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  smartSensors: state.smartSensors.list.smartSensors,
  loading: state.smartSensors.list.loading,
  error: state.smartSensors.list.error,
  total: state.smartSensors.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSmartSensorsConnect: getSmartSensors,
    },
    dispatch,
  );
};

export const SmartSensorsResponsiveTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSmartSensorsResponsiveTable);

import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Tooltip,
  Select,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { ManufacturerModel } from '../../model/domain';
import { UpdateManufacturerCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';
import { ProductCategory } from '../../model/enums';
import { SimpleManufacturerModel } from '../../model/simple';

type Props = {
  manufacturer: ManufacturerModel;
  onUpdate: (cmd: UpdateManufacturerCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const ManufacturerDataForm: React.FC<Props> = ({
  manufacturer,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    form.setFieldsValue({
      ...manufacturer,
      name: manufacturer.name,
      categories: manufacturer.categories,
    });
  }, [manufacturer]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Manufacturer updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Manufacturer',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { categories, name } = values;
    const cmd: UpdateManufacturerCmd = {};

    if (categories !== manufacturer.categories) {
      cmd.categories = categories;
    }

    if (name !== manufacturer.name) {
      cmd.name = name;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && manufacturer) {
      cmd.categories = manufacturer.categories;
      cmd.name = manufacturer.name;
    }

    if (Object.keys(cmd).length > 0) {
      onUpdate(cmd);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Name of the Manufacturer" />
      </Form.Item>

      <Form.Item
        label="Categories"
        name="categories"
        rules={[{ required: false }]}
      >
        <Select
          placeholder="Select manufacturer categories"
          mode="multiple"
          allowClear
        >
          <Select.Option value={ProductCategory.FEED}>{'FEED'}</Select.Option>
          <Select.Option value={ProductCategory.FEED_SUPPLEMENT}>
            {'FEED_SUPPLEMENT'}
          </Select.Option>
          <Select.Option value={ProductCategory.CHEMICAL}>
            {'CHEMICAL'}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Manufacturer
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Table,
  Form,
  Select,
  Input,
  Tag,
  Checkbox
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import Column from 'antd/lib/table/Column';
import { MqttConnectionStatus } from '../../model/enums';

import { getGateways } from '../../duck/modules/gateways';
import { functionalColor } from '../../colors';
import {
  renderGatewayConnectionStatus,
  renderGatewayCpuTemperature,
  renderGatewayDetailsLink,
  renderGatewayMainboardTemperature,
  renderGatewayOrganisation,
  renderGatewayPondsList,
  renderGatewaySensorsRegistered,
  renderNumberOfAerators,
  renderNumberOfPumps,
  renderTags,
} from './gatewayUtil';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      mqttConnectionStatuses?: MqttConnectionStatus[];
      tags?: string[];
      showArchived?: string;
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedGatewaysTable: React.FC<Props> = ({
  gateways,
  loading,
  error,
  total,
  getAnnouncementsConnect: getGatewaysConnect,
  queryParams,
  reload,
  onReloaded,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);
  const [showArchived, setShowDeleted] = React.useState(
    queryParams?.showArchived === 'true' || false,
  );
  const [mqttConnectionStatuses, setMqttConnectionStatuses] = React.useState(
    queryParams?.mqttConnectionStatuses || [],
  );
  const [tags, setTags] = React.useState(queryParams?.tags || []);

  console.log(tags);

  React.useEffect(() => {
    getGatewaysConnect({
      limit,
      offset,
      mqttConnectionStatuses,
      isArchived: showArchived,
      tags,
    });
    onReloaded();
  }, [offset, limit, reload, mqttConnectionStatuses, showArchived, tags]);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={gateways}
        scroll={{ x: true }}
      >
        <Column
          align="left"
          title="Status"
          render={renderGatewayConnectionStatus}
        />

        <Column
          align="left"
          title="Gateway"
          render={renderGatewayDetailsLink}
        />

        <Column
          align="left"
          title="Organisation"
          render={renderGatewayOrganisation}
        />

        <Column align="left" title="Ponds" render={renderGatewayPondsList} />

        <Column
          align="left"
          title="Connected sensors"
          render={renderGatewaySensorsRegistered}
        />

        <Column
          align="left"
          title="Number of Aerators"
          render={renderNumberOfAerators}
        />

        <Column
          align="left"
          title="Number of Pumps"
          render={renderNumberOfPumps}
        />

        <Column
          align="left"
          title="Mainboard Temperature"
          render={renderGatewayMainboardTemperature}
        />

        <Column
          align="left"
          title="CPU Temperature"
          render={renderGatewayCpuTemperature}
        />

        <Column align="left" title="Tags" render={renderTags} />
      </Table>
    );
  };

  const renderFilter = () => {
    return (
      <div>
        <Form
          layout="inline"
          style={{ width: '100%' }}
          initialValues={{
            mqttConnectionStatuses: mqttConnectionStatuses,
            tags: tags,
          }}
        >
          <Form.Item name="showArchived" valuePropName="showArchived">
            <Checkbox
              onChange={(e) => {
                const checked = new Boolean(e.target.checked);
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('showArchived', checked.toString());
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setShowDeleted(checked.valueOf());
              }}
              checked={showArchived}
            >
              {'Show archived'}
            </Checkbox>
          </Form.Item>

          <Form.Item name="mqttConnectionStatuses">
            <Select
              mode="tags"
              placeholder="Filter by status"
              value={mqttConnectionStatuses}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('mqttConnectionStatuses');
                value.forEach((d) =>
                  searchParams.append('mqttConnectionStatuses', d),
                );
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setMqttConnectionStatuses(data);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            >
              <Select.Option value={MqttConnectionStatus.CONNECTED}>
                <Tag color={functionalColor.success}>{'Online'}</Tag>
              </Select.Option>
              <Select.Option value={MqttConnectionStatus.NOT_CONNECTED}>
                <Tag color={functionalColor.error}>{'Offline'}</Tag>
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="tags">
            <Select
              mode="tags"
              placeholder="Search by Tags"
              value={tags}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('tags');
                value.forEach((tag) => {
                  searchParams.append('tags', tag);
                });
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setTags(value);
              }}
              allowClear={true}
              style={{ minWidth: 300 }}
            />
          </Form.Item>
        </Form>
        <br />
      </div>
    );
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Gateways"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      {renderFilter()}
      {renderTable()}
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  gateways: state.gateways.list.gateways,
  loading: state.gateways.list.loading,
  error: state.gateways.list.error,
  total: state.gateways.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAnnouncementsConnect: getGateways,
    },
    dispatch,
  );
};

export const GatewaysTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedGatewaysTable);

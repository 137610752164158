import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Select,
  Space,
  notification,
  DatePicker,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'antd/lib/form/interface';
import { createSample } from '../../duck/modules/samples';

import { usePrevious } from '../_util/hook';
import { CreateSampleCmd } from '../../model/cmds';
import { SampleModel } from '../../model/domain';
import { useState } from 'react';
import moment from 'moment';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pondId: string;
    cycleId: string;
    onCreated?: (sampleModel: SampleModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateSampleForm: React.FC<Props> = ({
  createSampleConnect,
  onCreated,
  onError,
  error,
  success,
  loading,
  pondId,
  cycleId,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ success, error });
  const [formValues, setFormValues] = useState(form.getFieldsValue);

  React.useEffect(() => {
    if (prev?.success === null && success) {
      notification.success({
        message: 'Sample created',
      });
    }
    if (success && onCreated) {
      onCreated(success);
    }
    form.resetFields();
    setFormValues(form.getFieldsValue);
  }, [success]);

  React.useEffect(() => {
    if (prev?.error === null && error) {
      notification.error({
        message: 'Sample creation error',
        description: error?.message,
      });
      if (error && onError) {
        onError(error);
      }
    }
  }, [error]);

  const onFinish = (values: Store) => {
    const {
      date,
      numberOfShrimpsPerKg,
      weightOfShrimpInGram,
      sizeOfShrimpInCm,
    } = values;
    const timestamp = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    const cmd: CreateSampleCmd = {
      timestamp,
      cycleId,
    };

    const numberOfShrimpsPerKgValue = parseInt(numberOfShrimpsPerKg);
    const sizeOfShrimpInCmValue = parseFloat(sizeOfShrimpInCm);
    const weightOfShrimpInGramValue = parseFloat(weightOfShrimpInGram);

    if (!!numberOfShrimpsPerKgValue) {
      cmd.numberOfShrimpsPerKg = numberOfShrimpsPerKgValue;
    } else if (!!sizeOfShrimpInCmValue) {
      cmd.sizeOfShrimpInCm = sizeOfShrimpInCmValue;
    } else if (!!weightOfShrimpInGramValue) {
      cmd.weightOfShrimpInGram = weightOfShrimpInGramValue;
    }

    createSampleConnect(pondId, cmd);
  };

  return (
    <Form
      form={form}
      requiredMark={false}
      onValuesChange={() => setFormValues(form.getFieldsValue())}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        date: moment(),
      }}
    >
      <Form.Item
        label="What date the sample was taken?"
        name="date"
        rules={[{ required: true }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          format={'DD / MM / YYYY'}
          disabledDate={(d) => d.isAfter(moment())}
        />
      </Form.Item>

      <Form.Item
        label="Number of shrimps per kilogram?"
        name="numberOfShrimpsPerKg"
        rules={[
          {
            required:
              !!!form.getFieldValue('sizeOfShrimpInCm') &&
              !!!form.getFieldValue('weightOfShrimpInGram'),
          },
        ]}
      >
        <Input type="number" placeholder="10" />
      </Form.Item>

      <Form.Item
        label="How many centimeters was the shrimp?"
        name="sizeOfShrimpInCm"
      >
        <Input type="number" placeholder="13.5" />
      </Form.Item>

      <Form.Item
        label="How many grams did the shrimp weigh?"
        name="weightOfShrimpInGram"
      >
        <Input type="number" placeholder="40" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            {'Create Sample'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.samples.create.loading,
  error: state.samples.create.error,
  success: state.samples.create.sample,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createSampleConnect: createSample,
    },
    dispatch,
  );
};

export const CreateSampleForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateSampleForm);

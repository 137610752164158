import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Row, Card, Col, Layout, Alert, Typography, Result, Tabs, Button, Switch, Space } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getOrganisation,
  updateOrganisation,
} from '../../duck/modules/organisations';
import { OrganisationDataForm } from '../organisation/organisationDataForm';
import { UpdateOrganisationCmd } from '../../model/cmds';
import { OrganisationMemberForm } from '../organisation/organisationMemberForm';
import { OrganisationFarmsList } from '../organisation/organisationFarmsList';
import { OrganisationSensorsList } from '../organisation/organisationSensorsList';
import { OrganisationGatewaysList } from '../organisation/organisationGatewaysList';
import { OrganisationSmartSensorsList } from '../organisation/organisationSmartSensorsList';
import { OrganisationLcmsList } from '../organisation/organisationLcmsList';
import Column from 'antd/lib/table/Column';
import { activateOrganisation, deactivateOrganisation } from '../../duck/modules/activation';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{
    organisationId: string;
  }>;

const UnconnectedOrganisationDetail: React.FC<Props> = ({
  getOrganisationConnect,
  updateOrganisationConnect,
  activateOrganisationConnect,
  deactivateOrganisationConnect,
  match,
  organisation,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  activationState,
  ...otherProps
}) => {
  const { organisationId } = match.params;
  React.useEffect(() => {
    getOrganisationConnect(organisationId);
  }, []);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load organisation"
        subTitle={error.message}
      />
    );
  }

  const renderGeneralTabContent = () => {
    return (
      <Row gutter={[20, 20]} justify="center">
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card title="General Data" loading={loading}>
            <OrganisationDataForm
              updating={updating}
              updateSuccess={updateSuccess}
              updateError={updateError}
              onUpdate={(cmd: UpdateOrganisationCmd) =>
                updateOrganisationConnect(organisationId, cmd)
              }
              organisation={organisation}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card title="Member Data" loading={loading}>
            <OrganisationMemberForm
              organisationId={organisationId}
              organisation={organisation}
            />
          </Card>
        </Col>
        <Col span={24}>
          <OrganisationFarmsList organisationId={organisationId} />
        </Col>
      </Row>
    );
  };

  const renderDevicesTabContent = () => {
    return (
      <Row gutter={[20, 20]} justify="center">
        <Col span={24}>
          <Card>
            <OrganisationGatewaysList organisationId={organisationId} />
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <OrganisationSensorsList organisationId={organisationId} />
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <OrganisationSmartSensorsList organisationId={organisationId} />
          </Card>
        </Col>
        {/* <Col span={24}>
          <Card>
            <OrganisationLcmsList organisationId={organisationId} />
          </Card>
        </Col> */}
      </Row>
    );
  };

  const activationWidget = () => {
    return (
      <Switch
        checked={organisation?.isActive}
        checkedChildren={"Active"}
        unCheckedChildren={"Inactive"}
        onClick={() => organisation?.isActive ? deactivateOrganisationConnect(organisationId) : activateOrganisationConnect(organisationId)}
        loading={activationState.loading}
      />
    );
  }

  const enabledWidget = () => {
    return (
      <Switch
        checked={organisation?.enabled}
        checkedChildren={"Enabled"}
        unCheckedChildren={"Disabled"}
        onClick={() => organisation?.enabled ? updateOrganisationConnect(organisationId, { enabled: false }) : updateOrganisationConnect(organisationId, { enabled: true })}
        loading={updating}
      />
    );
  }

  return (
    <Layout>
      <Content>
        <div className="card-container">

          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{enabledWidget()}</Col>
              <Col>{activationWidget()}</Col>
            </Row>
          </Col>

          <Tabs
            size="large"
            type="line"
            defaultActiveKey="general"
            tabPosition="top"
          >
            <TabPane key="general" tab="General">
              {renderGeneralTabContent()}

            </TabPane>
            <TabPane key="devices" tab="Devices">
              {renderDevicesTabContent()}
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  organisation: state.organisations.detail.organisation,
  loading: state.organisations.detail.loading,
  error: state.organisations.detail.error,
  updating: state.organisations.detail.updating,
  updateError: state.organisations.detail.updateError,
  updateSuccess: state.organisations.detail.updateSuccess,
  activationState: state.activation.organisation
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationConnect: getOrganisation,
      updateOrganisationConnect: updateOrganisation,
      activateOrganisationConnect: activateOrganisation,
      deactivateOrganisationConnect: deactivateOrganisation,

    },
    dispatch,
  );
};

export const OrganisationDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationDetail);

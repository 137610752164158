import {
  Button,
  Col,
  Descriptions,
  List,
  Modal,
  notification,
  Popconfirm,
  Result,
  Row,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck';
import { Link } from 'react-router-dom';
import {
  addPumpToGateway,
  removePumpFromGateway,
  synchronizeGateway,
} from '../../duck/modules/gateways';
import {
  addPumpToPond,
  refreshPond,
  removePumpFromPond,
} from '../../duck/modules/ponds';
import { PumpModel, PondModel } from '../../model/domain';
import {
  GatewayStateField,
  MqttConnectionStatus,
  RelayConnectionType,
} from '../../model/enums';
import { getRelayboardIdOptions } from '../../util';
import { usePrevious } from '../_util/hook';
import { CreatePumpForm } from '../pump/createPumpForm';
import DescriptionsItem from 'antd/lib/descriptions/Item';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pond: PondModel;
  };

export const UnconnectedPondPumpsList: React.FC<Props> = ({
  pond,
  refreshPondConnect,
  synchronizeGatewayConnect,

  // gateway
  removePumpFromGatewayConnect,
  removePumpFromPondConnect,
  refreshState,

  gatewayAddPumpState,
  gatewayRemovePumpState,
  pondAddPumpState,
  pondRemovePumpState,
}) => {
  const [showAddPumpModal, setShowAddPumpModal] = React.useState(false);

  const prev = usePrevious({
    gatewayAddPumpState,
    gatewayRemovePumpState,
    pondAddPumpState,
    pondRemovePumpState,
  });

  React.useEffect(() => {
    if (
      prev?.gatewayAddPumpState.loading &&
      !gatewayAddPumpState.loading &&
      !gatewayAddPumpState.error
    ) {
      notification.success({
        message: 'Pump added to Gateway',
      });
    }
  }, [gatewayAddPumpState]);

  React.useEffect(() => {
    if (
      prev?.gatewayAddPumpState.loading &&
      !gatewayAddPumpState.loading &&
      gatewayAddPumpState.error
    ) {
      notification.error({
        message: 'Error while adding pump to Gateway',
        description: gatewayAddPumpState.error.message,
      });
    }
  }, [gatewayAddPumpState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemovePumpState.loading &&
      !gatewayRemovePumpState.loading &&
      !gatewayRemovePumpState.error
    ) {
      notification.success({
        message: 'Pump removed from Gateway',
      });
    }
  }, [gatewayRemovePumpState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemovePumpState.loading &&
      !gatewayRemovePumpState.loading &&
      gatewayRemovePumpState.error
    ) {
      notification.error({
        message: 'Error while removing pump from Gateway',
        description: gatewayRemovePumpState.error.message,
      });
    }
  }, [gatewayRemovePumpState]);

  React.useEffect(() => {
    if (
      prev?.pondAddPumpState.loading &&
      !pondAddPumpState.loading &&
      !pondAddPumpState.error
    ) {
      setShowAddPumpModal(false);
      notification.success({
        message: 'Pump added to Pond',
      });
    }
  }, [pondAddPumpState]);

  React.useEffect(() => {
    if (
      prev?.pondAddPumpState.loading &&
      !pondAddPumpState.loading &&
      pondAddPumpState.error
    ) {
      notification.error({
        message: 'Error while adding pump to Pond',
        description: pondAddPumpState.error.message,
      });
    }
  }, [pondAddPumpState]);

  React.useEffect(() => {
    if (
      prev?.pondRemovePumpState.loading &&
      !pondRemovePumpState.loading &&
      !pondRemovePumpState.error
    ) {
      notification.success({
        message: 'Pump removed from Pond',
      });
    }
  }, [pondRemovePumpState]);

  React.useEffect(() => {
    if (
      prev?.pondRemovePumpState.loading &&
      !pondRemovePumpState.loading &&
      pondRemovePumpState.error
    ) {
      notification.error({
        message: 'Error while removing pump from Pond',
        description: pondRemovePumpState.error.message,
      });
    }
  }, [pondRemovePumpState]);

  const renderPumpsTabContent = () => {
    if (pond.gateway) {
      const gatewayId = pond.gateway.id;
      const inSync = pond.gateway.pumps.inSync;
      const isOnline =
        pond.gateway.mqttConnectionStatus === MqttConnectionStatus.CONNECTED;

      return (
        <List
          bordered
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 3,
          }}
          locale={{
            emptyText: <Typography>No pumps</Typography>,
          }}
          header={
            <Row justify={'space-between'}>
              <Col>
                <Typography.Text strong>Pumps</Typography.Text>
              </Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  disabled={!isOnline}
                  onClick={() => setShowAddPumpModal(true)}
                >
                  {'Add pump'}
                </Button>
              </Col>
            </Row>
          }
          footer={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  synchronizeGatewayConnect(gatewayId, {
                    stateFields: [GatewayStateField.PUMP],
                  });
                  refreshPondConnect(pond.id);
                }}
              >
                Force Sync
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  refreshPondConnect(pond.id);
                }}
              >
                Refresh
              </Button>
            </Space>
          }
          loading={!inSync}
          dataSource={
            pond.pumps.sort((p1, p2) => p1.id.localeCompare(p2.id)) || []
          }
          renderItem={(pump) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <Descriptions bordered column={1}>
                    {pump.name && (
                      <Descriptions.Item
                        label={
                          <Space>
                            <Typography.Text>{'Name'}</Typography.Text>
                          </Space>
                        }
                      >
                        <Typography.Text strong>
                          {pump.name || ''}
                        </Typography.Text>
                      </Descriptions.Item>
                    )}

                    <Descriptions.Item label={'ID'}>
                      <Link to={`/pumps/${pump.id}`}>{pump.id}</Link>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={
                        <Space>
                          <Typography.Text>{'Relay'}</Typography.Text>
                        </Space>
                      }
                    >
                      {pump.relay != undefined ? (
                        <Descriptions layout={'vertical'} bordered={true}>
                          <Descriptions.Item label={'Connection'}>
                            <Typography.Text>
                              {pump.relay.connectionType}
                            </Typography.Text>
                          </Descriptions.Item>
                          {pump.relay.connectionType ===
                            RelayConnectionType.RELAY_BOARD && (
                            <Descriptions.Item label={'Relayboard ID'}>
                              <Typography.Text>
                                {pump.relay.relayboardId}
                              </Typography.Text>
                            </Descriptions.Item>
                          )}
                          {pump.relay.connectionType ===
                            RelayConnectionType.SMART_RELAY && (
                            <Descriptions.Item label={'Smart Relay'}>
                              <Typography.Text>
                                {pump.relay.smartRelayId}
                              </Typography.Text>
                            </Descriptions.Item>
                          )}
                          {pump.relay.connectionType ===
                            RelayConnectionType.SMART_RELAY && (
                            <Descriptions.Item label={'Smart Relayboard ID'}>
                              <Typography.Text>
                                {pump.relay.smartRelayboardId}
                              </Typography.Text>
                            </Descriptions.Item>
                          )}
                          <Descriptions.Item label={'State'}>
                            <Typography.Text>
                              {pump.relay.state}
                            </Typography.Text>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : (
                        '-'
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label={'Mode'}>
                      <Typography>{pump.mode}</Typography>
                    </Descriptions.Item>

                    <DescriptionsItem label="Actions">
                      <Space>
                        {isOnline ? (
                          <Popconfirm
                            title={
                              'Are you sure to remove this pump from the pond?'
                            }
                            onConfirm={() => {
                              removePumpFromPondConnect(pond.id, pump.id);
                              removePumpFromGatewayConnect(
                                gatewayId,
                                pump.id,
                                pond.id,
                              );
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              loading={
                                pump.id === pondRemovePumpState.pumpId ||
                                pump.id == gatewayRemovePumpState.pumpId
                              }
                              danger
                              size="small"
                              disabled={!isOnline}
                            >
                              Remove
                            </Button>
                          </Popconfirm>
                        ) : (
                          <Button danger size="small" disabled>
                            Remove
                          </Button>
                        )}
                      </Space>
                    </DescriptionsItem>
                  </Descriptions>
                }
              />
            </List.Item>
          )}
        />
      );
    } else {
      return (
        <Typography.Title level={4}>
          You need an gateway in place to change pumps!
        </Typography.Title>
      );
    }
  };

  const renderAddPumpModal = () => {
    return (
      <Modal
        visible={showAddPumpModal}
        onOk={() => setShowAddPumpModal(false)}
        onCancel={() => setShowAddPumpModal(false)}
        footer={null}
        title={'Add pump'}
      >
        <CreatePumpForm
          relayboardOptions={
            pond.gateway && getRelayboardIdOptions(pond.gateway)
          }
          organisationId={pond.organisation.id}
          pondId={pond.id}
          gatewayId={pond.gateway?.id}
          onCreated={(_pump: PumpModel) => {
            setShowAddPumpModal(false);
          }}
        />
      </Modal>
    );
  };

  if (pond.gateway) {
    return (
      <div>
        {renderPumpsTabContent()}
        {renderAddPumpModal()}
      </div>
    );
  }

  return (
    <div>
      <Result status="info" title="Pond has no Gateway!" />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  refreshState: state.ponds.detail.refresh,
  gatewayAddPumpState: state.gateways.addPump,
  gatewayRemovePumpState: state.gateways.removePump,
  pondAddPumpState: state.ponds.addPump,
  pondRemovePumpState: state.ponds.removePump,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      refreshPondConnect: refreshPond,

      addPumpToPondConnect: addPumpToPond,
      addPumpToGatewayConnect: addPumpToGateway,

      removePumpFromPondConnect: removePumpFromPond,
      removePumpFromGatewayConnect: removePumpFromGateway,

      synchronizeGatewayConnect: synchronizeGateway,
    },
    dispatch,
  );
};

export const PondPumpsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondPumpsList);

import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Descriptions,
  Typography,
  Button,
  Tabs,
  Table,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getCycle, updateCycle, refreshCycle } from '../../duck/modules/cycles';
import { CycleDataForm } from '../cycle/cycleDataForm';
import { UpdateCycleCmd } from '../../model/cmds';
import Modal from 'antd/lib/modal/Modal';
import { CreateSampleForm } from '../samples/createSampleForm';
import { SampleModel } from '../../model/domain';
import { SimpleSampleAggregationModel } from '../../model/simple';
import _ from 'lodash';
import moment from 'moment';
import Column from 'antd/lib/table/Column';
import { getSamples } from '../../duck/modules/samples';
import { SamplesTable } from '../samples/samplesTable';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ cycleId: string }>;

const UnconnectedCycleDetail: React.FC<Props> = ({
  getCycleConnect,
  updateCycleConnect,
  refreshCycleConnect,
  match,
  cycleState,
}) => {
  const { cycleId } = match.params;
  const [addSampleModalVisible, setAddSampleModalVisible] = React.useState(
    false,
  );
  const [triggerReload, setTriggerReload] = useState(false);

  React.useEffect(() => {
    getCycleConnect(cycleId);
  }, []);

  if (cycleState.error) {
    return (
      <Result
        status="error"
        title="Can't load cycle"
        subTitle={cycleState.error.message}
      />
    );
  }

  const renderGeneralDataCard = () => {
    const { cycle, loading } = cycleState;
    return (
      <Card title="General Data" loading={loading}>
        {cycle && (
          <CycleDataForm
            cycle={cycleState.cycle}
            updating={cycleState.updating}
            updateSuccess={cycleState.updateSuccess}
            updateError={cycleState.updateError}
            onUpdate={(cmd: UpdateCycleCmd) => {
              updateCycleConnect(cycleId, cmd);
            }}
          />
        )}
      </Card>
    );
  };

  const renderHierarchyInformationCard = () => {
    const { cycle, loading } = cycleState;
    return (
      <Card title={'Hierarchy'} loading={loading}>
        {cycle && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Organisation">
              <Link to={`/organisations/${cycle.organisation.id}`}>
                {cycle.organisation.name || cycle.organisation.id}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Farm">
              <Link to={`/farms/${cycle.farm.id}`}>
                {cycle.farm.name || cycle.farm.id}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Pond">
              <Link to={`/ponds/${cycle.pond.id}`}>
                {cycle.pond.name || cycle.pond.id}
              </Link>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    );
  };

  const renderSamplesCard = () => {
    return (
      <Card
        title={'Samples'}
        extra={
          <Button
            size="small"
            onClick={() => setAddSampleModalVisible(true)}
            type="primary"
          >
            Add Sample
          </Button>
        }
      >
        <SamplesTable
          reload={triggerReload}
          onReloaded={() => setTriggerReload(false)}
          onDeleted={() => {
            refreshCycleConnect(cycleId);
            setTriggerReload(true);
          }}
          cycleId={cycleId}
        />
      </Card>
    );
  };

  const renderAddSampleModal = () => {
    const { cycle } = cycleState;
    return (
      <Modal
        visible={addSampleModalVisible}
        onOk={() => setAddSampleModalVisible(false)}
        onCancel={() => setAddSampleModalVisible(false)}
        footer={null}
        title={'Add sample'}
      >
        {cycle && (
          <CreateSampleForm
            pondId={cycle.pond.id}
            cycleId={cycle.id}
            onCreated={(_sample: SampleModel) => {
              setAddSampleModalVisible(false);
              refreshCycleConnect(cycleId);
              setTriggerReload(true);
            }}
            onError={(_error: Error) => {}}
          />
        )}
      </Modal>
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderGeneralDataCard()}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {renderHierarchyInformationCard()}
          </Col>

          <Col span={24}>{renderSamplesCard()}</Col>
        </Row>
        {renderAddSampleModal()}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  cycleState: state.cycles.detail,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCycleConnect: getCycle,
      updateCycleConnect: updateCycle,
      refreshCycleConnect: refreshCycle,
    },
    dispatch,
  );
};

export const CycleDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCycleDetail);

import { Button, Form, notification, Select, Space } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { GatewayModel, OrganisationModel } from '../../model/domain';
import { INT_MAX } from '../../constants';
import { getGateways } from '../../duck/modules/gateways';
import { addGatewayToOrganisation } from '../../duck/modules/organisations';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onAdded?: (_: OrganisationModel) => void;
    onError?: (error: Error) => void;
    organisationId: string;
  };

const UnconnectedAddGatewayToOrganisationForm: React.FC<Props> = ({
  getGatewaysConnect,
  addGatewayToOrganisationConnect,
  organisationId,
  gatewaysListState,
  gatewayAddState,
  gatewayRemoveState,
  onAdded,
  onError,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [gatewayRemoveState.organisation]);

  const fetchData = () => {
    getGatewaysConnect({
      limit: INT_MAX,
      offset: 0,
      organisationIds: 'null',
    });
  };

  React.useEffect(() => {
    if (gatewayAddState.organisation) {
      notification.success({
        message: 'Gateway added',
      });
      if (onAdded) {
        onAdded(gatewayAddState.organisation);
      }
      fetchData();
    }
  }, [gatewayAddState.organisation]);

  React.useEffect(() => {
    if (gatewayAddState.error) {
      notification.error({
        message: 'Error while adding Gateway',
        description: gatewayAddState.error?.message,
      });
      if (onError) {
        onError(gatewayAddState.error);
      }
    }
  }, [gatewayAddState.error]);

  const onSubmit = (values: Store) => {
    const { gatewayId } = values;
    form.resetFields();
    addGatewayToOrganisationConnect(organisationId, gatewayId);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item label="Gateway" name="gatewayId" rules={[{ required: true }]}>
        <Select
          placeholder="Select gateway"
          allowClear
          showSearch
          loading={gatewaysListState.loading}
        >
          {gatewaysListState.gateways.map((gateway: GatewayModel) => (
            <Option key={gateway.id} value={gateway.id}>
              {gateway.name?.actual || gateway.id}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={gatewayAddState.loading}
          >
            Add Gateway
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  gatewaysListState: state.gateways.list,
  gatewayAddState: state.organisations.addGateway,
  gatewayRemoveState: state.organisations.removeGateway,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getGatewaysConnect: getGateways,
      addGatewayToOrganisationConnect: addGatewayToOrganisation,
    },
    dispatch,
  );
};

export const AddGatewayToOrganisationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAddGatewayToOrganisationForm);

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateFarmCmd, UpdateFarmCmd } from '../model/cmds';
import { SimpleFarmModel, FarmModel } from '../model/domain';
import { CountFarmsModel, ListFarmsQuery, ListFarmsQueryModel } from '../model/querys';

export class FarmApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createFarm = this.createFarm.bind(this);
    this.fetchFarm = this.fetchFarm.bind(this);
    this.listFarms = this.listFarms.bind(this);
    this.updateFarm = this.updateFarm.bind(this);
    this.countFarms = this.countFarms.bind(this);
  }

  public createFarm(cmd: CreateFarmCmd): Promise<FarmModel> {
    return this.post<FarmModel, CreateFarmCmd, AxiosResponse<FarmModel>>(
      '/v1/farms',
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchFarm(farmId: string): Promise<FarmModel> {
    return this.get<FarmModel, AxiosResponse<FarmModel>>(
      `/v1/farms/${farmId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listFarms(cmd?: ListFarmsQuery): Promise<ListFarmsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<ListFarmsQueryModel, AxiosResponse<ListFarmsQueryModel>>(
      '/v1/farms',
      config,
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateFarm(farmId: string, cmd: UpdateFarmCmd): Promise<FarmModel> {
    return this.patch<FarmModel, UpdateFarmCmd, AxiosResponse<FarmModel>>(
      `/v1/farms/${farmId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public countFarms(): Promise<CountFarmsModel> {
    const config = getConfig();
    return this.get<
      CountFarmsModel,
      AxiosResponse<CountFarmsModel>
    >('/v1/count/farms', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

}

export const farmApi = new FarmApi();

import { Rule } from 'antd/lib/form';

export const onlyNonWhitespaceRegex = /^\S*$/;
export const emailRegex = /^[a-z0-9A-Z].+@[^\.].*\.[a-z]{2,}$/;
export const passwordRegex = /(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9@$!%*?&]+$/;
export const usernameRegex = /[a-zA-Z0-9._-]+/;
export const phoneNumberRegex = /^[+]{6,14}[0-9]/;

export const noWhitespacesRule: Rule = {
  type: 'string',
  message: 'No whitespaces allowed!',
  pattern: onlyNonWhitespaceRegex,
};

export const emailRule: Rule = {
  type: 'email',
  pattern: emailRegex,
  message: 'Input a valid email!',
  whitespace: true,
};

export const nameRule: Rule = {
  type: 'string',
  message: 'Input a valid name!',
  whitespace: true,
};

export const usernameRule: Rule = {
  type: 'string',
  pattern: usernameRegex,
  min: 3,
  max: 50,
  message:
    'The user name must be at least three characters long and must not contain any special characters other than underscores, dashes or dots!',
};

export const passwordRule: Rule = {
  type: 'string',
  min: 8,
  max: 64,
  pattern: passwordRegex,
  message:
    'Passwords must be at least eight characters long and contain letters and numbers → REGEX: (?=.*[a-z])(?=.*[0-9])[A-Za-z0-9@$!%*?&]+$',
};

export const phoneNumberRule: Rule = {
  type: 'string',
  min: 6,
  max: 14,
  pattern: phoneNumberRegex,
  message:
    "Telephone numbers must begin with '+' and have a length between 6 and 14 characters. ",
};

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateAnnouncementCmd, UpdateAnnouncementCmd } from '../model/cmds';
import { AnnouncementModel } from '../model/domain';
import {
  ListAnnouncementsQuery,
  ListAnnouncementsQueryModel,
} from '../model/querys';

export class AnnouncementApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createAnnouncement = this.createAnnouncement.bind(this);
    this.getAnnouncement = this.getAnnouncement.bind(this);
    this.listAnnouncements = this.listAnnouncements.bind(this);
    this.updateAnnouncement = this.updateAnnouncement.bind(this);
    this.deleteAnnouncement = this.deleteAnnouncement.bind(this);
  }

  public createAnnouncement(
    cmd: CreateAnnouncementCmd,
  ): Promise<AnnouncementModel> {
    return this.post<
      AnnouncementModel,
      CreateAnnouncementCmd,
      AxiosResponse<AnnouncementModel>
    >(`/v1/announcements`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getAnnouncement(announcementId: string): Promise<AnnouncementModel> {
    return this.get<AnnouncementModel, AxiosResponse<AnnouncementModel>>(
      `/v1/announcements/${announcementId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getLatestAnnouncement(): Promise<AnnouncementModel> {
    return this.get<AnnouncementModel, AxiosResponse<AnnouncementModel>>(
      `/v1/latestAnnouncement`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listAnnouncements(
    cmd?: ListAnnouncementsQuery,
  ): Promise<ListAnnouncementsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListAnnouncementsQueryModel,
      AxiosResponse<ListAnnouncementsQueryModel>
    >('/v1/announcements', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateAnnouncement(
    announcementId: string,
    cmd: UpdateAnnouncementCmd,
  ): Promise<AnnouncementModel> {
    return this.patch<
      AnnouncementModel,
      UpdateAnnouncementCmd,
      AxiosResponse<AnnouncementModel>
    >(`/v1/announcements/${announcementId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteAnnouncement(
    announcementId: string,
  ): Promise<AnnouncementModel> {
    return this.delete<AnnouncementModel, AxiosResponse<AnnouncementModel>>(
      `/v1/announcements/${announcementId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const announcementApi = new AnnouncementApi();

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreatePumpCmd, UpdatePumpCmd } from '../model/cmds';
import { PumpModel } from '../model/domain';
import { ListPumpsQuery, ListPumpsQueryModel } from '../model/querys';

export class PumpApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createPump = this.createPump.bind(this);
    this.getPump = this.getPump.bind(this);
    this.listPumps = this.listPumps.bind(this);
    this.updatePump = this.updatePump.bind(this);
  }

  public createPump(cmd: CreatePumpCmd): Promise<PumpModel> {
    return this.post<PumpModel, CreatePumpCmd, AxiosResponse<PumpModel>>(
      `/v1/pumps`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getPump(pumpId: string): Promise<PumpModel> {
    return this.get<PumpModel, AxiosResponse<PumpModel>>(
      `/v1/pumps/${pumpId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listPumps(cmd?: ListPumpsQuery): Promise<ListPumpsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<ListPumpsQueryModel, AxiosResponse<ListPumpsQueryModel>>(
      '/v1/pumps',
      config,
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updatePump(pumpId: string, cmd: UpdatePumpCmd): Promise<PumpModel> {
    return this.patch<PumpModel, UpdatePumpCmd, AxiosResponse<PumpModel>>(
      `/v1/pumps/${pumpId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const pumpApi = new PumpApi();

import { ACTIVATE_ACCOUNT, ACTIVATE_ACCOUNT_FAILED, ACTIVATE_ACCOUNT_SUCCESS, ACTIVATE_FARM, ACTIVATE_FARM_FAILED, ACTIVATE_FARM_SUCCESS, ACTIVATE_ORGANISATION, ACTIVATE_ORGANISATION_FAILED, ACTIVATE_ORGANISATION_SUCCESS, ACTIVATE_POND, ACTIVATE_POND_FAILED, ACTIVATE_POND_SUCCESS, ACTIVATE_SCORING_ACCOUNT, ACTIVATE_SCORING_ACCOUNT_FAILED, ACTIVATE_SCORING_ACCOUNT_SUCCESS, ACTIVATE_VIP_ACCOUNT, ACTIVATE_VIP_ACCOUNT_FAILED, ACTIVATE_VIP_ACCOUNT_SUCCESS, DEACTIVATE_ACCOUNT, DEACTIVATE_ACCOUNT_FAILED, DEACTIVATE_ACCOUNT_SUCCESS, DEACTIVATE_FARM, DEACTIVATE_FARM_FAILED, DEACTIVATE_FARM_SUCCESS, DEACTIVATE_ORGANISATION, DEACTIVATE_ORGANISATION_FAILED, DEACTIVATE_ORGANISATION_SUCCESS, DEACTIVATE_POND, DEACTIVATE_POND_FAILED, DEACTIVATE_POND_SUCCESS, DEACTIVATE_SCORING_ACCOUNT, DEACTIVATE_SCORING_ACCOUNT_FAILED, DEACTIVATE_SCORING_ACCOUNT_SUCCESS, DEACTIVATE_VIP_ACCOUNT, DEACTIVATE_VIP_ACCOUNT_FAILED, DEACTIVATE_VIP_ACCOUNT_SUCCESS } from "../../constants";
import { AccountModel, FarmModel, OrganisationModel, PondModel } from "../../model/domain";


// ORGANISATIONS

export interface ActivateOrganisationAction {
    type: ACTIVATE_ORGANISATION;
    payload: string;
}

export interface ActivateOrganisationSuccessAction {
    type: ACTIVATE_ORGANISATION_SUCCESS;
    payload: OrganisationModel;
}

export interface ActivateOrganisationFailedAction {
    type: ACTIVATE_ORGANISATION_FAILED;
    payload: Error;
}

export type OrganisationActivationAction =
    | ActivateOrganisationAction
    | ActivateOrganisationSuccessAction
    | ActivateOrganisationFailedAction;


export function organisationActivate(id: string): ActivateOrganisationAction {
    return {
        type: ACTIVATE_ORGANISATION,
        payload: id,
    };
}

export function organisationActivated(organisation: OrganisationModel): ActivateOrganisationSuccessAction {
    return {
        type: ACTIVATE_ORGANISATION_SUCCESS,
        payload: organisation,
    };
}

export function organisationActivateFailed(error: Error): ActivateOrganisationFailedAction {
    return {
        type: ACTIVATE_ORGANISATION_FAILED,
        payload: error,
    };
}


export interface DeactivateOrganisationAction {
    type: DEACTIVATE_ORGANISATION;
    payload: string,
}

export interface DeactivateOrganisationSuccessAction {
    type: DEACTIVATE_ORGANISATION_SUCCESS;
    payload: OrganisationModel;
}

export interface DeactivateOrganisationFailedAction {
    type: DEACTIVATE_ORGANISATION_FAILED;
    payload: Error;
}

export type OrganisationDeactivationAction =
    | DeactivateOrganisationAction
    | DeactivateOrganisationSuccessAction
    | DeactivateOrganisationFailedAction;

export function organisationDeactivate(id: string): DeactivateOrganisationAction {
    return {
        type: DEACTIVATE_ORGANISATION,
        payload: id,
    };
}

export function organisationDectivated(organisation: OrganisationModel): DeactivateOrganisationSuccessAction {
    return {
        type: DEACTIVATE_ORGANISATION_SUCCESS,
        payload: organisation,
    };
}

export function organisationDeactivateFailed(error: Error): DeactivateOrganisationFailedAction {
    return {
        type: DEACTIVATE_ORGANISATION_FAILED,
        payload: error,
    };
}


// FARMs


export interface ActivateFarmAction {
    type: ACTIVATE_FARM;
    payload: string;
}

export interface ActivateFarmSuccessAction {
    type: ACTIVATE_FARM_SUCCESS;
    payload: FarmModel;
}

export interface ActivateFarmFailedAction {
    type: ACTIVATE_FARM_FAILED;
    payload: Error;
}

export type FarmActivationAction =
    | ActivateFarmAction
    | ActivateFarmSuccessAction
    | ActivateFarmFailedAction;


export function farmActivate(id: string): ActivateFarmAction {
    return {
        type: ACTIVATE_FARM,
        payload: id,
    };
}

export function farmActivated(farm: FarmModel): ActivateFarmSuccessAction {
    return {
        type: ACTIVATE_FARM_SUCCESS,
        payload: farm,
    };
}

export function farmActivateFailed(error: Error): ActivateFarmFailedAction {
    return {
        type: ACTIVATE_FARM_FAILED,
        payload: error,
    };
}


export interface DeactivateFarmAction {
    type: DEACTIVATE_FARM;
    payload: string,
}

export interface DeactivateFarmSuccessAction {
    type: DEACTIVATE_FARM_SUCCESS;
    payload: FarmModel;
}

export interface DeactivateFarmFailedAction {
    type: DEACTIVATE_FARM_FAILED;
    payload: Error;
}

export type FarmDeactivationAction =
    | DeactivateFarmAction
    | DeactivateFarmSuccessAction
    | DeactivateFarmFailedAction;

export function farmDeactivate(id: string): DeactivateFarmAction {
    return {
        type: DEACTIVATE_FARM,
        payload: id,
    };
}

export function farmDectivated(farm: FarmModel): DeactivateFarmSuccessAction {
    return {
        type: DEACTIVATE_FARM_SUCCESS,
        payload: farm,
    };
}

export function farmDeactivateFailed(error: Error): DeactivateFarmFailedAction {
    return {
        type: DEACTIVATE_FARM_FAILED,
        payload: error,
    };
}


// PONDs


export interface ActivatePondAction {
    type: ACTIVATE_POND;
    payload: string;
}

export interface ActivatePondSuccessAction {
    type: ACTIVATE_POND_SUCCESS;
    payload: PondModel;
}

export interface ActivatePondFailedAction {
    type: ACTIVATE_POND_FAILED;
    payload: Error;
}

export type PondActivationAction =
    | ActivatePondAction
    | ActivatePondSuccessAction
    | ActivatePondFailedAction;


export function pondActivate(id: string): ActivatePondAction {
    return {
        type: ACTIVATE_POND,
        payload: id,
    };
}

export function pondActivated(pond: PondModel): ActivatePondSuccessAction {
    return {
        type: ACTIVATE_POND_SUCCESS,
        payload: pond,
    };
}

export function pondActivateFailed(error: Error): ActivatePondFailedAction {
    return {
        type: ACTIVATE_POND_FAILED,
        payload: error,
    };
}


export interface DeactivatePondAction {
    type: DEACTIVATE_POND;
    payload: string,
}

export interface DeactivatePondSuccessAction {
    type: DEACTIVATE_POND_SUCCESS;
    payload: PondModel;
}

export interface DeactivatePondFailedAction {
    type: DEACTIVATE_POND_FAILED;
    payload: Error;
}

export type PondDeactivationAction =
    | DeactivatePondAction
    | DeactivatePondSuccessAction
    | DeactivatePondFailedAction;

export function pondDeactivate(id: string): DeactivatePondAction {
    return {
        type: DEACTIVATE_POND,
        payload: id,
    };
}

export function pondDectivated(pond: PondModel): DeactivatePondSuccessAction {
    return {
        type: DEACTIVATE_POND_SUCCESS,
        payload: pond,
    };
}

export function pondDeactivateFailed(error: Error): DeactivatePondFailedAction {
    return {
        type: DEACTIVATE_POND_FAILED,
        payload: error,
    };
}

// ACCOUNTs


export interface ActivateAccountAction {
    type: ACTIVATE_ACCOUNT;
    payload: string;
}

export interface ActivateAccountSuccessAction {
    type: ACTIVATE_ACCOUNT_SUCCESS;
    payload: AccountModel;
}

export interface ActivateAccountFailedAction {
    type: ACTIVATE_ACCOUNT_FAILED;
    payload: Error;
}

export type AccountActivationAction =
    | ActivateAccountAction
    | ActivateAccountSuccessAction
    | ActivateAccountFailedAction;


export function accountActivate(id: string): ActivateAccountAction {
    return {
        type: ACTIVATE_ACCOUNT,
        payload: id,
    };
}

export function accountActivated(account: AccountModel): ActivateAccountSuccessAction {
    return {
        type: ACTIVATE_ACCOUNT_SUCCESS,
        payload: account,
    };
}

export function accountActivateFailed(error: Error): ActivateAccountFailedAction {
    return {
        type: ACTIVATE_ACCOUNT_FAILED,
        payload: error,
    };
}


export interface DeactivateAccountAction {
    type: DEACTIVATE_ACCOUNT;
    payload: string,
}

export interface DeactivateAccountSuccessAction {
    type: DEACTIVATE_ACCOUNT_SUCCESS;
    payload: AccountModel;
}

export interface DeactivateAccountFailedAction {
    type: DEACTIVATE_ACCOUNT_FAILED;
    payload: Error;
}

export type AccountDeactivationAction =
    | DeactivateAccountAction
    | DeactivateAccountSuccessAction
    | DeactivateAccountFailedAction;

export function accountDeactivate(id: string): DeactivateAccountAction {
    return {
        type: DEACTIVATE_ACCOUNT,
        payload: id,
    };
}

export function accountDectivated(account: AccountModel): DeactivateAccountSuccessAction {
    return {
        type: DEACTIVATE_ACCOUNT_SUCCESS,
        payload: account,
    };
}

export function accountDeactivateFailed(error: Error): DeactivateAccountFailedAction {
    return {
        type: DEACTIVATE_ACCOUNT_FAILED,
        payload: error,
    };
}

export interface ActivateVipAccountAction {
    type: ACTIVATE_VIP_ACCOUNT;
    payload: string;
}

export interface ActivateVipAccountSuccessAction {
    type: ACTIVATE_VIP_ACCOUNT_SUCCESS;
    payload: AccountModel;
}

export interface ActivateVipAccountFailedAction {
    type: ACTIVATE_VIP_ACCOUNT_FAILED;
    payload: Error;
}

export type VipAccountActivationAction =
    | ActivateVipAccountAction
    | ActivateVipAccountSuccessAction
    | ActivateVipAccountFailedAction;


export function vipAccountActivate(id: string): ActivateVipAccountAction {
    return {
        type: ACTIVATE_VIP_ACCOUNT,
        payload: id,
    };
}

export function vipAccountActivated(account: AccountModel): ActivateVipAccountSuccessAction {
    return {
        type: ACTIVATE_VIP_ACCOUNT_SUCCESS,
        payload: account,
    };
}

export function vipAccountActivateFailed(error: Error): ActivateVipAccountFailedAction {
    return {
        type: ACTIVATE_VIP_ACCOUNT_FAILED,
        payload: error,
    };
}


export interface DeactivateVipAccountAction {
    type: DEACTIVATE_VIP_ACCOUNT;
    payload: string,
}

export interface DeactivateVipAccountSuccessAction {
    type: DEACTIVATE_VIP_ACCOUNT_SUCCESS;
    payload: AccountModel;
}

export interface DeactivateVipAccountFailedAction {
    type: DEACTIVATE_VIP_ACCOUNT_FAILED;
    payload: Error;
}

export type VipAccountDeactivationAction =
    | DeactivateVipAccountAction
    | DeactivateVipAccountSuccessAction
    | DeactivateVipAccountFailedAction;

export function vipAccountDeactivate(id: string): DeactivateVipAccountAction {
    return {
        type: DEACTIVATE_VIP_ACCOUNT,
        payload: id,
    };
}

export function vipAccountDectivated(account: AccountModel): DeactivateVipAccountSuccessAction {
    return {
        type: DEACTIVATE_VIP_ACCOUNT_SUCCESS,
        payload: account,
    };
}

export function vipAccountDeactivateFailed(error: Error): DeactivateVipAccountFailedAction {
    return {
        type: DEACTIVATE_VIP_ACCOUNT_FAILED,
        payload: error,
    };
}



export interface ActivateScoringAccountAction {
    type: ACTIVATE_SCORING_ACCOUNT;
    payload: string;
}

export interface ActivateScoringAccountSuccessAction {
    type: ACTIVATE_SCORING_ACCOUNT_SUCCESS;
    payload: AccountModel;
}

export interface ActivateScoringAccountFailedAction {
    type: ACTIVATE_SCORING_ACCOUNT_FAILED;
    payload: Error;
}

export type ScoringAccountActivationAction =
    | ActivateScoringAccountAction
    | ActivateScoringAccountSuccessAction
    | ActivateScoringAccountFailedAction;


export function scoringAccountActivate(id: string): ActivateScoringAccountAction {
    return {
        type: ACTIVATE_SCORING_ACCOUNT,
        payload: id,
    };
}

export function scoringAccountActivated(account: AccountModel): ActivateScoringAccountSuccessAction {
    return {
        type: ACTIVATE_SCORING_ACCOUNT_SUCCESS,
        payload: account,
    };
}

export function scoringAccountActivateFailed(error: Error): ActivateScoringAccountFailedAction {
    return {
        type: ACTIVATE_SCORING_ACCOUNT_FAILED,
        payload: error,
    };
}


export interface DeactivateScoringAccountAction {
    type: DEACTIVATE_SCORING_ACCOUNT;
    payload: string,
}

export interface DeactivateScoringAccountSuccessAction {
    type: DEACTIVATE_SCORING_ACCOUNT_SUCCESS;
    payload: AccountModel;
}

export interface DeactivateScoringAccountFailedAction {
    type: DEACTIVATE_SCORING_ACCOUNT_FAILED;
    payload: Error;
}

export type ScoringAccountDeactivationAction =
    | DeactivateScoringAccountAction
    | DeactivateScoringAccountSuccessAction
    | DeactivateScoringAccountFailedAction;

export function scoringAccountDeactivate(id: string): DeactivateScoringAccountAction {
    return {
        type: DEACTIVATE_SCORING_ACCOUNT,
        payload: id,
    };
}

export function scoringAccountDectivated(account: AccountModel): DeactivateScoringAccountSuccessAction {
    return {
        type: DEACTIVATE_SCORING_ACCOUNT_SUCCESS,
        payload: account,
    };
}

export function scoringAccountDeactivateFailed(error: Error): DeactivateScoringAccountFailedAction {
    return {
        type: DEACTIVATE_SCORING_ACCOUNT_FAILED,
        payload: error,
    };
}


export type ActivationAction = OrganisationActivationAction | FarmActivationAction | PondActivationAction | AccountActivationAction | VipAccountActivationAction | ScoringAccountActivationAction;
export type DeactivationAction = OrganisationDeactivationAction | FarmDeactivationAction | PondDeactivationAction | AccountDeactivationAction | VipAccountDeactivationAction | ScoringAccountDeactivationAction;

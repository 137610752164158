import { ThunkDispatch as Dispatch } from 'redux-thunk';
import {
  FETCH_SENSOR_OPTIONS_SUCCESS,
  FETCH_SENSOR_OPTIONS,
  FETCH_SENSOR_OPTIONS_FAILED,
  FETCH_SMART_SENSOR_OPTIONS,
  FETCH_SMART_SENSOR_OPTIONS_SUCCESS,
  FETCH_SMART_SENSOR_OPTIONS_FAILED,
  FETCH_MANUFACTURER_OPTIONS,
  FETCH_MANUFACTURER_OPTIONS_SUCCESS,
  FETCH_MANUFACTURER_OPTIONS_FAILED,
  FETCH_POND_OPTIONS,
  FETCH_POND_OPTIONS_SUCCESS,
  FETCH_POND_OPTIONS_FAILED,
  FETCH_ORGANISATION_OPTIONS,
  FETCH_ORGANISATION_OPTIONS_SUCCESS,
  FETCH_ORGANISATION_OPTIONS_FAILED,
  FETCH_SENSOR_CAP_OPTIONS,
  FETCH_SENSOR_CAP_OPTIONS_SUCCESS,
  FETCH_SENSOR_CAP_OPTIONS_FAILED,
  FETCH_GATEWAY_OPTIONS,
  FETCH_GATEWAY_OPTIONS_SUCCESS,
  FETCH_GATEWAY_OPTIONS_FAILED,
} from '../../constants';
import {
  ListGatewaysQuery,
  ListManufacturersQuery,
  ListOrganisationsQuery,
  ListPondsQuery,
  ListSensorCapsQuery,
  ListSensorsQuery,
  ListSmartSensorsQuery,
} from '../../model/querys';
import { OptionsState } from '../../model/state';

import {
  gatewayOptionsFetch,
  gatewayOptionsFetched,
  gatewayOptionsFetchFailed,
  manufacturerOptionsFetch,
  manufacturerOptionsFetched,
  manufacturerOptionsFetchFailed,
  OptionsAction,
  organisationOptionsFetch,
  organisationOptionsFetched,
  organisationOptionsFetchFailed,
  pondOptionsFetch,
  pondOptionsFetched,
  pondOptionsFetchFailed,
  sensorCapOptionsFetch,
  sensorCapOptionsFetched,
  sensorCapOptionsFetchFailed,
  sensorOptionsFetch,
  sensorOptionsFetched,
  sensorOptionsFetchFailed,
  smartSensorOptionsFetch,
  smartSensorOptionsFetched,
  smartSensorOptionsFetchFailed,
} from '../actions/option';
import { sensingApi } from '../../api/sensingApi';
import { smartSensorApi } from '../../api/smartSensorApi';
import { manufacturerApi } from '../../api/manufacturerApi';
import { pondApi } from '../../api/pondApi';
import { organisationApi } from '../../api/organisationApi';
import { sensorCapApi } from '../../api/sensorCapApi';
import { SensorType } from '../../model/enums';
import { gatewayApi } from '../../api/gatewayApi';

export function getSensorOptions(
  query: ListSensorsQuery,
  filterTypes?: SensorType[],
) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(sensorOptionsFetch(query));
      const queryModel = await sensingApi.listSensors(query);

      if (filterTypes) {
        queryModel.sensors = queryModel.sensors.filter((s) =>
          filterTypes.includes(s.type),
        );
      }

      dispatch(sensorOptionsFetched(queryModel));
    } catch (e) {
      dispatch(sensorOptionsFetchFailed(e));
    }
  };
}

export function getSmartSensorOptions(query: ListSmartSensorsQuery) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(smartSensorOptionsFetch(query));
      const queryModel = await smartSensorApi.listSmartSensors(query);
      dispatch(smartSensorOptionsFetched(queryModel));
    } catch (e) {
      dispatch(smartSensorOptionsFetchFailed(e));
    }
  };
}

export function getManufacturerOptions(query: ListManufacturersQuery) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(manufacturerOptionsFetch(query));
      const queryModel = await manufacturerApi.listManufacturers(query);
      dispatch(manufacturerOptionsFetched(queryModel));
    } catch (e) {
      dispatch(manufacturerOptionsFetchFailed(e));
    }
  };
}

export function getPondOptions(query: ListPondsQuery) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(pondOptionsFetch(query));
      const queryModel = await pondApi.listPonds(query);
      dispatch(pondOptionsFetched(queryModel));
    } catch (e) {
      dispatch(pondOptionsFetchFailed(e));
    }
  };
}

export function getOrganisationOptions(query: ListOrganisationsQuery) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(organisationOptionsFetch(query));
      const queryModel = await organisationApi.listOrganisations(query);
      dispatch(organisationOptionsFetched(queryModel));
    } catch (e) {
      dispatch(organisationOptionsFetchFailed(e));
    }
  };
}

export function getSensorCapOptions(query: ListSensorCapsQuery) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(sensorCapOptionsFetch(query));
      const queryModel = await sensorCapApi.listSensorCaps(query);
      dispatch(sensorCapOptionsFetched(queryModel));
    } catch (e) {
      dispatch(sensorCapOptionsFetchFailed(e));
    }
  };
}

export function getGatewayOptions(query: ListGatewaysQuery) {
  return async (dispatch: Dispatch<OptionsAction, {}, any>) => {
    try {
      dispatch(gatewayOptionsFetch(query));
      const queryModel = await gatewayApi.listGateways(query);
      dispatch(gatewayOptionsFetched(queryModel));
    } catch (e) {
      dispatch(gatewayOptionsFetchFailed(e));
    }
  };
}

export default function optionsReducer(
  state: OptionsState = {
    sensorOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    smartSensorOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    manufacturerOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    organisationOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    pondOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    sensorCapOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
    gatewayOptions: {
      loading: false,
      error: null,
      query: null,
      model: null,
    },
  },
  action: OptionsAction,
): OptionsState {
  switch (action.type) {
    case FETCH_SENSOR_OPTIONS:
      return {
        ...state,
        sensorOptions: {
          ...state.sensorOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_SENSOR_OPTIONS_SUCCESS:
      return {
        ...state,
        sensorOptions: {
          ...state.sensorOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };

    case FETCH_SENSOR_OPTIONS_FAILED:
      return {
        ...state,
        sensorOptions: {
          ...state.sensorOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    case FETCH_SMART_SENSOR_OPTIONS:
      return {
        ...state,
        smartSensorOptions: {
          ...state.smartSensorOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_SMART_SENSOR_OPTIONS_SUCCESS:
      return {
        ...state,
        smartSensorOptions: {
          ...state.smartSensorOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };

    case FETCH_SMART_SENSOR_OPTIONS_FAILED:
      return {
        ...state,
        smartSensorOptions: {
          ...state.smartSensorOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    case FETCH_MANUFACTURER_OPTIONS:
      return {
        ...state,
        manufacturerOptions: {
          ...state.manufacturerOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_MANUFACTURER_OPTIONS_SUCCESS:
      return {
        ...state,
        manufacturerOptions: {
          ...state.manufacturerOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };

    case FETCH_MANUFACTURER_OPTIONS_FAILED:
      return {
        ...state,
        manufacturerOptions: {
          ...state.manufacturerOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    case FETCH_POND_OPTIONS:
      return {
        ...state,
        pondOptions: {
          ...state.pondOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_POND_OPTIONS_SUCCESS:
      return {
        ...state,
        pondOptions: {
          ...state.pondOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };

    case FETCH_POND_OPTIONS_FAILED:
      return {
        ...state,
        pondOptions: {
          ...state.pondOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    case FETCH_ORGANISATION_OPTIONS:
      return {
        ...state,
        organisationOptions: {
          ...state.organisationOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_ORGANISATION_OPTIONS_SUCCESS:
      return {
        ...state,
        organisationOptions: {
          ...state.organisationOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };

    case FETCH_ORGANISATION_OPTIONS_FAILED:
      return {
        ...state,
        organisationOptions: {
          ...state.organisationOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    case FETCH_SENSOR_CAP_OPTIONS:
      return {
        ...state,
        sensorCapOptions: {
          ...state.sensorCapOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_SENSOR_CAP_OPTIONS_SUCCESS:
      return {
        ...state,
        sensorCapOptions: {
          ...state.sensorCapOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };

    case FETCH_SENSOR_CAP_OPTIONS_FAILED:
      return {
        ...state,
        sensorCapOptions: {
          ...state.sensorCapOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    case FETCH_GATEWAY_OPTIONS:
      return {
        ...state,
        gatewayOptions: {
          ...state.gatewayOptions,
          loading: true,
          error: null,
          query: action.payload.query,
          model: null,
        },
      };

    case FETCH_GATEWAY_OPTIONS_SUCCESS:
      return {
        ...state,
        gatewayOptions: {
          ...state.gatewayOptions,
          loading: false,
          error: null,
          model: action.payload.model,
        },
      };
    case FETCH_GATEWAY_OPTIONS_FAILED:
      return {
        ...state,
        gatewayOptions: {
          ...state.gatewayOptions,
          loading: false,
          error: action.payload.error,
          model: null,
        },
      };

    default:
      return state;
  }
}

import { Button, Form, notification, Space, Typography, Upload } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { SensorCapModel } from '../../model/domain';
import { importSensorCaps } from '../../duck/modules/sensorCaps';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import _ from 'lodash';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onImported?: (sensorCaps: SensorCapModel[]) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedImportSensorCapsForm: React.FC<Props> = ({
  sensorCapsImportState,
  onImported,
  onError,
  importSensorCapsConnected,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([] as RcFile[]);

  React.useEffect(() => {
    if (sensorCapsImportState.sensorCaps.length > 0) {
      notification.success({
        message: 'Sensor cap created',
      });
      onReset();
      setFileList([]);
      if (onImported != null) {
        onImported(sensorCapsImportState.sensorCaps);
      }
    }
  }, [sensorCapsImportState.sensorCaps]);

  React.useEffect(() => {
    if (sensorCapsImportState.error) {
      notification.error({
        message: 'Sensor cap creation error',
        description: sensorCapsImportState.error?.message,
      });
      if (onError != null) {
        onError(sensorCapsImportState.error);
      }
    }
  }, [sensorCapsImportState.error]);

  const onReset = () => {
    form.resetFields();
  };

  const onSubmit = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append(`files`, file);
    });
    importSensorCapsConnected(formData);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.files;
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={{}}>
      <Form.Item>
        <Form.Item name="dragger" getValueFromEvent={normFile} noStyle>
          <Upload.Dragger
            multiple
            name="files"
            onRemove={(file) => {
              const index = fileList.indexOf(file as RcFile);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            beforeUpload={(file, fileList) => {
              setFileList(_.sortedUniq(_.union(fileList, [file])));
              return false;
            }}
            fileList={fileList}
            accept=".xlsx"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag files to this area to import
            </p>
            <p className="ant-upload-hint">Only XLSX files!</p>
            <p className="ant-upload-hint">
              Filename will be used as serial number
            </p>
            <br />
            <p className="ant-upload-hint">Required format:</p>
            <p className="ant-upload-hint">
              <img src="import-example.png" />
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={fileList.length === 0}
            loading={sensorCapsImportState.loading}
            style={{ marginTop: 16 }}
          >
            <Typography> {'Upload and import'}</Typography>
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  sensorCapsImportState: state.sensorCaps.import,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      importSensorCapsConnected: importSensorCaps,
    },
    dispatch,
  );
};

export const ImportSensorCapsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedImportSensorCapsForm);

import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Card,
  Descriptions,
  Table,
  Tag,
  Popconfirm,
  Tooltip,
  Badge,
  notification,
  Empty,
  Layout,
  Button,
  Space,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import {
  deleteAnnouncement,
  getLatestAnnouncement,
  updateAnnouncement,
} from '../../duck/modules/announcements';
import { usePrevious } from '../_util/hook';
import moment from 'moment';
import {
  renderAnnouncementMessage,
  renderAnnouncmentDetailLink,
  renderEndingTooltip,
  renderLastChageTooltip,
  renderStartingTooltip,
  renderStatusBadge,
  renderTypeTag,
} from './announcementUtil';
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    reload: boolean;
    onReloaded: () => void;
    onCreateClick: () => void;
  };

const UnconnectedLatestAnnouncementPartial: React.FC<Props> = ({
  announcement,
  loading,
  error,
  getLatestAnnouncementConnect,
  reload,
  onReloaded,
  onCreateClick,
  updating,
  updateError,
  updateSuccess,
  deleteAnnouncementConnect,
  deleteState,
}) => {
  React.useEffect(() => {
    getLatestAnnouncementConnect();
    onReloaded();
  }, [reload, deleteState]);

  const prev = usePrevious({
    deleteSuccess: deleteState.success,
    deleteError: deleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteState.success) {
      notification.success({
        message: 'Announcement deleted',
      });
    }
  }, [deleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteState.error) {
      notification.error({
        message: 'Announcement deletion error',
        description: deleteState.error?.message,
      });
    }
  }, [deleteState.error]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load latest announcement"
        subTitle={error.message}
      />
    );
  }

  if ((announcement as any) == '') {
    return (
      <Empty description="No valid announcement at the moment!">
        <Button onClick={() => onCreateClick()} type="primary">
          Create Announcement
        </Button>
      </Empty>
    );
  }

  const renderPreviewCardContent = () => {
    if (announcement) {
      return (
        <Descriptions bordered title="Latest Announcement">
          <Descriptions.Item label="ID" span={1}>
            {renderAnnouncmentDetailLink(announcement)}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={1}>
            {renderStatusBadge(announcement)}
          </Descriptions.Item>
          <Descriptions.Item label="Type" span={1}>
            {renderTypeTag(announcement)}
          </Descriptions.Item>

          <Descriptions.Item label="Validity Period" span={2}>
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Start">
                {renderStartingTooltip(announcement)}
              </Descriptions.Item>
              <Descriptions.Item label="End">
                {renderEndingTooltip(announcement)}
              </Descriptions.Item>

              <Descriptions.Item label="Duration">
                {moment
                  .duration(
                    moment(announcement.notShowAfter).diff(
                      announcement.notShowBefore,
                    ),
                  )
                  .humanize()}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label="Last Change">
            {renderLastChageTooltip(announcement)}
          </Descriptions.Item>

          <Descriptions.Item label="Content" span={3}>
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="English">
                {renderAnnouncementMessage(announcement.content.english)}
              </Descriptions.Item>
              <Descriptions.Item label="Thai">
                {renderAnnouncementMessage(announcement.content.thai)}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Actions">
            <Space>
              <Link
                key={announcement.id}
                to={`/announcements/${announcement.id}`}
              >
                <Button type="primary">{'Edit'}</Button>
              </Link>

              <Popconfirm
                title="Do you really want to delete this announcement?"
                onConfirm={() => deleteAnnouncementConnect(announcement.id)}
              >
                <Button loading={deleteState.loading} type="primary" danger>
                  {'Delete'}
                </Button>
              </Popconfirm>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      );
    }
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Card loading={loading}>{renderPreviewCardContent()}</Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  announcement: state.announcements.detail.announcement,
  loading: state.announcements.detail.loading,
  error: state.announcements.detail.error,
  updating: state.announcements.detail.updating,
  updateError: state.announcements.detail.updateError,
  updateSuccess: state.announcements.detail.updateSuccess,
  deleteState: state.announcements.delete,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getLatestAnnouncementConnect: getLatestAnnouncement,
      updateAnnouncementConnect: updateAnnouncement,
      deleteAnnouncementConnect: deleteAnnouncement,
    },
    dispatch,
  );
};

export const LatestAnnouncementPartial = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedLatestAnnouncementPartial);

import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { organisationApi } from '../../api/organisationApi';

import { manufacturerApi } from '../../api/manufacturerApi';
import {
  CREATE_MANUFACTURER,
  CREATE_MANUFACTURER_FAILED,
  CREATE_MANUFACTURER_SUCCESS,
  FETCH_MANUFACTURER,
  FETCH_MANUFACTURER_FAILED,
  FETCH_MANUFACTURER_SUCCESS,
  FETCH_MANUFACTURERS,
  FETCH_MANUFACTURERS_FAILED,
  FETCH_MANUFACTURERS_SUCCESS,
  UPDATE_MANUFACTURER,
  UPDATE_MANUFACTURER_FAILED,
  UPDATE_MANUFACTURER_SUCCESS,
  DELETE_MANUFACTURER,
  DELETE_MANUFACTURER_SUCCESS,
  INT_MAX,
  DELETE_MANUFACTURER_FAILED,
} from '../../constants';
import { CreateManufacturerCmd, UpdateManufacturerCmd } from '../../model/cmds';
import {
  ListManufacturersQuery,
  ListManufacturersQueryModel,
} from '../../model/querys';
import { ManufacturersState } from '../../model/state';
import {
  manufacturerCreate,
  manufacturerCreated,
  manufacturerCreateFailed,
  manufacturerFetch,
  manufacturerFetched,
  manufacturerFetchFailed,
  ManufacturersAction,
  manufacturersFetch,
  manufacturersFetched,
  manufacturersFetchFailed,
  manufacturerUpdate,
  manufacturerUpdated,
  manufacturerUpdateFailed,
  manufacturerDelete,
  manufacturerDeleted,
  manufacturerDeleteFailed,
} from '../actions';

export function createManufacturer(cmd: CreateManufacturerCmd) {
  return async (dispatch: Dispatch<ManufacturersAction, {}, any>) => {
    try {
      dispatch(manufacturerCreate());
      const manufacturer = await manufacturerApi.createManufacturer(cmd);
      dispatch(manufacturerCreated(manufacturer));
    } catch (e) {
      dispatch(manufacturerCreateFailed(e));
    }
  };
}

export function getManufacturer(id: string) {
  return async (dispatch: Dispatch<ManufacturersAction, {}, any>) => {
    try {
      dispatch(manufacturerFetch());
      const manufacturer = await manufacturerApi.getManufacturer(id);
      dispatch(manufacturerFetched(manufacturer));
    } catch (e) {
      dispatch(manufacturerFetchFailed(e));
    }
  };
}

export function getManufacturers(query: ListManufacturersQuery) {
  return async (dispatch: Dispatch<ManufacturersAction, {}, any>) => {
    try {
      dispatch(manufacturersFetch(query));
      const queryModel: ListManufacturersQueryModel = await manufacturerApi.listManufacturers(
        query,
      );
      const manufacturers = queryModel.manufacturers;
      dispatch(manufacturersFetched(queryModel, manufacturers));
    } catch (e) {
      dispatch(manufacturersFetchFailed(e));
    }
  };
}

export function updateManufacturer(id: string, cmd: UpdateManufacturerCmd) {
  return async (dispatch: Dispatch<ManufacturersAction, {}, any>) => {
    try {
      dispatch(manufacturerUpdate());
      const response = await manufacturerApi.updateManufacturer(id, cmd);
      dispatch(manufacturerUpdated(response));
      dispatch(getManufacturer(id));
    } catch (e) {
      dispatch(manufacturerUpdateFailed(e));
    }
  };
}

export function deleteManufacturer(
  manufacturerId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<ManufacturersAction, {}, any>) => {
    try {
      dispatch(manufacturerDelete(manufacturerId));
      await manufacturerApi.deleteManufacturer(manufacturerId);
      const manufacturer = await manufacturerApi.getManufacturer(
        manufacturerId,
      );
      dispatch(manufacturerDeleted(manufacturer));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(manufacturerDeleteFailed(e));
    }
  };
}

export default function manufacturersReducer(
  state: ManufacturersState = {
    create: {
      loading: false,
      error: null,
      manufacturer: null,
    },
    list: {
      loading: false,
      error: null,
      manufacturers: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      manufacturer: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      success: null,
      error: null,
    },
  },
  action: ManufacturersAction,
): ManufacturersState {
  switch (action.type) {
    case CREATE_MANUFACTURER:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          manufacturer: null,
        },
      };
    case CREATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          manufacturer: action.payload,
        },
      };
    case CREATE_MANUFACTURER_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          manufacturer: null,
        },
      };
    case FETCH_MANUFACTURER:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          manufacturer: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_MANUFACTURER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          manufacturer: action.payload,
        },
      };
    case FETCH_MANUFACTURER_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          manufacturer: null,
        },
      };
    case UPDATE_MANUFACTURER:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_MANUFACTURER_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_MANUFACTURERS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          manufacturers: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          manufacturers: action.payload.manufacturers,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_MANUFACTURERS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          manufacturers: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case DELETE_MANUFACTURER:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null,
          success: null,
        },
      };

    case DELETE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          manufacturer: action.payload,
        },
        delete: {
          ...state.delete,
          loading: false,
          error: null,
          success: true,
        },
      };

    case DELETE_MANUFACTURER_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    default:
      return state;
  }
}

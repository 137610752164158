import { ThunkDispatch as Dispatch } from 'redux-thunk';
import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILED,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  INT_MAX,
} from '../../constants';
import { NotificationModel } from '../../model/domain';
import { CreateNotificationCmd } from '../../model/cmds';
import { notificationApi } from '../../api/notificationApi';
import { NotificationsState } from '../../model/state';
import {
  ListNotificationsQuery,
  ListNotificationsQueryModel,
} from '../../model/querys';
import {
  NotificationsAction,
  notificationCreate,
  notificationCreated,
  notificationCreateFailed,
  notificationsFetch,
  notificationsFetched,
  notificationsFetchFailed,
} from '../actions';

export function createNotification(cmd: CreateNotificationCmd) {
  return async (dispatch: Dispatch<NotificationsAction, {}, any>) => {
    try {
      dispatch(notificationCreate());
      const notification = await notificationApi.createNotification(cmd);
      dispatch(notificationCreated(notification));
      // dispatch(getNotifications({limit: INT_MAX, offset: 0}));
    } catch (e) {
      dispatch(notificationCreateFailed(e));
    }
  };
}

export function getNotifications(query: ListNotificationsQuery) {
  return async (dispatch: Dispatch<NotificationsAction, {}, any>) => {
    try {
      dispatch(notificationsFetch(query));
      const response: ListNotificationsQueryModel = await notificationApi.listNotifications(
        query,
      );
      dispatch(notificationsFetched(response));
    } catch (e) {
      dispatch(notificationsFetchFailed(e));
    }
  };
}
export default function notificationsReducer(
  state: NotificationsState = {
    create: {
      loading: false,
      error: null,
      notification: null,
    },
    list: {
      loading: false,
      error: null,
      notifications: [],
      total: null,
      offset: null,
      limit: null,
    },
  },
  action: NotificationsAction,
): NotificationsState {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          notification: null,
        },
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          notification: action.payload,
        },
      };
    case CREATE_NOTIFICATION_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          notification: null,
        },
      };

    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          notifications: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          notifications: action.payload.notifications,
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: action.payload.total,
        },
      };

    case FETCH_NOTIFICATIONS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          notifications: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    default:
      return state;
  }
}

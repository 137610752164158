import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal, Tabs } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { ProductsResponsiveTable } from '../product/productsResponsiveTable';
import { CreateProductForm } from '../product/createProductForm';
import { ManufacturerResponsiveTable } from '../manufacturer/manufactorersResponsiveTable';
import { CreateManufacturerForm } from '../manufacturer/createManufacturerForm';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedProductCatalog: React.FC<Props> = ({ location }) => {
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
  const [triggerProductReload, setProductTriggerReload] = useState(false);

  const [isManufacturerModalVisible, setIsManufacturerModalVisible] = useState(
    false,
  );
  const [triggerManufacturerReload, setManufacturerTriggerReload] = useState(
    false,
  );

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const handleTabChanged = (activeKey: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const pathName = window.location.pathname;
    const searchParams = new URLSearchParams();
    searchParams.set('tab', activeKey);
    const newUrl = `${protocol}//${host}${pathName}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setProductTriggerReload(true);
    setManufacturerTriggerReload(true);
  };
  const defaultTab = queryParams['tab']?.toString() || 'general';

  return (
    <Layout>
      <Content>
        <Tabs defaultActiveKey={defaultTab} onChange={handleTabChanged}>
          <TabPane tab="Products" key="products">
            <Modal
              title="Create product"
              visible={isProductModalVisible}
              onCancel={() => {
                setIsProductModalVisible(false);
              }}
              footer={null}
            >
              <CreateProductForm
                onCreated={() => {
                  setProductTriggerReload(true);
                  setIsProductModalVisible(false);
                }}
              />
            </Modal>

            <ProductsResponsiveTable
              reload={triggerProductReload}
              onReloaded={() => setProductTriggerReload(false)}
              queryParams={queryParams}
            />

            {!isProductModalVisible && (
              <Fab
                style={{ bottom: 0, right: 0 }}
                mainButtonStyles={{
                  backgroundColor: getNavbarColor(),
                  color: getNavbarTextColor(),
                }}
                icon={<PlusOutlined />}
                alwaysShowTitle={false}
                onClick={() => {
                  setIsProductModalVisible(true);
                }}
                text="Create product"
              />
            )}
          </TabPane>
          <TabPane tab="Manufacturers" key="manufacturers">
            <Modal
              title="Create manufacturer"
              visible={isManufacturerModalVisible}
              onCancel={() => {
                setIsManufacturerModalVisible(false);
              }}
              footer={null}
            >
              <CreateManufacturerForm
                onCreated={() => {
                  setManufacturerTriggerReload(true);
                  setIsManufacturerModalVisible(false);
                }}
              />
            </Modal>

            <ManufacturerResponsiveTable
              reload={triggerManufacturerReload}
              onReloaded={() => setManufacturerTriggerReload(false)}
              queryParams={queryParams}
            />

            {!isManufacturerModalVisible && (
              <Fab
                style={{ bottom: 0, right: 0 }}
                mainButtonStyles={{
                  backgroundColor: getNavbarColor(),
                  color: getNavbarTextColor(),
                }}
                icon={<PlusOutlined />}
                alwaysShowTitle={false}
                onClick={() => {
                  setIsManufacturerModalVisible(true);
                }}
                text="Create manufacturer"
              />
            )}
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const ProductCatalog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedProductCatalog));

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateManufacturerCmd, UpdateManufacturerCmd } from '../model/cmds';
import { ManufacturerModel } from '../model/domain';
import {
  ListManufacturersQuery,
  ListManufacturersQueryModel,
} from '../model/querys';

export class ManufacturerApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createManufacturer = this.createManufacturer.bind(this);
    this.getManufacturer = this.getManufacturer.bind(this);
    this.listManufacturers = this.listManufacturers.bind(this);
    this.updateManufacturer = this.updateManufacturer.bind(this);
    this.deleteManufacturer = this.deleteManufacturer.bind(this);
  }

  public createManufacturer(
    cmd: CreateManufacturerCmd,
  ): Promise<ManufacturerModel> {
    return this.post<
      ManufacturerModel,
      CreateManufacturerCmd,
      AxiosResponse<ManufacturerModel>
    >(`/v1/manufacturers`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getManufacturer(manufacturerId: string): Promise<ManufacturerModel> {
    return this.get<ManufacturerModel, AxiosResponse<ManufacturerModel>>(
      `/v1/manufacturers/${manufacturerId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listManufacturers(
    cmd?: ListManufacturersQuery,
  ): Promise<ListManufacturersQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListManufacturersQueryModel,
      AxiosResponse<ListManufacturersQueryModel>
    >('/v1/manufacturers', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateManufacturer(
    manufacturerId: string,
    cmd: UpdateManufacturerCmd,
  ): Promise<ManufacturerModel> {
    return this.patch<
      ManufacturerModel,
      UpdateManufacturerCmd,
      AxiosResponse<ManufacturerModel>
    >(`/v1/manufacturers/${manufacturerId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteManufacturer(
    manufacturerId: string,
  ): Promise<ManufacturerModel> {
    return this.delete<ManufacturerModel, AxiosResponse<ManufacturerModel>>(
      `/v1/manufacturers/${manufacturerId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const manufacturerApi = new ManufacturerApi();

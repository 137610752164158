import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { organisationApi } from '../../api/organisationApi';

import { smartSensorApi } from '../../api/smartSensorApi';
import {
  CREATE_SMART_SENSOR,
  CREATE_SMART_SENSOR_FAILED,
  CREATE_SMART_SENSOR_SUCCESS,
  FETCH_SMART_SENSOR,
  FETCH_SMART_SENSOR_FAILED,
  FETCH_SMART_SENSOR_SUCCESS,
  FETCH_SMART_SENSORS,
  FETCH_SMART_SENSORS_FAILED,
  FETCH_SMART_SENSORS_SUCCESS,
  UPDATE_SMART_SENSOR,
  UPDATE_SMART_SENSOR_FAILED,
  UPDATE_SMART_SENSOR_SUCCESS,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS,
  REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED,
  INT_MAX,
  FETCH_ORGANISATION_SMART_SENSORS,
  FETCH_ORGANISATION_SMART_SENSORS_SUCCESS,
  FETCH_ORGANISATION_SMART_SENSORS_FAILED,
} from '../../constants';
import { CreateSmartSensorCmd, UpdateSmartSensorCmd } from '../../model/cmds';
import {
  ListSmartSensorsQuery,
  ListSmartSensorsQueryModel,
} from '../../model/querys';
import { SmartSensorsState } from '../../model/state';
import {
  organisationSmartSensorRemove,
  organisationSmartSensorRemoved,
  organisationSmartSensorsFetch,
  organisationSmartSensorsFetched,
  organisationSmartSensorsFetchFailed,
  smartSensorCreate,
  smartSensorCreated,
  smartSensorCreateFailed,
  smartSensorFetch,
  smartSensorFetched,
  smartSensorFetchFailed,
  SmartSensorsAction,
  smartSensorsFetch,
  smartSensorsFetched,
  smartSensorsFetchFailed,
  smartSensorUpdate,
  smartSensorUpdated,
  smartSensorUpdateFailed,
} from '../actions';

export function createSmartSensor(
  cmd: CreateSmartSensorCmd,
  organisationId?: string,
) {
  return async (dispatch: Dispatch<SmartSensorsAction, {}, any>) => {
    try {
      dispatch(smartSensorCreate());
      const smartSensor = await smartSensorApi.createSmartSensor(cmd);

      if (organisationId) {
        // add sensor to organisation
        const organisation = await organisationApi.addSmartSensorToOrganisation(
          organisationId,
          { smartSensorId: smartSensor.id },
        );

        dispatch(smartSensorCreated(smartSensor));
      } else {
        dispatch(smartSensorCreated(smartSensor));
      }
    } catch (e) {
      dispatch(smartSensorCreateFailed(e));
    }
  };
}

export function getSmartSensor(id: string) {
  return async (dispatch: Dispatch<SmartSensorsAction, {}, any>) => {
    try {
      dispatch(smartSensorFetch());
      const smartSensor = await smartSensorApi.fetchSmartSensor(id);
      dispatch(smartSensorFetched(smartSensor));
    } catch (e) {
      dispatch(smartSensorFetchFailed(e));
    }
  };
}

export function getSmartSensors(query: ListSmartSensorsQuery) {
  return async (dispatch: Dispatch<SmartSensorsAction, {}, any>) => {
    try {
      dispatch(smartSensorsFetch(query));
      const queryModel: ListSmartSensorsQueryModel = await smartSensorApi.listSmartSensors(
        query,
      );
      const smartSensors = queryModel.smartSensors;

      dispatch(smartSensorsFetched(queryModel, smartSensors));
    } catch (e) {
      dispatch(smartSensorsFetchFailed(e));
    }
  };
}

export function getOrganisationSmartSensors(organisationId: string) {
  return async (dispatch: Dispatch<SmartSensorsAction, {}, any>) => {
    try {
      const query = {
        limit: INT_MAX,
        offset: 0,
        organisationIds: [organisationId],
      };

      dispatch(organisationSmartSensorsFetch(query));
      const queryModel = await smartSensorApi.listSmartSensors(query);
      const smartSensors = queryModel.smartSensors;

      dispatch(organisationSmartSensorsFetched(queryModel, smartSensors));
    } catch (e) {
      dispatch(organisationSmartSensorsFetchFailed(e));
    }
  };
}

export function updateSmartSensor(id: string, cmd: UpdateSmartSensorCmd) {
  return async (dispatch: Dispatch<SmartSensorsAction, {}, any>) => {
    try {
      dispatch(smartSensorUpdate());
      const response = await smartSensorApi.updateSmartSensor(id, cmd);
      dispatch(smartSensorUpdated(response));
      dispatch(getSmartSensor(id));
    } catch (e) {
      dispatch(smartSensorUpdateFailed(e));
    }
  };
}

export function removeSmartSensorFromOrganisation(
  organisationId: string,
  smartSensorId: string,
  onSuccess?: Function,
) {
  return async (dispatch: Dispatch<SmartSensorsAction, {}, any>) => {
    try {
      dispatch(organisationSmartSensorRemove(organisationId, smartSensorId));
      const sensor = await organisationApi.removeSmartSensorFromOrganisation(
        organisationId,
        smartSensorId,
      );

      const organisation = await organisationApi.fetchOrganisation(
        organisationId,
      );

      dispatch(organisationSmartSensorRemoved(organisation));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(smartSensorUpdateFailed(e));
    }
  };
}

export default function smartSensorsReducer(
  state: SmartSensorsState = {
    create: {
      loading: false,
      error: null,
      smartSensor: null,
    },
    list: {
      loading: false,
      error: null,
      smartSensors: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      smartSensor: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    removeFromOrganisation: {
      loading: false,
      error: null,
      success: null,
    },
    organisationSmartSensors: {
      loading: false,
      error: null,
      smartSensors: [],
      total: null,
      offset: null,
      limit: null,
    },
  },
  action: SmartSensorsAction,
): SmartSensorsState {
  switch (action.type) {
    case CREATE_SMART_SENSOR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          smartSensor: null,
        },
      };
    case CREATE_SMART_SENSOR_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          smartSensor: action.payload,
        },
      };
    case CREATE_SMART_SENSOR_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          smartSensor: null,
        },
      };
    case FETCH_SMART_SENSOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          smartSensor: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_SMART_SENSOR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          smartSensor: action.payload,
        },
      };
    case FETCH_SMART_SENSOR_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          smartSensor: null,
        },
      };
    case UPDATE_SMART_SENSOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_SMART_SENSOR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_SMART_SENSOR_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_SMART_SENSORS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          smartSensors: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_SMART_SENSORS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          smartSensors: action.payload.smartSensors,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_SMART_SENSORS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          smartSensors: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case REMOVE_SMART_SENSOR_FROM_ORGANISATION: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: true,
          success: null,
          error: null,
        },
      };
    }

    case REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: false,
          success: true,
          error: null,
        },
      };
    }

    case REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED: {
      return {
        ...state,
        removeFromOrganisation: {
          ...state.removeFromOrganisation,
          loading: true,
          success: false,
          error: action.payload,
        },
      };
    }

    case FETCH_ORGANISATION_SMART_SENSORS:
      return {
        ...state,
        organisationSmartSensors: {
          ...state.organisationSmartSensors,
          loading: true,
          error: null,
          smartSensors: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ORGANISATION_SMART_SENSORS_SUCCESS:
      return {
        ...state,
        organisationSmartSensors: {
          ...state.organisationSmartSensors,
          loading: false,
          error: null,
          smartSensors: action.payload.smartSensors,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_ORGANISATION_SMART_SENSORS_FAILED:
      return {
        ...state,
        organisationSmartSensors: {
          ...state.organisationSmartSensors,
          loading: true,
          error: null,
          smartSensors: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    default:
      return state;
  }
}

import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Popconfirm,
  Button,
  notification,
  Typography,
  Descriptions,
  Checkbox,
  Input,
  Select,
  Space,
  Tag,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { UpdateTicketCmd } from '../../model/cmds';
import {
  addCommentAndUpdateTicket,
  deleteTicket,
  getTicket,
  removeCommentFromTicket,
  updateTicket,
} from '../../duck/modules/tickets';
import { usePrevious } from '../_util/hook';
import { getProducts } from '../../duck/modules/products';
import moment from 'moment';
import { TicketStatus } from '../../model/enums';
import { functionalColor } from '../../colors';
import { renderStatusTag } from '../ticket/ticketUtil';
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ ticketId: string }>;

const UnconnectedTicketDetail: React.FC<Props> = ({
  getTicketConnect,
  updateTicketConnect,
  ticket,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  deleteTicketConnect,
  deleting,
  deleteSuccess,
  deleteError,
  addCommentAndUpdateTicketConnect,
  removeCommentFromTicket,
}) => {
  const { ticketId } = match.params;

  React.useEffect(() => {
    getTicketConnect(ticketId);
  }, []);

  const prev = usePrevious({
    deleteSuccess: deleteSuccess,
    deleteError: deleteError,
    updateSuccess: updateSuccess,
    updateError: updateError,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteSuccess) {
      notification.success({
        message: 'Ticket deleted',
      });
    }
  }, [deleteSuccess]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteError) {
      notification.error({
        message: 'Ticket deletion error',
        description: deleteError?.message,
      });
    }
  }, [deleteError]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Ticket updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating Ticket',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const [commentCreator, setCommentCreator] = useState('');
  const [commentNote, setCommentNote] = useState('');
  const [commentInternal, setCommentInternal] = useState(true);
  const [nextTicketStatus, setNextTicketStatus] = useState(TicketStatus.WIP);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load ticket"
        subTitle={error.message}
      />
    );
  }

  const renderCommentForm = () => {
    return (
      <Col span={24}>
        <Row justify="start" gutter={[20, 0]}>
          <Col span={2} offset={4}>
            <Input
              value={commentCreator}
              onChange={(e) => setCommentCreator(e.target.value)}
            />
          </Col>

          <Col span={10}>
            <Input.TextArea
              placeholder="Enter note about ticket"
              value={commentNote}
              onChange={(e) => setCommentNote(e.target.value)}
            />
          </Col>

          <Col span={2}>
            <Checkbox
              checked={commentInternal}
              onClick={(e) => setCommentInternal(!commentInternal)}
            />
          </Col>

          <Col span={3}>
            <Select
              style={{ width: 120 }}
              value={nextTicketStatus}
              onChange={(value: TicketStatus) => setNextTicketStatus(value)}
            >
              <Select.Option value={TicketStatus.WIP}>
                <Tag color={functionalColor.link}>{'WIP'}</Tag>
              </Select.Option>

              <Select.Option value={TicketStatus.SOLVED}>
                <Tag color={functionalColor.success}>{'SOLVED'}</Tag>
              </Select.Option>
            </Select>
          </Col>

          <Col span={3}>
            <Button
              type="primary"
              disabled={
                !commentNote ||
                !commentCreator ||
                commentNote.trim() == '' ||
                commentCreator.trim() == ''
              }
              onClick={() => clickOnAddComment()}
              loading={updating}
            >
              {'Add Comment'}
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  const clickOnAddComment = () => {
    const creator: string = commentCreator;
    const note: string = commentNote;
    const internal: boolean = commentInternal;
    const nextStatus: TicketStatus = nextTicketStatus;

    addCommentAndUpdateTicketConnect(
      ticketId,
      {
        creator,
        note,
        internal,
      },
      {
        status: nextStatus,
      },
    );

    setCommentCreator('');
    setCommentInternal(true);
    setCommentNote('');
    setNextTicketStatus(
      nextStatus == TicketStatus.SOLVED
        ? TicketStatus.SOLVED
        : TicketStatus.WIP,
    );
  };

  const renderCommentFormHeader = () => {
    return (
      <Col span={24}>
        <Row justify="start" gutter={[20, 0]}>
          <Col span={2} offset={4}>
            <Typography.Text strong>{'User'}</Typography.Text>
          </Col>

          <Col span={10}>
            <Typography.Text strong>{'Note'}</Typography.Text>
          </Col>

          <Col span={2}>
            <Typography.Text strong>{'Internal Only'}</Typography.Text>
          </Col>

          <Col span={3}>
            <Typography.Text strong>{'Next Status'}</Typography.Text>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderComments = () => {
    return (
      <Col span={24}>
        {ticket?.comments.map((comment) => (
          <Row justify="start" gutter={[20, 0]}>
            <Col span={4}>
              <Typography>{moment(comment.timestamp).format('LLL')}</Typography>
            </Col>

            <Col span={2}>
              <Typography>{comment.creator}</Typography>
            </Col>

            <Col span={10}>
              <Typography>{comment.note}</Typography>
            </Col>

            <Col span={2}>
              <Checkbox checked={comment.internal} />
            </Col>

            <Col span={2} offset={3}>
              <Popconfirm
                title="Are you sure you want to remove this comment?"
                onConfirm={() => removeCommentFromTicket(ticketId, comment.id)}
              >
                <Button type="text" danger>
                  {'Remove Comment'}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        ))}
      </Col>
    );
  };

  const renderGeneralDataContent = () => {
    if (ticket) {
      return (
        <Descriptions
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          colon={false}
          size="default"
        >
          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'ID:'}
              </Typography.Text>
            }
          >
            {ticket.ticketNumber}
          </Descriptions.Item>
          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'Status:'}
              </Typography.Text>
            }
          >
            {renderStatusTag(ticket)}
          </Descriptions.Item>
          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'Open Date:'}
              </Typography.Text>
            }
          >
            {ticket.timestamp}
          </Descriptions.Item>

          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'Organisation:'}
              </Typography.Text>
            }
          >
            <Link to={`/organisations/${ticket?.organisation?.id}`}>
              {ticket?.organisation?.name ?? 'N/A'}
            </Link>
          </Descriptions.Item>

          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'Reporter:'}
              </Typography.Text>
            }
          >
            <Link to={`/accounts/${ticket.reporter?.id}`}>
              {ticket?.reporter?.name ?? 'N/A'}
            </Link>
          </Descriptions.Item>

          {ticket.pond && (
            <Descriptions.Item
              span={24}
              label={
                <Typography.Text strong style={{ width: 100 }}>
                  {'Pond:'}
                </Typography.Text>
              }
            >
              <Link to={`/ponds/${ticket?.pond?.id}`}>{ticket?.pond?.name ?? "N/A"}</Link>
            </Descriptions.Item>
          )}

          {ticket.gateway && (
            <Descriptions.Item
              span={24}
              label={
                <Typography.Text strong style={{ width: 100 }}>
                  {'Gateway:'}
                </Typography.Text>
              }
            >
              <Link to={`/gateways/${ticket?.gateway?.id}`}>
                {ticket?.gateway?.name ?? 'N/A'}
              </Link>
            </Descriptions.Item>
          )}

          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'Message:'}
              </Typography.Text>
            }
          >
            <Typography.Paragraph>{ticket.message}</Typography.Paragraph>
          </Descriptions.Item>

          <Descriptions.Item
            span={24}
            label={
              <Typography.Text strong style={{ width: 100 }}>
                {'Meta:'}
              </Typography.Text>
            }
          >
            <Typography.Paragraph >{ticket.meta}</Typography.Paragraph>
          </Descriptions.Item>



        </Descriptions>
      );
    }
  };

  const renderDeleteButton = () => {
    if (ticket) {
      if (ticket.deleted === true) {
        return (
          <Button
            type="ghost"
            danger
            style={{ marginLeft: 12 }}
            onClick={() => updateTicketConnect(ticketId, { deleted: false })}
          >
            {'Restore Ticket'}
          </Button>
        );
      }
      return (
        <Popconfirm
          title="Do you really want to delete this ticket?"
          onConfirm={() => deleteTicketConnect(ticketId)}
        >
          <Button
            type="ghost"
            style={{ marginLeft: 12 }}
            danger
            loading={deleting}
          >
            {'Delete Ticket'}
          </Button>
        </Popconfirm>
      );
    }
  };

  return (
    <Layout>
      <Content>
        <Col span={18} offset={3}>
          <Row justify="center" align="top" gutter={[10, 10]}>
            <Col>{renderDeleteButton()}</Col>
          </Row>

          <Row justify="center" align="top" gutter={[10, 10]}>
            {renderGeneralDataContent()}
          </Row>

          <Space size={'large'} />

          <Row>
            <Typography.Title level={2}>{'Comments'}</Typography.Title>
          </Row>

          <Row justify="center" align="top" gutter={[10, 10]}>
            {renderCommentFormHeader()}
          </Row>

          <Row justify="center" align="top" gutter={[10, 10]}>
            {renderCommentForm()}
          </Row>

          <br />

          <Row justify="start" align="top" gutter={[10, 10]}>
            {renderComments()}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  ticket: state.tickets.detail.ticket,
  loading: state.tickets.detail.loading,
  error: state.tickets.detail.error,
  updating: state.tickets.detail.updating,
  updateError: state.tickets.detail.updateError,
  updateSuccess: state.tickets.detail.updateSuccess,
  deleting: state.tickets.detail.deleting,
  deleteSuccess: state.tickets.detail.deleteSuccess,
  deleteError: state.tickets.detail.deleteError,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTicketConnect: getTicket,
      updateTicketConnect: updateTicket,
      deleteTicketConnect: deleteTicket,
      getProductsConntect: getProducts,
      addCommentAndUpdateTicketConnect: addCommentAndUpdateTicket,
      removeCommentFromTicket: removeCommentFromTicket,
    },
    dispatch,
  );
};

export const TicketDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedTicketDetail);

import { MessageModel } from '../../model/domain';
import {
  AutoComplete,
  Button,
  Form,
  Input,
  notification,
  Select,
  Space,
  Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { RootState } from '../../duck';
import { bindActionCreators, Dispatch } from 'redux';
import { createMessage } from '../../duck/modules/messages';
import { connect } from 'react-redux';
import React from 'react';
import { MessageType, PopUpRule, PopUpType } from '../../model/enums';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (message: MessageModel) => void;
    onError: (error: Error) => void;
  };

const UnconnectedCreatePopUpForm: React.FC<Props> = ({
  loading,
  error,
  createMessageConnect,
  messageCreated,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();

  const convertUTC = (date: Date) => {
    return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  };

  React.useEffect(() => {
    if (messageCreated) {
      notification.success({
        message: 'Message created',
      });
      onReset();
      if (onCreated != null) {
        onCreated(messageCreated);
      }
    }
  }, [messageCreated]);

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (values: Store) => {
    const {
      type,
      titleInEnglish,
      titleInThai,
      bodyInEnglish,
      bodyInThai,
      gifUrl,
      rule,
    } = values;

    createMessageConnect({
      type: MessageType.POP_UP,
      popUp: {
        dataInEnglish: {
          title: titleInEnglish,
          body: bodyInEnglish,
        },
        dataInThai: {
          title: titleInThai,
          body: bodyInThai,
        },
        gifUrl: gifUrl,
        rules: [rule],
        type: type,
      },
    });
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item label="Pop Up Type" name="type">
        <Select placeholder="Select pop up type">
          <Option value={PopUpType.GENERAL_POP_UP}>{'General Pop Up'}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={<Typography.Text italic>{'Title'}</Typography.Text>}
        style={{ marginBottom: 0 }}
      >
        <Form.Item
          label="Enlish"
          name="titleInEnglish"
          rules={[{ required: true }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
          }}
        >
          <Input placeholder="Title (in English)" />
        </Form.Item>
        <Form.Item
          label="Thai"
          name="titleInThai"
          rules={[{ required: true }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <Input placeholder="Title (in Thai)" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={<Typography.Text italic>{'Body'}</Typography.Text>}
        style={{ marginBottom: 0 }}
      >
        <Form.Item
          label="English"
          name="bodyInEnglish"
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <TextArea
            placeholder="Body (In English)"
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(body) =>
              form.setFieldsValue({ englishBody: body.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="Thai"
          name="bodyInThai"
          rules={[{ required: true }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <TextArea
            placeholder="Body (In Thai)"
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(body) =>
              form.setFieldsValue({ thaiBody: body.target.value })
            }
          />
        </Form.Item>
      </Form.Item>
      <Form.Item label="Gif URL" name="gifUrl" rules={[{ required: true }]}>
        <Input placeholder="Gif url for the pop up" />
      </Form.Item>
      <Form.Item label="Rules" name="rule" rules={[{ required: true }]}>
        <AutoComplete
          placeholder="Enter Rules"
          options={[
            PopUpRule.SHOW_ONCE,
            PopUpRule.REPEAT_ONE_DAILY,
            PopUpRule.ACCOUNT,
            PopUpRule.FARM,
            PopUpRule.POND,
            PopUpRule.SENSOR,
            PopUpRule.TNC,
          ].map((option) => {
            return { lable: option, value: option };
          })}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Pop Up
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.message.create.loading,
  error: state.message.create.error,
  messageCreated: state.message.create.message,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createMessageConnect: createMessage,
    },
    dispatch,
  );
};

export const CreatePopUpForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreatePopUpForm);

import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Popconfirm,
  Button,
  notification,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { UpdateAnnouncementCmd } from '../../model/cmds';
import {
  deleteAnnouncement,
  getAnnouncement,
  updateAnnouncement,
} from '../../duck/modules/announcements';
import { usePrevious } from '../_util/hook';
import { getProducts } from '../../duck/modules/products';
import { AnnouncementDataForm } from '../announcement/announcementDataForm';
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ announcementId: string }>;

const UnconnectedAnnouncementDetail: React.FC<Props> = ({
  getAnnouncementConnect,
  updateAnnouncementConnect,
  announcement,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  deleteAnnouncementConnect,
  deleteState,
}) => {
  const { announcementId } = match.params;

  React.useEffect(() => {
    getAnnouncementConnect(announcementId);
  }, []);

  const prev = usePrevious({
    deleteSuccess: deleteState.success,
    deleteError: deleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteState.success) {
      notification.success({
        message: 'Announcement deleted',
      });
    }
  }, [deleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteState.error) {
      notification.error({
        message: 'Announcement deletion error',
        description: deleteState.error?.message,
      });
    }
  }, [deleteState.error]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load announcement"
        subTitle={error.message}
      />
    );
  }

  const renderGeneralDataCardContent = () => {
    if (announcement) {
      return (
        <AnnouncementDataForm
          announcement={announcement}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onUpdate={(cmd: UpdateAnnouncementCmd) => {
            updateAnnouncementConnect(announcementId, cmd);
          }}
        />
      );
    }
  };

  const renderDeleteButton = () => {
    if (announcement) {
      if (announcement.deleted === true) {
        return (
          <Button
            type="ghost"
            danger
            style={{ marginLeft: 12 }}
            onClick={() =>
              updateAnnouncementConnect(announcementId, { deleted: false })
            }
          >
            Restore
          </Button>
        );
      }
      return (
        <Popconfirm
          title="Do you really want to delete this announcement?"
          onConfirm={() => deleteAnnouncementConnect(announcementId)}
        >
          <Button
            type="ghost"
            style={{ marginLeft: 12 }}
            danger
            loading={deleteState.loading}
          >
            Delete
          </Button>
        </Popconfirm>
      );
    }
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{renderDeleteButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Announcement Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  announcement: state.announcements.detail.announcement,
  loading: state.announcements.detail.loading,
  error: state.announcements.detail.error,
  updating: state.announcements.detail.updating,
  updateError: state.announcements.detail.updateError,
  updateSuccess: state.announcements.detail.updateSuccess,
  deleteState: state.announcements.delete,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAnnouncementConnect: getAnnouncement,
      updateAnnouncementConnect: updateAnnouncement,
      deleteAnnouncementConnect: deleteAnnouncement,
      getProductsConntect: getProducts,
    },
    dispatch,
  );
};

export const AnnouncementDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAnnouncementDetail);

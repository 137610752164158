import { UploadFile } from 'antd/lib/upload/interface';
import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { sensorCapApi } from '../../api/sensorCapApi';
import {
  CREATE_SENSOR_CAP,
  CREATE_SENSOR_CAP_FAILED,
  CREATE_SENSOR_CAP_SUCCESS,
  FETCH_SENSOR_CAP,
  FETCH_SENSOR_CAP_FAILED,
  FETCH_SENSOR_CAP_SUCCESS,
  FETCH_SENSOR_CAPS,
  FETCH_SENSOR_CAPS_FAILED,
  FETCH_SENSOR_CAPS_SUCCESS,
  UPDATE_SENSOR_CAP,
  UPDATE_SENSOR_CAP_FAILED,
  UPDATE_SENSOR_CAP_SUCCESS,
  DELETE_SENSOR_CAP,
  DELETE_SENSOR_CAP_SUCCESS,
  DELETE_SENSOR_CAP_FAILED,
  IMPORT_SENSOR_CAPS,
  IMPORT_SENSOR_CAPS_SUCCESS,
  IMPORT_SENSOR_CAPS_FAILED,
} from '../../constants';
import { CreateSensorCapCmd, UpdateSensorCapCmd } from '../../model/cmds';
import {
  ListSensorCapsQuery,
  ListSensorCapsQueryModel,
} from '../../model/querys';
import { SensorCapsState } from '../../model/state';
import {
  sensorCapCreate,
  sensorCapCreated,
  sensorCapCreateFailed,
  sensorCapFetch,
  sensorCapFetched,
  sensorCapFetchFailed,
  SensorCapsAction,
  sensorCapsFetch,
  sensorCapsFetched,
  sensorCapsFetchFailed,
  sensorCapUpdate,
  sensorCapUpdated,
  sensorCapUpdateFailed,
  sensorCapDelete,
  sensorCapDeleted,
  sensorCapDeleteFailed,
  sensorCapsImport,
  sensorCapsImported,
  sensorCapsImportFailed,
} from '../actions';

export function createSensorCap(cmd: CreateSensorCapCmd) {
  return async (dispatch: Dispatch<SensorCapsAction, {}, any>) => {
    try {
      dispatch(sensorCapCreate());
      const sensorCap = await sensorCapApi.createSensorCap(cmd);
      dispatch(sensorCapCreated(sensorCap));
    } catch (e) {
      dispatch(sensorCapCreateFailed(e as any));
    }
  };
}

export function importSensorCaps(formData: FormData) {
  return async (dispatch: Dispatch<SensorCapsAction, {}, any>) => {
    try {
      dispatch(sensorCapsImport());
      const response = await sensorCapApi.importSensorCaps(formData);
      dispatch(sensorCapsImported(response));
    } catch (e) {
      dispatch(sensorCapsImportFailed(e as any));
    }
  };
}

export function getSensorCap(id: string) {
  return async (dispatch: Dispatch<SensorCapsAction, {}, any>) => {
    try {
      dispatch(sensorCapFetch());
      const sensorCap = await sensorCapApi.getSensorCap(id);
      dispatch(sensorCapFetched(sensorCap));
    } catch (e) {
      dispatch(sensorCapFetchFailed(e as any));
    }
  };
}

export function getSensorCaps(query: ListSensorCapsQuery) {
  return async (dispatch: Dispatch<SensorCapsAction, {}, any>) => {
    try {
      dispatch(sensorCapsFetch(query));
      const queryModel: ListSensorCapsQueryModel = await sensorCapApi.listSensorCaps(
        query,
      );
      const sensorCaps = queryModel.sensorCapValues;
      dispatch(sensorCapsFetched(queryModel, sensorCaps));
    } catch (e) {
      dispatch(sensorCapsFetchFailed(e as any));
    }
  };
}

export function updateSensorCap(id: string, cmd: UpdateSensorCapCmd) {
  return async (dispatch: Dispatch<SensorCapsAction, {}, any>) => {
    try {
      dispatch(sensorCapUpdate());
      const response = await sensorCapApi.updateSensorCap(id, cmd);
      dispatch(sensorCapUpdated(response));
      dispatch(getSensorCap(id));
    } catch (e) {
      dispatch(sensorCapUpdateFailed(e as any));
    }
  };
}

export function deleteSensorCap(sensorCapId: string, onSuccess?: Function) {
  return async (dispatch: Dispatch<SensorCapsAction, {}, any>) => {
    try {
      dispatch(sensorCapDelete(sensorCapId));
      await sensorCapApi.deleteSensorCap(sensorCapId);
      const sensorCap = await sensorCapApi.getSensorCap(sensorCapId);
      dispatch(sensorCapDeleted(sensorCap));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(sensorCapDeleteFailed(e as any));
    }
  };
}

export default function sensorCapsReducer(
  state: SensorCapsState = {
    create: {
      loading: false,
      error: null,
      sensorCap: null,
    },
    import: {
      loading: false,
      error: null,
      sensorCaps: [],
    },
    list: {
      loading: false,
      error: null,
      sensorCaps: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      sensorCap: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      success: null,
      error: null,
    },
  },
  action: SensorCapsAction,
): SensorCapsState {
  switch (action.type) {
    case CREATE_SENSOR_CAP:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          sensorCap: null,
        },
      };
    case CREATE_SENSOR_CAP_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          sensorCap: action.payload,
        },
      };
    case CREATE_SENSOR_CAP_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          sensorCap: null,
        },
      };
    case IMPORT_SENSOR_CAPS:
      return {
        ...state,
        import: {
          ...state.import,
          loading: true,
          error: null,
          sensorCaps: [],
        },
      };
    case IMPORT_SENSOR_CAPS_SUCCESS:
      return {
        ...state,
        import: {
          ...state.import,
          loading: false,
          error: null,
          sensorCaps: action.payload.sensorCapValues,
        },
      };
    case IMPORT_SENSOR_CAPS_FAILED:
      return {
        ...state,
        import: {
          ...state.import,
          loading: false,
          error: action.payload,
          sensorCaps: [],
        },
      };

    case FETCH_SENSOR_CAP:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          sensorCap: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_SENSOR_CAP_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          sensorCap: action.payload,
        },
      };
    case FETCH_SENSOR_CAP_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          sensorCap: null,
        },
      };
    case UPDATE_SENSOR_CAP:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_SENSOR_CAP_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_SENSOR_CAP_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case FETCH_SENSOR_CAPS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          sensorCaps: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_SENSOR_CAPS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          sensorCaps: action.payload.sensorCaps,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_SENSOR_CAPS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          sensorCaps: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case DELETE_SENSOR_CAP:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null,
          success: null,
        },
      };

    case DELETE_SENSOR_CAP_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          sensorCap: action.payload,
        },
        delete: {
          ...state.delete,
          loading: false,
          error: null,
          success: true,
        },
      };

    case DELETE_SENSOR_CAP_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    default:
      return state;
  }
}

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AddCommentToTicketCmd,
  CreateTicketCmd,
  UpdateTicketCmd,
} from '../model/cmds';
import { TicketModel } from '../model/domain';
import { ListTicketsQueryModel, ListTicketsQuery } from '../model/querys';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';

export class TicketApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createTicket = this.createTicket.bind(this);
    this.getTicket = this.getTicket.bind(this);
    this.deleteTicket = this.deleteTicket.bind(this);
    this.updateTicket = this.updateTicket.bind(this);
    this.listTickets = this.listTickets.bind(this);
    this.addComment = this.addComment.bind(this);
    this.removeComment = this.removeComment.bind(this);
  }

  public createTicket(cmd: CreateTicketCmd): Promise<TicketModel> {
    return this.post<TicketModel, CreateTicketCmd, AxiosResponse<TicketModel>>(
      `/v1/tickets`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addComment(
    id: string,
    cmd: AddCommentToTicketCmd,
  ): Promise<TicketModel> {
    return this.post<
      TicketModel,
      AddCommentToTicketCmd,
      AxiosResponse<TicketModel>
    >(`/v1/tickets/${id}/comments`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getTicket(ticketId: string): Promise<TicketModel> {
    return this.get<TicketModel, AxiosResponse<TicketModel>>(
      `/v1/tickets/${ticketId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listTickets(cmd?: ListTicketsQuery): Promise<ListTicketsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }

    return this.get<
      ListTicketsQueryModel,
      AxiosResponse<ListTicketsQueryModel>
    >(`/v1/tickets`, config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteTicket(ticketId: string): Promise<TicketModel> {
    return this.delete<TicketModel, AxiosResponse<TicketModel>>(
      `/v1/tickets/${ticketId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeComment(
    ticketId: string,
    commentId: string,
  ): Promise<TicketModel> {
    return this.delete<TicketModel, AxiosResponse<TicketModel>>(
      `/v1/tickets/${ticketId}/comments/${commentId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateTicket(
    ticketId: string,
    cmd: UpdateTicketCmd,
  ): Promise<TicketModel> {
    console.log(cmd);
    return this.patch<TicketModel, UpdateTicketCmd, AxiosResponse<TicketModel>>(
      `/v1/tickets/${ticketId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const ticketApi = new TicketApi();

import React from 'react';
import { Form, Input, Button, Space, notification, Tabs } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { PondModel } from '../../model/domain';
import { UpdatePondCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';

const { TabPane } = Tabs;

type Props = {
  pond: PondModel;
  onUpdate: (cmd: UpdatePondCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
};

export const PondThresholdsTabs: React.FC<Props> = ({
  pond,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [doForm] = Form.useForm();
  const [phForm] = Form.useForm();
  const [tempForm] = Form.useForm();

  const prev = usePrevious({ updateSuccess, updateError });

  React.useEffect(() => {
    doForm.setFieldsValue({ ...pond });
    phForm.setFieldsValue({ ...pond });
    tempForm.setFieldsValue({ ...pond });
  }, [pond]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Settings updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating settings',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmitDoSettings = (values: Store) => {
    const { lowCritical, lowReminder } = values;
    const cmd: UpdatePondCmd = {};

    if (
      Number.parseFloat(lowReminder) !==
      pond.doNotificationThreshold?.lowReminder
    ) {
      cmd.doNotificationThreshold = {
        ...cmd.doNotificationThreshold,
        lowReminder: Number.parseFloat(lowReminder),
      };
    }

    if (
      Number.parseFloat(lowCritical) !==
      pond.doNotificationThreshold?.lowCritical
    ) {
      cmd.doNotificationThreshold = {
        ...cmd.doNotificationThreshold,
        lowCritical: Number.parseFloat(lowCritical),
      };
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && pond) {
      cmd.doNotificationThreshold = {
        ...pond.doNotificationThreshold,
      };
      cmd.phNotificationThreshold = {
        ...pond.phNotificationThreshold,
      };
    }

    onUpdate(cmd);
  };

  const onSubmitPhSettings = (values: Store) => {
    const { lowReminder, lowCritical, highReminder, highCritical } = values;
    const cmd: UpdatePondCmd = {};

    if (
      Number.parseFloat(lowReminder) !==
      pond.phNotificationThreshold?.lowReminder
    ) {
      cmd.phNotificationThreshold = {
        ...cmd.phNotificationThreshold,
        lowReminder: Number.parseFloat(lowReminder),
      };
    }

    if (
      Number.parseFloat(lowCritical) !==
      pond.phNotificationThreshold?.lowCritical
    ) {
      cmd.phNotificationThreshold = {
        ...cmd.phNotificationThreshold,
        lowCritical: Number.parseFloat(lowCritical),
      };
    }

    if (
      Number.parseFloat(highReminder) !==
      pond.phNotificationThreshold?.highReminder
    ) {
      cmd.phNotificationThreshold = {
        ...cmd.phNotificationThreshold,
        highReminder: Number.parseFloat(highReminder),
      };
    }

    if (
      Number.parseFloat(highCritical) !==
      pond.phNotificationThreshold?.highCritical
    ) {
      cmd.phNotificationThreshold = {
        ...cmd.phNotificationThreshold,
        highCritical: Number.parseFloat(highCritical),
      };
    }

    onUpdate(cmd);
  };

  const onSubmitTempSettings = (values: Store) => {
    const { lowReminder, lowCritical, highReminder, highCritical } = values;
    const cmd: UpdatePondCmd = {};

    if (
      Number.parseFloat(lowReminder) !==
      pond.tempNotificationThreshold?.lowReminder
    ) {
      cmd.tempNotificationThreshold = {
        ...cmd.tempNotificationThreshold,
        lowReminder: Number.parseFloat(lowReminder),
      };
    }

    if (
      Number.parseFloat(lowCritical) !==
      pond.tempNotificationThreshold?.lowCritical
    ) {
      cmd.tempNotificationThreshold = {
        ...cmd.tempNotificationThreshold,
        lowCritical: Number.parseFloat(lowCritical),
      };
    }

    if (
      Number.parseFloat(highReminder) !==
      pond.tempNotificationThreshold?.highReminder
    ) {
      cmd.tempNotificationThreshold = {
        ...cmd.tempNotificationThreshold,
        highReminder: Number.parseFloat(highReminder),
      };
    }

    if (
      Number.parseFloat(highCritical) !==
      pond.tempNotificationThreshold?.highCritical
    ) {
      cmd.tempNotificationThreshold = {
        ...cmd.tempNotificationThreshold,
        highCritical: Number.parseFloat(highCritical),
      };
    }

    onUpdate(cmd);
  };

  const renderDoForm = () => {
    return (
      <Form
        form={doForm}
        initialValues={{
          lowReminder: pond.doNotificationThreshold?.lowReminder,
          lowCritical: pond.doNotificationThreshold?.lowCritical,
        }}
        layout="vertical"
        onFinish={onSubmitDoSettings}
      >
        <Form.Item label="Low Critical" name="lowCritical">
          <Input type="number" placeholder="2.5" />
        </Form.Item>

        <Form.Item label="Low Reminder" name="lowReminder">
          <Input type="number" placeholder="4.0" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={updating}>
              Update settings
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const renderPhForm = () => {
    return (
      <Form
        form={phForm}
        initialValues={{
          lowReminder: pond?.phNotificationThreshold?.lowReminder,
          lowCritical: pond?.phNotificationThreshold?.lowCritical,
          highReminder: pond?.phNotificationThreshold?.highReminder,
          highCritical: pond?.phNotificationThreshold?.highCritical,
        }}
        layout="vertical"
        onFinish={onSubmitPhSettings}
      >
        <Form.Item label="Low Critical" name="lowCritical">
          <Input type="number" placeholder="6.5" />
        </Form.Item>

        <Form.Item label="Low Reminder" name="lowReminder">
          <Input type="number" placeholder="7.0" />
        </Form.Item>

        <Form.Item label="High Reminder" name="highReminder">
          <Input type="number" placeholder="8.7" />
        </Form.Item>

        <Form.Item label="High Critical" name="highCritical">
          <Input type="number" placeholder="9.0" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={updating}>
              Update settings
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const renderTempForm = () => {
    return (
      <Form
        form={tempForm}
        initialValues={{
          lowReminder: pond?.tempNotificationThreshold?.lowReminder,
          lowCritical: pond?.tempNotificationThreshold?.lowCritical,
          highReminder: pond?.tempNotificationThreshold?.highReminder,
          highCritical: pond?.tempNotificationThreshold?.highCritical,
        }}
        layout="vertical"
        onFinish={onSubmitTempSettings}
      >
        <Form.Item label="Low Critical" name="lowCritical">
          <Input type="number" placeholder="24" />
        </Form.Item>

        <Form.Item label="Low Reminder" name="lowReminder">
          <Input type="number" placeholder="26" />
        </Form.Item>

        <Form.Item label="High Reminder" name="highReminder">
          <Input type="number" placeholder="32" />
        </Form.Item>

        <Form.Item label="High Critical" name="highCritical">
          <Input type="number" placeholder="35" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={updating}>
              Update settings
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Tabs defaultActiveKey="do" type="line" tabPosition="top">
      <TabPane forceRender tab="DO" key="do">
        {renderDoForm()}
      </TabPane>
      <TabPane forceRender tab="PH" key="ph">
        {renderPhForm()}
      </TabPane>
      <TabPane forceRender tab="Temperature" key="temp">
        {renderTempForm()}
      </TabPane>
    </Tabs>
  );
};

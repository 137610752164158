import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateFeedCmd, UpdateFeedCmd } from '../model/cmds';
import { FeedModel } from '../model/domain';
import { ListFeedsQuery, ListFeedsQueryModel } from '../model/querys';

export class FeedApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createFeed = this.createFeed.bind(this);
    this.fetchFeed = this.fetchFeed.bind(this);
    this.listFeeds = this.listFeeds.bind(this);
    this.updateFeed = this.updateFeed.bind(this);
  }

  public createFeed(cmd: CreateFeedCmd): Promise<FeedModel> {
    return this.post<FeedModel, CreateFeedCmd, AxiosResponse<FeedModel>>(
      `/v1/feeds`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchFeed(feedId: string): Promise<FeedModel> {
    return this.get<FeedModel, AxiosResponse<FeedModel>>(
      `/v1/feeds/${feedId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listFeeds(cmd?: ListFeedsQuery): Promise<ListFeedsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<ListFeedsQueryModel, AxiosResponse<ListFeedsQueryModel>>(
      '/v1/feeds',
      config,
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateFeed(feedId: string, cmd: UpdateFeedCmd): Promise<FeedModel> {
    return this.patch<FeedModel, UpdateFeedCmd, AxiosResponse<FeedModel>>(
      `/v1/feeds/${feedId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteFeed(feedId: string): Promise<void> {
    return this.delete<FeedModel, AxiosResponse<void>>(
      `/v1/feeds/${feedId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const feedApi = new FeedApi();

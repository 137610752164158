import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Card,
  Descriptions,
  Table,
  Form,
  Select,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getAnnouncements } from '../../duck/modules/announcements';
import Column from 'antd/lib/table/Column';
import { AnnouncementType } from '../../model/enums';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  renderAnnouncmentDetailLink,
  renderEndingTooltip,
  renderLastChageTooltip,
  renderStartingTooltip,
  renderStatusBadge,
  renderTypeTag,
} from './announcementUtil';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      types?: AnnouncementType[];
      showDeleted?: string;
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedAnnouncementsResponsiveTable: React.FC<Props> = ({
  announcements,
  loading,
  error,
  total,
  getAnnouncementsConnect,
  queryParams,
  reload,
  onReloaded,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  const [showDeleted, setShowDeleted] = React.useState(
    queryParams?.showDeleted === 'true' || false,
  );
  const [types, setTypes] = React.useState(queryParams?.types || []);

  React.useEffect(() => {
    getAnnouncementsConnect({
      limit,
      offset,
      types,
      includeDeleted: showDeleted,
    });
    onReloaded();
  }, [offset, limit, reload, types, showDeleted]);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={announcements}
        scroll={{ x: true }}
      >
        <Column align="center" title="Status" render={renderStatusBadge} />
        <Column align="center" title="Type" render={renderTypeTag} />
        <Column
          align="center"
          title="ID"
          render={renderAnnouncmentDetailLink}
        />

        <Column
          align="center"
          title="Starting"
          render={renderStartingTooltip}
        />

        <Column align="center" title="Ending" render={renderEndingTooltip} />

        <Column
          align="center"
          title="Last Change"
          render={renderLastChageTooltip}
        />
      </Table>
    );
  };

  const renderList = () => {
    return (
      <List
        loading={loading}
        grid={{ gutter: 10, column: 1 }}
        dataSource={announcements}
        renderItem={(announcement) => (
          <List.Item>
            <Card>
              <Descriptions
                column={3}
                layout="horizontal"
                colon={true}
                size="small"
                bordered
              >
                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Status'}
                    </Typography>
                  }
                >
                  {renderStatusBadge(announcement)}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Type'}
                    </Typography>
                  }
                >
                  {renderTypeTag(announcement)}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>{'ID'}</Typography>
                  }
                >
                  {renderAnnouncmentDetailLink(announcement)}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Starting'}
                    </Typography>
                  }
                >
                  {renderStartingTooltip(announcement)}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Ending'}
                    </Typography>
                  }
                >
                  {renderEndingTooltip(announcement)}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Last Change'}
                    </Typography>
                  }
                >
                  {renderLastChageTooltip(announcement)}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </List.Item>
        )}
      />
    );
  };

  const renderFilter = () => {
    return (
      <Form
        layout="inline"
        style={{ width: '100%' }}
        initialValues={{
          types: [...types],
        }}
      >
        <Form.Item name="showDeleted" valuePropName="showDeleted">
          <Checkbox
            onChange={(e) => {
              const checked = new Boolean(e.target.checked);

              let searchParams = new URLSearchParams(window.location.search);
              searchParams.set('showDeleted', checked.toString());
              let newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?' +
                searchParams.toString();
              window.history.pushState({ path: newurl }, '', newurl);

              setShowDeleted(checked.valueOf());
            }}
            checked={showDeleted}
          >
            {'Show deleted'}
          </Checkbox>
        </Form.Item>

        <Form.Item name="types" label="Filter by Category">
          <Select
            mode="multiple"
            placeholder="nothing selected"
            value={types}
            onChange={(data) => {
              let searchParams = new URLSearchParams(window.location.search);
              searchParams.delete('types');
              data.forEach((d) => searchParams.append('types', d));

              let newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?' +
                searchParams.toString();
              window.history.pushState({ path: newurl }, '', newurl);

              setTypes(data);
            }}
            allowClear={false}
            style={{ minWidth: 300 }}
          >
            <Select.Option value={AnnouncementType.INFO}>
              {'INFO'}
            </Select.Option>
            <Select.Option value={AnnouncementType.MAINTENANCE}>
              {'MAINTENANCE'}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    );
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Announcements"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      {renderFilter()}
      <div className="hide-on-mobile">{renderTable()}</div>
      <div className="hide-on-desktop">{renderList()}</div>
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  announcements: state.announcements.list.announcements,
  loading: state.announcements.list.loading,
  error: state.announcements.list.error,
  total: state.announcements.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAnnouncementsConnect: getAnnouncements,
    },
    dispatch,
  );
};

export const AnnouncementsResponsiveTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedAnnouncementsResponsiveTable);

import React from 'react';
import {
  Table,
  Tag,
  Button,
  Result,
  Typography,
  Row,
  Col,
  Popconfirm,
  notification,
  Modal,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import Column from 'antd/lib/table/Column';
import { SampleType } from '../../model/enums';
import moment from 'moment';
import { deleteSample, getSamples } from '../../duck/modules/samples';
import { SimpleSampleModel } from '../../model/simple';
import { ListSamplesQuery } from '../../model/querys';
import { usePrevious } from '../_util/hook';
import { UpdateSampleForm } from './updateSampleForm';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    reload: boolean;
    onReloaded: () => void;
    cycleId?: string;
    pondId?: string;
    onDeleted?: () => void;
    onDeleteError?: (error: Error) => void;
  };

const UnconnectedSamplesTable: React.FC<Props> = ({
  reload,
  cycleId,
  pondId,
  onReloaded,
  getSamplesConnect,
  deleteSampleConnect,
  sampleListState,
  sampleDeleteState,
  onDeleted,
  onDeleteError,
}) => {
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [
    updateSampleModalVisible,
    setUpdateSampleModalVisible,
  ] = React.useState(false);
  const [updateSampleId, setUpdateSampleId] = React.useState('');

  const prev = usePrevious({
    deleteSuccess: sampleDeleteState.success,
    deleteError: sampleDeleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && sampleDeleteState.success) {
      notification.success({
        message: 'Sample deleted',
      });
    }
    if (sampleDeleteState.success && onDeleted) {
      onDeleted();
    }
  }, [sampleDeleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && sampleDeleteState.error) {
      notification.error({
        message: 'Sample deletion error',
        description: sampleDeleteState.error?.message,
      });
      if (sampleDeleteState.error && onDeleteError) {
        onDeleteError(sampleDeleteState.error);
      }
    }
  }, [sampleDeleteState.error]);

  React.useEffect(() => {
    fetchData();
  }, [offset, limit, reload]);

  const fetchData = () => {
    const query: ListSamplesQuery = { limit, offset };
    if (cycleId) {
      query.cycleIds = [cycleId];
    }
    if (pondId) {
      query.pondIds = [pondId];
    }
    getSamplesConnect(query);
    onReloaded();
  };

  const pagination = () => ({
    total: sampleListState.total || 0,
    pageSize: limit,
    responsive: true,
    showSizeChanger: true,
    showQuickJumper: true,
    defaultPageSize: 10,
    pageSizeOptions: ['5', '25', '50', '100', '200'],
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page: number | undefined, pageSize: number | undefined) => {
      if (page && pageSize) {
        setOffset(pageSize * (page - 1));
        setLimit(pageSize);
      }
    },
  });

  const renderUpdateSampleModal = () => {
    return (
      <Modal
        visible={updateSampleModalVisible}
        onOk={() => setUpdateSampleModalVisible(false)}
        onCancel={() => {
          setUpdateSampleModalVisible(false);
          setUpdateSampleId('');
        }}
        footer={null}
        title={'Update sample'}
        getContainer={false}
      >
        {updateSampleId != '' && (
          <UpdateSampleForm
            sampleId={updateSampleId}
            onUpdated={() => {
              setUpdateSampleModalVisible(false);
              setUpdateSampleId('');
              fetchData();
            }}
            onError={(_error: Error) => {}}
          />
        )}
      </Modal>
    );
  };

  if (sampleListState.error) {
    return (
      <Result
        status="error"
        title="Can't load samples"
        subTitle={sampleListState.error.message}
      />
    );
  }

  return (
    <div>
      {renderUpdateSampleModal()}
      <Table
        bordered
        pagination={pagination()}
        loading={sampleListState.loading}
        rowKey="id"
        dataSource={sampleListState.samples}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Date"
          render={(sample: SimpleSampleModel) => {
            return (
              <Typography>
                {moment(sample.timestamp).format('DD / MM / YYYY')}
              </Typography>
            );
          }}
        />

        <Column
          align="center"
          title="Type"
          render={(sample: SimpleSampleModel) => {
            if (sample.type === SampleType.SHRIMP_BUCKET) {
              return <Tag>Bucket</Tag>;
            } else if (sample.type === SampleType.SHRIMP_LENGTH) {
              return <Tag>Length</Tag>;
            } else if (sample.type === SampleType.SHRIMP_WEIGHT) {
              return <Tag>Weight</Tag>;
            }
            return <Tag>Unknown</Tag>;
          }}
        />

        <Column
          align="center"
          title="Number of shrimps per kg"
          render={(sample: SimpleSampleModel) => {
            return (
              <Typography>{sample.numberOfShrimpsPerKg || '-'}</Typography>
            );
          }}
        />

        <Column
          align="center"
          title="Size of single shrimp"
          render={(sample: SimpleSampleModel) => {
            if (sample.sizeOfShrimpInCm) {
              return (
                <Typography>{`${sample.sizeOfShrimpInCm.toFixed(
                  1,
                )} cm`}</Typography>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Weight of single shrimp"
          render={(sample: SimpleSampleModel) => {
            if (sample.weightOfShrimpInGram) {
              return (
                <Typography>{`${sample.weightOfShrimpInGram.toFixed(
                  1,
                )} g`}</Typography>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Actions"
          render={(sample: SimpleSampleModel) => {
            return (
              <Row justify="center" gutter={10}>
                <Col>
                  <Popconfirm
                    title={'Are you sure to delete this sample?'}
                    onConfirm={() => {
                      deleteSampleConnect(sample.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="default">
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
                <Col>
                  <Button
                    type="default"
                    onClick={() => {
                      setUpdateSampleId(sample.id);
                      setUpdateSampleModalVisible(true);
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            );
          }}
        />
      </Table>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  sampleListState: state.samples.list,
  sampleDeleteState: state.samples.delete,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSamplesConnect: getSamples,
      deleteSampleConnect: deleteSample,
    },
    dispatch,
  );
};

export const SamplesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSamplesTable);

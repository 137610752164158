import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateSmartSensorCmd, UpdateSmartSensorCmd } from '../model/cmds';
import { SmartSensorModel } from '../model/domain';
import {
  ListSmartSensorsQuery,
  ListSmartSensorsQueryModel,
} from '../model/querys';

export class SmartSensorApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createSmartSensor = this.createSmartSensor.bind(this);
    this.fetchSmartSensor = this.fetchSmartSensor.bind(this);
    this.listSmartSensors = this.listSmartSensors.bind(this);
    this.updateSmartSensor = this.updateSmartSensor.bind(this);
  }

  public createSmartSensor(
    cmd: CreateSmartSensorCmd,
  ): Promise<SmartSensorModel> {
    return this.post<
      SmartSensorModel,
      CreateSmartSensorCmd,
      AxiosResponse<SmartSensorModel>
    >(`/v1/smartSensors`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchSmartSensor(smartSensorId: string): Promise<SmartSensorModel> {
    return this.get<SmartSensorModel, AxiosResponse<SmartSensorModel>>(
      `/v1/smartSensors/${smartSensorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listSmartSensors(
    cmd?: ListSmartSensorsQuery,
  ): Promise<ListSmartSensorsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListSmartSensorsQueryModel,
      AxiosResponse<ListSmartSensorsQueryModel>
    >('/v1/smartSensors', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateSmartSensor(
    smartSensorId: string,
    cmd: UpdateSmartSensorCmd,
  ): Promise<SmartSensorModel> {
    return this.patch<
      SmartSensorModel,
      UpdateSmartSensorCmd,
      AxiosResponse<SmartSensorModel>
    >(`/v1/smartSensors/${smartSensorId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const smartSensorApi = new SmartSensorApi();

import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { farmApi } from '../../api/farmApi';
import {
  COUNT_FARMS,
  COUNT_FARMS_FAILED,
  COUNT_FARMS_SUCCESS,
  CREATE_FARM,
  CREATE_FARM_FAILED,
  CREATE_FARM_SUCCESS,
  FETCH_FARM,
  FETCH_FARM_FAILED,
  FETCH_FARM_SUCCESS,
  FETCH_FARMS,
  FETCH_FARMS_FAILED,
  FETCH_FARMS_SUCCESS,
  UPDATE_FARM,
  UPDATE_FARM_FAILED,
  UPDATE_FARM_SUCCESS,
} from '../../constants';
import { CreateFarmCmd, UpdateFarmCmd } from '../../model/cmds';
import { CountFarmsModel, ListFarmsQuery, ListFarmsQueryModel } from '../../model/querys';
import { FarmsState } from '../../model/state';
import { pondApi } from '../../api/pondApi';
import { locationApi } from '../../api/locationApi';
import {
  farmCreate,
  farmCreated,
  farmCreateFailed,
  farmFetch,
  farmFetched,
  farmFetchFailed,
  FarmsAction,
  farmsCount,
  farmsCounted,
  farmsCountFailed,
  farmsFetch,
  farmsFetched,
  farmsFetchFailed,
  farmUpdate,
  farmUpdated,
  farmUpdateFailed,
} from '../actions';

export function createFarm(cmd: CreateFarmCmd) {
  return async (dispatch: Dispatch<FarmsAction, {}, any>) => {
    try {
      dispatch(farmCreate());
      const farm = await farmApi.createFarm(cmd);
      dispatch(farmCreated(farm));
      //dispatch(getFarms({limit: INT_MAX, offset: 0, organisationIds: [cmd.organisationId]}));
    } catch (e) {
      dispatch(farmCreateFailed(e));
    }
  };
}

export function getFarm(id: string) {
  return async (dispatch: Dispatch<FarmsAction, {}, any>) => {
    try {
      dispatch(farmFetch());
      const farm = await farmApi.fetchFarm(id);
      // fetch ponds for the farm
      //const ponds = await Promise.all(farm.ponds.map(pond => pondApi.fetchPond(pond.id)));
      const extendedFarm = {
        ...farm,
        //ponds: ponds,
      };
      if (farm.locationId) {
        extendedFarm.location = await locationApi.fetchLocation(
          farm.locationId,
        );
      }
      dispatch(farmFetched(extendedFarm));
    } catch (e) {
      dispatch(farmFetchFailed(e));
    }
  };
}

export function getFarms(query: ListFarmsQuery) {
  return async (dispatch: Dispatch<FarmsAction, {}, any>) => {
    try {
      dispatch(farmsFetch(query));
      const queryModel: ListFarmsQueryModel = await farmApi.listFarms(query);

      // fetch details for each received farm
      const farms = await Promise.all(
        queryModel.farms.map(async (f) => {
          const farm = await farmApi.fetchFarm(f.id);
          if (farm.locationId) {
            farm.location = await locationApi.fetchLocation(farm.locationId);
          }
          return farm;
        }),
      );

      // fetch details for every pond on a farm
      // const ponds = await Promise.all(farms.flatMap(farm => {
      //   return farm.ponds.map(pond => pondApi.fetchPond(pond.id))
      // }));

      // const extendedFarms = farms.map(farm => {
      //   const farmPonds = farm.ponds.map((pond) =>  ponds.find(p => p.id === pond.id) || pond);
      //   return {
      //     ...farm,
      //     ponds: farmPonds,
      //   }
      // })

      dispatch(farmsFetched(queryModel, farms));
    } catch (e) {
      dispatch(farmsFetchFailed(e));
    }
  };
}

export function updateFarm(id: string, cmd: UpdateFarmCmd) {
  return async (dispatch: Dispatch<FarmsAction, {}, any>) => {
    try {
      dispatch(farmUpdate());
      const response = await farmApi.updateFarm(id, cmd);
      dispatch(farmUpdated(response));
      dispatch(getFarm(id));
    } catch (e) {
      dispatch(farmUpdateFailed(e));
    }
  };
}

export function countFarms() {
  return async (dispatch: Dispatch<FarmsAction, {}, any>) => {
    try {
      dispatch(farmsCount());
      const response: CountFarmsModel = await farmApi.countFarms();
      dispatch(farmsCounted(response));
    } catch (e) {
      dispatch(farmsCountFailed(e));
    }
  };
}

export default function farmsReducer(
  state: FarmsState = {
    create: {
      loading: false,
      error: null,
      farm: null,
    },
    list: {
      loading: false,
      error: null,
      farms: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      farm: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    counts: {
      loading: false,
      error: null,
      active: null,
      inactive: null,
      all: null,
    }
  },
  action: FarmsAction,
): FarmsState {
  switch (action.type) {
    case CREATE_FARM:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, farm: null },
      };
    case CREATE_FARM_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          farm: action.payload,
        },
      };
    case CREATE_FARM_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          farm: null,
        },
      };
    case FETCH_FARM:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          farm: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_FARM_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          farm: action.payload,
        },
      };
    case FETCH_FARM_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          farm: null,
        },
      };
    case UPDATE_FARM:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_FARM_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_FARM_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case FETCH_FARMS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          farms: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_FARMS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          farms: action.payload.farms,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_FARMS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          farms: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case COUNT_FARMS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: true,
          error: null,
          active: null,
          inactive: null,
          all: null,
        }
      };
    case COUNT_FARMS_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: null,
          active: action.payload.counts.active,
          inactive: action.payload.counts.inactive,
          all: action.payload.counts.all,
        }
      };
    case COUNT_FARMS_FAILED:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
          error: action.payload,
          active: null,
          inactive: null,
          all: null,
        }
      };

    default:
      return state;
  }
}

import * as React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import LoggedOutRoute from '../_routes/loggedOutRoute';
import LoggedInRoute from '../_routes/loggedInRoute';

import { Accounts } from './accounts';
import { NotFound } from './notFound';
import { Login } from './login';
import { Profile } from './profile';
import { AccountDetail } from './accountDetail';
import { Organisations } from './organisations';
import { OrganisationDetail } from './organisationDetail';
import { Farms } from './farms';
import { FarmDetail } from './farmDetail';
import { Ponds } from './ponds';
import { PondDetail } from './pondDetail';
import { Gateways } from './gateways';
import { SensorDetail } from './sensorDetail';
import { Notifications } from './notifications';
import { Cycles } from './cycles';
import { CycleDetail } from './cycleDetail';
import { AeratorDetail } from './aeratorDetail';
import { FeedTables } from './feedTables';
import { FeedTableDetail } from './feedTableDetail';
import { CalibrationLogDetail } from './calibrationLogDetail';
import { SmartSensorDetail } from './smartSensorDetail';
import { SmartDevices } from './smartDevices';
import { GatewayDetail } from './gatewayDetail';
import { PumpDetail } from './pumpDetail';
import { ProductCatalog } from './productCatalog';
import { ProductDetail } from './productDetail';
import { ManufacturerDetail } from './manufacturerDetail';
import { Announcements } from './announcements';
import { AnnouncementDetail } from './announcementDetail';
import { PopUps } from './popUps';
import { PopUpDetail } from './popUpDetails';
import { SensorCaps } from './sensorCaps';
import { SensorCapDetail } from './sensorCapDetail';
import { Tickets } from './tickets';
import { TicketDetail } from './ticketDetail';
import { ShrimpSize } from './shrimpSize';

const Pages = () => {

  const history = useHistory();

  return (
    <Switch>
      <LoggedOutRoute path="/" exact={true} component={Login} />
      <LoggedInRoute
        path="/accounts"
        exact={true}
        component={Accounts}
        headerTitle="Accounts"
      />
      <LoggedInRoute
        path="/accounts/:accountId"
        exact={true}
        component={AccountDetail}
        headerTitle="Account Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/organisations/:organisationId"
        exact={true}
        component={OrganisationDetail}
        headerTitle="Organisation Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/farms/:farmId"
        exact={true}
        component={FarmDetail}
        headerTitle="Farm Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/ponds/:pondId"
        exact={true}
        component={PondDetail}
        headerTitle="Pond Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/aerators/:aeratorId"
        exact={true}
        component={AeratorDetail}
        headerTitle="Aerator Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/gateways"
        exact={true}
        component={Gateways}
        headerTitle="Gateways"
      />
      <LoggedInRoute
        path="/organisations"
        exact={true}
        component={Organisations}
        headerTitle="Organisations"
      />
      <LoggedInRoute
        path="/farms"
        exact={true}
        component={Farms}
        headerTitle="Farms"
      />
      <LoggedInRoute
        path="/ponds"
        exact={true}
        component={Ponds}
        headerTitle="Ponds"
      />
      <LoggedInRoute
        path="/shrimp-size"
        exact={true}
        component={ShrimpSize}
        headerTitle="Shrimp Size"
      />
      <LoggedInRoute
        path="/profile"
        exact={true}
        component={Profile}
        headerTitle="Profile"
      />
      <LoggedInRoute
        path="/sensors/:sensorId"
        exact={true}
        component={SensorDetail}
        headerTitle="Sensor Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/notifications"
        exact={true}
        component={Notifications}
        headerTitle="Notifications"
      />
      <LoggedInRoute
        path="/cycles"
        exact={true}
        component={Cycles}
        headerTitle="Cycles"
      />
      <LoggedInRoute
        path="/cycles/:cycleId"
        exact={true}
        component={CycleDetail}
        headerTitle="Cycle Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/feedTables"
        exact={true}
        component={FeedTables}
        headerTitle="Feed Tables"
      />
      <LoggedInRoute
        path="/feedTables/:feedTableId"
        exact={true}
        component={FeedTableDetail}
        headerTitle="Feed Table Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/calibrationLogs/:calibrationLogId"
        exact={true}
        component={CalibrationLogDetail}
        headerTitle="Calibration Log Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/smartSensors/:smartSensorId"
        exact={true}
        component={SmartSensorDetail}
        headerTitle="Smart Sensor Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/smartDevices"
        exact={true}
        component={SmartDevices}
        headerTitle="Smart Devices"
      />
      <LoggedInRoute
        path="/gateways/:gatewayId"
        exact={true}
        component={GatewayDetail}
        headerTitle="Gateway Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/pumps/:pumpId"
        exact={true}
        component={PumpDetail}
        headerTitle="Pump Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/catalog"
        exact={true}
        component={ProductCatalog}
        headerTitle="Product Catalog"
      />
      <LoggedInRoute
        path="/products/:productId"
        exact={true}
        component={ProductDetail}
        headerTitle="Product Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/manufacturers/:manufacturerId"
        exact={true}
        component={ManufacturerDetail}
        headerTitle="Manufacturer Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/announcements"
        exact={true}
        component={Announcements}
        headerTitle="Announcements"
      />
      <LoggedInRoute
        path="/announcements/:announcementId"
        exact={true}
        component={AnnouncementDetail}
        headerTitle="Announcement Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/popUps"
        exact={true}
        component={PopUps}
        headerTitle="Pop Ups"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/popUps/:popUpId"
        exact={true}
        component={PopUpDetail}
        headerTitle="Pop Up Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/sensorCaps"
        exact={true}
        component={SensorCaps}
        headerTitle="Sensor Caps"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/sensorCaps/:sensorCapId"
        exact={true}
        component={SensorCapDetail}
        headerTitle="Sensor Cap Detail"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/tickets"
        exact={true}
        component={Tickets}
        headerTitle="Tickets"
        onBack={() => history.goBack()}
      />
      <LoggedInRoute
        path="/tickets/:ticketId"
        exact={true}
        component={TicketDetail}
        headerTitle="Ticket Detail"
        onBack={() => history.goBack()}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Pages;

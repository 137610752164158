import { blue, green, gold, red } from '@ant-design/colors';

export const functionalColor = {
  // https://ant.design/docs/spec/colors#Functional-Color
  link: blue[5], // blue-6
  success: green[5], // green-6
  warning: gold[5], // gold-6
  error: red[4], // red-5
  primaryText: 'rgba(0, 0, 0, 0.85)',
  secondaryText: 'rgba(0, 0, 0, 0.45)',
};

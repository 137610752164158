import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import {
  CreateOrganisationCmd,
  UpdateOrganisationCmd,
  AddAccountToOrganisationCmd,
  AddGatewayToOrganisationCmd,
  AddSmartSensorToOrganisationCmd,
  AddSmartSensorToGatewayCmd,
  AddLcmToOrganisationCmd,
} from '../model/cmds';
import { LcmModel, OrganisationModel, SmartSensorModel } from '../model/domain';
import {
  CountOrganisationsModel,
  ListOrganisationsQuery,
  ListOrganisationsQueryModel,
} from '../model/querys';

export class OrganisationApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createOrganisation = this.createOrganisation.bind(this);
    this.fetchOrganisation = this.fetchOrganisation.bind(this);
    this.listOrganisations = this.listOrganisations.bind(this);
    this.updateOrganisation = this.updateOrganisation.bind(this);
    this.addMemberToOrganisation = this.addMemberToOrganisation.bind(this);
    this.removeAccountFromOrganisation = this.removeAccountFromOrganisation.bind(this);
    this.addGatewayToOrganisation = this.addGatewayToOrganisation.bind(this);
    this.removeGatewayFromOrganisation = this.removeGatewayFromOrganisation.bind(this);
    this.addSmartSensorToOrganisation = this.addSmartSensorToOrganisation.bind(this);
    this.removeSmartSensorFromOrganisation = this.removeSmartSensorFromOrganisation.bind(this);
    this.addLcmToOrganisation = this.addLcmToOrganisation.bind(this);
    this.removeLcmFromOrganisation = this.removeLcmFromOrganisation.bind(this);
    this.countOrganisations = this.countOrganisations.bind(this);
  }

  public createOrganisation(
    cmd: CreateOrganisationCmd,
  ): Promise<OrganisationModel> {
    return this.post<
      OrganisationModel,
      CreateOrganisationCmd,
      AxiosResponse<OrganisationModel>
    >('/v1/organisations', cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchOrganisation(organisationId: string): Promise<OrganisationModel> {
    return this.get<OrganisationModel, AxiosResponse<OrganisationModel>>(
      `/v1/organisations/${organisationId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listOrganisations(
    cmd?: ListOrganisationsQuery,
  ): Promise<ListOrganisationsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListOrganisationsQueryModel,
      AxiosResponse<ListOrganisationsQueryModel>
    >('/v1/organisations', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateOrganisation(
    organisationId: string,
    cmd: UpdateOrganisationCmd,
  ): Promise<OrganisationModel> {
    return this.patch<
      OrganisationModel,
      UpdateOrganisationCmd,
      AxiosResponse<OrganisationModel>
    >(`/v1/organisations/${organisationId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addMemberToOrganisation(
    organisationId: string,
    cmd: AddAccountToOrganisationCmd,
  ) {
    return this.post<
      OrganisationModel,
      AddAccountToOrganisationCmd,
      AxiosResponse<OrganisationModel>
    >(`/v1/organisations/${organisationId}/members`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeAccountFromOrganisation(
    organisationId: string,
    accountId: string,
  ) {
    return this.delete<void, AxiosResponse<void>>(
      `/v1/organisations/${organisationId}/members/${accountId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addGatewayToOrganisation(
    organisationId: string,
    cmd: AddGatewayToOrganisationCmd,
  ) {
    return this.post<
      OrganisationModel,
      AddGatewayToOrganisationCmd,
      AxiosResponse<OrganisationModel>
    >(`/v1/organisations/${organisationId}/gateways`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeGatewayFromOrganisation(
    organisationId: string,
    gatewayId: string,
  ) {
    return this.delete<OrganisationModel, AxiosResponse<OrganisationModel>>(
      `/v1/organisations/${organisationId}/gateways/${gatewayId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addSmartSensorToOrganisation(
    organisationId: string,
    cmd: AddSmartSensorToOrganisationCmd,
  ) {
    return this.post<
      OrganisationModel,
      AddSmartSensorToOrganisationCmd,
      AxiosResponse<OrganisationModel>
    >(`/v1/organisations/${organisationId}/smartSensors`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeSmartSensorFromOrganisation(
    organisationId: string,
    smartSensorId: string,
  ) {
    return this.delete<SmartSensorModel, AxiosResponse<SmartSensorModel>>(
      `/v1/organisations/${organisationId}/smartSensors/${smartSensorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addLcmToOrganisation(
    organisationId: string,
    cmd: AddLcmToOrganisationCmd,
  ) {
    return this.post<
      OrganisationModel,
      AddLcmToOrganisationCmd,
      AxiosResponse<OrganisationModel>
    >(`/v1/organisations/${organisationId}/lcms`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeLcmFromOrganisation(organisationId: string, lcmId: string) {
    return this.delete<LcmModel, AxiosResponse<LcmModel>>(
      `/v1/organisations/${organisationId}/lcms/${lcmId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public countOrganisations(): Promise<CountOrganisationsModel> {
    const config = getConfig();
    return this.get<
      CountOrganisationsModel,
      AxiosResponse<CountOrganisationsModel>
    >('/v1/count/organisations', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }


}

export const organisationApi = new OrganisationApi();

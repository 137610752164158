import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Card,
  Descriptions,
  Table,
  Tag,
  Form,
  Tooltip,
  Badge,
  Select,
  Statistic,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getSensorCaps } from '../../duck/modules/sensorCaps';
import Column from 'antd/lib/table/Column';
import { SimpleSensorCapModel } from '../../model/simple';
import { functionalColor } from '../../colors';
import { Option } from 'antd/lib/mentions';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import _ from 'lodash';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      showDeleted?: string;
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedSensorCapsResponsiveTable: React.FC<Props> = ({
  sensorCaps,
  loading,
  error,
  total,
  getSensorCapsConnect,
  queryParams,
  reload,
  onReloaded,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  const [showDeleted, setShowDeleted] = React.useState(
    queryParams?.showDeleted === 'true' || false,
  );

  React.useEffect(() => {
    getSensorCapsConnect({
      limit,
      offset,
      isDeleted: showDeleted,
    });
    onReloaded();
  }, [offset, limit, reload, showDeleted]);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderList = () => {
    return (
      <List
        loading={loading}
        grid={{ gutter: 10, column: 1 }}
        dataSource={sensorCaps}
        renderItem={(sensorCap) => (
          <List.Item>
            <Card>
              <Descriptions
                column={3}
                layout="horizontal"
                colon={true}
                size="small"
                bordered
              >
                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Status'}
                    </Typography>
                  }
                >
                  {!sensorCap.isDeleted ? (
                    <Badge color={functionalColor.success} text="Active" />
                  ) : (
                    <Badge color={functionalColor.error} text="Deactivated" />
                  )}
                  <Link key={sensorCap.id} to={`/sensorCaps/${sensorCap.id}`}>
                    {JSON.stringify(sensorCap)}
                  </Link>
                </Descriptions.Item>
                {/* 
                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'SensorCap'}
                    </Typography>
                  }
                >
                  <Link key={sensorCap.id} to={`/sensorCaps/${sensorCap.id}`}>
                    {sensorCap.name != undefined
                      ? sensorCap.name
                      : sensorCap.id}
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Manufacturer'}
                    </Typography>
                  }
                >
                  {sensorCap.manufacturer ? (
                    <Link
                      key={sensorCap.id}
                      to={`/manufacturers/${sensorCap.manufacturer.id}`}
                    >
                      {sensorCap.manufacturer.name || sensorCap.manufacturer.id}
                    </Link>
                  ) : (
                    <Typography>{'-'}</Typography>
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Category'}
                    </Typography>
                  }
                >
                  {sensorCap.category ? (
                    <Tag>{sensorCap.category}</Tag>
                  ) : (
                    <Typography>{'-'}</Typography>
                  )}
                </Descriptions.Item> */}
              </Descriptions>
            </Card>
          </List.Item>
        )}
      />
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={sensorCaps}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Serial No."
          render={(sensorCap: SimpleSensorCapModel) => (
            <Link key={sensorCap.id} to={`/sensorCaps/${sensorCap.id}`}>
              {sensorCap.serialNo}
            </Link>
          )}
        />
        <Column
          align="center"
          title="K0"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k0 != undefined ? (
              <Statistic value={sensorCap.k0} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K1"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k1 != undefined ? (
              <Statistic value={sensorCap.k1} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K2"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k2 != undefined ? (
              <Statistic value={sensorCap.k2} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K3"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k3 != undefined ? (
              <Statistic value={sensorCap.k3} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K4"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k4 != undefined ? (
              <Statistic value={sensorCap.k4} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K5"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k5 != undefined ? (
              <Statistic value={sensorCap.k5} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K6"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k6 != undefined ? (
              <Statistic value={sensorCap.k6} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K7"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k7 != undefined ? (
              <Statistic value={sensorCap.k7} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
        <Column
          align="center"
          title="K8"
          render={(sensorCap: SimpleSensorCapModel) =>
            sensorCap.k8 != undefined ? (
              <Statistic value={sensorCap.k8} />
            ) : (
              <Typography>{'-'}</Typography>
            )
          }
        />
      </Table>
    );
  };

  const renderFilter = () => {
    return (
      <Form layout="inline" style={{ width: '100%' }} initialValues={{}}>
        <Form.Item name="showDeleted" valuePropName="showDeleted">
          <Checkbox
            onChange={(e) => {
              const checked = new Boolean(e.target.checked);

              let searchParams = new URLSearchParams(window.location.search);
              searchParams.set('showDeleted', checked.toString());
              let newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?' +
                searchParams.toString();
              window.history.pushState({ path: newurl }, '', newurl);

              setShowDeleted(checked.valueOf());
            }}
            checked={showDeleted}
          >
            {'View Deleted'}
          </Checkbox>
        </Form.Item>
      </Form>
    );
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load SensorCaps"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      {renderFilter()}
      <div className="hide-on-mobile">{renderTable()}</div>
      <div className="hide-on-desktop">{renderList()}</div>
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  sensorCaps: state.sensorCaps.list.sensorCaps,
  loading: state.sensorCaps.list.loading,
  error: state.sensorCaps.list.error,
  total: state.sensorCaps.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSensorCapsConnect: getSensorCaps,
    },
    dispatch,
  );
};

export const SensorCapsResponsiveTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSensorCapsResponsiveTable);

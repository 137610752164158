import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateSmartRelayCmd, UpdateSmartRelayCmd } from '../model/cmds';
import { SmartRelayModel } from '../model/domain';
import {
  ListSmartRelaysQuery,
  ListSmartRelaysQueryModel,
} from '../model/querys';

export class SmartRelayApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createSmartRelay = this.createSmartRelay.bind(this);
    this.fetchSmartRelay = this.fetchSmartRelay.bind(this);
    this.listSmartRelays = this.listSmartRelays.bind(this);
    this.updateSmartRelay = this.updateSmartRelay.bind(this);
  }

  public createSmartRelay(cmd: CreateSmartRelayCmd): Promise<SmartRelayModel> {
    return this.post<
      SmartRelayModel,
      CreateSmartRelayCmd,
      AxiosResponse<SmartRelayModel>
    >(`/v1/smartRelays`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchSmartRelay(smartRelayId: string): Promise<SmartRelayModel> {
    return this.get<SmartRelayModel, AxiosResponse<SmartRelayModel>>(
      `/v1/smartRelays/${smartRelayId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listSmartRelays(
    cmd?: ListSmartRelaysQuery,
  ): Promise<ListSmartRelaysQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListSmartRelaysQueryModel,
      AxiosResponse<ListSmartRelaysQueryModel>
    >('/v1/smartRelays', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateSmartRelay(
    smartRelayId: string,
    cmd: UpdateSmartRelayCmd,
  ): Promise<SmartRelayModel> {
    return this.patch<
      SmartRelayModel,
      UpdateSmartRelayCmd,
      AxiosResponse<SmartRelayModel>
    >(`/v1/smartRelays/${smartRelayId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const smartRelayApi = new SmartRelayApi();

import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  notification,
  Select,
  Space,
  Typography,
  DatePicker
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import _ from 'lodash';

import { UpdateControllerCmd } from '../../model/cmds';
import { ControllerModel } from '../../model/domain';
import {
  ControllerProductVersion,
  ControllerRelayboardVersion,
  ControllerUsage,
} from '../../model/enums';
import { usePrevious } from '../_util/hook';
import moment from 'moment';

const { Option } = Select;


type Props = {
  controller?: ControllerModel;
  onUpdate: (cmd: UpdateControllerCmd) => void;
  loading?: boolean;
  updating: boolean;
};

export const ControllerDataForm: React.FC<Props> = ({
  controller,
  loading,
  updating,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const [dateOfInstallation, setDateOfInstallation] = React.useState(undefined);
  const [endOfWarranty, setEndOfWarranty] = React.useState(undefined);

  React.useEffect(() => {

    if (controller) {


      form.setFieldsValue({
        //...controller,
        // works without this line
        productVersion: controller?.productVersion,
        relayboardVersion:
          controller?.relayboardVersion?.requested ||
          controller?.relayboardVersion?.actual,
        // hardware components
        mainboardSerialNumber:
          controller?.hardwareComponents.mainboardSerialNumber,
        relayboardSerialNumber:
          controller?.hardwareComponents.relayboardSerialNumber,
        displayboardSerialNumber:
          controller?.hardwareComponents.displayboardSerialNumber,
        lcmSerialNumber: controller?.hardwareComponents.lcmSerialNumber,
        usage: controller?.usage,
        dateOfInstallation: controller?.dateOfInstallation ? moment(controller?.dateOfInstallation) : undefined,
        endOfWarranty: controller?.endOfWarranty ? moment(controller?.endOfWarranty) : undefined,
      });

      setDateOfInstallation(controller?.dateOfInstallation ? moment(controller?.dateOfInstallation) : undefined)
      setEndOfWarranty(controller?.endOfWarranty ? moment(controller?.endOfWarranty) : undefined)


    }
  }, [controller]);

  const onSubmit = (values: Store) => {

    const {
      productVersion,
      relayboardVersion,
      mainboardSerialNumber,
      relayboardSerialNumber,
      displayboardSerialNumber,
      lcmSerialNumber,
    } = values;
    const cmd: UpdateControllerCmd = {};

    if (productVersion !== controller?.productVersion) {
      cmd.productVersion = productVersion;
    }

    if (relayboardVersion !== controller?.relayboardVersion) {
      cmd.relayboardVersion = relayboardVersion;
    }

    if (
      mainboardSerialNumber !==
      controller?.hardwareComponents.mainboardSerialNumber
    ) {
      cmd.hardwareComponents = {
        ...cmd.hardwareComponents,
        mainboardSerialNumber,
      };
    }

    if (
      relayboardSerialNumber !==
      controller?.hardwareComponents.relayboardSerialNumber
    ) {
      cmd.hardwareComponents = {
        ...cmd.hardwareComponents,
        relayboardSerialNumber,
      };
    }

    if (
      displayboardSerialNumber !==
      controller?.hardwareComponents.displayboardSerialNumber
    ) {
      cmd.hardwareComponents = {
        ...cmd.hardwareComponents,
        displayboardSerialNumber,
      };
    }

    if (lcmSerialNumber !== controller?.hardwareComponents.lcmSerialNumber) {
      cmd.hardwareComponents = {
        ...cmd.hardwareComponents,
        lcmSerialNumber,
      };
    }

    if (dateOfInstallation) {
      const doi = dateOfInstallation.format('YYYY-MM-DD');
      if (doi !== controller?.dateOfInstallation) {
        cmd.dateOfInstallation = doi;
      }
    }

    if (endOfWarranty) {
      const eow = endOfWarranty.format('YYYY-MM-DD');
      if (endOfWarranty !== controller?.endOfWarranty) {
        cmd.endOfWarranty = eow;
      }
    }

    // nothing changed
    if (Object.keys(cmd).length === 0 && controller) {
      cmd.productVersion = controller.productVersion;
      cmd.relayboardVersion = controller.relayboardVersion?.actual;
      cmd.hardwareComponents = {
        mainboardSerialNumber:
          controller.hardwareComponents.mainboardSerialNumber,
        relayboardSerialNumber:
          controller.hardwareComponents.relayboardSerialNumber,
        displayboardSerialNumber:
          controller.hardwareComponents.displayboardSerialNumber,
        lcmSerialNumber: controller.hardwareComponents.lcmSerialNumber,
      };
      cmd.dateOfInstallation = controller.dateOfInstallation;
      cmd.endOfWarranty = controller.endOfWarranty;
      cmd.usage = controller.usage;
    }

    onUpdate(cmd);
  };

  const renderCardContent = () => {

    if (controller && !_.isEmpty(controller)) {
      return (
        <Form form={form} layout="vertical" onFinish={onSubmit}
        >
          <Form.Item
            label="Product Version"
            name="productVersion"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select Product Version" allowClear>
              <Option value={ControllerProductVersion.V0}>{'V0'}</Option>
              <Option value={ControllerProductVersion.V1}>{'V1'}</Option>
              <Option value={ControllerProductVersion.V2}>{'V2'}</Option>
              <Option value={ControllerProductVersion.V3}>{'V3'}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Relayboard Version"
            name="relayboardVersion"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select Relayboard Version" allowClear>
              <Option value={ControllerRelayboardVersion.V1}>{'v1'}</Option>
              <Option value={ControllerRelayboardVersion.V3}>{'v3'}</Option>
              <Option value={ControllerRelayboardVersion.V3_LATCHING}>
                {'v3 Latching'}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item label="Mainboard S/N" name="mainboardSerialNumber">
            <Input placeholder="Serial number of the Mainboard" />
          </Form.Item>

          <Form.Item label="Relayboard S/N" name="relayboardSerialNumber">
            <Input placeholder="Serial number of the Relayboard" />
          </Form.Item>

          <Form.Item label="Displayboard S/N" name="displayboardSerialNumber">
            <Input placeholder="Serial number of the Displayboard" />
          </Form.Item>

          <Form.Item label="LCM S/N" name="lcmSerialNumber">
            <Input placeholder="Serial number of the Lora Communication Module" />
          </Form.Item>

          <Form.Item
            label="Usage"
            name="usage"
            rules={[{ required: false }]}
          >
            <Select placeholder="Select Usage">
              <Option value={ControllerUsage.SMART_SHRIMP_FARMING}>{'SMART_SHRIMP_FARMING'}</Option>
              <Option value={ControllerUsage.SMART_FISH_FARMING}>{'SMART_FISH_FARMING'}</Option>
              <Option value={ControllerUsage.INDUSTRIAL}>{'INDUSTRIAL'}</Option>
              <Option value={ControllerUsage.RESEARCH_AND_DEVELOPMENT}>{'RESEARCH_AND_DEVELOPMENT'}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Date of Installation"
            name="dateOfInstallation"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD / MM / YYYY'}
              placeholder="Date of Installation (optional)"
              onChange={(date, _) => { setDateOfInstallation(date) }}
            />
          </Form.Item>



          <Form.Item
            label="End of Warranty"
            name="endOfWarranty"
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD / MM / YYYY'}
              placeholder="End of Warranty (optional)"
              onChange={(date, _) => { setEndOfWarranty(date) }}
            />
          </Form.Item>


          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={updating}>
                Update Controller
              </Button>
            </Space>
          </Form.Item>


        </Form>


      );
    }
    return (
      <div>
        <Form form={form} /> <Empty description={<span>No Data</span>} />
      </div>
    );
  };

  return (
    <Card title="Controller Data" loading={loading} style={{ height: '100%' }}>
      {renderCardContent()}
    </Card>
  );
};

import {
  Button,
  Col,
  Descriptions,
  Modal,
  notification,
  Result,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { functionalColor } from '../../colors';
import { RootState } from '../../duck';
import { synchronizeGateway } from '../../duck/modules/gateways';
import { refreshPond } from '../../duck/modules/ponds';
import { getOrganisationSensors } from '../../duck/modules/sensing';
import { GatewayModel, PondModel } from '../../model/domain';
import { MqttConnectionStatus } from '../../model/enums';
import { usePrevious } from '../_util/hook';
import { AssignPondToGatewayForm } from '../gateway/assignPondToGatewayForm';
import { Link } from 'react-router-dom';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pond: PondModel;
  };

export const UnconnectedPondGatewayHierarchy: React.FC<Props> = ({
  pond,
  gatewayAddPondState,
  gatewayRemovePondState,
}) => {
  const [showAssignGatewayModal, setShowAssignGatewayModal] = React.useState(
    false,
  );

  const prev = usePrevious({
    gatewayAddPondState,
    gatewayRemovePondState,
  });

  React.useEffect(() => {
    if (
      prev?.gatewayAddPondState.loading &&
      !gatewayAddPondState.loading &&
      !gatewayAddPondState.error
    ) {
      setShowAssignGatewayModal(false);
      notification.success({
        message: 'Pond assigned to Gateway',
      });
    }
  }, [gatewayAddPondState]);

  React.useEffect(() => {
    if (
      prev?.gatewayAddPondState.loading &&
      !gatewayAddPondState.loading &&
      gatewayAddPondState.error
    ) {
      notification.error({
        message: 'Error while assigning pond to Gateway',
        description: gatewayAddPondState.error.message,
      });
    }
  }, [gatewayAddPondState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemovePondState.loading &&
      !gatewayRemovePondState.loading &&
      !gatewayRemovePondState.error
    ) {
      notification.success({
        message: 'Pond removed from Gateway',
      });
    }
  }, [gatewayRemovePondState]);

  React.useEffect(() => {
    if (
      prev?.gatewayRemovePondState.loading &&
      !gatewayRemovePondState.loading &&
      gatewayRemovePondState.error
    ) {
      notification.error({
        message: 'Error while removing pond from Gateway',
        description: gatewayRemovePondState.error.message,
      });
    }
  }, [gatewayRemovePondState]);

  const getSynchronizedTag = (inSync: boolean) => {
    if (inSync) {
      return <Tag color={functionalColor.success}>Synchronized</Tag>;
    }
    return <Tag color={functionalColor.warning}>Syncing</Tag>;
  };

  const getConnectionTooltop = (gateway: GatewayModel) => {
    if (gateway.mqttConnectionStatus === MqttConnectionStatus.CONNECTED) {
      return <Tag color={functionalColor.success}>Online</Tag>;
    }
    return (
      <Tooltip title={moment(gateway.mqttConnectionTimestamp).fromNow()}>
        <span>
          <Tag color={functionalColor.error}>Offline</Tag>
        </span>
      </Tooltip>
    );
  };

  const renderGatewayInfoContent = () => {
    const { gateway } = pond;
    if (gateway) {
      return (
        <Descriptions bordered column={{ sm: 1, md: 3 }}>
          <Descriptions.Item label="Connection">
            {getConnectionTooltop(gateway)}
          </Descriptions.Item>
          <Descriptions.Item label="Ponds">
            {getSynchronizedTag(gateway.ponds.inSync)}
          </Descriptions.Item>
          <Descriptions.Item label="Sensors">
            {getSynchronizedTag(gateway.sensors.inSync)}
          </Descriptions.Item>
          <Descriptions.Item label="Aerators">
            {getSynchronizedTag(gateway.aerators.inSync)}
          </Descriptions.Item>
          <Descriptions.Item label="LCMs">
            {getSynchronizedTag(gateway.lcms.inSync)}
          </Descriptions.Item>
        </Descriptions>
      );
    }
  };

  const renderAssignGatewayModal = () => {
    return (
      <Modal
        visible={showAssignGatewayModal}
        onOk={() => setShowAssignGatewayModal(false)}
        onCancel={() => setShowAssignGatewayModal(false)}
        footer={null}
        title={'Assing pond to gateway'}
      >
        <AssignPondToGatewayForm
          organisationId={pond.organisation.id}
          pondId={pond.id}
          onSuccess={() => {
            refreshPond(pond.id);
            setShowAssignGatewayModal(false);
          }}
        />
      </Modal>
    );
  };

  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item label="Organisation">
        <Link to={`/organisations/${pond.organisation.id}`}>
          {pond.organisation.name}
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label="Farm">
        <Link to={`/farms/${pond.farm.id}`}>{pond.farm.name}</Link>
      </Descriptions.Item>
      <Descriptions.Item label="Gateway">
        {pond.gateway ? (
          <Row justify="space-between">
            <Link to={`/gateways/${pond.gateway.id}`}>
              {pond.gateway.name?.actual || pond.gateway.id}
            </Link>
            {pond.gateway.mqttConnectionStatus ===
            MqttConnectionStatus.CONNECTED ? (
              <Tag color={functionalColor.success}>Online</Tag>
            ) : (
              <Tooltip
                title={moment(pond.gateway.mqttConnectionTimestamp).fromNow()}
              >
                <span>
                  <Tag color={functionalColor.error}>Offline</Tag>
                </span>
              </Tooltip>
            )}
          </Row>
        ) : (
          <div>
            {renderAssignGatewayModal()}
            <Result
              status="info"
              title="In order to manage devices, a pond must have a gateway!"
              extra={
                <Button
                  type="primary"
                  onClick={() => setShowAssignGatewayModal(true)}
                >
                  {'Assing Gateway'}
                </Button>
              }
            />
          </div>
        )}
      </Descriptions.Item>
    </Descriptions>
  );

  if (pond.gateway) {
    return <div>{renderGatewayInfoContent()}</div>;
  }

  return (
    <div>
      {renderAssignGatewayModal()}
      <Result
        status="info"
        title="In order to manage devices, a pond must have a gateway!"
        extra={
          <Button
            type="primary"
            onClick={() => setShowAssignGatewayModal(true)}
          >
            {'Assing Gateway'}
          </Button>
        }
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  gatewayAddPondState: state.gateways.addPond,
  gatewayRemovePondState: state.gateways.removePond,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationSensorsConnect: getOrganisationSensors,
      refreshPondConnect: refreshPond,
      synchronizeGatewayConnect: synchronizeGateway,
    },
    dispatch,
  );
};

export const PondGatewayHierarchy = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPondGatewayHierarchy);

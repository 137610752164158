import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { locationApi } from '../../api/locationApi';
import {
  FETCH_LOCATION,
  FETCH_LOCATION_FAILED,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATIONS_SUCCESS,
} from '../../constants';
import {
  ListLocationsQuery,
  ListLocationsQueryModel,
} from '../../model/querys';
import { LocationsState } from '../../model/state';
import {
  locationFetch,
  locationFetched,
  locationFetchFailed,
  LocationsAction,
  locationsFetch,
  locationsFetched,
  locationsFetchFailed,
} from '../actions';

export function getLocation(id: string) {
  return async (dispatch: Dispatch<LocationsAction, {}, any>) => {
    try {
      dispatch(locationFetch());
      const location = await locationApi.fetchLocation(id);
      dispatch(locationFetched(location));
    } catch (e) {
      dispatch(locationFetchFailed(e));
    }
  };
}

export function getLocations(query: ListLocationsQuery) {
  return async (dispatch: Dispatch<LocationsAction, {}, any>) => {
    try {
      dispatch(locationsFetch(query));
      const queryModel: ListLocationsQueryModel = await locationApi.listLocations(
        query,
      );
      dispatch(locationsFetched(queryModel, queryModel.locations));
    } catch (e) {
      dispatch(locationsFetchFailed(e));
    }
  };
}

export default function locationsReducer(
  state: LocationsState = {
    list: {
      loading: false,
      error: null,
      locations: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      location: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: LocationsAction,
): LocationsState {
  switch (action.type) {
    case FETCH_LOCATION:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          location: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          location: action.payload,
        },
      };
    case FETCH_LOCATION_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          location: null,
        },
      };

    case FETCH_LOCATIONS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          locations: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          locations: action.payload.locations,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_LOCATIONS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          locations: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Select,
  Typography,
  Row,
  Col,
  DatePicker,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { CycleModel } from '../../model/domain';
import { CycleState } from '../../model/enums';
import { UpdateCycleCmd } from '../../model/cmds';
import { usePrevious } from '../_util/hook';
import moment from 'moment';
import { getDayOfCultivation } from '../../util';

const { Option } = Select;

type Props = {
  cycle: CycleModel | null;
  onUpdate: (cmd: UpdateCycleCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
  doc?: number;
};

export const CycleDataForm: React.FC<Props> = ({
  cycle,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError });
  const [dayOfCultivation, setDayOfCultivation] = React.useState(0);

  React.useEffect(() => {
    form.setFieldsValue({ ...cycle });
    if (cycle?.startTimestamp) {
      setDayOfCultivation(
        getDayOfCultivation(
          cycle.startTimestamp,
          cycle.endTimestamp,
        ),
      );
    }
  }, [cycle]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Cycle updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating cycle',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const { state, start, end } = values;
    const cmd: UpdateCycleCmd = {};

    if (state !== cycle?.state) {
      cmd.state = state;
    }

    if (start && !moment(start).isSame(cycle?.startTimestamp, 'day')) {
      cmd.startTimestamp =
        moment.utc(start).format('YYYY-MM-DD') + 'T00:00:00Z';
    }

    if (end && !moment(end).isSame(cycle?.endTimestamp, 'day')) {
      cmd.endTimestamp = moment.utc(end).format('YYYY-MM-DD') + 'T00:00:00Z';
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && cycle) {
      cmd.state = cycle.state;
      cmd.startTimestamp = cycle.startTimestamp;
      cmd.endTimestamp = cycle.endTimestamp;
    }
    onUpdate(cmd);
  };

  return (
    <Form
      form={form}
      initialValues={{
        state: cycle?.state,
        start: moment(cycle?.startTimestamp),
        end: cycle?.endTimestamp && moment(cycle?.endTimestamp),
      }}
      onValuesChange={(_cvs, vs) =>
        setDayOfCultivation(getDayOfCultivation(vs.start, vs.end))
      }
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item label="State" name="state">
        <Select allowClear placeholder="Select location">
          <Option value={CycleState.ACTIVE}>{'Active'}</Option>
          <Option value={CycleState.DONE}>{'Done'}</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Start of cycle" name="start">
        <DatePicker
          style={{ width: '100%' }}
          format={'DD / MM / YYYY'}
          placeholder="Day of cultivation"
          disabledDate={(d) => d.isAfter(moment())}
        />
      </Form.Item>

      <Form.Item label="End of cycle" name="end">
        <DatePicker
          style={{ width: '100%' }}
          format={'DD / MM / YYYY'}
          placeholder="End of cycle"
          disabledDate={(d) =>
            d.isBefore(form.getFieldValue('start')) || d.isAfter(moment())
          }
        />
      </Form.Item>

      <Form.Item>
        <Row justify="space-between">
          <Col>
            <Space>
              <Button type="primary" htmlType="submit" loading={updating}>
                Update Cycle
              </Button>
              {cycle?.state === CycleState.ACTIVE && (
                <Button
                  danger
                  type="primary"
                  onClick={() => onUpdate({ state: CycleState.DONE })}
                  loading={updating}
                >
                  End Cycle
                </Button>
              )}
            </Space>
          </Col>
          <Col>
            <Typography>{`DoC: ${dayOfCultivation}`}</Typography>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

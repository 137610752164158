import { Card, Descriptions, Empty, Typography } from 'antd';
import React from 'react';
import _ from 'lodash';

import { NetworkInformation } from '../../model/domain';

type Props = {
  networkInformation?: NetworkInformation;
  loading?: boolean;
};

export const NetworkInformationCard: React.FC<Props> = ({
  networkInformation,
  loading,
}) => {
  const renderCardContent = () => {
    if (networkInformation && !_.isEmpty(networkInformation)) {
      return (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="IMEI">
            <Typography.Text>{networkInformation.imei}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="IMSI">
            <Typography.Text>{networkInformation.imsi}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="ICCID">
            <Typography.Text>{networkInformation.iccid}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Operator">
            <Typography.Text>{networkInformation.operator}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="RSSI">
            <Typography.Text>{networkInformation.rssi}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="BER">
            <Typography.Text>{networkInformation.ber}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Access Technology">
            <Typography.Text>
              {networkInformation.accessTechnology}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Band">
            <Typography.Text>{networkInformation.band}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Channel">
            <Typography.Text>{networkInformation.channel}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      );
    }
    return <Empty description={<span>No Network Data </span>} />;
  };
  return (
    <Card
      title="Network Information"
      loading={loading}
      style={{ height: '100%' }}
    >
      {renderCardContent()}
    </Card>
  );
};

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateProductCmd, UpdateProductCmd } from '../model/cmds';
import { ProductModel } from '../model/domain';
import { ListProductsQuery, ListProductsQueryModel } from '../model/querys';

export class ProductApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createProduct = this.createProduct.bind(this);
    this.getProduct = this.getProduct.bind(this);
    this.listProducts = this.listProducts.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
  }

  public createProduct(cmd: CreateProductCmd): Promise<ProductModel> {
    return this.post<
      ProductModel,
      CreateProductCmd,
      AxiosResponse<ProductModel>
    >(`/v1/products`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getProduct(productId: string): Promise<ProductModel> {
    return this.get<ProductModel, AxiosResponse<ProductModel>>(
      `/v1/products/${productId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listProducts(
    cmd?: ListProductsQuery,
  ): Promise<ListProductsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListProductsQueryModel,
      AxiosResponse<ListProductsQueryModel>
    >('/v1/products', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateProduct(
    productId: string,
    cmd: UpdateProductCmd,
  ): Promise<ProductModel> {
    return this.patch<
      ProductModel,
      UpdateProductCmd,
      AxiosResponse<ProductModel>
    >(`/v1/products/${productId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public deleteProduct(productId: string): Promise<ProductModel> {
    return this.delete<ProductModel, AxiosResponse<ProductModel>>(
      `/v1/products/${productId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const productApi = new ProductApi();

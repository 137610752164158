import React from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  notification,
  Select,
  Tooltip,
  Typography,
  AutoComplete,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { GatewaySensorModel, SensorModel } from '../../model/domain';
import { UpdateSensorCmd } from '../../model/cmds';
import { nameRule } from '../_util/validation';
import { usePrevious } from '../_util/hook';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { functionalColor } from '../../colors';
import {
  DeviceSocket,
  SensingMode,
  SensorConnectionType,
  SensorType,
} from '../../model/enums';
import { getDeviceSockets } from '../../util';
import { SimpleSensorCapModel } from '../../model/simple';
import Item from 'antd/lib/list/Item';

const { Option } = Select;

type Props = {
  sensor: SensorModel;
  onUpdate: (cmd: UpdateSensorCmd) => void;
  updating: boolean;
  updateSuccess: boolean | null;
  updateError: Error | null;
  smartSensorOptions: GatewaySensorModel[];
  sensorCapOptions: SimpleSensorCapModel[];
};

export const SensorEntityForm: React.FC<Props> = ({
  sensor,
  updating,
  updateSuccess,
  updateError,
  onUpdate,
  smartSensorOptions,
  sensorCapOptions,
}) => {
  const [form] = Form.useForm();
  const prev = usePrevious({ updateSuccess, updateError, sensor });
  const [formValues, setFormValues] = React.useState(form.getFieldsValue);
  React.useEffect(() => {
    if (sensor != prev?.sensor) {
      form.setFieldsValue({
        ...sensor,
        name: sensor.name
          ? sensor.name.inSync
            ? sensor.name.actual
            : sensor.name.requested
          : '',
        deviceSocket: sensor.deviceSocket.inSync
          ? sensor.deviceSocket.actual
          : sensor.deviceSocket.requested,
        mode: sensor.mode.inSync ? sensor.mode.actual : sensor.mode.requested,
        intervalInSec: sensor.timeBasedMode.inSync
          ? sensor.timeBasedMode.actual.intervalInSec
          : sensor.timeBasedMode.requested?.intervalInSec,
        connectionType: sensor.connectionType.inSync
          ? sensor.connectionType.actual
          : sensor.connectionType?.requested,
        smartSensorId:
          sensor.smartSensor != null
            ? sensor.smartSensor.requested?.id || sensor.smartSensor.actual.id
            : '',
        sensorCapValues:
          sensor.sensorCapValues != null
            ? sensor.sensorCapValues.requested || sensor.sensorCapValues.actual
            : '',
      });
      setFormValues(form.getFieldsValue());
    }
  }, [sensor, updateSuccess, updateError]);

  React.useEffect(() => {
    if (prev?.updateSuccess === null && updateSuccess) {
      notification.success({
        message: 'Sensor updated',
      });
    }
  }, [updateSuccess]);

  React.useEffect(() => {
    if (prev?.updateError === null && updateError) {
      notification.error({
        message: 'Error while updating sensor',
        description: updateError?.message,
      });
    }
  }, [updateError]);

  const onSubmit = (values: Store) => {
    const {
      name,
      deviceSocket,
      mode,
      intervalInSec,
      connectionType,
      smartSensorId,
      sensorCapValues,
    } = values;

    const cmd: UpdateSensorCmd = {};

    if (name !== sensor.name?.actual) {
      cmd.name = name;
    }

    if (deviceSocket !== sensor.deviceSocket.actual) {
      cmd.deviceSocket = deviceSocket;
    }

    if (mode !== sensor.mode.actual) {
      cmd.mode = mode;
    }

    if (connectionType !== sensor.connectionType.actual) {
      cmd.connectionType = connectionType;
    }

    if (
      connectionType === SensorConnectionType.SMART_SENSOR &&
      smartSensorId !== sensor.smartSensor?.actual.id
    ) {
      cmd.smartSensorId = smartSensorId;
    }

    // if (
    //   connectionType === SensorConnectionType.MAIN_BOARD &&
    //   connectionType !== sensor.connectionType.actual
    // ) {
    //   cmd.smartSensorId = 'null';
    // }

    const interval = parseInt(intervalInSec);
    if (
      mode === SensingMode.TIME_BASED &&
      interval !== sensor.timeBasedMode.actual.intervalInSec
    ) {
      cmd.timeBasedMode = { intervalInSec: interval };
    }

    if (smartSensorId !== sensor.smartSensor?.actual.id) {
      cmd.smartSensorId = smartSensorId;
    }

    if (
      sensorCapValues !== '' &&
      sensor.sensorCapValues != null &&
      sensorCapValues !== sensor.sensorCapValues.actual
    ) {
      cmd.sensorCapValuesId = sensorCapValues;
    }

    // nothing changed
    if (Object.keys(cmd).length == 0 && sensor) {
      cmd.name = sensor.name && (sensor.name.requested || sensor.name.actual);
      cmd.deviceSocket =
        sensor.deviceSocket &&
        (sensor.deviceSocket.requested || sensor.deviceSocket.actual);
      cmd.mode = sensor.mode && (sensor.mode.requested || sensor.mode.actual);
      cmd.connectionType =
        sensor.connectionType &&
        (sensor.connectionType.requested || sensor.connectionType.actual);
      cmd.smartSensorId =
        sensor.smartSensor &&
        (sensor.smartSensor.requested?.id || sensor.smartSensor.actual.id);
      cmd.timeBasedMode = sensor.timeBasedMode && {
        intervalInSec:
          sensor.timeBasedMode.requested?.intervalInSec ||
          sensor.timeBasedMode.actual?.intervalInSec,
      };
      cmd.sensorCapValuesId =
        sensor.sensorCapValues &&
        (sensor.sensorCapValues.requested || sensor.sensorCapValues.actual);
    }

    onUpdate(cmd);
  };

  const isOnboard =
    sensor.type == SensorType.OMS || sensor.type == SensorType.OCS;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onValuesChange={() => setFormValues(form.getFieldsValue())}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[nameRule, { required: false }]}
      >
        <Input
          placeholder="Name of the sensor"
          suffix={
            sensor.name?.inSync ? (
              <Tooltip title={'Synchronized'}>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            ) : (
              <Tooltip title={`Syncing...`}>
                <WarningOutlined style={{ color: functionalColor.warning }} />
              </Tooltip>
            )
          }
        />
      </Form.Item>

      {!isOnboard && (
        <Form.Item
          label="Socket"
          name="deviceSocket"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select socket for the device"
            suffixIcon={
              sensor.deviceSocket?.inSync ? (
                <Tooltip title={'Synchronized'}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Syncing...`}>
                  <WarningOutlined style={{ color: functionalColor.warning }} />
                </Tooltip>
              )
            }
          >
            {getDeviceSockets().map((deviceSocket) => (
              <Option key={deviceSocket} value={deviceSocket}>
                {deviceSocket}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {!isOnboard && (
        <Form.Item label="Mode" name="mode" rules={[{ required: true }]}>
          <Select
            placeholder="Select socket for the device"
            suffixIcon={
              sensor.mode?.inSync ? (
                <Tooltip title={'Synchronized'}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Syncing...`}>
                  <WarningOutlined style={{ color: functionalColor.warning }} />
                </Tooltip>
              )
            }
          >
            <Option value={SensingMode.NOT_SET}>{'Deactived'}</Option>
            <Option value={SensingMode.TIME_BASED}>{'Time based'}</Option>
          </Select>
        </Form.Item>
      )}

      {!isOnboard && (
        <Form.Item
          label="Connection Type"
          name="connectionType"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select connection type"
            suffixIcon={
              sensor.connectionType?.inSync ? (
                <Tooltip title={'Synchronized'}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Syncing...`}>
                  <WarningOutlined style={{ color: functionalColor.warning }} />
                </Tooltip>
              )
            }
          >
            <Option value={SensorConnectionType.NOT_SET}>{'Not set'}</Option>

            <Option value={SensorConnectionType.MAIN_BOARD}>
              {'Mainboard'}
            </Option>
            <Option value={SensorConnectionType.SMART_SENSOR}>
              {'Smart Sensor'}
            </Option>
          </Select>
        </Form.Item>
      )}

      {!isOnboard &&
        form.getFieldValue('connectionType') ===
          SensorConnectionType.SMART_SENSOR && (
          <Form.Item
            label={
              <Space direction="horizontal">
                <Typography>{'Smart Sensor ID'} </Typography>
                {sensor.smartSensor &&
                  (sensor.smartSensor.inSync ? (
                    <Tooltip title={'Synchronized'}>
                      <InfoCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Syncing...`}>
                      <WarningOutlined
                        style={{ color: functionalColor.warning }}
                      />
                    </Tooltip>
                  ))}
              </Space>
            }
            name="smartSensorId"
            rules={[
              {
                required:
                  form.getFieldValue('connectionType') ===
                  SensorConnectionType.SMART_SENSOR,
              },
            ]}
          >
            <AutoComplete
              placeholder="Enter ID of smart sensor"
              options={smartSensorOptions.map((option) => {
                return { lable: option.id, value: option.id };
              })}
              suffixIcon={
                sensor.smartSensor?.inSync ? (
                  <Tooltip title={'Synchronized'}>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={`Syncing...`}>
                    <WarningOutlined
                      style={{ color: functionalColor.warning }}
                    />
                  </Tooltip>
                )
              }
            />
          </Form.Item>
        )}

      {!isOnboard && form.getFieldValue('mode') === SensingMode.TIME_BASED && (
        <Form.Item
          label="Interval in seconds"
          name="intervalInSec"
          rules={[
            { required: form.getFieldValue('mode') === SensingMode.TIME_BASED },
          ]}
        >
          <Input
            placeholder="After how many seconds should a new value be sent?"
            type="number"
            suffix={
              sensor.timeBasedMode?.inSync ? (
                <Tooltip title={'Synchronized'}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Syncing...`}>
                  <WarningOutlined style={{ color: functionalColor.warning }} />
                </Tooltip>
              )
            }
          />
        </Form.Item>
      )}

      {sensor.type === SensorType.YDS && (
        <Form.Item
          name="sensorCapValues"
          label="Sensor Cap"
          rules={[{ required: false, message: 'Selected sensor cap' }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            filterOption
            placeholder="Select sensor cap"
            options={
              sensorCapOptions.map((option) => {
                return {
                  label: `${option.serialNo}`,
                  value: option.id,
                };
              }) || []
            }
            suffixIcon={
              sensor.sensorCapValues?.inSync ? (
                <Tooltip title={'Synchronized'}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Syncing...`}>
                  <WarningOutlined style={{ color: functionalColor.warning }} />
                </Tooltip>
              )
            }
          />
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={updating}>
            Update Sensor
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import {
  AeratorAutomation,
  AeratorType,
  CalibrationState,
  ControllerProductVersion,
  DeviceSocket,
  RelayboardId,
  SensorType,
  SmartRelayboardId,
} from './model/enums';
import { GatewayModel } from './model/domain';
import { RelayboardIdOption } from './model/util';
import moment from 'moment';
import { functionalColor } from './colors';
import { getConfig } from './api/util/apiConfig';

export const getSensorNameToType = (type: SensorType) => {
  switch (type) {
    case SensorType.YDS:
      return 'DO-1000D';
    case SensorType.IDS:
      return 'DO-1010D';
    case SensorType.SAP:
      return 'PH-1000A';
    case SensorType.SES:
      return 'SES';
    case SensorType.YSS:
      return 'YSS';
    case SensorType.OCS:
      return 'OCS';
    case SensorType.OMS:
      return 'OMS';
    case SensorType.NDP:
      return 'SD-14A16';
    case SensorType.STOF:
      return 'STOF';
    case SensorType.LUX:
      return 'LUX';
    default:
      return 'UNKNOWN SENSOR';
  }
};

export const getNameToAeratorType = (type: AeratorType) => {
  switch (type) {
    case AeratorType.BUBBLES:
      return 'Bubbels';
    case AeratorType.NANO_BUBBLES:
      return 'Nano Bubbels';
    case AeratorType.PADDLE_WHEEL:
      return 'Paddle Wheel';
    case AeratorType.SUBMERGED_PROPELLER:
      return 'Submerged Propeller';
    case AeratorType.SUBMERGED_TURBINE:
      return 'Submerged Turbine';
    default:
      return 'Not set';
  }
};

export const getNameToRelayboardId = (
  type: RelayboardId | SmartRelayboardId,
) => {
  switch (type) {
    case RelayboardId.RELAY_1 || SmartRelayboardId.RELAY_1:
      return 'Relay 1';
    case RelayboardId.RELAY_2 || SmartRelayboardId.RELAY_2:
      return 'Relay 2';
    case RelayboardId.RELAY_3:
      return 'Relay 3';
    case RelayboardId.RELAY_4:
      return 'Relay 4';
    case RelayboardId.RELAY_5:
      return 'Relay 5';
    case RelayboardId.RELAY_6:
      return 'Relay 6';
    case RelayboardId.RELAY_7:
      return 'Relay 7';
    case RelayboardId.RELAY_8:
      return 'Relay 8';
    case RelayboardId.RELAY_9:
      return 'Relay 9';
    case RelayboardId.RELAY_10:
      return 'Relay 10';
    case RelayboardId.RELAY_11:
      return 'Relay 11';
    case RelayboardId.RELAY_12:
      return 'Relay 12';
    case RelayboardId.RELAY_13:
      return 'Relay 13';
    case RelayboardId.RELAY_14:
      return 'Relay 14';
    case RelayboardId.RELAY_15:
      return 'Relay 15';
    case RelayboardId.RELAY_16:
      return 'Relay 16';
    default:
      return 'Not set';
  }
};

export const getDayOfCultivation = (
  startTimestamp: string,
  endTimestamp?: string,
): number => {
  const start = moment.utc(startTimestamp).format('YYYY-MM-DD');
  const end = moment.utc(endTimestamp).format('YYYY-MM-DD');
  return moment(end).diff(moment(start).startOf('day'), 'days') + 1;
};

export const getRelayboardIdOptions = (
  gateway: GatewayModel,
): RelayboardIdOption[] => {
  let options = [
    { label: 'Relay 1', value: RelayboardId.RELAY_1 },
    { label: 'Relay 2', value: RelayboardId.RELAY_2 },
    { label: 'Relay 3', value: RelayboardId.RELAY_3 },
    { label: 'Relay 4', value: RelayboardId.RELAY_4 },
    { label: 'Relay 5', value: RelayboardId.RELAY_5 },
    { label: 'Relay 6', value: RelayboardId.RELAY_6 },
    { label: 'Relay 7', value: RelayboardId.RELAY_7 },
    { label: 'Relay 8', value: RelayboardId.RELAY_8 },
    { label: 'Relay 9', value: RelayboardId.RELAY_9 },
    { label: 'Relay 10', value: RelayboardId.RELAY_10 },
    { label: 'Relay 11', value: RelayboardId.RELAY_11 },
    { label: 'Relay 12', value: RelayboardId.RELAY_12 },
  ];

  let isVersion0 =
    gateway.controller.productVersion === ControllerProductVersion.V0;
  let isVersion1 =
    gateway.controller.productVersion === ControllerProductVersion.V1;

  if (!isVersion0) {
    options.push({ label: 'Relay 13', value: RelayboardId.RELAY_13 });
    options.push({ label: 'Relay 14', value: RelayboardId.RELAY_14 });
    options.push({ label: 'Relay 15', value: RelayboardId.RELAY_15 });
  }

  if (!(isVersion0 || isVersion1)) {
    options.push({ label: 'Relay 16', value: RelayboardId.RELAY_16 });
  }

  const usedRelayboardIds = [
    ...gateway.aerators.actual.map((a) => a.relayboardId),
    ...gateway.pumps.actual.map((p) => p.relayboardId),
  ];
  options = options.filter((o) => !usedRelayboardIds.includes(o.value));
  return options;
};

export const getDeviceSockets = (): DeviceSocket[] => {
  return [
    DeviceSocket.ANALOG_1,
    DeviceSocket.ANALOG_2,
    DeviceSocket.ANALOG_3,
    DeviceSocket.ANALOG_4,
    DeviceSocket.EC_1,
    DeviceSocket.DIGITAL_1,
    DeviceSocket.DIGITAL_2,
    DeviceSocket.DIGITAL_3,
    DeviceSocket.DIGITAL_4,
    DeviceSocket.DIGITAL_5,
    DeviceSocket.DIGITAL_6,
    DeviceSocket.DIGITAL_7,
    DeviceSocket.DIGITAL_8,
    DeviceSocket.DIGITAL_9,
    DeviceSocket.DIGITAL_10,
    DeviceSocket.DIGITAL_15,
    DeviceSocket.DIGITAL_16,
    DeviceSocket.DIGITAL_17,
    DeviceSocket.DIGITAL_18,
    DeviceSocket.DIGITAL_19,
    DeviceSocket.DIGITAL_20,
    DeviceSocket.DIGITAL_22,
    DeviceSocket.DIGITAL_23,
    DeviceSocket.DIGITAL_24,
    DeviceSocket.DIGITAL_25,
    DeviceSocket.DIGITAL_26,
    DeviceSocket.DIGITAL_27,
    DeviceSocket.DIGITAL_28,
    DeviceSocket.DIGITAL_29,
    DeviceSocket.DIGITAL_30,
    DeviceSocket.DIGITAL_31,
    DeviceSocket.DIGITAL_32,
    DeviceSocket.DIGITAL_33,
    DeviceSocket.DIGITAL_34,
    DeviceSocket.DIGITAL_35,
    DeviceSocket.DIGITAL_36,
    DeviceSocket.DIGITAL_37,
    DeviceSocket.DIGITAL_38,
    DeviceSocket.DIGITAL_39,
    DeviceSocket.DIGITAL_40,
    DeviceSocket.DIGITAL_41,
    DeviceSocket.DIGITAL_42,
    DeviceSocket.DIGITAL_43,
    DeviceSocket.DIGITAL_44,
    DeviceSocket.DIGITAL_45,
    DeviceSocket.DIGITAL_46,
    DeviceSocket.DIGITAL_47,
    DeviceSocket.DIGITAL_48,
    DeviceSocket.DIGITAL_49,
    DeviceSocket.DIGITAL_50,
    DeviceSocket.DIGITAL_51,
    DeviceSocket.DIGITAL_52,
    DeviceSocket.DIGITAL_53,
    DeviceSocket.DIGITAL_54,
    DeviceSocket.DIGITAL_55,
    DeviceSocket.DIGITAL_56,
    DeviceSocket.DIGITAL_57,
    DeviceSocket.DIGITAL_58,
    DeviceSocket.DIGITAL_59,
    DeviceSocket.DIGITAL_60,
    DeviceSocket.DIGITAL_61,
    DeviceSocket.DIGITAL_62,
    DeviceSocket.DIGITAL_63,
    DeviceSocket.DIGITAL_64,
    DeviceSocket.DIGITAL_65,
    DeviceSocket.DIGITAL_66,
    DeviceSocket.DIGITAL_67,
    DeviceSocket.DIGITAL_68,
    DeviceSocket.DIGITAL_69,
    DeviceSocket.DIGITAL_70,
    DeviceSocket.DIGITAL_71,
    DeviceSocket.DIGITAL_72,
    DeviceSocket.DIGITAL_73,
    DeviceSocket.DIGITAL_74,
    DeviceSocket.DIGITAL_75,
    DeviceSocket.DIGITAL_76,
    DeviceSocket.DIGITAL_77,
    DeviceSocket.DIGITAL_78,
    DeviceSocket.DIGITAL_79,
    DeviceSocket.DIGITAL_80,
    DeviceSocket.DIGITAL_81,
    DeviceSocket.DIGITAL_82,
    DeviceSocket.DIGITAL_83,
    DeviceSocket.DIGITAL_84,
    DeviceSocket.DIGITAL_85,
    DeviceSocket.DIGITAL_86,
    DeviceSocket.DIGITAL_87,
    DeviceSocket.DIGITAL_88,
    DeviceSocket.DIGITAL_89,
    DeviceSocket.DIGITAL_90,
    DeviceSocket.DIGITAL_91,
    DeviceSocket.DIGITAL_92,
    DeviceSocket.DIGITAL_93,
    DeviceSocket.DIGITAL_94,
    DeviceSocket.DIGITAL_95,
    DeviceSocket.DIGITAL_96,
    DeviceSocket.DIGITAL_97,
    DeviceSocket.DIGITAL_98,
    DeviceSocket.DIGITAL_99,
    DeviceSocket.DIGITAL_100,
    DeviceSocket.DIGITAL_101,
    DeviceSocket.DIGITAL_102,
    DeviceSocket.DIGITAL_103,
    DeviceSocket.DIGITAL_104,
    DeviceSocket.DIGITAL_105,
    DeviceSocket.DIGITAL_106,
    DeviceSocket.DIGITAL_107,
    DeviceSocket.DIGITAL_108,
    DeviceSocket.DIGITAL_109,
    DeviceSocket.DIGITAL_110,
    DeviceSocket.DIGITAL_111,
    DeviceSocket.DIGITAL_112,
    DeviceSocket.DIGITAL_113,
    DeviceSocket.DIGITAL_114,
    DeviceSocket.DIGITAL_115,
    DeviceSocket.DIGITAL_116,
    DeviceSocket.DIGITAL_117,
    DeviceSocket.DIGITAL_118,
    DeviceSocket.DIGITAL_119,
    DeviceSocket.DIGITAL_120,
    DeviceSocket.DIGITAL_121,
    DeviceSocket.DIGITAL_122,
    DeviceSocket.DIGITAL_123,
    DeviceSocket.DIGITAL_124,
    DeviceSocket.DIGITAL_125,
    DeviceSocket.DIGITAL_126,
    DeviceSocket.DIGITAL_127,
    DeviceSocket.DIGITAL_128,
    DeviceSocket.DIGITAL_129,
    DeviceSocket.DIGITAL_130,
    DeviceSocket.DIGITAL_131,
    DeviceSocket.DIGITAL_132,
    DeviceSocket.DIGITAL_133,
    DeviceSocket.DIGITAL_134,
    DeviceSocket.DIGITAL_135,
    DeviceSocket.DIGITAL_136,
    DeviceSocket.DIGITAL_137,
    DeviceSocket.DIGITAL_138,
    DeviceSocket.DIGITAL_139,
    DeviceSocket.DIGITAL_140,
    DeviceSocket.DIGITAL_141,
    DeviceSocket.DIGITAL_142,
    DeviceSocket.DIGITAL_143,
    DeviceSocket.DIGITAL_144,
    DeviceSocket.DIGITAL_145,
    DeviceSocket.DIGITAL_146,
    DeviceSocket.DIGITAL_147,
    DeviceSocket.DIGITAL_148,
    DeviceSocket.DIGITAL_149,
    DeviceSocket.DIGITAL_150,
    DeviceSocket.DIGITAL_151,
    DeviceSocket.DIGITAL_152,
    DeviceSocket.DIGITAL_153,
    DeviceSocket.DIGITAL_154,
    DeviceSocket.DIGITAL_155,
    DeviceSocket.DIGITAL_156,
    DeviceSocket.DIGITAL_157,
    DeviceSocket.DIGITAL_158,
    DeviceSocket.DIGITAL_159,
    DeviceSocket.DIGITAL_160,
    DeviceSocket.DIGITAL_161,
    DeviceSocket.DIGITAL_162,
    DeviceSocket.DIGITAL_163,
    DeviceSocket.DIGITAL_164,
    DeviceSocket.DIGITAL_165,
    DeviceSocket.DIGITAL_166,
    DeviceSocket.DIGITAL_167,
    DeviceSocket.DIGITAL_168,
    DeviceSocket.DIGITAL_169,
    DeviceSocket.DIGITAL_170,
    DeviceSocket.DIGITAL_171,
    DeviceSocket.DIGITAL_172,
    DeviceSocket.DIGITAL_173,
    DeviceSocket.DIGITAL_174,
    DeviceSocket.DIGITAL_175,
    DeviceSocket.DIGITAL_176,
    DeviceSocket.DIGITAL_177,
    DeviceSocket.DIGITAL_178,
    DeviceSocket.DIGITAL_179,
    DeviceSocket.DIGITAL_180,
    DeviceSocket.DIGITAL_181,
    DeviceSocket.DIGITAL_182,
    DeviceSocket.DIGITAL_183,
    DeviceSocket.DIGITAL_184,
    DeviceSocket.DIGITAL_185,
    DeviceSocket.DIGITAL_186,
    DeviceSocket.DIGITAL_187,
    DeviceSocket.DIGITAL_188,
    DeviceSocket.DIGITAL_189,
    DeviceSocket.DIGITAL_190,
    DeviceSocket.DIGITAL_191,
    DeviceSocket.DIGITAL_192,
    DeviceSocket.DIGITAL_193,
    DeviceSocket.DIGITAL_194,
    DeviceSocket.DIGITAL_195,
    DeviceSocket.DIGITAL_196,
    DeviceSocket.DIGITAL_197,
    DeviceSocket.DIGITAL_198,
    DeviceSocket.DIGITAL_199,
    DeviceSocket.DIGITAL_200,
    DeviceSocket.DIGITAL_201,
    DeviceSocket.DIGITAL_202,
    DeviceSocket.DIGITAL_203,
    DeviceSocket.DIGITAL_204,
    DeviceSocket.DIGITAL_205,
    DeviceSocket.DIGITAL_206,
    DeviceSocket.DIGITAL_207,
    DeviceSocket.DIGITAL_208,
    DeviceSocket.DIGITAL_209,
    DeviceSocket.DIGITAL_210,
    DeviceSocket.DIGITAL_211,
    DeviceSocket.DIGITAL_212,
    DeviceSocket.DIGITAL_213,
    DeviceSocket.DIGITAL_214,
    DeviceSocket.DIGITAL_215,
    DeviceSocket.DIGITAL_216,
    DeviceSocket.DIGITAL_217,
    DeviceSocket.DIGITAL_218,
    DeviceSocket.DIGITAL_219,
    DeviceSocket.DIGITAL_220,
    DeviceSocket.DIGITAL_221,
    DeviceSocket.DIGITAL_222,
    DeviceSocket.DIGITAL_223,
    DeviceSocket.DIGITAL_224,
    DeviceSocket.DIGITAL_225,
    DeviceSocket.DIGITAL_226,
    DeviceSocket.DIGITAL_227,
    DeviceSocket.DIGITAL_228,
    DeviceSocket.DIGITAL_229,
    DeviceSocket.DIGITAL_230,
    DeviceSocket.DIGITAL_231,
    DeviceSocket.DIGITAL_232,
    DeviceSocket.DIGITAL_233,
    DeviceSocket.DIGITAL_234,
    DeviceSocket.DIGITAL_235,
    DeviceSocket.DIGITAL_236,
    DeviceSocket.DIGITAL_237,
    DeviceSocket.DIGITAL_238,
    DeviceSocket.DIGITAL_239,
    DeviceSocket.DIGITAL_240,
    DeviceSocket.DIGITAL_241,
    DeviceSocket.DIGITAL_242,
    DeviceSocket.DIGITAL_243,
    DeviceSocket.DIGITAL_244,
    DeviceSocket.DIGITAL_245,
    DeviceSocket.DIGITAL_246,
    DeviceSocket.DIGITAL_247,
    DeviceSocket.DIGITAL_248,
    DeviceSocket.DIGITAL_249,
    DeviceSocket.DIGITAL_250,
    DeviceSocket.DIGITAL_251,
    DeviceSocket.DIGITAL_252,
    DeviceSocket.DIGITAL_253,
    DeviceSocket.DIGITAL_254,
  ];
};

export const chartjsTimeAxisOptions = {
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          displayFormats: {
            millisecond: 'h:mm:ss.SSS a',
            second: 'h:mm:ss a',
            minute: 'MMM D - h:mm a',
            hour: 'hA',
            day: 'MMM D',
            week: 'll',
            month: 'MMM YYYY',
            quarter: '[Q]Q - YYYY',
            year: 'YYYY',
          },
          tooltipFormat: 'LLL',
        },
        ticks: {
          source: 'auto',
        },
      },
    ],
  },
  plugins: [
    {
      beforeUpdate: function (chart: any) {
        chart.data.labels.forEach(function (e: any, i: any, a: any) {
          if (/\n/.test(e)) {
            a[i] = e.split(/\n/);
          }
        });
      },
    },
  ],
};

export const getDeepLinkForAppImpersonate = (token: string): string => {
  switch (getEnvironment()) {
    case 'PRODUCTION':
      return `hydroneo://titan-app/impersonate?token=${token}&url=backend.hydroneo.net&ssl=true`;
    case 'STAGING':
      return `hydroneo://titan-app/impersonate?token=${token}&url=backend-staging.hydroneo.net&ssl=true`;
    case 'DEVELOPMENT':
      return `hydroneo://titan-app/impersonate?token=${token}`;
  }
};

export const getNavbarColor = () => {
  switch (getEnvironment()) {
    case 'PRODUCTION':
      return '#111d2c';
    case 'STAGING':
      return invertColor('#111d2c');
    case 'DEVELOPMENT':
      return functionalColor.warning;
  }
};

export const getNavbarTextColor = () => {
  return invertColor(getNavbarColor());
};

export const invertColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
};

const padZero = (str: string, len?: number) => {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

export const getEnvironment = () => {
  switch (getConfig().baseURL) {
    case 'https://backend.hydroneo.de/':
    case 'https://backend.hydroneo.net/':
      return 'PRODUCTION';
    case 'https://backend-staging.hydroneo.net/':
      return 'STAGING';
    default:
      return 'DEVELOPMENT';
  }
};

export enum SensorValueType {
  DO_VALUE_PPM = 'DO_VALUE',
  DO_VALUE_PROCENT = 'DO_VALUE',
  WATER_TEMPERATURE = 'WATER_TEMPERATURE',
  PH_VALUE = 'PH_VALUE',
  AMPERE_VALUE = 'AMPERE_VALUE',
  KILOWATT_VALUE = 'KILOWATT_VALUE',
  WATER_SALINITY = 'WATER_SALINITY',
  CPU_TEMPERATURE = 'CPU_TEMPERATURE',
  MAINBOARD_TEMPERATURE = 'MAINBOARD_TEMPERATURE',
  LUX_VALUE = 'LUX_VALUE',
  HUMIDITY_VALUE = 'HUMIDITY_VALUE',
  LUX_TEMPERATURE = 'LUX_TEMPERATURE',
}

export const getColorForSensorValue = (
  sensorValueType: SensorValueType,
  timestamp: string,
  value?: number,
) => {
  const thresholds = {
    doPPM: {
      lowCritical: 2.5,
      lowReminder: 4,
    },
    doPercent: {
      lowCritical: 75,
      lowReminder: 80,
    },
    waterTemperature: {
      lowCritical: 20,
      lowReminder: 22,
    },
    ph: {
      lowCritical: 6.5,
      lowReminder: 7,
      highReminder: 8.7,
      highCritical: 9,
    },
    mainboardTemperature: {
      highReminder: 80,
      highCritical: 90,
    },
    cpuTemperature: {
      highReminder: 80,
      highCritical: 90,
    },
  };

  // 1. check data
  if (value != null) {
    switch (sensorValueType) {
      case SensorValueType.DO_VALUE_PPM:
        if (value < thresholds.doPPM.lowCritical) {
          return functionalColor.error;
        }
        if (value < thresholds.doPPM.lowReminder) {
          return functionalColor.warning;
        }
        break;
      case SensorValueType.DO_VALUE_PROCENT:
        if (value < thresholds.doPercent.lowCritical) {
          return functionalColor.error;
        }
        if (value < thresholds.doPercent.lowReminder) {
          return functionalColor.warning;
        }
        break;
      case SensorValueType.WATER_TEMPERATURE:
        if (value < thresholds.waterTemperature.lowCritical) {
          return functionalColor.error;
        }
        if (value < thresholds.waterTemperature.lowReminder) {
          return functionalColor.warning;
        }
        break;
      case SensorValueType.PH_VALUE:
        if (value < thresholds.ph.lowCritical) {
          return functionalColor.error;
        }
        if (value < thresholds.ph.lowReminder) {
          return functionalColor.warning;
        }
        if (value > thresholds.ph.highCritical) {
          return functionalColor.error;
        }
        if (value > thresholds.ph.highReminder) {
          return functionalColor.warning;
        }
        break;
      case SensorValueType.CPU_TEMPERATURE:
        if (value > thresholds.cpuTemperature.highCritical) {
          return functionalColor.error;
        }
        if (value > thresholds.cpuTemperature.highReminder) {
          return functionalColor.warning;
        }
        return functionalColor.primaryText; // dont color based on timestamp
      case SensorValueType.MAINBOARD_TEMPERATURE:
        if (value > thresholds.mainboardTemperature.highCritical) {
          return functionalColor.error;
        }
        if (value > thresholds.mainboardTemperature.highReminder) {
          return functionalColor.warning;
        }
        return functionalColor.primaryText; // dont color based on timestamp
      default:
        break;
    }
  }

  // 2. check timestamp
  const measurementTimestamp = moment(timestamp);

  // when data is older than 30 minutes
  if (measurementTimestamp.isBefore(moment().subtract(30, 'minutes'))) {
    return functionalColor.secondaryText;
  }

  return functionalColor.primaryText;
};

export const getShortNameToAeration = (automation: AeratorAutomation) => {
  switch (automation) {
    case AeratorAutomation.FULL_AUTOMATION:
      return 'Full';
    case AeratorAutomation.MANUAL:
      return 'Manual';
    case AeratorAutomation.PARTIAL_AUTOMATION:
      return 'Partial';
    case AeratorAutomation.ON_SITE:
      return 'On Site';
    case AeratorAutomation.NOT_SET:
      return 'NOT_SET';
    default:
      return '???';
  }
};

export const getNameToCalibrationState = (state: CalibrationState) => {
  switch (state) {
    case CalibrationState.NOT_SET:
      return 'Not set';
    case CalibrationState.FAILURE:
      return 'Failure';
    case CalibrationState.NO_ANSWER_IN_TIME:
      return 'No answer in time';
    case CalibrationState.PENDING:
      return 'Pending';
    case CalibrationState.REMOTE_STARTED:
      return 'Remote started';
    case CalibrationState.SUCCESS:
      return 'Success';
    default:
      return 'UNKNOWN CALIBRATION STATE';
  }
};

export const getEstimatedShrimpSizeInPcsPerKg = (doc: number): number => {
  return getReferencePcsPerKilogramForDoC(doc);
};

const getReferenceWeightInGramForDoC = (doc: number) => {
  return 0.0109 * Math.pow(doc, 1.7274);
};
const getReferencePcsPerKilogramForDoC = (doc: number) => {
  const weightPerPcs = getReferenceWeightInGramForDoC(doc);
  return 1000.0 / weightPerPcs;
};


export const mapObjectValues = (obj = {}) => {
  for (var key in obj) {
    var val = obj[key];

    if (val === "false") {
      obj[key] = false;
    }
    else if (val === "true") {
      obj[key] = true;
    }
  }
}

export function boolFromStringOtherwiseNull(s) {
  if (s == 'true') return true
  if (s == 'false') return false
  return null
}
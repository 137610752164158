import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import { CreateNotificationCmd } from '../model/cmds';
import { NotificationModel } from '../model/domain';
import {
  ListNotificationsQuery,
  ListNotificationsQueryModel,
} from '../model/querys';

export class NotificationApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createNotification = this.createNotification.bind(this);
    this.listNotifications = this.listNotifications.bind(this);
  }

  public createNotification(
    cmd: CreateNotificationCmd,
  ): Promise<NotificationModel> {
    return this.post<
      NotificationModel,
      CreateNotificationCmd,
      AxiosResponse<NotificationModel>
    >('/v1/messages', cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listNotifications(
    cmd?: ListNotificationsQuery,
  ): Promise<ListNotificationsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListNotificationsQueryModel,
      AxiosResponse<ListNotificationsQueryModel>
    >('/v1/notifications', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const notificationApi = new NotificationApi();

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { LoginCmd } from '../model/cmds';
import { getConfig } from './util/apiConfig';
import { AuthCredentials } from '../model/domain';

export class AuthApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.login = this.login.bind(this);
  }

  public login(cmd: LoginCmd): Promise<AuthCredentials> {
    return this.post<AuthCredentials, LoginCmd, AxiosResponse<AuthCredentials>>(
      '/v1/login',
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const authApi = new AuthApi();

import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Card,
  Descriptions,
  Table,
  Form,
  Select,
  Input,
  Tag,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import Column from 'antd/lib/table/Column';
import { TicketStatus } from '../../model/enums';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { getTickets } from '../../duck/modules/tickets';
import { SimpleTicketModel } from '../../model/simple';
import {
  renderDate,
  renderGatewayLink,
  renderGatewayStatusTag,
  renderMessage,
  renderOrganisationLink,
  renderReporterLink,
  renderStatusTag,
  renderTicketDetailLink,
} from './ticketUtil';
import { functionalColor } from '../../colors';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      statuses?: TicketStatus[];
      ticketNumbers?: string[];

      showDeleted?: string;
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedTicketsResponsiveTable: React.FC<Props> = ({
  tickets,
  loading,
  error,
  total,
  getAnnouncementsConnect: getTicketsConnect,
  queryParams,
  reload,
  onReloaded,
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);

  const [showDeleted, setShowDeleted] = React.useState(
    queryParams?.showDeleted === 'true' || false,
  );
  const [statuses, setStatuses] = React.useState(queryParams?.statuses || []);
  const [ticketNumbers, setTicketNumbers] = React.useState(
    queryParams?.ticketNumbers || [],
  );

  React.useEffect(() => {
    getTicketsConnect({
      limit,
      offset,
      statuses: statuses,
      showDeleted: showDeleted,
      ticketNumbers: ticketNumbers,
    });
    onReloaded();
  }, [offset, limit, reload, statuses, showDeleted, ticketNumbers]);

  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={tickets}
        scroll={{ x: true }}
      >
        <Column align="left" title="ID" render={renderTicketDetailLink} />

        <Column align="left" title="Status" render={renderStatusTag} />

        <Column align="left" title="Date" render={renderDate} />

        <Column
          align="left"
          title="Organisation"
          render={renderOrganisationLink}
        />

        <Column align="left" title="Reported By" render={renderReporterLink} />

        <Column align="left" title="Message" render={renderMessage} />

        <Column align="left" title="Gateway" render={renderGatewayLink} />

        <Column
          align="left"
          title="Gateway Status"
          render={renderGatewayStatusTag}
        />
      </Table>
    );
  };

  const renderList = () => {
    return (
      <List
        loading={loading}
        grid={{ gutter: 10, column: 1 }}
        dataSource={tickets}
        renderItem={(announcement) => (
          <List.Item>
            <Card>
              <Descriptions
                column={3}
                layout="horizontal"
                colon={true}
                size="small"
                bordered
              >
                <Descriptions.Item
                  span={3}
                  label={
                    <Typography style={{ fontWeight: 500 }}>
                      {'Ticket'}
                    </Typography>
                  }
                >
                  <Typography>{JSON.stringify(announcement)}</Typography>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </List.Item>
        )}
      />
    );
  };

  const renderFilter = () => {
    return (
      <div>
        <Form
          layout="inline"
          style={{ width: '100%' }}
          initialValues={{
            types: [...statuses],
          }}
        >
          <Form.Item name="showDeleted" valuePropName="showDeleted">
            <Checkbox
              onChange={(e) => {
                const checked = new Boolean(e.target.checked);

                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('showDeleted', checked.toString());
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);

                setShowDeleted(checked.valueOf());
              }}
              checked={showDeleted}
            >
              {'Show deleted'}
            </Checkbox>
          </Form.Item>

          <Form.Item name="statuses">
            <Select
              mode="multiple"
              placeholder="Filter by Status"
              value={statuses}
              onChange={(data) => {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('statuses');
                data.forEach((d) => searchParams.append('statuses', d));

                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);

                setStatuses(data);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            >
              <Select.Option value={TicketStatus.OPEN}>
                <Tag color={functionalColor.warning}>{'OPEN'}</Tag>
              </Select.Option>
              <Select.Option value={TicketStatus.WIP}>
                <Tag color={functionalColor.link}>{'WIP'}</Tag>
              </Select.Option>
              <Select.Option value={TicketStatus.SOLVED}>
                <Tag color={functionalColor.success}>{'SOLVED'}</Tag>
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="ticketNumbers">
            <Input
              placeholder="Search by ID"
              value={statuses}
              onPressEnter={(data) => {
                var value = data.currentTarget.value;

                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('ticketNumbers');

                searchParams.append('ticketNumbers', value);

                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);

                setTicketNumbers([value]);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            />
          </Form.Item>
        </Form>
        <br />
      </div>
    );
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Announcements"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      {renderFilter()}
      <div className="hide-on-mobile">{renderTable()}</div>
      <div className="hide-on-desktop">{renderList()}</div>
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  tickets: state.tickets.list.tickets,
  loading: state.tickets.list.loading,
  error: state.tickets.list.error,
  total: state.tickets.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAnnouncementsConnect: getTickets,
    },
    dispatch,
  );
};

export const TicketsResponsiveTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedTicketsResponsiveTable);

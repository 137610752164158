import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { gatewayApi } from '../../api/gatewayApi';
import {
  CREATE_GATEWAY,
  CREATE_GATEWAY_FAILED,
  CREATE_GATEWAY_SUCCESS,
  FETCH_GATEWAY,
  FETCH_GATEWAY_FAILED,
  FETCH_GATEWAY_SUCCESS,
  FETCH_GATEWAYS,
  FETCH_GATEWAYS_FAILED,
  FETCH_GATEWAYS_SUCCESS,
  UPDATE_GATEWAY,
  UPDATE_GATEWAY_FAILED,
  UPDATE_GATEWAY_SUCCESS,
  ADD_SENSOR_TO_GATEWAY,
  REMOVE_SENSOR_FROM_GATEWAY,
  ADD_SMART_SENSOR_TO_GATEWAY,
  ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS,
  ADD_SMART_SENSOR_TO_GATEWAY_FAILED,
  REMOVE_SMART_SENSOR_FROM_GATEWAY,
  REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS,
  REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED,
  ADD_LCM_TO_GATEWAY,
  ADD_LCM_TO_GATEWAY_SUCCESS,
  ADD_LCM_TO_GATEWAY_FAILED,
  REMOVE_LCM_FROM_GATEWAY,
  REMOVE_LCM_FROM_GATEWAY_SUCCESS,
  REMOVE_LCM_FROM_GATEWAY_FAILED,
  ADD_PUMP_TO_GATEWAY,
  ADD_PUMP_TO_GATEWAY_SUCCESS,
  ADD_PUMP_TO_GATEWAY_FAILED,
  REMOVE_PUMP_FROM_GATEWAY,
  REMOVE_PUMP_FROM_GATEWAY_SUCCESS,
  REMOVE_PUMP_FROM_GATEWAY_FAILED,
  SWAP_GATEWAYS,
  SWAP_GATEWAYS_SUCCESS,
  SWAP_GATEWAYS_FAILED,
} from '../../constants';
import {
  CreateGatewayCmd,
  UpdateGatewayCmd,
  GatewaySynchronizationCmd,
  AddAeratorToGatewayCmd,
  AddSensorToGatewayCmd,
  AddPondToGatewayCmd,
  AddSmartSensorToGatewayCmd,
  AddLcmToGatewayCmd,
  AddPumpToGatewayCmd,
  UpdateBatchInformationCmd,
} from '../../model/cmds';
import { ListGatewaysQuery, ListGatewaysQueryModel } from '../../model/querys';
import { GatewaysState } from '../../model/state';
import { pondApi } from '../../api/pondApi';
import {
  gatewaySyncronize,
  gatewaySyncronized,
  gatewaySyncronizeFailed,
  pondFetched,
  gatewaySensorAdd,
  gatewaySensorAdded,
  gatewaySensorAddFailed,
  gatewaySensorRemove,
  gatewaySensorRemoved,
  gatewaySensorRemoveFailed,
  pondSensorAdd,
  pondSensorAdded,
  gatewayAeratorAddFailed,
  gatewayAeratorAdded,
  gatewayAeratorAdd,
  gatewayAeratorRemoved,
  gatewayAeratorRemove,
  gatewayAeratorRemoveFailed,
  gatewayPondAdd,
  gatewayPondAdded,
  gatewayPondAddFailed,
  gatewayPondRemove,
  gatewayPondRemoved,
  gatewayPondRemoveFailed,
  pondRefreshed,
  organisationGatewaysFetch,
  organisationGatewaysFetched,
  organisationGatewaysFetchFailed,
  gatewaySmartSensorRemove,
  gatewaySmartSensorRemoved,
  gatewaySmartSensorRemoveFailed,
  gatewaySmartSensorAddFailed,
  gatewaySmartSensorAdded,
  gatewaySmartSensorAdd,
  gatewayLcmAdd,
  gatewayLcmAdded,
  gatewayLcmAddFailed,
  gatewayLcmRemove,
  gatewayLcmRemoved,
  gatewayLcmRemoveFailed,
  gatewayPumpAdded,
  gatewayPumpAddFailed,
  gatewayPumpAdd,
  gatewayPumpRemove,
  gatewayPumpRemoved,
  gatewayPumpRemoveFailed,
  batchInformationUpdate,
  batchInformationUpdated,
  batchInformationUpdateFailed,
  swapGateways,
  swapGatewaysSuccess,
  swapGatewaysFailed,
} from '../actions';
import { refreshPond, addSensorToPond } from './ponds';
import { aeratorApi } from '../../api/aeratorApi';
import { sensingApi } from '../../api/sensingApi';
import {
  ADD_SENSOR_TO_GATEWAY_SUCCESS,
  ADD_SENSOR_TO_GATEWAY_FAILED,
  REMOVE_SENSOR_FROM_GATEWAY_SUCCESS,
  REMOVE_SENSOR_FROM_GATEWAY_FAILED,
  ADD_AERATOR_TO_GATEWAY,
  ADD_AERATOR_TO_GATEWAY_SUCCESS,
  ADD_AERATOR_TO_GATEWAY_FAILED,
  REMOVE_AERATOR_FROM_GATEWAY,
  REMOVE_AERATOR_FROM_GATEWAY_SUCCESS,
  REMOVE_AERATOR_FROM_GATEWAY_FAILED,
  ADD_POND_TO_GATEWAY,
  ADD_POND_TO_GATEWAY_SUCCESS,
  ADD_POND_TO_GATEWAY_FAILED,
  REMOVE_POND_FROM_GATEWAY,
  REMOVE_POND_FROM_GATEWAY_SUCCESS,
  REMOVE_POND_FROM_GATEWAY_FAILED,
  FETCH_ORGANISATION_GATEWAYS,
  FETCH_ORGANISATION_GATEWAYS_SUCCESS,
  FETCH_ORGANISATION_GATEWAYS_FAILED,
  INT_MAX,
} from '../../constants';
import { AxiosError } from 'axios';
import {
  gatewayCreate,
  gatewayCreated,
  gatewayCreateFailed,
  gatewayFetch,
  gatewayFetched,
  gatewayFetchFailed,
  GatewaysAction,
  gatewaysFetch,
  gatewaysFetched,
  gatewaysFetchFailed,
  gatewayUpdate,
  gatewayUpdated,
  gatewayUpdateFailed,
} from '../actions';
import { SensorType } from '../../model/enums';
import { GatewayModel } from '../../model/domain';

export function createGateway(cmd: CreateGatewayCmd) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayCreate());
      const gateway = await gatewayApi.createGateway(cmd);
      dispatch(gatewayCreated(gateway));
      //dispatch(getGateways({limit: INT_MAX, offset: 0, organisationIds: [cmd.organisationId]}));
    } catch (e) {
      dispatch(gatewayCreateFailed(e));
    }
  };
}
export function getGateway(id: string) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayFetch());
      const gateway = await gatewayApi.fetchGateway(id);
      // // fetch ponds for the gateway
      // const ponds = await Promise.all(gateway.ponds.map(pond => pondApi.fetchPond(pond.id)));
      // const extendedGateway = {
      //   ...gateway,
      //   ponds: ponds,
      // };

      // fetch details for each received gateway

      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));
    } catch (e) {
      dispatch(gatewayFetchFailed(e));
    }
  };
}

export function getGateways(query: ListGatewaysQuery) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewaysFetch(query));
      const queryModel: ListGatewaysQueryModel = await gatewayApi.listGateways(
        query,
      );

      // fetch details for each received gateway
      const gateways = await Promise.all(
        queryModel.gateways.map((gateway) =>
          gatewayApi.fetchGateway(gateway.id),
        ),
      );

      const expandedGateways = await Promise.all(
        gateways.map(async (g) => {
          const gateway = g;

          const omsSensorId = g.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = g.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          return gateway;
        }),
      );

      dispatch(gatewaysFetched(queryModel, expandedGateways));
    } catch (e) {
      dispatch(gatewaysFetchFailed(e));
    }
  };
}

export function updateGateway(id: string, cmd: UpdateGatewayCmd) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayUpdate());
      const response = await gatewayApi.updateGateway(id, cmd);
      dispatch(gatewayUpdated(response));
      dispatch(getGateway(id));
    } catch (e) {
      dispatch(gatewayUpdateFailed(e));
    }
  };
}

export function updateBatchInformation(
  id: string,
  cmd: UpdateBatchInformationCmd,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(batchInformationUpdate());
      const response = await gatewayApi.updateGatewayBatchInformation(id, cmd);
      dispatch(batchInformationUpdated(response));
      dispatch(getGateway(id));
    } catch (e) {
      dispatch(batchInformationUpdateFailed(e));
    }
  };
}

export function swap(replacementGatewayId: string, targetGatewayId: string) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(swapGateways());
      const response = await gatewayApi.swapGateways(
        replacementGatewayId,
        targetGatewayId,
      );
      dispatch(swapGatewaysSuccess(response));
      dispatch(getGateway(replacementGatewayId));
    } catch (e) {
      dispatch(swapGatewaysFailed(e));
    }
  };
}

export function synchronizeGateway(id: string, cmd: GatewaySynchronizationCmd) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewaySyncronize());
      const response = await gatewayApi.synchronizeGateway(id, cmd);
      dispatch(gatewaySyncronized());
      dispatch(getGateway(id));
    } catch (e) {
      dispatch(gatewaySyncronizeFailed(e));
    }
  };
}

export function addSensorToGatewayAndPond(
  sensorId: string,
  gatewayId: string,
  pondId: string,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewaySensorAdd(gatewayId, sensorId));

      let sensor = null;

      // add sensor to gateway
      let gateway = await gatewayApi.addSensorToGateway(gatewayId, {
        sensorId,
      });
      dispatch(gatewayFetched(gateway));
      dispatch(refreshPond(pondId));

      // check if gateway is syncronized
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if sensor was added
        if (
          gateway.sensors.inSync &&
          !gateway.sensors.requested?.find((s) => s.id === sensorId) &&
          gateway.sensors.actual?.find((s) => s.id === sensorId)
        ) {
          console.warn('sensor syncronized with gateway', gateway);
          dispatch(gatewaySensorAdded(gateway));

          // add sensor to pond
          dispatch(pondSensorAdd(pondId, sensorId));
          sensor = await sensingApi.updateSensor(sensorId, { pondId });
          console.warn('added pond to sensor', sensor);

          // update pond data
          dispatch(refreshPond(pondId));

          break;
        } else {
          // wait 2 seconds
          await new Promise((resolve) => setTimeout(resolve, 2000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          dispatch(gatewayFetched(gateway));
        }
      }

      if (sensor) {
        for (let tryNo = 0; tryNo < 10; tryNo++) {
          if (sensor.pond?.inSync) {
            const pond = await pondApi.fetchPond(pondId);
            console.warn('sensor added to pond', pond);
            dispatch(pondSensorAdded(pond));
            dispatch(pondFetched(pond));
            break;
          } else {
            // wait 2 seconds
            await new Promise((resolve) => setTimeout(resolve, 2000));
            // refetch gateway info
            sensor = await sensingApi.getSensor(sensorId);
          }
        }
      } else {
        dispatch(gatewaySensorAddFailed(new Error('Sensor is null!')));
      }

      // update pond
      //dispatch(refreshPond(pondId))

      // wait unit added

      // add sensor to pond
    } catch (e) {
      dispatch(gatewaySensorAddFailed(e));
    }
  };
}

export function addSensorToGateway(
  gatewayId: string,
  cmd: AddSensorToGatewayCmd,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      const sensorId = cmd.sensorId;
      dispatch(gatewaySensorAdd(gatewayId, cmd.sensorId));
      let gateway = await gatewayApi.addSensorToGateway(gatewayId, cmd);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if sensor is syncronized with gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if sensor was added
        if (
          gateway.sensors.inSync &&
          gateway.sensors.actual?.find((s) => s.id === sensorId)
        ) {
          console.warn('sensor syncronized with gateway', gateway);
          dispatch(gatewaySensorAdded(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(gatewaySensorAddFailed(new Error('Sensor not syncronized!')));
      }
    } catch (e) {
      dispatch(gatewaySensorAddFailed(e));
    }
  };
}

export function removeSensorFromGateway(
  gatewayId: string,
  sensorId: string,
  refreshPondId?: string,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewaySensorRemove(gatewayId, sensorId));
      await gatewayApi.removeSensorFromGateway(gatewayId, sensorId);
      let gateway = await gatewayApi.fetchGateway(gatewayId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if sensor was removed from gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if sensor was added
        if (
          gateway.sensors.inSync &&
          !gateway.sensors.actual?.find((s) => s.id === sensorId)
        ) {
          console.warn('sensor removed from gateway', gateway);
          dispatch(gatewaySensorRemoved(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }
          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewaySensorRemoveFailed(new Error('Syncronized with gateway')),
        );
      }
      if (refreshPondId) {
        dispatch(refreshPond(refreshPondId));
      }
    } catch (e) {
      dispatch(gatewaySensorRemoveFailed(e));
    }
  };
}

export function addSmartSensorToGateway(
  gatewayId: string,
  cmd: AddSmartSensorToGatewayCmd,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      const smartSensorId = cmd.smartSensorId;
      dispatch(gatewaySmartSensorAdd(gatewayId, cmd.smartSensorId));
      let gateway = await gatewayApi.addSmartSensorToGateway(gatewayId, cmd);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if smartSensor is syncronized with gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if smartSensor was added
        if (
          gateway.smartSensors.inSync &&
          gateway.smartSensors.actual?.find((s: any) => s.id === smartSensorId)
        ) {
          console.warn('smartSensor syncronized with gateway', gateway);
          dispatch(gatewaySmartSensorAdded(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewaySmartSensorAddFailed(
            new Error('SmartSensor not syncronized!'),
          ),
        );
      }
    } catch (e) {
      dispatch(gatewaySmartSensorAddFailed(e));
    }
  };
}

export function removeSmartSensorFromGateway(
  gatewayId: string,
  smartSensorId: string,
  refreshPondId?: string,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewaySmartSensorRemove(gatewayId, smartSensorId));
      await gatewayApi.removeSmartSensorFromGateway(gatewayId, smartSensorId);
      let gateway = await gatewayApi.fetchGateway(gatewayId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if smartSensor was removed from gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if smartSensor was added
        if (
          gateway.smartSensors.inSync &&
          !gateway.smartSensors.actual?.find((s) => s.id === smartSensorId)
        ) {
          console.warn('smartSensor removed from gateway', gateway);
          dispatch(gatewaySmartSensorRemoved(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewaySmartSensorRemoveFailed(new Error('Syncronized with gateway')),
        );
      }
      if (refreshPondId) {
        dispatch(refreshPond(refreshPondId));
      }
    } catch (e) {
      dispatch(gatewaySmartSensorRemoveFailed(e));
    }
  };
}

export function addAeratorToGateway(
  gatewayId: string,
  cmd: AddAeratorToGatewayCmd,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      const aeratorId = cmd.aeratorId;
      dispatch(gatewayAeratorAdd(gatewayId, aeratorId));
      let gateway = await gatewayApi.addAeratorToGateway(gatewayId, cmd);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if aerator is syncronized with gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if aerator was added
        if (
          gateway.aerators.inSync &&
          gateway.aerators.actual?.find((a) => a.id === aeratorId)
        ) {
          console.warn('aerators syncronized with gateway', gateway);
          dispatch(gatewayAeratorAdded(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewayAeratorAddFailed(new Error('Aerator not syncronized!')),
        );
      }
    } catch (e) {
      dispatch(gatewayAeratorAddFailed(e));
    }
  };
}

export function removeAeratorFromGateway(
  gatewayId: string,
  aeratorId: string,
  refreshPondId?: string,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayAeratorRemove(gatewayId, aeratorId));
      await gatewayApi.removeAeratorFromGateway(gatewayId, aeratorId);
      let gateway = await gatewayApi.fetchGateway(gatewayId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if sensor was removed from gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if sensor was added
        if (
          gateway.aerators.inSync &&
          !gateway.aerators.actual?.find((a) => a.id === aeratorId)
        ) {
          console.warn('aerator removed from gateway', gateway);
          dispatch(gatewayAeratorRemoved(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewayAeratorRemoveFailed(new Error('Syncronized with gateway')),
        );
      }
      if (refreshPondId) {
        dispatch(refreshPond(refreshPondId));
      }
    } catch (e) {
      dispatch(gatewaySensorRemoveFailed(e));
    }
  };
}

export function addPondToGateway(gatewayId: string, cmd: AddPondToGatewayCmd) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      const pondId = cmd.pondId;
      dispatch(gatewayPondAdd(gatewayId, pondId));
      let gateway = await gatewayApi.addPondToGateway(gatewayId, cmd);
      let pond = await pondApi.fetchPond(cmd.pondId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      //dispatch(gatewayFetched(gateway));

      let success = false;

      // check if pond is syncronized with gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if pond was added
        if (
          gateway.ponds.inSync &&
          gateway.ponds.actual?.find((p) => p.id === pondId)
        ) {
          console.warn('ponds syncronized with gateway', gateway);
          dispatch(gatewayPondAdded(gateway));
          success = true;
          dispatch(gatewayFetched(gateway));
          dispatch(pondRefreshed(pond));

          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          pond = await pondApi.fetchPond(cmd.pondId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }
        }
      }
      if (!success) {
        dispatch(gatewayPondAddFailed(new Error('Not syncronized!')));
      }
    } catch (e) {
      dispatch(gatewayPondAddFailed(e));
    }
  };
}

export function removePondFromGateway(gatewayId: string, pondId: string) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayPondRemove(gatewayId, pondId));
      await gatewayApi.removePondFromGateway(gatewayId, pondId);
      let gateway = await gatewayApi.fetchGateway(gatewayId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if sensor was removed from gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if sensor was added
        if (
          gateway.ponds.inSync &&
          !gateway.ponds.actual?.find((p) => p.id === pondId)
        ) {
          console.warn('pond removed from gateway', gateway);
          dispatch(gatewayPondRemoved(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewayPondRemoveFailed(new Error('Syncronized with gateway')),
        );
      }
      refreshPond(pondId);
    } catch (e) {
      dispatch(gatewayPondRemoveFailed(e));
    }
  };
}

export function getOrganisationGateways(
  organisationId: string,
  query?: ListGatewaysQuery,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(
        organisationGatewaysFetch(query || { limit: INT_MAX, offset: 0 }),
      );
      const queryModel: ListGatewaysQueryModel =
        await gatewayApi.listOrganisationGateways(organisationId);
      const gateways = await Promise.all(
        queryModel.gateways.map((gateway) =>
          gatewayApi.fetchGateway(gateway.id),
        ),
      );
      dispatch(organisationGatewaysFetched(queryModel, gateways));
    } catch (e) {
      dispatch(organisationGatewaysFetchFailed(e));
    }
  };
}

export function addLcmToGateway(gatewayId: string, cmd: AddLcmToGatewayCmd) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      const lcmId = cmd.lcmId;
      dispatch(gatewayLcmAdd(gatewayId, cmd.lcmId));
      let gateway = await gatewayApi.addLcmToGateway(gatewayId, cmd);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if lcm is syncronized with gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if lcm was added
        if (
          gateway.lcms.inSync &&
          gateway.lcms.actual?.find((s: any) => s.id === lcmId)
        ) {
          console.warn('lcm syncronized with gateway', gateway);
          dispatch(gatewayLcmAdded(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(gatewayLcmAddFailed(new Error('Lcm not syncronized!')));
      }
    } catch (e) {
      dispatch(gatewayLcmAddFailed(e));
    }
  };
}

export function removeLcmFromGateway(
  gatewayId: string,
  lcmId: string,
  refreshPondId?: string,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayLcmRemove(gatewayId, lcmId));
      await gatewayApi.removeLcmFromGateway(gatewayId, lcmId);
      let gateway = await gatewayApi.fetchGateway(gatewayId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if lcm was removed from gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if lcm was added
        if (
          gateway.lcms.inSync &&
          !gateway.lcms.actual?.find((s) => s.id === lcmId)
        ) {
          console.warn('lcm removed from gateway', gateway);
          dispatch(gatewayLcmRemoved(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(gatewayLcmRemoveFailed(new Error('Syncronized with gateway')));
      }
      if (refreshPondId) {
        dispatch(refreshPond(refreshPondId));
      }
    } catch (e) {
      dispatch(gatewayLcmRemoveFailed(e));
    }
  };
}

export function addPumpToGateway(gatewayId: string, cmd: AddPumpToGatewayCmd) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      const pumpId = cmd.pumpId;
      dispatch(gatewayPumpAdd(gatewayId, pumpId));
      let gateway = await gatewayApi.addPumpToGateway(gatewayId, cmd);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if pump is syncronized with gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if pump was added
        if (
          gateway.pumps.inSync &&
          gateway.pumps.actual?.find((a) => a.id === pumpId)
        ) {
          console.warn('pumps syncronized with gateway', gateway);
          dispatch(gatewayPumpAdded(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(gatewayPumpAddFailed(new Error('Pump not syncronized!')));
      }
    } catch (e) {
      dispatch(gatewayPumpAddFailed(e));
    }
  };
}

export function removePumpFromGateway(
  gatewayId: string,
  pumpId: string,
  refreshPondId?: string,
) {
  return async (dispatch: Dispatch<GatewaysAction, {}, any>) => {
    try {
      dispatch(gatewayPumpRemove(gatewayId, pumpId));
      await gatewayApi.removePumpFromGateway(gatewayId, pumpId);
      let gateway = await gatewayApi.fetchGateway(gatewayId);
      const omsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OMS,
      )?.id;
      const ocsSensorId = gateway.sensors.actual.find(
        (s) => s.type === SensorType.OCS,
      )?.id;

      if (omsSensorId) {
        const omsSensor = await sensingApi.getSensor(omsSensorId);
        gateway.omsSensor = omsSensor;
      }

      if (ocsSensorId) {
        const ocsSensor = await sensingApi.getSensor(ocsSensorId);
        gateway.ocsSensor = ocsSensor;
      }

      dispatch(gatewayFetched(gateway));

      let success = false;

      // check if sensor was removed from gateway
      for (let tryNo = 0; tryNo < 10; tryNo++) {
        // check if sensor was added
        if (
          gateway.pumps.inSync &&
          !gateway.pumps.actual?.find((a) => a.id === pumpId)
        ) {
          console.warn('pump removed from gateway', gateway);
          dispatch(gatewayPumpRemoved(gateway));
          success = true;
          break;
        } else {
          // wait 1 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // refetch gateway info
          gateway = await gatewayApi.fetchGateway(gatewayId);
          const omsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OMS,
          )?.id;
          const ocsSensorId = gateway.sensors.actual.find(
            (s) => s.type === SensorType.OCS,
          )?.id;

          if (omsSensorId) {
            const omsSensor = await sensingApi.getSensor(omsSensorId);
            gateway.omsSensor = omsSensor;
          }

          if (ocsSensorId) {
            const ocsSensor = await sensingApi.getSensor(ocsSensorId);
            gateway.ocsSensor = ocsSensor;
          }

          dispatch(gatewayFetched(gateway));
        }
      }
      if (!success) {
        dispatch(
          gatewayPumpRemoveFailed(new Error('Syncronized with gateway')),
        );
      }
      if (refreshPondId) {
        dispatch(refreshPond(refreshPondId));
      }
    } catch (e) {
      dispatch(gatewaySensorRemoveFailed(e));
    }
  };
}

export default function gatewaysReducer(
  state: GatewaysState = {
    create: {
      loading: false,
      error: null,
      gateway: null,
    },
    list: {
      loading: false,
      error: null,
      gateways: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      gateway: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    addSensor: {
      loading: false,
      error: null,
      sensorId: null,
      gatewayId: null,
    },
    removeSensor: {
      loading: false,
      error: null,
      sensorId: null,
      gatewayId: null,
    },
    addAerator: {
      loading: false,
      error: null,
      aeratorId: null,
      gatewayId: null,
    },
    removeAerator: {
      loading: false,
      error: null,
      aeratorId: null,
      gatewayId: null,
    },
    addPond: {
      loading: false,
      error: null,
      pondId: null,
      gatewayId: null,
    },
    removePond: {
      loading: false,
      error: null,
      pondId: null,
      gatewayId: null,
    },
    organisationGateways: {
      loading: false,
      error: null,
      gateways: [],
      total: null,
      offset: null,
      limit: null,
    },
    addSmartSensor: {
      loading: false,
      error: null,
      smartSensorId: null,
      gatewayId: null,
    },
    removeSmartSensor: {
      loading: false,
      error: null,
      smartSensorId: null,
      gatewayId: null,
    },
    addLcm: {
      loading: false,
      error: null,
      lcmId: null,
      gatewayId: null,
    },
    removeLcm: {
      loading: false,
      error: null,
      lcmId: null,
      gatewayId: null,
    },
    addPump: {
      loading: false,
      error: null,
      pumpId: null,
      gatewayId: null,
    },
    removePump: {
      loading: false,
      error: null,
      pumpId: null,
      gatewayId: null,
    },
    swapGateway: {
      loading: false,
      error: null,
      gateway: null,
    }
  },
  action: GatewaysAction,
): GatewaysState {
  switch (action.type) {
    case CREATE_GATEWAY:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, gateway: null },
      };
    case CREATE_GATEWAY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          gateway: action.payload,
        },
      };
    case CREATE_GATEWAY_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          gateway: null,
        },
      };
    case FETCH_GATEWAY:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          gateway: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_GATEWAY_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          gateway: action.payload,
        },
      };
    case FETCH_GATEWAY_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          gateway: null,
        },
      };
    case UPDATE_GATEWAY:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_GATEWAY_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_GATEWAY_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case ADD_SENSOR_TO_GATEWAY: {
      return {
        ...state,
        addSensor: {
          ...state.addSensor,
          loading: true,
          error: null,
          sensorId: action.payload.sensorId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case ADD_SENSOR_TO_GATEWAY_SUCCESS: {
      return {
        ...state,
        addSensor: {
          ...state.addSensor,
          loading: false,
          error: null,
          sensorId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_SENSOR_TO_GATEWAY_FAILED: {
      return {
        ...state,
        addSensor: {
          ...state.addSensor,
          loading: false,
          error: action.payload,
          sensorId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_SENSOR_FROM_GATEWAY: {
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: true,
          error: null,
          sensorId: action.payload.sensorId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case REMOVE_SENSOR_FROM_GATEWAY_SUCCESS: {
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: false,
          error: null,
          sensorId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_SENSOR_FROM_GATEWAY_FAILED: {
      return {
        ...state,
        removeSensor: {
          ...state.removeSensor,
          loading: false,
          error: action.payload,
          sensorId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_AERATOR_TO_GATEWAY: {
      return {
        ...state,
        addAerator: {
          ...state.addAerator,
          loading: true,
          error: null,
          aeratorId: action.payload.aeratorId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case ADD_AERATOR_TO_GATEWAY_SUCCESS: {
      return {
        ...state,
        addAerator: {
          ...state.addAerator,
          loading: false,
          error: null,
          aeratorId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_AERATOR_TO_GATEWAY_FAILED: {
      return {
        ...state,
        addAerator: {
          ...state.addAerator,
          loading: false,
          error: action.payload,
          aeratorId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_AERATOR_FROM_GATEWAY: {
      return {
        ...state,
        removeAerator: {
          ...state.removeAerator,
          loading: true,
          error: null,
          aeratorId: action.payload.aeratorId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case REMOVE_AERATOR_FROM_GATEWAY_SUCCESS: {
      return {
        ...state,
        removeAerator: {
          ...state.removeAerator,
          loading: false,
          error: null,
          aeratorId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_AERATOR_FROM_GATEWAY_FAILED: {
      return {
        ...state,
        removeAerator: {
          ...state.removeAerator,
          loading: false,
          error: action.payload,
          aeratorId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_POND_TO_GATEWAY: {
      return {
        ...state,
        addPond: {
          ...state.addPond,
          loading: true,
          error: null,
          pondId: action.payload.pondId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case ADD_POND_TO_GATEWAY_SUCCESS: {
      return {
        ...state,
        addPond: {
          ...state.addPond,
          loading: false,
          error: null,
          pondId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_POND_TO_GATEWAY_FAILED: {
      return {
        ...state,
        addPond: {
          ...state.addPond,
          loading: false,
          error: action.payload,
          pondId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_POND_FROM_GATEWAY: {
      return {
        ...state,
        removePond: {
          ...state.removePond,
          loading: true,
          error: null,
          pondId: action.payload.pondId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case REMOVE_POND_FROM_GATEWAY_SUCCESS: {
      return {
        ...state,
        removePond: {
          ...state.removePond,
          loading: false,
          error: null,
          pondId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_POND_FROM_GATEWAY_FAILED: {
      return {
        ...state,
        removePond: {
          ...state.removePond,
          loading: false,
          error: action.payload,
          pondId: null,
          gatewayId: null,
        },
      };
    }

    case FETCH_GATEWAYS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          gateways: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_GATEWAYS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          gateways: action.payload.gateways,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_GATEWAYS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          gateways: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case FETCH_ORGANISATION_GATEWAYS:
      return {
        ...state,
        organisationGateways: {
          ...state.organisationGateways,
          loading: true,
          error: null,
          gateways: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_ORGANISATION_GATEWAYS_SUCCESS:
      return {
        ...state,
        organisationGateways: {
          ...state.organisationGateways,
          loading: false,
          error: null,
          gateways: action.payload.gateways,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_ORGANISATION_GATEWAYS_FAILED:
      return {
        ...state,
        organisationGateways: {
          ...state.organisationGateways,
          loading: true,
          error: null,
          gateways: [],
          limit: null,
          offset: null,
          total: null,
        },
      };

    case ADD_SMART_SENSOR_TO_GATEWAY: {
      return {
        ...state,
        addSmartSensor: {
          ...state.addSmartSensor,
          loading: true,
          error: null,
          smartSensorId: action.payload.smartSensorId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS: {
      return {
        ...state,
        addSmartSensor: {
          ...state.addSmartSensor,
          loading: false,
          error: null,
          smartSensorId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_SMART_SENSOR_TO_GATEWAY_FAILED: {
      return {
        ...state,
        addSmartSensor: {
          ...state.addSmartSensor,
          loading: false,
          error: action.payload,
          smartSensorId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_SMART_SENSOR_FROM_GATEWAY: {
      return {
        ...state,
        removeSmartSensor: {
          ...state.removeSmartSensor,
          loading: true,
          error: null,
          smartSensorId: action.payload.smartSensorId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS: {
      return {
        ...state,
        removeSmartSensor: {
          ...state.removeSmartSensor,
          loading: false,
          error: null,
          smartSensorId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED: {
      return {
        ...state,
        removeSmartSensor: {
          ...state.removeSmartSensor,
          loading: false,
          error: action.payload,
          smartSensorId: null,
          gatewayId: null,
        },
      };
    }

    case ADD_LCM_TO_GATEWAY: {
      return {
        ...state,
        addLcm: {
          ...state.addLcm,
          loading: true,
          error: null,
          lcmId: action.payload.lcmId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case ADD_LCM_TO_GATEWAY_SUCCESS: {
      return {
        ...state,
        addLcm: {
          ...state.addLcm,
          loading: false,
          error: null,
          lcmId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_LCM_TO_GATEWAY_FAILED: {
      return {
        ...state,
        addLcm: {
          ...state.addLcm,
          loading: false,
          error: action.payload,
          lcmId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_LCM_FROM_GATEWAY: {
      return {
        ...state,
        removeLcm: {
          ...state.removeLcm,
          loading: true,
          error: null,
          lcmId: action.payload.lcmId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case REMOVE_LCM_FROM_GATEWAY_SUCCESS: {
      return {
        ...state,
        removeLcm: {
          ...state.removeLcm,
          loading: false,
          error: null,
          lcmId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_LCM_FROM_GATEWAY_FAILED: {
      return {
        ...state,
        removeLcm: {
          ...state.removeLcm,
          loading: false,
          error: action.payload,
          lcmId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_PUMP_TO_GATEWAY: {
      return {
        ...state,
        addPump: {
          ...state.addPump,
          loading: true,
          error: null,
          pumpId: action.payload.pumpId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case ADD_PUMP_TO_GATEWAY_SUCCESS: {
      return {
        ...state,
        addPump: {
          ...state.addPump,
          loading: false,
          error: null,
          pumpId: null,
          gatewayId: null,
        },
      };
    }
    case ADD_PUMP_TO_GATEWAY_FAILED: {
      return {
        ...state,
        addPump: {
          ...state.addPump,
          loading: false,
          error: action.payload,
          pumpId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_PUMP_FROM_GATEWAY: {
      return {
        ...state,
        removePump: {
          ...state.removePump,
          loading: true,
          error: null,
          pumpId: action.payload.pumpId,
          gatewayId: action.payload.gatewayId,
        },
      };
    }
    case REMOVE_PUMP_FROM_GATEWAY_SUCCESS: {
      return {
        ...state,
        removePump: {
          ...state.removePump,
          loading: false,
          error: null,
          pumpId: null,
          gatewayId: null,
        },
      };
    }
    case REMOVE_PUMP_FROM_GATEWAY_FAILED: {
      return {
        ...state,
        removePump: {
          ...state.removePump,
          loading: false,
          error: action.payload,
          pumpId: null,
          gatewayId: null,
        },
      };
    }
    case SWAP_GATEWAYS: {
      return {
        ...state,
        swapGateway: {
          ...state.swapGateway,
          loading: true,
          error: null,
          gateway: null,
        },
      };
    }
    case SWAP_GATEWAYS_SUCCESS: {
      return {
        ...state,
        swapGateway: {
          ...state.swapGateway,
          loading: false,
          error: null,
          gateway: action.payload,
        },
      };
    }
    case SWAP_GATEWAYS_FAILED: {
      return {
        ...state,
        swapGateway: {
          ...state.swapGateway,
          loading: false,
          error: action.payload,
          gateway: null,
        },
      };
    }



    default:
      return state;
  }
}

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { Layout, PageHeader, Col, Typography } from 'antd';
import { RootState } from '../../duck/index';
import Grid from 'antd/lib/card/Grid';
const { Content, Footer } = Layout;

interface Props {
  exact?: boolean;
  isAuthenticated: boolean | null;
  path: string;
  headerTitle?: string | null;
  headerSubTitle?: string | null;
  component: React.ComponentType<any>;
  onBack?: () => void;
}

const LoggedInRoute = ({
  component: Component,
  isAuthenticated,
  headerTitle,
  headerSubTitle,
  path,
  exact,
  onBack,
  ...otherProps
}: Props) => {
  if (isAuthenticated !== true) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      {headerTitle && (
        <PageHeader
          title={headerTitle}
          subTitle={headerSubTitle}
          onBack={onBack}
        />
      )}
      <Content style={{ padding: '0 10px' }}>
        <Route
          path={path}
          exact={exact}
          render={(otherProps) => <Component {...otherProps} />}
        />
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(LoggedInRoute);

import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Popconfirm,
  Button,
  notification,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { UpdateProductCmd } from '../../model/cmds';
import {
  deleteProduct,
  getProduct,
  updateProduct,
} from '../../duck/modules/products';
import { ProductDataForm } from '../product/productDataForm';
import { usePrevious } from '../_util/hook';
import { getManufacturerOptions } from '../../duck/modules/options';
import { INT_MAX } from '../../constants';

const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ productId: string }>;

const UnconnectedProductDetail: React.FC<Props> = ({
  getProductConnect,
  updateProductConnect,
  product,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  deleteProductConnect,
  deleteState,
  getManufacturerOptionsConnect,
  manufacturerOptionsState,
}) => {
  const { productId } = match.params;

  React.useEffect(() => {
    getProductConnect(productId);
  }, []);

  React.useEffect(() => {
    if (product) {
      getManufacturerOptionsConnect({
        limit: INT_MAX,
        offset: 0,
        categories: [product.category],
      });
    }
  }, [product]);

  const prev = usePrevious({
    deleteSuccess: deleteState.success,
    deleteError: deleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteState.success) {
      notification.success({
        message: 'Product deleted',
      });
    }
  }, [deleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteState.error) {
      notification.error({
        message: 'Product deletion error',
        description: deleteState.error?.message,
      });
    }
  }, [deleteState.error]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load product"
        subTitle={error.message}
      />
    );
  }

  const renderGeneralDataCardContent = () => {
    if (product) {
      return (
        <ProductDataForm
          product={product}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          manufacturerOptions={
            manufacturerOptionsState.model?.manufacturers || []
          }
          onUpdate={(cmd: UpdateProductCmd) => {
            updateProductConnect(productId, cmd);
          }}
        />
      );
    }
  };

  const rendeDeleteButton = () => {
    if (product) {
      if (product.deleted === true) {
        return (
          <Button
            type="ghost"
            danger
            onClick={() => updateProductConnect(productId, { deleted: false })}
          >
            Restore
          </Button>
        );
      }
      return (
        <Popconfirm
          title="Do you really want to delete this product?"
          onConfirm={() => deleteProductConnect(productId)}
        >
          <Button type="ghost" danger loading={deleteState.loading}>
            Delete
          </Button>
        </Popconfirm>
      );
    }
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{rendeDeleteButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="General Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  product: state.products.detail.product,
  loading: state.products.detail.loading,
  error: state.products.detail.error,
  updating: state.products.detail.updating,
  updateError: state.products.detail.updateError,
  updateSuccess: state.products.detail.updateSuccess,
  deleteState: state.products.delete,
  manufacturerOptionsState: state.options.manufacturerOptions,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getProductConnect: getProduct,
      updateProductConnect: updateProduct,
      deleteProductConnect: deleteProduct,
      getManufacturerOptionsConnect: getManufacturerOptions,
    },
    dispatch,
  );
};

export const ProductDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedProductDetail);

import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { aeratorApi } from '../../api/aeratorApi';
import {
  CREATE_AERATOR,
  CREATE_AERATOR_FAILED,
  CREATE_AERATOR_SUCCESS,
  FETCH_AERATOR,
  FETCH_AERATOR_FAILED,
  FETCH_AERATOR_SUCCESS,
  FETCH_AERATORS,
  FETCH_AERATORS_FAILED,
  FETCH_AERATORS_SUCCESS,
  UPDATE_AERATOR,
  UPDATE_AERATOR_FAILED,
  UPDATE_AERATOR_SUCCESS,
} from '../../constants';
import { CreateAeratorCmd, UpdateAeratorCmd } from '../../model/cmds';
import { ListAeratorsQuery, ListAeratorsQueryModel } from '../../model/querys';
import { AeratorsState } from '../../model/state';
import { gatewayApi } from '../../api/gatewayApi';
import { pondApi } from '../../api/pondApi';
import { getPond, refreshPond } from './ponds';
import {
  aeratorCreate,
  aeratorCreated,
  aeratorCreateFailed,
  aeratorFetch,
  aeratorFetched,
  aeratorFetchFailed,
  AeratorsAction,
  aeratorsFetch,
  aeratorsFetched,
  aeratorsFetchFailed,
  aeratorUpdate,
  aeratorUpdated,
  aeratorUpdateFailed,
} from '../actions';

export function createAerator(
  cmd: CreateAeratorCmd,
  gatewayId?: string,
  pondId?: string,
) {
  return async (dispatch: Dispatch<AeratorsAction, {}, any>) => {
    try {
      dispatch(aeratorCreate());
      let newAerator = await aeratorApi.createAerator(cmd);

      // add aerator to gateway
      if (gatewayId) {
        let gateway = await gatewayApi.addAeratorToGateway(gatewayId, {
          aeratorId: newAerator.id,
        });

        // check if gateway is syncronized
        for (let tryNo = 0; tryNo < 10; tryNo++) {
          // new aeator added to gateway
          if (
            gateway.aerators.inSync &&
            gateway.aerators.actual.find((a) => a.id === newAerator.id)
          ) {
            // add aertor to pond
            console.warn('aerator attached to gateway');
            if (pondId) {
              newAerator = await aeratorApi.updateAerator(newAerator.id, {
                pondId,
              });
              // check if pond is set for aeator
              if (
                newAerator.pond.inSync &&
                newAerator.pond.actual.id === pondId
              ) {
                console.warn('aerator attached to pond');
                dispatch(refreshPond(pondId));
                break;
              } else {
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            }
          } else {
            // wait 2 seconds
            await new Promise((resolve) => setTimeout(resolve, 2000));
            // refetch gateway info
            gateway = await gatewayApi.fetchGateway(gatewayId);
          }
        }
      }

      dispatch(aeratorCreated(newAerator));
    } catch (e) {
      dispatch(aeratorCreateFailed(e));
    }
  };
}

export function getAerator(id: string) {
  return async (dispatch: Dispatch<AeratorsAction, {}, any>) => {
    try {
      dispatch(aeratorFetch());
      const aerator = await aeratorApi.getAerator(id);
      dispatch(aeratorFetched(aerator));
    } catch (e) {
      dispatch(aeratorFetchFailed(e));
    }
  };
}

export function getAerators(query: ListAeratorsQuery) {
  return async (dispatch: Dispatch<AeratorsAction, {}, any>) => {
    try {
      dispatch(aeratorsFetch(query));
      const queryModel: ListAeratorsQueryModel = await aeratorApi.listAerators(
        query,
      );
      // fetch details for each received aerator
      const aerators = await Promise.all(
        queryModel.aerators.map((aerator) => aeratorApi.getAerator(aerator.id)),
      );
      dispatch(aeratorsFetched(queryModel, aerators));
    } catch (e) {
      dispatch(aeratorsFetchFailed(e));
    }
  };
}

export function updateAerator(id: string, cmd: UpdateAeratorCmd) {
  return async (dispatch: Dispatch<AeratorsAction, {}, any>) => {
    try {
      dispatch(aeratorUpdate());
      const aerator = await aeratorApi.updateAerator(id, cmd);
      dispatch(aeratorUpdated(aerator));
      dispatch(getAerator(id));
    } catch (e) {
      dispatch(aeratorUpdateFailed(e));
    }
  };
}

export default function aeratorsReducer(
  state: AeratorsState = {
    create: {
      loading: false,
      error: null,
      aerator: null,
    },
    list: {
      loading: false,
      error: null,
      aerators: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      aerator: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: AeratorsAction,
): AeratorsState {
  switch (action.type) {
    case CREATE_AERATOR:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, aerator: null },
      };
    case CREATE_AERATOR_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          aerator: action.payload,
        },
      };
    case CREATE_AERATOR_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          aerator: null,
        },
      };
    case FETCH_AERATOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          aerator: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_AERATOR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          aerator: action.payload,
        },
      };
    case FETCH_AERATOR_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          aerator: null,
        },
      };
    case UPDATE_AERATOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_AERATOR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_AERATOR_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case FETCH_AERATORS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          aerators: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_AERATORS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          aerators: action.payload.aerators,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_AERATORS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          aerators: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

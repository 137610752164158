import React from 'react';
import {
  List,
  Result,
  Typography,
  Col,
  Row,
  Pagination,
  Table,
  Form,
  Select,
  Input,
  Tag,
  Tooltip,
  Badge,
  Descriptions,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import Column from 'antd/lib/table/Column';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { functionalColor } from '../../colors';
import { countAccounts, getAccounts } from '../../duck/modules/accounts';
import { AccountModel } from '../../model/domain';
import { Link } from 'react-router-dom';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { boolFromStringOtherwiseNull } from '../../util';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    queryParams?: {
      page?: number;
      pageSize?: number;
      referralCodes?: string[];
      includeDisabled?: string;
      phoneNumbers?: string[];
      emails?: string[];
      isActive?: string | string[];
    };
    reload: boolean;
    onReloaded: () => void;
  };

const UnconnectedGatewaysTable: React.FC<Props> = ({
  accounts,
  loading,
  error,
  total,
  getAccountsConnect,
  queryParams,
  reload,
  onReloaded,
  countAccountsConnect,
  countsState
}) => {
  const [limit, setLimit] = React.useState(queryParams?.pageSize || 25);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(queryParams?.page || 1);
  const [currentPageSize, setCurrentPageSize] = React.useState(limit);
  const [includeDisabled, setIncludeDisabled] = React.useState(
    queryParams?.includeDisabled !== 'true' ? false : true,
  );
  const [referralCodes, setReferralCodes] = React.useState(
    queryParams?.referralCodes || [],
  );
  const [emails, setEmails] = React.useState(queryParams?.emails || []);
  const [phoneNumbers, setPhoneNumbers] = React.useState(
    queryParams?.phoneNumbers || [],
  );

  const [isActive, setIsActive] = React.useState<boolean[]>(
    typeof queryParams?.isActive === "string" ? [boolFromStringOtherwiseNull(queryParams?.isActive)] :
      typeof queryParams?.isActive === "object" && queryParams?.isActive instanceof Array ? queryParams?.isActive.map(boolFromStringOtherwiseNull) : []
  );

  const [
    latestTermsAndConditionsFromHub,
    setLatestTermsAndConditionsFromHub,
  ] = React.useState('');

  React.useEffect(() => {
    getAccountsConnect({
      limit,
      offset,
      referralCodes,
      includeDisabled,
      emails,
      phoneNumbers,
      isActive
    });

    countAccountsConnect();

    if (!latestTermsAndConditionsFromHub) {
      getLatestTermsAndConditionsFromHub();
    }

    onReloaded();
  }, [
    offset,
    limit,
    reload,
    emails,
    phoneNumbers,
    includeDisabled,
    referralCodes,
    isActive
  ]);

  React.useEffect(() => {
    if (typeof queryParams?.isActive === "string") {
      setIsActive([boolFromStringOtherwiseNull(queryParams?.isActive)]);
    } else if (typeof queryParams?.isActive === "object" && queryParams?.isActive instanceof Array) {
      setIsActive(queryParams?.isActive.map(boolFromStringOtherwiseNull));
    }
  }, [queryParams?.isActive]);


  React.useEffect(() => {
    if (queryParams && queryParams.pageSize && queryParams.pageSize != limit) {
      setLimit(queryParams.pageSize);
    }
  }, [queryParams?.pageSize]);

  React.useEffect(() => {
    if (queryParams && queryParams.page) {
      setOffset((queryParams.page - 1) * limit);
    }
  }, [queryParams?.page]);

  const getLatestTermsAndConditionsFromHub = async () => {
    try {
      const response = await Axios.get<any, AxiosResponse<any>>(
        'https://hub.hydroneo.de/items/tncs?fields=*.*&filter[effective_date][_lte]=$NOW&sort[]=-effective_date&limit=1',
      );
      const data = await response.data;
      const version = (data as any)['data'][0]['version'];
      setLatestTermsAndConditionsFromHub(version);
    } catch (e) {
      // dismiss
    }
  };

  const handleChange = (pageSize: number, page: number) => {
    setOffset(pageSize * (page - 1));
    setLimit(pageSize);
    setPage(page);
    setCurrentPageSize(pageSize);

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const renderPagination = () => {
    return (
      <div>
        {total != null && (
          <Row justify="center" style={{ marginTop: 10 }}>
            <Pagination
              size="default"
              total={total}
              defaultCurrent={page || 1}
              pageSize={currentPageSize}
              pageSizeOptions={['5', '10', '25', '50', '100', '200']}
              responsive={true}
              showSizeChanger={true}
              showTotal={(total) => `Total: ${total} items`}
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  handleChange(pageSize, page);
                }
              }}
              onShowSizeChange={(_, pageSize) => {
                handleChange(pageSize, page);
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
        dataSource={accounts}
        scroll={{ x: true }}
      >
        <Column
          align="center"
          title="Enabled"
          render={(account: AccountModel) => {
            if (account.enabled) {
              return <Typography style={{ color: functionalColor.success }} >{"✓"}</Typography>
            }
            return <Typography style={{ color: functionalColor.error }}  >{"✘"}</Typography>
          }}
        />

        <Column
          align="center"
          title="BI"
          render={(account: AccountModel) => {
            if (account.isActive === true) {
              return <Tag color={functionalColor.success}>Active</Tag>;
            } else if (account.isActive === false) {
              return <Tag color={functionalColor.error}>Inactive</Tag>;
            }
            return <Tag color={functionalColor.warning}>NULL</Tag>;
          }}
        />

        <Column
          align="center"
          title="VIP"
          render={(account: AccountModel) => {
            if (account.isVIP === true) {
              return <Tag color={functionalColor.success}>VIP</Tag>;
            }
          }}
        />

        <Column
          align="center"
          title="ID"
          render={(account: AccountModel) => (
            <Link
              key={account.id}
              to={`/accounts/${account.id}`}
            >
              {account.id}
            </Link>
          )}
        />

        {/* <Column
          align="center"
          title="Email"
          render={(account: AccountModel) => (
            <Link key={account.id} to={`/accounts/${account.id}`}>
              {account.email}
            </Link>
          )}
        />
    */}

        <Column
          align="center"
          title="Username"
          render={(account: AccountModel) => {
            if (account.username) {
              return (
                <Typography>{account.username}</Typography>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />


        <Column
          align="center"
          title="Name"
          render={(account: AccountModel) => {
            if (account.username) {
              return (
                <Typography>{account.name}</Typography>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}

        />

        <Column
          align="center"
          title="Phone"
          render={(account: AccountModel) => {
            if (account.phoneNumber) {
              return (
                <a href={`tel:${account.phoneNumber}`}>{account.phoneNumber}</a>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="CRM"
          render={(account: AccountModel) => {
            if (account.crmContactId) {
              return (
                <a
                  target="_blank"
                  href={`https://app.hubspot.com/contacts/5586633/contact/${account.crmContactId}`}
                >
                  {account.crmContactId}
                </a>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="T&Cs accepted"
          render={(account: AccountModel) => {
            if (account.latestTocAcceptanceRecord) {
              const isLatest =
                account.latestTocAcceptanceRecord.tocVersion ===
                latestTermsAndConditionsFromHub;
              return (
                <Tooltip
                  title={`${isLatest ? 'Latest' : 'Older'} version - ${moment(
                    account.latestTocAcceptanceRecord.acceptanceTimestamp,
                  ).format('lll')}`}
                >
                  <Typography>
                    {latestTermsAndConditionsFromHub && (
                      <Badge
                        color={
                          isLatest
                            ? functionalColor.success
                            : functionalColor.warning
                        }
                        title={account.latestTocAcceptanceRecord.tocVersion}
                      />
                    )}
                    {account.latestTocAcceptanceRecord.tocVersion}
                  </Typography>
                </Tooltip>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Organisation"
          render={(account: AccountModel) => {
            if (account.organisation) {
              return (
                <Link
                  key={account.id}
                  to={`/organisations/${account.organisation.id}`}
                >
                  {account.organisation.name}
                </Link>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />

        <Column
          align="center"
          title="Referral Code"
          render={(account: AccountModel) => {
            if (account.referralCode) {
              return (
                <Typography.Text copyable={{ tooltips: true }}>
                  {account?.referralCode}
                </Typography.Text>
              );
            }
            return <Typography>{'-'}</Typography>;
          }}
        />
      </Table>
    );
  };

  const renderFilter = () => {
    return (
      <div>
        <Form
          layout="inline"
          style={{ width: '100%' }}
          initialValues={{
            includeDisabled,
            isActive,
            referralCodes,
            emails,
            phoneNumbers,
          }}
        >
          <Form.Item name="includeDisabled" valuePropName="includeDisabled">
            <Checkbox
              onChange={(e) => {
                const checked = new Boolean(e.target.checked);
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('includeDisabled', checked.toString());
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setIncludeDisabled(checked.valueOf());
              }}
              checked={includeDisabled}
            >
              {'Include disabled'}
            </Checkbox>
          </Form.Item>

          <Form.Item name="isActive">
            <Select
              mode="multiple"
              placeholder="Filter by BI"
              value={isActive}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('isActive');
                value.forEach((d) =>
                  searchParams.append('isActive', d.toString()),
                );
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setIsActive(data);
              }}
              allowClear={false}
              style={{ minWidth: 300 }}
            >
              <Select.Option value={true}>
                <Tag color={functionalColor.success}>{'Active'}</Tag>
              </Select.Option>
              <Select.Option value={false}>
                <Tag color={functionalColor.error}>{'Inactive'}</Tag>
              </Select.Option>
            </Select>
          </Form.Item>



          <Form.Item name="referralCodes">
            <Select
              mode="tags"
              placeholder="Search by Referral Codes"
              value={referralCodes}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('referralCodes');
                value.forEach((referralCode) => {
                  searchParams.append('referralCodes', referralCode);
                });
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setReferralCodes(value);
              }}
              allowClear={true}
              style={{ minWidth: 300 }}
            />
          </Form.Item>

          <Form.Item name="emails">
            <Select
              mode="tags"
              placeholder="Search by Emails"
              value={emails}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('emails');
                value.forEach((email) => {
                  searchParams.append('emails', email);
                });
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setEmails(value);
              }}
              allowClear={true}
              style={{ minWidth: 300 }}
            />
          </Form.Item>

          <Form.Item name="phoneNumbers">
            <Select
              mode="tags"
              placeholder="Search by Phone Numbers"
              value={phoneNumbers}
              onChange={(data) => {
                var value = data;
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('phoneNumbers');
                value.forEach((phoneNumber) => {
                  searchParams.append('phoneNumbers', phoneNumber);
                });
                let newurl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?' +
                  searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
                setPhoneNumbers(value);
              }}
              allowClear={true}
              style={{ minWidth: 300 }}
            />
          </Form.Item>
        </Form>
        <br />
      </div>
    );
  };

  const renderResultInfo = () => {

    return (
      <div>
        <Descriptions column={1} layout='horizontal' >

          <Descriptions.Item label="Query results">
            {total}
          </Descriptions.Item>
          <Descriptions.Item label="Active accounts">
            {countsState?.active}
          </Descriptions.Item>

          <Descriptions.Item label="Inactive accounts">
            {countsState?.inactive}
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            {countsState?.all}
          </Descriptions.Item>

        </Descriptions>
        <br />
      </div>
    );

  };

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load Accounts"
        subTitle={error.message}
      />
    );
  }

  return (
    <Col>
      {renderFilter()}
      {renderResultInfo()}
      {renderTable()}
      {renderPagination()}
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  accounts: state.accounts.list.accounts,
  loading: state.accounts.list.loading,
  error: state.accounts.list.error,
  total: state.accounts.list.total,
  countsState: state.accounts.counts,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAccountsConnect: getAccounts,
      countAccountsConnect: countAccounts,
    },
    dispatch,
  );
};

export const AccountsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedGatewaysTable);

// UTIL
export const INT_MAX = 0x7fffffff;

// AUTH

export const AUTHENTICATE = 'AUTHENTICATE';
export type AUTHENTICATE = typeof AUTHENTICATE;

export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export type UNAUTHENTICATE = typeof UNAUTHENTICATE;

export const FETCH_ME = 'FETCH_ME';
export type FETCH_ME = typeof FETCH_ME;

export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export type FETCH_ME_SUCCESS = typeof FETCH_ME_SUCCESS;

export const FETCH_ME_FAILED = 'FETCH_ME_FAILED';
export type FETCH_ME_FAILED = typeof FETCH_ME_FAILED;

// ACCOUNT

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export type CREATE_ACCOUNT = typeof CREATE_ACCOUNT;

export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export type CREATE_ACCOUNT_SUCCESS = typeof CREATE_ACCOUNT_SUCCESS;

export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED';
export type CREATE_ACCOUNT_FAILED = typeof CREATE_ACCOUNT_FAILED;

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export type UPDATE_ACCOUNT = typeof UPDATE_ACCOUNT;

export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export type UPDATE_ACCOUNT_SUCCESS = typeof UPDATE_ACCOUNT_SUCCESS;

export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';
export type UPDATE_ACCOUNT_FAILED = typeof UPDATE_ACCOUNT_FAILED;

export const UPDATE_ACCOUNT_ROLE = 'UPDATE_ACCOUNT_ROLE';
export type UPDATE_ACCOUNT_ROLE = typeof UPDATE_ACCOUNT_ROLE;

export const UPDATE_ACCOUNT_ROLE_SUCCESS = 'UPDATE_ACCOUNT_ROLE_SUCCESS';
export type UPDATE_ACCOUNT_ROLE_SUCCESS = typeof UPDATE_ACCOUNT_ROLE_SUCCESS;

export const UPDATE_ACCOUNT_ROLE_FAILED = 'UPDATE_ACCOUNT_ROLE_FAILED';
export type UPDATE_ACCOUNT_ROLE_FAILED = typeof UPDATE_ACCOUNT_ROLE_FAILED;

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export type FETCH_ACCOUNTS = typeof FETCH_ACCOUNTS;

export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export type FETCH_ACCOUNTS_SUCCESS = typeof FETCH_ACCOUNTS_SUCCESS;

export const FETCH_ACCOUNTS_FAILED = 'FETCH_ACCOUNTS_FAILED';
export type FETCH_ACCOUNTS_FAILED = typeof FETCH_ACCOUNTS_FAILED;

export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export type FETCH_ACCOUNT = typeof FETCH_ACCOUNT;

export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export type FETCH_ACCOUNT_SUCCESS = typeof FETCH_ACCOUNT_SUCCESS;

export const FETCH_ACCOUNT_FAILED = 'FETCH_ACCOUNT_FAILED';
export type FETCH_ACCOUNT_FAILED = typeof FETCH_ACCOUNT_FAILED;


export const COUNT_ACCOUNTS = 'COUNT_ACCOUNTS';
export type COUNT_ACCOUNTS = typeof COUNT_ACCOUNTS;

export const COUNT_ACCOUNTS_SUCCESS = 'COUNT_ACCOUNTS_SUCCESS';
export type COUNT_ACCOUNTS_SUCCESS = typeof COUNT_ACCOUNTS_SUCCESS;

export const COUNT_ACCOUNTS_FAILED = 'COUNT_ACCOUNTS_FAILED';
export type COUNT_ACCOUNTS_FAILED = typeof COUNT_ACCOUNTS_FAILED;

// ORGANISATION

export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export type CREATE_ORGANISATION = typeof CREATE_ORGANISATION;

export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export type CREATE_ORGANISATION_SUCCESS = typeof CREATE_ORGANISATION_SUCCESS;

export const CREATE_ORGANISATION_FAILED = 'CREATE_ORGANISATION_FAILED';
export type CREATE_ORGANISATION_FAILED = typeof CREATE_ORGANISATION_FAILED;

export const FETCH_ORGANISATION = 'FETCH_ORGANISATION';
export type FETCH_ORGANISATION = typeof FETCH_ORGANISATION;

export const FETCH_ORGANISATION_SUCCESS = 'FETCH_ORGANISATION_SUCCESS';
export type FETCH_ORGANISATION_SUCCESS = typeof FETCH_ORGANISATION_SUCCESS;

export const FETCH_ORGANISATION_FAILED = 'FETCH_ORGANISATION_FAILED';
export type FETCH_ORGANISATION_FAILED = typeof FETCH_ORGANISATION_FAILED;

export const FETCH_ORGANISATIONS = 'FETCH_ORGANISATIONS';
export type FETCH_ORGANISATIONS = typeof FETCH_ORGANISATIONS;

export const FETCH_ORGANISATIONS_SUCCESS = 'FETCH_ORGANISATIONS_SUCCESS';
export type FETCH_ORGANISATIONS_SUCCESS = typeof FETCH_ORGANISATIONS_SUCCESS;

export const FETCH_ORGANISATIONS_FAILED = 'FETCH_ORGANISATIONS_FAILED';
export type FETCH_ORGANISATIONS_FAILED = typeof FETCH_ORGANISATIONS_FAILED;

export const COUNT_ORGANISATIONS = 'COUNT_ORGANISATIONS';
export type COUNT_ORGANISATIONS = typeof COUNT_ORGANISATIONS;

export const COUNT_ORGANISATIONS_SUCCESS = 'COUNT_ORGANISATIONS_SUCCESS';
export type COUNT_ORGANISATIONS_SUCCESS = typeof COUNT_ORGANISATIONS_SUCCESS;

export const COUNT_ORGANISATIONS_FAILED = 'COUNT_ORGANISATIONS_FAILED';
export type COUNT_ORGANISATIONS_FAILED = typeof COUNT_ORGANISATIONS_FAILED;

export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export type UPDATE_ORGANISATION = typeof UPDATE_ORGANISATION;

export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export type UPDATE_ORGANISATION_SUCCESS = typeof UPDATE_ORGANISATION_SUCCESS;

export const UPDATE_ORGANISATION_FAILED = 'UPDATE_ORGANISATION_FAILED';
export type UPDATE_ORGANISATION_FAILED = typeof UPDATE_ORGANISATION_FAILED;

export const FETCH_ORGANISATION_MEMBERS = 'FETCH_ORGANISATION_MEMBERS';
export type FETCH_ORGANISATION_MEMBERS = typeof FETCH_ORGANISATION_MEMBERS;

export const FETCH_ORGANISATION_MEMBERS_SUCCESS =
  'FETCH_ORGANISATION_MEMBERS_SUCCESS';
export type FETCH_ORGANISATION_MEMBERS_SUCCESS =
  typeof FETCH_ORGANISATION_MEMBERS_SUCCESS;

export const FETCH_ORGANISATION_MEMBERS_FAILED =
  'FETCH_ORGANISATION_MEMBERS_FAILED';
export type FETCH_ORGANISATION_MEMBERS_FAILED =
  typeof FETCH_ORGANISATION_MEMBERS_FAILED;

export const FETCH_ORGANISATION_MEMBER_OPTIONS =
  'FETCH_ORGANISATION_MEMBER_OPTIONS';
export type FETCH_ORGANISATION_MEMBER_OPTIONS =
  typeof FETCH_ORGANISATION_MEMBER_OPTIONS;

export const FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS =
  'FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS';
export type FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS =
  typeof FETCH_ORGANISATION_MEMBER_OPTIONS_SUCCESS;

export const FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED =
  'FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED';
export type FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED =
  typeof FETCH_ORGANISATION_MEMBER_OPTIONS_FAILED;

export const ADD_MEMBER_TO_ORGANISATION = 'ADD_MEMBER_TO_ORGANISATION';
export type ADD_MEMBER_TO_ORGANISATION = typeof ADD_MEMBER_TO_ORGANISATION;

export const ADD_MEMBER_TO_ORGANISATION_SUCCESS =
  'ADD_MEMBER_TO_ORGANISATION_SUCCESS';
export type ADD_MEMBER_TO_ORGANISATION_SUCCESS =
  typeof ADD_MEMBER_TO_ORGANISATION_SUCCESS;

export const ADD_MEMBER_TO_ORGANISATION_FAILED =
  'ADD_MEMBER_TO_ORGANISATION_FAILED';
export type ADD_MEMBER_TO_ORGANISATION_FAILED =
  typeof ADD_MEMBER_TO_ORGANISATION_FAILED;

export const REMOVE_ACCOUNT_FROM_ORGANISATION =
  'REMOVE_ACCOUNT_FROM_ORGANISATION';
export type REMOVE_ACCOUNT_FROM_ORGANISATION =
  typeof REMOVE_ACCOUNT_FROM_ORGANISATION;

export const REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS =
  'REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS';
export type REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS =
  typeof REMOVE_ACCOUNT_FROM_ORGANISATION_SUCCESS;

export const REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED =
  'REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED';
export type REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED =
  typeof REMOVE_ACCOUNT_FROM_ORGANISATION_FAILED;

export const ADD_GATEWAY_TO_ORGANISATION = 'ADD_GATEWAY_TO_ORGANISATION';
export type ADD_GATEWAY_TO_ORGANISATION = typeof ADD_GATEWAY_TO_ORGANISATION;

export const ADD_GATEWAY_TO_ORGANISATION_SUCCESS =
  'ADD_GATEWAY_TO_ORGANISATION_SUCCESS';
export type ADD_GATEWAY_TO_ORGANISATION_SUCCESS =
  typeof ADD_GATEWAY_TO_ORGANISATION_SUCCESS;

export const ADD_GATEWAY_TO_ORGANISATION_FAILED =
  'ADD_GATEWAY_TO_ORGANISATION_FAILED';
export type ADD_GATEWAY_TO_ORGANISATION_FAILED =
  typeof ADD_GATEWAY_TO_ORGANISATION_FAILED;

export const REMOVE_GATEWAY_FROM_ORGANISATION =
  'REMOVE_GATEWAY_FROM_ORGANISATION';
export type REMOVE_GATEWAY_FROM_ORGANISATION =
  typeof REMOVE_GATEWAY_FROM_ORGANISATION;

export const REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS =
  'REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS';
export type REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS =
  typeof REMOVE_GATEWAY_FROM_ORGANISATION_SUCCESS;

export const REMOVE_GATEWAY_FROM_ORGANISATION_FAILED =
  'REMOVE_GATEWAY_FROM_ORGANISATION_FAILED';
export type REMOVE_GATEWAY_FROM_ORGANISATION_FAILED =
  typeof REMOVE_GATEWAY_FROM_ORGANISATION_FAILED;

// FARMS

export const CREATE_FARM = 'CREATE_FARM';
export type CREATE_FARM = typeof CREATE_FARM;

export const CREATE_FARM_SUCCESS = 'CREATE_FARM_SUCCESS';
export type CREATE_FARM_SUCCESS = typeof CREATE_FARM_SUCCESS;

export const CREATE_FARM_FAILED = 'CREATE_FARM_FAILED';
export type CREATE_FARM_FAILED = typeof CREATE_FARM_FAILED;

export const FETCH_FARM = 'FETCH_FARM';
export type FETCH_FARM = typeof FETCH_FARM;

export const FETCH_FARM_SUCCESS = 'FETCH_FARM_SUCCESS';
export type FETCH_FARM_SUCCESS = typeof FETCH_FARM_SUCCESS;

export const FETCH_FARM_FAILED = 'FETCH_FARM_FAILED';
export type FETCH_FARM_FAILED = typeof FETCH_FARM_FAILED;

export const FETCH_FARMS = 'FETCH_FARMS';
export type FETCH_FARMS = typeof FETCH_FARMS;

export const FETCH_FARMS_SUCCESS = 'FETCH_FARMS_SUCCESS';
export type FETCH_FARMS_SUCCESS = typeof FETCH_FARMS_SUCCESS;

export const FETCH_FARMS_FAILED = 'FETCH_FARMS_FAILED';
export type FETCH_FARMS_FAILED = typeof FETCH_FARMS_FAILED;

export const UPDATE_FARM = 'UPDATE_FARM';
export type UPDATE_FARM = typeof UPDATE_FARM;

export const UPDATE_FARM_SUCCESS = 'UPDATE_FARM_SUCCESS';
export type UPDATE_FARM_SUCCESS = typeof UPDATE_FARM_SUCCESS;

export const UPDATE_FARM_FAILED = 'UPDATE_FARM_FAILED';
export type UPDATE_FARM_FAILED = typeof UPDATE_FARM_FAILED;


export const COUNT_FARMS = 'COUNT_FARMS';
export type COUNT_FARMS = typeof COUNT_FARMS;

export const COUNT_FARMS_SUCCESS = 'COUNT_FARMS_SUCCESS';
export type COUNT_FARMS_SUCCESS = typeof COUNT_FARMS_SUCCESS;

export const COUNT_FARMS_FAILED = 'COUNT_FARMS_FAILED';
export type COUNT_FARMS_FAILED = typeof COUNT_FARMS_FAILED;


// PONDS

export const CREATE_POND = 'CREATE_POND';
export type CREATE_POND = typeof CREATE_POND;

export const CREATE_POND_SUCCESS = 'CREATE_POND_SUCCESS';
export type CREATE_POND_SUCCESS = typeof CREATE_POND_SUCCESS;

export const CREATE_POND_FAILED = 'CREATE_POND_FAILED';
export type CREATE_POND_FAILED = typeof CREATE_POND_FAILED;

export const FETCH_POND = 'FETCH_POND';
export type FETCH_POND = typeof FETCH_POND;

export const FETCH_POND_SUCCESS = 'FETCH_POND_SUCCESS';
export type FETCH_POND_SUCCESS = typeof FETCH_POND_SUCCESS;

export const FETCH_POND_FAILED = 'FETCH_POND_FAILED';
export type FETCH_POND_FAILED = typeof FETCH_POND_FAILED;

export const REFRESH_POND = 'REFRESH_POND';
export type REFRESH_POND = typeof REFRESH_POND;

export const REFRESH_POND_SUCCESS = 'REFRESH_POND_SUCCESS';
export type REFRESH_POND_SUCCESS = typeof REFRESH_POND_SUCCESS;

export const REFRESH_POND_FAILED = 'REFRESH_POND_FAILED';
export type REFRESH_POND_FAILED = typeof REFRESH_POND_FAILED;

export const FETCH_PONDS = 'FETCH_PONDS';
export type FETCH_PONDS = typeof FETCH_PONDS;

export const FETCH_PONDS_SUCCESS = 'FETCH_PONDS_SUCCESS';
export type FETCH_PONDS_SUCCESS = typeof FETCH_PONDS_SUCCESS;

export const FETCH_PONDS_FAILED = 'FETCH_PONDS_FAILED';
export type FETCH_PONDS_FAILED = typeof FETCH_PONDS_FAILED;

export const COUNT_PONDS = 'COUNT_PONDS';
export type COUNT_PONDS = typeof COUNT_PONDS;

export const COUNT_PONDS_SUCCESS = 'COUNT_PONDS_SUCCESS';
export type COUNT_PONDS_SUCCESS = typeof COUNT_PONDS_SUCCESS;

export const COUNT_PONDS_FAILED = 'COUNT_PONDS_FAILED';
export type COUNT_PONDS_FAILED = typeof COUNT_PONDS_FAILED;


export const UPDATE_POND = 'UPDATE_POND';
export type UPDATE_POND = typeof UPDATE_POND;

export const UPDATE_POND_SUCCESS = 'UPDATE_POND_SUCCESS';
export type UPDATE_POND_SUCCESS = typeof UPDATE_POND_SUCCESS;

export const UPDATE_POND_FAILED = 'UPDATE_POND_FAILED';
export type UPDATE_POND_FAILED = typeof UPDATE_POND_FAILED;

export const UPDATE_POND_THRESHOLDS = 'UPDATE_POND_THRESHOLDS';
export type UPDATE_POND_THRESHOLDS = typeof UPDATE_POND_THRESHOLDS;

export const UPDATE_POND_THRESHOLDS_SUCCESS = 'UPDATE_POND_THRESHOLDS_SUCCESS';
export type UPDATE_POND_THRESHOLDS_SUCCESS =
  typeof UPDATE_POND_THRESHOLDS_SUCCESS;

export const UPDATE_POND_THRESHOLDS_FAILED = 'UPDATE_POND_THRESHOLDS_FAILED';
export type UPDATE_POND_THRESHOLDS_FAILED =
  typeof UPDATE_POND_THRESHOLDS_FAILED;

export const ADD_SENSOR_TO_POND = 'ADD_SENSOR_TO_POND';
export type ADD_SENSOR_TO_POND = typeof ADD_SENSOR_TO_POND;

export const ADD_SENSOR_TO_POND_SUCCESS = 'ADD_SENSOR_TO_POND_SUCCESS';
export type ADD_SENSOR_TO_POND_SUCCESS = typeof ADD_SENSOR_TO_POND_SUCCESS;

export const ADD_SENSOR_TO_POND_FAILED = 'ADD_SENSOR_TO_POND_FAILED';
export type ADD_SENSOR_TO_POND_FAILED = typeof ADD_SENSOR_TO_POND_FAILED;

export const REMOVE_SENSOR_FROM_POND = 'REMOVE_SENSOR_FROM_POND';
export type REMOVE_SENSOR_FROM_POND = typeof REMOVE_SENSOR_FROM_POND;

export const REMOVE_SENSOR_FROM_POND_SUCCESS =
  'REMOVE_SENSOR_FROM_POND_SUCCESS';
export type REMOVE_SENSOR_FROM_POND_SUCCESS =
  typeof REMOVE_SENSOR_FROM_POND_SUCCESS;

export const REMOVE_SENSOR_FROM_POND_FAILED = 'REMOVE_SENSOR_FROM_POND_FAILED';
export type REMOVE_SENSOR_FROM_POND_FAILED =
  typeof REMOVE_SENSOR_FROM_POND_FAILED;

export const ADD_SENSOR_TO_GATEWAY = 'ADD_SENSOR_TO_GATEWAY';
export type ADD_SENSOR_TO_GATEWAY = typeof ADD_SENSOR_TO_GATEWAY;

export const ADD_SENSOR_TO_GATEWAY_SUCCESS = 'ADD_SENSOR_TO_GATEWAY_SUCCESS';
export type ADD_SENSOR_TO_GATEWAY_SUCCESS =
  typeof ADD_SENSOR_TO_GATEWAY_SUCCESS;

export const ADD_SENSOR_TO_GATEWAY_FAILED = 'ADD_SENSOR_TO_GATEWAY_FAILED';
export type ADD_SENSOR_TO_GATEWAY_FAILED = typeof ADD_SENSOR_TO_GATEWAY_FAILED;

export const REMOVE_SENSOR_FROM_GATEWAY = 'REMOVE_SENSOR_FROM_GATEWAY';
export type REMOVE_SENSOR_FROM_GATEWAY = typeof REMOVE_SENSOR_FROM_GATEWAY;

export const REMOVE_SENSOR_FROM_GATEWAY_SUCCESS =
  'REMOVE_SENSOR_FROM_GATEWAY_SUCCESS';
export type REMOVE_SENSOR_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_SENSOR_FROM_GATEWAY_SUCCESS;

export const REMOVE_SENSOR_FROM_GATEWAY_FAILED =
  'REMOVE_SENSOR_FROM_GATEWAY_FAILED';
export type REMOVE_SENSOR_FROM_GATEWAY_FAILED =
  typeof REMOVE_SENSOR_FROM_GATEWAY_FAILED;

export const ADD_AERATOR_TO_GATEWAY = 'ADD_AERATOR_TO_GATEWAY';
export type ADD_AERATOR_TO_GATEWAY = typeof ADD_AERATOR_TO_GATEWAY;

export const ADD_AERATOR_TO_GATEWAY_SUCCESS = 'ADD_AERATOR_TO_GATEWAY_SUCCESS';
export type ADD_AERATOR_TO_GATEWAY_SUCCESS =
  typeof ADD_AERATOR_TO_GATEWAY_SUCCESS;

export const ADD_AERATOR_TO_GATEWAY_FAILED = 'ADD_AERATOR_TO_GATEWAY_FAILED';
export type ADD_AERATOR_TO_GATEWAY_FAILED =
  typeof ADD_AERATOR_TO_GATEWAY_FAILED;

export const REMOVE_AERATOR_FROM_GATEWAY = 'REMOVE_AERATOR_FROM_GATEWAY';
export type REMOVE_AERATOR_FROM_GATEWAY = typeof REMOVE_AERATOR_FROM_GATEWAY;

export const REMOVE_AERATOR_FROM_GATEWAY_SUCCESS =
  'REMOVE_AERATOR_FROM_GATEWAY_SUCCESS';
export type REMOVE_AERATOR_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_AERATOR_FROM_GATEWAY_SUCCESS;

export const REMOVE_AERATOR_FROM_GATEWAY_FAILED =
  'REMOVE_AERATOR_FROM_GATEWAY_FAILED';
export type REMOVE_AERATOR_FROM_GATEWAY_FAILED =
  typeof REMOVE_AERATOR_FROM_GATEWAY_FAILED;

export const ADD_PUMP_TO_GATEWAY = 'ADD_PUMP_TO_GATEWAY';
export type ADD_PUMP_TO_GATEWAY = typeof ADD_PUMP_TO_GATEWAY;

export const ADD_PUMP_TO_GATEWAY_SUCCESS = 'ADD_PUMP_TO_GATEWAY_SUCCESS';
export type ADD_PUMP_TO_GATEWAY_SUCCESS = typeof ADD_PUMP_TO_GATEWAY_SUCCESS;

export const ADD_PUMP_TO_GATEWAY_FAILED = 'ADD_PUMP_TO_GATEWAY_FAILED';
export type ADD_PUMP_TO_GATEWAY_FAILED = typeof ADD_PUMP_TO_GATEWAY_FAILED;

export const REMOVE_PUMP_FROM_GATEWAY = 'REMOVE_PUMP_FROM_GATEWAY';
export type REMOVE_PUMP_FROM_GATEWAY = typeof REMOVE_PUMP_FROM_GATEWAY;

export const REMOVE_PUMP_FROM_GATEWAY_SUCCESS =
  'REMOVE_PUMP_FROM_GATEWAY_SUCCESS';
export type REMOVE_PUMP_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_PUMP_FROM_GATEWAY_SUCCESS;

export const REMOVE_PUMP_FROM_GATEWAY_FAILED =
  'REMOVE_PUMP_FROM_GATEWAY_FAILED';
export type REMOVE_PUMP_FROM_GATEWAY_FAILED =
  typeof REMOVE_PUMP_FROM_GATEWAY_FAILED;

export const ADD_POND_TO_GATEWAY = 'ADD_POND_TO_GATEWAY';
export type ADD_POND_TO_GATEWAY = typeof ADD_POND_TO_GATEWAY;

export const ADD_POND_TO_GATEWAY_SUCCESS = 'ADD_POND_TO_GATEWAY_SUCCESS';
export type ADD_POND_TO_GATEWAY_SUCCESS = typeof ADD_POND_TO_GATEWAY_SUCCESS;

export const ADD_POND_TO_GATEWAY_FAILED = 'ADD_POND_TO_GATEWAY_FAILED';
export type ADD_POND_TO_GATEWAY_FAILED = typeof ADD_POND_TO_GATEWAY_FAILED;

export const REMOVE_POND_FROM_GATEWAY = 'REMOVE_POND_FROM_GATEWAY';
export type REMOVE_POND_FROM_GATEWAY = typeof REMOVE_POND_FROM_GATEWAY;

export const REMOVE_POND_FROM_GATEWAY_SUCCESS =
  'REMOVE_POND_FROM_GATEWAY_SUCCESS';
export type REMOVE_POND_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_POND_FROM_GATEWAY_SUCCESS;

export const REMOVE_POND_FROM_GATEWAY_FAILED =
  'REMOVE_POND_FROM_GATEWAY_FAILED';
export type REMOVE_POND_FROM_GATEWAY_FAILED =
  typeof REMOVE_POND_FROM_GATEWAY_FAILED;

export const ADD_AERATOR_TO_POND = 'ADD_AERATOR_TO_POND';
export type ADD_AERATOR_TO_POND = typeof ADD_AERATOR_TO_POND;

export const ADD_AERATOR_TO_POND_SUCCESS = 'ADD_AERATOR_TO_POND_SUCCESS';
export type ADD_AERATOR_TO_POND_SUCCESS = typeof ADD_AERATOR_TO_POND_SUCCESS;

export const ADD_AERATOR_TO_POND_FAILED = 'ADD_AERATOR_TO_POND_FAILED';
export type ADD_AERATOR_TO_POND_FAILED = typeof ADD_AERATOR_TO_POND_FAILED;

export const REMOVE_AERATOR_FROM_POND = 'REMOVE_AERATOR_FROM_POND';
export type REMOVE_AERATOR_FROM_POND = typeof REMOVE_AERATOR_FROM_POND;

export const REMOVE_AERATOR_FROM_POND_SUCCESS =
  'REMOVE_AERATOR_FROM_POND_SUCCESS';
export type REMOVE_AERATOR_FROM_POND_SUCCESS =
  typeof REMOVE_AERATOR_FROM_POND_SUCCESS;

export const REMOVE_AERATOR_FROM_POND_FAILED =
  'REMOVE_AERATOR_FROM_POND_FAILED';
export type REMOVE_AERATOR_FROM_POND_FAILED =
  typeof REMOVE_AERATOR_FROM_POND_FAILED;

export const ADD_PUMP_TO_POND = 'ADD_PUMP_TO_POND';
export type ADD_PUMP_TO_POND = typeof ADD_PUMP_TO_POND;

export const ADD_PUMP_TO_POND_SUCCESS = 'ADD_PUMP_TO_POND_SUCCESS';
export type ADD_PUMP_TO_POND_SUCCESS = typeof ADD_PUMP_TO_POND_SUCCESS;

export const ADD_PUMP_TO_POND_FAILED = 'ADD_PUMP_TO_POND_FAILED';
export type ADD_PUMP_TO_POND_FAILED = typeof ADD_PUMP_TO_POND_FAILED;

export const REMOVE_PUMP_FROM_POND = 'REMOVE_PUMP_FROM_POND';
export type REMOVE_PUMP_FROM_POND = typeof REMOVE_PUMP_FROM_POND;

export const REMOVE_PUMP_FROM_POND_SUCCESS = 'REMOVE_PUMP_FROM_POND_SUCCESS';
export type REMOVE_PUMP_FROM_POND_SUCCESS =
  typeof REMOVE_PUMP_FROM_POND_SUCCESS;

export const REMOVE_PUMP_FROM_POND_FAILED = 'REMOVE_PUMP_FROM_POND_FAILED';
export type REMOVE_PUMP_FROM_POND_FAILED = typeof REMOVE_PUMP_FROM_POND_FAILED;

// GATEWAYS

export const CREATE_GATEWAY = 'CREATE_GATEWAY';
export type CREATE_GATEWAY = typeof CREATE_GATEWAY;

export const CREATE_GATEWAY_SUCCESS = 'CREATE_GATEWAY_SUCCESS';
export type CREATE_GATEWAY_SUCCESS = typeof CREATE_GATEWAY_SUCCESS;

export const CREATE_GATEWAY_FAILED = 'CREATE_GATEWAY_FAILED';
export type CREATE_GATEWAY_FAILED = typeof CREATE_GATEWAY_FAILED;

export const FETCH_GATEWAY = 'FETCH_GATEWAY';
export type FETCH_GATEWAY = typeof FETCH_GATEWAY;

export const FETCH_GATEWAY_SUCCESS = 'FETCH_GATEWAY_SUCCESS';
export type FETCH_GATEWAY_SUCCESS = typeof FETCH_GATEWAY_SUCCESS;

export const FETCH_GATEWAY_FAILED = 'FETCH_GATEWAY_FAILED';
export type FETCH_GATEWAY_FAILED = typeof FETCH_GATEWAY_FAILED;

export const FETCH_GATEWAYS = 'FETCH_GATEWAYS';
export type FETCH_GATEWAYS = typeof FETCH_GATEWAYS;

export const FETCH_GATEWAYS_SUCCESS = 'FETCH_GATEWAYS_SUCCESS';
export type FETCH_GATEWAYS_SUCCESS = typeof FETCH_GATEWAYS_SUCCESS;

export const FETCH_GATEWAYS_FAILED = 'FETCH_GATEWAYS_FAILED';
export type FETCH_GATEWAYS_FAILED = typeof FETCH_GATEWAYS_FAILED;

export const UPDATE_GATEWAY = 'UPDATE_GATEWAY';
export type UPDATE_GATEWAY = typeof UPDATE_GATEWAY;

export const UPDATE_GATEWAY_SUCCESS = 'UPDATE_GATEWAY_SUCCESS';
export type UPDATE_GATEWAY_SUCCESS = typeof UPDATE_GATEWAY_SUCCESS;

export const UPDATE_GATEWAY_FAILED = 'UPDATE_GATEWAY_FAILED';
export type UPDATE_GATEWAY_FAILED = typeof UPDATE_GATEWAY_FAILED;

export const SYNCRONIZE_GATEWAY = 'SYNCRONIZE_GATEWAY';
export type SYNCRONIZE_GATEWAY = typeof SYNCRONIZE_GATEWAY;

export const SYNCRONIZE_GATEWAY_SUCCESS = 'SYNCRONIZE_GATEWAY_SUCCESS';
export type SYNCRONIZE_GATEWAY_SUCCESS = typeof SYNCRONIZE_GATEWAY_SUCCESS;

export const SYNCRONIZE_GATEWAY_FAILED = 'SYNCRONIZE_GATEWAY_FAILED';
export type SYNCRONIZE_GATEWAY_FAILED = typeof SYNCRONIZE_GATEWAY_FAILED;

export const CREATE_SENSOR = 'CREATE_SENSOR';
export type CREATE_SENSOR = typeof CREATE_SENSOR;

export const CREATE_SENSOR_SUCCESS = 'CREATE_SENSOR_SUCCESS';
export type CREATE_SENSOR_SUCCESS = typeof CREATE_SENSOR_SUCCESS;

export const CREATE_SENSOR_FAILED = 'CREATE_SENSOR_FAILED';
export type CREATE_SENSOR_FAILED = typeof CREATE_SENSOR_FAILED;

export const FETCH_SENSOR = 'FETCH_SENSOR';
export type FETCH_SENSOR = typeof FETCH_SENSOR;

export const FETCH_SENSOR_SUCCESS = 'FETCH_SENSOR_SUCCESS';
export type FETCH_SENSOR_SUCCESS = typeof FETCH_SENSOR_SUCCESS;

export const FETCH_SENSOR_FAILED = 'FETCH_SENSOR_FAILED';
export type FETCH_SENSOR_FAILED = typeof FETCH_SENSOR_FAILED;

export const FETCH_SENSOR_DATA_AGGREGATIONS = 'FETCH_SENSOR_DATA_AGGREGATIONS';
export type FETCH_SENSOR_DATA_AGGREGATIONS =
  typeof FETCH_SENSOR_DATA_AGGREGATIONS;

export const FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS =
  'FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS';
export type FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS =
  typeof FETCH_SENSOR_DATA_AGGREGATIONS_SUCCESS;

export const FETCH_SENSOR_DATA_AGGREGATIONS_FAILED =
  'FETCH_SENSOR_DATA_AGGREGATIONS_FAILED';
export type FETCH_SENSOR_DATA_AGGREGATIONS_FAILED =
  typeof FETCH_SENSOR_DATA_AGGREGATIONS_FAILED;

export const FETCH_SENSORS = 'FETCH_SENSORS';
export type FETCH_SENSORS = typeof FETCH_SENSORS;

export const FETCH_SENSORS_SUCCESS = 'FETCH_SENSORS_SUCCESS';
export type FETCH_SENSORS_SUCCESS = typeof FETCH_SENSORS_SUCCESS;

export const FETCH_SENSORS_FAILED = 'FETCH_SENSORS_FAILED';
export type FETCH_SENSORS_FAILED = typeof FETCH_SENSORS_FAILED;

export const FETCH_ORGANISATION_SENSORS = 'FETCH_ORGANISATION_SENSORS';
export type FETCH_ORGANISATION_SENSORS = typeof FETCH_ORGANISATION_SENSORS;

export const FETCH_ORGANISATION_SENSORS_SUCCESS =
  'FETCH_ORGANISATION_SENSORS_SUCCESS';
export type FETCH_ORGANISATION_SENSORS_SUCCESS =
  typeof FETCH_ORGANISATION_SENSORS_SUCCESS;

export const FETCH_ORGANISATION_SENSORS_FAILED =
  'FETCH_ORGANISATION_SENSORS_FAILED';
export type FETCH_ORGANISATION_SENSORS_FAILED =
  typeof FETCH_ORGANISATION_SENSORS_FAILED;

export const UPDATE_SENSOR = 'UPDATE_SENSOR';
export type UPDATE_SENSOR = typeof UPDATE_SENSOR;

export const UPDATE_SENSOR_SUCCESS = 'UPDATE_SENSOR_SUCCESS';
export type UPDATE_SENSOR_SUCCESS = typeof UPDATE_SENSOR_SUCCESS;

export const UPDATE_SENSOR_FAILED = 'UPDATE_SENSOR_FAILED';
export type UPDATE_SENSOR_FAILED = typeof UPDATE_SENSOR_FAILED;

export const ADD_SENSOR_TO_ORGANISATION = 'ADD_SENSOR_TO_ORGANISATION';
export type ADD_SENSOR_TO_ORGANISATION = typeof ADD_SENSOR_TO_ORGANISATION;

export const ADD_SENSOR_TO_ORGANISATION_SUCCESS =
  'ADD_SENSOR_TO_ORGANISATION_SUCCESS';
export type ADD_SENSOR_TO_ORGANISATION_SUCCESS =
  typeof ADD_SENSOR_TO_ORGANISATION_SUCCESS;

export const ADD_SENSOR_TO_ORGANISATION_FAILED =
  'ADD_SENSOR_TO_ORGANISATION_FAILED';
export type ADD_SENSOR_TO_ORGANISATION_FAILED =
  typeof ADD_SENSOR_TO_ORGANISATION_FAILED;

export const REMOVE_SENSOR_FROM_ORGANISATION =
  'REMOVE_SENSOR_FROM_ORGANISATION';
export type REMOVE_SENSOR_FROM_ORGANISATION =
  typeof REMOVE_SENSOR_FROM_ORGANISATION;

export const REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS =
  'REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS';
export type REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS =
  typeof REMOVE_SENSOR_FROM_ORGANISATION_SUCCESS;

export const REMOVE_SENSOR_FROM_ORGANISATION_FAILED =
  'REMOVE_SENSOR_FROM_ORGANISATION_FAILED';
export type REMOVE_SENSOR_FROM_ORGANISATION_FAILED =
  typeof REMOVE_SENSOR_FROM_ORGANISATION_FAILED;

// AERATORS

export const CREATE_AERATOR = 'CREATE_AERATOR';
export type CREATE_AERATOR = typeof CREATE_AERATOR;

export const CREATE_AERATOR_SUCCESS = 'CREATE_AERATOR_SUCCESS';
export type CREATE_AERATOR_SUCCESS = typeof CREATE_AERATOR_SUCCESS;

export const CREATE_AERATOR_FAILED = 'CREATE_AERATOR_FAILED';
export type CREATE_AERATOR_FAILED = typeof CREATE_AERATOR_FAILED;

export const FETCH_AERATOR = 'FETCH_AERATOR';
export type FETCH_AERATOR = typeof FETCH_AERATOR;

export const FETCH_AERATOR_SUCCESS = 'FETCH_AERATOR_SUCCESS';
export type FETCH_AERATOR_SUCCESS = typeof FETCH_AERATOR_SUCCESS;

export const FETCH_AERATOR_FAILED = 'FETCH_AERATOR_FAILED';
export type FETCH_AERATOR_FAILED = typeof FETCH_AERATOR_FAILED;

export const FETCH_AERATORS = 'FETCH_AERATORS';
export type FETCH_AERATORS = typeof FETCH_AERATORS;

export const FETCH_AERATORS_SUCCESS = 'FETCH_AERATORS_SUCCESS';
export type FETCH_AERATORS_SUCCESS = typeof FETCH_AERATORS_SUCCESS;

export const FETCH_AERATORS_FAILED = 'FETCH_AERATORS_FAILED';
export type FETCH_AERATORS_FAILED = typeof FETCH_AERATORS_FAILED;

export const UPDATE_AERATOR = 'UPDATE_AERATOR';
export type UPDATE_AERATOR = typeof UPDATE_AERATOR;

export const UPDATE_AERATOR_SUCCESS = 'UPDATE_AERATOR_SUCCESS';
export type UPDATE_AERATOR_SUCCESS = typeof UPDATE_AERATOR_SUCCESS;

export const UPDATE_AERATOR_FAILED = 'UPDATE_AERATOR_FAILED';
export type UPDATE_AERATOR_FAILED = typeof UPDATE_AERATOR_FAILED;

// CONTROLLERS

export const CREATE_CONTROLLER = 'CREATE_CONTROLLER';
export type CREATE_CONTROLLER = typeof CREATE_CONTROLLER;

export const CREATE_CONTROLLER_SUCCESS = 'CREATE_CONTROLLER_SUCCESS';
export type CREATE_CONTROLLER_SUCCESS = typeof CREATE_CONTROLLER_SUCCESS;

export const CREATE_CONTROLLER_FAILED = 'CREATE_CONTROLLER_FAILED';
export type CREATE_CONTROLLER_FAILED = typeof CREATE_CONTROLLER_FAILED;

export const FETCH_CONTROLLER = 'FETCH_CONTROLLER';
export type FETCH_CONTROLLER = typeof FETCH_CONTROLLER;

export const FETCH_CONTROLLER_SUCCESS = 'FETCH_CONTROLLER_SUCCESS';
export type FETCH_CONTROLLER_SUCCESS = typeof FETCH_CONTROLLER_SUCCESS;

export const FETCH_CONTROLLER_FAILED = 'FETCH_CONTROLLER_FAILED';
export type FETCH_CONTROLLER_FAILED = typeof FETCH_CONTROLLER_FAILED;

export const FETCH_CONTROLLERS = 'FETCH_CONTROLLERS';
export type FETCH_CONTROLLERS = typeof FETCH_CONTROLLERS;

export const FETCH_CONTROLLERS_SUCCESS = 'FETCH_CONTROLLERS_SUCCESS';
export type FETCH_CONTROLLERS_SUCCESS = typeof FETCH_CONTROLLERS_SUCCESS;

export const FETCH_CONTROLLERS_FAILED = 'FETCH_CONTROLLERS_FAILED';
export type FETCH_CONTROLLERS_FAILED = typeof FETCH_CONTROLLERS_FAILED;

export const UPDATE_CONTROLLER = 'UPDATE_CONTROLLER';
export type UPDATE_CONTROLLER = typeof UPDATE_CONTROLLER;

export const UPDATE_CONTROLLER_SUCCESS = 'UPDATE_CONTROLLER_SUCCESS';
export type UPDATE_CONTROLLER_SUCCESS = typeof UPDATE_CONTROLLER_SUCCESS;

export const UPDATE_CONTROLLER_FAILED = 'UPDATE_CONTROLLER_FAILED';
export type UPDATE_CONTROLLER_FAILED = typeof UPDATE_CONTROLLER_FAILED;

export const UPDATE_BATCH_INFORMATION = 'UPDATE_BATCH_INFORMATION';
export type UPDATE_BATCH_INFORMATION = typeof UPDATE_BATCH_INFORMATION;

export const UPDATE_BATCH_INFORMATION_SUCCESS =
  'UPDATE_BATCH_INFORMATION_SUCCESS';
export type UPDATE_BATCH_INFORMATION_SUCCESS =
  typeof UPDATE_BATCH_INFORMATION_SUCCESS;

export const UPDATE_BATCH_INFORMATION_FAILED =
  'UPDATE_BATCH_INFORMATION_FAILED';
export type UPDATE_BATCH_INFORMATION_FAILED =
  typeof UPDATE_BATCH_INFORMATION_FAILED;

export const SWAP_GATEWAYS = 'SWAP_GATEWAYS';

export type SWAP_GATEWAYS = typeof SWAP_GATEWAYS;

export const SWAP_GATEWAYS_SUCCESS = 'SWAP_GATEWAYS_SUCCESS';

export type SWAP_GATEWAYS_SUCCESS = typeof SWAP_GATEWAYS_SUCCESS;

export const SWAP_GATEWAYS_FAILED = 'SWAP_GATEWAYS_FAILED';

export type SWAP_GATEWAYS_FAILED = typeof SWAP_GATEWAYS_FAILED;
// LOCATIONS

export const FETCH_LOCATION = 'FETCH_LOCATION';
export type FETCH_LOCATION = typeof FETCH_LOCATION;

export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export type FETCH_LOCATION_SUCCESS = typeof FETCH_LOCATION_SUCCESS;

export const FETCH_LOCATION_FAILED = 'FETCH_LOCATION_FAILED';
export type FETCH_LOCATION_FAILED = typeof FETCH_LOCATION_FAILED;

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export type FETCH_LOCATIONS = typeof FETCH_LOCATIONS;

export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export type FETCH_LOCATIONS_SUCCESS = typeof FETCH_LOCATIONS_SUCCESS;

export const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED';
export type FETCH_LOCATIONS_FAILED = typeof FETCH_LOCATIONS_FAILED;

// AERATION_PROFILES

export const FETCH_AERATION_PROFILE = 'FETCH_AERATION_PROFILE';
export type FETCH_AERATION_PROFILE = typeof FETCH_AERATION_PROFILE;

export const FETCH_AERATION_PROFILE_SUCCESS = 'FETCH_AERATION_PROFILE_SUCCESS';
export type FETCH_AERATION_PROFILE_SUCCESS =
  typeof FETCH_AERATION_PROFILE_SUCCESS;

export const FETCH_AERATION_PROFILE_FAILED = 'FETCH_AERATION_PROFILE_FAILED';
export type FETCH_AERATION_PROFILE_FAILED =
  typeof FETCH_AERATION_PROFILE_FAILED;

export const UPDATE_AERATION_PROFILE = 'UPDATE_AERATION_PROFILE';
export type UPDATE_AERATION_PROFILE = typeof UPDATE_AERATION_PROFILE;

export const UPDATE_AERATION_PROFILE_SUCCESS =
  'UPDATE_AERATION_PROFILE_SUCCESS';
export type UPDATE_AERATION_PROFILE_SUCCESS =
  typeof UPDATE_AERATION_PROFILE_SUCCESS;

export const UPDATE_AERATION_PROFILE_FAILED = 'UPDATE_AERATION_PROFILE_FAILED';
export type UPDATE_AERATION_PROFILE_FAILED =
  typeof UPDATE_AERATION_PROFILE_FAILED;

// NOTIFICATION

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export type CREATE_NOTIFICATION = typeof CREATE_NOTIFICATION;

export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export type CREATE_NOTIFICATION_SUCCESS = typeof CREATE_NOTIFICATION_SUCCESS;

export const CREATE_NOTIFICATION_FAILED = 'CREATE_NOTIFICATION_FAILED';
export type CREATE_NOTIFICATION_FAILED = typeof CREATE_NOTIFICATION_FAILED;

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export type FETCH_NOTIFICATIONS = typeof FETCH_NOTIFICATIONS;

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export type FETCH_NOTIFICATIONS_SUCCESS = typeof FETCH_NOTIFICATIONS_SUCCESS;

export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED';
export type FETCH_NOTIFICATIONS_FAILED = typeof FETCH_NOTIFICATIONS_FAILED;

// CYCLES

export const CREATE_CYCLE = 'CREATE_CYCLE';
export type CREATE_CYCLE = typeof CREATE_CYCLE;

export const CREATE_CYCLE_SUCCESS = 'CREATE_CYCLE_SUCCESS';
export type CREATE_CYCLE_SUCCESS = typeof CREATE_CYCLE_SUCCESS;

export const CREATE_CYCLE_FAILED = 'CREATE_CYCLE_FAILED';
export type CREATE_CYCLE_FAILED = typeof CREATE_CYCLE_FAILED;

export const FETCH_CYCLE = 'FETCH_CYCLE';
export type FETCH_CYCLE = typeof FETCH_CYCLE;

export const FETCH_CYCLE_SUCCESS = 'FETCH_CYCLE_SUCCESS';
export type FETCH_CYCLE_SUCCESS = typeof FETCH_CYCLE_SUCCESS;

export const FETCH_CYCLE_FAILED = 'FETCH_CYCLE_FAILED';
export type FETCH_CYCLE_FAILED = typeof FETCH_CYCLE_FAILED;

export const FETCH_CYCLES = 'FETCH_CYCLES';
export type FETCH_CYCLES = typeof FETCH_CYCLES;

export const FETCH_CYCLES_SUCCESS = 'FETCH_CYCLES_SUCCESS';
export type FETCH_CYCLES_SUCCESS = typeof FETCH_CYCLES_SUCCESS;

export const FETCH_CYCLES_FAILED = 'FETCH_CYCLES_FAILED';
export type FETCH_CYCLES_FAILED = typeof FETCH_CYCLES_FAILED;

export const UPDATE_CYCLE = 'UPDATE_CYCLE';
export type UPDATE_CYCLE = typeof UPDATE_CYCLE;

// SAMPLES

export const CREATE_SAMPLE = 'CREATE_SAMPLE';
export type CREATE_SAMPLE = typeof CREATE_SAMPLE;

export const CREATE_SAMPLE_SUCCESS = 'CREATE_SAMPLE_SUCCESS';
export type CREATE_SAMPLE_SUCCESS = typeof CREATE_SAMPLE_SUCCESS;

export const CREATE_SAMPLE_FAILED = 'CREATE_SAMPLE_FAILED';
export type CREATE_SAMPLE_FAILED = typeof CREATE_SAMPLE_FAILED;

export const FETCH_SAMPLE = 'FETCH_SAMPLE';
export type FETCH_SAMPLE = typeof FETCH_SAMPLE;

export const FETCH_SAMPLE_SUCCESS = 'FETCH_SAMPLE_SUCCESS';
export type FETCH_SAMPLE_SUCCESS = typeof FETCH_SAMPLE_SUCCESS;

export const FETCH_SAMPLE_FAILED = 'FETCH_SAMPLE_FAILED';
export type FETCH_SAMPLE_FAILED = typeof FETCH_SAMPLE_FAILED;

export const FETCH_SAMPLES = 'FETCH_SAMPLES';
export type FETCH_SAMPLES = typeof FETCH_SAMPLES;

export const FETCH_SAMPLES_SUCCESS = 'FETCH_SAMPLES_SUCCESS';
export type FETCH_SAMPLES_SUCCESS = typeof FETCH_SAMPLES_SUCCESS;

export const FETCH_SAMPLES_FAILED = 'FETCH_SAMPLES_FAILED';
export type FETCH_SAMPLES_FAILED = typeof FETCH_SAMPLES_FAILED;

export const UPDATE_SAMPLE = 'UPDATE_SAMPLE';
export type UPDATE_SAMPLE = typeof UPDATE_SAMPLE;

export const UPDATE_SAMPLE_SUCCESS = 'UPDATE_SAMPLE_SUCCESS';
export type UPDATE_SAMPLE_SUCCESS = typeof UPDATE_SAMPLE_SUCCESS;

export const UPDATE_SAMPLE_FAILED = 'UPDATE_SAMPLE_FAILED';
export type UPDATE_SAMPLE_FAILED = typeof UPDATE_SAMPLE_FAILED;

export const UPDATE_CYCLE_SUCCESS = 'UPDATE_CYCLE_SUCCESS';
export type UPDATE_CYCLE_SUCCESS = typeof UPDATE_CYCLE_SUCCESS;

export const UPDATE_CYCLE_FAILED = 'UPDATE_CYCLE_FAILED';
export type UPDATE_CYCLE_FAILED = typeof UPDATE_CYCLE_FAILED;

export const DELETE_SAMPLE = 'DELETE_SAMPLE';
export type DELETE_SAMPLE = typeof DELETE_SAMPLE;

export const DELETE_SAMPLE_SUCCESS = 'DELETE_SAMPLE_SUCCESS';
export type DELETE_SAMPLE_SUCCESS = typeof DELETE_SAMPLE_SUCCESS;

export const DELETE_SAMPLE_FAILED = 'DELETE_SAMPLE_FAILED';
export type DELETE_SAMPLE_FAILED = typeof DELETE_SAMPLE_FAILED;

export const FETCH_ORGANISATION_GATEWAYS = 'FETCH_ORGANISATION_GATEWAYS';
export type FETCH_ORGANISATION_GATEWAYS = typeof FETCH_ORGANISATION_GATEWAYS;

export const FETCH_ORGANISATION_GATEWAYS_SUCCESS =
  'FETCH_ORGANISATION_GATEWAYS_SUCCESS';
export type FETCH_ORGANISATION_GATEWAYS_SUCCESS =
  typeof FETCH_ORGANISATION_GATEWAYS_SUCCESS;

export const FETCH_ORGANISATION_GATEWAYS_FAILED =
  'FETCH_ORGANISATION_GATEWAYS_FAILED';
export type FETCH_ORGANISATION_GATEWAYS_FAILED =
  typeof FETCH_ORGANISATION_GATEWAYS_FAILED;

// FEEDS

export const CREATE_FEED = 'CREATE_FEED';
export type CREATE_FEED = typeof CREATE_FEED;

export const CREATE_FEED_SUCCESS = 'CREATE_FEED_SUCCESS';
export type CREATE_FEED_SUCCESS = typeof CREATE_FEED_SUCCESS;

export const CREATE_FEED_FAILED = 'CREATE_FEED_FAILED';
export type CREATE_FEED_FAILED = typeof CREATE_FEED_FAILED;

export const FETCH_FEED = 'FETCH_FEED';
export type FETCH_FEED = typeof FETCH_FEED;

export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS';
export type FETCH_FEED_SUCCESS = typeof FETCH_FEED_SUCCESS;

export const FETCH_FEED_FAILED = 'FETCH_FEED_FAILED';
export type FETCH_FEED_FAILED = typeof FETCH_FEED_FAILED;

export const FETCH_FEEDS = 'FETCH_FEEDS';
export type FETCH_FEEDS = typeof FETCH_FEEDS;

export const FETCH_FEEDS_SUCCESS = 'FETCH_FEEDS_SUCCESS';
export type FETCH_FEEDS_SUCCESS = typeof FETCH_FEEDS_SUCCESS;

export const FETCH_FEEDS_FAILED = 'FETCH_FEEDS_FAILED';
export type FETCH_FEEDS_FAILED = typeof FETCH_FEEDS_FAILED;

export const UPDATE_FEED = 'UPDATE_FEED';
export type UPDATE_FEED = typeof UPDATE_FEED;

export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS';
export type UPDATE_FEED_SUCCESS = typeof UPDATE_FEED_SUCCESS;

export const UPDATE_FEED_FAILED = 'UPDATE_FEED_FAILED';
export type UPDATE_FEED_FAILED = typeof UPDATE_FEED_FAILED;

export const DELETE_FEED = 'DELETE_FEED';
export type DELETE_FEED = typeof DELETE_FEED;

export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS';
export type DELETE_FEED_SUCCESS = typeof DELETE_FEED_SUCCESS;

export const DELETE_FEED_FAILED = 'DELETE_FEED_FAILED';
export type DELETE_FEED_FAILED = typeof DELETE_FEED_FAILED;

// FEED_TABLES

export const CREATE_FEED_TABLE = 'CREATE_FEED_TABLE';
export type CREATE_FEED_TABLE = typeof CREATE_FEED_TABLE;

export const CREATE_FEED_TABLE_SUCCESS = 'CREATE_FEED_TABLE_SUCCESS';
export type CREATE_FEED_TABLE_SUCCESS = typeof CREATE_FEED_TABLE_SUCCESS;

export const CREATE_FEED_TABLE_FAILED = 'CREATE_FEED_TABLE_FAILED';
export type CREATE_FEED_TABLE_FAILED = typeof CREATE_FEED_TABLE_FAILED;

export const FETCH_FEED_TABLE = 'FETCH_FEED_TABLE';
export type FETCH_FEED_TABLE = typeof FETCH_FEED_TABLE;

export const FETCH_FEED_TABLE_SUCCESS = 'FETCH_FEED_TABLE_SUCCESS';
export type FETCH_FEED_TABLE_SUCCESS = typeof FETCH_FEED_TABLE_SUCCESS;

export const FETCH_FEED_TABLE_FAILED = 'FETCH_FEED_TABLE_FAILED';
export type FETCH_FEED_TABLE_FAILED = typeof FETCH_FEED_TABLE_FAILED;

export const FETCH_FEED_TABLES = 'FETCH_FEED_TABLES';
export type FETCH_FEED_TABLES = typeof FETCH_FEED_TABLES;

export const FETCH_FEED_TABLES_SUCCESS = 'FETCH_FEED_TABLES_SUCCESS';
export type FETCH_FEED_TABLES_SUCCESS = typeof FETCH_FEED_TABLES_SUCCESS;

export const FETCH_FEED_TABLES_FAILED = 'FETCH_FEED_TABLES_FAILED';
export type FETCH_FEED_TABLES_FAILED = typeof FETCH_FEED_TABLES_FAILED;

export const UPDATE_FEED_TABLE = 'UPDATE_FEED_TABLE';
export type UPDATE_FEED_TABLE = typeof UPDATE_FEED_TABLE;

export const UPDATE_FEED_TABLE_SUCCESS = 'UPDATE_FEED_TABLE_SUCCESS';
export type UPDATE_FEED_TABLE_SUCCESS = typeof UPDATE_FEED_TABLE_SUCCESS;

export const UPDATE_FEED_TABLE_FAILED = 'UPDATE_FEED_TABLE_FAILED';
export type UPDATE_FEED_TABLE_FAILED = typeof UPDATE_FEED_TABLE_FAILED;

export const DELETE_FEED_TABLE = 'DELETE_FEED_TABLE';
export type DELETE_FEED_TABLE = typeof DELETE_FEED_TABLE;

export const DELETE_FEED_TABLE_SUCCESS = 'DELETE_FEED_TABLE_SUCCESS';
export type DELETE_FEED_TABLE_SUCCESS = typeof DELETE_FEED_TABLE_SUCCESS;

export const DELETE_FEED_TABLE_FAILED = 'DELETE_FEED_TABLE_FAILED';
export type DELETE_FEED_TABLE_FAILED = typeof DELETE_FEED_TABLE_FAILED;

export const UPDATE_FEED_TABLE_ROWS = 'UPDATE_FEED_TABLE_ROWS';
export type UPDATE_FEED_TABLE_ROWS = typeof UPDATE_FEED_TABLE_ROWS;

export const UPDATE_FEED_TABLE_ROWS_SUCCESS = 'UPDATE_FEED_TABLE_ROWS_SUCCESS';
export type UPDATE_FEED_TABLE_ROWS_SUCCESS =
  typeof UPDATE_FEED_TABLE_ROWS_SUCCESS;

export const UPDATE_FEED_TABLE_ROWS_FAILED = 'UPDATE_FEED_TABLE_ROWS_FAILED';
export type UPDATE_FEED_TABLE_ROWS_FAILED =
  typeof UPDATE_FEED_TABLE_ROWS_FAILED;

// CALIBRATION_LOGS

export const FETCH_CALIBRATION_LOG = 'FETCH_CALIBRATION_LOG';
export type FETCH_CALIBRATION_LOG = typeof FETCH_CALIBRATION_LOG;

export const FETCH_CALIBRATION_LOG_SUCCESS = 'FETCH_CALIBRATION_LOG_SUCCESS';
export type FETCH_CALIBRATION_LOG_SUCCESS =
  typeof FETCH_CALIBRATION_LOG_SUCCESS;

export const FETCH_CALIBRATION_LOG_FAILED = 'FETCH_CALIBRATION_LOG_FAILED';
export type FETCH_CALIBRATION_LOG_FAILED = typeof FETCH_CALIBRATION_LOG_FAILED;

export const FETCH_CALIBRATION_LOGS = 'FETCH_CALIBRATION_LOGS';
export type FETCH_CALIBRATION_LOGS = typeof FETCH_CALIBRATION_LOGS;

export const FETCH_CALIBRATION_LOGS_SUCCESS = 'FETCH_CALIBRATION_LOGS_SUCCESS';
export type FETCH_CALIBRATION_LOGS_SUCCESS =
  typeof FETCH_CALIBRATION_LOGS_SUCCESS;

export const FETCH_CALIBRATION_LOGS_FAILED = 'FETCH_CALIBRATION_LOGS_FAILED';
export type FETCH_CALIBRATION_LOGS_FAILED =
  typeof FETCH_CALIBRATION_LOGS_FAILED;

// SMART_SENSORS

export const CREATE_SMART_SENSOR = 'CREATE_SMART_SENSOR';
export type CREATE_SMART_SENSOR = typeof CREATE_SMART_SENSOR;

export const CREATE_SMART_SENSOR_SUCCESS = 'CREATE_SMART_SENSOR_SUCCESS';
export type CREATE_SMART_SENSOR_SUCCESS = typeof CREATE_SMART_SENSOR_SUCCESS;

export const CREATE_SMART_SENSOR_FAILED = 'CREATE_SMART_SENSOR_FAILED';
export type CREATE_SMART_SENSOR_FAILED = typeof CREATE_SMART_SENSOR_FAILED;

export const FETCH_SMART_SENSOR = 'FETCH_SMART_SENSOR';
export type FETCH_SMART_SENSOR = typeof FETCH_SMART_SENSOR;

export const FETCH_SMART_SENSOR_SUCCESS = 'FETCH_SMART_SENSOR_SUCCESS';
export type FETCH_SMART_SENSOR_SUCCESS = typeof FETCH_SMART_SENSOR_SUCCESS;

export const FETCH_SMART_SENSOR_FAILED = 'FETCH_SMART_SENSOR_FAILED';
export type FETCH_SMART_SENSOR_FAILED = typeof FETCH_SMART_SENSOR_FAILED;

export const ADD_SMART_SENSOR_TO_ORGANISATION =
  'ADD_SMART_SENSOR_TO_ORGANISATION';
export type ADD_SMART_SENSOR_TO_ORGANISATION =
  typeof ADD_SMART_SENSOR_TO_ORGANISATION;

export const ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS =
  'ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS';
export type ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS =
  typeof ADD_SMART_SENSOR_TO_ORGANISATION_SUCCESS;

export const ADD_SMART_SENSOR_TO_ORGANISATION_FAILED =
  'ADD_SMART_SENSOR_TO_ORGANISATION_FAILED';
export type ADD_SMART_SENSOR_TO_ORGANISATION_FAILED =
  typeof ADD_SMART_SENSOR_TO_ORGANISATION_FAILED;

export const REMOVE_SMART_SENSOR_FROM_ORGANISATION =
  'REMOVE_SMART_SENSOR_FROM_ORGANISATION';
export type REMOVE_SMART_SENSOR_FROM_ORGANISATION =
  typeof REMOVE_SMART_SENSOR_FROM_ORGANISATION;

export const REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS =
  'REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS';
export type REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS =
  typeof REMOVE_SMART_SENSOR_FROM_ORGANISATION_SUCCESS;

export const REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED =
  'REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED';
export type REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED =
  typeof REMOVE_SMART_SENSOR_FROM_ORGANISATION_FAILED;

export const FETCH_SMART_SENSORS = 'FETCH_SMART_SENSORS';
export type FETCH_SMART_SENSORS = typeof FETCH_SMART_SENSORS;

export const FETCH_SMART_SENSORS_SUCCESS = 'FETCH_SMART_SENSORS_SUCCESS';
export type FETCH_SMART_SENSORS_SUCCESS = typeof FETCH_SMART_SENSORS_SUCCESS;

export const FETCH_SMART_SENSORS_FAILED = 'FETCH_SMART_SENSORS_FAILED';
export type FETCH_SMART_SENSORS_FAILED = typeof FETCH_SMART_SENSORS_FAILED;

export const UPDATE_SMART_SENSOR = 'UPDATE_SMART_SENSOR';
export type UPDATE_SMART_SENSOR = typeof UPDATE_SMART_SENSOR;

export const UPDATE_SMART_SENSOR_SUCCESS = 'UPDATE_SMART_SENSOR_SUCCESS';
export type UPDATE_SMART_SENSOR_SUCCESS = typeof UPDATE_SMART_SENSOR_SUCCESS;

export const UPDATE_SMART_SENSOR_FAILED = 'UPDATE_SMART_SENSOR_FAILED';
export type UPDATE_SMART_SENSOR_FAILED = typeof UPDATE_SMART_SENSOR_FAILED;

export const PAIR_SMART_SENSOR = 'PAIR_SMART_SENSOR';
export type PAIR_SMART_SENSOR = typeof PAIR_SMART_SENSOR;

export const PAIR_SMART_SENSOR_SUCCESS = 'PAIR_SMART_SENSOR_SUCCESS';
export type PAIR_SMART_SENSOR_SUCCESS = typeof PAIR_SMART_SENSOR_SUCCESS;

export const PAIR_SMART_SENSOR_FAILED = 'PAIR_SMART_SENSOR_FAILED';
export type PAIR_SMART_SENSOR_FAILED = typeof PAIR_SMART_SENSOR_FAILED;

export const RESET_SMART_SENSOR = 'RESET_SMART_SENSOR';
export type RESET_SMART_SENSOR = typeof RESET_SMART_SENSOR;

export const RESET_SMART_SENSOR_SUCCESS = 'RESET_SMART_SENSOR_SUCCESS';
export type RESET_SMART_SENSOR_SUCCESS = typeof RESET_SMART_SENSOR_SUCCESS;

export const RESET_SMART_SENSOR_FAILED = 'RESET_SMART_SENSOR_FAILED';
export type RESET_SMART_SENSOR_FAILED = typeof RESET_SMART_SENSOR_FAILED;

export const ADD_SMART_SENSOR_TO_GATEWAY = 'ADD_SMART_SENSOR_TO_GATEWAY';
export type ADD_SMART_SENSOR_TO_GATEWAY = typeof ADD_SMART_SENSOR_TO_GATEWAY;

export const ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS =
  'ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS';
export type ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS =
  typeof ADD_SMART_SENSOR_TO_GATEWAY_SUCCESS;

export const ADD_SMART_SENSOR_TO_GATEWAY_FAILED =
  'ADD_SMART_SENSOR_TO_GATEWAY_FAILED';
export type ADD_SMART_SENSOR_TO_GATEWAY_FAILED =
  typeof ADD_SMART_SENSOR_TO_GATEWAY_FAILED;

export const REMOVE_SMART_SENSOR_FROM_GATEWAY =
  'REMOVE_SMART_SENSOR_FROM_GATEWAY';
export type REMOVE_SMART_SENSOR_FROM_GATEWAY =
  typeof REMOVE_SMART_SENSOR_FROM_GATEWAY;

export const REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS =
  'REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS';
export type REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_SMART_SENSOR_FROM_GATEWAY_SUCCESS;

export const REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED =
  'REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED';
export type REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED =
  typeof REMOVE_SMART_SENSOR_FROM_GATEWAY_FAILED;

export const FETCH_ORGANISATION_SMART_SENSORS =
  'FETCH_ORGANISATION_SMART_SENSORS';
export type FETCH_ORGANISATION_SMART_SENSORS =
  typeof FETCH_ORGANISATION_SMART_SENSORS;

export const FETCH_ORGANISATION_SMART_SENSORS_SUCCESS =
  'FETCH_ORGANISATION_SMART_SENSORS_SUCCESS';
export type FETCH_ORGANISATION_SMART_SENSORS_SUCCESS =
  typeof FETCH_ORGANISATION_SMART_SENSORS_SUCCESS;

export const FETCH_ORGANISATION_SMART_SENSORS_FAILED =
  'FETCH_ORGANISATION_SMART_SENSORS_FAILED';
export type FETCH_ORGANISATION_SMART_SENSORS_FAILED =
  typeof FETCH_ORGANISATION_SMART_SENSORS_FAILED;

// SMART_RELAYS

export const CREATE_SMART_RELAY = 'CREATE_SMART_RELAY';
export type CREATE_SMART_RELAY = typeof CREATE_SMART_RELAY;

export const CREATE_SMART_RELAY_SUCCESS = 'CREATE_SMART_RELAY_SUCCESS';
export type CREATE_SMART_RELAY_SUCCESS = typeof CREATE_SMART_RELAY_SUCCESS;

export const CREATE_SMART_RELAY_FAILED = 'CREATE_SMART_RELAY_FAILED';
export type CREATE_SMART_RELAY_FAILED = typeof CREATE_SMART_RELAY_FAILED;

export const FETCH_SMART_RELAY = 'FETCH_SMART_RELAY';
export type FETCH_SMART_RELAY = typeof FETCH_SMART_RELAY;

export const FETCH_SMART_RELAY_SUCCESS = 'FETCH_SMART_RELAY_SUCCESS';
export type FETCH_SMART_RELAY_SUCCESS = typeof FETCH_SMART_RELAY_SUCCESS;

export const FETCH_SMART_RELAY_FAILED = 'FETCH_SMART_RELAY_FAILED';
export type FETCH_SMART_RELAY_FAILED = typeof FETCH_SMART_RELAY_FAILED;

export const ADD_SMART_RELAY_TO_ORGANISATION =
  'ADD_SMART_RELAY_TO_ORGANISATION';
export type ADD_SMART_RELAY_TO_ORGANISATION =
  typeof ADD_SMART_RELAY_TO_ORGANISATION;

export const ADD_SMART_RELAY_TO_ORGANISATION_SUCCESS =
  'ADD_SMART_RELAY_TO_ORGANISATION_SUCCESS';
export type ADD_SMART_RELAY_TO_ORGANISATION_SUCCESS =
  typeof ADD_SMART_RELAY_TO_ORGANISATION_SUCCESS;

export const ADD_SMART_RELAY_TO_ORGANISATION_FAILED =
  'ADD_SMART_RELAY_TO_ORGANISATION_FAILED';
export type ADD_SMART_RELAY_TO_ORGANISATION_FAILED =
  typeof ADD_SMART_RELAY_TO_ORGANISATION_FAILED;

export const REMOVE_SMART_RELAY_FROM_ORGANISATION =
  'REMOVE_SMART_RELAY_FROM_ORGANISATION';
export type REMOVE_SMART_RELAY_FROM_ORGANISATION =
  typeof REMOVE_SMART_RELAY_FROM_ORGANISATION;

export const REMOVE_SMART_RELAY_FROM_ORGANISATION_SUCCESS =
  'REMOVE_SMART_RELAY_FROM_ORGANISATION_SUCCESS';
export type REMOVE_SMART_RELAY_FROM_ORGANISATION_SUCCESS =
  typeof REMOVE_SMART_RELAY_FROM_ORGANISATION_SUCCESS;

export const REMOVE_SMART_RELAY_FROM_ORGANISATION_FAILED =
  'REMOVE_SMART_RELAY_FROM_ORGANISATION_FAILED';
export type REMOVE_SMART_RELAY_FROM_ORGANISATION_FAILED =
  typeof REMOVE_SMART_RELAY_FROM_ORGANISATION_FAILED;

export const FETCH_SMART_RELAYS = 'FETCH_SMART_RELAYS';
export type FETCH_SMART_RELAYS = typeof FETCH_SMART_RELAYS;

export const FETCH_SMART_RELAYS_SUCCESS = 'FETCH_SMART_RELAYS_SUCCESS';
export type FETCH_SMART_RELAYS_SUCCESS = typeof FETCH_SMART_RELAYS_SUCCESS;

export const FETCH_SMART_RELAYS_FAILED = 'FETCH_SMART_RELAYS_FAILED';
export type FETCH_SMART_RELAYS_FAILED = typeof FETCH_SMART_RELAYS_FAILED;

export const UPDATE_SMART_RELAY = 'UPDATE_SMART_RELAY';
export type UPDATE_SMART_RELAY = typeof UPDATE_SMART_RELAY;

export const UPDATE_SMART_RELAY_SUCCESS = 'UPDATE_SMART_RELAY_SUCCESS';
export type UPDATE_SMART_RELAY_SUCCESS = typeof UPDATE_SMART_RELAY_SUCCESS;

export const UPDATE_SMART_RELAY_FAILED = 'UPDATE_SMART_RELAY_FAILED';
export type UPDATE_SMART_RELAY_FAILED = typeof UPDATE_SMART_RELAY_FAILED;

export const PAIR_SMART_RELAY = 'PAIR_SMART_RELAY';
export type PAIR_SMART_RELAY = typeof PAIR_SMART_RELAY;

export const PAIR_SMART_RELAY_SUCCESS = 'PAIR_SMART_RELAY_SUCCESS';
export type PAIR_SMART_RELAY_SUCCESS = typeof PAIR_SMART_RELAY_SUCCESS;

export const PAIR_SMART_RELAY_FAILED = 'PAIR_SMART_RELAY_FAILED';
export type PAIR_SMART_RELAY_FAILED = typeof PAIR_SMART_RELAY_FAILED;

export const RESET_SMART_RELAY = 'RESET_SMART_RELAY';
export type RESET_SMART_RELAY = typeof RESET_SMART_RELAY;

export const RESET_SMART_RELAY_SUCCESS = 'RESET_SMART_RELAY_SUCCESS';
export type RESET_SMART_RELAY_SUCCESS = typeof RESET_SMART_RELAY_SUCCESS;

export const RESET_SMART_RELAY_FAILED = 'RESET_SMART_RELAY_FAILED';
export type RESET_SMART_RELAY_FAILED = typeof RESET_SMART_RELAY_FAILED;

export const ADD_SMART_RELAY_TO_GATEWAY = 'ADD_SMART_RELAY_TO_GATEWAY';
export type ADD_SMART_RELAY_TO_GATEWAY = typeof ADD_SMART_RELAY_TO_GATEWAY;

export const ADD_SMART_RELAY_TO_GATEWAY_SUCCESS =
  'ADD_SMART_RELAY_TO_GATEWAY_SUCCESS';
export type ADD_SMART_RELAY_TO_GATEWAY_SUCCESS =
  typeof ADD_SMART_RELAY_TO_GATEWAY_SUCCESS;

export const ADD_SMART_RELAY_TO_GATEWAY_FAILED =
  'ADD_SMART_RELAY_TO_GATEWAY_FAILED';
export type ADD_SMART_RELAY_TO_GATEWAY_FAILED =
  typeof ADD_SMART_RELAY_TO_GATEWAY_FAILED;

export const REMOVE_SMART_RELAY_FROM_GATEWAY =
  'REMOVE_SMART_RELAY_FROM_GATEWAY';
export type REMOVE_SMART_RELAY_FROM_GATEWAY =
  typeof REMOVE_SMART_RELAY_FROM_GATEWAY;

export const REMOVE_SMART_RELAY_FROM_GATEWAY_SUCCESS =
  'REMOVE_SMART_RELAY_FROM_GATEWAY_SUCCESS';
export type REMOVE_SMART_RELAY_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_SMART_RELAY_FROM_GATEWAY_SUCCESS;

export const REMOVE_SMART_RELAY_FROM_GATEWAY_FAILED =
  'REMOVE_SMART_RELAY_FROM_GATEWAY_FAILED';
export type REMOVE_SMART_RELAY_FROM_GATEWAY_FAILED =
  typeof REMOVE_SMART_RELAY_FROM_GATEWAY_FAILED;

export const FETCH_ORGANISATION_SMART_RELAYS =
  'FETCH_ORGANISATION_SMART_RELAYS';
export type FETCH_ORGANISATION_SMART_RELAYS =
  typeof FETCH_ORGANISATION_SMART_RELAYS;

export const FETCH_ORGANISATION_SMART_RELAYS_SUCCESS =
  'FETCH_ORGANISATION_SMART_RELAYS_SUCCESS';
export type FETCH_ORGANISATION_SMART_RELAYS_SUCCESS =
  typeof FETCH_ORGANISATION_SMART_RELAYS_SUCCESS;

export const FETCH_ORGANISATION_SMART_RELAYS_FAILED =
  'FETCH_ORGANISATION_SMART_RELAYS_FAILED';
export type FETCH_ORGANISATION_SMART_RELAYS_FAILED =
  typeof FETCH_ORGANISATION_SMART_RELAYS_FAILED;

// LCMS

export const CREATE_LCM = 'CREATE_LCM';
export type CREATE_LCM = typeof CREATE_LCM;

export const CREATE_LCM_SUCCESS = 'CREATE_LCM_SUCCESS';
export type CREATE_LCM_SUCCESS = typeof CREATE_LCM_SUCCESS;

export const CREATE_LCM_FAILED = 'CREATE_LCM_FAILED';
export type CREATE_LCM_FAILED = typeof CREATE_LCM_FAILED;

export const FETCH_LCM = 'FETCH_LCM';
export type FETCH_LCM = typeof FETCH_LCM;

export const FETCH_LCM_SUCCESS = 'FETCH_LCM_SUCCESS';
export type FETCH_LCM_SUCCESS = typeof FETCH_LCM_SUCCESS;

export const FETCH_LCM_FAILED = 'FETCH_LCM_FAILED';
export type FETCH_LCM_FAILED = typeof FETCH_LCM_FAILED;

export const ADD_LCM_TO_ORGANISATION = 'ADD_LCM_TO_ORGANISATION';
export type ADD_LCM_TO_ORGANISATION = typeof ADD_LCM_TO_ORGANISATION;

export const ADD_LCM_TO_ORGANISATION_SUCCESS =
  'ADD_LCM_TO_ORGANISATION_SUCCESS';
export type ADD_LCM_TO_ORGANISATION_SUCCESS =
  typeof ADD_LCM_TO_ORGANISATION_SUCCESS;

export const ADD_LCM_TO_ORGANISATION_FAILED = 'ADD_LCM_TO_ORGANISATION_FAILED';
export type ADD_LCM_TO_ORGANISATION_FAILED =
  typeof ADD_LCM_TO_ORGANISATION_FAILED;

export const REMOVE_LCM_FROM_ORGANISATION = 'REMOVE_LCM_FROM_ORGANISATION';
export type REMOVE_LCM_FROM_ORGANISATION = typeof REMOVE_LCM_FROM_ORGANISATION;

export const REMOVE_LCM_FROM_ORGANISATION_SUCCESS =
  'REMOVE_LCM_FROM_ORGANISATION_SUCCESS';
export type REMOVE_LCM_FROM_ORGANISATION_SUCCESS =
  typeof REMOVE_LCM_FROM_ORGANISATION_SUCCESS;

export const REMOVE_LCM_FROM_ORGANISATION_FAILED =
  'REMOVE_LCM_FROM_ORGANISATION_FAILED';
export type REMOVE_LCM_FROM_ORGANISATION_FAILED =
  typeof REMOVE_LCM_FROM_ORGANISATION_FAILED;

export const FETCH_LCMS = 'FETCH_LCMS';
export type FETCH_LCMS = typeof FETCH_LCMS;

export const FETCH_LCMS_SUCCESS = 'FETCH_LCMS_SUCCESS';
export type FETCH_LCMS_SUCCESS = typeof FETCH_LCMS_SUCCESS;

export const FETCH_LCMS_FAILED = 'FETCH_LCMS_FAILED';
export type FETCH_LCMS_FAILED = typeof FETCH_LCMS_FAILED;

export const UPDATE_LCM = 'UPDATE_LCM';
export type UPDATE_LCM = typeof UPDATE_LCM;

export const UPDATE_LCM_SUCCESS = 'UPDATE_LCM_SUCCESS';
export type UPDATE_LCM_SUCCESS = typeof UPDATE_LCM_SUCCESS;

export const UPDATE_LCM_FAILED = 'UPDATE_LCM_FAILED';
export type UPDATE_LCM_FAILED = typeof UPDATE_LCM_FAILED;

export const ADD_LCM_TO_GATEWAY = 'ADD_LCM_TO_GATEWAY';
export type ADD_LCM_TO_GATEWAY = typeof ADD_LCM_TO_GATEWAY;

export const ADD_LCM_TO_GATEWAY_SUCCESS = 'ADD_LCM_TO_GATEWAY_SUCCESS';
export type ADD_LCM_TO_GATEWAY_SUCCESS = typeof ADD_LCM_TO_GATEWAY_SUCCESS;

export const ADD_LCM_TO_GATEWAY_FAILED = 'ADD_LCM_TO_GATEWAY_FAILED';
export type ADD_LCM_TO_GATEWAY_FAILED = typeof ADD_LCM_TO_GATEWAY_FAILED;

export const REMOVE_LCM_FROM_GATEWAY = 'REMOVE_LCM_FROM_GATEWAY';
export type REMOVE_LCM_FROM_GATEWAY = typeof REMOVE_LCM_FROM_GATEWAY;

export const REMOVE_LCM_FROM_GATEWAY_SUCCESS =
  'REMOVE_LCM_FROM_GATEWAY_SUCCESS';
export type REMOVE_LCM_FROM_GATEWAY_SUCCESS =
  typeof REMOVE_LCM_FROM_GATEWAY_SUCCESS;

export const REMOVE_LCM_FROM_GATEWAY_FAILED = 'REMOVE_LCM_FROM_GATEWAY_FAILED';
export type REMOVE_LCM_FROM_GATEWAY_FAILED =
  typeof REMOVE_LCM_FROM_GATEWAY_FAILED;

export const FETCH_ORGANISATION_LCMS = 'FETCH_ORGANISATION_LCMS';
export type FETCH_ORGANISATION_LCMS = typeof FETCH_ORGANISATION_LCMS;

export const FETCH_ORGANISATION_LCMS_SUCCESS =
  'FETCH_ORGANISATION_LCMS_SUCCESS';
export type FETCH_ORGANISATION_LCMS_SUCCESS =
  typeof FETCH_ORGANISATION_LCMS_SUCCESS;

export const FETCH_ORGANISATION_LCMS_FAILED = 'FETCH_ORGANISATION_LCMS_FAILED';
export type FETCH_ORGANISATION_LCMS_FAILED =
  typeof FETCH_ORGANISATION_LCMS_FAILED;

// PUMPS

export const CREATE_PUMP = 'CREATE_PUMP';
export type CREATE_PUMP = typeof CREATE_PUMP;

export const CREATE_PUMP_SUCCESS = 'CREATE_PUMP_SUCCESS';
export type CREATE_PUMP_SUCCESS = typeof CREATE_PUMP_SUCCESS;

export const CREATE_PUMP_FAILED = 'CREATE_PUMP_FAILED';
export type CREATE_PUMP_FAILED = typeof CREATE_PUMP_FAILED;

export const FETCH_PUMP = 'FETCH_PUMP';
export type FETCH_PUMP = typeof FETCH_PUMP;

export const FETCH_PUMP_SUCCESS = 'FETCH_PUMP_SUCCESS';
export type FETCH_PUMP_SUCCESS = typeof FETCH_PUMP_SUCCESS;

export const FETCH_PUMP_FAILED = 'FETCH_PUMP_FAILED';
export type FETCH_PUMP_FAILED = typeof FETCH_PUMP_FAILED;

export const FETCH_PUMPS = 'FETCH_PUMPS';
export type FETCH_PUMPS = typeof FETCH_PUMPS;

export const FETCH_PUMPS_SUCCESS = 'FETCH_PUMPS_SUCCESS';
export type FETCH_PUMPS_SUCCESS = typeof FETCH_PUMPS_SUCCESS;

export const FETCH_PUMPS_FAILED = 'FETCH_PUMPS_FAILED';
export type FETCH_PUMPS_FAILED = typeof FETCH_PUMPS_FAILED;

export const UPDATE_PUMP = 'UPDATE_PUMP';
export type UPDATE_PUMP = typeof UPDATE_PUMP;

export const UPDATE_PUMP_SUCCESS = 'UPDATE_PUMP_SUCCESS';
export type UPDATE_PUMP_SUCCESS = typeof UPDATE_PUMP_SUCCESS;

export const UPDATE_PUMP_FAILED = 'UPDATE_PUMP_FAILED';
export type UPDATE_PUMP_FAILED = typeof UPDATE_PUMP_FAILED;

// OPTIONS
export const FETCH_SENSOR_OPTIONS = 'FETCH_SENSOR_OPTIONS';
export type FETCH_SENSOR_OPTIONS = typeof FETCH_SENSOR_OPTIONS;

export const FETCH_SENSOR_OPTIONS_SUCCESS = 'FETCH_SENSOR_OPTIONS_SUCCESS';
export type FETCH_SENSOR_OPTIONS_SUCCESS = typeof FETCH_SENSOR_OPTIONS_SUCCESS;

export const FETCH_SENSOR_OPTIONS_FAILED = 'FETCH_SENSOR_OPTIONS_FAILED';
export type FETCH_SENSOR_OPTIONS_FAILED = typeof FETCH_SENSOR_OPTIONS_FAILED;

export const FETCH_SMART_SENSOR_OPTIONS = 'FETCH_SMART_SENSOR_OPTIONS';
export type FETCH_SMART_SENSOR_OPTIONS = typeof FETCH_SMART_SENSOR_OPTIONS;

export const FETCH_SMART_SENSOR_OPTIONS_SUCCESS =
  'FETCH_SMART_SENSOR_OPTIONS_SUCCESS';
export type FETCH_SMART_SENSOR_OPTIONS_SUCCESS =
  typeof FETCH_SMART_SENSOR_OPTIONS_SUCCESS;

export const FETCH_SMART_SENSOR_OPTIONS_FAILED =
  'FETCH_SMART_SENSOR_OPTIONS_FAILED';
export type FETCH_SMART_SENSOR_OPTIONS_FAILED =
  typeof FETCH_SMART_SENSOR_OPTIONS_FAILED;

export const FETCH_MANUFACTURER_OPTIONS = 'FETCH_MANUFACTURER_OPTIONS';
export type FETCH_MANUFACTURER_OPTIONS = typeof FETCH_MANUFACTURER_OPTIONS;

export const FETCH_MANUFACTURER_OPTIONS_SUCCESS =
  'FETCH_MANUFACTURER_OPTIONS_SUCCESS';
export type FETCH_MANUFACTURER_OPTIONS_SUCCESS =
  typeof FETCH_MANUFACTURER_OPTIONS_SUCCESS;

export const FETCH_MANUFACTURER_OPTIONS_FAILED =
  'FETCH_MANUFACTURER_OPTIONS_FAILED';
export type FETCH_MANUFACTURER_OPTIONS_FAILED =
  typeof FETCH_MANUFACTURER_OPTIONS_FAILED;

export const FETCH_ORGANISATION_OPTIONS = 'FETCH_ORGANISATION_OPTIONS';
export type FETCH_ORGANISATION_OPTIONS = typeof FETCH_ORGANISATION_OPTIONS;

export const FETCH_ORGANISATION_OPTIONS_SUCCESS =
  'FETCH_ORGANISATION_OPTIONS_SUCCESS';
export type FETCH_ORGANISATION_OPTIONS_SUCCESS =
  typeof FETCH_ORGANISATION_OPTIONS_SUCCESS;

export const FETCH_ORGANISATION_OPTIONS_FAILED =
  'FETCH_ORGANISATION_OPTIONS_FAILED';
export type FETCH_ORGANISATION_OPTIONS_FAILED =
  typeof FETCH_ORGANISATION_OPTIONS_FAILED;

export const FETCH_POND_OPTIONS = 'FETCH_POND_OPTIONS';
export type FETCH_POND_OPTIONS = typeof FETCH_POND_OPTIONS;

export const FETCH_POND_OPTIONS_SUCCESS = 'FETCH_POND_OPTIONS_SUCCESS';
export type FETCH_POND_OPTIONS_SUCCESS = typeof FETCH_POND_OPTIONS_SUCCESS;

export const FETCH_POND_OPTIONS_FAILED = 'FETCH_POND_OPTIONS_FAILED';
export type FETCH_POND_OPTIONS_FAILED = typeof FETCH_POND_OPTIONS_FAILED;

export const FETCH_SENSOR_CAP_OPTIONS = 'FETCH_SENSOR_CAP_OPTIONS';
export type FETCH_SENSOR_CAP_OPTIONS = typeof FETCH_SENSOR_CAP_OPTIONS;

export const FETCH_SENSOR_CAP_OPTIONS_SUCCESS =
  'FETCH_SENSOR_CAP_OPTIONS_SUCCESS';
export type FETCH_SENSOR_CAP_OPTIONS_SUCCESS =
  typeof FETCH_SENSOR_CAP_OPTIONS_SUCCESS;

export const FETCH_SENSOR_CAP_OPTIONS_FAILED =
  'FETCH_SENSOR_CAP_OPTIONS_FAILED';
export type FETCH_SENSOR_CAP_OPTIONS_FAILED =
  typeof FETCH_SENSOR_CAP_OPTIONS_FAILED;


export const FETCH_GATEWAY_OPTIONS = 'FETCH_GATEWAY_OPTIONS';
export type FETCH_GATEWAY_OPTIONS = typeof FETCH_GATEWAY_OPTIONS;

export const FETCH_GATEWAY_OPTIONS_SUCCESS =
  'FETCH_GATEWAY_OPTIONS_SUCCESS';
export type FETCH_GATEWAY_OPTIONS_SUCCESS =
  typeof FETCH_GATEWAY_OPTIONS_SUCCESS;

export const FETCH_GATEWAY_OPTIONS_FAILED =
  'FETCH_GATEWAY_OPTIONS_FAILED';
export type FETCH_GATEWAY_OPTIONS_FAILED =
  typeof FETCH_GATEWAY_OPTIONS_FAILED;

// PRODUCTS

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export type CREATE_PRODUCT = typeof CREATE_PRODUCT;

export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export type CREATE_PRODUCT_SUCCESS = typeof CREATE_PRODUCT_SUCCESS;

export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';
export type CREATE_PRODUCT_FAILED = typeof CREATE_PRODUCT_FAILED;

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export type FETCH_PRODUCT = typeof FETCH_PRODUCT;

export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export type FETCH_PRODUCT_SUCCESS = typeof FETCH_PRODUCT_SUCCESS;

export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED';
export type FETCH_PRODUCT_FAILED = typeof FETCH_PRODUCT_FAILED;

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export type FETCH_PRODUCTS = typeof FETCH_PRODUCTS;

export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export type FETCH_PRODUCTS_SUCCESS = typeof FETCH_PRODUCTS_SUCCESS;

export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export type FETCH_PRODUCTS_FAILED = typeof FETCH_PRODUCTS_FAILED;

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export type UPDATE_PRODUCT = typeof UPDATE_PRODUCT;

export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export type UPDATE_PRODUCT_SUCCESS = typeof UPDATE_PRODUCT_SUCCESS;

export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export type UPDATE_PRODUCT_FAILED = typeof UPDATE_PRODUCT_FAILED;

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export type DELETE_PRODUCT = typeof DELETE_PRODUCT;

export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export type DELETE_PRODUCT_SUCCESS = typeof DELETE_PRODUCT_SUCCESS;

export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';
export type DELETE_PRODUCT_FAILED = typeof DELETE_PRODUCT_FAILED;


// MANUFACTURERS

export const CREATE_MANUFACTURER = 'CREATE_MANUFACTURER';
export type CREATE_MANUFACTURER = typeof CREATE_MANUFACTURER;

export const CREATE_MANUFACTURER_SUCCESS = 'CREATE_MANUFACTURER_SUCCESS';
export type CREATE_MANUFACTURER_SUCCESS = typeof CREATE_MANUFACTURER_SUCCESS;

export const CREATE_MANUFACTURER_FAILED = 'CREATE_MANUFACTURER_FAILED';
export type CREATE_MANUFACTURER_FAILED = typeof CREATE_MANUFACTURER_FAILED;

export const FETCH_MANUFACTURER = 'FETCH_MANUFACTURER';
export type FETCH_MANUFACTURER = typeof FETCH_MANUFACTURER;

export const FETCH_MANUFACTURER_SUCCESS = 'FETCH_MANUFACTURER_SUCCESS';
export type FETCH_MANUFACTURER_SUCCESS = typeof FETCH_MANUFACTURER_SUCCESS;

export const FETCH_MANUFACTURER_FAILED = 'FETCH_MANUFACTURER_FAILED';
export type FETCH_MANUFACTURER_FAILED = typeof FETCH_MANUFACTURER_FAILED;

export const FETCH_MANUFACTURERS = 'FETCH_MANUFACTURERS';
export type FETCH_MANUFACTURERS = typeof FETCH_MANUFACTURERS;

export const FETCH_MANUFACTURERS_SUCCESS = 'FETCH_MANUFACTURERS_SUCCESS';
export type FETCH_MANUFACTURERS_SUCCESS = typeof FETCH_MANUFACTURERS_SUCCESS;

export const FETCH_MANUFACTURERS_FAILED = 'FETCH_MANUFACTURERS_FAILED';
export type FETCH_MANUFACTURERS_FAILED = typeof FETCH_MANUFACTURERS_FAILED;

export const UPDATE_MANUFACTURER = 'UPDATE_MANUFACTURER';
export type UPDATE_MANUFACTURER = typeof UPDATE_MANUFACTURER;

export const UPDATE_MANUFACTURER_SUCCESS = 'UPDATE_MANUFACTURER_SUCCESS';
export type UPDATE_MANUFACTURER_SUCCESS = typeof UPDATE_MANUFACTURER_SUCCESS;

export const UPDATE_MANUFACTURER_FAILED = 'UPDATE_MANUFACTURER_FAILED';
export type UPDATE_MANUFACTURER_FAILED = typeof UPDATE_MANUFACTURER_FAILED;

export const DELETE_MANUFACTURER = 'DELETE_MANUFACTURER';
export type DELETE_MANUFACTURER = typeof DELETE_MANUFACTURER;

export const DELETE_MANUFACTURER_SUCCESS = 'DELETE_MANUFACTURER_SUCCESS';
export type DELETE_MANUFACTURER_SUCCESS = typeof DELETE_MANUFACTURER_SUCCESS;

export const DELETE_MANUFACTURER_FAILED = 'DELETE_MANUFACTURER_FAILED';
export type DELETE_MANUFACTURER_FAILED = typeof DELETE_MANUFACTURER_FAILED;

// ANNOUCEMENTS

export const CREATE_ANNOUCEMENT = 'CREATE_ANNOUCEMENT';
export type CREATE_ANNOUCEMENT = typeof CREATE_ANNOUCEMENT;

export const CREATE_ANNOUCEMENT_SUCCESS = 'CREATE_ANNOUCEMENT_SUCCESS';
export type CREATE_ANNOUCEMENT_SUCCESS = typeof CREATE_ANNOUCEMENT_SUCCESS;

export const CREATE_ANNOUCEMENT_FAILED = 'CREATE_ANNOUCEMENT_FAILED';
export type CREATE_ANNOUCEMENT_FAILED = typeof CREATE_ANNOUCEMENT_FAILED;

export const FETCH_ANNOUCEMENT = 'FETCH_ANNOUCEMENT';
export type FETCH_ANNOUCEMENT = typeof FETCH_ANNOUCEMENT;

export const FETCH_ANNOUCEMENT_SUCCESS = 'FETCH_ANNOUCEMENT_SUCCESS';
export type FETCH_ANNOUCEMENT_SUCCESS = typeof FETCH_ANNOUCEMENT_SUCCESS;

export const FETCH_ANNOUCEMENT_FAILED = 'FETCH_ANNOUCEMENT_FAILED';
export type FETCH_ANNOUCEMENT_FAILED = typeof FETCH_ANNOUCEMENT_FAILED;

export const FETCH_LATEST_ANNOUCEMENT = 'FETCH_LATEST_ANNOUCEMENT';
export type FETCH_LATEST_ANNOUCEMENT = typeof FETCH_LATEST_ANNOUCEMENT;

export const FETCH_LATEST_ANNOUCEMENT_SUCCESS =
  'FETCH_LATEST_ANNOUCEMENT_SUCCESS';
export type FETCH_LATEST_ANNOUCEMENT_SUCCESS =
  typeof FETCH_LATEST_ANNOUCEMENT_SUCCESS;

export const FETCH_LATEST_ANNOUCEMENT_FAILED =
  'FETCH_LATEST_ANNOUCEMENT_FAILED';
export type FETCH_LATEST_ANNOUCEMENT_FAILED =
  typeof FETCH_LATEST_ANNOUCEMENT_FAILED;

export const FETCH_ANNOUCEMENTS = 'FETCH_ANNOUCEMENTS';
export type FETCH_ANNOUCEMENTS = typeof FETCH_ANNOUCEMENTS;

export const FETCH_ANNOUCEMENTS_SUCCESS = 'FETCH_ANNOUCEMENTS_SUCCESS';
export type FETCH_ANNOUCEMENTS_SUCCESS = typeof FETCH_ANNOUCEMENTS_SUCCESS;

export const FETCH_ANNOUCEMENTS_FAILED = 'FETCH_ANNOUCEMENTS_FAILED';
export type FETCH_ANNOUCEMENTS_FAILED = typeof FETCH_ANNOUCEMENTS_FAILED;

export const UPDATE_ANNOUCEMENT = 'UPDATE_ANNOUCEMENT';
export type UPDATE_ANNOUCEMENT = typeof UPDATE_ANNOUCEMENT;

export const UPDATE_ANNOUCEMENT_SUCCESS = 'UPDATE_ANNOUCEMENT_SUCCESS';
export type UPDATE_ANNOUCEMENT_SUCCESS = typeof UPDATE_ANNOUCEMENT_SUCCESS;

export const UPDATE_ANNOUCEMENT_FAILED = 'UPDATE_ANNOUCEMENT_FAILED';
export type UPDATE_ANNOUCEMENT_FAILED = typeof UPDATE_ANNOUCEMENT_FAILED;

export const DELETE_ANNOUCEMENT = 'DELETE_ANNOUCEMENT';
export type DELETE_ANNOUCEMENT = typeof DELETE_ANNOUCEMENT;

export const DELETE_ANNOUCEMENT_SUCCESS = 'DELETE_ANNOUCEMENT_SUCCESS';
export type DELETE_ANNOUCEMENT_SUCCESS = typeof DELETE_ANNOUCEMENT_SUCCESS;

export const DELETE_ANNOUCEMENT_FAILED = 'DELETE_ANNOUCEMENT_FAILED';
export type DELETE_ANNOUCEMENT_FAILED = typeof DELETE_ANNOUCEMENT_FAILED;

// MESSAGES

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export type CREATE_MESSAGE = typeof CREATE_MESSAGE;

export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export type CREATE_MESSAGE_SUCCESSS = typeof CREATE_MESSAGE_SUCCESS;

export const CREATE_MESSAGE_FAILED = 'CREATE_MESSAGE_FAILED';
export type CREATE_MESSAGE_FAILED = typeof CREATE_MESSAGE_FAILED;

export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export type FETCH_MESSAGE = typeof FETCH_MESSAGE;

export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
export type FETCH_MESSAGE_SUCCESS = typeof FETCH_MESSAGE_SUCCESS;

export const FETCH_MESSAGE_FAILED = 'FETCH_MESSAGE_FAILED';
export type FETCH_MESSAGE_FAILED = typeof FETCH_MESSAGE_FAILED;

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export type FETCH_MESSAGES = typeof FETCH_MESSAGES;

export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export type FETCH_MESSAGES_SUCCESS = typeof FETCH_MESSAGES_SUCCESS;

export const FETCH_MESSAGES_FAILED = 'FETCH_MESSAGES_FAILED';
export type FETCH_MESSAGES_FAILED = typeof FETCH_MESSAGES_FAILED;

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export type UPDATE_MESSAGE = typeof UPDATE_MESSAGE;

export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export type UPDATE_MESSAGE_SUCCESS = typeof UPDATE_MESSAGE_SUCCESS;

export const UPDATE_MESSAGE_FAILED = 'UPDATE_MESSAGE_FAILED';
export type UPDATE_MESSAGE_FAILED = typeof UPDATE_MESSAGE_FAILED;

export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export type DELETE_MESSAGE = typeof DELETE_MESSAGE;

export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export type DELETE_MESSAGE_SUCCESS = typeof DELETE_MESSAGE_SUCCESS;

export const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED';
export type DELETE_MESSAGE_FAILED = typeof DELETE_MESSAGE_FAILED;

// SENSOR_CAPS

export const CREATE_SENSOR_CAP = 'CREATE_SENSOR_CAP';
export type CREATE_SENSOR_CAP = typeof CREATE_SENSOR_CAP;

export const CREATE_SENSOR_CAP_SUCCESS = 'CREATE_SENSOR_CAP_SUCCESS';
export type CREATE_SENSOR_CAP_SUCCESS = typeof CREATE_SENSOR_CAP_SUCCESS;

export const CREATE_SENSOR_CAP_FAILED = 'CREATE_SENSOR_CAP_FAILED';
export type CREATE_SENSOR_CAP_FAILED = typeof CREATE_SENSOR_CAP_FAILED;

export const IMPORT_SENSOR_CAPS = 'IMPORT_SENSOR_CAPS';
export type IMPORT_SENSOR_CAPS = typeof IMPORT_SENSOR_CAPS;

export const IMPORT_SENSOR_CAPS_SUCCESS = 'IMPORT_SENSOR_CAPS_SUCCESS';
export type IMPORT_SENSOR_CAPS_SUCCESS = typeof IMPORT_SENSOR_CAPS_SUCCESS;

export const IMPORT_SENSOR_CAPS_FAILED = 'IMPORT_SENSOR_CAPS_FAILED';
export type IMPORT_SENSOR_CAPS_FAILED = typeof IMPORT_SENSOR_CAPS_FAILED;

export const FETCH_SENSOR_CAP = 'FETCH_SENSOR_CAP';
export type FETCH_SENSOR_CAP = typeof FETCH_SENSOR_CAP;

export const FETCH_SENSOR_CAP_SUCCESS = 'FETCH_SENSOR_CAP_SUCCESS';
export type FETCH_SENSOR_CAP_SUCCESS = typeof FETCH_SENSOR_CAP_SUCCESS;

export const FETCH_SENSOR_CAP_FAILED = 'FETCH_SENSOR_CAP_FAILED';
export type FETCH_SENSOR_CAP_FAILED = typeof FETCH_SENSOR_CAP_FAILED;

export const FETCH_SENSOR_CAPS = 'FETCH_SENSOR_CAPS';
export type FETCH_SENSOR_CAPS = typeof FETCH_SENSOR_CAPS;

export const FETCH_SENSOR_CAPS_SUCCESS = 'FETCH_SENSOR_CAPS_SUCCESS';
export type FETCH_SENSOR_CAPS_SUCCESS = typeof FETCH_SENSOR_CAPS_SUCCESS;

export const FETCH_SENSOR_CAPS_FAILED = 'FETCH_SENSOR_CAPS_FAILED';
export type FETCH_SENSOR_CAPS_FAILED = typeof FETCH_SENSOR_CAPS_FAILED;

export const UPDATE_SENSOR_CAP = 'UPDATE_SENSOR_CAP';
export type UPDATE_SENSOR_CAP = typeof UPDATE_SENSOR_CAP;

export const UPDATE_SENSOR_CAP_SUCCESS = 'UPDATE_SENSOR_CAP_SUCCESS';
export type UPDATE_SENSOR_CAP_SUCCESS = typeof UPDATE_SENSOR_CAP_SUCCESS;

export const UPDATE_SENSOR_CAP_FAILED = 'UPDATE_SENSOR_CAP_FAILED';
export type UPDATE_SENSOR_CAP_FAILED = typeof UPDATE_SENSOR_CAP_FAILED;

export const DELETE_SENSOR_CAP = 'DELETE_SENSOR_CAP';
export type DELETE_SENSOR_CAP = typeof DELETE_SENSOR_CAP;

export const DELETE_SENSOR_CAP_SUCCESS = 'DELETE_SENSOR_CAP_SUCCESS';
export type DELETE_SENSOR_CAP_SUCCESS = typeof DELETE_SENSOR_CAP_SUCCESS;

export const DELETE_SENSOR_CAP_FAILED = 'DELETE_SENSOR_CAP_FAILED';
export type DELETE_SENSOR_CAP_FAILED = typeof DELETE_SENSOR_CAP_FAILED;

// TICKETS

export const CREATE_TICKET = 'CREATE_TICKET';
export type CREATE_TICKET = typeof CREATE_TICKET;

export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export type CREATE_TICKET_SUCCESSS = typeof CREATE_TICKET_SUCCESS;

export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';
export type CREATE_TICKET_FAILED = typeof CREATE_TICKET_FAILED;

export const FETCH_TICKET = 'FETCH_TICKET';
export type FETCH_TICKET = typeof FETCH_TICKET;

export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export type FETCH_TICKET_SUCCESS = typeof FETCH_TICKET_SUCCESS;

export const FETCH_TICKET_FAILED = 'FETCH_TICKET_FAILED';
export type FETCH_TICKET_FAILED = typeof FETCH_TICKET_FAILED;

export const FETCH_TICKETS = 'FETCH_TICKETS';
export type FETCH_TICKETS = typeof FETCH_TICKETS;

export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export type FETCH_TICKETS_SUCCESS = typeof FETCH_TICKETS_SUCCESS;

export const FETCH_TICKETS_FAILED = 'FETCH_TICKETS_FAILED';
export type FETCH_TICKETS_FAILED = typeof FETCH_TICKETS_FAILED;

export const UPDATE_TICKET = 'UPDATE_TICKET';
export type UPDATE_TICKET = typeof UPDATE_TICKET;

export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export type UPDATE_TICKET_SUCCESS = typeof UPDATE_TICKET_SUCCESS;

export const UPDATE_TICKET_FAILED = 'UPDATE_TICKET_FAILED';
export type UPDATE_TICKET_FAILED = typeof UPDATE_TICKET_FAILED;

export const DELETE_TICKET = 'DELETE_TICKET';
export type DELETE_TICKET = typeof DELETE_TICKET;

export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export type DELETE_TICKET_SUCCESS = typeof DELETE_TICKET_SUCCESS;

export const DELETE_TICKET_FAILED = 'DELETE_TICKET_FAILED';
export type DELETE_TICKET_FAILED = typeof DELETE_TICKET_FAILED;

// CALIBRATION

export const CHECK_CALIBRATION = 'CHECK_CALIBRATION';
export type CHECK_CALIBRATION = typeof CHECK_CALIBRATION;

export const CHECK_CALIBRATION_SUCCESS = 'CHECK_CALIBRATION_SUCCESS';
export type CHECK_CALIBRATION_SUCCESS = typeof CHECK_CALIBRATION_SUCCESS;

export const CHECK_CALIBRATION_FAILED = 'CHECK_CALIBRATION_FAILED';
export type CHECK_CALIBRATION_FAILED = typeof CHECK_CALIBRATION_FAILED;

export const TRIGGER_CALIBRATION = 'TRIGGER_CALIBRATION';
export type TRIGGER_CALIBRATION = typeof TRIGGER_CALIBRATION;

export const TRIGGER_CALIBRATION_SUCCESS = 'TRIGGER_CALIBRATION_SUCCESS';
export type TRIGGER_CALIBRATION_SUCCESS = typeof TRIGGER_CALIBRATION_SUCCESS;

export const TRIGGER_CALIBRATION_FAILED = 'TRIGGER_CALIBRATION_FAILED';
export type TRIGGER_CALIBRATION_FAILED = typeof TRIGGER_CALIBRATION_FAILED;

export const CALIBRATION_IN_PROGESS = 'CALIBRATION_IN_PROGESS';
export type CALIBRATION_IN_PROGESS = typeof CALIBRATION_IN_PROGESS;

export const CALIBRATION_WAITING_FOR_NEXT_STEP =
  'CALIBRATION_WAITING_FOR_NEXT_STEP';
export type CALIBRATION_WAITING_FOR_NEXT_STEP =
  typeof CALIBRATION_WAITING_FOR_NEXT_STEP;

export const CALIBRATION_LOG_EVENT_RECEIVED = 'CALIBRATION_LOG_EVENT_RECEIVED';
export type CALIBRATION_LOG_EVENT_RECEIVED =
  typeof CALIBRATION_LOG_EVENT_RECEIVED;

export const CALIBRATION_SUCCESS = 'CALIBRATION_SUCCESS';
export type CALIBRATION_SUCCESS = typeof CALIBRATION_SUCCESS;

export const CALIBRATION_FAILED = 'CALIBRATION_FAILED';
export type CALIBRATION_FAILED = typeof CALIBRATION_FAILED;

// ACTIVATION

export const ACTIVATE_ORGANISATION = 'ACTIVATE_ORGANISATION';
export type ACTIVATE_ORGANISATION = typeof ACTIVATE_ORGANISATION;

export const ACTIVATE_ORGANISATION_SUCCESS = 'ACTIVATE_ORGANISATION_SUCCESS';
export type ACTIVATE_ORGANISATION_SUCCESS =
  typeof ACTIVATE_ORGANISATION_SUCCESS;

export const ACTIVATE_ORGANISATION_FAILED = 'ACTIVATE_ORGANISATION_FAILED';
export type ACTIVATE_ORGANISATION_FAILED = typeof ACTIVATE_ORGANISATION_FAILED;

export const DEACTIVATE_ORGANISATION = 'DEACTIVATE_ORGANISATION';
export type DEACTIVATE_ORGANISATION = typeof DEACTIVATE_ORGANISATION;

export const DEACTIVATE_ORGANISATION_SUCCESS =
  'DEACTIVATE_ORGANISATION_SUCCESS';
export type DEACTIVATE_ORGANISATION_SUCCESS =
  typeof DEACTIVATE_ORGANISATION_SUCCESS;

export const DEACTIVATE_ORGANISATION_FAILED = 'DEACTIVATE_ORGANISATION_FAILED';
export type DEACTIVATE_ORGANISATION_FAILED =
  typeof DEACTIVATE_ORGANISATION_FAILED;

export const ACTIVATE_FARM = 'ACTIVATE_FARM';
export type ACTIVATE_FARM = typeof ACTIVATE_FARM;

export const ACTIVATE_FARM_SUCCESS = 'ACTIVATE_FARM_SUCCESS';
export type ACTIVATE_FARM_SUCCESS = typeof ACTIVATE_FARM_SUCCESS;

export const ACTIVATE_FARM_FAILED = 'ACTIVATE_FARM_FAILED';
export type ACTIVATE_FARM_FAILED = typeof ACTIVATE_FARM_FAILED;

export const DEACTIVATE_FARM = 'DEACTIVATE_FARM';
export type DEACTIVATE_FARM = typeof DEACTIVATE_FARM;

export const DEACTIVATE_FARM_SUCCESS = 'DEACTIVATE_FARM_SUCCESS';
export type DEACTIVATE_FARM_SUCCESS = typeof DEACTIVATE_FARM_SUCCESS;

export const DEACTIVATE_FARM_FAILED = 'DEACTIVATE_FARM_FAILED';
export type DEACTIVATE_FARM_FAILED = typeof DEACTIVATE_FARM_FAILED;

export const ACTIVATE_POND = 'ACTIVATE_POND';
export type ACTIVATE_POND = typeof ACTIVATE_POND;

export const ACTIVATE_POND_SUCCESS = 'ACTIVATE_POND_SUCCESS';
export type ACTIVATE_POND_SUCCESS = typeof ACTIVATE_POND_SUCCESS;

export const ACTIVATE_POND_FAILED = 'ACTIVATE_POND_FAILED';
export type ACTIVATE_POND_FAILED = typeof ACTIVATE_POND_FAILED;

export const DEACTIVATE_POND = 'DEACTIVATE_POND';
export type DEACTIVATE_POND = typeof DEACTIVATE_POND;

export const DEACTIVATE_POND_SUCCESS = 'DEACTIVATE_POND_SUCCESS';
export type DEACTIVATE_POND_SUCCESS = typeof DEACTIVATE_POND_SUCCESS;

export const DEACTIVATE_POND_FAILED = 'DEACTIVATE_POND_FAILED';
export type DEACTIVATE_POND_FAILED = typeof DEACTIVATE_POND_FAILED;

export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export type ACTIVATE_ACCOUNT = typeof ACTIVATE_ACCOUNT;

export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export type ACTIVATE_ACCOUNT_SUCCESS = typeof ACTIVATE_ACCOUNT_SUCCESS;

export const ACTIVATE_ACCOUNT_FAILED = 'ACTIVATE_ACCOUNT_FAILED';
export type ACTIVATE_ACCOUNT_FAILED = typeof ACTIVATE_ACCOUNT_FAILED;

export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT';
export type DEACTIVATE_ACCOUNT = typeof DEACTIVATE_ACCOUNT;

export const DEACTIVATE_ACCOUNT_SUCCESS = 'DEACTIVATE_ACCOUNT_SUCCESS';
export type DEACTIVATE_ACCOUNT_SUCCESS = typeof DEACTIVATE_ACCOUNT_SUCCESS;

export const DEACTIVATE_ACCOUNT_FAILED = 'DEACTIVATE_ACCOUNT_FAILED';
export type DEACTIVATE_ACCOUNT_FAILED = typeof DEACTIVATE_ACCOUNT_FAILED;

export const ACTIVATE_VIP_ACCOUNT = 'ACTIVATE_VIP_ACCOUNT';
export type ACTIVATE_VIP_ACCOUNT = typeof ACTIVATE_VIP_ACCOUNT;

export const ACTIVATE_VIP_ACCOUNT_SUCCESS = 'ACTIVATE_VIP_ACCOUNT_SUCCESS';
export type ACTIVATE_VIP_ACCOUNT_SUCCESS = typeof ACTIVATE_VIP_ACCOUNT_SUCCESS;

export const ACTIVATE_VIP_ACCOUNT_FAILED = 'ACTIVATE_VIP_ACCOUNT_FAILED';
export type ACTIVATE_VIP_ACCOUNT_FAILED = typeof ACTIVATE_VIP_ACCOUNT_FAILED;

export const DEACTIVATE_VIP_ACCOUNT = 'DEACTIVATE_VIP_ACCOUNT';
export type DEACTIVATE_VIP_ACCOUNT = typeof DEACTIVATE_VIP_ACCOUNT;

export const DEACTIVATE_VIP_ACCOUNT_SUCCESS = 'DEACTIVATE_VIP_ACCOUNT_SUCCESS';
export type DEACTIVATE_VIP_ACCOUNT_SUCCESS = typeof DEACTIVATE_VIP_ACCOUNT_SUCCESS;

export const DEACTIVATE_VIP_ACCOUNT_FAILED = 'DEACTIVATE_VIP_ACCOUNT_FAILED';
export type DEACTIVATE_VIP_ACCOUNT_FAILED = typeof DEACTIVATE_VIP_ACCOUNT_FAILED;


export const ACTIVATE_SCORING_ACCOUNT = 'ACTIVATE_SCORING_ACCOUNT';
export type ACTIVATE_SCORING_ACCOUNT = typeof ACTIVATE_SCORING_ACCOUNT;

export const ACTIVATE_SCORING_ACCOUNT_SUCCESS = 'ACTIVATE_SCORING_ACCOUNT_SUCCESS';
export type ACTIVATE_SCORING_ACCOUNT_SUCCESS = typeof ACTIVATE_SCORING_ACCOUNT_SUCCESS;

export const ACTIVATE_SCORING_ACCOUNT_FAILED = 'ACTIVATE_SCORING_ACCOUNT_FAILED';
export type ACTIVATE_SCORING_ACCOUNT_FAILED = typeof ACTIVATE_SCORING_ACCOUNT_FAILED;

export const DEACTIVATE_SCORING_ACCOUNT = 'DEACTIVATE_SCORING_ACCOUNT';
export type DEACTIVATE_SCORING_ACCOUNT = typeof DEACTIVATE_SCORING_ACCOUNT;

export const DEACTIVATE_SCORING_ACCOUNT_SUCCESS = 'DEACTIVATE_SCORING_ACCOUNT_SUCCESS';
export type DEACTIVATE_SCORING_ACCOUNT_SUCCESS = typeof DEACTIVATE_SCORING_ACCOUNT_SUCCESS;

export const DEACTIVATE_SCORING_ACCOUNT_FAILED = 'DEACTIVATE_SCORING_ACCOUNT_FAILED';
export type DEACTIVATE_SCORING_ACCOUNT_FAILED = typeof DEACTIVATE_SCORING_ACCOUNT_FAILED;

import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { cycleApi } from '../../api/cycleApi';
import {
  CREATE_CYCLE,
  CREATE_CYCLE_FAILED,
  CREATE_CYCLE_SUCCESS,
  FETCH_CYCLE,
  FETCH_CYCLE_FAILED,
  FETCH_CYCLE_SUCCESS,
  FETCH_CYCLES,
  FETCH_CYCLES_FAILED,
  FETCH_CYCLES_SUCCESS,
  UPDATE_CYCLE,
  UPDATE_CYCLE_FAILED,
  UPDATE_CYCLE_SUCCESS,
} from '../../constants';
import { CreateCycleCmd, UpdateCycleCmd } from '../../model/cmds';
import { ListCyclesQuery, ListCyclesQueryModel } from '../../model/querys';
import { CyclesState } from '../../model/state';
import { refreshPond } from './ponds';
import {
  cycleCreate,
  cycleCreated,
  cycleCreateFailed,
  cycleFetch,
  cycleFetched,
  cycleFetchFailed,
  CyclesAction,
  cyclesFetch,
  cyclesFetched,
  cyclesFetchFailed,
  cycleUpdate,
  cycleUpdated,
  cycleUpdateFailed,
} from '../actions';

export function createCycle(pondId: string, cmd: CreateCycleCmd) {
  return async (dispatch: Dispatch<CyclesAction, {}, any>) => {
    try {
      dispatch(cycleCreate());
      const cycle = await cycleApi.createCycle(pondId, cmd);
      dispatch(cycleCreated(cycle));
      dispatch(refreshPond(pondId));
    } catch (e) {
      dispatch(cycleCreateFailed(e));
    }
  };
}

export function getCycle(id: string) {
  return async (dispatch: Dispatch<CyclesAction, {}, any>) => {
    try {
      dispatch(cycleFetch());
      const cycle = await cycleApi.fetchCycle(id);
      dispatch(cycleFetched(cycle));
    } catch (e) {
      dispatch(cycleFetchFailed(e));
    }
  };
}

export function refreshCycle(id: string) {
  return async (dispatch: Dispatch<CyclesAction, {}, any>) => {
    try {
      const cycle = await cycleApi.fetchCycle(id);
      dispatch(cycleFetched(cycle));
    } catch (e) {
      dispatch(cycleFetchFailed(e));
    }
  };
}

export function getCycles(query: ListCyclesQuery) {
  return async (dispatch: Dispatch<CyclesAction, {}, any>) => {
    try {
      dispatch(cyclesFetch(query));
      const queryModel: ListCyclesQueryModel = await cycleApi.listCycles(query);
      const simpleCycles = queryModel.cycles;

      dispatch(cyclesFetched(queryModel, simpleCycles));
    } catch (e) {
      dispatch(cyclesFetchFailed(e));
    }
  };
}

export function updateCycle(id: string, cmd: UpdateCycleCmd, pondId?: string) {
  return async (dispatch: Dispatch<CyclesAction, {}, any>) => {
    try {
      dispatch(cycleUpdate());
      const response = await cycleApi.updateCycle(id, cmd);
      dispatch(cycleUpdated(response));
      dispatch(getCycle(id));
      if (pondId) {
        dispatch(refreshPond(pondId));
      }
    } catch (e) {
      dispatch(cycleUpdateFailed(e));
    }
  };
}

export default function cyclesReducer(
  state: CyclesState = {
    create: {
      loading: false,
      error: null,
      cycle: null,
    },
    list: {
      loading: false,
      error: null,
      cycles: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      cycle: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: CyclesAction,
): CyclesState {
  switch (action.type) {
    case CREATE_CYCLE:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, cycle: null },
      };
    case CREATE_CYCLE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          cycle: action.payload,
        },
      };
    case CREATE_CYCLE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          cycle: null,
        },
      };
    case FETCH_CYCLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          cycle: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_CYCLE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          cycle: action.payload,
        },
      };
    case FETCH_CYCLE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          cycle: null,
        },
      };
    case UPDATE_CYCLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_CYCLE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_CYCLE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case FETCH_CYCLES:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          cycles: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_CYCLES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          cycles: action.payload.cycles,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_CYCLES_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          cycles: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { controllerApi } from '../../api/controllerApi';
import {
  CREATE_CONTROLLER,
  CREATE_CONTROLLER_FAILED,
  CREATE_CONTROLLER_SUCCESS,
  FETCH_CONTROLLER,
  FETCH_CONTROLLER_FAILED,
  FETCH_CONTROLLER_SUCCESS,
  FETCH_CONTROLLERS,
  FETCH_CONTROLLERS_FAILED,
  FETCH_CONTROLLERS_SUCCESS,
  UPDATE_CONTROLLER,
  UPDATE_CONTROLLER_FAILED,
  UPDATE_CONTROLLER_SUCCESS,
} from '../../constants';
import { CreateControllerCmd, UpdateControllerCmd } from '../../model/cmds';
import {
  ListControllersQuery,
  ListControllersQueryModel,
} from '../../model/querys';
import { ControllersState } from '../../model/state';
import {
  controllerCreate,
  controllerCreated,
  controllerCreateFailed,
  controllerFetch,
  controllerFetched,
  controllerFetchFailed,
  ControllersAction,
  controllersFetch,
  controllersFetched,
  controllersFetchFailed,
  controllerUpdate,
  controllerUpdated,
  controllerUpdateFailed,
} from '../actions';

export function createController(cmd: CreateControllerCmd) {
  return async (dispatch: Dispatch<ControllersAction, {}, any>) => {
    try {
      dispatch(controllerCreate());
      const controller = await controllerApi.createController(cmd);
      dispatch(controllerCreated(controller));
      //dispatch(getControllers({limit: INT_MAX, offset: 0, organisationIds: [cmd.organisationId]}));
    } catch (e) {
      dispatch(controllerCreateFailed(e));
    }
  };
}

export function getController(id: string) {
  return async (dispatch: Dispatch<ControllersAction, {}, any>) => {
    try {
      dispatch(controllerFetch());
      const controller = await controllerApi.fetchController(id);
      dispatch(controllerFetched(controller));
    } catch (e) {
      dispatch(controllerFetchFailed(e));
    }
  };
}

export function getControllers(query: ListControllersQuery) {
  return async (dispatch: Dispatch<ControllersAction, {}, any>) => {
    try {
      dispatch(controllersFetch(query));
      const queryModel: ListControllersQueryModel = await controllerApi.listControllers(
        query,
      );

      // fetch details for each received controller
      //const controllers = await Promise.all(queryModel.controllers.map(controller => controllerApi.fetchController(controller.id)))

      // fetch details for every pond on a controller
      // const ponds = await Promise.all(controllers.flatMap(controller => {
      //   return controller.ponds.map(pond => pondApi.fetchPond(pond.id))
      // }));

      // const extendedControllers = controllers.map(controller => {
      //   const controllerPonds = controller.ponds.map((pond) =>  ponds.find(p => p.id === pond.id) || pond);
      //   return {
      //     ...controller,
      //     ponds: controllerPonds,
      //   }
      // })

      dispatch(controllersFetched(queryModel, queryModel.controllers));
    } catch (e) {
      dispatch(controllersFetchFailed(e));
    }
  };
}

export function updateController(id: string, cmd: UpdateControllerCmd) {
  return async (dispatch: Dispatch<ControllersAction, {}, any>) => {
    try {
      dispatch(controllerUpdate());
      const response = await controllerApi.updateController(id, cmd);
      dispatch(controllerUpdated(response));
      dispatch(getController(id));
    } catch (e) {
      dispatch(controllerUpdateFailed(e));
    }
  };
}

export default function controllersReducer(
  state: ControllersState = {
    create: {
      loading: false,
      error: null,
      controller: null,
    },
    list: {
      loading: false,
      error: null,
      controllers: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      controller: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: ControllersAction,
): ControllersState {
  switch (action.type) {
    case CREATE_CONTROLLER:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
          controller: null,
        },
      };
    case CREATE_CONTROLLER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          controller: action.payload,
        },
      };
    case CREATE_CONTROLLER_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          controller: null,
        },
      };
    case FETCH_CONTROLLER:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          controller: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_CONTROLLER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          controller: action.payload,
        },
      };
    case FETCH_CONTROLLER_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          controller: null,
        },
      };
    case UPDATE_CONTROLLER:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_CONTROLLER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_CONTROLLER_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    case FETCH_CONTROLLERS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          controllers: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_CONTROLLERS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          controllers: action.payload.controllers,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_CONTROLLERS_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          controllers: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import {
  CreateGatewayCmd,
  UpdateGatewayCmd,
  GatewaySynchronizationCmd,
  AddAeratorToGatewayCmd,
  AddSensorToGatewayCmd,
  AddPondToGatewayCmd,
  AddSmartSensorToGatewayCmd,
  AddLcmToGatewayCmd,
  AddPumpToGatewayCmd,
  UpdateBatchInformationCmd,
} from '../model/cmds';
import { GatewayModel } from '../model/domain';
import { ListGatewaysQuery, ListGatewaysQueryModel } from '../model/querys';

export class GatewayApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createGateway = this.createGateway.bind(this);
    this.fetchGateway = this.fetchGateway.bind(this);
    this.listGateways = this.listGateways.bind(this);
    this.updateGateway = this.updateGateway.bind(this);
    this.synchronizeGateway = this.synchronizeGateway.bind(this);
    this.addAeratorToGateway = this.addAeratorToGateway.bind(this);
  }

  public createGateway(cmd: CreateGatewayCmd): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      CreateGatewayCmd,
      AxiosResponse<GatewayModel>
    >('/v1/gateways', cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchGateway(gatewayId: string): Promise<GatewayModel> {
    return this.get<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listGateways(
    cmd?: ListGatewaysQuery,
  ): Promise<ListGatewaysQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListGatewaysQueryModel,
      AxiosResponse<ListGatewaysQueryModel>
    >('/v1/gateways', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public listOrganisationGateways(
    organisationId: string,
    query?: ListGatewaysQuery,
  ): Promise<ListGatewaysQueryModel> {
    const config = getConfig();
    if (query) {
      config.params = query;
    }
    return this.get<
      ListGatewaysQueryModel,
      AxiosResponse<ListGatewaysQueryModel>
    >(`/v1/organisations/${organisationId}/gateways`, config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateGateway(
    gatewayId: string,
    cmd: UpdateGatewayCmd,
  ): Promise<GatewayModel> {
    return this.patch<
      GatewayModel,
      UpdateGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateGatewayBatchInformation(
    gatewayId: string,
    cmd: UpdateBatchInformationCmd,
  ): Promise<GatewayModel> {
    return this.patch<
      GatewayModel,
      UpdateBatchInformationCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/batchInformation`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public synchronizeGateway(
    gatewayId: string,
    cmd: GatewaySynchronizationCmd,
  ): Promise<void> {
    return this.post<void, GatewaySynchronizationCmd, AxiosResponse<void>>(
      `/v1/gateways/${gatewayId}/synchronize`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addAeratorToGateway(
    gatewayId: string,
    cmd: AddAeratorToGatewayCmd,
  ): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      AddAeratorToGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/aerators`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeAeratorFromGateway(
    gatewayId: string,
    aeratorId: string,
  ): Promise<GatewayModel> {
    return this.delete<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}/aerators/${aeratorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addSensorToGateway(
    gatewayId: string,
    cmd: AddSensorToGatewayCmd,
  ): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      AddSensorToGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/sensors`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeSensorFromGateway(
    gatewayId: string,
    sensorId: string,
  ): Promise<GatewayModel> {
    return this.delete<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}/sensors/${sensorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addPondToGateway(
    gatewayId: string,
    cmd: AddPondToGatewayCmd,
  ): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      AddPondToGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/ponds`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removePondFromGateway(
    gatewayId: string,
    pondId: string,
  ): Promise<GatewayModel> | AxiosError<Error> {
    return this.delete<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}/ponds/${pondId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addSmartSensorToGateway(
    gatewayId: string,
    cmd: AddSmartSensorToGatewayCmd,
  ): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      AddSmartSensorToGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/smartSensors`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeSmartSensorFromGateway(
    gatewayId: string,
    smartSensorId: string,
  ): Promise<GatewayModel> {
    return this.delete<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}/smartSensors/${smartSensorId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addLcmToGateway(
    gatewayId: string,
    cmd: AddLcmToGatewayCmd,
  ): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      AddLcmToGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/lcms`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removeLcmFromGateway(
    gatewayId: string,
    lcmId: string,
  ): Promise<GatewayModel> {
    return this.delete<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}/lcms/${lcmId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public addPumpToGateway(
    gatewayId: string,
    cmd: AddPumpToGatewayCmd,
  ): Promise<GatewayModel> {
    return this.post<
      GatewayModel,
      AddPumpToGatewayCmd,
      AxiosResponse<GatewayModel>
    >(`/v1/gateways/${gatewayId}/pumps`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public removePumpFromGateway(
    gatewayId: string,
    pumpId: string,
  ): Promise<GatewayModel> {
    return this.delete<GatewayModel, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${gatewayId}/pumps/${pumpId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public swapGateways(
    replacementGatewayId: string,
    targetGatewayId: string,
  ): Promise<GatewayModel> {
    return this.patch<GatewayModel, any, AxiosResponse<GatewayModel>>(
      `/v1/gateways/${replacementGatewayId}/replace/${targetGatewayId}`,
      null,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const gatewayApi = new GatewayApi();

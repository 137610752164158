import React, { useState } from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import { Layout, Modal, Tabs } from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { Fab } from 'react-tiny-fab';
import { getNavbarTextColor, getNavbarColor } from '../../util';
import { PlusOutlined } from '@ant-design/icons';
import { AnnouncementsResponsiveTable } from '../announcement/announcementsResponsiveTable';
import { CreateAnnouncementForm } from '../announcement/createAnnouncementForm';
import { TicketsResponsiveTable } from '../ticket/ticketsResponsiveTable';

const { Content } = Layout;
const { TabPane } = Tabs;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

const UnconnectedTickets: React.FC<Props> = ({ location }) => {
  const [isAnnouncementModalVisible, setIsAnnouncementModalVisible] = useState(
    false,
  );
  const [triggerAnnouncementReload, setAnnouncementTriggerReload] = useState(
    false,
  );

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout>
      <Content>
        <TicketsResponsiveTable
          reload={triggerAnnouncementReload}
          onReloaded={() => setAnnouncementTriggerReload(false)}
          queryParams={queryParams}
        />

        {/* {!isAnnouncementModalVisible && (
          <Fab
            style={{ bottom: 0, right: 0 }}
            mainButtonStyles={{
              backgroundColor: getNavbarColor(),
              color: getNavbarTextColor(),
            }}
            icon={<PlusOutlined />}
            alwaysShowTitle={false}
            onClick={() => {
              setIsAnnouncementModalVisible(true);
            }}
            text="Create announcement"
          />
        )}
        <Modal
          title="Create announcement"
          visible={isAnnouncementModalVisible}
          onCancel={() => {
            setIsAnnouncementModalVisible(false);
          }}
          footer={null}
        >
          <CreateAnnouncementForm
            onCreated={() => {
              setAnnouncementTriggerReload(true);
              setIsAnnouncementModalVisible(false);
            }}
          />
        </Modal> */}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const Tickets = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnconnectedTickets));

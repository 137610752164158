import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { pondApi } from '../../api/pondApi';
import {
  FETCH_AERATION_PROFILE,
  FETCH_AERATION_PROFILE_FAILED,
  FETCH_AERATION_PROFILE_SUCCESS,
  UPDATE_AERATION_PROFILE,
  UPDATE_AERATION_PROFILE_FAILED,
  UPDATE_AERATION_PROFILE_SUCCESS,
} from '../../constants';
import { UpdateAerationProfileCmd } from '../../model/cmds';
import { AerationProfilesState } from '../../model/state';

import {
  aerationProfileFetch,
  aerationProfileFetched,
  aerationProfileFetchFailed,
  AerationProfilesAction,
  aerationProfileUpdate,
  aerationProfileUpdated,
  aerationProfileUpdateFailed,
} from '../actions';

export function getAerationProfile(id: string) {
  return async (dispatch: Dispatch<AerationProfilesAction, {}, any>) => {
    try {
      dispatch(aerationProfileFetch());
      const aerationProfile = await pondApi.getAerationProfile(id);
      dispatch(aerationProfileFetched(aerationProfile));
    } catch (e) {
      dispatch(aerationProfileFetchFailed(e));
    }
  };
}

export function updateAerationProfile(
  id: string,
  cmd: UpdateAerationProfileCmd,
) {
  return async (dispatch: Dispatch<AerationProfilesAction, {}, any>) => {
    try {
      dispatch(aerationProfileUpdate());
      const response = await pondApi.updateAerationProfile(id, cmd);
      dispatch(aerationProfileUpdated(response));
      dispatch(aerationProfileFetched(response));
    } catch (e) {
      dispatch(aerationProfileUpdateFailed(e));
    }
  };
}

export default function aerationProfilesReducer(
  state: AerationProfilesState = {
    detail: {
      loading: false,
      error: null,
      aerationProfile: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
  },
  action: AerationProfilesAction,
): AerationProfilesState {
  switch (action.type) {
    case FETCH_AERATION_PROFILE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          aerationProfile: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_AERATION_PROFILE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          aerationProfile: action.payload,
        },
      };
    case FETCH_AERATION_PROFILE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          aerationProfile: null,
        },
      };
    case UPDATE_AERATION_PROFILE:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_AERATION_PROFILE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_AERATION_PROFILE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };

    default:
      return state;
  }
}

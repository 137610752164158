import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import {
  CreateAccountCmd,
  UpdateAccountCmd,
  UpdateAccountRoleCmd,
} from '../model/cmds';
import { AccountModel, AuthCredentials, TokenModel } from '../model/domain';
import { ListAccountsQueryModel, ListAccountsQuery, CountAccountsModel } from '../model/querys';

export class AccountApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createAccount = this.createAccount.bind(this);
    this.fetchMe = this.fetchMe.bind(this);
    this.fetchToken = this.fetchToken.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.countAccounts = this.countAccounts.bind(this);
    this.updateAccountRole = this.updateAccountRole.bind(this);
    this.fetchAccounts = this.fetchAccounts.bind(this);
    this.fetchAccount = this.fetchAccount.bind(this);
  }

  public createAccount(cmd: CreateAccountCmd): Promise<AuthCredentials> {
    return this.post<
      AuthCredentials,
      CreateAccountCmd,
      AxiosResponse<AuthCredentials>
    >('/v1/accounts', cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateAccount(
    accountId: string,
    cmd: UpdateAccountCmd,
  ): Promise<AccountModel> {
    return this.patch<
      AccountModel,
      UpdateAccountCmd,
      AxiosResponse<AccountModel>
    >(`/v1/accounts/${accountId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateAccountRole(
    accountId: string,
    cmd: UpdateAccountRoleCmd,
  ): Promise<AccountModel> {
    return this.patch<
      AccountModel,
      UpdateAccountRoleCmd,
      AxiosResponse<AccountModel>
    >(`/v1/accounts/roles/${accountId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key} : ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchAccounts(
    cmd?: ListAccountsQuery,
  ): Promise<ListAccountsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<
      ListAccountsQueryModel,
      AxiosResponse<ListAccountsQueryModel>
    >('/v1/accounts', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchAccount(accountId: string): Promise<AccountModel> {
    return this.get<AccountModel, AxiosResponse<AccountModel>>(
      `/v1/accounts/${accountId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchMe(): Promise<AccountModel> {
    return this.get<AccountModel, AxiosResponse<AccountModel>>(
      '/v1/me',
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public fetchToken(accountId: string): Promise<TokenModel> {
    return this.post<TokenModel, Object, AxiosResponse<TokenModel>>(
      `/v1/accounts/${accountId}/_token`,
      {},
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public countAccounts(): Promise<CountAccountsModel> {
    const config = getConfig();
    return this.get<
      CountAccountsModel,
      AxiosResponse<CountAccountsModel>
    >('/v1/count/accounts', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }
}

export const accountApi = new AccountApi();

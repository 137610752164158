import React from 'react';
import { RootState } from '../../duck';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  Col,
  Layout,
  Result,
  Popconfirm,
  Button,
  List,
  notification,
  Typography,
  Badge,
} from 'antd';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { UpdateManufacturerCmd } from '../../model/cmds';
import {
  deleteManufacturer,
  getManufacturer,
  updateManufacturer,
} from '../../duck/modules/manufacturers';
import { usePrevious } from '../_util/hook';
import { ManufacturerDataForm } from '../manufacturer/manufactorerDataForm';
import { getProducts } from '../../duck/modules/products';
import { INT_MAX } from '../../constants';
const { Content } = Layout;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ manufacturerId: string }>;

const UnconnectedManufacturerDetail: React.FC<Props> = ({
  getManufacturerConnect,
  updateManufacturerConnect,
  manufacturer,
  match,
  loading,
  error,
  updating,
  updateError,
  updateSuccess,
  deleteManufacturerConnect,
  deleteState,
  productState,
  getProductsConntect,
}) => {
  const { manufacturerId } = match.params;

  React.useEffect(() => {
    getManufacturerConnect(manufacturerId);
    getProductsConntect({
      limit: INT_MAX,
      offset: 0,
      manufacturesIds: [manufacturerId],
    });
  }, []);

  const prev = usePrevious({
    deleteSuccess: deleteState.success,
    deleteError: deleteState.error,
  });

  React.useEffect(() => {
    if (prev?.deleteSuccess === null && deleteState.success) {
      notification.success({
        message: 'Manufacturer deleted',
      });
    }
  }, [deleteState.success]);

  React.useEffect(() => {
    if (prev?.deleteError === null && deleteState.error) {
      notification.error({
        message: 'Manufacturer deletion error',
        description: deleteState.error?.message,
      });
    }
  }, [deleteState.error]);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load manufacturer"
        subTitle={error.message}
      />
    );
  }

  const renderGeneralDataCardContent = () => {
    if (manufacturer) {
      return (
        <ManufacturerDataForm
          manufacturer={manufacturer}
          updating={updating}
          updateSuccess={updateSuccess}
          updateError={updateError}
          onUpdate={(cmd: UpdateManufacturerCmd) => {
            updateManufacturerConnect(manufacturerId, cmd);
          }}
        />
      );
    }
  };

  const renderDeleteButton = () => {
    if (manufacturer) {
      if (manufacturer.deleted === true) {
        return (
          <Button
            type="ghost"
            danger
            onClick={() =>
              updateManufacturerConnect(manufacturerId, { deleted: false })
            }
          >
            Restore
          </Button>
        );
      }
      return (
        <Popconfirm
          title="Do you really want to delete this manufacturer?"
          onConfirm={() => deleteManufacturerConnect(manufacturerId)}
        >
          <Button type="ghost" danger loading={deleteState.loading}>
            Delete
          </Button>
        </Popconfirm>
      );
    }
  };

  const renderProductList = () => {
    return (
      <List
        loading={productState.loading}
        dataSource={productState.products}
        locale={{ emptyText: 'No Products' }}
        grid={{ gutter: 8, column: 3 }}
        renderItem={(product) => {
          return (
            <List.Item>
              <Link to={`/products/${product.id}`}>
                <Card>
                  <List.Item.Meta
                    title={
                      <Badge
                        status={product.deleted ? 'error' : 'success'}
                        text={product.name}
                      />
                    }
                    description={product.category}
                  />
                </Card>
              </Link>
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{renderDeleteButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="General Data" loading={loading}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Products">{renderProductList()}</Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  manufacturer: state.manufacturers.detail.manufacturer,
  loading: state.manufacturers.detail.loading,
  error: state.manufacturers.detail.error,
  updating: state.manufacturers.detail.updating,
  updateError: state.manufacturers.detail.updateError,
  updateSuccess: state.manufacturers.detail.updateSuccess,
  deleteState: state.manufacturers.delete,

  productState: state.products.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getManufacturerConnect: getManufacturer,
      updateManufacturerConnect: updateManufacturer,
      deleteManufacturerConnect: deleteManufacturer,
      getProductsConntect: getProducts,
    },
    dispatch,
  );
};

export const ManufacturerDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedManufacturerDetail);

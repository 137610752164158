import { combineReducers } from 'redux';
import authReducer from './modules/auth';
import organisationsReducer from './modules/organisations';
import accountsReducer from './modules/accounts';
import farmsReducer from './modules/farms';
import pondsReducer from './modules/ponds';
import gatewaysReducer from './modules/gateways';
import sensingReducer from './modules/sensing';
import aeratorsReducer from './modules/aerators';
import controllersReducer from './modules/controllers';
import locationsReducer from './modules/locations';
import aerationProfilesReducer from './modules/aerationProfiles';
import notificationsReducer from './modules/notifications';
import cyclesReducer from './modules/cycles';
import samplesReducer from './modules/samples';
import feedsReducer from './modules/feeds';
import feedTablesReducer from './modules/feedTables';
import calibrationLogsReducer from './modules/calibrationLogs';
import smartSensorReducer from './modules/smartSensors';
import smartRelaysReducer from './modules/smartRelays';
import lcmReducer from './modules/lcms';
import pumpsReducer from './modules/pumps';
import optionsReducer from './modules/options';
import productsReducer from './modules/products';
import manufacturersReducer from './modules/manufacturers';
import announcementsReducer from './modules/announcements';
import messageReducer from './modules/messages';
import sensorCapsReducer from './modules/sensorCaps';
import ticketReducer from './modules/tickets';
import calibrationReducer from './modules/calibration';
import activationReducer from './modules/activation';

export const rootReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  organisations: organisationsReducer,
  farms: farmsReducer,
  ponds: pondsReducer,
  gateways: gatewaysReducer,
  sensing: sensingReducer,
  aerators: aeratorsReducer,
  controllers: controllersReducer,
  locations: locationsReducer,
  aerationProfiles: aerationProfilesReducer,
  notifications: notificationsReducer,
  cycles: cyclesReducer,
  samples: samplesReducer,
  feeds: feedsReducer,
  feedTables: feedTablesReducer,
  calibrationLogs: calibrationLogsReducer,
  smartSensors: smartSensorReducer,
  smartRelays: smartRelaysReducer,
  lcms: lcmReducer,
  pumps: pumpsReducer,
  options: optionsReducer,
  products: productsReducer,
  manufacturers: manufacturersReducer,
  announcements: announcementsReducer,
  message: messageReducer,
  sensorCaps: sensorCapsReducer,
  tickets: ticketReducer,
  calibration: calibrationReducer,
  activation: activationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

import { ThunkDispatch as Dispatch } from 'redux-thunk';

import { sampleApi } from '../../api/sampleApi';
import {
  CREATE_SAMPLE,
  CREATE_SAMPLE_FAILED,
  CREATE_SAMPLE_SUCCESS,
  FETCH_SAMPLE,
  FETCH_SAMPLE_FAILED,
  FETCH_SAMPLE_SUCCESS,
  FETCH_SAMPLES,
  FETCH_SAMPLES_FAILED,
  FETCH_SAMPLES_SUCCESS,
  UPDATE_SAMPLE,
  UPDATE_SAMPLE_FAILED,
  UPDATE_SAMPLE_SUCCESS,
} from '../../constants';
import { CreateSampleCmd, UpdateSampleCmd } from '../../model/cmds';
import { ListSamplesQuery, ListSamplesQueryModel } from '../../model/querys';
import { SamplesState } from '../../model/state';
import { sampleDelete, sampleDeleted, sampleDeleteFailed } from '../actions';
import {
  DELETE_SAMPLE,
  DELETE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_FAILED,
} from '../../constants';
import {
  sampleCreate,
  sampleCreated,
  sampleCreateFailed,
  sampleFetch,
  sampleFetched,
  sampleFetchFailed,
  SamplesAction,
  samplesFetch,
  samplesFetched,
  samplesFetchFailed,
  sampleUpdate,
  sampleUpdated,
  sampleUpdateFailed,
} from '../actions';

export function createSample(pondId: string, cmd: CreateSampleCmd) {
  return async (dispatch: Dispatch<SamplesAction, {}, any>) => {
    try {
      dispatch(sampleCreate());
      const sample = await sampleApi.createSample(pondId, cmd);
      dispatch(sampleCreated(sample));
    } catch (e) {
      dispatch(sampleCreateFailed(e));
    }
  };
}

export function getSample(id: string) {
  return async (dispatch: Dispatch<SamplesAction, {}, any>) => {
    try {
      dispatch(sampleFetch());
      const sample = await sampleApi.fetchSample(id);
      dispatch(sampleFetched(sample));
    } catch (e) {
      dispatch(sampleFetchFailed(e));
    }
  };
}

export function getSamples(query: ListSamplesQuery) {
  return async (dispatch: Dispatch<SamplesAction, {}, any>) => {
    try {
      dispatch(samplesFetch(query));
      const queryModel: ListSamplesQueryModel = await sampleApi.listSamples(
        query,
      );
      const samples = queryModel.samples;

      dispatch(samplesFetched(queryModel, samples));
    } catch (e) {
      dispatch(samplesFetchFailed(e));
    }
  };
}

export function deleteSample(id: string) {
  return async (dispatch: Dispatch<SamplesAction, {}, any>) => {
    try {
      dispatch(sampleDelete(id));
      await sampleApi.deleteSample(id);
      dispatch(sampleDeleted(id));
    } catch (e) {
      dispatch(sampleDeleteFailed(e));
    }
  };
}

export function updateSample(id: string, cmd: UpdateSampleCmd) {
  return async (dispatch: Dispatch<SamplesAction, {}, any>) => {
    try {
      dispatch(sampleUpdate());
      const response = await sampleApi.updateSample(id, cmd);
      dispatch(sampleUpdated(response));
      dispatch(getSample(id));
    } catch (e) {
      dispatch(sampleUpdateFailed(e));
    }
  };
}

export default function samplesReducer(
  state: SamplesState = {
    create: {
      loading: false,
      error: null,
      sample: null,
    },
    list: {
      loading: false,
      error: null,
      samples: [],
      total: null,
      offset: null,
      limit: null,
    },
    detail: {
      loading: false,
      error: null,
      sample: null,
      updating: false,
      updateSuccess: null,
      updateError: null,
    },
    delete: {
      loading: false,
      error: null,
      success: null,
    },
  },
  action: SamplesAction,
): SamplesState {
  switch (action.type) {
    case CREATE_SAMPLE:
      return {
        ...state,
        create: { ...state.create, loading: true, error: null, sample: null },
      };
    case CREATE_SAMPLE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: null,
          sample: action.payload,
        },
      };
    case CREATE_SAMPLE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.payload,
          sample: null,
        },
      };
    case FETCH_SAMPLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          error: null,
          sample: null,
          updateSuccess: null,
          updateError: null,
        },
      };
    case FETCH_SAMPLE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: null,
          sample: action.payload,
        },
      };
    case FETCH_SAMPLE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          sample: null,
        },
      };
    case UPDATE_SAMPLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: true,
          updateSuccess: null,
          updateError: null,
        },
      };
    case UPDATE_SAMPLE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: true,
          updateError: null,
        },
      };
    case UPDATE_SAMPLE_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          updating: false,
          updateSuccess: false,
          updateError: action.payload,
        },
      };
    case DELETE_SAMPLE:
      return {
        ...state,
        delete: { ...state.delete, loading: true, error: null, success: null },
      };
    case DELETE_SAMPLE_SUCCESS:
      return {
        ...state,
        delete: { ...state.delete, loading: false, error: null, success: true },
      };
    case DELETE_SAMPLE_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: action.payload,
          success: null,
        },
      };
    case FETCH_SAMPLES:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          samples: [],
          limit: action.payload.limit,
          offset: action.payload.offset,
          total: null,
        },
      };

    case FETCH_SAMPLES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          samples: action.payload.samples,
          limit: action.payload.query.limit,
          offset: action.payload.query.offset,
          total: action.payload.query.total,
        },
      };

    case FETCH_SAMPLES_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
          samples: [],
          limit: null,
          offset: null,
          total: null,
        },
      };
    default:
      return state;
  }
}

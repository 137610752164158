import {
  Button,
  Col,
  Descriptions,
  Empty,
  Form,
  List,
  Modal,
  notification,
  Avatar,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';

import { RootState } from '../../duck';
import {
  getOrganisationSensors,
  removeSensorFromOrganisation,
} from '../../duck/modules/sensing';
import { SensorModel, GatewayModel } from '../../model/domain';
import { MqttConnectionStatus, SensorType } from '../../model/enums';
import { CreateSensorForm } from '../sensor/createSensorForm';
import { getSensorNameToType } from '../../util';
import { functionalColor } from '../../colors';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getOrganisationGateways } from '../../duck/modules/gateways';
import { AddGatewayToOrganisationForm } from './addGatewayToOrganisationForm';
import { removeGatewayFromOrganisation } from '../../duck/modules/organisations';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    organisationId: string;
  };

const UnconnectedOrganisationGatewaysList: React.FC<Props> = ({
  organisationId,
  organisationGatewaysState,
  addGatewayState,
  removeGatewayState,
  getOrganisationGatewaysConnect,
  removeGatewayFromOrganisationConnect,
}) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    getOrganisationGatewaysConnect(organisationId);
  }, []);

  React.useEffect(() => {
    if (removeGatewayState.organisation) {
      notification.success({
        message: 'Gateway removed',
      });
      getOrganisationGatewaysConnect(organisationId);
    }
  }, [removeGatewayState.organisation]);

  React.useEffect(() => {
    if (removeGatewayState.error) {
      notification.error({
        message: 'Error while removing Gateway',
        description: removeGatewayState.error?.message,
      });
    }
  }, [removeGatewayState.error]);

  const renderGateway = (gateway: GatewayModel) => {
    return (
      <List.Item>
        <List.Item.Meta
          description={
            <Descriptions bordered column={1}>
              {gateway.name && (
                <Descriptions.Item
                  label={
                    <Space>
                      <Typography.Text>{'Name'}</Typography.Text>
                      {gateway.name && gateway.name.inSync && (
                        <Tooltip title={'Synchronized'}>
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      )}
                      {gateway.name && !gateway.name.inSync && (
                        <Tooltip title={`Syncing...`}>
                          <WarningOutlined
                            style={{
                              color: functionalColor.warning,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Space>
                  }
                >
                  <Typography.Text strong>
                    {gateway.name ? gateway.name.actual : ''}
                  </Typography.Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item label={'ID'}>
                <Link to={`/gateways/${gateway.id}`}>{gateway.id}</Link>
              </Descriptions.Item>

              <Descriptions.Item label={'Type'}>
                {gateway.type}
              </Descriptions.Item>

              <Descriptions.Item label={'Connection'}>
                {gateway.mqttConnectionStatus ===
                MqttConnectionStatus.CONNECTED ? (
                  <Tag color={functionalColor.success}>Online</Tag>
                ) : (
                  <Tooltip
                    title={moment(gateway.mqttConnectionTimestamp).fromNow()}
                  >
                    <span>
                      <Tag color={functionalColor.error}>Offline</Tag>
                    </span>
                  </Tooltip>
                )}
              </Descriptions.Item>

              <Descriptions.Item label={'Number of ponds'}>
                <Typography>{gateway.ponds.actual.length}</Typography>
              </Descriptions.Item>

              <Descriptions.Item
                label={<Typography.Text>{'Actions'}</Typography.Text>}
              >
                <Space>
                  <Popconfirm
                    title={
                      'Are you sure to remove this gateway from the organisation?'
                    }
                    onConfirm={() => {
                      removeGatewayFromOrganisationConnect(
                        organisationId,
                        gateway.id,
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      danger
                      type="default"
                      loading={removeGatewayState.gatewayId === gateway.id}
                    >
                      Remove
                    </Button>
                  </Popconfirm>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          }
        />
      </List.Item>
    );
  };

  const renderListHeader = () => {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Text strong>Gateways</Typography.Text>
        </Col>
        <Col>
          <Button onClick={() => setVisible(true)} type="link">
            Add Gateway
          </Button>
        </Col>
        <Modal
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          title={'Add gateway to organisation'}
        >
          <AddGatewayToOrganisationForm
            organisationId={organisationId}
            onAdded={(_: any) => {
              setVisible(false);
              getOrganisationGatewaysConnect(organisationId);
            }}
            onError={(_error: Error) => {
              // setVisible(false);
            }}
          />
        </Modal>
      </Row>
    );
  };

  return (
    <List
      loading={organisationGatewaysState.loading}
      bordered
      header={renderListHeader()}
      itemLayout="horizontal"
      dataSource={organisationGatewaysState.gateways}
      grid={{
        gutter: 8,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      renderItem={(gateway) => renderGateway(gateway)}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  organisationGatewaysState: state.gateways.organisationGateways,
  addGatewayState: state.organisations.addGateway,
  removeGatewayState: state.organisations.removeGateway,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrganisationGatewaysConnect: getOrganisationGateways,
      removeGatewayFromOrganisationConnect: removeGatewayFromOrganisation,
    },
    dispatch,
  );
};

export const OrganisationGatewaysList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedOrganisationGatewaysList);

import React from 'react';
import { Tag, Typography, Tooltip } from 'antd';
import { SimpleTicketModel } from '../../model/simple';
import { functionalColor } from '../../colors';
import { MqttConnectionStatus, TicketStatus } from '../../model/enums';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const renderStatusTag = (ticket: any) => {
  switch (ticket.status) {
    case TicketStatus.OPEN:
      return <Tag color={functionalColor.warning}>{'OPEN'}</Tag>;
    case TicketStatus.WIP:
      return <Tag color={functionalColor.link}>{'WIP'}</Tag>;

    case TicketStatus.SOLVED:
      return <Tag color={functionalColor.success}>{'SOLVED'}</Tag>;
    default:
      return <Tag color={functionalColor.error}>{ticket.status}</Tag>;
  }
};

export const renderIdLink = (ticket: SimpleTicketModel) => {
  return (
    <Typography>
      {moment(ticket.timestamp).format('DD/MM/yyyy HH:mm')}
    </Typography>
  );
};

export const renderTicketDetailLink = (ticket: SimpleTicketModel) => (
  <Link key={ticket.id} to={`/tickets/${ticket.id}`}>
    {ticket.ticketNumber}
  </Link>
);

export const renderDate = (ticket: SimpleTicketModel) => {
  return (
    <Tooltip title={`${moment(ticket.timestamp).fromNow()}`}>
      <Typography>
        {moment(ticket.timestamp).format('DD/MM/yyyy HH:mm')}
      </Typography>
    </Tooltip>
  );
};

export const renderOrganisationLink = (ticket: SimpleTicketModel) => {
  if (ticket.organisation) {
    return (
      <Link to={`/organisations/${ticket.organisation.id}`}>
        {ticket.organisation.name}
      </Link>
    );
  }
  return <Typography>{'-'}</Typography>;
};

export const renderReporterLink = (ticket: SimpleTicketModel) => {
  if (ticket.reporter) {
    return (
      <Link to={`/accounts/${ticket.reporter.id}`}>{ticket.reporter.name}</Link>
    );
  }
  return <Typography>{'-'}</Typography>;
};

export const renderMessage = (ticket: SimpleTicketModel) => {
  return (
    <Typography.Paragraph
      ellipsis={{
        rows: 1,
        expandable: true,
      }}
    >
      {ticket.message}
    </Typography.Paragraph>
  );
};

export const renderGatewayLink = (ticket: SimpleTicketModel) => {
  if (ticket.gateway) {
    return (
      <Link to={`/gateways/${ticket.gateway.id}`}>{ticket.gateway.name}</Link>
    );
  }
  return <Typography>{'-'}</Typography>;
};

export const renderGatewayStatusTag = (ticket: SimpleTicketModel) => {
  if (ticket.gateway) {
    switch (ticket.gateway.mqttConnectionStatus) {
      case MqttConnectionStatus.CONNECTED:
        return <Tag color={functionalColor.success}>{'ONLINE'}</Tag>;
      case MqttConnectionStatus.NOT_CONNECTED:
        return <Tag color={functionalColor.error}>{'OFFLINE'}</Tag>;
    }
  }
  return <Typography>{'-'}</Typography>;
};

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './util/api';
import { getConfig } from './util/apiConfig';
import {
  CreatePondCmd,
  UpdatePondCmd,
  UpdateAerationProfileCmd,
} from '../model/cmds';
import { PondModel, AerationProfileModel } from '../model/domain';
import { CountPondsModel, ListPondsQuery, ListPondsQueryModel } from '../model/querys';
import { gatewayApi } from './gatewayApi';
import { sensingApi } from './sensingApi';

export class PondApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config);
    this.createPond = this.createPond.bind(this);
    this.fetchPond = this.fetchPond.bind(this);
    this.listPonds = this.listPonds.bind(this);
    this.updatePond = this.updatePond.bind(this);
    this.getAerationProfile = this.getAerationProfile.bind(this);
    this.updateAerationProfile = this.updateAerationProfile.bind(this);
    this.countPonds = this.countPonds.bind(this);
  }

  public createPond(cmd: CreatePondCmd): Promise<PondModel> {
    return this.post<PondModel, CreatePondCmd, AxiosResponse<PondModel>>(
      `/v1/farms/${cmd.farmId}/ponds`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public async fetchPond(pondId: string): Promise<PondModel> {
    const pond = await this.get<PondModel, AxiosResponse<PondModel>>(
      `/v1/ponds/${pondId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });

    const sensors = await Promise.all(
      pond.sensors.map((simpleSensor) => sensingApi.getSensor(simpleSensor.id)),
    );
    pond.sensors = sensors;

    if (pond.gateway) {
      const gateway = await gatewayApi.fetchGateway(pond.gateway.id);
      pond.gateway = gateway;
    }

    return pond;
  }

  public listPonds(cmd?: ListPondsQuery): Promise<ListPondsQueryModel> {
    const config = getConfig();
    if (cmd) {
      config.params = cmd;
    }
    return this.get<ListPondsQueryModel, AxiosResponse<ListPondsQueryModel>>(
      '/v1/ponds',
      config,
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updatePond(pondId: string, cmd: UpdatePondCmd): Promise<PondModel> {
    return this.patch<PondModel, UpdatePondCmd, AxiosResponse<PondModel>>(
      `/v1/ponds/${pondId}`,
      cmd,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public getAerationProfile(pondId: string): Promise<AerationProfileModel> {
    return this.get<AerationProfileModel, AxiosResponse<AerationProfileModel>>(
      `/v1/aerationProfiles/${pondId}`,
      getConfig(),
    )
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public updateAerationProfile(
    pondId: string,
    cmd: UpdateAerationProfileCmd,
  ): Promise<AerationProfileModel> {
    return this.patch<
      AerationProfileModel,
      UpdateAerationProfileCmd,
      AxiosResponse<AerationProfileModel>
    >(`/v1/aerationProfiles/${pondId}`, cmd, getConfig())
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

  public countPonds(): Promise<CountPondsModel> {
    const config = getConfig();
    return this.get<
      CountPondsModel,
      AxiosResponse<CountPondsModel>
    >('/v1/count/ponds', config)
      .then(this.success)
      .catch((error: AxiosError<any>) => {
        if (error.response && error.response.data) {
          error.message = `${error.response.data.key}: ${error.response.data.reason}`;
        }
        throw error;
      });
  }

}

export const pondApi = new PondApi();

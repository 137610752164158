import { Button, Form, Input, notification, Space } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { SensorCapModel } from '../../model/domain';
import { CreateSensorCapCmd } from '../../model/cmds';
import { createSensorCap } from '../../duck/modules/sensorCaps';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (sensorCap: SensorCapModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateSensorCapForm: React.FC<Props> = ({
  createSensorCapConnect,
  sensorCapCreateState,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (sensorCapCreateState.sensorCap) {
      notification.success({
        message: 'Sensor cap created',
      });
      onReset();
      if (onCreated != null) {
        onCreated(sensorCapCreateState.sensorCap);
      }
    }
  }, [sensorCapCreateState.sensorCap]);

  React.useEffect(() => {
    if (sensorCapCreateState.error) {
      notification.error({
        message: 'Sensor cap creation error',
        description: sensorCapCreateState.error?.message,
      });
      if (onError != null) {
        onError(sensorCapCreateState.error);
      }
    }
  }, [sensorCapCreateState.error]);

  const onReset = () => {
    form.resetFields();
  };

  const onSubmit = (values: Store) => {
    const { serialNo, k0, k1, k2, k3, k4, k5, k6, k7, k8 } = values;

    const cmd: CreateSensorCapCmd = {
      serialNo,
      k0,
      k1,
      k2,
      k3,
      k4,
      k5,
      k6,
      k7,
      k8,
    };

    createSensorCapConnect(cmd);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      initialValues={{
        manufacturerId: '',
      }}
    >
      <Form.Item
        label="Serial Number"
        name="serialNo"
        rules={[{ required: true }]}
      >
        <Input placeholder="YL5021091316" />
      </Form.Item>

      <Form.Item label="K0 / L0" name="k0" rules={[{ required: true }]}>
        <Input placeholder="-0.00221376346707775" type={'number'} />
      </Form.Item>

      <Form.Item label="K1 / L1" name="k1" rules={[{ required: true }]}>
        <Input placeholder="0.274767457758179" type={'number'} />
      </Form.Item>

      <Form.Item label="K2 / L2" name="k2" rules={[{ required: true }]}>
        <Input placeholder="-0.0323236846371235" type={'number'} />
      </Form.Item>

      <Form.Item label="K3 / L3" name="k3" rules={[{ required: true }]}>
        <Input placeholder="0.0167768910454112" type={'number'} />
      </Form.Item>

      <Form.Item label="K4 = 0" name="k4" rules={[{ required: true }]}>
        <Input placeholder="0" type={'number'} />
      </Form.Item>

      <Form.Item label="K5 / T1" name="k5" rules={[{ required: true }]}>
        <Input placeholder="0.0139881840528325" type={'number'} />
      </Form.Item>

      <Form.Item label="K6 / T2" name="k6" rules={[{ required: true }]}>
        <Input placeholder="-0.0000303095585006093" type={'number'} />
      </Form.Item>

      <Form.Item label="K7 / T0" name="k7" rules={[{ required: true }]}>
        <Input placeholder="25" type={'number'} />
      </Form.Item>

      <Form.Item label="K8 / TanZero" name="k8" rules={[{ required: true }]}>
        <Input placeholder="2.280849" type={'number'} />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={sensorCapCreateState.loading}
          >
            {'Create Sensor Cap'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  sensorCapCreateState: state.sensorCaps.create,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createSensorCapConnect: createSensorCap,
    },
    dispatch,
  );
};

export const CreateSensorCapForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateSensorCapForm);

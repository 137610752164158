import React from 'react';
import {
  List,
  Result,
  Typography,
  Card,
  Descriptions,
  Space,
  Tooltip,
  Popconfirm,
  Button,
  message,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getSensors } from '../../duck/modules/sensing';
import { INT_MAX } from '../../constants';
import { SensorModel } from '../../model/domain';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { functionalColor } from '../../colors';
import { getSensorNameToType } from '../../util';
import { Link } from 'react-router-dom';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    smartSensorId: string;
  };

const UnconnectedSmartSensorConnectedSensorsList: React.FC<Props> = ({
  sensors,
  loading,
  error,
  total,
  getSensorsConnect,
  smartSensorId,
}) => {
  React.useEffect(() => {
    getSensorsConnect({
      limit: INT_MAX,
      offset: 0,
      smartSensorIds: [smartSensorId],
    });
  }, []);

  if (error) {
    return (
      <Result
        status="error"
        title="Can't load conntected sensors"
        subTitle={error.message}
      />
    );
  }

  const renderSensor = (sensor: SensorModel) => {
    return (
      <List.Item>
        <List.Item.Meta
          description={
            <Descriptions bordered column={1}>
              {sensor.name && (
                <Descriptions.Item
                  label={
                    <Space>
                      <Typography.Text>{'Name'}</Typography.Text>
                      {sensor.name && sensor.name.inSync && (
                        <Tooltip title={'Synchronized'}>
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      )}
                      {sensor.name && !sensor.name.inSync && (
                        <Tooltip title={`Syncing...`}>
                          <WarningOutlined
                            style={{
                              color: functionalColor.warning,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Space>
                  }
                >
                  <Typography.Text strong>
                    {sensor.name ? sensor.name.actual : ''}
                  </Typography.Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item label={'ID'}>
                <Link to={`/sensors/${sensor.id}`}>{sensor.id}</Link>
              </Descriptions.Item>

              <Descriptions.Item label={'Type'}>
                {getSensorNameToType(sensor.type)}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <Typography.Text>{'Socket'}</Typography.Text>
                    {sensor.deviceSocket.inSync ? (
                      <Tooltip title={'Synchronized'}>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={`Syncing...`}>
                        <WarningOutlined
                          style={{
                            color: functionalColor.warning,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Space>
                }
              >
                <Typography.Text>
                  {sensor.deviceSocket.inSync
                    ? sensor.deviceSocket.actual
                    : sensor.deviceSocket.requested}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <Typography.Text>{'Mode'}</Typography.Text>
                    {sensor.deviceSocket.inSync ? (
                      <Tooltip title={'Synchronized'}>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={`Syncing...`}>
                        <WarningOutlined
                          style={{
                            color: functionalColor.warning,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Space>
                }
              >
                <Typography.Text>
                  {sensor.mode.inSync
                    ? sensor.mode.actual
                    : sensor.mode.requested}
                </Typography.Text>
              </Descriptions.Item>

              {/* <Descriptions.Item
                label={<Typography.Text>{'Actions'}</Typography.Text>}
              >
                <Space>
                  <Popconfirm
                    title={
                      'Are you sure to remove connection between this sensor from and the smart sensor?'
                    }
                    onConfirm={() =>
                      // removeSensorFromOrganisationConnect(
                      //   organisationId,
                      //   sensor.id,
                      // )
                      message.info('Not implemented yet!')
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="default">
                      Unconnect
                    </Button>
                  </Popconfirm>
                </Space>
              </Descriptions.Item> */}
            </Descriptions>
          }
        />
      </List.Item>
    );
  };

  return (
    <List
      loading={loading}
      grid={{ gutter: 10, column: 1 }}
      dataSource={sensors}
      locale={{ emptyText: 'No sensors connected' }}
      renderItem={(sensor) => renderSensor(sensor)}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  sensors: state.sensing.list.sensors,
  loading: state.sensing.list.loading,
  error: state.sensing.list.error,
  total: state.sensing.list.total,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSensorsConnect: getSensors,
    },
    dispatch,
  );
};

export const SmartSensorConnectedSensorsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedSmartSensorConnectedSensorsList);

import {
  Button,
  Form,
  Input,
  notification,
  DatePicker,
  Select,
  Space,
  Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../duck';
import { AnnouncementModel } from '../../model/domain';
import { AnnouncementType } from '../../model/enums';
import { CreateAnnouncementCmd } from '../../model/cmds';
import { createAnnouncement } from '../../duck/modules/announcements';
import { getManufacturerOptions } from '../../duck/modules/options';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onCreated?: (announcement: AnnouncementModel) => void;
    onError?: (error: Error) => void;
  };

const UnconnectedCreateAnnouncementForm: React.FC<Props> = ({
  createAnnouncementConnect,
  getManufacturerOptionsConnect,
  announcementCreateState,
  manufacturerOptionsState,
  onCreated,
  onError,
}) => {
  const [form] = Form.useForm();
  const [type, setType] = React.useState<AnnouncementType>();

  React.useEffect(() => {
    if (announcementCreateState.announcement) {
      notification.success({
        message: 'Announcement created',
      });
      onReset();
      if (onCreated != null) {
        onCreated(announcementCreateState.announcement);
      }
    }
  }, [announcementCreateState.announcement]);

  React.useEffect(() => {
    if (announcementCreateState.error) {
      notification.error({
        message: 'Announcement creation error',
        description: announcementCreateState.error?.message,
      });
      if (onError != null) {
        onError(announcementCreateState.error);
      }
    }
  }, [announcementCreateState.error]);

  const onTypeChanged = (type: AnnouncementType) => {
    setType(type);
    form.setFieldsValue({ type });
  };

  const onReset = () => {
    onTypeChanged(AnnouncementType.INFO);
    form.resetFields();
  };

  const convertUTC = (date: Date) => {
    return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  };

  const onSubmit = (values: Store) => {
    const {
      type,
      englishTitle,
      thaiTitle,
      englishBody,
      thaiBody,
      validityPeriod,
    } = values;

    const notShowBefore: moment.Moment = validityPeriod[0];
    const notShowAfter: moment.Moment = validityPeriod[1];

    const cmd: CreateAnnouncementCmd = {
      type,
      notShowAfter: convertUTC(notShowAfter.toDate()),
      notShowBefore: convertUTC(notShowBefore.toDate()),
      content: {
        english: {
          title: englishTitle,
          body: englishBody,
        },
        thai: {
          title: thaiTitle,
          body: thaiBody,
        },
      },
    };

    createAnnouncementConnect(cmd);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      initialValues={{
        type: type,
      }}
    >
      <Form.Item label="Type" name="type" rules={[{ required: true }]}>
        <Select
          placeholder="Select announcement type"
          allowClear
          onChange={onTypeChanged}
        >
          <Select.Option value={AnnouncementType.INFO}>{'INFO'}</Select.Option>
          <Select.Option value={AnnouncementType.MAINTENANCE}>
            {'MAINTENANCE'}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Validity period"
        name="validityPeriod"
        rules={[{ required: true }]}
      >
        <RangePicker
          showTime={{ format: 'HH:mm' }}
          format="DD / MM / YYYY HH:mm"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        label={<Typography.Text italic>{'Title'}</Typography.Text>}
        style={{ marginBottom: 0 }}
      >
        <Form.Item
          label="English"
          name="englishTitle"
          rules={[{ required: true }, { type: 'string' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input placeholder="English" />
        </Form.Item>
        <Form.Item
          label="Thai"
          name="thaiTitle"
          rules={[{ required: true }, { type: 'string' }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <Input placeholder="Thai" />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={<Typography.Text italic>{'Body'}</Typography.Text>}
        style={{ marginBottom: 0 }}
      >
        <Form.Item
          label="English"
          name="englishBody"
          rules={[{ required: true }, { type: 'string' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <TextArea
            placeholder="English"
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(body) =>
              form.setFieldsValue({ englishBody: body.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="Thai"
          name="thaiBody"
          rules={[{ required: true }, { type: 'string' }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <TextArea
            placeholder="Thai"
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(body) =>
              form.setFieldsValue({ thaiBody: body.target.value })
            }
          />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={announcementCreateState.loading}
          >
            {'Create Announcement'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  announcementCreateState: state.announcements.create,
  manufacturerOptionsState: state.options.manufacturerOptions,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createAnnouncementConnect: createAnnouncement,
      getManufacturerOptionsConnect: getManufacturerOptions,
    },
    dispatch,
  );
};

export const CreateAnnouncementForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedCreateAnnouncementForm);

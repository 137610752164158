import React, { useState } from 'react';
import {
  Table,
  Tag,
  Button,
  Result,
  Typography,
  Form,
  Pagination,
  Col,
  Popconfirm,
  InputNumber,
  Input,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck/index';
import { getFarms } from '../../duck/modules/farms';
import {
  FarmModel,
  FeedTableModel,
  FeedTableRowModel,
} from '../../model/domain';
import { functionalColor } from '../../colors';
import Column from 'antd/lib/table/Column';
import {
  getFeedTables,
  updateFeedTableRows,
} from '../../duck/modules/feedTables';
import { SimpleFeedTableModel } from '../../model/simple';
import { Store } from 'antd/lib/form/interface';
import { UpdateFeedTableRowCmd } from '../../model/cmds';
import { update } from 'lodash';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    feedTable: FeedTableModel;
  };

const UnconnectedFeedTableTable: React.FC<Props> = ({
  feedTable,
  updateFeedTableRowsConnect,
}) => {
  const [form] = Form.useForm();

  interface Item {
    key: string;
    doc: number;
    numberOfShrimpsPerKg: number;
    feedRateInPerc: number;
  }

  const originData: Item[] = [];
  feedTable.feedTableRows.forEach((value, key) => {
    originData.push({
      key: key,
      doc: Number.parseInt(key),
      feedRateInPerc: value.feedRateInPerc,
      numberOfShrimpsPerKg: value.numberOfShrimpsPerKg,
    });
  });

  const onSubmit = (values: Store) => {
    const formData = new Map<string, any>(Object.entries(values));
    const updateCmds = new Map<number, UpdateFeedTableRowCmd>();

    feedTable.feedTableRows.forEach((value, key) => {
      const doc = Number.parseInt(key);
      const formNumberOfShrimps = formData.get(`numberOfShrimpsPerKg_${doc}`);
      const formFeedRate = formData.get(`feedRateInPerc_${doc}`);
      if (formNumberOfShrimps != null && formFeedRate != null) {
        if (
          formNumberOfShrimps != value.numberOfShrimpsPerKg ||
          formFeedRate != value.feedRateInPerc
        ) {
          updateCmds.set(doc, {
            numberOfShrimpsPerKg: formNumberOfShrimps,
            feedRateInPerc: formFeedRate,
          });
        }
      }
    });

    console.log(updateCmds);

    if (updateCmds.size > 0) {
      updateFeedTableRowsConnect(feedTable.id, updateCmds);
    }
  };

  const initialValues: Store = {};

  feedTable.feedTableRows.forEach((value, key) => {
    const doc = Number.parseInt(key);
    initialValues[`numberOfShrimpsPerKg_${doc}`] = value.numberOfShrimpsPerKg;
    initialValues[`feedRateInPerc_${doc}`] = value.feedRateInPerc;
  });

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit}>
      <Table bordered dataSource={originData} pagination={false}>
        <Column
          align="center"
          title="DoC"
          render={(row: Item) => {
            return <Typography>{row.doc}</Typography>;
          }}
        />
        <Column
          width="40%"
          align="right"
          title="Shrimps per Kilogram"
          render={(row: Item) => {
            return (
              <Form.Item
                name={`numberOfShrimpsPerKg_${row.doc}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `Add number of shrimps for DoC ${row.doc}`,
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            );
          }}
        />
        <Column
          width="40%"
          align="right"
          title="Feed Rate (%)"
          render={(row: Item) => {
            return (
              <Form.Item
                name={`feedRateInPerc_${row.doc}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `Add feed rate for DoC ${row.doc}`,
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            );
          }}
        />
      </Table>

      <Form.Item style={{ marginTop: 20 }}>
        <Button type="primary" htmlType="submit">
          Update Table
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateFeedTableRowsConnect: updateFeedTableRows,
    },
    dispatch,
  );
};

export const FeedTableTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedFeedTableTable);

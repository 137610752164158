import {
  Select,
  AutoComplete,
  Button,
  Form,
  Input,
  Space,
  Typography,
  Popconfirm,
  Layout,
  Row,
  Col,
  Card,
  notification,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Store } from 'rc-field-form/lib/interface';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../duck';
import {
  deleteMessage,
  getMessage,
  updateMessage,
} from '../../duck/modules/messages';
import { MessageType, PopUpRule, PopUpType } from '../../model/enums';

const { Option } = Select;
const { TextArea } = Input;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ popUpId: string }>;

const UnconnectedPopUpDetail: React.FC<Props> = ({
  getMessageConnect,
  updateMessageConnect,
  deleteMessageConnect,
  message,
  loading,
  error,
  updateSuccess,
  updateError,
  updating,
  deleteSuccess,
  deleteError,
  deleting,
  match,
}) => {
  const [form] = Form.useForm();

  const { popUpId } = match.params;

  React.useEffect(() => {
    getMessageConnect(popUpId);
  }, []);

  React.useEffect(() => {
    if (updateSuccess) {
      notification.success({
        message: 'Pop Up updated',
      });
    }
  });

  React.useEffect(() => {
    if (deleteSuccess) {
      notification.success({
        message: 'Pop Up deleted',
      });
    }
  });

  React.useEffect(() => {
    if (updateError) {
      notification.error({
        message: 'Error while updating pop up',
      });
    }
  });

  React.useEffect(() => {
    if (deleteError) {
      notification.error({
        message: 'Error while deleting pop up',
      });
    }
  });

  React.useEffect(() => {
    getMessageConnect(popUpId);
  }, [updateSuccess]);

  React.useEffect(() => {
    if (message) {
      var messageData = new Map(Object.entries(message.data));
      var popUpData = new Map(Object.entries(messageData.get('nodes'))) as Map<
        String,
        any
      >;

      var dataInEnglishRaw = popUpData.get('dataInEnglish') as Object;
      var dataInEnglish = new Map(Object.entries(dataInEnglishRaw));
      var titleInEnglish = String(dataInEnglish.get('title'));
      var bodyInEnglish = String(dataInEnglish.get('body'));

      var dataInThaiRaw = popUpData.get('dataInThai') as Object;
      var dataInThai = new Map(Object.entries(dataInThaiRaw));
      var titleInThai = String(dataInThai.get('title'));
      var bodyInThai = String(dataInThai.get('body'));

      var typeRaw = new Map(Object.entries(messageData.get('nodes'))).get(
        'type',
      );
      var type = String(typeRaw);

      var gifUrlRaw = new Map(Object.entries(messageData.get('nodes'))).get(
        'gifUrl',
      );
      var gifUrl = String(gifUrlRaw);

      var rulesRaw = new Map(Object.entries(messageData.get('nodes'))).get(
        'rules',
      );
      var rules = rulesRaw;

      form.setFieldsValue({
        type: type,
        titleInEnglish: titleInEnglish,
        titleInThai: titleInThai,
        bodyInEnglish: bodyInEnglish,
        bodyInThai: bodyInThai,
        gifUrl: gifUrl,
        rule: rules,
      });
    }
  });

  const onFinish = (values: Store) => {
    const {
      type,
      titleInEnglish,
      titleInThai,
      bodyInEnglish,
      bodyInThai,
      gifUrl,
      rule,
    } = values;
    if (popUpId) {
      updateMessageConnect(popUpId, {
        type: MessageType.POP_UP,
        popUp: {
          type: type,
          dataInEnglish: {
            title: titleInEnglish,
            body: bodyInEnglish,
          },
          dataInThai: {
            title: titleInThai,
            body: bodyInThai,
          },
          gifUrl: gifUrl,
          rules: rule,
        },
      });
    }
  };

  const renderGeneralDataCardContent = () => {
    if (message) {
      return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="Pop Up Type" name="type">
            <Select placeholder="Select pop up type">
              <Option value={PopUpType.GENERAL_POP_UP}>
                {'General Pop Up'}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={<Typography.Text italic>{'Title'}</Typography.Text>}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              label="Enlish"
              name="titleInEnglish"
              rules={[{ required: true }]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
              }}
            >
              <Input placeholder="Title (in English)" />
            </Form.Item>
            <Form.Item
              label="Thai"
              name="titleInThai"
              rules={[{ required: true }]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px',
              }}
            >
              <Input placeholder="Title (in Thai)" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={<Typography.Text italic>{'Body'}</Typography.Text>}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              label="English"
              name="bodyInEnglish"
              rules={[{ required: true }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <TextArea
                placeholder="Body (In English)"
                autoSize={{ minRows: 10, maxRows: 10 }}
                onChange={(body) =>
                  form.setFieldsValue({ englishBody: body.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              label="Thai"
              name="bodyInThai"
              rules={[{ required: true }]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px',
              }}
            >
              <TextArea
                placeholder="Body (In Thai)"
                autoSize={{ minRows: 10, maxRows: 10 }}
                onChange={(body) =>
                  form.setFieldsValue({ thaiBody: body.target.value })
                }
              />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Gif URL" name="gifUrl" rules={[{ required: true }]}>
            <Input placeholder="Gif url for the pop up" />
          </Form.Item>
          <Form.Item label="Rules" name="rule" rules={[{ required: true }]}>
            <AutoComplete
              placeholder="Enter Rules"
              options={[
                PopUpRule.SHOW_ONCE,
                PopUpRule.REPEAT_ONE_DAILY,
                PopUpRule.ACCOUNT,
                PopUpRule.FARM,
                PopUpRule.POND,
                PopUpRule.SENSOR,
                PopUpRule.TNC,
              ].map((option) => {
                return { lable: option, value: option };
              })}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update Pop Up
              </Button>
            </Space>
          </Form.Item>
        </Form>
      );
    }
  };

  const renderDeleteButton = () => {
    return (
      <Popconfirm
        title="Do you really want to delete this message?"
        onConfirm={() => {
          deleteMessageConnect(popUpId);
        }}
      >
        <Button
          type="ghost"
          style={{ marginLeft: 12 }}
          danger
          loading={deleting}
        >
          Delete
        </Button>
      </Popconfirm>
    );
  };

  return (
    <Layout>
      <Content>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col>{renderDeleteButton()}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card title="Pop Up Data" loading={updating}>
              {renderGeneralDataCardContent()}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  message: state.message.detail.message,
  loading: state.message.detail.loading,
  error: state.message.detail.error,
  updating: state.message.detail.updating,
  updateSuccess: state.message.detail.updateSuccess,
  updateError: state.message.detail.updateError,
  deleting: state.message.detail.deleting,
  deleteSuccess: state.message.detail.deleteSuccess,
  deleteError: state.message.detail.deleteError,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getMessageConnect: getMessage,
      updateMessageConnect: updateMessage,
      deleteMessageConnect: deleteMessage,
    },
    dispatch,
  );
};

export const PopUpDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedPopUpDetail);
